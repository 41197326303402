<span>
    
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i>
 <b> {{this.configSvc.nodeds[0].name}}</b> <em>{{this.configSvc.nodeds[0].desc}}</em>
    <span >
        <div *ngIf="this.configSvc.nodeds[0].type == 'node' || this.configSvc.nodeds[0].type == 'nodeimage' || this.configSvc.nodeds[0].type == 'foldertags' || 
        this.configSvc.nodeds[0].launcher == 'node'  || this.configSvc.nodeds[0].type == 'smartfolder'
        || this.configSvc.nodeds[0].type == 'basicflow'" mat-dialog-content>
         
        <mat-horizontal-stepper [linear]="true" #stepper [(selectedIndex)]="this.stepIndex">
        

     
          <mat-step [stepControl]="step1">
            <ng-template matStepLabel>
             
          
             Type</ng-template>

        <span *ngIf="data.type == 'node' || data.type == 'basicflow'">

         
          <div>
          <mat-radio-group [(ngModel)]="createtype"> 
    <span>
      How would you like to action this {{this.configSvc.nodeds[0].name}}?
    </span>
        <div><mat-radio-button value="manual">Save {{this.configSvc.nodeds[0].name}} to {{this.configSvc.activename}} folder</mat-radio-button>
        <div *ngIf="this.configSvc.folderdetailsds.tagged">
           <mat-checkbox  [(ngModel)]="indexes">Index</mat-checkbox>
             <mat-checkbox  [(ngModel)]="verifyindexes">Verify Indexes</mat-checkbox></div> 
        
        </div> 
        
        <span class ="addoptions">  <mat-checkbox  [(ngModel)]="this.fileattach">Attach File</mat-checkbox></span>
        <span class ="addoptions">  <mat-checkbox  [(ngModel)]="this.assigned">Assign to User</mat-checkbox> </span>
        <span class ="addoptions">  <mat-checkbox  [(ngModel)]="this.deadline">Set Deadline</mat-checkbox>  </span> 
       
        <div>   <mat-radio-button value="automation">Create multiple {{this.configSvc.nodeds[0].name}}'s</mat-radio-button></div> 
     
        <div *ngIf="this.configSvc.favtools"> <mat-checkbox  [(ngModel)]="removefavs">Remove from Favourites</mat-checkbox></div>
        <div *ngIf="!this.configSvc.favtools"> 
          
          <span class ="addoptions"><mat-checkbox  [(ngModel)]="favtool">Add to Favourites</mat-checkbox></span>
       
        </div> 
        <span *ngIf="!removefavs"> <button mat-button matStepperNext matSuffix 
        mat-raised-button color="primary" (click)="addToolfavs()">Next </button></span>

       <span *ngIf="removefavs"> <button mat-button matSuffix 
        mat-raised-button color="primary" (click)="removeToolfavs()">Remove from Favourites </button></span>
            </mat-radio-group></div>
        </span> 

        <span *ngIf=" data.type == 'smartfolder' ">
Select Next to create the Smart Folder {{this.configSvc.nodeds[0].name}}?
          <div *ngIf="!this.configSvc.favtools"> <mat-checkbox  [(ngModel)]="favtool">Add to Favourites</mat-checkbox>
         
          
          
          </div> 

         

          <button mat-button matStepperNext matSuffix 
          mat-raised-button color="primary"
          (click)="move(1)">Next </button> 
          <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
        </span>
</mat-step>

<mat-step [stepControl]="step2" *ngIf =" this.fileattach && this.configSvc.nodeds[0].type != 'basicflow'">
  <ng-template matStepLabel>
   

    Upload File</ng-template>

    
    <mat-checkbox [(ngModel)]="this.configSvc.textract" >Try to automatically index {{this.configSvc.nodeds[0].name}} with Textract?
     
      
      </mat-checkbox>
   
    <div *ngIf = "fileattach && !this.configSvc.textract">
   Upload document here</div>

   <div *ngIf = "fileattach && this.configSvc.textract">
    Upload pdf or jpeg file here for Textract to process
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': green}">fact_check</i>
  </div>
    <button mat-button matStepperNext matSuffix 
    mat-raised-button color="primary"
    (click)="move(2)">Next </button>
</mat-step>

<mat-step [stepControl]="step3" *ngIf ="this.assigned && this.configSvc.nodeds[0].type != 'basicflow'">
  <ng-template matStepLabel>
   

    Assign</ng-template>

   
   
    <div *ngIf = "assigned">
    <app-assigned [assigntype]="currentAssigntype" ></app-assigned></div>
    <button mat-button matStepperNext matSuffix 
    mat-raised-button color="primary"
    (click)="move(3)">Next </button>
</mat-step>

<mat-step [stepControl]="step4" *ngIf ="this.deadline && this.configSvc.nodeds[0].type != 'basicflow'">
  <ng-template matStepLabel>
   

    Deadline</ng-template>

   

    <div *ngIf = "deadline">
      <span class = "labels">
        <mat-label>Set Deadline for {{this.configSvc.nodeds[0].name}}?</mat-label>
       </span> 
    <mat-form-field appearance="fill">
      <input matInput [matDatepicker]="picker1" placeholder="Set Deadline" [(ngModel)]="deadlineval " >
           <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
           <mat-datepicker  #picker1></mat-datepicker>
         </mat-form-field></div>
    <button mat-button matStepperNext matSuffix 
    mat-raised-button color="primary"
    (click)=move(4)>Next </button>
 </mat-step>

<mat-step [stepControl]="step5" *ngIf ="createtype == 'automation'">
  <ng-template matStepLabel>
   

   Automation</ng-template>
          <span >
            Download sample bulk upload csv file
            <ngx-file-drop dropZoneLabel="Drop csv file for bulk creation here"
            (onFileDrop)="bulkforms($event, '4')"
                    
                    >
                   
                   </ngx-file-drop>

                   <mat-card>
                    <mat-card-content>
                      <span class = "button-container">
                      <h2 class="progress-h2">Automation Progess</h2>
                      <span class = "button-container"><mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar></span> </span> 
                     <button mat-raised-button color="primary" (click)="onNoClick()">Finish</button>

                    </mat-card-content>
                  </mat-card>
          </span>
 </mat-step>

 <mat-step [stepControl]="step5" *ngIf ="verifyindexes">
  <ng-template matStepLabel>
   

   Verify Indexes</ng-template>
          <span >
  

                   <mat-card>
                    <app-foldertags [indexlaunch]="'toolbar'"></app-foldertags>
                 <button mat-button matStepperNext matSuffix 
                 mat-raised-button color="primary">Next </button>
                  </mat-card>
          </span>
 </mat-step>

 
<mat-step [stepControl]="step6" *ngIf ="createtype != 'automation'">
  <ng-template matStepLabel>
   

    {{this.configSvc.nodeds[0].name}}</ng-template>

         
   <div *ngIf="this.configSvc.nodeds[0]?.statetracker"> 
         
    <div class= "labels"> 

      <mat-slide-toggle [disabled] = "!this.previewFG.dirty"
      [checked] = this.configSvc.nodeds[0].state
      (change)="changeState()">
                              
       {{this.configSvc.nodeds[0].statelabel}}
        </mat-slide-toggle>
     <span *ngIf="this.configSvc.nodeds[0].state">
  
      <i class="material-icons md-48" [ngStyle]="{'color': 'green'}">check_circle_outline </i>
     </span>
       
      </div> 

     </div>

           <span *ngIf="createtype == 'manual' || createtype == 'assign'">
               <form id="myForm" [formGroup]="previewFG" >
                    <span [formArrayName]="'formArrayName'" class = "container" >
            
                    <div *ngFor="let property of this.previewArray.controls; let i = index"
                    [ngClass]= "
                    {'fullrow': property.value.rowsize == 'full',
                    'halfrow': property.value.rowsize == 'half',
                    'thirdrow': property.value.rowsize == 'third'}"
                    
                    >
                      <span [formGroupName]="i"  >
            
  <span *ngIf="property.value.formtype.value == 'string'" class = "flex-item" > 
                           
                           <span *ngIf="property.value.label.value==true" class = "labels">
                             <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                            </span> 
                          
                            <mat-form-field appearance="fill">
                               
                                 <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                </mat-form-field>
                              
     </span> 

     <span *ngIf="property.value.formtype.value == 'image'" class = "flex-item" > 
                           
      <span *ngIf="property.value.label.value==true" class = "labels">
        <mat-label>{{property.value.formitemlabel.value}}</mat-label>
       </span> 
     
       <mat-form-field appearance="fill">
          
            <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
           </mat-form-field>

      
                  <div *ngIf="!imageready">
                  <input type='file' (change)="imagedropped($event)"></div>
                  <div *ngIf="imageready">
                  
                    <img [src]="url" height="200"> <br/>
                  </div>
         
        </span> 
                   
                                <span *ngIf="property.value.formtype.value == 'number'"> 
                                
                                  <span *ngIf="property.value.label.value==true" class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   </span> 
                                   <mat-form-field appearance="fill" class = "matnum">
                                       <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                                      </mat-form-field></span>
                   
                                <span *ngIf="property.value.formtype.value == 'boolean'" class = "slider"> 
                                
                                  <mat-slide-toggle 
                                  [checked] = property.value.hasdefault
                                  (change)="changeBoolean(property.value.hasdefault, i)">
                                                          
                                   {{property.value.formitemlabel.value}}
                                    </mat-slide-toggle>
                              
                            
                              



                              </span>
                   
                                <span *ngIf="property.value.formtype.value == 'date'"> 
                                  <span class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   </span> 
                                <mat-form-field appearance="fill">
                                  <input matInput [matDatepicker]="picker1" placeholder="{{property.value.itemvalue.value}}" formControlName="itemvalue" >
                                       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                       <mat-datepicker  #picker1></mat-datepicker>
                                     </mat-form-field></span>
            
                                     <span *ngIf="property.value.formchoices.value==false && property.value.formtype.value == 'checkbox'" class = "slider"> 
                                      <mat-checkbox formControlName="hasdefault">
                                        {{property.value.formitemlabel.value}} </mat-checkbox></span>

          
          <span *ngIf=" property.value.formtype.value == 'text'" class = "flex-item" > 
                           
            <span *ngIf="property.value.label.value==true" class = "labels">
              <mat-label>{{property.value.formitemlabel.value}}</mat-label>
             </span> 
           
             <mat-form-field appearance="outline"   class = "textarea">
                <textarea
                
                formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
               </mat-form-field>
               
              </span> 

              <div *ngIf="property.value.formtype.value == 'choicelist'  ">
   

                <span *ngIf="property.value.label.value==true" class= "itemcontainer">
                  <span class = "labels">
                  <mat-label >{{property.value.formitemlabel.value}} </mat-label></span> </span>
<mat-form-field>
              
                 <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue" 
                
                 (selectionChange)="choiceChange()"
                 >
             <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
               {{choice.name}}
             </mat-option>
           </mat-select>
          
         </mat-form-field>
       
         
       
           </div>

           <div *ngIf="property.value.formtype.value == 'treelist'  ">


            <span class= "treecontainer">
              <span class = "labels">
              <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>

           
            <mat-chip-list>
      
              
              <mat-chip *ngFor="let paths of this.sortPath()"
              (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
              >
                {{paths.name}}
          </mat-chip>
          </mat-chip-list>
      
        
            <div class="outerbox">  
              <span  >
              <table mat-table [dataSource]="this.tagerSvc.activetree"  >
              
                <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                (click)="this.getChoicetree(selectedrow._id); 
               
                
                this.hlchoice(selectedrow,  i);
                "
                [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                class="innerbox"
                ></tr>
              
              
                         
              <ng-container matColumnDef="Name" >
                    <th mat-header-cell *matHeaderCellDef>Name </th>
                 <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.haschild">  
                    <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                    </span>
                     {{element.name}}
               </td></ng-container>
              
                 </table> </span>
      </div>
      
      Value selected: 
      <div *ngIf="this.clickchoiceset"><mat-chip-list>
      <mat-chip>
      {{property.value.itemvalue}}
      </mat-chip></mat-chip-list></div>
      
      </span>
      
       </div>

           <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
     <div  *ngIf="property.value.label.value==true" >
           
              <div class = "labels">
              <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
              
            <mat-radio-group
            class="radio-group">
            <mat-radio-button class="radio-button" 
            
          
           
            *ngFor="let choice of getChoices(property.value.choicesetid.value)"
            [value]="choice.name"
            [checked]="choice.name == property.value.itemvalue"
            
            (change)="this.changeRadio(choice,  i)"
            >
              {{choice.name}}
            </mat-radio-button>
          </mat-radio-group>
         
     
      </div>
   
       </div>

    
                                          
                </span> 
 </div>

 
 <div  *ngIf="this.configSvc.nodeds[0].type == 'flow'">
  <button mat-raised-button color="primary" (click)="launchFlow()">Launch {{this.configSvc.nodeds[0].name}}</button>
  <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
 </div>
                    
                   
                  </span>
                     </form>

                     <span *ngIf="data.type == 'node' || data.type == 'nodeimage'  || data.type == 'basicflow'">

          <span *ngIf="createtype == 'manual' && data.type == 'node' && !assigned">
          <button mat-raised-button color="primary" (click)="saveNode('node')" class = "buttonstyle">Save Only</button></span>

          <span *ngIf="createtype == 'manual' && data.type == 'node' && assigned">
            <div>
              Save to {{this.configSvc.activename}} and Assign to {{this.configSvc.assigned[0]}}
            </div>
            <button mat-raised-button color="primary" (click)="assignNode()" class = "buttonstyle">Save Assign</button></span>



          <span *ngIf="createtype == 'manual' && data.type == 'basicflow'">
            <button mat-raised-button color="primary" (click)="saveFormassign( 'launch')" class = "buttonstyle">Launch</button></span>
    
  
         
          <button mat-raised-button color="primary" (click)="onNoClick()" class = "buttonstyle">Cancel</button></span>  </span>


          </mat-step>
         

  
           </mat-horizontal-stepper>

      </div>

      <div *ngIf="this.configSvc.nodeds[0].type == 'kanban'" mat-dialog-content>
        <span *ngIf="!this.configSvc.folderdetailsds.kanbantagged"><form id="myForm" [formGroup]="previewFG" >
          <span [formArrayName]="'formArrayName'" class = "flex-container" >
  
          <div *ngFor="let property of this.previewArray.controls; let i = index"
         class = "kanban-container">
         <mat-card><span class= "tracks">
         <h2  class="mat-h2">{{property.value.formitem.value}}</h2>
         <i class="material-icons md-24" [ngStyle]="{'color': 'green'}"
         matTooltip={{property.value.itemvalue}}  matTooltipPosition= 'after'
         >info</i></span>
        </mat-card>
          
          </div>

        </span> 
   
      

       </form></span>

       <span *ngIf="this.configSvc.folderdetailsds.kanbantagged">This folder already has a Kanban applied</span>
      </div>
      
      <div *ngIf="this.configSvc.nodeds[0].type == 'wizard' || this.configSvc.nodeds[0].launcher == 'wizard'
      || this.configSvc.nodeds[0].type == 'smartfolder' || this.configSvc.nodeds[0].type == 'editstepperassign'" mat-dialog-content>
        <div>

          <mat-horizontal-stepper [linear]="isLinear" #stepper>
  

     
            <mat-step [stepControl]="step1">
              <ng-template matStepLabel>
               
            
               Type</ng-template>
  
          <span>
  
           
            <div>
            <mat-radio-group [(ngModel)]="createtype"> 
      
          <div><mat-radio-button value="manual">Save wizard to {{this.configSvc.activename}}</mat-radio-button></div>    
          <div *ngIf="this.configSvc.nodeds[0].type != 'editstepperassign'">  <mat-radio-button value="assign">Assign wizard to someone else</mat-radio-button></div>    
          <div>   <mat-radio-button value="automation">Automation</mat-radio-button></div>    
              
          <button mat-button matStepperNext matSuffix 
          mat-raised-button color="primary">Next </button>
              </mat-radio-group></div>
          </span> 
  </mat-step>
  <mat-step [stepControl]="step2" *ngIf ="createtype == 'assign'">
    <ng-template matStepLabel>
     
  
      Assign</ng-template>
      <app-assignment></app-assignment>
      <button mat-button matStepperNext matSuffix 
      mat-raised-button color="primary">Next </button>
   </mat-step>
  
  <mat-step [stepControl]="step3" *ngIf ="createtype == 'automation'">
    <ng-template matStepLabel>
     
  
     Automation</ng-template>
            <span >
              <ngx-file-drop dropZoneLabel="Drop csv file for bulk creation here"
              (onFileDrop)="bulkforms($event, '4')"
                      >
                     
                     </ngx-file-drop>
  
                     <mat-card>
                      <mat-card-content>
                        <h2 class="progress-h2">Automation Progess</h2>
                        <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
                      
                      </mat-card-content>
                    </mat-card>
            </span>
   </mat-step>
  <mat-step [stepControl]="step4" *ngIf ="createtype != 'automation'">

          <mat-horizontal-stepper [linear]="isLinear" #stepper >
            <mat-step *ngFor="let step of this.configSvc.nodeds[0].stepper; let stepindex = index; " >
              <ng-template matStepLabel>{{ step.step }}</ng-template>
             <form id="myForm" [formGroup]="previewFG" >
              <div class = "popuptitle">{{step.desc}}</div> 
                  <span [formArrayName]="'formArrayName'" class="container">
                   
                  <div *ngFor="let property of this.previewArray.controls; let i = index" [ngClass]= "
                  {'fullrow': property.value.rowsize == 'full',
                  'halfrow': property.value.rowsize == 'half',
                  'thirdrow': property.value.rowsize == 'third'}">
                 
                    <div [formGroupName]="i" *ngIf="property.value.step.value == stepindex" >
                   
                     
                      <div *ngIf="property.value.formtype.value == 'string'"  > 
                       
                        <span *ngIf="property.value.label.value==true" class = "labels">
                          <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                         
                       
                        <mat-form-field appearance="fill" class= "large-item"> 
                         <span *ngIf="property.value.label.value==false">
                              <mat-label >{{property.value.formitem.value}} </mat-label></span>
                              <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                             </mat-form-field>
                          
          
                           <div *ngIf="property.value.label.value==false" class = "labels">
                           
                           <mat-form-field appearance="outline" floatLabel="always"> 
                             <mat-label >{{property.value.property.value}} </mat-label>
                                 <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                </mat-form-field>
                               </div> 
                           
                       </div> 
                 
                        <div *ngIf="property.value.formtype.value == 'number'" > 
                          <span>
            <span *ngIf="property.value.label.value==true" class = "labels">
                            <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                           
                         <mat-form-field appearance="fill" class= "small-item">  
                             
                               <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
             </mat-form-field></span>
            
             <span *ngIf="property.value.label.value==false" class = "labels">
          
              <mat-form-field appearance="outline" floatLabel="always"> 
                <mat-label >{{property.value.property.value}} </mat-label>
                    <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                   </mat-form-field>
            </span></span>
          </div>  
                 
          <span *ngIf="property.value.formtype.value == 'boolean'"> 
           
            <div class= "labels"> 
       
              <mat-slide-toggle 
              [checked] = property.value.hasdefault
              (change)="changeBoolean(property.value.hasdefault, i)">
                                      
               {{property.value.formitemlabel.value}}
                </mat-slide-toggle>
             
               
              </div> 
       
                           </span>
                 
                              <span *ngIf="property.value.formtype.value == 'date'"  > 
                                <span *ngIf="property.value.label.value==true" class = "labels">
                                  <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                 </span> 
                              <mat-form-field >
                                
                                <input matInput [matDatepicker]="picker1"  formControlName="itemvalue" class= "medium-date">
                                     <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                     <mat-datepicker  #picker1></mat-datepicker>
                                   </mat-form-field></span>
          
          
                             <span *ngIf="property.value.formtype.value == 'checkbox'"> 
                                   
                                    
                                     <mat-checkbox formControlName="hasdefault">
                                      {{property.value.formitemlabel.value}} </mat-checkbox>
                           </span>
             
    
                           <span *ngIf="property.value.formtype.value == 'text'"  > 
                               
                            <span class = "labels">
                              <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                             </span> 
                           
                             <mat-form-field appearance="outline"   class= "xlarge-item">
                                <textarea
                                
                                formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                               </mat-form-field>
                               
                       </span> 
    
                       <span *ngIf="property.value.formtype.value == 'choicelist'"  class= "medium-item">
    
    
                        <span>
                          <span class = "labels">
                          <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
    <mat-form-field>
                      
                         <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue">
                     <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                       {{choice.name}}
                     </mat-option>
                   </mat-select>
                  
                 </mat-form-field>
                </span>
                 
               
              </span>
    
                   <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                    <span  *ngIf="property.value.label.value==true" >
                           <span >
                             <div class = "labels">
                             <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
                           <mat-radio-group
                           class="radio-container">
                           <mat-radio-button
                           
                         
                          
                           *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                           [value]="choice.name"
                           [checked]="choice.name == property.value.itemvalue"
                           (change)="changeRadio(choice, i)"
                           >
                             {{choice.name}}
                           </mat-radio-button>
                         </mat-radio-group>
                        
                       </span>
                     </span>
                  
                      </div>
    
                      <div *ngIf="property.value.formtype.value == 'treelist'  ">
                     
                        <span *ngIf="property.value.label.value==true"  class= "treecontainer">
               <span class = "labels">
               <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
    
            
             <mat-chip-list>
       
               
               <mat-chip *ngFor="let paths of this.sortPath()"
               (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
               >
                 {{paths.name}}
           </mat-chip>
           </mat-chip-list>
       
         
             <div *ngIf="clickchoiceset" class="outerbox">  
               <span  >
               <table mat-table [dataSource]="this.tagerSvc.activetree"  >
               
                 <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                 (click)="this.getChoicetree(selectedrow._id); 
                
                 
                 this.hlchoice(selectedrow,  i);
                 "
                 [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                 class="innerbox"
                 ></tr>
               
               
                          
               <ng-container matColumnDef="Name" >
                     <th mat-header-cell *matHeaderCellDef>Name </th>
                  <td mat-cell *matCellDef="let element">
                   <span *ngIf="element.haschild">  
                     <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                     </span>
                      {{element.name}}
                </td></ng-container>
               
                  </table> </span>
       </div>
       
      
     
       
       </span>
                   </div>
    
    
                                         
                                  </div> 
                  </div>
                  
                 
                </span>
                   </form>
          
                   <span class = "stepbuttons">

                    <span *ngIf="stepindex != 0">
                      <button mat-button matStepperPrevious matSuffix 
                      mat-raised-button color="primary">Previous </button>
                      </span>

                      <span *ngIf="stepindex != this.configSvc.nodeds[0].stepper.length-1">
                        <button mat-button matStepperNext matSuffix 
                        mat-raised-button color="primary">Next </button>
                        </span>

                   <span *ngIf="stepindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'wizard'">
               
                   
                    <button mat-button matStepperNext matSuffix 
                    mat-raised-button color="primary"  (click)="saveStepper('stepper')">Save Stepper</button>
                    </span>

                    <span *ngIf="stepindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'wizard' && createtype == 'assign'">
                      <button mat-button matStepperNext matSuffix 
                      mat-raised-button color="primary"  (click)="saveStepperassign('assign', 'stepperassign')">Assign Stepper {{this.configSvc.nodeds[0].name}}</button>
                      </span>

                      <span *ngIf="stepindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'editstepperassign'">
                        <button mat-button matStepperNext matSuffix 
                        mat-raised-button color="primary"  (click)="saveStepperassign('launch', 'stepperassign')">Launch Wizard {{this.configSvc.nodeds[0].name}}</button>
                        </span>

                    

                        <span>
                          <button mat-button  
                          mat-raised-button color="primary" (click)="this.dialogRef.close()">Cancel </button>
                          </span>
                        </span>
                   </mat-step>
          </mat-horizontal-stepper>
          </mat-step>
          </mat-horizontal-stepper>
          </div>

      </div>
    
      <div *ngIf="this.configSvc.nodeds[0].type == 'nodetab'  || this.configSvc.nodeds[0].type == 'smartfoldertab' || this.configSvc.nodeds[0].type == 'edittabbedassign'">

        
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
  

     
          <mat-step [stepControl]="step1">
            <ng-template matStepLabel>
             
          
             Type</ng-template>

        <span>

         
          <div>
          <mat-radio-group [(ngModel)]="createtype"> 
    
        <div><mat-radio-button value="manual">Save Tabbed Form to {{this.configSvc.activename}}</mat-radio-button></div>    
        <div *ngIf="this.configSvc.nodeds[0].type != 'editstepperassign' && this.configSvc.nodeds[0].type != 'edittabbedassign'">  <mat-radio-button value="assign">Assign wizard to someone else</mat-radio-button></div>    
        <div>   <mat-radio-button value="automation">Automation</mat-radio-button></div>    
            
        <button mat-button matStepperNext matSuffix 
        mat-raised-button color="primary">Next </button>
            </mat-radio-group></div>
        </span> 
</mat-step>
<mat-step [stepControl]="step2" *ngIf ="createtype == 'assign'">
  <ng-template matStepLabel>
   

    Assign</ng-template>
    <app-assignment></app-assignment>
    <button mat-button matStepperNext matSuffix 
    mat-raised-button color="primary">Next </button>
 </mat-step>

<mat-step [stepControl]="step3" *ngIf ="createtype == 'automation'">
  <ng-template matStepLabel>
   

   Automation</ng-template>
          <span >
            <ngx-file-drop dropZoneLabel="Drop csv file for bulk creation here"
            (onFileDrop)="bulkforms($event, '4')"
                   
                    >
                   
                   </ngx-file-drop>

                   <mat-card>
                    <mat-card-content>
                      <h2 class="progress-h2">Automation Progess</h2>
                      <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
                    
                    </mat-card-content>
                  </mat-card>
          </span>
 </mat-step>
<mat-step [stepControl]="step4" *ngIf ="createtype != 'automation'">

        <mat-card class = "activestepper">

          <mat-tab-group>
            <mat-tab *ngFor="let tab of this.configSvc.nodeds[0].stepper; let tabindex = index; " label={{tab.step}}>
              
             <form id="myForm" [formGroup]="previewFG" >
                  <span [formArrayName]="'formArrayName'" class="popup-container">
                    <span class = "popuptitle">{{tab.desc}}</span> 
                  <div *ngFor="let property of this.previewArray.controls; let i = index">
                 
                    <div [formGroupName]="i" *ngIf="property.value.step.value == tabindex" >
                   
                     
                      <div *ngIf="property.value.formtype.value == 'string'"  > 
                        <span >
                        <span *ngIf="property.value.label.value==true" class = "labels">
                          <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                         
                       
                        <mat-form-field appearance="fill" class= "large-item"> 
                         <span *ngIf="property.value.label.value==false">
                              <mat-label >{{property.value.formitem.value}} </mat-label></span>
                              <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                             </mat-form-field>
                          
          
                           <div *ngIf="property.value.label.value==false" class = "labels">
                           
                           <mat-form-field appearance="outline" floatLabel="always"> 
                             <mat-label >{{property.value.property.value}} </mat-label>
                                 <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                </mat-form-field>
                               </div> 
                           
                        </span>     </div> 
                 
                        <div *ngIf="property.value.formtype.value == 'number'" > 
                          <span>
            <span *ngIf="property.value.label.value==true" class = "labels">
                            <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                           
                         <mat-form-field appearance="fill" class= "small-item">  
                             
                               <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
             </mat-form-field></span>
            
             <span *ngIf="property.value.label.value==false" class = "labels">
          
              <mat-form-field appearance="outline" floatLabel="always"> 
                <mat-label >{{property.value.property.value}} </mat-label>
                    <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                   </mat-form-field>
            </span></span>
          </div>  
                 
          <span *ngIf="property.value.formtype.value == 'boolean'"> 
           
            <div class= "labels"> 
        
              <mat-slide-toggle 
              [checked] = property.value.hasdefault
              (change)="changeBoolean(property.value.hasdefault, i)">
                                      
               {{property.value.formitemlabel.value}}
                </mat-slide-toggle>
             
               
              </div> 
        
                           </span>
                 
                              <span *ngIf="property.value.formtype.value == 'date'"  > 
                                <span *ngIf="property.value.label.value==true" class = "labels">
                                  <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                 </span> 
                              <mat-form-field appearance="fill">
                                
                                <input matInput [matDatepicker]="picker1"  formControlName="itemvalue" class= "medium-date">
                                     <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                     <mat-datepicker  #picker1></mat-datepicker>
                                   </mat-form-field></span>
          
          
                             <span *ngIf="property.value.formtype.value == 'checkbox'"> 
                                   
                                    
                                     <mat-checkbox formControlName="hasdefault">
                                      {{property.value.formitemlabel.value}} </mat-checkbox>
                           </span>
             
        
                           <span *ngIf="property.value.formtype.value == 'text'"  > 
                               
                            <span class = "labels">
                              <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                             </span> 
                           
                             <mat-form-field appearance="outline"   class= "xlarge-item">
                                <textarea
                                
                                formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                               </mat-form-field>
                               
                       </span> 
        
                       <span *ngIf="property.value.formtype.value == 'choicelist'"  class= "medium-item">
        
        
                        <span>
                          <span class = "labels">
                          <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
        <mat-form-field>
                      
                         <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue">
                     <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                       {{choice.name}}
                     </mat-option>
                   </mat-select>
                  
                 </mat-form-field>
                </span>
                 
               
              </span>
        
                   <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                    <span  *ngIf="property.value.label.value==true" >
                           <span >
                             <div class = "labels">
                             <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
                           <mat-radio-group
                           class="radio-container">
                           <mat-radio-button
                           
                         
                          
                           *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                           [value]="choice.name"
                           [checked]="choice.name == property.value.itemvalue"
                           (change)="changeRadio(choice, i)"
                           >
                             {{choice.name}}
                           </mat-radio-button>
                         </mat-radio-group>
                        
                       </span>
                     </span>
                  
                      </div>
        
                      <div *ngIf="property.value.formtype.value == 'treelist'  ">
                     
                        <span *ngIf="property.value.label.value==true"  class= "treecontainer">
               <span class = "labels">
               <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
        
            
             <mat-chip-list>
        
               
               <mat-chip *ngFor="let paths of this.sortPath()"
               (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
               >
                 {{paths.name}}
           </mat-chip>
           </mat-chip-list>
        
         
             <div *ngIf="this.clickchoiceset == true" class="outerbox">  
               <span  >
               <table mat-table [dataSource]="this.tagerSvc.activetree"  >
               
                 <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                 (click)="this.getChoicetree(selectedrow._id); 
                
                 
                 this.hlchoice(selectedrow,  i);
                 "
                 [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                 class="innerbox"
                 ></tr>
               
               
                          
               <ng-container matColumnDef="Name" >
                     <th mat-header-cell *matHeaderCellDef>Name </th>
                  <td mat-cell *matCellDef="let element">
                   <span *ngIf="element.haschild">  
                     <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                     </span>
                      {{element.name}}
                </td></ng-container>
               
                  </table> </span>
        </div>
        
        
        
        
        </span>
                   </div>
        
        
                                         
                                  </div> 
                  </div>
                  
                 
                </span>
                   </form>
          
                   <span class = "actions">
        
               
        
                   <span *ngIf="tabindex == this.configSvc.nodeds[0].stepper.length-1" >
               
      
                    <span *ngIf="this.configSvc.nodeds[0].type == 'activeflow'">  <button  [disabled]= "this.configSvc.nodeds[0].status == 2" (click)="this.nextPhase();"
                mat-raised-button color="primary" >Finish</button></span>

                <span *ngIf="tabindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'nodetab' && createtype == 'manual'">
                  <button mat-button 
                  mat-raised-button color="primary"  (click)="saveStepper('tabbed')">Save Tabbed Form</button>
                  </span>

                  <span *ngIf="tabindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'nodetab' && createtype == 'assign'">
                    <button mat-button matStepperNext matSuffix 
                    mat-raised-button color="primary"  (click)="saveStepperassign('assign', 'tabbedassign')">Assign Tabbed Form {{this.configSvc.nodeds[0].name}}</button>
                    </span>


                    <span *ngIf="tabindex == this.configSvc.nodeds[0].stepper.length-1 && this.configSvc.nodeds[0].type == 'edittabbedassign'">
                      <button mat-button matStepperNext matSuffix 
                      mat-raised-button color="primary"  (click)="saveStepperassign('launch', 'tabbedassign')">Launch Tabbed form {{this.configSvc.nodeds[0].name}}</button>
                      </span>


                      <span>
                        <button mat-button  
                        mat-raised-button color="primary" (click)="this.dialogRef.close()">Cancel </button>
                        </span>
            
                    </span>
        
                    
                        </span>
                   </mat-tab>
          </mat-tab-group>
        </mat-card>
        </mat-step>
        </mat-horizontal-stepper>

      </div>
      
      <div *ngIf="this.configSvc.nodeds[0].type == 'batchitems'">
        batchitems
        <div class = "smartfolder-container">
          <span class = "smart-table">
              <table mat-table [dataSource]="this.configSvc.subfolders " >
                  <tr mat-header-row *matHeaderRowDef="activeColumns; sticky: true" ></tr>
          
                  <tr mat-row *matRowDef="let element; columns: activeColumns; let itemindex = index;"
                  
                (click)="this.selectSubitem(element, itemindex)"
                [style.background]=" this.hlsubitem.indexOf(element) == 0 ? 'lightblue' : ''"
                  ></tr>
          
                  <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef  width ="30px" ></th>
               
                      <td mat-cell *matCellDef="let element">
                          
                        
          
                              <div>
                                  <i class="material-icons md-24" [ngStyle]="{'color': element.iconcolor}">{{element.icontype}}</i>
                                  
                                  </div>
                          </td>
          
                      </ng-container>
          
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef  width ="30px" ></th>
                   
                          <td mat-cell *matCellDef="let element">
                              
                              <div>
                                  {{element.name}}
                                  
                                  </div>
                              </td>
              
          
                          
                          </ng-container>
              </table>

              <button mat-raised-button color="primary" (click)="batchitems(this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0]._id, this.configSvc.nodeds[0].indextype)">Create {{this.configSvc.nodeds[0].name}}</button>
          </span>
      </div>
      </div>
        
      
      </span>

    
  
      <span class= "actions">

      
    

    <span *ngIf="data.type == 'smartfolder' && this.stepIndex == 3 ">
      <button mat-raised-button color="primary" (click)="createFormfolder()">Create Form Folder</button>
     
      <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
  
    </span> 

    <span *ngIf="data.type == 'smartfolder'">
      <button mat-raised-button color="primary" (click)="createStepperfolder()">Create Stepper Folder</button>
     
      <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
     
    </span> 

    <span *ngIf="data.type == 'smartfoldertab'">
      <button mat-raised-button color="primary" (click)="createTabbedfolder()">Create Tabbed Folder</button>
     
      <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
     
    </span> 


    <span *ngIf="data.type == 'foldertags'">

    <div>Identify tags on: {{this.configSvc.activename}}:
      <span *ngIf="chosentype=='none'"><i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >folder</i></span>
      <span *ngIf="chosentype=='coloredfolder'"><i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >folder</i></span>
      <span *ngIf="chosentype=='bookmarks'"><i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >bookmarks</i></span>
      
    </div> 

    
      <mat-radio-group
      [(ngModel)]="chosentype"
      class="radio-group">
      <mat-radio-button class="radio-button" 
      *ngFor="let type of foldertypes"
      [value]="type"
      
      >
        {{type}}
      </mat-radio-button>
    </mat-radio-group>
      <button mat-raised-button color="primary" (click)="appyTags()">Apply Tags to {{this.configSvc.activename}}</button>
      <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
  </span> 

  <span *ngIf="!this.configSvc.folderdetailsds.kanbantagged">
  <span *ngIf="data.type == 'kanban'">
   
   <em> Apply Kanban to Folder {{this.configSvc.activename}}</em>
    <span class = "action-container">
      <span class = "button-container">
  <button mat-raised-button color="primary" (click)="applyKanban()">Apply</button></span>
  <span class = "button-container"> <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
    </span>
  </span> 
  
  
  </span>
</span>
    

  
     
</span>
</span>