<span [formGroup]="editlistFG" class = "details-container">
           
    <div>
     


        <mat-form-field appearance="fill" floatLabel="always" > 
          <mat-label>ListName</mat-label>
              <input formControlName="setname" matInput value="{{this.tagerSvc.setdetailsds[0].name}}">
             </mat-form-field>
      
      </div>
    

      <div><mat-form-field appearance="outline" floatLabel="always" >
        
          <mat-label >Added By</mat-label><input matInput [readonly]= "true" matInput value={{this.tagerSvc.setdetailsds[0].ab}}>
        </mat-form-field> </div>

        <div><mat-form-field appearance="outline" floatLabel="always" >
        
          <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
          [ngModelOptions]="{standalone: true}"
          [ngModel]="this.tagerSvc.setdetailsds[0].ao | date: 'medium'"
          >
        </mat-form-field> </div>


                      

   
      <div> <mat-form-field appearance="outline" floatLabel="always" >
          <mat-label>SetID</mat-label><input  matInput placeholder={{this.tagerSvc.setdetailsds[0]._id}}>
       </mat-form-field></div>

       <div> <button  [disabled]= "!this.editlistFG.dirty" (click)="this.renameSet(this.editlistFG.value.setname);"
        mat-raised-button color="primary" [disabled]="!editlistFG.dirty">Apply Changes</button>
     </div>
     
     
     
      </span>