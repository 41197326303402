import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


export interface UserDetails {

  _id: string;
  email: string;
  username: string;
  exp: number;
  iat: number;
  companyname: string;
}



interface TokenResponse {
  token: string;
}

export interface TokenPayload {
  username: string;
  password: string;
  name?: string;
}



@Injectable()
export class AuthenticationService {
  private token: string;
  public tagteamid;
  public nodeteamid;
  public choiceseturl;
  public getsearch;
  public pathid;
  public cachedchoices = [];
  public choicesetids = []  ;


  constructor(private http: HttpClient, private router: Router) {}

  private saveToken(token: string): void {
      // console.log('tring to save token:' + token);
    localStorage.setItem('tager-token', token);
    this.token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('tager-token');
     // // console.log('getToklen is:' + this.token);
    }
    return this.token;
  }
// the user details are stored in the token payload so this extracts the info and returns it as a json object
  public getUserDetails(): UserDetails {
    const token = this.getToken();
    // // console.log('token is:' + token);
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
    
      return JSON.parse(payload);
    } else {
      return null;
    }
  }



  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
   // // console.log('user details are:' + JSON.stringify(user));
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }
// this is the key component that communicates with the backend api
  private request(method: 'post'|'get', type: 'login'|'register'| 'registerCompany' | 'registerUser' | 'resetPword' |  'sendpwordEmail' |  'completeregistration' | 'profile'| 'updatePrefs' | 'addToolfavs' | 'addBookmark' | 'moveItem' | 'removeToolfavs' |'tagteamid'| 'getsearch' | 'getpath' | 'docbatch' |'download'| 'sendtojae' | 'addNodefiles' |
   'searchdocs' | 'searchAI' | 'addcomment' | 'getcomments'|  'clearNotification' | 'clearComment' | 'searchdocid' | 'searchattach' | 'searchconfig' |  'getbasetasks' | 'addproject' | 'saveSearch' | 'fileReport' | 'attachNode' | 'updateToolfavs' | 'editnewImage' | 'editexistingImage' |
    'getdochist' | 'getsearchhist' | 'getFoldertags' | 'getSystemColumns' | 'getCustomColumns' | 'getTeam' | 'addlevel1Task' | 'createTaskset' | 'createProject'| 'addFolderTask' | 'updateTagitems' | 'createImage' |
   'searchdoctype' |'gettaskonomyLevel1'| 'getTask' | 'clickcategory'|'addCategory'| 'getCategories' |'getTagerbyCategory' | 'createTeam'| 'createandjoinTeam' | 'addSubcategory'|'addtask'|'addFileTask'| 'addFormtoFolder' | 'clearDeadline' |
   'addsectag'|'addgroup'|'clicksubcat' | 'getSelectedtaskonomy' | 'getFile' | 'getTagtemplate' | 'getTags' | 'getVersions' | 'refreshFolder' |'getFileTasks' | 'processTask' | 'inviteUser' | 'removeUser' | 'lockToolbar' | 'unlockToolbar' | 'removeKanban' |
   'updatePos' | 'getTaskset' | 'getFileQuestions'| 'answerQuestion' | 'getFileComments' | 'archiveQuestion' | 'getArchivedQuestions' | 'createKanbantemplate'| 'applyKanban' | 'removeteammgr' | 'addsetmgr' | 'foldertagsFilter' | 'removefromFlow' | 'getImagebyver' |
   'configupload'| 'searchuserclick' | 'bulkprocess'  | 'saveNodeimage'  | 'getsavedSearch' | 'applynodeKanban' |  'applySmartfoldertag' | 'getSubitem' | 'updateBatchitem' | 'batchitems' | 'updateNodeimage' | 'getImagebyid' | 'getLatestphoto' |'imagevers' | 'searchgroupclick' | 'searchmembershipclick' | 'getusers' | 'getgroups' |'adduser' | 'selTasksetitem' | 'getTagitems' | 'findChoiceset' | 'getChoicesetbyid' | 'addnodeteammgr' |
   'findUser' | 'findTeam' | 'findTemplate' | 'searchallTemplates' | 'findFoldertags' | 'findSmartfolder' |  'findKanban' | 'addtoFlow' | 'updateAssignment' |  'clearAssignment' |  'setDeadline' | 'setState' | 'setAssignedto' | 'updateNodeAssignment' | 'moveFlowitem' | 'moveTagitem' | 'moveTrackitem' | 'folderFilter' | 'usernameFilter' | 'copyDesign' | 'getFlowitembyid' | 'deleteProject' | 'deleteItem'| 'chooseProject'| 'chooseTask' |'saveProject' |'addFileQuestion' | 'getInbox' | 'selectWork' | 'findDatatags'| 'findstepset' |'findTagItem' |
   'startTaskset' | 'updateTaskset' | 'addtoProject' | 'updateWorkitem' | 'addFolder' |  'addTeamTagFolder'| 'createTagset'| 'getTagitem' | 'getChoiceset' | 'getChoiceitem'| 'getNodeTeamDetails' | 'getNodeDetails' | 'getItembyid' | 'addteammgr' | 'getsetDetails' |'updateFoldertags'| 'updateNodetags'| 'toolbarFilter' | 'deleteChoiceitem' |
   'createTagitem' | 'createListitem' | 'getToolbars' | 'getfavToolbars' | 'getfavLocations' | 'saveNode' | 'askChatgpt' |  'askToolbard' | 'saveFormassign' | 'reTriggerflow' | 'assignNode' | 'saveStepperassign' | 'saveStepper' | 'applyTags' | 'updateStepper' | 'updateTagitem' | 'deleteTagitem' | 'createChoiceset' |'createChoiceitem' | 'renameFolder' | 'getFolderDetails' | 'applyTemplate' |'getTeamDetails' | 'addteamuser' | 'addnodeteamuser' | 'removeteamuser' |
   'changeteamcolor' | 'editFile' | 'uploaddocver' | 'deleteFile' | 'deleteDesign' | 'deleteFolder' | 'getKanban' | 'findTager' | 'findKanbanItem' | 'createTager' | 'getTracks' | 'createTrack' | 'getTager' | 'nextPhase' | 'prevPhase' | 'updateFoldertabs' | 'deleteStep' | 'findSmartfolderitems' |
    'getKanbantemplate' | 'moveKanban' | 'movePosition' | 'updateKanitem' |'findFormItem' | 'findFormChoiceset' | 'createStepset' | 'createStepelement' | 'createFormChoiceset' | 'createFormChoiceitem' | 'getStepitems' | 'getActiveflow' | 'ratePrompt' |
    'getFormChoiceset' | 'getFormChoiceitem' | 'getFormChoices' | 'applyFormTemplate' | 'getFormsteps' | 'createStep' | 'applyIcon' | 'applyTagcolor' | 'getformPreview' | 'launchTagerseq' | 'launchFlow' | 'getActiveform' | 'getPromptlist' |
    'updateForm' | 'createTagChoiceset' | 'unassignChoiceset' | 'applyTagChoiceset' | 'applyChoiceset'| 'getTracksec' | 'applyTracksec' | 'applyStepset' | 'getStepsetbyid' | 'finishTager' | 'createSubfolder' | 'addffItem'
    | 'updateNode' | 'updateTeam' | 'editTrack' | 'updateStep' | 'applytagertagChoiceset' | 'gettagertagPreview' | 'applyTagertag' | 'assignKey' | 'saveTageritem' | 'getCatpath' | 'getNodebyid' |'nodeTeam' | 'getNodepath' | 'getChoicepath' | 'getFlowDetails' | 'getQueueDetails'
    | 'createapplyChoiceset' | 'getActivetager' |'moveTrack' | 'getFavs' | 'addFavs' | 'createCardbyid' | 'createStepsbyid' |'findTags' | 'getCategoryDetails'  | 'getKanbanDetails'| 'createNode' | 'createFlow' | 'addTrack' | 'createIndexer'
    | 'addStep' | 'createLinktab',
 formdata?: FormData,  user?: TokenPayload): Observable<any> {
    let base;
    // console.log('type is: ' + type);

    if (method === 'post' && type === 'login' || type === 'register' || type == 'registerCompany') {
      // console.log('this is a login register or profile url');
      base = this.http.post(`/api/${type}`, user);
      // console.log('posting back the results after:' + JSON.stringify(user));
     // // console.log('base is equal to before:' + JSON.stringify(base));
    }

    if (method === 'post' && type == 'registerUser' || type === 'resetPword' || type ===  'sendpwordEmail' ) {
   
      base = this.http.post(`/api/${type}`, formdata);
    
    }


   

    if (method === 'post' && type === 'createTagset') {
      // console.log('this is a createTagset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updatePrefs') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'addToolfavs') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'addBookmark') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    
    if (method === 'post' && type === 'saveSearch') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'moveItem') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }


    if (method === 'post' && type === 'removeToolfavs') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateToolfavs') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createTager') {
      // console.log('this is a createTager url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createNode') {
      // console.log('this is a createNode url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createFlow') {

      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getQueueDetails') {

      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createIndexer') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createSubfolder') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'addffItem') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'addTrack') {
      // console.log('this is a addTrack url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

  

    if (method === 'post' && type === 'addStep') {
      // console.log('this is a addStep url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createLinktab') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }


    if (method === 'post' && type === 'createTagitem') {
      // console.log('this is a createTagitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createListitem') {
      // console.log('this is a createListitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getToolbars') {
      // console.log('this is a getToolbars url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getfavToolbars') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getfavLocations') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'saveNode') {
      // console.log('this is a saveNode url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    
    if (method === 'post' && type === 'askChatgpt') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'askToolbard') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'saveFormassign') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'reTriggerflow') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'assignNode') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'saveStepperassign') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'saveStepper') {
      // console.log('this is a saveStepper url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'applyTags') {
      // console.log('this is a applyTags url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateStepper') {
      // console.log('this is a updateStepper url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateFoldertabs') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateTagitem') {
      // console.log('this is a updateTagitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'deleteTagitem') {
      // console.log('this is a deleteTagitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'deleteStep') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createChoiceset') {
      // console.log('this is a createChoiceset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createapplyChoiceset') {
      // console.log('this is a createapplyChoiceset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }


    if (method === 'post' && type === 'createChoiceitem') {
      // console.log('this is a createChoiceitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'deleteChoiceitem') {
      // console.log('this is a deleteChoiceitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findDatatags') {
      // console.log('this is a findDatatags url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findstepset') {
      // console.log('this is a findstepset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateNode') {
      // console.log('this is a updateNode url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateTeam') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'editTrack') {
      // console.log('this is a editTrack url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'updateStep') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'finishTager') {
      // console.log('this is a finishTager url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'launchTagerseq') {
      // console.log('this is a launchTagerseq url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'launchFlow') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getActiveflow') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'nextPhase') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'prevPhase') {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findTager') {
      // console.log('this is a findTager url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findTagItem') {
      // console.log('this is a findTagItem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findKanbanItem') {
      // console.log('this is a findKanbanItem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findChoiceset') {
      // console.log('this is a findChoiceset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getChoicesetbyid') {
      // console.log('this is a getChoicesetbyid url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getpath') {
      // console.log('this is a getpath url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    
    if (method === 'post' && type === 'getNodepath') {
      // console.log('this is a getNodepath url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getChoicepath') {
      // console.log('this is a getChoicepath url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getTagitem') {
      // console.log('this is a getTagitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'findTags') {
      // console.log('this is a findTags url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getTracks') {
      // console.log('this is a getTracks url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getTager') {
      // console.log('this is a getTager url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getCatpath') {
      // console.log('this is a getCatpath url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getTracksec') {
      // console.log('this is a getTracksec url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'createTrack') {
      // console.log('this is a createTrack url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getChoiceset') {
      // console.log('this is a getChoiceset url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getChoiceitem') {
      // console.log('this is a getChoiceitem url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getPromptlist') {

      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    if (method === 'post' && type === 'getTagitems') {
      // console.log('this is a getTagitems url');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

      if (method === 'post' && type === 'docbatch'  ) {
        // console.log('this is drag and drop post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addNodefiles'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      

      if (method === 'post' && type === 'createImage'  ) { 
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
    }
    // had to move the response type out of the heders to ensure response is blob and not json took ages to figure out
    , responseType: 'blob'
   });
    }

      if (method === 'post' && type === 'attachNode'  ) {

        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
   
      }

      if (method === 'post' && type === 'fileReport'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'editnewImage'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'editexistingImage'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'uploaddocver'  ) {
        // console.log('this is uploaddocver post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'bulkprocess'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'saveNodeimage'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getsavedSearch'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applynodeKanban'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applySmartfoldertag'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getSubitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateBatchitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'batchitems'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateNodeimage'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'lockToolbar'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'unlockToolbar'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'removeKanban'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getLatestphoto'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getImagebyid'  ) { base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
      }
      // had to move the response type out of the heders to ensure response is blob and not json took ages to figure out
      , responseType: 'blob'
     });
      }

      if (method === 'get' && type === 'imagevers'  ) {
  
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
      }
      // had to move the response type out of the heders to ensure response is blob and not json took ages to figure out
      , responseType: 'blob'
     });
      }

      if (method === 'post' && type === 'getImagebyver'  ) {
  
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
      }
      // had to move the response type out of the heders to ensure response is blob and not json took ages to figure out
      , responseType: 'blob'
     });
      }

      if (method === 'post' && type === 'deleteFile'  ) {
        // console.log('this is deleteFile post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'deleteDesign'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'deleteFolder'  ) {
        // console.log('this is deleteFolder post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }


      if (method === 'post' && type === 'addcomment') {
        // console.log('this is addcomment post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getcomments' ) {
     base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});

      }

      if (method === 'post' && type === 'clearNotification' ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
         }

         if (method === 'post' && type === 'clearComment' ) {
          base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
           }

      if (method === 'post' && type === 'download' ) {
        // console.log('this is  download  post request:');
      // base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`}});
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
         // had to move the response type out of the heders to ensure response is blob and not json took ages to figure out
         , responseType: 'blob'
        });
         // console.log('download base is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchdocs' ) {
        // console.log('this is  search  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchAI' ) {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`} });
      
      }

      if (method === 'post' && type === 'getdochist' ) {
        // console.log('this is  search  history post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
        //  // console.log('search hist is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getsearchhist' ) {
        // console.log('this is  search  history post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
        //  // console.log('search hist is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getFoldertags' ) {
       base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }  });}

      if (method === 'post' && type === 'searchdocid' ) {
        // console.log('this is  search doc id  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchattach' ) {
        // console.log('this is  search attach  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchconfig' ) {
        // console.log('this is  search config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getbasetasks' ) {
        // console.log('this is  getbasetasks config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchdoctype' ) {
        // console.log('this is  search doctype  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'gettaskonomyLevel1' ) {
        // console.log('this is  search doctype click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getTask' ) {
        // console.log('this is  getTask post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'clickcategory' ) {
        // console.log('this is  search doc property click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'clicksubcat' ) {
        // console.log('this is  search choice list click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchuserclick' ) {
        // console.log('this is  search user click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchgroupclick' ) {
        // console.log('this is  search group click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
        // // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'searchmembershipclick' ) {
        // console.log('this is  search membership click post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('search is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addproject' ) {
        // console.log('this is  search config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add doc type is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addCategory' ) {
        // console.log('this is  search config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('addcategory is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getCategories' ) {
        // console.log('this is  search config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('getCategories is:' + JSON.stringify(base));
      }

      
      if (method === 'post' && type === 'getTagerbyCategory' ) {
        // console.log('this is  search getTagerbyCategory  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('getTagerbyCategory is:' + JSON.stringify(base));
      }


     


      if (method === 'post' && type === 'addSubcategory' ) {
        // console.log('this is  add choice list config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add choicelist is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addtask' ) {
        // console.log('this is  add task config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add task is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addFileTask' ) {
        // console.log('this is  addFileTask config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('addFileTask is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addFolderTask' ) {
        // console.log('this is  addFolderTask config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('addFolderTask is:' + JSON.stringify(base));
      }

      
      if (method === 'post' && type === 'addFormtoFolder' ) {
        // console.log('this is  addFormtoFolder config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('addFormtoFolder is:' + JSON.stringify(base));
      }


      if (method === 'post' && type === 'addFileQuestion' ) {
        // console.log('this is  add question config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add question is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'archiveQuestion' ) {
        // console.log('this is  archiveQuestion   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add question is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'getArchivedQuestions' ) {
        // console.log('this is  getArchivedQuestions   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add question is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'applyTagertag' ) {
        // console.log('this is  applyTagertag   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('applyTagertag is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'assignKey' ) {
   
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }});}

      if (method === 'post' && type === 'saveTageritem' ) {
        // console.log('this is  saveTageritem   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('saveTageritem is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'createKanbantemplate' ) {
        // console.log('this is  createKanbantemplate   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }});// console.log('createKanbantemplate is:' + JSON.stringify(base));
      }

    

      if (method === 'post' && type === 'applyTracksec' ) {
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         
      }

      if (method === 'post' && type === 'applyStepset' ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
           }}); }

      if (method === 'post' && type === 'applyKanban' ) {
        // console.log('this is  applyKanban   post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('applyKanban is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'updateTaskset' ) {
        // console.log('this is  updateTaskset  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add question is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addtoProject' ) {
        // console.log('this is  addtoProject  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add question is:' + JSON.stringify(base));
      }


      if (method === 'post' && type === 'addsectag' ) {
        // console.log('this is  add security tag config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add choicelist is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'addgroup' ) {
        // console.log('this is  group add config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add group is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'adduser' ) {
        // console.log('this is  user add config  post request:');
      base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}`
         }
        });
         // console.log('add user is:' + JSON.stringify(base));
      }


      if (method === 'post' && type === 'sendtojae'  ) {
        // console.log('this is sendtojae post request:');
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
        // // console.log('base value is:' + JSON.stringify(base));
      }

      if (method === 'post' && type === 'configupload'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getusers'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getgroups'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findUser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findTeam'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findTemplate'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'searchallTemplates'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findFoldertags'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findSmartfolder'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findKanban'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addtoFlow'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateAssignment'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'clearAssignment'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'clearDeadline'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'setDeadline'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'setState'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'setAssignedto'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateNodeAssignment'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'removefromFlow'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'moveFlowitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'moveTagitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'moveTrackitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'folderFilter'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'toolbarFilter'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'usernameFilter'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'foldertagsFilter'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'copyDesign'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFlowitembyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'deleteProject'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getSystemColumns'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getCustomColumns'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'chooseProject'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getInbox'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'startTaskset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateWorkitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'selectWork'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'selTasksetitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      

      if (method === 'post' && type === 'chooseTask'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'deleteItem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'saveProject'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createTeam'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createandjoinTeam'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'nodeTeam'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addFolder'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addTeamTagFolder'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getTeam'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addlevel1Task'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createTaskset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createProject'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getSelectedtaskonomy'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFile'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getVersions'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getTagtemplate'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getKanbantemplate'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findFormItem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createStepset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createStep'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyIcon'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyTagcolor'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getformPreview'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'gettagertagPreview'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyTagertag'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'saveTageritem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createCardbyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createStepsbyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'profile'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateForm'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'moveTrack'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getActiveform'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getActivetager'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      
      if (method === 'post' && type === 'getNodebyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createStepelement'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createFormChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'createTagChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'unassignChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyTagChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applytagertagChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }


      if (method === 'post' && type === 'createFormChoiceitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFormsteps'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getStepsetbyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getStepitems'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'getFormChoiceset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'getFormChoiceitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'getFormChoices'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'applyFormTemplate'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'moveKanban'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'movePosition'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateKanitem'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getTags'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFavs'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addFavs'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getKanban'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'refreshFolder'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'ratePrompt'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFileTasks'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFileQuestions'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFileComments'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'answerQuestion'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'processTask'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updatePos'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getTaskset'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'renameFolder'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFolderDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'findSmartfolderitems'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getsetDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getCategoryDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getFlowDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getKanbanDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'applyTemplate'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateFoldertags'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }
      if (method === 'post' && type === 'updateNodetags'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

     

      if (method === 'post' && type === 'getTeamDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getNodeTeamDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'updateTagitems'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getNodeDetails'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getItembyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'getItembyid'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addteamuser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addteammgr'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addnodeteamuser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addnodeteammgr'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'addsetmgr'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'removeteamuser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'removeteammgr'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'removeUser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'inviteUser'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'changeteamcolor'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }

      if (method === 'post' && type === 'editFile'  ) {
        base = this.http.post(`/api/${type}`, formdata, { headers: { Authorization: `Bearer ${this.getToken()}` }});
      }


     

      if (method === 'get') {
      // // console.log('base is equal to after:' + JSON.stringify(base));
      // console.log('token is:' + JSON.stringify(this.getToken));
      base = this.http.get(`/api/${type}`, { headers: { Authorization: `Bearer ${this.getToken()}` }});
    }

    const request = base.pipe(
      map((data: TokenResponse) => {
        // // console.log('request data is:' + JSON.stringify( data));
        // // console.log('request data token is:' + JSON.stringify(data.token));
        if (data.token) {
            // console.log('trying to save token in the auth service');
            // console.log('data token is:' + JSON.stringify( data.token));
          this.saveToken(data.token);
        }
        // console.log('token data from authentication service request' + JSON.stringify( data));
        return data;
      })
    );
    // console.log('returing private request post request heree');
    // // console.log('requrest data is:' + JSON.stringify( request));
    return request;
  }

  public register(user: TokenPayload, formdata?: FormData): Observable<any> {
    return this.request('post', 'register', formdata, user);
  }

  public registerCompany(user: TokenPayload, formdata?: FormData): Observable<any> {
    return this.request('post', 'registerCompany', formdata, user);
  }

  public registerUser(formdata: FormData): Observable<any> {
    return this.request('post', 'registerUser', formdata);
  }

  public resetPword(formdata: FormData): Observable<any> {
    return this.request('post', 'resetPword', formdata);
  }

  public sendpwordEmail(formdata: FormData): Observable<any> {
    return this.request('post', 'sendpwordEmail', formdata);
  }




  public login(user: TokenPayload, formdata?: FormData): Observable<any> {
      // // console.log('authewntication service payload is' + JSON.stringify(user));
    return this.request('post', 'login', formdata, user);
  }



  public tagteam(url): Observable<any> {
    this.tagteamid = url;
    return this.request('get', this.tagteamid );
  }

  public imagevers(imageurl): Observable<Blob> {
  
    return this.request('get', imageurl );
  }

  

  

  public getQueues(url): Observable<any> {
    return this.request('get', url );
  }

  public getPreferences(url): Observable<any> {
    return this.request('get', url );
  }

  public myFeed(url): Observable<any> {
    return this.request('get', url );
  }

 

  public nodeteam(url): Observable<any> {
    this.nodeteamid = url;
    return this.request('get', this.nodeteamid );
  }

  public choiceset(url): Observable<any> {
    this.choiceseturl = url;
    return this.request('get', this.choiceseturl );
  }

  public searchurl(searchurl): Observable<any> {
    this.getsearch = searchurl;
    return this.request('get', this.getsearch );
  }

 
  public rootteam(url): Observable<any> {
   
    this.tagteamid = url;
    // console.log('this is the auth svc url'+url)
    return this.request('get', this.tagteamid );
  }

  public completeregistration(url): Observable<any> {
   
     console.log('this is the complete registration url'+url)
    return this.request('get', url );
  }

  public logout(): void {
    // this.router.navigateByUrl('/logout');
   this.router.navigateByUrl('/login');
    // console.log('logging out now');
    this.token = '';
    window.localStorage.removeItem('tager-token');
    window.localStorage.clear();
    this.cachedchoices = [];
    this.choicesetids = [];
    // console.log('cache for choicelists has bbeen cleare');

  }

  public upload(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'docbatch', formdata, user);
  }

  public createImage(imagedata: FormData, user: TokenPayload ): Observable<Blob> {
    return this.request('post', 'createImage', imagedata, user);
  }



  public addNodefiles(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addNodefiles', formdata, user);
  }

  public attachNode(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'attachNode', formdata, user);
  }

  public fileReport(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'fileReport', formdata, user);
  }

  public editnewImage(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'editnewImage', formdata, user);
  }

  public editexistingImage(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'editexistingImage', formdata, user);
  }

  public bulkprocess(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'bulkprocess', formdata, user);
  }

  public saveNodeimage(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'saveNodeimage', formdata, user);
  }

  public getsavedSearch(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getsavedSearch', formdata, user);
  }

  public applynodeKanban(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'applynodeKanban', formdata, user);
  }

  public applySmartfoldertag(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'applySmartfoldertag', formdata, user);
  }

  public getSubitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getSubitem', formdata, user);
  }

  public updateBatchitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateBatchitem', formdata, user);
  }

  public batchitems(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'batchitems', formdata, user);
  }


  public updateNodeimage(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateNodeimage', formdata, user);
  }

  public lockToolbar(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'lockToolbar', formdata, user);
  }

  public unlockToolbar(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'unlockToolbar', formdata, user);
  }

  public removeKanban(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'removeKanban', formdata, user);
  }



  public getImagebyid(formdata: FormData, user: TokenPayload ): Observable<Blob>  {
    return this.request('post', 'getImagebyid', formdata, user);
  }

  public getLatestphoto(formdata: FormData, user: TokenPayload ): Observable<any>  {
    return this.request('post', 'getLatestphoto', formdata, user);
  }


  

  public getpath(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('getpath observable running in auth service, returning post docbatch');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'getpath', formdata, user);
  }

  public getNodepath(formdata: FormData, user: TokenPayload ): Observable<any> {

     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'getNodepath', formdata, user);
  }

  public getChoicepath(formdata: FormData, user: TokenPayload ): Observable<any> {

    // console.log('authentication service payload is' + JSON.stringify(user));
   return this.request('post', 'getChoicepath', formdata, user);
 }


  public configupload(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'configupload', formdata, user);
  }

  public sendtojae(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending the data to jae for index processing');
    return this.request('post', 'sendtojae', formdata, user);
  }

  public downloaddoc( formdata: FormData, user: TokenPayload  ): Observable<Blob> {
   //  responseType: 'blob'
// console.log('auth service download doc, returning get download request is');
    return this.request('post', 'download', formdata, user);
  }

  public searchdocs(formdata: FormData,  user: TokenPayload): Observable<any> {
   // // console.log('authentication search underway for user: ' + JSON.stringify(user));
    return this.request('post', 'searchdocs', formdata, user);

  }

  public searchAI(formdata: FormData,  user: TokenPayload): Observable<any> {
    // // console.log('authentication search underway for user: ' + JSON.stringify(user));
     return this.request('post', 'searchAI', formdata, user);
 
   }

  public searchdocid(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for docid: ' + JSON.stringify(user));
    return this.request('post', 'searchdocid', formdata, user);

  }

  public searchattach(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for attachments: ' + JSON.stringify(user));
    return this.request('post', 'searchattach', formdata, user);

  }

  public searchconfig(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for config data: ' + JSON.stringify(user));
    return this.request('post', 'searchconfig', formdata, user);

  }

  public getbasetasks(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for base tasks: ' + JSON.stringify(user));
    return this.request('post', 'getbasetasks', formdata, user);

  }


  public getTeam(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getTeam tasks: ' + JSON.stringify(user));
    return this.request('post', 'getTeam', formdata, user);

  }

  public getSelectedtaskonomy(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getSelectedtaskonomy tasks: ' + JSON.stringify(user));
    return this.request('post', 'getSelectedtaskonomy', formdata, user);

  }

  public getFile(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFile in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFile', formdata, user);

  }

  public getVersions(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getVersions in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getVersions', formdata, user);

  }

  public getTagtemplate(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getTagtemplate in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getTagtemplate', formdata, user);
  }

  public findFormItem(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('findFormItem in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'findFormItem', formdata, user);
  }

  public findFormChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('findFormChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'findFormChoiceset', formdata, user);
  }
  public createStepset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createStepset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createStepset', formdata, user);
  }

  public createStep(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createStep in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createStep', formdata, user);
  }

  public applyIcon(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyIcon in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyIcon', formdata, user);
  }

  public applyTagcolor(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyTagcolor in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyTagcolor', formdata, user);
  }

  public getformPreview(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getformPreview in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getformPreview', formdata, user);
  }

  public gettagertagPreview(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('gettagertagPreview in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'gettagertagPreview', formdata, user);
  }

  public applyTagertag(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyTagertag in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyTagertag', formdata, user);
  }

  public assignKey(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyTagertag in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'assignKey', formdata, user);
  }

  public saveTageritem(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('saveTageritem in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'saveTageritem', formdata, user);
  }

  public createCardbyid(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createCardbyid in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createCardbyid', formdata, user);
  }

  public createStepsbyid(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createStepsbyid in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createStepsbyid', formdata, user);
  }
  

  public getActiveform(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getActiveform in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getActiveform', formdata, user);
  }

  public getActivetager(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getActivetager in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getActivetager', formdata, user);
  }

  public getNodebyid(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getNodebyid in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getNodebyid', formdata, user);
  }

  public updateForm(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('updateForm in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'updateForm', formdata, user);
  }

  public moveTrack(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('moveTrack in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'moveTrack', formdata, user);
  }

  public createStepelement(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createStepelement in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createStepelement', formdata, user);
  }

  public createFormChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createFormChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createFormChoiceset', formdata, user);
  }

  public createTagChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createTagChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createTagChoiceset', formdata, user);
  }

  public unassignChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('unassignChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'unassignChoiceset', formdata, user);
  }

  public applyTagChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyTagChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyTagChoiceset', formdata, user);
  }

  public applyChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyChoiceset', formdata, user);
  }

  public applytagertagChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applytagertagChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applytagertagChoiceset', formdata, user);
  }

  public createFormChoiceitem(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('createFormChoiceitem in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'createFormChoiceitem', formdata, user);
  }

  public getFormsteps(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFormsteps in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFormsteps', formdata, user);
  }

  public getStepsetbyid(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getStepsetbyid in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getStepsetbyid', formdata, user);
  }
  public getStepitems(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getStepitems in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getStepitems', formdata, user);
  }
  public getFormChoiceset(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFormChoiceset in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFormChoiceset', formdata, user);
  }
  public getFormChoiceitem(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFormChoiceitem in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFormChoiceitem', formdata, user);
  }
  public getFormChoices(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFormChoices in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFormChoices', formdata, user);
  }
  public applyFormTemplate(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('applyFormTemplate in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'applyFormTemplate', formdata, user);
  }
  public getKanbantemplate(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getKanbantemplate in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getKanbantemplate', formdata, user);
  }


  public moveKanban(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('moveKanban in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'moveKanban', formdata, user);
  }

  public movePosition(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('movePosition in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'movePosition', formdata, user);
  }

  public updateKanitem(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('updatekiWorker in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'updateKanitem', formdata, user);
  }


  public getTags(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getTags in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getTags', formdata, user);

  }

  public getKanban(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getKanban in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getKanban', formdata, user);

  }

  public getFavs(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFavs in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFavs', formdata, user);

  }

  public addFavs(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('addFavs in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'addFavs', formdata, user);

  }

  public refreshFolder(formdata: FormData,  user: TokenPayload): Observable<any> {
    return this.request('post', 'refreshFolder', formdata, user);
  }

  public ratePrompt(formdata: FormData,  user: TokenPayload): Observable<any> {
    return this.request('post', 'ratePrompt', formdata, user);
  }

  

  public getFileTasks(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFileTasks in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFileTasks', formdata, user);

  }

  public getFileQuestions(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFileQuestions in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFileQuestions', formdata, user);

  }

  public getFileComments(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('getFileComments in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'getFileComments', formdata, user);

  }

 

  public processTask(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('processTask in auth svc: ' + JSON.stringify(user));
    return this.request('post', 'processTask', formdata, user);

  }

  public getdochist(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for doc history: ' + JSON.stringify(user));
    return this.request('post', 'getdochist', formdata, user);

  }

  public getsearchhist(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for search history: ' + JSON.stringify(user));
    return this.request('post', 'getsearchhist', formdata, user);

  }

  public getFoldertags(formdata: FormData,  user: TokenPayload): Observable<any> {
    return this.request('post', 'getFoldertags', formdata, user);
  }

  public getusers(usersForm: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for users data: ' + JSON.stringify(user));
    return this.request('post', 'getusers', usersForm, user);

  }

  public adduser(adduserForm: FormData,  user: TokenPayload): Observable<any> {
    // console.log('adding users data: ' + JSON.stringify(user));
    return this.request('post', 'adduser', adduserForm, user);

  }


  public getgroups(groupsForm: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for groups data: ' + JSON.stringify(user));
    return this.request('post', 'getgroups', groupsForm, user);

  }

  public searchdoctype(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for doctype data: ' + JSON.stringify(formdata));
    return this.request('post', 'searchdoctype', formdata, user);
  }

  public getSystemColumns(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for SystemColumns: ');
    return this.request('post', 'getSystemColumns', formdata, user);
  }

  public getCustomColumns(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for CustomColumns');
    return this.request('post', 'getCustomColumns', formdata, user);
  }

  public gettaskonomyLevel1(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for doctype data after clicking: ');
    return this.request('post', 'gettaskonomyLevel1', formdata, user);
  }

  public getTask(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for task data after clicking: ');
    return this.request('post', 'getTask', formdata, user);
  }

  public clickcategory(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for field data after clicking ');
    return this.request('post', 'clickcategory', formdata, user);
  }

  public clicksubcat(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for security tag data after clicking choicelist ');
    return this.request('post', 'clicksubcat', formdata, user);
  }

  public searchuserclick(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for user data after clicking user ');
    return this.request('post', 'searchuserclick', formdata, user);
  }

  public searchgroupclick(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for group data after clicking user ');
    return this.request('post', 'searchgroupclick', formdata, user);
  }

  public searchmembershipclick(formdata: FormData,  user: TokenPayload): Observable<any> {
    // console.log('searching for membership data after clicking user ');
    return this.request('post', 'searchmembershipclick', formdata, user);
  }

  public addcomment(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding comment in auth service, returning post addcomment');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addcomment', formdata, user);
  }

  public addProject(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding doctype in auth service, returning post addProject');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addproject', formdata, user);
  }

  public addTask(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding task in auth service, returning post addtask');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addtask', formdata, user);
  }

  public addFileTask(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding addFileTask in auth service, returning post addFileTask');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addFileTask', formdata, user);
  }

  public addFolderTask(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding addFolderTask in auth service, returning post addFolderTask');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addFolderTask', formdata, user);
  }

  public addFormtoFolder(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding addFormtoFolder in auth service, returning post addFormtoFolder');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addFormtoFolder', formdata, user);
  }

  public createTaskset(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('createTaskset in auth service, returning post createTaskset');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'createTaskset', formdata, user);
  }

  public createProject(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('createProject in auth service, returning post createProject');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'createProject', formdata, user);
  }

  public addlevel1Task(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('addlevel1Task in auth service, returning post addlevel1Task');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addlevel1Task', formdata, user);
  }

  public addCategory(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding doctype in auth service, returning post addCategory');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addCategory', formdata, user);
  }

  public getCategories(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding doctype in auth service, returning post getCategories');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'getCategories', formdata, user);
  }
  
  public getTagerbyCategory(formdata: FormData, user: TokenPayload ): Observable<any> {
      // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'getTagerbyCategory', formdata, user);
  }

  public createTeam(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding createTeam, returning post createTeam');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'createTeam', formdata, user);
  }

  public createandjoinTeam(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'createandjoinTeam', formdata, user);
  }

  public nodeTeam(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding nodeTeam, returning post nodeTeam');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'nodeTeam', formdata, user);
  }

  public addFolder(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding addFolder, returning post addFolder');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addFolder', formdata, user);
  }

  public addTeamTagFolder(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addTeamTagFolder', formdata, user);
  }

  public addSubcategory(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding choicelist in auth service, returning post addSubcategory');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addSubcategory', formdata, user);
  }

  public addsectag(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding sectag in auth service, returning post addsectag');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addsectag', formdata, user);
  }

  public addgroup(formdata: FormData, user: TokenPayload ): Observable<any> {
    // console.log('adding group in auth service, returning post addgroup');
     // console.log('authentication service payload is' + JSON.stringify(user));
    return this.request('post', 'addgroup', formdata, user);
  }

  public getComments(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getcomments', formdata, user);
  }

  public clearNotification(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'clearNotification', formdata, user);
  }

  public clearComment(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'clearComment', formdata, user);
  }

  public findUser(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findUser', formdata, user);
  }

  public findTeam(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findTeam', formdata, user);
  }

  public findTemplate(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findTemplate', formdata, user);
  }

  public searchallTemplates(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'searchallTemplates', formdata, user);
  }

  public findFoldertags(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findFoldertags', formdata, user);
  }

  public findSmartfolder(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findSmartfolder', formdata, user);
  }

  public findKanban(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findKanban', formdata, user);
  }

  public addtoFlow(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addtoFlow', formdata, user);
  }

  public moveFlowitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'moveFlowitem', formdata, user);
  }

  public updateAssignment(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateAssignment', formdata, user);
  }

  public clearAssignment(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'clearAssignment', formdata, user);
  }

  public clearDeadline(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'clearDeadline', formdata, user);
  }

  public setDeadline(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'setDeadline', formdata, user);
  }
  
  public setState(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'setState', formdata, user);
  }
  public setAssignedto(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'setAssignedto', formdata, user);
  }

  public updateNodeAssignment(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateNodeAssignment', formdata, user);
  }

  public removefromFlow(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'removefromFlow', formdata, user);
  }

  public moveTagitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'moveTagitem', formdata, user);
  }

  public moveTrackitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'moveTrackitem', formdata, user);
  }

  public folderFilter(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'folderFilter', formdata, user);
  }

  public toolbarFilter(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'toolbarFilter', formdata, user);
  }

  public profile(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'profile', formdata, user);
  }

  public updatePrefs(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updatePrefs', formdata, user);
  }

  public addToolfavs(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addToolfavs', formdata, user);
  }
  
  public addBookmark(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addBookmark', formdata, user);
  }

  public saveSearch(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'saveSearch', formdata, user);
  }

  public moveItem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'moveItem', formdata, user);
  }

  public removeToolfavs(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'removeToolfavs', formdata, user);
  }

  public updateToolfavs(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateToolfavs', formdata, user);
  }

  public usernameFilter(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'usernameFilter', formdata, user);
  }

  public foldertagsFilter(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'foldertagsFilter', formdata, user);
  }

  public copyDesign(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'copyDesign', formdata, user);
  }

  public getFlowitembyid(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getFlowitembyid', formdata, user);
  }

  public deleteProject(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'deleteProject', formdata, user);
  }
  public deleteItem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'deleteItem', formdata, user);
  }

  public chooseProject(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'chooseProject', formdata, user);
  }

  public getInbox(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getInbox', formdata, user);
  }

  public startTaskset(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'startTaskset', formdata, user);
  }

  public updateWorkitem(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateWorkitem', formdata, user);
  }

  public chooseTask(formdata: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'chooseTask', formdata, user);
  }

  public saveProject(saveProjectform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'saveProject', saveProjectform, user);
  }

  public addFileQuestion(addFileQuestionform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addFileQuestion', addFileQuestionform, user);
  }

  public archiveQuestion(archiveQuestionform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'archiveQuestion', archiveQuestionform, user);
  }

  public getArchivedQuestions(getarchivedQuestionform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getArchivedQuestions', getarchivedQuestionform, user);
  }

 

  public createKanbantemplate(applyKanbansetForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'createKanbantemplate', applyKanbansetForm, user);
  }



  public applyTracksec(applyKanbansecForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'applyTracksec', applyKanbansecForm, user);
  }

  public applyStepset(applyKanbanstepsetForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'applyStepset', applyKanbanstepsetForm, user);
  }

  public applyKanban(applyKanbanForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'applyKanban', applyKanbanForm, user);
  }

  public selectWork(selectWorkform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for mdata from selectwork' + JSON.stringify(selectWorkform));
    return this.request('post', 'selectWork', selectWorkform, user);
  }

  
  public selTasksetitem(seltasksetitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for mdata from selTasksetitem' + JSON.stringify(seltasksetitemform));
    return this.request('post', 'selTasksetitem', seltasksetitemform, user);
  }

  public updateTaskset(updateTasksetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for mdata from updateTaskset' + JSON.stringify(updateTasksetform));
    return this.request('post', 'updateTaskset', updateTasksetform, user);
  }

  public addtoProject(addtoProjectform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('authentication svc addtoProject' + JSON.stringify(addtoProjectform));
    return this.request('post', 'addtoProject', addtoProjectform, user);
  }

  public  updatePos(updatePosform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for mdata from updatePos' + JSON.stringify(updatePosform));
    return this.request('post', 'updatePos', updatePosform, user);
  }

  public  getTaskset(getTasksetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for data from getTaskset' + JSON.stringify(getTasksetform));
    return this.request('post', 'getTaskset', getTasksetform, user);
  }

  public createTagset(createtagsetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for creating tag set' + JSON.stringify(createtagsetform));
    return this.request('post', 'createTagset', createtagsetform, user);
  }

  public createTager(createkanbansetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('for creating kanban set' + JSON.stringify(createkanbansetform));
    return this.request('post', 'createTager', createkanbansetform, user);
  }

  public createNode(createnodeform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('form for createNode' + JSON.stringify(createnodeform));
    return this.request('post', 'createNode', createnodeform, user);
  }

  public createFlow(createflowform: FormData, user: TokenPayload ): Observable<any> {

    return this.request('post', 'createFlow', createflowform, user);
  }

  public getQueueDetails(getQueueDetailsForm: FormData, user: TokenPayload ): Observable<any> {

    return this.request('post', 'getQueueDetails', getQueueDetailsForm, user);
  }

  public createIndexer(createindexerform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'createIndexer', createindexerform, user);
  }

  public createSubfolder(createsubfolderform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'createSubfolder', createsubfolderform, user);
  }

   public addffItem(addffitemform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addffItem', addffitemform, user);
  }

  public addTrack(addtrackform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('form for addTrack' + JSON.stringify(addtrackform));
    return this.request('post', 'addTrack', addtrackform, user);
  }

 

  public addStep(addstepform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('form for addStep' + JSON.stringify(addstepform));
    return this.request('post', 'addStep', addstepform, user);
  }

  public createLinktab(createlinktabform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'createLinktab', createlinktabform, user);
  }

  public createTagitem(createtagitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createtagitem post to node' + JSON.stringify(createtagitemform));
    return this.request('post', 'createTagitem', createtagitemform, user);
  }

  public createListitem(createlistitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createListitem post to node' + JSON.stringify(createlistitemform));
    return this.request('post', 'createListitem', createlistitemform, user);
  }

  public getToolbars(toolbarform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending toolbarform post to node' + JSON.stringify(toolbarform));
    return this.request('post', 'getToolbars', toolbarform, user);
  }

  public getfavToolbars(toolbarform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getfavToolbars', toolbarform, user);
  }

  public getfavLocations(toolbarform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getfavLocations', toolbarform, user);
  }

  public saveNode(savenodeform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'saveNode', savenodeform, user);
  }

  public askChatgpt(askchatgptform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'askChatgpt', askchatgptform, user);
  }

  public askToolbard(asktoolbardform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'askToolbard', asktoolbardform, user);
  }

  public saveFormassign(formassignform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending savenodeform post to node' + JSON.stringify(savenodeform));
    return this.request('post', 'saveFormassign', formassignform, user);
  }


  public reTriggerflow(retriggerform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending savenodeform post to node' + JSON.stringify(savenodeform));
    return this.request('post', 'reTriggerflow', retriggerform, user);
  }

  public assignNode(formassignform: FormData, user: TokenPayload ): Observable<any> {

    return this.request('post', 'assignNode', formassignform, user);
  }

  public saveStepperassign(stepperassignform: FormData, user: TokenPayload ): Observable<any> {
    
    return this.request('post', 'saveStepperassign', stepperassignform, user);
  }

  public saveStepper(savestepperform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending savenodeform post to stepper' + JSON.stringify(savestepperform));
    return this.request('post', 'saveStepper', savestepperform, user);
  }

  public applyTags(applytagsform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending applytagsform post to node' + JSON.stringify(applytagsform));
    return this.request('post', 'applyTags', applytagsform, user);
  }

  public updateStepper(updateStepperform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending updateStepperform post to node' + JSON.stringify(updateStepperform));
    return this.request('post', 'updateStepper', updateStepperform, user);
  }

  public updateFoldertabs(updateFoldertabsform: FormData, user: TokenPayload ): Observable<any> {
  
    return this.request('post', 'updateFoldertabs', updateFoldertabsform, user);
  }

  public updateTagitem(updatetagitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending updateTagitem post to node' + JSON.stringify(updatetagitemform));
    return this.request('post', 'updateTagitem', updatetagitemform, user);
  }

  public deleteTagitem(deltagitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending deleteTagitem post to node' + JSON.stringify(deltagitemform));
    return this.request('post', 'deleteTagitem', deltagitemform, user);
  }

  public deleteStep(deletestepform: FormData, user: TokenPayload ): Observable<any> {
  
    return this.request('post', 'deleteStep', deletestepform, user);
  }

  public createChoiceset(createchoicesetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createChoiceset post to node' + JSON.stringify(createchoicesetform));
    return this.request('post', 'createChoiceset', createchoicesetform, user);
  }

  public createapplyChoiceset(createapplychoicesetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createapplyChoiceset post to node' + JSON.stringify(createapplychoicesetform));
    return this.request('post', 'createapplyChoiceset', createapplychoicesetform, user);
  }

  public createChoiceitem(createchoiceitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createChoiceitem post to node' + JSON.stringify(createchoiceitemform));
    return this.request('post', 'createChoiceitem', createchoiceitemform, user);
  }

  public deleteChoiceitem(deletechoiceitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending createChoiceitem post to node' + JSON.stringify(createchoiceitemform));
    return this.request('post', 'deleteChoiceitem', deletechoiceitemform, user);
  }

  public findDatatags(finddatatagsform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findDatatags post to node' + JSON.stringify(finddatatagsform));
    return this.request('post', 'findDatatags', finddatatagsform, user);
  }

  public findstepset(formTemplateForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findstepset post to node' + JSON.stringify(formTemplateForm));
    return this.request('post', 'findstepset', formTemplateForm, user);
  }

  public updateNode(updateNodeform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateNode', updateNodeform, user);
  }

  public updateTeam(updateteamform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateTeam', updateteamform, user);
  }

  public updateStep(updateStepform: FormData, user: TokenPayload ): Observable<any> {
  
    return this.request('post', 'updateStep', updateStepform, user);
  }

  public editTrack(editTrackform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending editTrack post to node' + JSON.stringify(editTrackform));
    return this.request('post', 'editTrack', editTrackform, user);
  }

  public finishTager(finishTagerform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending finishTager post to node' + JSON.stringify(finishTagerform));
    return this.request('post', 'finishTager', finishTagerform, user);
  }

  public launchTagerseq(launchTagerseq: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending saving form post to node' + JSON.stringify(launchTagerseq));
    return this.request('post', 'launchTagerseq', launchTagerseq, user);
  }

  public launchFlow(launchflowform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending saving form post to node' + JSON.stringify(launchTagerseq));
    return this.request('post', 'launchFlow', launchflowform, user);
  }

  public getActiveflow(activeflowform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending saving form post to node' + JSON.stringify(launchTagerseq));
    return this.request('post', 'getActiveflow', activeflowform, user);
  }

  public nextPhase(nextphaseform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'nextPhase', nextphaseform, user);
  }

  public prevPhase(prevphaseform: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'prevPhase', prevphaseform, user);
  }


  public findTager(findkanbansform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findTager post to node' + JSON.stringify(findkanbansform));
    return this.request('post', 'findTager', findkanbansform, user);
  }

  public findTagItem(findtagitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findTagItem post to node' + JSON.stringify(findtagitemform));
    return this.request('post', 'findTagItem', findtagitemform, user);
  }

  public findKanbanItem(findkanbanitemform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findKanbanItem post to node' + JSON.stringify(findkanbanitemform));
    return this.request('post', 'findKanbanItem', findkanbanitemform, user);
  }

  public findChoiceset(findchoicesetform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findChoiceset post to node' + JSON.stringify(findchoicesetform));
    return this.request('post', 'findChoiceset', findchoicesetform, user);
  }

  public getChoicesetbyid(choicesetbyidform: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findChoiceset post to node' + JSON.stringify(choicesetbyidform));
    return this.request('post', 'getChoicesetbyid', choicesetbyidform, user);
  }

  public getTagitem(getTagitemForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTagitem post to node' + JSON.stringify(getTagitemForm));
    return this.request('post', 'getTagitem', getTagitemForm, user);
  }

  public findTags(findTagsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending findTags post to node' + JSON.stringify(findTagsForm));
    return this.request('post', 'findTags', findTagsForm, user);
  }

  public getTracks(getKanbanitemForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTracks post to node' + JSON.stringify(getKanbanitemForm));
    return this.request('post', 'getTracks', getKanbanitemForm, user);
  }

  public getTager(getTagerForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTager post to node' + JSON.stringify(getTagerForm));
    return this.request('post', 'getTager', getTagerForm, user);
  }

  public getCatpath(getCatpathForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTager post to node' + JSON.stringify(getCatpathForm));
    return this.request('post', 'getCatpath', getCatpathForm, user);
  }

  public getTracksec(getKanbansecForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTracksec post to node' + JSON.stringify(getKanbansecForm));
    return this.request('post', 'getTracksec', getKanbansecForm, user);
  }

  public createTrack(createKanbanitemForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTracks post to node' + JSON.stringify(createKanbanitemForm));
    return this.request('post', 'createTrack', createKanbanitemForm, user);
  }

  public getChoiceset(getChoicesetForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getChoiceset post to node' + JSON.stringify(getChoicesetForm));
    return this.request('post', 'getChoiceset', getChoicesetForm, user);
  }

  public getChoiceitem(getChoiceitemForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getChoiceitem post to node' + JSON.stringify(getChoiceitemForm));
    return this.request('post', 'getChoiceitem', getChoiceitemForm, user);
  }

  public getPromptlist(promptForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getPromptlist post to node' + JSON.stringify(getChoiceitemForm));
    return this.request('post', 'getPromptlist', promptForm, user);
  }

  public getTagitems(getTagitemForm: FormData, user: TokenPayload ): Observable<any> {
    
    return this.request('post', 'getTagitems', getTagitemForm, user);
  }

  public renameFolder(renamefolderForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending renameFolder post to node' + JSON.stringify(renamefolderForm));
    return this.request('post', 'renameFolder', renamefolderForm, user);
  }

  public getFolderDetails(getFolderDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getFolderDetails post to node' + JSON.stringify(getFolderDetailsForm));
    return this.request('post', 'getFolderDetails', getFolderDetailsForm, user);
  }

  public findSmartfolderitems(smartfolderitemForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'findSmartfolderitems', smartfolderitemForm, user);
  }

  public getsetDetails(getSetDetailsForm: FormData, user: TokenPayload ): Observable<any> {

    return this.request('post', 'getsetDetails', getSetDetailsForm, user);
  }

  public getCategoryDetails(getCategoryDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getCategoryDetails post to node' + JSON.stringify(getCategoryDetailsForm));
    return this.request('post', 'getCategoryDetails', getCategoryDetailsForm, user);
  }

  public getFlowDetails(getFlowDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'getFlowDetails', getFlowDetailsForm, user);
  }

  public getKanbanDetails(getKanbanDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getKanbanDetails post to node' + JSON.stringify(getKanbanDetailsForm));
    return this.request('post', 'getKanbanDetails', getKanbanDetailsForm, user);
  }

  public applyTemplate(addFolderPropsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending applyTemplate post to node' + JSON.stringify(addFolderPropsForm));
    return this.request('post', 'applyTemplate', addFolderPropsForm, user);
  }

  public updateNodetags(updatenodetagsForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateNodetags', updatenodetagsForm, user);
  }



  public updateFoldertags(updatefoldertagsForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'updateFoldertags', updatefoldertagsForm, user);
  }

  public getTeamDetails(getTeamDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getTeamDetails post to node' + JSON.stringify(getTeamDetailsForm));
    return this.request('post', 'getTeamDetails', getTeamDetailsForm, user);
  }

  public getNodeTeamDetails(getNodeTeamDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getNodeTeamDetails post to node' + JSON.stringify(getNodeTeamDetailsForm));
    return this.request('post', 'getNodeTeamDetails', getNodeTeamDetailsForm, user);
  }

  public updateTagitems(updateTagitemsForm: FormData, user: TokenPayload ): Observable<any> {
    
    return this.request('post', 'updateTagitems', updateTagitemsForm, user);
  }

  public getNodeDetails(getNodeDetailsForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getNodeDetails post to node' + JSON.stringify(getNodeDetailsForm));
    return this.request('post', 'getNodeDetails', getNodeDetailsForm, user);
  }

  public getItembyid(itemForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending getNodeDetails post to node' + JSON.stringify(getNodeDetailsForm));
    return this.request('post', 'getItembyid', itemForm, user);
  }

  public getImagebyver(imageForm: FormData, user: TokenPayload ): Observable<Blob>  {

    return this.request('post', 'getImagebyver', imageForm, user);
  }

  public addteamuser(addTeamUserForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending addteamuser post to node' + JSON.stringify(addTeamUserForm));
    return this.request('post', 'addteamuser', addTeamUserForm, user);
  }

  public addteammgr(addTeamMgrForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'addteammgr', addTeamMgrForm, user);
  }

  public addnodeteamuser(addnodeteamUserForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending addteamuser post to node' + JSON.stringify(addTeamUserForm));
    return this.request('post', 'addnodeteamuser', addnodeteamUserForm, user);
  }

  public addnodeteammgr(addnodeteamMgrForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending addteamuser post to node' + JSON.stringify(addTeamUserForm));
    return this.request('post', 'addnodeteammgr', addnodeteamMgrForm, user);
  }

  public addsetmgr(addsetMgrForm: FormData, user: TokenPayload ): Observable<any> {

    return this.request('post', 'addsetmgr', addsetMgrForm, user);
  }

  public removeteamuser(removeTeamUserForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending removeteamuser post to node' + JSON.stringify(removeTeamUserForm));
    return this.request('post', 'removeteamuser', removeTeamUserForm, user);
  }

  public removeteammgr(removeTeamMgrForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'removeteammgr', removeTeamMgrForm, user);
  }

  public removeUser(removeUserForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'removeUser', removeUserForm, user);
  }

  public inviteUser(inviteTeamMgrForm: FormData, user: TokenPayload ): Observable<any> {
    return this.request('post', 'inviteUser', inviteTeamMgrForm, user);
  }

  public changeteamcolor(changeteamcolorForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending removeteamuser post to node' + JSON.stringify(changeteamcolorForm));
    return this.request('post', 'changeteamcolor', changeteamcolorForm, user);
  }

  public editFile(editfileForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending editFile post to node' + JSON.stringify(editfileForm));
    return this.request('post', 'editFile', editfileForm, user);
  }

  public uploaddocver(uploaddocverForm: FormData, user: TokenPayload ): Observable<any> {
    // console.log('sending uploaddocver post to node' + JSON.stringify(uploaddocverForm));
    return this.request('post', 'uploaddocver', uploaddocverForm, user);
  }

  public deleteFile(deletefileForm: FormData, user: TokenPayload ): Observable<any> {
    
    return this.request('post', 'deleteFile', deletefileForm, user);
  }

  public deleteDesign(deletedesignForm: FormData, user: TokenPayload ): Observable<any> {
   
    return this.request('post', 'deleteDesign', deletedesignForm, user);
  }

  public deleteFolder(deletefolderForm: FormData, user: TokenPayload ): Observable<any> {
  
    return this.request('post', 'deleteFolder', deletefolderForm, user);
  }

  

  

}
