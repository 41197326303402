

<mat-tab-group >

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">folder_special</mat-icon>
      Toolbar Details
      </ng-template>
        <span [formGroup]="editcategoryFG" class= "details-container">
           
            <div><mat-form-field appearance="outline" floatLabel="always" style="width: 50vw">
                <mat-label >Category</mat-label><input matInput [readonly]= "!this.configSvc.itemlocked"  matInput value={{this.configSvc.categorydetailsds?.name}}>
                <mat-icon matSuffix>mode_edit</mat-icon></mat-form-field></div>
            
  
              <div><mat-form-field appearance="outline" floatLabel="always" style="width: 50vw">
                
                  <mat-label >Added By</mat-label><input matInput [readonly]= "true" matInput value={{this.configSvc.categorydetailsds?.ab}}>
                </mat-form-field> </div>
  
                <div><mat-form-field appearance="outline" floatLabel="always"style="width: 50vw" >
                
                  <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="this.configSvc.categorydetailsds?.ao | date: 'medium'"
                  >
                </mat-form-field> </div>
  
              
                 
                 
                      
  
                       
  
  
                           
  
                            
  
           
              <div> <mat-form-field appearance="outline" floatLabel="always" style="width: 50vw">
                  <mat-label>FolderID</mat-label><input  matInput placeholder={{this.configSvc.categorydetailsds?._id}}>
               </mat-form-field></div>
  
            
              </span>
  
     
    </mat-tab>
  
    </mat-tab-group>
  
   