  
        <div  class="outerbox" >
            <table mat-table [dataSource]="this.lineitemdata"  >
            
            
          
                       
            <ng-container matColumnDef="litem" >
                  <th mat-header-cell *matHeaderCellDef>Line Item</th>
               <td mat-cell *matCellDef="let element">
                
                   {{element.litem}}
             </td></ng-container>
    
            

           <ng-container matColumnDef="link" >
            <th mat-header-cell *matHeaderCellDef>Link</th>
         <td mat-cell *matCellDef="let element; let rowcount = index;">
          
         

              <span *ngIf="!element.match && this.itemselected && rowcount == this.indexselected"> 
               <mat-form-field appearance="fill">
                  
                  <mat-select  (selectionChange)="chooseProperty(property)"  [(ngModel)]="selectedProperty" name="property">
                    <mat-option *ngFor="let property of indexoptions" [value]="property">
                      {{property.formitem}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
       </td></ng-container>
      
    
         
        
   
    
    
    <tr mat-header-row *matHeaderRowDef="textcolumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let selectedrow; columns: textcolumns; let i2 = index;"
    (click)="this.selectItem(selectedrow, i2)"
    [style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"
    
    ></tr>
    
                 
            
               </table>

          
          </div>
          
