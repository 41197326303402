import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, FormArray, Validators, NgForm} from '@angular/forms';

@Component({
  selector: 'app-invites',
  templateUrl: './invites.component.html',
  styleUrls: ['./invites.component.css']
})
export class InvitesComponent implements OnInit {

  public invitedmgrs = [];
  public hlemail = [];
  invitedmgrfc = new FormControl('', [Validators.required,Validators.email,]);
  // public emailColumns = ['email','ab','ao','accept'];
  public emailColumns = ['accept','email','ab','ao'];
  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {
  }
  
  public inviteUser(useremail){
    console.log('inviting new manager'+useremail )
    this.configSvc.inviteUser( this.configSvc.activeteamid, this.configSvc.activename, useremail)
    
  }

  public removeInvite(){

    const index = this.invitedmgrs.indexOf(this.hlemail)
    this.invitedmgrs.splice(index, 1);
  }

  public selectInvitedmgrs(mgremail){
    console.log('selected invited mgr'+mgremail)
    this.hlemail = [];
    this.hlemail.push(mgremail)
  }


}
