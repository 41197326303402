import { Injectable } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';
import {Tagmodel} from '.././model/tagmodel';
import {ConfigService} from '../services/config.service';


@Injectable({
  providedIn: 'root'
})
export class TagService {
  public kanbantagged = '0';
  public kandocs = '0';
  public finddtagclicked = false
  public tagtemplatesel = false;
  public choosetag = false;
  public datenow;
  public folderformds = [];
  tagsetsel = false;
  tagitemsel = false;
  choicesetsel = false;
  choiceitemsel = false;
  finddatatagsclicked = false;
  findtagitemclicked = false;
  findchoicesetclicked = false;
  tagitemsearchselected = false;
  choicesetsearchselected = false;
  tagaction = '';
  user: TokenPayload;
  public tagset =[];
public currTagset: Tagmodel;
public initTeam = false;
public highlightedTagset = [];

public tagitems = [];
public tagitemsrch = [];
public choicesetsrch = [];
public currTagitem : Tagmodel;
public highlightedTagitem = [];

public choiceset = [];
public currChoiceset : Tagmodel;
public highlightedChoiceset = [];

public choiceitems = [];
public choiceitems2 = [];
public choiceitems3 = [];
public choiceitems4 = [];
public choiceitems5 = [];
// public tagitems = [];
public currChoiceitem : Tagmodel;
public highlightedChoiceitem = [];
public ts = '0'; //tagset true or false
public tagtype = 'tagset';

  constructor(private authService: AuthenticationService, public configSvc: ConfigService) { }

  findDatatags(datatag){
    console.log('this is the datatag that were looking for: ' + JSON.stringify(datatag));
    this.tagset = []; this.tagitems= []; this.choiceset= []; this.choiceitems = [];
    const datatagForm = new FormData();
    datatagForm.append('tagsetname', datatag);
   
    this.authService.findDatatags(datatagForm, this.user).subscribe(datatagresults => {
      console.log('this is the results from trying to find datatags: ' + JSON.stringify(datatagresults));
       this.tagset = datatagresults;
       this.tagtype = 'tagset';
    }, (err) => {
      console.error(err); });

    }

    findTagItem(tagitem){
      console.log('this is the tagitem that were looking for: ' + JSON.stringify(tagitem));
     this.tagitemsrch= [];
      const tagitemsrchForm = new FormData();
      tagitemsrchForm.append('tagitem', tagitem);
      this.authService.findTagItem(tagitemsrchForm, this.user).subscribe(tagitemresults => {
        console.log('this is the results from trying to find tagitems: ' + JSON.stringify(tagitemresults));
         this.tagitemsrch = tagitemresults;
         this.findtagitemclicked = true;
      }, (err) => {
        console.error(err); });
      }

      findChoiceset(choiceset){
        console.log('this is the choiceset that were looking for: ' + JSON.stringify(choiceset));
       this.choicesetsrch= [];
        const choicesetsrchForm = new FormData();
        choicesetsrchForm.append('choiceset', choiceset);
        this.authService.findChoiceset(choicesetsrchForm, this.user).subscribe(choicesetresults => {
          console.log('this is the results from trying to find choicesets: ' + JSON.stringify(choicesetresults));
           this.choicesetsrch = choicesetresults;
           this.findchoicesetclicked = true;
        }, (err) => {
          console.error(err); });
        }

    createTagset(tagsetname)
    {
      const tagsetForm = new FormData();
      tagsetForm.append('tagsetname', tagsetname);
   this.authService.createTagset(tagsetForm, this.user).subscribe(tagsetresults => {
      console.log('this is the results from createing Tagset: ' + JSON.stringify(tagsetresults));
       this.tagset = tagsetresults;
     
    }, (err) => {
      console.error(err); });

    }

    createTagitem( tagerid, nodename, tagname, tagtype, haslabel,  taglabel)
    {
      const tagitemForm = new FormData();
      tagitemForm.append('tagerid', tagerid);
      tagitemForm.append('nodename', nodename);
      tagitemForm.append('tagname', tagname);
      tagitemForm.append('tagtype', tagtype);
      tagitemForm.append('haslabel', haslabel);
      tagitemForm.append('taglabel', taglabel);
      
     
   this.authService.createTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
      console.log('this is the results from createing Tagset: ' + JSON.stringify(tagitemresults));
       this.tagitems = tagitemresults;
      
    }, (err) => {
      console.error(err); });

    }

    addTagitem( tagsetid, tagsetname, tagitem, tagtype)
    {
      const tagitemForm = new FormData();
      tagitemForm.append('tagsetid', tagsetid);
      tagitemForm.append('tagsetname', tagsetname);
      tagitemForm.append('tagitem', tagitem);
      tagitemForm.append('tagtype', tagtype);
   this.authService.createTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
      console.log('this is the results from createing Tagset: ' + JSON.stringify(tagitemresults));
       this.tagitems = tagitemresults;
      
    }, (err) => {
      console.error(err); });

    }

    createChoiceset(tagsetid, tagsetname, tagitem, tagitemid, choicesetname)
    {
      const choicesetForm = new FormData();
      choicesetForm.append('tagsetid', tagsetid);
      choicesetForm.append('tagsetname', tagsetname);
      choicesetForm.append('tagitem', tagitem);
      choicesetForm.append('tagitemid', tagitemid);
      choicesetForm.append('choicesetname', choicesetname);
   this.authService.createChoiceset(choicesetForm, this.user).subscribe(choicesetresults => {
      console.log('this is the results from createing choiceset: ' + JSON.stringify(choicesetresults));
       this.choiceset= choicesetresults;
     
    }, (err) => {
      console.error(err); });

    }

    createChoiceitem(tagsetid, tagsetname, tagitem, tagitemid, choicesetname, choicesetid, choiceitemname)
    {
      const choiceitemForm = new FormData();
      choiceitemForm.append('tagsetid', tagsetid);
      choiceitemForm.append('tagsetname', tagsetname);
      choiceitemForm.append('tagitem', tagitem);
      choiceitemForm.append('tagitemid', tagitemid);
      choiceitemForm.append('choicesetname', choicesetname);
      choiceitemForm.append('choicesetid', choicesetid);
      choiceitemForm.append('choiceitemname', choiceitemname);
   this.authService.createChoiceitem(choiceitemForm, this.user).subscribe(choiceitemresults => {
      console.log('this is the results from createing choiceitem: ' + JSON.stringify(choiceitemresults));
       this.choiceitems= choiceitemresults;
       this.tagtype= 'choiceitem';
    }, (err) => {
      console.error(err); });

    }

    public getTagitem(tagset) {
      this.highlightedTagset = [];
      this.highlightedTagset.push(tagset);
      console.log('highlighed tagset row: ' + JSON.stringify(this.highlightedTagset));
      this.configSvc.color = this.highlightedTagset[0].tagcolor
      console.log('configsvc color is now '+this.configSvc.color);
       this.currTagset = tagset;
       this.ts = '1';
       console.log('current tagset row: ' + JSON.stringify(this.currTagset));
      
       this.tagitems= []; this.choiceset= []; this.choiceitems = [];
       const getTagitemForm = new FormData();
       getTagitemForm.append('tagsetid', this.currTagset._id);
       this.authService.getTagitem(getTagitemForm, this.user).subscribe(getTagitemresults => {
       console.log('this is the results from getting tag items choiceitem: ' + JSON.stringify(getTagitemresults));
        this.tagitems= getTagitemresults;
        this.tagtype = 'tagitem';
        this.configSvc.color = getTagitemresults.color;
      }, (err) => {console.error(err); });

     }
       
        

        public selectTagitem(tagitem) {
          this.highlightedTagitem = [];
          this.tagitemsearchselected = true;
           this.currTagitem = tagitem;
           this.highlightedTagitem.push(tagitem);
           console.log('current tagitem row: ' + JSON.stringify(this.currTagitem));
           console.log('highlighed tagitem row: ' + JSON.stringify(this.highlightedTagitem));
           const getChoicesetForm = new FormData();
           getChoicesetForm.append('tagitemid', this.currTagitem._id);
       this.authService.getChoiceset(getChoicesetForm, this.user).subscribe(getChoicesetresults => {
       console.log('this is the results from getting choiceset: ' + JSON.stringify(getChoicesetresults));
        this.choiceset= getChoicesetresults;
      //  console.log('this is the assigned choiceset: '+this.choiceset[0]?.choicesetn);
        this.tagtype = 'choiceset';
      
      }, (err) => {console.error(err); });

     }
           
            
     public selectChoiceset(choiceset) {
      this.tagaction = 'choiceset';
      this.highlightedChoiceset = [];
       this.currChoiceset = choiceset;
       this.highlightedChoiceset.push(choiceset);
       console.log('current choiceset row: ' + JSON.stringify(this.currChoiceset));
       console.log('highlighed choiceset row: ' + JSON.stringify(this.highlightedChoiceset));
       const getChoiceitemForm = new FormData();
       getChoiceitemForm.append('choicesetid', this.currChoiceset.choicesetid);
   this.authService.getChoiceitem(getChoiceitemForm, this.user).subscribe(getChoiceitemresults => {
   console.log('this is the results from getting choiceitem: ' + JSON.stringify(getChoiceitemresults));
    this.choiceitems= getChoiceitemresults;
    this.tagtype = 'choiceitem';
   

  }, (err) => {console.error(err); });

 }

 public getChoices(choicesetid, level) {
  console.log('choicesetid is'+choicesetid)
  console.log('this is level: '+level)
  // this.choiceitems = [];
   const getChoicesForm = new FormData();
   getChoicesForm.append('choicesetid', choicesetid);
this.authService.getChoiceitem(getChoicesForm, this.user).subscribe(getChoiceitemresults => {
console.log('this is the results from getting choices: ' + JSON.stringify(getChoiceitemresults));

if (level == 1){
  console.log('this is level1')
  this.choiceitems = [];
  this.choiceitems = getChoiceitemresults
  console.log('this is the actual choices1 after pushing: ' + JSON.stringify(this.choiceitems));
}

if(level ==2){
  console.log('this is level2')
  this.choiceitems2 = [];
this.choiceitems2 = getChoiceitemresults
console.log('this is the actual choices2 after pushing: ' + JSON.stringify(this.choiceitems2));}


if(level ==3){
  console.log('populating level3 choices')
  this.choiceitems3 = [];this.choiceitems3 = getChoiceitemresults}
if(level ==4){this.choiceitems4 = [];this.choiceitems4 = getChoiceitemresults}
if(level ==5){this.choiceitems5 = [];this.choiceitems5 = getChoiceitemresults}


this.tagtype = 'choiceitem';
this.tagaction = 'choiceset';
this.configSvc.gettingchoices = true;

}, (err) => {console.error(err); });

}

      
            public applyTagertag(tagerid,nodename, type, folderid, tagcolor)
            {
              const applyTagertagForm = new FormData();
              applyTagertagForm.append('tagerid', tagerid);
              applyTagertagForm.append('nodename', nodename);
              applyTagertagForm.append('type', type);
            
              applyTagertagForm.append('itemid', folderid);
              applyTagertagForm.append('tagcolor', tagcolor);
             
           this.authService.applyTagertag(applyTagertagForm, this.user).subscribe(applytagertagresults => {
              console.log('this is the results from createing Tagertag: ' + JSON.stringify(applytagertagresults));
               this.configSvc.folderdetailsds = applytagertagresults;
            }, (err) => {
              console.error(err); });
        
            }

            public assignKey(itemid,property,  key, keyid, newvalue, propid)
            {
              const assignkeyForm = new FormData();
              assignkeyForm.append('itemid', itemid);
              assignkeyForm.append('property', property);
              assignkeyForm.append('key', key);
              assignkeyForm.append('newvalue', newvalue);
           //   assignkeyForm.append('propid', propid);
              assignkeyForm.append('keyid', keyid);
  this.configSvc.nodeready = false
             
           this.authService.assignKey(assignkeyForm, this.user).subscribe(assignkeyresults => {
              console.log('this is the results from assigning key: ' + JSON.stringify(assignkeyresults));
               this.configSvc.nodeds = [assignkeyresults];
               this.configSvc.nodeready = true
            }, (err) => {
              console.error(err); });
        
            }

            

            public saveTageritem(tagerid,teamid, tagernum, type, folderid, icontype, iconcolor, tagercnt, tagerdesc, nodename)
            {
              const savetageritemForm = new FormData();
              savetageritemForm.append('tagerid', tagerid);
              savetageritemForm.append('teamid', teamid);
              savetageritemForm.append('tagernum', tagernum);
              savetageritemForm.append('type', type);
            
              savetageritemForm.append('folderid', folderid);
              savetageritemForm.append('icontype', icontype);
              savetageritemForm.append('iconcolor', iconcolor);
              savetageritemForm.append('tagercnt', tagercnt);
              savetageritemForm.append('tagerdesc', tagerdesc);
              savetageritemForm.append('nodename', nodename);
              if (!this.configSvc.folderdetailsds.kanbantagged){
                this.kanbantagged = '0'}
                if (this.configSvc.folderdetailsds.kanbantagged){
                  this.kanbantagged = '1'}
    
                  if (!this.configSvc.folderdetailsds.kandocs){
                    this.kandocs = '0'}
                    if (this.configSvc.folderdetailsds.kandocs){
                      this.kandocs = '1'
                    
                    }
              
                    savetageritemForm.append('kanbantagged', this.kanbantagged);
                    savetageritemForm.append('kandocs', this.kandocs);
             
           this.authService.saveTageritem(savetageritemForm, this.user).subscribe(tageritemresults => {
              console.log('this is the results from saving Tageritem: ' + JSON.stringify(tageritemresults));
               this.configSvc.teamds = tageritemresults;
            }, (err) => {
              console.error(err); });
        
            }

            public createCardbyid(tagerid,tagertype, nodename, folderid, level, teamid)
            {
              this.datenow = Date.now();
              const createtagerbyidForm = new FormData();
              createtagerbyidForm.append('kanbansetid', tagerid);
              createtagerbyidForm.append('tagertype', tagertype);
              createtagerbyidForm.append('nodename', nodename);
              createtagerbyidForm.append('folderid', folderid)
              createtagerbyidForm.append('type', 'create')
              createtagerbyidForm.append('level', level)
              createtagerbyidForm.append('teamid', teamid)
              createtagerbyidForm.append('addedon', this.datenow);
              if (!this.configSvc.folderdetailsds.kanbantagged){
                this.kanbantagged = '0'}
                if (this.configSvc.folderdetailsds.kanbantagged){
                  this.kanbantagged = '1'}
    
                  if (!this.configSvc.folderdetailsds.kandocs){
                    this.kandocs = '0'}
                    if (this.configSvc.folderdetailsds.kandocs){
                      this.kandocs = '1'
                    
                    }
              
                    createtagerbyidForm.append('kanbantagged', this.kanbantagged);
                    createtagerbyidForm.append('kandocs', this.kandocs);
             
           this.authService.createCardbyid(createtagerbyidForm, this.user).subscribe(createtagerbyidresults => {
              console.log('this is the results from creating Tager by id: ' + JSON.stringify(createtagerbyidresults));
               this.configSvc.teamds = createtagerbyidresults;
            }, (err) => {
              console.error(err); });
        
            }

            public createStepsbyid(tagerid,tagertype, nodename, folderid, level, teamid)
            {
              this.datenow = Date.now();
              const createstepsbyidForm = new FormData();
              createstepsbyidForm.append('tagerid', tagerid);
              createstepsbyidForm.append('tagertype', tagertype);
              createstepsbyidForm.append('nodename', nodename);
              createstepsbyidForm.append('folderid', folderid)
              createstepsbyidForm.append('type', 'create')
              createstepsbyidForm.append('level', level)
              createstepsbyidForm.append('teamid', teamid)
              createstepsbyidForm.append('addedon', this.datenow);
              createstepsbyidForm.append('track', '1');
              if (!this.configSvc.folderdetailsds.kanbantagged){
                this.kanbantagged = '0'}
                if (this.configSvc.folderdetailsds.kanbantagged){
                  this.kanbantagged = '1'}
    
                  if (!this.configSvc.folderdetailsds.kandocs){
                    this.kandocs = '0'}
                    if (this.configSvc.folderdetailsds.kandocs){
                      this.kandocs = '1'
                    
                    }
              
                    createstepsbyidForm.append('kanbantagged', this.kanbantagged);
                    createstepsbyidForm.append('kandocs', this.kandocs);
             
           this.authService.createStepsbyid(createstepsbyidForm, this.user).subscribe(createstepsbyidresults => {
              console.log('this is the results from creating Tager by id: ' + JSON.stringify(createstepsbyidresults));
               this.configSvc.teamds = createstepsbyidresults;
            }, (err) => {
              console.error(err); });
        
            }


            public getTagitems() {

              this.tagitems = [];
               const getTagitemsForm = new FormData();
            //   getTagitemsForm.append('choicesetid', choicesetid);
            this.authService.getTagitems(getTagitemsForm, this.user).subscribe(getTagitemresults => {
            console.log('this is the results from getting tagitems: ' + JSON.stringify(getTagitemresults));
            for(var tagitem of getTagitemresults){
            this.tagitems.push(tagitem);}
            console.log('this is the actual tagitem after pushing: ' + JSON.stringify(this.tagitems));
           // this.tagtype = 'choiceitem';
            
            }, (err) => {console.error(err); });
            
            }

            public addFormtoFolder(formname, folderid, tagsetid, level) {

              const addformForm = new FormData();
              console.log('formname Im trying to add is' + JSON.stringify(formname));
              addformForm.append('formname', formname);
               
              addformForm.append('folderid', folderid);
              console.log('folderid is' + folderid);
              this.datenow = Date.now();
              addformForm.append('addedon', this.datenow);
              addformForm.append('tagsetid', tagsetid);
              console.log('tagsetid is' + tagsetid);
              addformForm.append('level', level);
              console.log('level is' + level);
              addformForm.append('type', 'Form');
             
              this.authService.addFormtoFolder(addformForm, this.user).subscribe(refreshFolder => {
                this.configSvc.teamds = refreshFolder;
               //  this.configSvc.buildtool = false;
              
             //  console.log('this is the folderformds'+JSON.stringify(this.folderformds));
              }
               , (err) => {
                console.error(err); });
              }

              applyTagcolor(  tagsetid, tagcolor)
    {
      const applytagcolorForm = new FormData();
      applytagcolorForm.append('tagsetid', tagsetid);
    
      applytagcolorForm.append('tagcolor', tagcolor);
     
   this.authService.applyTagcolor(applytagcolorForm, this.user).subscribe(applytagcolorresults => {
      console.log('this is the results from applying icon: ' + JSON.stringify(applytagcolorresults));
       this.tagset= applytagcolorresults;
       this.highlightedTagset = [];
       this.highlightedTagset.push(applytagcolorresults);
      
    }, (err) => {
      console.error(err); });

    }
}
