import { Component, OnInit, Inject } from '@angular/core';

import { AuthenticationService } from '../../../services/authentication.service';
import {ConfigService} from '../../../services/config.service';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {TagerService} from '../../../services/tager.service';

export interface DialogData {
 
  formitem: string;
  type: string;
  formitemlabel: string;
  defaultvalue: string;
}

@Component({
  selector: 'app-steptools',
  templateUrl: './steptools.component.html',
  styleUrls: ['./steptools.component.css']
})
export class SteptoolsComponent implements OnInit {

  constructor(public configSvc: ConfigService, public dialog: MatDialog, private authSvc: AuthenticationService) { }

  ngOnInit(): void {
  }

  openTagtool(type, name): void {

    //  console.log('setting cache clear')
    //  this.authSvc.cachedchoices = [];
      console.log('popup name is'+name)
      console.log('popup type is'+type)
      if(type == 'step'){this.configSvc.formstyle = 'step'}
      console.log('formstyle is '+this.configSvc.formstyle)
  var popupheight = '500px'
  var popupwidth = '400px'

  if(type == 'linktab'){ popupheight = '60vh'; popupwidth = '40vw'
  
  }
      if(type == 'deletecategory' || type == 'deletefile' || type == 'deletetageritem' || type == 'deletedesign')
      {popupheight = '200px'; popupwidth = '600px'}
      if(type == 'string' || type == 'number' || type == 'image' ){popupheight = '500px'; popupwidth = '400px'}
      if(type == 'choicelist' || type == 'treelist' || type== 'radiogroup') {popupheight = '500px'; popupwidth = '800px'} 
     
      if(type == 'steppreview' || type == 'nodepreview'){popupheight = '800px'; popupwidth = '1200px'
      console.log('popup width is now '+popupwidth)
      }
      if(type=='find'){popupheight = '800px'; popupwidth = '1400px'}
      if(type == 'kanban'){popupheight = '800px'; popupwidth = '1400px'}
      
      const dialogRef = this.dialog.open(StepPopup, {
        width: popupwidth, height: popupheight,
        data: {name: name, type: type}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed and a change was made');
    
      });
    }

}

@Component({
  selector: 'app-StepPopup',
  templateUrl: 'StepPopup.html',
  styleUrls: ['./steptools.component.css']
})

export class StepPopup implements OnInit{
  public kanbanready =false;
  public selectedkanban = [];
 public  searchtypechosen
 public kanbanname = '';
clickchoiceset = false;
hltreepath =[];
  hlchoiceset = [];
  resultColumns = [ 'Icon', 'Name', 'Desc', 'AddedBy', 'AddedOn'];
  treeColumns = ['Name']
  haslabel = false;
  rowsize: number;
  hasdefault = false;
  defaulttoggle = false
  public itemselected = false;
  public activenodeready = false;
  public activestepperready = false;
  public itemsadded = [];
  findColumns = [ 'icontype', 'name', 'type', 'desc', 'ab', 'ao'];
  searchtypes = ['foldertags', 'kanban', 'flow','node', 'stepper' ]
  public searchtype = 'name'
  public searchtypesel = new FormControl('node');
  public newname: string
public formtypes = ['basic', 'stepper', 'tabbed']
public tagtype = 'firstprop'
  
  
  public previewArray: FormArray;
  public previewFG: FormGroup;
  public choicesetFG: FormGroup;
  public templateFG: FormGroup;
  stpcnt: string;
  public sorted = [];
  public reset: any[] = [{}];
  public trackchoicesetid = [];
  public choicesetselected = false;
  public nodeid: String;
  public stepid: String;
  public selectedRowsize = 'full';
  public selectedImagesize = 'large';
  public icons = false;
  public sourcetab = []
  public tabselected = false
  public sourceindexselected = false
  public sourceindex = []
  public chosenindex = ''

  public templateselected  =false
  public targettemplate = '';
  public restrict = false
  public targetindexes = [];
  public targeticon = '';
  public targetcolor = '';
  public chosentargetindex = ''


  constructor(private router: Router,private authSvc: AuthenticationService,
    public dialogRef: MatDialogRef<StepPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, 
    public configSvc: ConfigService, private fb: FormBuilder, public tagerSvc: TagerService) {}

    
  ngOnInit() {

    this.previewFG = new FormGroup({formArrayName: this.fb.array([])})
    console.log('building form  preview now');

   

    if(this.configSvc.activetype =='editstep'){
      this.buildstepperitems()}


    if(this.configSvc.activetype =='tabbed' || this.configSvc.activetype =='editstepperassign' 
     || this.configSvc.activetype =='smartfolder'  || this.configSvc.activetype =='smartfoldertab'  || this.configSvc.activetype =='nodetab'
     || this.configSvc.activetype =='edittabbedassign'){
    //  this.stepid =  this.configSvc.stepdetailsds[0]._id
    //  console.log('active stepid is'+this.stepid )
      }

    

     

      this.nodeid =  this.configSvc.nodeds[0]._id

    }

public applynodetabKanban(){
  console.log('apply tabbed kanban to '+this.configSvc.activeitemid)
  console.log('use this kanban id '+this.selectedkanban[0]._id)

  this.configSvc.applynodeKanban(this.configSvc.activeitemid, this.selectedkanban[0]._id, 'nodetab' )
}

public applySmartfoldertag(name, desc,type, tagtype){
  console.log('applying foldertag to smartfolder name '+name)
  console.log('applying foldertag to smartfolder desc '+desc)
  console.log('applying foldertag to smartfolder type '+type)
  console.log('applying foldertag to smartfolder tagtype '+tagtype)
  var foldertags = []
  if(tagtype == 'firstprop'){foldertags = [this.configSvc.nodeds[0].stepper[0].tags[0]]}
  if(tagtype == 'firsttab'){foldertags = [this.configSvc.nodeds[0].stepper[0]]}
  console.log('applying foldertag to smartfolder foldertags '+JSON.stringify(foldertags))

  this.configSvc.applySmartfoldertag(this.configSvc.activeitemid, name, desc, type, tagtype, foldertags)
  this.dialogRef.close();
}



  onNoClick(): void {
    this.dialogRef.close();
  }

  buildstepperitems(){

    console.log('this is the stepper node data we are workin with: '+JSON.stringify(this.configSvc.nodeds[0]));
         
          this.previewArray = this.previewFG.get('formArrayName') as FormArray;
         Object.keys(this.configSvc.nodeds[0].stepper).forEach((i) => {
  
         console.log('this is the stepitem property for step'+i);
         console.log('this is the stepper array'+JSON.stringify(this.configSvc.nodeds[0].stepper[i]));
         Object.keys(this.configSvc.nodeds[0].stepper[i].tags).forEach((i2) => {
          console.log('tthis is the item number in the step'+i2);
          this.stpcnt = i2;
          console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[0].stepper[i].tags[i2].formitem));
       
     // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
         // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);
  
            this.previewArray.push( this.fb.group({
              formstep: new FormControl({ value: i}),
               property: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitem, emitEvent:false}),
               label: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].label, emitEvent:false}),
               formitemlabel: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitemlabel, emitEvent:false}),
              itemvalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].itemvalue, disabled: false  }),
               formtype: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formtype}),
          //   formchoices: new FormControl({ value:this.configSvc.nodeds[0].stepper[i2].tags[i2].formchoices}),
            choicesetid: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid}),
            choicesetname: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetname}),
            choicevalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicevalue, disabled: false }),
            hasdefault: new FormControl(this.configSvc.nodeds[0].stepper[i].tags[i2].hasdefault),
            treepath: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].treepath, emitEvent:false}),
            
              }))
  
              console.log('preview array is now:'+this.previewArray);
              
              if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
                && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)===null){ 
                 
                 
                console.log('this property has a list associated with it and its not in cache, lets go and get it')
             // choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
            
             this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'cache')
  
              }
  
              if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
              && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)!==null){ 
                console.log('check to see if the cache variable has the localstorage cache')
                console.log('this is the choicesetid: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
             // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].stepper[i].tags[i2]..choicesetid)
             var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid))
             console.log('this is the localdata im pushing'+localdata);
             if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)){
              this.authSvc.choicesetids.push(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
              console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid);
              console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
              this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
            }
            
            
            }
  
            if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype == 'treelist'){
              this.hltreepath = this.configSvc.nodeds[0].stepper[i].tags[i2].treepath
              this.clickchoiceset = true;
                console.log('this is it he initial hl stepper tree path '+JSON.stringify( this.hltreepath));
               this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'treelist')
               
            }
  
            
  
              if (!this.configSvc.nodeds[0].stepper[i].tags[i2].formchoices){
                console.log('this property does NOT have a list associated with it, doing nothing')
              } })
  })}

  public addTrack(trackname, desc, type){
    this.configSvc.addTrack(trackname, desc, type)
    this.dialogRef.close();
  }

  kanbanFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering index on: '+filterValue)
    this.configSvc.finditemds = [];
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.findKanban( filterValue)
  console.log('foldertagFilter value is currently set to '+this.configSvc.finditemds)

  }
}

kanbanSelected(kanban){
  this.kanbanready =false
console.log('this is the selected kanban '+JSON.stringify(kanban))
/// this.configSvc.nodeds[0] = kanban
this.selectedkanban = [kanban]
this.configSvc.activekanban = kanban.stepitems

console.log('this is the active selected kanban '+JSON.stringify(this.configSvc.activekanban))
this.kanbanready =true
}




    

    public addStep(stepname, desc, type){

      if(!this.icons){
        this.configSvc.addStep(stepname, desc, type, false)
        this.dialogRef.close();
      }
      if(this.icons){
        this.configSvc.addStep(stepname, desc, type, true, this.configSvc.chosenIcon, this.configSvc.color)
        this.dialogRef.close();
      }
      
    }

    public createLinktab(stepname, desc, type, restrict, tool,toolindex, toolinval, targeticon, targetcolor){

      console.log('creating linktab')
      console.log('step name is '+stepname)
      console.log('descis '+desc)
      console.log('type is '+type)
      console.log('tool is '+tool)
      console.log('toolindex is '+toolindex)
      console.log('toolinval  is '+toolinval)
      console.log('targeticon is '+targeticon)
      console.log('targetcolor  is '+targetcolor)
      console.log('restrict  is '+restrict)
    
      
        this.configSvc.createLinktab(stepname, desc, type,toolindex, restrict, tool,  toolinval, targeticon, targetcolor)
        this.dialogRef.close();
      
    }

 



 
    public onRecreate(){
      this.reset[0] = {}
    }

    public changeTab(sourcetab){
console.log('choosing tab '+JSON.stringify(sourcetab))
this.tabselected = true
this.sourceindex = sourcetab.tags
    }

    public changesourceIndex(sourceindex){
      console.log('choosing source index '+JSON.stringify(sourceindex))
      this.sourceindexselected = true

    }

    templateFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log('filtering index on: '+filterValue)
      if (filterValue != '')
      {
        console.log('filter value befoore sending is : '+filterValue)
      this.configSvc.searchallTemplates(filterValue)
    console.log('templateFilter value is currently set to '+this.configSvc.finditemds)
  
    }

    
  }

  selectTemplate(template){
    console.log('this is the selected taplate '+JSON.stringify(template))
 this.onRecreate()
 this.templateselected = true
   this.targettemplate = template.name
   console.log('current nodestuff is '+JSON.stringify(template.stepitems))
   this.targetindexes = template.stepitems
   this.targeticon = template.icontype
   this.targetcolor = template.iconcolor
  }

  public changetargetIndex(chosentargetindex){
    this.chosentargetindex = chosentargetindex
    console.log('chosen target index is '+this.chosentargetindex)
  }
    
    
  
 




}
