

  <div fxLayout="row">
     
    <mat-card>  
     <span *ngIf = "this.configSvc.roottoolbar == false">
       <mat-card class = "moveup"><span  (click)="this.getParenttoolbar()"><i class="material-icons icon-moveup">
        subdirectory_arrow_left</i></span></mat-card></span> 

      <table mat-table [dataSource]="this.configSvc.toolbards">
             <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
           
             ></tr>
             
  
           
    
               <!-- MimeType Column -->
               <ng-container matColumnDef="ToolbarColumn">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                      <div  *ngIf="element.type == 'nodeteam'" 
                      
                      (click)="this.getToolbars(element.type, element._id, 'nodeteam')">
                      <mat-card class = "sidenavheader">
                          <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >
                            {{element.icontype}} </i>{{element.name}}</mat-card>
                      
                      
                      </div>
                     <div  *ngIf="element.type == 'category'"
                     (click)="this.configSvc.getToolbars(element.type, element._id, element.teamid)"> 
                     <mat-card class = "sidenavheader"><i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >
                       folder_special</i>{{element.name}}</mat-card>
                    </div>
                  
                
                  
     
                 
                  
                  </td>
              </ng-container>
    
         
    
    
        </table>
       
      </mat-card>
     </div>
                                      
  
