import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-toolbard',
  templateUrl: './toolbard.component.html',
  styleUrls: ['./toolbard.component.css']
})
export class ToolbardComponent implements OnInit {

  browseColumns = [ 'ToolbarColumn'];

  constructor(public configSvc: ConfigService) { }

  ngOnInit() {
  }

  getToolbars(type, itemid, teamid){
   
    console.log('the parenttoolbar is: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars(type, itemid, teamid); 
    // this.configSvc.activetype= type; console.log('active type is set to'+type)
    this.configSvc.toolmenu = 'toolbartools'; console.log('active toolmenu is set to toolbartools')
    this.configSvc.favtools = false
    console.log('toolbard favtools is set to '+this.configSvc.favtools)
  }

  getParenttoolbar(){
    console.log('the parenttoolbar is: '+JSON.stringify(this.configSvc.parenttoolbar[0]))
    this.configSvc.getToolbars(this.configSvc.parenttoolbar[0].type, 
      this.configSvc.parenttoolbar[0].itemid,this.configSvc.parenttoolbar[0].teamid);
  }

}
