<div class="signin-content">
    <mat-card>
      <mat-card-content>
Welcome to {{ authService.getUserDetails()?.companyname}}
<div>
Made with Toolbard
<div><i class="material-icons icon-createteamactive"  title="Create a new Team">build_circle</i></div>
</div>
Please click on one of the teams on the left hand side to begin.


</mat-card-content>
</mat-card>
</div>
