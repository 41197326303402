<span>
    <form [formGroup]="kanbanFG">

    <div class = "container" *ngIf="this.configSvc.folderdetailsds?.kantype == 'time' || this.configSvc.folderdetailsds?.kantype == 'standard'">
    <i class="material-icons icon-addfolderactive" [ngStyle]="{'color': 'green'}">schedule</i>
  
   
  Time worked on {{data.kiname}}
    <mat-form-field appearance="outline" floatLabel="always" class = "small-item">  
      <mat-label >Hours</mat-label>
      <input 
      formControlName ="kitime"
      matInput type="number"
      matInput value="{{data.kitime}}">
     </mat-form-field>

    </div>

    <div class = "container" *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneydollar'">
      <i class="material-icons icon-addfolderactive" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">monetization_on</i>
    
     
    Time worked on {{data.kiname}}
      <mat-form-field appearance="outline" floatLabel="always" class = "small-item">  
        <mat-label >Dollars</mat-label>
        <input 
        formControlName ="kitime"
        matInput type="number"
        matInput value="{{data.kitime}}">
       </mat-form-field>
  
      </div>

      <div class = "container" *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneypound'">
        <i class="material-icons icon-addfolderactive" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">currency_pound</i>
      
       
      Time worked on {{data.kiname}}
        <mat-form-field appearance="outline" floatLabel="always" class = "small-item">  
          <mat-label >Pounds</mat-label>
          <input 
          formControlName ="kitime"
          matInput type="number"
          matInput value="{{data.kitime}}">
         </mat-form-field>
    
        </div>
  
    <div *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneydollar'" class = "container ">
      <i class="material-icons icon-addfolderactive" [ngStyle]="{'color': 'green'}">monetization_on</i>
    
     
     {{data.kiname}} Total
      <mat-form-field appearance="outline" floatLabel="always" class = "small-item">  
        <mat-label >Total</mat-label>
        <input 
        formControlName ="kiworker"
        matInput type="number"
        matInput value="{{data.kiworker}}">
       </mat-form-field>
     
      </div>
   
  
   </form>
       
  
   
   
  <div mat-dialog-actions class = "actions">

    <button class ="btns" matSuffix mat-raised-button color="primary" [disabled]="!kanbanFG.dirty" 
    (click)="updateKanitem(this.configSvc.activeitemid, data.kidata,this.kanbanFG.value.kiworker, this.kanbanFG.value.kitime,  data.track);">Apply Changes</button>

   <button matSuffix mat-raised-button color="primary"(click)="onNoClick()">Close</button>
  
    </div>
  
  
  </span>
  
  