
<div *ngIf="this.configSvc.kanbanready == true" cdkDropListGroup>
    <div class="board" >
    
      <div  class="kanban-container"
      
      *ngFor="let track of this.sortKanban()" 
      cdkDropList [id]="track.trackorder" 
      [cdkDropListData]="track.kanitems"
      [cdkDropListConnectedTo]="tracknames" 
      (cdkDropListDropped)="onTaskDrop($event)" 
      
      cdkDragHandle>
    
      <mat-card><span class= "tracks">
        <h2  class="mat-h2">{{track.track}}</h2>
        <span *ngIf="this.configSvc.activetype == 'fold'" >
        <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}"
        matTooltip={{track.desc}}  matTooltipPosition= 'after'
        >info</i></span>
        <span *ngIf="this.configSvc.activetype == 'nodetab'" >
          <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.kanbancolor}"
          matTooltip={{track.desc}}  matTooltipPosition= 'after'
          >info</i></span>
      
      
      
      </span>
       </mat-card>
    
     
       
    
            <mat-card class ="drag-items" *ngFor="let element of track.kanitems"  cdkDrag>
              <!-- Use the mat-card-content to add the proper spacing. -->
              <span class="flex-container"  (dblclick)="this.openDialog(element, track.track)">
              <div class = "items">
                <div *ngIf="element.kitype == 'node' || element.kitype == 'nodetab'"><i class="material-icons md-48" [ngStyle]="{'color': element.kiclr || 'black'}" >{{element.kiicon}} </i></div>
                <div *ngIf="element.kitype == 'askchatgpt'">    <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"
            
                  ></mat-icon></div>
                <div *ngIf="element.kitype == 'pdf'" class="fappt
                -2x"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i></span></div>
                <div *ngIf="element.kitype == 'msg'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span>
                    <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i><span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div>
                </div>
                 <div *ngIf="element.kitype == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                <div *ngIf="element.kitype == 'xlsx'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                <div *ngIf="element.kitype == 'docx'" class="fa-2x"><span class="fa-layers fa-fw">
                        <span><i class="fas fa-file-word" data-fa-transform="shrink-5" style="color:blue"></i></span></span>
                      </div>
              
                <div *ngIf="element.kitype == 'bmp' || element.kitype == 'jpg' ||  element.kitype == 'png'  " class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
              
                  {{element.kiname}}
  
                  <span class ="badge-container">
                    <span *ngIf=" this.configSvc.folderdetailsds.kantype == 'standard' && element.kitime != 0" class ="badge"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}"  matBadge="{{element.kitime}}" matBadgePosition="after" matBadgeColor="accent">schedule</i></span>
                    <span *ngIf=" this.configSvc.folderdetailsds.kantype == 'moneydollars'" class ="badge">  <i class="material-icons md-48" [ngStyle]="{'color': 'green'}">monetization_on</i>{{element.kiworker}}</span>
                    <span *ngIf=" this.configSvc.folderdetailsds.kantype == 'moneypounds'" class ="badge">  <i class="material-icons md-48" [ngStyle]="{'color': 'green'}">currency_pound</i>{{element.kiworker}}</span>
                  
                  </span></div>
                 </span>
            </mat-card>
    
       
    
      </div>
    
</div>

<mat-card *ngIf="this.configSvc.kanbanready == false" class = "results-container">
      


  <mat-spinner></mat-spinner>
</mat-card>
    