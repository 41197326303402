import { Component, OnInit, Inject, Input } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import { DocactionsService } from '../../../services/docactions.service';
import {TagService} from '../../../services/tag.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TagerService} from '../../../services/tager.service';
import {UploadService} from '../../../services/upload.service';
import { NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import { DoccommentsService } from '../../../services/doccomments.service';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import { AuthenticationService } from '../../../services/authentication.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { jsPDF, AcroFormCheckBox } from "jspdf";
import {SidenavService} from '../../../services/sidenav.service';





export interface DialogData {
 
  name: string;
  type: string;
}

@Component({
  selector: 'app-filetools',
  templateUrl: './filetools.component.html',
  styleUrls: ['./filetools.component.css']
})
export class FiletoolsComponent implements OnInit {

  

  constructor(public configSvc: ConfigService, public docActions: DocactionsService, public sidenavSvc: SidenavService,
    public dialog: MatDialog, public tagerSvc: TagerService, public auth: AuthenticationService,) { }

  ngOnInit() {

    
   
    
  }

  getrootToolbars(){
    this.sidenavSvc.toggle('navigation')
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sidenavSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
  }

  

  openDialog(type, name): void {

    console.log('popup name is'+name)
    console.log('popup type is'+type)
    console.log('aidata before sending comment: '+this.configSvc.nodeds[0].aidata)
    this.configSvc.tagupload = true;
var popupheight = '500px'
var popupwidth= '400px'
    if(type == 'deletefold' || type == 'deletefile' || type == 'deletetageritem' || type =='cutitem' || type =='pasteitem'){popupheight = '150px'}
    if(type == 'folder' || 'bulkadd'){popupheight = '450px'; popupwidth = '500px'}
    if(type == 'addfiles'){popupwidth = '700px'}
    if(type == 'folderdetails'){popupwidth = '1200px'}
    if(type == 'report'){popupwidth = '700px'}
    if(type == 'createteam'){
      popupheight = '650px'; popupwidth= '1200px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'team';
    this.configSvc.activetype = 'createteam';
    }
    if(type == 'comment'){
       popupheight = '650px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'orange'
      this.configSvc.commenticon = 'chat'
    }

   

   
    const dialogRef = this.dialog.open(FilePopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.tagupload = false;
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

  hideBrowse(){
    //this.tagerSvc.kibrowse=false;
  //  this.configSvc.buildtool = true;
    console.log('kibrowse is now: '+this.tagerSvc.kibrowse)
  }


  

  
  


}

@Component({
  selector: 'app-filepopup',
  templateUrl: 'filepopup.html',
  styleUrls: ['./filetools.component.css']
})
export class FilePopup {
  public username = '';
  public cmtIcon = 'cmt'
  
  public attachreport = false;
  @Input() teamtags: boolean;
  @Input() foldertags = true;
  @Input() kanbanned: boolean;
  public indextype = '0';
  
  isLinear = true;
  step1 = "step1";
  step2= "step2";

  @Input() teamtype: string;
 // isLinear = true;
 // step1 = "step1";
 // step2= "step2";
  step3= "step3";
  step4= "step4";
  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;

    fileFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    public csvRecords: any[] = [];
    public header = true;
    textarearowmin = 10;
    texarearowmax = 20;
    public mimetypes = ['jpg', 'png', 'gif', ]
    public sizes = ['256x256', '512x512','1024x1024' ]

  constructor(private _formBuilder: FormBuilder, private router: Router, 
    public dialogRef: MatDialogRef<FilePopup>, private doccomments: DoccommentsService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public tagSvc: TagService, private authService: AuthenticationService, 
    public configSvc: ConfigService, public tagerSvc: TagerService, private uploadSvc: UploadService,
    private ngxCsvParser: NgxCsvParser) {}

    ngOnInit() {
  
      this.fileFG = 
      this._formBuilder.group({
      
        assigned: false,
        assignedto: '',

        deadline: false,
        deadlineval: '',
       
        statelabel: 'Completed',
        statevalue: false , 
        username: '',

        cmticon: 'chat',
        cmtclr: 'blue',
        emoji: false,
        icontype: '',
        iconcolor: '',
        cmtval: '',

        
        atcmt: false,
        atcmtuser: '',
        attachreport: false,
        teamtags: [],
        foldertags: [],
        newimage: false,
        edit: false,
        mt: 'jpg',
        size: '256x256',
        
      
      });

      if(this.configSvc.nodeds[0].imageby == 'chatgpt'){
      //  this.fileFG.patchValue({edit: true})
     //   console.log('this is the edit '+this.fileFG.value.edit)
        this.fileFG.patchValue({size: this.configSvc.nodeds[0].size})
        console.log('this is the size '+this.fileFG.value.size)
        this.fileFG.patchValue({mt: this.configSvc.nodeds[0].mt})
        console.log('this is the mt '+this.fileFG.value.mt)
      }

    
      
      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public setState(statelabel, statevalue){

    this.configSvc.setState(this.configSvc.activeitemid, statevalue, statelabel, false, this.configSvc.folderdetailsds._id)
    this.dialogRef.close();
  }

 
  



 
  createCardbyid(tagerid, tagertype, name): void {
    console.log('saving tageritem now'+name)
    console.log('saving tageritem id'+tagerid)
    console.log('saving tageritem id with level set to '+this.configSvc.activelevel)
    console.log('saving tageritem id with teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createCardbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }

  createStepsbyid(tagerid, tagertype, name): void {
    console.log('creating Steps now'+name)
    console.log('id'+tagerid)
    console.log('level set to '+this.configSvc.activelevel)
    console.log(' teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createStepsbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }
  
  /*
  addFolder(name, indextype){
    this.configSvc.addFolder('fold', name, desc,
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');
      this.dialogRef.close();
  }
  */
/*
  public dropped(files: NgxFileDropEntry[]) {
    this.uploadSvc.totalfilesuploaded = files.length
    console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
    this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
    this.uploadSvc.filedropped(files, this.indextype.toString(), this.configSvc.textract, 'fileupload');

  // this.dialogRef.close();
    }
    */

    /*
    public bulkprocess(files: NgxFileDropEntry[]) {
      console.log('bulk processing now')
      this.uploadSvc.bulkprocess(files);
    }
*/
    public bulkprocesslocal(files: NgxFileDropEntry[], indextype) {
      console.log('bulk processing locally with indextype of'+indextype)
      for (const droppedFile of files) {


      
          
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  
  
          fileEntry.file((fileext) => {
            console.log('cwhat is going on here');
      //start of parse process
      this.ngxCsvParser.parse(fileext, { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
 
        console.log('Result', result);
        this.csvRecords = result;

        for(const csvitem of this.csvRecords){
         console.log('this is the skeleton indexes'+JSON.stringify(this.configSvc.teamdetailsds.stepitems))
        console.log('csvitem is: '+csvitem.Name)
        console.log('this is the csv row: '+JSON.stringify(csvitem))
        this.configSvc.totalcsvrows = this.csvRecords.length;
    console.log('totalcsvrows is: '+this.configSvc.totalcsvrows)
    
        let name = csvitem.name
        let desc = csvitem.desc
        if(this.configSvc.activetype == 'fold' && this.configSvc.folderdetailsds.tagged){
          console.log('this is folder tagged')
        this.configSvc.newpropvalues = this.configSvc.folderdetailsds.stepitems}
        if(this.configSvc.activetype == 'team' && this.configSvc.folderdetailsds.tagged){
          console.log('this is team tagged')
          this.configSvc.newpropvalues = this.configSvc.teamdetailsds.stepitems}

          if(this.configSvc.activetype == 'fold' && !this.configSvc.folderdetailsds.tagged){
            console.log('this is a plain old folder')
          }

        for(const propertyitem of this.configSvc.newpropvalues )
        {

          console.log('property is'+propertyitem.formitem);
            let csvprop= propertyitem.formitem
            console.log('csvprop is'+csvprop);
          console.log('property value is'+propertyitem.itemvalue);
          propertyitem.itemvalue = csvitem[csvprop];
          console.log('newly update property value is'+propertyitem.itemvalue);
        }

        console.log('this is the populated new skeleton indexes'+JSON.stringify(this.configSvc.newpropvalues ))
        
          this.configSvc.addFolder('fold', name, desc, 
          this.configSvc.activeitemid, this.configSvc.activename, 
          this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');


        }
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      //end of parse process
    });
     
  }
    }
 
  
    
    
      public fileOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

  public addComment(ischatgpt: boolean) {
    
console.log('ischatgpt value is '+ischatgpt)
console.log('aidata before sending comment: '+this.configSvc.nodeds[0].aidata)
 
    if(this.fileFG.value.cmticon == 'thumb_up'){this.configSvc.color = 'green'}
    if(this.fileFG.value.cmticon == 'cmt'){this.configSvc.color = 'black'}
    if(this.fileFG.value.cmticon == 'thumb_down'){this.configSvc.color = 'blue'}
    if(this.fileFG.value.cmticon == 'priority_high'){this.configSvc.color = 'red'}
    if(this.fileFG.value.emoji){ 
      console.log('changed to custom emoji')
      this.fileFG.patchValue({cmticon: this.configSvc.chosenIcon})}
      console.log('aidata before sending comment: '+this.configSvc.nodeds[0].aidata)
  this.doccomments.addcomment('file', false, this.configSvc.activetype, this.fileFG.value.cmtval, this.fileFG.value.cmticon, this.configSvc.color,  this.fileFG.value.atcmt, this.fileFG.value.atcmtuser, ischatgpt);
}

userSelected(user){
  console.log('this is the selected user '+JSON.stringify(user))
  this.fileFG.patchValue({assignedto: user.inboxemail})
  console.log('this is the actual selected user '+this.fileFG.value.assignedto)
this.configSvc.userready =true
        }

onIconChange(cmticon){
  this.fileFG.value.cmticon = cmticon
  console.log('changing the cmticon '+cmticon)
   if(cmticon == 'thumb_up'){this.configSvc.color = 'green'}
   if(cmticon == 'cmt'){this.configSvc.color = 'black'}
   if(cmticon == 'thumb_down'){this.configSvc.color = 'blue'}
   if(cmticon == 'priority_high'){this.configSvc.color = 'red'}

}

   public setDeadline(deadline){

    this.configSvc.setDeadline(this.configSvc.activeitemid, deadline, true, this.configSvc.folderdetailsds._id)
    this.dialogRef.close();
    
  }

   usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
 }
 
}

public setAssignedto(){
  console.log('setting setAssignedto '+this.fileFG.value.assignedto)
  this.configSvc.setAssignedto('node', this.configSvc.nodeds[0]._id, this.fileFG.value.assignedto, false, this.configSvc.folderdetailsds._id)
  this.dialogRef.close();
}


   public dropversion(files: NgxFileDropEntry[]) {
    this.uploadSvc.dropversion(files);
    this.dialogRef.close();
    }

    
    
      public fileverOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

      public fileverLeave(event) {
        console.log(event);
    
      }

      public delete(deletetype){

        if(this.configSvc.activetype == 'fold' && deletetype == 'folder'){
         
          console.log('this is the pathds'+JSON.stringify(this.configSvc.pathds));
          console.log('this is the current level'+this.configSvc.activelevel);
          console.log('this is the parent path'+JSON.stringify(this.configSvc.pathds[this.configSvc.activelevel-1]));
          var parentpath = [this.configSvc.pathds[this.configSvc.activelevel-1]]
          console.log('this is the parentpath'+JSON.stringify(parentpath));
          var parentitemid = parentpath[0].path.pfoldid
          console.log('this is the parentitemid'+parentitemid);
          var parenttype = parentpath[0].path.type
          var parentname = parentpath[0].path.name
          var parentlevel = parentpath[0].path.level
          var parentteamid = parentpath[0].path.teamid
        console.log('deleting folder '+JSON.stringify(this.configSvc.folderdetailsds.parentid));
        this.configSvc.deleteFolder(this.configSvc.activeitemid, this.configSvc.folderdetailsds.parentid, this.configSvc.activeteamid)
       // this.router.navigateByUrl(this.configSvc.currentURL);
       this.router.navigate(['/toolbard/home'], { queryParams: { itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid } });
        //this.router.navigate(["/toolbard/home" [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}" ></a>
      }
        if(deletetype == 'item' && this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, true ,this.configSvc.currentrow.activetrack )
        }

        if(deletetype == 'item' && !this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting non kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, false )
        }
         

        this.dialogRef.close();
      }

      public findUser() {
        this.finduserclicked = true;
        this.configSvc.findUser(this.usersearchfc.value);
       // console.log('userdetailset is' + this.configSvc.userdetailset);
       // this.dbuser = this.configSvc.userdetailset.name;
       // this.dbemail = this.configSvc.userdetailset.email;
      }
  
      drop(event: CdkDragDrop<string[]>) {
     
       
        transferArrayItem(this.configSvc.finduserdataset, this.configSvc.usersadded, event.previousIndex, event.currentIndex);
        console.log('finished transferring users to selected user');
        console.log('current selected user list'+JSON.stringify(this.configSvc.usersadded));
        //this.configSvc.userselected.push(this.configSvc.usersadded._id);
    }
  
    public addMyself(){
      this.configSvc.mgrsadded = [];
      const userdetails  = this.authService.getUserDetails();
      console.log('this is the managers details'+JSON.stringify(userdetails))
      const username = userdetails.username
      this.configSvc.mgrsadded.push(username)
      console.log('activetype after adding myself ist'+this.configSvc.activetype)
     // this.configSvc.findUser(mgremail, 'addmyself');
     // this.myself = userdetails;
     this.configSvc.activetype = 'team'
     this.configSvc.usersadded = []
    // this.configSvc.mgrsadded.push(userdetails);
  
  
    }
   
   

    cutItem(itemid, type, level, teamid, name, icontype, iconcolor,sourcekanban){
      console.log('moving itemid '+itemid)
      console.log('moving item name '+name)
      console.log('moving item icontype '+icontype)
      console.log('moving item iconcolor '+iconcolor)
      this.configSvc.move = true;
      this.configSvc.moveitem = [];
      if(sourcekanban == true){
        this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
         'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': this.configSvc.folderdetailsds._id})
        console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}
  
        if(sourcekanban == false){
          this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
           'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': ''})
          console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}
  
      this.dialogRef.close();
     
    }

    pasteItem(itemid,parentfolderid, type, level, teamid, name, icontype, iconcolor, sourcekanban, sourcekanbanid){

      console.log('icontype is '+icontype); console.log('iconcolor is '+iconcolor)
      this.configSvc.moveItem(itemid, parentfolderid, type, level, teamid, name, icontype, iconcolor,  sourcekanban, sourcekanbanid )
      this.dialogRef.close();
    }

    moveFolder(itemid, name){
      console.log('moving folder')
      this.configSvc.move = true;
      this.dialogRef.close();
    }

    folderReport(attachreport){
      // Default export is a4 paper, portrait, using millimeters for units
      console.log('starting to write report')
      console.log('this is the contents of the folder '+JSON.stringify(this.configSvc.teamds))

      var doc = new jsPDF();
   
     // var {CheckBox} = jsPDF.;
    // doc.advancedAPI(doc => {
      // your code
    
 
  
      doc.text('Eddison Inspections', 20, 20);
      doc.text('This a a report for all assets in folder '+this.configSvc.activename, 20, 30);
      doc.text('page ' + 1, 170,285);
    
     // doc.text('Do you like that?',20, 20);
     var pagecnt = 2;
     
     for(var i = 0; i < this.configSvc.teamds.length; i++){

       var asset = this.configSvc.teamds[i]
      console.log('asset desc is '+asset.desc)
      doc.addPage();

      doc.text('page ' + pagecnt++, 170,285);
      var assetname: string = String(asset.name);
      var assetdesc: string = String(asset.desc);
      doc.text(assetname+' '+assetdesc, 10, 20);
     
     

      var ab: string = String(asset.ab);
      var ti: string = String(asset.ti);
      var bo: string = String(asset.bo);
      doc.text('AssetID: ' +ti+' created by ' +ab+  ' based on:' +bo, 10, 40);

var location =  50;
      Object.keys(this.configSvc.teamds[i].stepitems).forEach((i2) => {
      //  var tag = asset.stepitems[i2]
      //  console.log('tag  is '+JSON.stringify(tag))
      //  console.log('formitemlabel  is '+JSON.stringify(tag))
location = location +10;
// console.log('i2 value is '+i2)
if(i2 == '0'){
  doc.text( 'Engineer carried out the following checks on site: ', 15, location);
  location = location +10
}

if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
const checkBox =new AcroFormCheckBox();
checkBox.fieldName = "CheckBox";
checkBox.x = 5;
checkBox.y = location-10;
checkBox.width = 5;
checkBox.height = 15;
checkBox.maxFontSize = 15;
checkBox.readOnly = true;
checkBox.textAlign = 'center';
// checkBox.value = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
// checkBox.caption = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
//checkBox.fieldName = this.configSvc.teamds[i].stepitems[i2].formitemlabel;

if(!this.configSvc.teamds[i].stepitems[i2].hasdefault){
checkBox.color = 'red';


}
doc.addField(checkBox);
      }


      var formitemlabel: string = String(this.configSvc.teamds[i].stepitems[i2].formitemlabel);
      if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
       
        doc.text( formitemlabel, 15, location);}

        if(this.configSvc.teamds[i].stepitems[i2].formtype == 'string' ){

          var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
          doc.text( formitemlabel+ ':' +itemvalue, 15, location);
        }

        
        if(this.configSvc.teamds[i].stepitems[i2].formtype == 'boolean' ){
if(this.configSvc.teamds[i].stepitems[i2].hasdefault){
          var hasdefault: string = String(this.configSvc.teamds[i].stepitems[i2].hasdefault);
          doc.text( 'This asset has NOT '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, 15, location+10);
        }
       
        if(!this.configSvc.teamds[i].stepitems[i2].hasdefault){
          var hasdefault: string = String(this.configSvc.teamds[i].stepitems[i2].hasdefault);
          doc.text( 'This asset has '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, 15, location+10);
        }
      }
      
    })

    Object.keys(this.configSvc.teamds[i].comments).forEach((i3) => {
      location = location +20;
      console.log('i3 value is '+i3)
      var cmt: string = String(this.configSvc.teamds[i].comments[i3].comment);
      var ab: string = String(this.configSvc.teamds[i].comments[i3].ab);
      doc.text( cmt+ ': commented by' +ab, 15, location);
      console.log('i3 value is '+i3)

    })
     
     }

      var title =this.configSvc.activename+" "+"Report.pdf";
      doc.save(title);
      console.log('finished writing report')
      var uploadedpdf = doc.output('blob');
      console.log('finished writing report')
      if(attachreport){
        console.log('filing report now')
      this.fileReport(uploadedpdf)
      }

//
    }

    public fileReport(doc) {
      var reportname =  this.configSvc.activename+' Folder Report.pdf';
      this.uploadSvc.totalfilesuploaded = doc.length
      console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
      this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
      this.uploadSvc.fileReport(doc, reportname);
      }

      public editnewImage(chatgptcomment, mt, size) {


        this.uploadSvc.editnewImage(chatgptcomment, this.configSvc.nodeds[0].name, mt, size, this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.folderdetailsds._id, this.configSvc.nodeds[0].ver);
               
 }

 public editexistingImage(chatgptcomment) {

  this.uploadSvc.editexistingImage(chatgptcomment,  this.configSvc.nodeds[0].mt,  this.configSvc.nodeds[0].size, this.configSvc.nodeds[0].pa, this.configSvc.nodeds[0].name, this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.folderdetailsds._id, this.configSvc.nodeds[0].ver);
 }

 

 
  

}
