import { Component, OnInit } from '@angular/core';
import {ConfigService} from '.././../services/config.service';
;
import {TagService} from '../../services/tag.service';
import {TagerService} from '../../services/tager.service';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {
  public hue: string;
  public color: string;
  public teamcolor = ['chooseteam'];


  constructor(public configSvc: ConfigService, public tagSvc: TagService, public tagerSvc: TagerService){

  }
  ngOnInit() {

    console.log('colorinputtype is set to '+this.configSvc.colorinputtype)

    if (this.configSvc.colorinputtype == 'kanban'){
      this.configSvc.color = 'green'
      this.configSvc.chosenIcon = 'view_column'
    }

    if (this.configSvc.colorinputtype == 'formfolder'){
      this.configSvc.color = 'yellow'
      this.configSvc.chosenIcon = 'folder'
    }
   // this.configSvc.color = 'black'
    console.log('quicadd set to '+this.configSvc.quickadd)
    
  }
  

 
}

