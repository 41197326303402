import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../../services/authentication.service';
import {DocactionsService} from '../../services/docactions.service';
import {UploadService} from '../../services/upload.service';
import {ConfigService} from '../../services/config.service';
import {FlowService} from '../../services/flow.service';
// import {SidenavService} from '../../services/sidenav.service';
import { SidenavService } from 'src/app/services/side-nav.service';

import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import {TagerService} from '../../services/tager.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  implements OnInit  {
  @ViewChild('sidenav', { static: true }) 
  public sidenav: MatSidenav;
  public sorted = [];
  mode = new FormControl('side');
  public lockFC = new FormControl();
  public navigatorFG: FormGroup;
  toggleActive = false;


  constructor(private _fB: FormBuilder, public auth: AuthenticationService, public docActions: DocactionsService, 
    private uploadSvc: UploadService, public flowSvc: FlowService,
    private router: Router,private activatedRoute: ActivatedRoute,  public tagerSvc: TagerService,
    public configSvc: ConfigService, public sidenavSvc: SidenavService) {

  }

  ngOnInit() {
    this.navigatorFG = this._fB.group({
      lockFC: [this.configSvc.setlocked]});

       console.log('init header for activetype '+this.configSvc.activetype)

       if(this.configSvc.activetype == 'queuedirect'){
         this.configSvc.nodeds = []
        var base = '/toolbard/teamqueue?'
        var itemid =  this.flowSvc.queueds[0]._id
        var name = this.flowSvc.queueds[0].name
        var teamid = this.flowSvc.queueds[0].teamid;
      this.configSvc.queuedirecturl = base+'itemid='+itemid+'&type=queuedirect&name='+name+'teamid='+teamid
       
      console.log('init header queuedirecturl'+this.configSvc.queuedirecturl)
    }
  
  }

  
  

   public elementattach() {
    return this.docActions.currentattach.Filename;
   }

   public docadd() {
    return this.docActions.showprogress;
  }

  public showdetails() {
    return this.docActions.showdetails;
  }

  public showattdetails() {
    return this.docActions.showattdetails;
  }

  
  setActive(item: string){
    console.log('this is the item being processed'+JSON.stringify(item));
    if (item == 'addteam'){this.configSvc.activetype = 'addteam'; console.log('add team active now');} 
    if (item == 'addfolder'){this.configSvc.activetype = 'addfolder';console.log('addfolder active now');} 
    if (item == 'addfile'){this.configSvc.activetype = 'addfile';console.log('add file tool active now');}  
  
  
    
    
}



getPaths(){
  console.log('getting paths now')
 console.log('this is the current path array'+JSON.stringify(this.configSvc.pathds));
 return this.configSvc.pathds;
}

public pathSort(){
  this.sorted = [];
 // console.log('set sorted to blank')
// console.log('current path is set to'+JSON.stringify(this.configSvc.folderpath))
this.sorted = this.configSvc.folderpath.sort((a, b) => Number(a.level) - Number(b.level));
// console.log('this is the sorted result: '+JSON.stringify(this.sorted))
  return this.sorted

}

setActivetool(type){
  console.log('current type clicked '+type);
  // this.configSvc.buildtool = false; 
  this.configSvc.typeenabled = true;
  if(type == 'fold' || type == 'file'){
    this.configSvc.activetool = 'editfold'
  this.configSvc.activetype = 'fold'}

  /*
  if(this.configSvc.folderdetailsds.fot){
    console.log('this folder has got a task ');
    this.taskSvc.filetasksds =null;
                  this.taskSvc.getFileTasks(this.configSvc.activeitemid)
  }
  */
  if(type == 'team'){this.configSvc.activetool = 'editteam'}
 // if(type == 'file'){this.configSvc.activetool = 'file'}
  
}

public deselect(){
  console.log('deseelcting now')
  this.configSvc.deselect(this.configSvc.activetype)
}

public sortnodedirectPath(){

this.sorted = this.configSvc.nodeds[0].path.sort((a, b) => Number(a.level) - Number(b.level));
//console.log('this is the sorted result: '+JSON.stringify(this.sorted))
  return this.sorted
}

public clickChip(){

 // this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];
 // this.configSvc.nodeds = [];this.configSvc.hltag = [];this.configSvc.hlstep = [];
  
  this.configSvc.toolmenu= 'designtools'
  if(this.configSvc.activetype == 'editstep'){
    this.configSvc.activetype = 'wizard'
    this.configSvc.stepclicked = true;
    
  this.configSvc.nodeteamds = this.configSvc.nodeds[0].stepper
  console.log('nodeteamds is now: '+JSON.stringify(this.configSvc.nodeteamds[0]))
  this.configSvc.stepdetailsds = [];
}
if(this.configSvc.activetype == 'node'){
  console.log('activetype is set to node' )
this.configSvc.editnode ='editnode'; this.configSvc.toolmenu = 'tagtools'
}

else{
 this.configSvc.stepclicked = false;

}

  
}

public clickFold(){
  console.log('active type is currently '+this.configSvc.activetype)
  
  this.configSvc.hlitem=[]; this.configSvc.nodeds=[]
  
}

public toggleCriteria(togglevalue){
  console.log('toggling criteria')
  this.configSvc.hc = !togglevalue
  console.log('hide crtieria is set to '+ this.configSvc.hc)
}

public deselectQueue(){
  console.log('deselecting queue')
  this.flowSvc.deselectQueue()
}

  }
