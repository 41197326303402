import { Component, OnInit, Inject, Input } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {FlowService} from '../../../services/flow.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';

export interface DialogData {
 
  name: string;
  type: string;
  desc: string;
}

@Component({
  selector: 'app-designtools',
  templateUrl: './designtools.component.html',
  styleUrls: ['./designtools.component.css']
})
export class designtoolsComponent implements OnInit {

  

  constructor(public configSvc: ConfigService, public dialog: MatDialog) { }

  ngOnInit() {
    
  }

  openNodetool(type, name): void {
this.configSvc.usersadded = [];this.configSvc.mgrsadded = [];
this.configSvc.assigned = []
    console.log('popup name is'+name)
    console.log('popup type is'+type)
    console.log('popup name is'+name)
    console.log(' teamtype= '+this.configSvc.teamtype)
    this.configSvc.formstyle = type
    console.log(' formstyle is'+this.configSvc.formstyle)
    if(this.configSvc.formstyle == 'smartfoldertab'){
    
      this.configSvc.colorinputtype = 'smartfolder'
      console.log(' color is now set to is'+ this.configSvc.color)
    }

var popupheight = '500px'
var popupwidth = '400px'
    if(type == 'deletecategory' || type == 'deletefile' || type == 'deletetageritem')
    {popupheight = '150px'}
    if(type == 'form' ){
      this.configSvc.colorinputtype = 'node'
      popupheight = '600px'; popupwidth = '800px'}

      if( type == 'prompt'){
        this.configSvc.colorinputtype = 'prompt'
        popupheight = '600px'; popupwidth = '60vw'}

      if(type == 'smartfolder' ){
        this.configSvc.colorinputtype = 'smartfolder'
        popupheight = '600px'; popupwidth = '800px'}

      if( type == 'kanban'){
        this.configSvc.colorinputtype = 'kanban'
        popupheight = '600px'; popupwidth = '800px'}

      if(type == 'flow'){
        this.configSvc.colorinputtype = 'node'
        popupheight = '600px'; popupwidth = '1000px'}



    if(this.configSvc.activetype == 'editflow'){popupheight = '500px'; popupwidth = '900px'}
    if(type=='nodeteam'){popupheight = '700px'; popupwidth = '900px'}
    if(type=='find'){popupheight = '500px'; popupwidth = '900px'}

    const dialogRef = this.dialog.open(DesignPopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.finditemfound = false
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

}

@Component({
  selector: 'app-designpopup',
  templateUrl: 'designpopup.html',
  styleUrls: ['./designtools.component.css']
})

export class DesignPopup {

  
  @Input() teamtype: string;
  isLinear = true;
  step1 = "step1";
  step2= "step2";
  step3= "step3";
  step4= "step4";
  step5= "step5";

  

  findstep = 'find1';
  assignstep = 'assign2'

  public searchtype = 'name';
  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;
    teamfileFG: FormGroup;
    public flowFG: FormGroup;
    public formFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;

public itemselected = false;
public itemsadded = [];
findColumns = [ 'icontype', 'name', 'type', 'desc', 'ab', 'ao'];
searchtypes = ['foldertags', 'kanban', 'flow','node', 'stepper' ]
public searchtypesel = new FormControl('node');
public newname: string
public searchname = '';
public flowtypes = ['basicflow', 'wizardflow', 'tabflow']
public formcopy = 'New';
public formcopytype = ['Create New', 'Copy Existing']
public statetracker = false;

public stateicon = 'check_circle';
public statelabel = 'Completed?';
public reset: any[] = [{}];
public indexready = false;


  constructor(private router: Router,private _formBuilder: FormBuilder,private authService: AuthenticationService,
    public dialogRef: MatDialogRef<DesignPopup>,  public flowSvc: FlowService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public configSvc: ConfigService) {}

    ngOnInit() {
  
      this.configSvc.formtypeSelected = 'basic'
     
      this.teamfileFG = this._formBuilder.group({
        teamname: [''],
        teamdescription : '',
        colors: 'black',
        checked: false,
        teams: [],
        tagstep: 1,
       
      });

      this.flowFG = this._formBuilder.group({
       flowname: [''],
        flowdesc : '',
        formcopy : 'New',
        flowtype: 'basicflow',
        icontype: '',
        iconcolor: '',
       
      });

      this.formFG = this._formBuilder.group({
        formname: '',
     
         formdesc : '',
         formcopy : 'New',
         formtype: 'basic',
         icontype: '',
         iconcolor: '',
     settotal :false,
     timetotal: 40,
     kantype: 'standard',
         indexname: '',
         textractable: false,
         textractype: 'form',
         ischatgpt: false,
         website: false,
         weblink: 'https://www'
        
       });



    

      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addCategory(name){
    this.configSvc.addCategory(name, 
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel);
      this.dialogRef.close();
      
  }


      public delete(deletetype){

        if(this.configSvc.activetype == 'fold' && deletetype == 'folder'){
         
          console.log('this is the pathds'+JSON.stringify(this.configSvc.pathds));
          console.log('this is the current level'+this.configSvc.activelevel);
          console.log('this is the parent path'+JSON.stringify(this.configSvc.pathds[this.configSvc.activelevel-1]));
          var parentpath = [this.configSvc.pathds[this.configSvc.activelevel-1]]
          console.log('this is the parentpath'+JSON.stringify(parentpath));
          var parentitemid = parentpath[0].path.pfoldid
          console.log('this is the parentitemid'+parentitemid);
          var parenttype = parentpath[0].path.type
          var parentname = parentpath[0].path.name
          var parentlevel = parentpath[0].path.level
          var parentteamid = parentpath[0].path.teamid
        console.log('deleting folder '+JSON.stringify(this.configSvc.folderdetailsds.parentid));
        this.configSvc.deleteFolder(this.configSvc.activeitemid, this.configSvc.folderdetailsds.parentid, this.configSvc.activeteamid)
       // this.router.navigateByUrl(this.configSvc.currentURL);
       this.router.navigate(['/toolbard/home'], { queryParams: { itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid } });
        //this.router.navigate(["/toolbard/home" [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}" ></a>
      }
        if(this.configSvc.activetype == 'file'&& deletetype == 'file'){
          console.log('deleting file '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteFile(this.configSvc.currentrow._id, this.configSvc.activeitemid)
        }
          if(this.configSvc.activetype == 'tageritem' || this.configSvc.activetype == 'edittager'){
            console.log('deleting tageritem '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteFile(this.configSvc.currentrow._id, this.configSvc.activeitemid)}

        this.dialogRef.close();
      }
      public onRecreate(){
        this.reset[0] = {}
      }

      indexFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        console.log('filtering index on: '+filterValue)
        if (filterValue != '')
        {
          console.log('filter value befoore sending is : '+filterValue)
        this.configSvc.findTemplate(filterValue)
      console.log('indexFilter value is currently set to '+this.configSvc.finditemds)
    
      }
    }

    indexSelected(index){
      console.log('this is the selected index '+JSON.stringify(index))
      this.configSvc.nodeds[0] = index
      this.onRecreate()
       
      this.indexready =true
      if(index.type == 'basicflow' || index.type == 'editstepperassign' || index.type == 'edittabbedassign'){
        this.formFG.patchValue({flow: true, assigned: false })
        console.log('folderfg flow value: '+this.formFG.value.flow)
      }
            }

      public createNode(nodename, desc, type){
        var nodestyle
        if(type == 'basic'){type = 'editnode'; nodestyle = 'form'}
       
        if(type == 'tabbed'){type = 'nodetab';nodestyle = 'tabbed' }
        if(type == 'kanban'){nodestyle = 'kanban';  }
        if(type == 'batchitems'){nodestyle = 'none';  }
       this.configSvc.createNode(nodename, desc, type, nodestyle, this.formFG.value.textractable, this.formFG.value.textractype, this.formFG.value.ischatgpt, this.formFG.value.website, this.formFG.value.weblink)
        
          
    


        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }
      public createKanban(nodename, desc, type, kantype, settotal, timetotal){
    
   
       
       
       
       
          
          this.configSvc.createKanban(nodename, desc, type, kantype, settotal, timetotal)


        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }

      public createIndexer(nodename, desc, type){
        var nodestyle
        if(type == 'basic'){type = 'editnode'; nodestyle = 'form'}
        if(type == 'stepper'){type = 'wizard'; nodestyle = 'wizard'}
        if(type == 'tabbed'){type = 'nodetab';nodestyle = 'tabbed' }

        this.configSvc.createIndexer(nodename, desc, type, nodestyle )
        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }



      public createsmartfolder(nodename, desc, type){
        console.log('creating smartfolder of type '+type)
        var smartfolderstyle
       
        if(type == 'basic'){  type = 'smartfolder'; smartfolderstyle = 'form'}
      
        if(type == 'tabbed'){ type = 'smartfoldertab'; smartfolderstyle = 'tabbed'}
        this.configSvc.createNode(nodename, desc, type, smartfolderstyle, this.formFG.value.textractable, this.formFG.value.textractype, this.formFG.value.ischatgpt, this.formFG.value.website, this.formFG.value.weblink)
        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }

      public createFormassign(nodename, desc, type){
        console.log('creating formassign of type '+type)
        var formassignstyle
        if(type == 'basic'){type = 'basicflow'; formassignstyle = 'form'}
        if(type == 'stepper'){type = 'editstepperassign'; formassignstyle = 'wizard'}
        if(type == 'tabbed'){type = 'edittabbedassign'; formassignstyle = 'tabbed'}
        this.configSvc.createNode(nodename, desc, type, formassignstyle, this.formFG.value.textractable, this.formFG.value.textractype, this.formFG.value.ischatgpt, this.formFG.value.website, this.formFG.value.weblink)
        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }

      public createFlow(nodename, desc, type){
        console.log('creating formassign of type '+type)
        var formassignstyle
       
        this.flowSvc.createFlow(nodename, desc, type )
        this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];this.configSvc.nodeds = [];
        this.dialogRef.close();
      }


      public addTrack(trackname, desc, type){
        this.configSvc.addTrack(trackname, desc, type)
        this.dialogRef.close();
      }



      public findTemplate(itemname){
        this.itemselected = false;this.itemsadded = []; 
        console.log('off to find the item based on this query '+itemname)
        
        this.configSvc.findTemplate(itemname)
      }

      public selectItem(item){
        this.itemselected = true;
        console.log('is item selected? '+this.itemselected)
        this.itemsadded = []
this.itemsadded.push(item)
console.log('selected items '+JSON.stringify(this.itemsadded))
console.log('planning to add them to  '+JSON.stringify(this.configSvc.activeitemid))
this.newname = 'Copy of '+this.itemsadded[0].name;
      
      }

      public addtoFlow(){
       // var assignedteam = [];
      
     // assignedteam = this.configSvc.assigned[0]
      console.log('assigned team: '+JSON.stringify(this.configSvc.assigned[0]))
      //  console.log('off to add these items '+JSON.stringify(this.itemsadded))
      console.log('icontype of added item  '+JSON.stringify(this.itemsadded[0].icontype))
      console.log('iconcolor of add ed item  '+JSON.stringify(this.itemsadded[0].iconcolor))
      console.log('type of added item  '+JSON.stringify(this.itemsadded[0].type))
      console.log('name of added item  '+JSON.stringify(this.itemsadded[0].name))
        console.log('off to add these itemid of added item  '+JSON.stringify(this.itemsadded[0]._id))
        console.log('adding them to  '+JSON.stringify(this.configSvc.activeitemid))
        this.configSvc.addtoFlow(this.configSvc.activeitemid, this.itemsadded[0]._id, this.itemsadded[0].name,
          this.itemsadded[0].type, this.itemsadded[0].icontype, this.itemsadded[0].iconcolor, this.configSvc.assigned)
          this.dialogRef.close();
        }

        public addMyself(){
          const userdetails  = this.authService.getUserDetails();
          console.log('this is the managers details'+JSON.stringify(userdetails))
          const username = userdetails.username
          console.log('active type is'+this.configSvc.activetype)
          this.configSvc.mgrsadded.push(username)
      
      
        }

public copyDesign(activeitemid, teamid, level, formid, formtype,  newname, desc, icontype, iconcolor, statetracker, statelabel){


this.configSvc.copyDesign(activeitemid, teamid, level, formid, formtype, newname, desc, icontype, iconcolor, statetracker, statelabel)
this.dialogRef.close();
}

public changeFormtype(formtype: string){
  console.log('changed form type to '+formtype)
  this.configSvc.formtypeSelected = formtype; 
  console.log('formtypeSelected is '+this.configSvc.formtypeSelected)
  console.log('formstyleis '+this.configSvc.formstyle)
  
  if(formtype == 'basic'  && this.configSvc.formstyle == 'form') {
    console.log('this is a basic form')
    this.configSvc.chosenIcon = 'assignment'; this.configSvc.color = 'blue'}
  if(formtype == 'stepper' && this.configSvc.formstyle == 'form') {
    console.log('this is a wizard form')
    this.configSvc.chosenIcon = 'assignment'; this.configSvc.color = 'green'}
  if(formtype == 'tabbed' && this.configSvc.formstyle == 'form') {
    console.log('this is a tabbed form')
    this.configSvc.chosenIcon = 'assignment'; this.configSvc.color = 'red'}

  if(formtype == 'basic'  && this.configSvc.formstyle == 'smartfolder') {
    console.log('this is a basic form folder')
    this.configSvc.chosenIcon = 'assignment_returned'; this.configSvc.color = 'blue'}
  if(formtype == 'stepper' && this.configSvc.formstyle == 'smartfolder') {
    console.log('this is a wizard form folder')
    this.configSvc.chosenIcon = 'assignment_returned'; this.configSvc.color = 'green'}
  if(formtype == 'tabbed' && this.configSvc.formstyle == 'smartfolder') {
    console.log('this is a tabbed form folder')
    this.configSvc.chosenIcon = 'assignment_returned'; this.configSvc.color = 'red'}

    if(formtype == 'basic'  && this.configSvc.formstyle == 'formassign') {
      console.log('this is a basic assign folder')
      this.configSvc.chosenIcon = 'assignment_ind'; this.configSvc.color = 'blue'}
    if(formtype == 'stepper' && this.configSvc.formstyle == 'formassign') {
      console.log('this is a wizard assign folder')
      this.configSvc.chosenIcon = 'assignment_ind'; this.configSvc.color = 'green'}
    if(formtype == 'tabbed' && this.configSvc.formstyle == 'formassign') {
      console.log('this is a tabbed assign folder')
      this.configSvc.chosenIcon = 'assignment_ind'; this.configSvc.color = 'red'}



}

public createBatchitems(){
  console.log('creating batch items')
}

      }