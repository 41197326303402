import { Component, OnInit } from '@angular/core';
import {Tagmodel} from '../../model/tagmodel';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ConfigService} from '../../services/config.service';
import { TagService } from '.././../services/tag.service';
import {DocSearchService} from '../../services/docsearch.service';
import { AuthenticationService} from '../../services/authentication.service';
import { Router, ActivatedRoute, NavigationExtras  } from '@angular/router';
import * as moment from 'moment';
import { PrefsService } from 'src/app/services/prefs.service';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {TagerService} from '../../services/tager.service';
import {DatePipe} from '@angular/common';


export interface Operator {
  value: string;
  viewValue: string;
  type: string;
}

export interface Searchop {
  value: string;
  viewValue: string;
  icon: string;
}

@Component({
  selector: 'app-searchcriteria',
  templateUrl: './searchcriteria.component.html',
  styleUrls: ['./searchcriteria.component.css']
})
export class SearchcriteriaComponent implements OnInit {

 public searchvalue = '';
  public activeindexes = [];
  public propertyselected = false;
  public indexselected = false;
  operatorvalues = '$eq';
  searchvalues = '';
datecriteria: string;
datecriteria2: string;
choices = false;
public numcriteria;
public searchquery: any = {};
public searchitems: Array<any> = [];
public queuesearchitems: Array<any> = [];
// public chosentype: String;
public chosenchoicesetid= ''
public hlsearch = [];
public activesearchrow: Number;
public searchrowhled = false;
// public hc = '';
  
public hlqueuesearch = [];
public activequeuerow: Number;
public queuerowhled = false;

  public selectedProperty : Tagmodel;
  public selectedChoice : Tagmodel;
  selectedOperator: string;
  selectable = true;
  removable = true;
  slider = false;
  public newcmtfc = new FormControl();

  brunsearch = false;
  public showspinner: Boolean;

  public queuestatus =0;

  hltreepath =[];
  sorted=[];
  clickchoiceset = false;
  public itemindexes = [];
  public toolbaritemselected = false;
  public reset: any[] = [{}]
  public indexready = false;
  // public customindexes = [];

  sysProperties: Operator[] = [
 
    {value: 'ab', viewValue: 'Added By', type: 'string'},
    {value: 'ao', viewValue: 'Added On', type: 'date'},
    {value: 'team', viewValue: 'Team Name', type: 'string'},
    {value: 'ti', viewValue: 'Document Title', type: 'string'},
    {value: 'name', viewValue: ' Name', type: 'string'},
    {value: 'mt', viewValue: 'Mime Type', type: 'string'},
    ];

    queueProperties: Operator[] = [
 
      {value: 'ab', viewValue: 'Added By', type: 'string'},
      {value: 'status', viewValue: 'Status', type: 'string'},
      {value: 'ao', viewValue: 'Added On', type: 'date'},
      {value: 'assignedname', viewValue: 'Assigned To', type: 'string'},
      {value: 'name', viewValue: ' Name', type: 'string'},
    
      ];

  operators: Operator[] = [
    {value: 'equals', viewValue: '=', type: 'op'},
  //  {value: '$gte', viewValue: '>=', type: 'op'},
    {value: 'contains', viewValue: 'Contains', type: 'op'}
  ];

  status: Operator[] = [
    {value: '0', viewValue: 'NOT STARTED', type: 'number'},
    {value: '1', viewValue: 'IN PROGRESS', type: 'number'},
    {value: '2', viewValue: 'COMPLETED', type: 'number'},
    {value: '3', viewValue: 'ARCHIVED', type: 'number'},
  ];

  numoperators: Operator[] = [
    {value: 'numeqls', viewValue: '=', type: 'op'},
    {value: 'numgte', viewValue: '>=', type: 'op'},
    {value: 'numlte', viewValue: '<=', type: 'op'},
    
  ];

  booloperators: Operator[] = [ {value: 'booleqls', viewValue: '=', type: 'op'}];

  boolvalues: Operator[] = [ 
    {value: 'true', viewValue: 'TRUE', type: 'op'},
    {value: 'false', viewValue: 'FALSE', type: 'op'},
];

  eqloperators: Operator[] = [ {value: 'eqls', viewValue: '=', type: 'op'}];

  stroperators: Operator[] = [
    {value: 'equals', viewValue: '=', type: 'op'},
   
    {value: 'contains', viewValue: 'Contains', type: 'op'}
  ];



  dateoperators: Operator[] = [
   
    {value: 'dategte', viewValue: '>=', type: 'op'},
    {value: 'datelte', viewValue: '<=', type: 'op'},
    {value: 'dateday', viewValue: 'Day Added', type: 'op'},
    {value: 'dateweek', viewValue: 'Week Begnning On', type: 'op'},
    {value: 'between', viewValue: 'Between Dates', type: 'op'},
    
  ];

  ratingoperators: Operator[] = [
   
    {value: 'ratinggte', viewValue: '>=', type: 'op'},
    {value: 'ratinglte', viewValue: '<=', type: 'op'},
    {value: 'ratingeqls', viewValue: '=', type: 'op'},
    
    
  ];

  ratings: Operator[] = [
    {value: '1', viewValue: '1', type: 'number'},
    {value: '2', viewValue: '2', type: 'number'},
    {value: '3', viewValue: '3', type: 'number'},
    {value: '4', viewValue: '4', type: 'number'},
    {value: '5', viewValue: '5', type: 'number'},
  ];

  boolop: Operator[] = [
    {value: '$eq', viewValue: '=', type: 'op'}
   
  ];


  properties = new FormControl();
  highlightedRows = [];
  checked = false;


 selectedColumns = ['FileCount', 'Mt', 'Status', 'CommentCnt', 'Addedby', 'BatchNumber', 'Filename',
 'Title', 'Path', 'Filesize',
'From', 'To', 'CC', 'BCC',
 'Clientid', 'ClientName', 'Coverno', 'DocType' ];

 searchtypes = ['system', 'custom' ]

 public  activeColumns : string[] = ['property', 'index', 'operator', 'criteria', 'type'];
 public  queueColumns : string[] = ['property', 'operator', 'criteria', 'type'];

 // customtypes = []
/*
 searchtypes : Searchop[] = [
  {value: 'all', viewValue: 'All Types', icon: ''},
  {value: 'folder', viewValue: 'Folder', icon: 'folder'},
  {value: 'file', viewValue: 'File', icon: 'cloud_download'},
  {value: 'tagerseq', viewValue: 'TagerStep',icon: 'double_arrow'},
  {value: 'tagerpar', viewValue: 'TagerBurst', icon: 'share'},
  {value: 'tageritem', viewValue: 'TagerTag', icon: 'assignment'},
 
 ]*/





iconDisplay(e: any): string {
  return e._name + ' <i class="fa fa-' + e._icon + '"></i>';
}

currentURL: string;
searchFG : FormGroup;
showfoldertags = false;
public existingsearch: Boolean; 
public existingqueue: Boolean;
public todaysdate;
// tagindexes = [];
  constructor(public searchDocsvc: DocSearchService, private authService: AuthenticationService,  
    private router: Router, private activatedRoute: ActivatedRoute,private dateAdapter: DateAdapter<Date>,
    public configSvc: ConfigService, public tagSvc: TagService, private _formBuilder: FormBuilder,public prefSvc: PrefsService,
    public tagerSvc: TagerService, private authSvc: AuthenticationService,private datePipe: DatePipe)
     { this.dateAdapter.setLocale('en-AU')
  
    }


  ngOnInit() {
    this.configSvc.mode = 'search'
   this.searchDocsvc.searchstatus = 0;
   this.configSvc.activetype = 'search'
   console.log('getting saved searches')
   this.configSvc.getsavedSearch();
  this.todaysdate = new Date();

    this.activatedRoute.queryParams.subscribe(params => {
      /*
      this.configSvc.searchtype = params['type'];
      this.configSvc.searchproperty = params['property'];
      this.configSvc.searchoperator = params['operator'];
      this.configSvc.searchcriteria = params['criteria'];
      
      
     
      console.log('activated  searchtype'+this.configSvc.searchtype);
      console.log('activated searchproperty'+this.configSvc.searchproperty);
      console.log('activated searchoperator'+this.configSvc.searchoperator);
      console.log('activated searchcriteria'+this.configSvc.searchcriteria);
      */
  
     
      console.log('sending this url to the auth service'+this.router.url);
      console.log('current params is set to '+JSON.stringify(params))
     if(Object.keys(params).length>0){
     

       
        this.authService.searchurl(this.router.url).subscribe(searchresults => {
          this.searchDocsvc.searchstatus = 1
          this.configSvc.teamds = searchresults;
          if(this.configSvc.teamds.length<1){
            console.log('search status is now 3');
            this.searchDocsvc.searchstatus = 3
          }
  
          if(this.configSvc.teamds.length>0){
            console.log('search status is now 2');
            console.log('actibve type status is now '+this.configSvc.activetype);
            this.searchDocsvc.searchstatus = 2
          }

        
          if (this.activatedRoute.snapshot.queryParamMap.has('ab')){
            console.log('this search includes an addedby search')
            console.log( 'here is the addedby params '+ params['ab'])
           
            this.searchFG.patchValue({addedby: true})
           if( params['ab'] == 'self'){
            console.log('this is a search  for myaself')
            this.searchFG.patchValue({abtype: 'ME'})}
            if(params['ab'] != 'self'){
             console.log('this is a search not for myaself')
              this.searchFG.patchValue({abtype: 'ELSE'})
              this.searchFG.patchValue({email:  params['ab'] })
            
            
           }

          }

          if (this.activatedRoute.snapshot.queryParamMap.has('name')){
            console.log('this search includes a name search')
            console.log( 'here is the name params '+ params['name'])
            this.searchFG.patchValue({name: true})
            if( params['nameop'] == 'contains'){this.searchFG.patchValue({optype: 'contains'})}
            if( params['nameop'] == 'equals'){ this.searchFG.patchValue({optype :'equals'})}
            // if( params['nameop'] == ' $gte'){ this.searchFG.patchValue({optype :' $gte'})}
           
            this.searchFG.patchValue({searchBox: params['name']})
          }

          if (this.activatedRoute.snapshot.queryParamMap.has('lineitem')){
            console.log('this search includes a lineitem search')
            console.log( 'here is the lineitem params '+params['lineitem'])
            this.searchFG.patchValue({lineitem: true})
            this.searchFG.patchValue({optype: 'contains'})
            
            // if( params['nameop'] == ' $gte'){ this.searchFG.patchValue({optype :' $gte'})}
           
            this.searchFG.patchValue({lineitemBox: params['lineitem']})
          }

          if (this.activatedRoute.snapshot.queryParamMap.has('prompt')){
            console.log('this search includes a prompt search')
            console.log( 'here is the prompt params '+params['prompt'])
            this.searchFG.patchValue({prompt: true})

            if (this.activatedRoute.snapshot.queryParamMap.has('rate')){
              this.searchFG.patchValue({rate: true})
            }
          }

          if (this.activatedRoute.snapshot.queryParamMap.has('tool')){
            console.log('this search is on a template')
            console.log( 'here is the tool params '+ params['tool'])
            this.searchFG.patchValue({searchtemplates: true})
            this.searchFG.patchValue({templateselected: true})
     
           this.searchFG.patchValue({chosenproperty: params['tool']})
      
           
            this.searchFG.patchValue({searchBox: params['name']})
          }

          if (this.activatedRoute.snapshot.queryParamMap.has('toolindex')){
            console.log('this search is on a template index')
            console.log( 'here is the tool params '+ params['toolindex'])
            this.searchFG.patchValue({indexselected: true})
           
     
          // this.searchFG.patchValue({customindex: params['toolindex']})
      
        //   this.searchFG.patchValue({toolindop: params['toolindop']})
         //  this.searchFG.patchValue({customindex: params['toolindex']})
       //    console.log('the default custom index is '+this.searchFG.value.customindex)
       //    this.activeindexes.push({formitem: this.searchFG.value.customindex})
       
            this.searchFG.patchValue({searchBox: params['name']})
          }

          if (this.activatedRoute.snapshot.queryParamMap.has('ao')){
            console.log('this search includes a name search')
            console.log( 'here is the name params '+ params['ao'])
            this.searchFG.patchValue({addedon: true})}

            if (this.activatedRoute.snapshot.queryParamMap.has('mo')){
              console.log('this search includes a name search')
              console.log( 'here is the name params '+ params['mo'])
              this.searchFG.patchValue({modifiedon: true})}

            this.searchFG.patchValue({dateop: params['dateop']})

            
            var newdate =  this.datePipe.transform( params['ao'], 'yyyy-MM-dd');
            this.searchFG.patchValue({datebox: newdate})

            if (this.activatedRoute.snapshot.queryParamMap.has('ao2')){
              var newdate2 =  this.datePipe.transform( params['ao2'], 'yyyy-MM-dd');
              this.searchFG.patchValue({datebox2: newdate2})

            }

            if (this.activatedRoute.snapshot.queryParamMap.has('hc')){
              if (params['hc'] == 'true'){
              //  this.hc = 'hidden';
                this.searchFG.patchValue({hc: true})
              }
           
            }

            if (this.activatedRoute.snapshot.queryParamMap.has('teamid')){
              
              this.searchFG.patchValue({team: true})
              this.searchFG.patchValue({teamid: params['teamid']})
              
           
            }


        }, (err) => {
          console.error(err);
         });
        } 
      

      
/*
      if (this.activatedRoute.snapshot.queryParamMap.get('srchtype') == 'sys'){
      //  this.showspinner = true;
      this.existingsearch = true;
      this.searchDocsvc.searchstatus = 1;     console.log('search status is now 1');
      this.authService.searchurl(this.router.url).subscribe(searchresults => {
        this.configSvc.teamds = searchresults;
        console.log('this is the search results: '+searchresults)
        if(this.configSvc.teamds.length<1){
          console.log('search status is now 3');
          this.searchDocsvc.searchstatus = 3
        }

        if(this.configSvc.teamds.length>0){
          console.log('search status is now 2');
          this.searchDocsvc.searchstatus = 2
        }
     //   this.showspinner = false;
      //  this.brunsearch = true;
    // this.searchDocsvc.searchstatus = 1
        
       }, (err) => {
        console.error(err);
       }); }
       */

       if (this.activatedRoute.snapshot.queryParamMap.get('srchtype') == 'queue'){
        console.log('queuestatus is now 1');
        this.existingqueue = true;
        this.queuestatus = 1;     
        this.authService.searchurl(this.router.url).subscribe(searchresults => {
          this.configSvc.queueresults = searchresults;
          console.log('this is the search results: '+searchresults)
          if(this.configSvc.queueresults.length<1){
            console.log('search status is now 3');
            this.queuestatus = 3
          }
  
          if(this.configSvc.queueresults.length>0){
            console.log('search status is now 2');
            this.queuestatus = 2
          }
  
          
         }, (err) => {
          console.error(err);
         }); }

         /*
       if (!this.activatedRoute.snapshot.queryParamMap.has('type')){
        this.existingsearch = false;
        this.configSvc.searchtype = 'system'
        this.configSvc.searchproperty = 'name'
        this.configSvc.searchoperator = 'equals'
        this.configSvc.searchcriteria = ''
       }
       */
     
    });

    
    this.searchFG = this._formBuilder.group({
      property: ['', Validators.required],
      chosenproperty: ['', Validators.required],
      chosentype: ['', Validators.required],
      toolbaritem: ['', Validators.required],
      choiceproperty: ['', Validators.required],
      checked: [false, Validators.required],
      findtemplate: ['search', Validators.required],
      indexname: ['', Validators.required],
      searchBox: [this.configSvc.searchcriteria, Validators.required],
      lineitemBox: [this.configSvc.lineitemcriteria, Validators.required],
      prompt: [false, Validators.required],
      rate: [false, Validators.required],
      rating: ['', Validators.required],
      promptBox: ['', Validators.required],
      promptstartswith: [false, Validators.required],
      searchtemplates:  [false, Validators.required],
      searchfoldertags:  [false, Validators.required],
      datebox: [this.datecriteria, Validators.required],
      datebox2: [this.datecriteria2, Validators.required],
      dateop: ['', Validators.required],
      rateop: ['', Validators.required],
      optype: [this.configSvc.searchoperator, Validators.required],
      searchtype: [this.configSvc.searchtype, Validators.required],
      customtype: ['', Validators.required],
      choices: [this.choices, Validators.required],
      radios: ['', Validators.required],
      choicesetid: ['', Validators.required],
      jointype: ['AND', Validators.required],
      username: ['', Validators.required],
      filtereduser: ['', Validators.required],
      statustype: ['1', Validators.required],
     
    
      // customindexes:  ['', Validators.required],
      customindex:  ['', Validators.required],
      indexproperty:  ['', Validators.required],
      indexselected:  [false, Validators.required],
      templateselected:  [false, Validators.required],
      indexval:  ['', Validators.required],
      addedby:  [false, Validators.required],
      assignedto:  [false, Validators.required],
      savedsearch:  [false, Validators.required],
      hideafter:  [false, Validators.required],
      hc:  [this.configSvc.hc, Validators.required],
      ssname:  ['', Validators.required],
      toolbar:  [false, Validators.required],
      name:  [false, Validators.required],
      lineitem:  [false, Validators.required],
      namevalue:  ['', Validators.required],
      team:  [false, Validators.required],
      teamid:  ['', Validators.required],
      teamname:  ['', Validators.required],
      addedon:  [false, Validators.required],
      mo:  [false, Validators.required],
      abtype: ['ME', Validators.required], //addedby type
      attype: ['ME', Validators.required], //assigned to type
      searchcriteria:  [this.configSvc.searchcriteria, Validators.required],
      lineitemcriteria:  [this.configSvc.lineitemcriteria, Validators.required],
      state: [false, Validators.required],
      stateval: [false, Validators.required],
      template: ['', Validators.required],
    });



  
  

}

   onChanges(): void {
    this.searchFG.get('datebox').valueChanges.subscribe(val => {
      console.log('this is the current date '+val)
    console.log('this is the unix date'+moment.unix(val))
    console.log('this is the dmy date'+moment(val).format("DD-MM-YYYY"));
    console.log('this is the iso date'+moment(val).format());
   // this.numcriteria =moment(val).format("DD-MM-YYYY");
    this.numcriteria =moment(val).format();
  
    });
  }

  setdatetoday(){
   // this.searchFG.patchValue({dateop: true})
    this.searchFG.patchValue({datebox: this.todaysdate})
    this.searchFG.patchValue({dateop:'dateday'})
  }

  templateFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering index on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.searchallTemplates(filterValue)
  console.log('templateFilter value is currently set to '+this.configSvc.finditemds)

  }
}



public onRecreate(){
  this.reset[0] = {}
}

selectTemplate(template){
console.log('this is the selected index '+JSON.stringify(template))
this.configSvc.nodeds[0] = template

this.onRecreate()
// this.searchFG.value.indexselected
this.searchFG.patchValue({templateselected: true})
this.searchFG.patchValue({chosenproperty: template.name})
console.log('property now changed to '+JSON.stringify(this.searchFG.value.chosenproperty));
console.log('toolbaritem type is '+template.type);
if(template.type == 'editabbled') {template.type = 'tabbed'}
this.searchFG.patchValue({chosentype: template.type})
if (template.type =='tabbed'  ){

  console.log('current stepperstuff is '+JSON.stringify(template.stepper))
  for(const tagitems of template.stepper){
    console.log('current tagitems '+JSON.stringify(tagitems))
    const newtagitems = tagitems.tags
    console.log('new tagitems '+JSON.stringify(newtagitems))
    for(const tags of newtagitems){
      console.log('current tags '+JSON.stringify(tags))
      console.log('current tags property '+JSON.stringify(tags))
      this.activeindexes.push(tags)
    }
    
  }}
if (template.type =='node' || template.type =='nodeimage'){
console.log('current nodestuff is '+JSON.stringify(template.stepitems))
for(const nodeitems of template.stepitems){
this.activeindexes.push(nodeitems)}}

if (template.type == 'foldertags'){
  console.log('current foldertags is '+JSON.stringify(template.foldertags))
  for(const nodeitems of template.foldertags){
  this.activeindexes.push(nodeitems)}}

}






  teamFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering index on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.findTeam(filterValue, 'team' )
  console.log('teamFilter value is currently set to '+this.configSvc.finditemds)

  }
}

teamSelected(index){
console.log('this is the selected index '+JSON.stringify(index))
this.searchFG.patchValue({teamid: index._id})
// this.configSvc.nodeds[0] = index
//this.onRecreate()

////this.teamready =true

}


     

  


  onOperator1(ops: string) {this.operatorvalues = ops;
    console.log('this is the operator value you chose' + ops);
  }

  public searchdocs(type) {
  //  this.configSvc.getCustomColumns(this.selectedProject);
 // this.configSvc.getSystemColumns(this.selectedProject);
 console.log('selected property is: '+JSON.stringify(this.searchFG.value.property));
 console.log('selected type is: '+JSON.stringify(type));
 console.log('selected operator is: '+this.searchFG.value.optype)
 console.log('seearch criteria is: '+this.searchFG.value.searchBox)
   
    this.searchDocsvc.searchdocs(this.selectedProperty.tagtype, this.selectedProperty.tagitem, this.selectedOperator, this.searchvalues);
                      }



public remove(chosencols): void {
            const index = this.selectedColumns.indexOf(chosencols);
                  if (index >= 0) {
              this.selectedColumns.splice(index, 1); }
                      }
 public docsearchcurow() { return this.searchDocsvc.searchresults; }


public getFoldertags(){
  console.log('getting foldertags now')
  this.searchDocsvc.getFoldertags()
 
  console.log('this is the filtered choicelist: '+JSON.stringify(this.searchDocsvc.foldertags));
  this.searchFG.value.foldertag = this.configSvc.foldertag
 // return this.searchDocsvc.foldertags
}

public changeSearchtype(searchtype){
  console.log('changed the search type to '+searchtype)
  this.searchDocsvc.searchstatus=0
  if(searchtype == 'custom'){
    console.log('off to get the root tollbar items')
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
    
   
  }
  
}

public getsearchToolbars(){

  this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')

}

public changeFoldertag(chosenfoldertag){
  console.log('changing foldertags now to these indexes: '+JSON.stringify(chosenfoldertag.stepitems))
  console.log('setting tagindexes to true')
  
  this.searchDocsvc.tagindexes = chosenfoldertag.stepitems
  this.showfoldertags = true
  this.existingsearch = false;
 
  /*
  for (var item of this.searchDocsvc.tagindexes) {
    console.log('active item is: '+item.formitem);
  this.searchDocsvc.activeColumns.push(item.formitem)}

  console.log('active columns are now set to:  '+JSON.stringify(this.searchDocsvc.activeColumns))
*/
}



public runSearch(){
  console.log('ive just hit a search')
}


 public getChoices(choicesetid){
 
 var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)

  return filtered;
  

   }

   public changeRadio(selectedrow){
    console.log('changing radio option')
   this.searchFG.value.radios = selectedrow.name
   
  }


  public sortPath(){
    this.sorted = [];this.hltreepath = [];
    
  //   console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
   this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
   //console.log('this is the sorted result: '+JSON.stringify(this.sorted))
     return this.sorted
   
   }

   public getChip(choicesetid){
   
    
    this.sorted = [];
    console.log('getting choicesetbyid with getChip type is path')
    this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
  }

   public getChoicetree(choicesetid){
   this.hltreepath = [];
    this.getChip(choicesetid)
     this.sorted = [];
  
  
   
    this.clickchoiceset = true; 
     console.log('getting the choice tree for '+choicesetid)
     console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
   
     this.sortPath()
    if(this.authSvc.choicesetids.includes(choicesetid)){
      console.log('this choicetree has a list associated with it and its in cache')
  
    }
  
  
   if(!this.authSvc.choicesetids.includes(choicesetid)){
     console.log('ive never got this list before lets get it')
    this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
  
  }
  
  
  }

  public getToolbars(toolbar){
if(toolbar.type == 'nodeteam'){
  this.configSvc.getToolbars(toolbar.type, toolbar._id, 'nodeteam')
}

if(toolbar.type == 'category'){
  this.hltreepath = [];
  this.configSvc.getToolbars(toolbar.type, toolbar._id, toolbar.teamid)
  this.hltreepath = this.configSvc.toolbards[0].path
}

  }

  public changeProperty(toolbaritem){
    this.activeindexes = [];
console.log('active toolbaritem  '+JSON.stringify(toolbaritem))
// this.searchFG.value.property = toolbaritem.formitem
this.propertyselected = true

// this.searchFG.value.chosenproperty = toolbaritem.name;
this.searchFG.patchValue({chosenproperty: toolbaritem.name})
console.log('property now changed to '+JSON.stringify(this.searchFG.value.chosenproperty));
console.log('toolbaritem type is '+toolbaritem.type);
this.searchFG.patchValue({chosentype: toolbaritem.type})
// this.chosentype = toolbaritem.type;
console.log('chosen property now changed to '+this.searchFG.value.chosenproperty );



for(const index of [toolbaritem]){
  if (index.type =='wizard'){

    console.log('current stepperstuff is '+JSON.stringify(index.stepper))
    for(const tagitems of index.stepper){
      console.log('current tagitems '+JSON.stringify(tagitems))
      const newtagitems = tagitems.tags
      console.log('new tagitems '+JSON.stringify(newtagitems))
      for(const tags of newtagitems){
        console.log('current tags '+JSON.stringify(tags))
        console.log('current tags property '+JSON.stringify(tags))
        this.activeindexes.push(tags)
      }
      
    }}
if (index.type =='node' || index.type == 'foldertags' || index.type =='nodeimage'){
console.log('current nodestuff is '+JSON.stringify(index.stepitems))
for(const nodeitems of index.stepitems){
this.activeindexes.push(nodeitems)}}
}

console.log('this is the activeindexes'+JSON.stringify(this.activeindexes))



  }

 

  public toggleIndexes(){
  //  this.toolbaritemselected = !this.toolbaritemselected
  }

  public changeIndex(chosenindex){
  
    this.searchFG.patchValue({customindex: chosenindex})
    // value.customindex= chosenindex
   // this.searchFG.value.chosenproperty =this.searchFG.value.property.name
    console.log('cusrrent chosenindex is'+JSON.stringify(chosenindex));
   console.log('cusrrent custom index  is'+JSON.stringify(this.searchFG.value.customindex));
  //  this.chosenchoicesetid = chosenindex.choicesetid;
 //  console.log('cusrrent choicesetid is'+this.chosenchoicesetid);
   this.searchFG.patchValue({choicesetid: chosenindex.choicesetid})
   console.log('current patchvalue choicesetid: '+this.searchFG.value.choicesetid);
   this.searchFG.patchValue({indexproperty: chosenindex.formitem})
   console.log('current indexproperty'+this.searchFG.value.indexproperty);
   console.log('current patchvalue chosenproperty after changing index'+this.searchFG.value.chosenproperty);
   this.searchFG.patchValue({indexselected: true})
   console.log('current patchvalue indexselected'+this.searchFG.value.indexselected);
  // this.indexselected = true;
   var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === this.searchFG.value.choicesetid)
  console.log('this is the filtered choicelist: '+filtered)

  if(localStorage.getItem(this.searchFG.value.choicesetid)===null && !this.authSvc.choicesetids.includes(this.searchFG.value.choicesetid)){
    console.log('there is no '+this.searchFG.value.choicesetid+' in cache need to get an get it')
    this.configSvc.nodedetailsready = false;
    this.tagerSvc.getChoicesetbyid(this.searchFG.value.choicesetid, 'types')

  }
  if(localStorage.getItem(this.searchFG.value.choicesetid)!==null){
    console.log('there is  a '+this.searchFG.value.choicesetid+' in cache lets use its values')
    var localdata = JSON.parse(localStorage.getItem(this.searchFG.value.choicesetid))
               console.log('this is the localdata im pushing'+localdata);
               if(!this.authSvc.choicesetids.includes(this.searchFG.value.choicesetid)){
                this.authSvc.choicesetids.push(this.searchFG.value.choicesetid)
                console.log('we havent added this to the auth localcache yet: '+this.searchFG.value.choicesetid);
                console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)}
              
              }

              console.log('this is the a+value of the active index property'+this.searchFG.value.indexproperty);
              console.log('this is the a+value of the custom index formtype'+this.searchFG.value.customindex.formtype);
   
  }

  

 public removeRow(row){
  
   console.log('removing row '+row)
 this.searchitems.splice(row, 1)
 console.log('searchitems now set to '+JSON.stringify(this.searchitems));
 this.searchitems = [...this.searchitems];  //refresh the dataSource
 this.searchrowhled = false;
}






  public addtoSearch(searchtype){

    var propertyvalue
  var indexvalue
  var criteriavalue
  var lineitemvalue
  var operatorvalue
  var typevalue

    if(searchtype == 'system' ){
      console.log('this is a system search')
      propertyvalue = searchtype
      indexvalue = this.searchFG.value.property
      criteriavalue = this.searchFG.value.searchBox
      console.log('criteria value is '+criteriavalue)
      lineitemvalue = this.searchFG.value.lineitemBox
      console.log('lineitem value is '+lineitemvalue)
      operatorvalue = this.searchFG.value.optype;
      console.log('operator value is '+operatorvalue)
      typevalue = 'system';
      console.log('typevalue value is '+typevalue)

    }
   


 if(searchtype == 'custom' ){
  console.log('this is a custom search')
  propertyvalue = this.searchFG.value.chosenproperty
  indexvalue = this.searchFG.value.customindex.formitem
  criteriavalue = this.searchFG.value.searchBox
  console.log('criteria value is '+criteriavalue)
  typevalue = this.searchFG.value.chosentype;
      console.log('typevalue value is '+typevalue)
      operatorvalue = this.searchFG.value.optype;
      console.log('operator value is '+operatorvalue)

  if(!this.searchFG.value.indexselected){
    propertyvalue = 'customonly'
  indexvalue = this.searchFG.value.chosenproperty
criteriavalue = 'ANY'
operatorvalue = 'equals';
console.log('operator value is '+criteriavalue)
}
}


  this.searchitems.push({property: propertyvalue,index:indexvalue,
     operator: operatorvalue, criteria: criteriavalue, 'type':typevalue, 
    })
    



    

              

      this.searchitems = [...this.searchitems];  //refresh the dataSource
  
    console.log('current search template'+JSON.stringify(this.searchitems))

  }

  public search(searchtype)
  {

  if(this.searchitems.length == 1){
    console.log('this is a single query search')
  this.router.navigate(['/toolbard/search/'], { queryParams: {srchtype: 'sys',  sysprop1 : this.searchitems[0].property, index1:this.searchitems[0].index,
    operator1: this.searchitems[0].operator, criteria1: this.searchitems[0].criteria, type1: this.searchitems[0].type,join1:'nojoin' }});
   }

   if(this.searchitems.length == 2){
    this.router.navigate(['/toolbard/search/'], { queryParams: { srchtype: 'sys', sysprop1 : this.searchitems[0].property, index1:this.searchitems[0].index,
      operator1: this.searchitems[0].operator, criteria1: this.searchitems[0].criteria, type1: this.searchitems[0].type, join1: this.searchFG.value.jointype, property2 : this.searchitems[1].property,
      index2:this.searchitems[1].index, operator2: this.searchitems[0].operator, criteria2: this.searchitems[1].criteria, type2: this.searchitems[1].type }});
     }



  


  }

  public newSearch(){
    console.log('this is a new search')


    const dynamicquery = {};
   // dynamicquery['hc'] =   this.searchFG.value.hc;
    if(this.searchFG.value.addedby && this.searchFG.value.abtype == 'ME'){ dynamicquery['ab'] = 'self'}
    if(this.searchFG.value.addedby && this.searchFG.value.abtype == 'ELSE'){ dynamicquery['ab'] = this.searchFG.value.username}
    if(this.searchFG.value.assignedto && this.searchFG.value.attype == 'ME'){ dynamicquery['at'] = 'self'}
    if(this.searchFG.value.assignedto && this.searchFG.value.attype == 'ELSE'){ dynamicquery['at'] = this.searchFG.value.username}
    if(this.searchFG.value.state){ dynamicquery['state'] = this.searchFG.value.stateval}
    if(this.searchFG.value.name){ 
      dynamicquery['name'] = this.searchFG.value.searchBox
      dynamicquery['nameop'] = this.searchFG.value.optype
    }
    if(this.searchFG.value.lineitem){ 
      dynamicquery['lineitem'] = this.searchFG.value.lineitemBox
    //  dynamicquery['nameop'] = this.searchFG.value.optype
    }
    if(this.searchFG.value.prompt){ 
      dynamicquery['prompt'] = 'true'

      if(this.searchFG.value.rate){ 
        dynamicquery['rate'] = 'true'
        dynamicquery['rateop'] = this.searchFG.value.rateop
        dynamicquery['rating'] = this.searchFG.value.rating
      }
      if(this.searchFG.value.promptstartswith){ 
        dynamicquery['promptstartswith'] = 'true'
        dynamicquery['rateop'] = this.searchFG.value.rateop
        dynamicquery['promptBox'] = this.searchFG.value.promptBox
      }
    //  dynamicquery['nameop'] = this.searchFG.value.optype
    }
    if(this.searchFG.value.addedon){ 
      dynamicquery['ao'] = this.searchFG.value.datebox
      dynamicquery['dateop'] = this.searchFG.value.dateop

      if(this.searchFG.value.dateop =='between'){
        dynamicquery['ao2'] = this.searchFG.value.datebox2
      }
    }

    if(this.searchFG.value.mo){ 
      dynamicquery['mo'] = this.searchFG.value.datebox
      dynamicquery['dateop'] = this.searchFG.value.dateop

      if(this.searchFG.value.dateop =='between'){
        dynamicquery['mo2'] = this.searchFG.value.datebox2
      }
    }

    if(this.searchFG.value.team){ 
      dynamicquery['teamid'] = this.searchFG.value.teamid
     
    }

    if(this.searchFG.value.templateselected){ 
      dynamicquery['tool'] = this.searchFG.value.chosenproperty
      dynamicquery['type'] = this.searchFG.value.chosentype
    //  dynamicquery['dateop'] = this.searchFG.value.dateop
     if(this.searchFG.value.indexselected){
      dynamicquery['toolindex'] = this.searchFG.value.indexproperty
      dynamicquery['toolindop'] = this.searchFG.value.optype
      dynamicquery['toolinval'] = this.searchFG.value.indexval
     }
    }

    

   // { dynamicquery.push({ab: this.searchFG.value.email})}
    console.log('here is the dynamic query '+JSON.stringify(dynamicquery))
    // var queryString = Object.keys(dynamicquery).map(key => key + '=' + dynamicquery[key]).join('&');
    //var queryParams = Object.keys(dynamicquery).map(function(key) {return key + '=' + dynamicquery[key]}).join('&');
   //  var queryString = Object.keys(dynamicquery).map(key => key + '=' + dynamicquery[key]).join('&');

   let navigationExtras: NavigationExtras = {queryParams: dynamicquery};
  //  console.log('here is the  query string'+JSON.stringify(queryString))
    this.router.navigate(['/toolbard/search/'], navigationExtras);
    this.searchDocsvc.searchstatus = 1
  }

  public saveSearch(searchname){
    console.log('saving search ')
    console.log(' search name '+searchname)
    console.log('this is the url '+this.router.url)
    this.prefSvc.saveSearch(searchname, this.router.url
      )
  }
  

  public clickSearchrow(selectedrow, index){
    this.hlsearch = [];
    console.log('clicked search')
    this.hlsearch.push(selectedrow, index)

    console.log('hlighlishted search row is '+JSON.stringify(this.hlsearch))
    this.activesearchrow = index
    this.searchrowhled = true;
  }

  public clickQueuerow(selectedqueue, index){
    this.hlqueuesearch = [];
    console.log('clicked queue search')
    this.hlsearch.push(selectedqueue, index)

    console.log('hlighlishted queuesearch row is '+JSON.stringify(this.hlqueuesearch))
    this.activequeuerow = index
    this.queuerowhled = true;
  }

  public addtoqueueSearch(){

    var propertyvalue

  var criteriavalue
  var operatorvalue
  var typevalue


      console.log('adding to queue search template')
   
  propertyvalue = this.searchFG.value.property
  console.log('property value is '+propertyvalue);

  if(propertyvalue == 'ab' || propertyvalue == 'assignedname'){
    criteriavalue = this.searchFG.value.email
    console.log('email or assignedname criteria value is '+criteriavalue)
  }

  if(propertyvalue == 'status'){
    criteriavalue = this.searchFG.value.statustype
    console.log('status criteria value is '+criteriavalue)
  }



  if(propertyvalue == 'ao' || propertyvalue == 'name'){
    criteriavalue = this.searchFG.value.searchBox
    console.log('ao criteria value is '+criteriavalue)
  }


      
     
      operatorvalue = this.searchFG.value.optype;
      console.log('operator value is '+operatorvalue)
      typevalue = 'queue';
      console.log('typevalue value is '+typevalue)

    

  this.queuesearchitems.push({property: propertyvalue,
     operator: operatorvalue, criteria: criteriavalue, 'type':typevalue, 
    })
    



    

              

      this.queuesearchitems = [...this.queuesearchitems];  //refresh the dataSource
  
    console.log('current search template'+JSON.stringify(this.queuesearchitems))

  }

  public newqueueSearch(){
    
     
    this.queuesearchitems = [];
    this.configSvc.teamds=null;
    this.router.navigate(['/toolbard/search/']);
    this.queuestatus = 3
    
 }

 public removequeueRow(row){
  
  console.log('removing row '+row)
this.queuesearchitems.splice(row, 1)
console.log('searchitems now set to '+JSON.stringify(this.queuesearchitems));
this.queuesearchitems = [...this.queuesearchitems];  //refresh the dataSource
this.queuerowhled = false;
}

usernameFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  console.log('filtering email on: '+filterValue)
  if (filterValue != '')
  {
    console.log('filter value befoore sending is : '+filterValue)
  this.configSvc.usernameFilter(filterValue, 'user')
console.log('usernameFilter value is currently set to '+this.searchFG.value.usernameFilter)
console.log('email value is currently set to '+this.searchFG.value.email)
}

}

changeEmail(email){
  console.log('changing emails')
  console.log('changing emails for '+email)
}

public queueSearch()
{


if(this.queuesearchitems.length == 1){
  console.log('this is a single query search')
this.router.navigate(['/toolbard/search/'], { queryParams: { srchtype: 'queue', sysprop1 : this.queuesearchitems[0].property, index1:this.queuesearchitems[0].index,
  operator1: this.queuesearchitems[0].operator, criteria1: this.queuesearchitems[0].criteria, type1: this.queuesearchitems[0].type,join1:'nojoin' }});
 }

 if(this.queuesearchitems.length == 2){
  this.router.navigate(['/toolbard/search/'], { queryParams: { srchtype: 'queue', sysprop1 : this.queuesearchitems[0].property, index1:this.queuesearchitems[0].index,
    operator1: this.queuesearchitems[0].operator, criteria1: this.queuesearchitems[0].criteria, type1: this.queuesearchitems[0].type, join1: this.searchFG.value.jointype, property2 : this.queuesearchitems[1].property,
    index2:this.queuesearchitems[1].index, operator2: this.queuesearchitems[0].operator, criteria2: this.queuesearchitems[1].criteria, type2: this.queuesearchitems[1].type }});
   }




}

public searchAI(searchterm: string){
  console.log('searching AI with '+searchterm)
  this.searchDocsvc.searchAI(this.searchvalue);
                    
}





 
  

}


