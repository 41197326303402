import { Component, OnInit } from '@angular/core';

import {TagerService} from '../../services/tager.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { TagService } from '.././../services/tag.service';
import { ConfigService } from '.././../services/config.service';


@Component({
  selector: 'app-editnode',
  templateUrl: './editnode.component.html',
  styleUrls: ['./editnode.component.css']
})
export class EditnodeComponent implements OnInit {

  editnodeFG: FormGroup;
  foldertagtypes = ['none', 'colorfolder', 'bookmark']

  constructor(private _formBuilder: FormBuilder, public tagerSvc: TagerService, public configSvc: ConfigService,
    public tagSvc: TagService) { }

  ngOnInit() {

    this.configSvc.colorinputtype = 'node';
    this.configSvc.editnode = 'editnode';
    this.configSvc.nodepathds = this.configSvc.nodeds[0].path;
    this.configSvc.color = this.configSvc.nodeds[0].iconcolor
   

    this.editnodeFG = this._formBuilder.group({
     name: [this.configSvc.nodeds[0].name, Validators.required],
     desc: [this.configSvc.nodeds[0].desc, Validators.required],
     changecolor: [false, Validators.required],
     icontype: [this.configSvc.nodeds[0].icontype, Validators.required],
     iconcolor: [this.configSvc.nodeds[0].iconcolor, Validators.required],
      type: [this.configSvc.nodeds[0].type, Validators.required],
      textractable: [this.configSvc.nodeds[0].textractable, Validators.required],
      defaultvalue: ['', Validators.required],
      textractype: [this.configSvc.nodeds[0].textractype, Validators.required],
      ischatgpt: [this.configSvc.nodeds[0].ischatgpt, Validators.required],
      defaultprompt: [this.configSvc.nodeds[0].defaultprompt, Validators.required],
      website: [this.configSvc.nodeds[0].website, Validators.required],
      weblink: [this.configSvc.nodeds[0].weblink, Validators.required],

      
    });
  }

  
  updateNode(){

   // this.tagerSvc.editTager(this.tagerSvc.hlKanbanset[0]._id, 
    //  this.foldertagactionFG.value.nodename, this.foldertagactionFG.value.tagerdesc,
    //   this.foldertagactionFG.value.tagertype, this.foldertagactionFG.value.category)
    //   this.foldertagactionFG.markAsPristine();
    console.log('current level '+this.configSvc.activelevel)
    console.log('current nodepathds '+JSON.stringify(this.configSvc.nodepathds));
    console.log('current nodepathds level1 '+JSON.stringify(this.configSvc.nodepathds[1]));
    var pathid = this.configSvc.nodepathds[this.configSvc.activelevel]._id
    console.log('current pathid trying to update '+pathid)

    this.configSvc.updateNode(this.configSvc.nodeds[0]._id, this.editnodeFG.value.name,
      this.editnodeFG.value.desc, this.editnodeFG.value.type, this.editnodeFG.value.icontype, 
      this.editnodeFG.value.iconcolor, pathid, this.editnodeFG.value.textractable, this.editnodeFG.value.textractype, this.editnodeFG.value.ischatgpt, this.editnodeFG.value.defaultprompt)
      this.editnodeFG.markAsPristine(); 
      this.configSvc.colorchanged =false;
  }


}
