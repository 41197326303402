<span *ngIf="this.prefSvc.profileready">
<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="material-icons" [ngStyle]="{'color': 'green'}">settings_accessibility</i>
            Preferences
          </ng-template>


    <mat-card>
     
        <form  [formGroup]="editprefFG" class = "details-container" >
      <span class = "item-width">
            <mat-form-field appearance="fill" >
                <mat-label>Default Location</mat-label>
                <mat-select formControlName="defloc" >
                  <mat-option *ngFor="let location of locations" [value]="location">
                    {{location}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

          
            </span>
            <div >
          MyFeed Settings
                <mat-form-field appearance="fill">
                  <mat-label>Assigned To</mat-label>
                 
                  <input type="text"  
                  formControlName="assignedto"  
                  [matAutocomplete]="auto"
                  
                  matInput (keyup)="usernameFilter($event)" placeholder="AssignedTo" autocomplete="off" >
                  
                  <mat-autocomplete #auto="matAutocomplete">
             <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
              {{option.inboxemail}} 
            
             </mat-option>
                  </mat-autocomplete>
            
                 
                 </mat-form-field>
               
          
                
                <span >
               STATE:
                  <mat-form-field class="ex1">
                    <mat-select placeholder="Choose Operator" formControlName="state">
                      <mat-option *ngFor="let opers of boolvalues" [value]="opers">
                        {{opers}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
   </div>


            <mat-checkbox formControlName="hc">Hide Criteria after searching</mat-checkbox>

            <span class= "pagesize">
              <mat-form-field appearance="outline" floatLabel="always"  style="width:10vw"> 
                <mat-label >PageSize </mat-label>
                    <input formControlName="pagesize" type = "number"  matInput value="pagesize">
                   </mat-form-field>
            </span>
              <div>
                <mat-radio-group formControlName="filter"  >
          
                 <mat-radio-button [value]=false>Show all contents</mat-radio-button>
                 <mat-radio-button [value]=true>Show only content assigned to : 
                  <span *ngIf="this.editprefFG.value.filter">
                  <mat-form-field>
                    <mat-label>User</mat-label>
                   
                    <input type="text"  
                    formControlName="userfilter"  
                    [matAutocomplete]="auto"
                    
                    matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
                    
                    <mat-autocomplete #auto="matAutocomplete">
               <mat-option *ngFor="let option of this.configSvc.usernameFilter" [value]="option.username">
                 {{option.username}}
               </mat-option>
                    </mat-autocomplete>
            
                   
                   </mat-form-field></span>
                   <span *ngIf="!this.editprefFG.value.filter">No filter</span>
                 </mat-radio-button>
               </mat-radio-group></div>
          
            </form>

            <div> <button  [disabled]= "!this.editprefFG.dirty" (click)="this.updatePrefs();"
              mat-raised-button color="primary" [disabled]="!editprefFG.dirty">Apply Changes </button>
           </div>
        
    </mat-card>
</mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <i class="material-icons" [ngStyle]="{'color': 'blue'}">star</i>
           Tool Favourites
          </ng-template>
           
<span class="drag-container">
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let fav of this.prefSvc.toolfavs; let itemindex = index" cdkDrag  
            (click)="this.selectItem(fav, itemindex)"  [style.background]=" this.itemsadded.indexOf(fav) != -1 ? 'lightblue' : ''">
              <div class="example-custom-placeholder" *cdkDragPlaceholder  ></div>
              <span
             >
                <i class="material-icons md-48" [ngStyle]="{'color': fav.iconcolor || 'black'}" >{{fav.icontype}} </i>{{fav.name}}
                <i class="material-icons md-24" [ngStyle]="{'color': fav.iconcolor}"
                matTooltip={{fav.toolid}}  matTooltipPosition= 'after'
                >info</i></span>
              
            </div>
          </div>


  
      <div class = "favbtns">
            <div style="margin-bottom: 10px"> <button [disabled]="!favselected" mat-raised-button color="primary" (click)="removeToolfavs(this.itemsadded[0]?.toolid, this.itemsadded[0]?.name)" mat-button>Delete  {{this.itemsadded[0]?.name}}</button> 
            </div>
          

            <div > <button mat-raised-button color="primary" (click)="updateToolfavs()" mat-button>Save positions</button> 
            </div>
          </div></span>
        

    </mat-tab>

   

  <mat-tab>
    <ng-template mat-tab-label>
        <i class="material-icons" [ngStyle]="{'color': 'blue'}">search</i>
       Search Favourites
      </ng-template>
      <div class="outerbox" >
        <table mat-table [dataSource]="this.prefSvc.search"  >
        
        
      
                   
        <ng-container matColumnDef="name" >
              <th mat-header-cell *matHeaderCellDef>Name </th>
           <td mat-cell *matCellDef="let element">
            
               {{element.name}}
         </td></ng-container>


     
     <ng-container matColumnDef="searchurl" >
      <th mat-header-cell *matHeaderCellDef>Link </th>
   <td mat-cell *matCellDef="let element">
    
       {{element.searchurl}}
 </td></ng-container>





<tr mat-header-row *matHeaderRowDef="searchcolumns;  sticky: true"></tr>
<tr mat-row *matRowDef="let selectedrow; columns: searchcolumns; let i2 = index;"
(click)="this.selectItem(selectedrow)"
[style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"

></tr>

             
        
           </table>
      </div>

</mat-tab>
</mat-tab-group>
</span>