import { Component, OnInit} from '@angular/core';
import { AuthenticationService, TokenPayload } from '../../services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // new
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';



@Component({
        templateUrl: './registercompany.component.html',
        styleUrls: ['./registercompany.component.css'] // new
})
export class RegistercompanyComponent implements OnInit {
    error = ''; // new
    registercompanyFG: FormGroup;  // new
    step1 = "step1";
    step2= "step2";
   

    public selectedIndex: number
    public stepIndex = 0;
    public icontype: string;
    public usersadded = [];
    public mgradded = [];
 
    private formSubmitAttempt: boolean;  // new

  credentials: TokenPayload = {
    username: '',
    password: ''

  };

  constructor(
          private fb: FormBuilder, // new
          private auth: AuthenticationService,
          private router: Router,
          public configSvc: ConfigService
          ) {}

  ngOnInit() {
      this.registercompanyFG = this.fb.group({     // {5}
        email: ['', Validators.required],
        username: ['', Validators.required],
        companyname: ['', Validators.required],
 
      });
      console.log(' STEP1 register component ng on init phase');

    }
  onSubmit({ value }) 
  {
    console.log('value collected from submit' + JSON.stringify(value));
  
    this.auth.registerCompany(value).subscribe(result => {
      console.log('confirm by email '+result);
      console.log(' rego result '+result.registration);
      console.log(' email result '+result.email);
      console.log(' username result '+result.username);
      this.router.navigateByUrl('/emailconfirm');
    }, (err) => {
      console.error(err);
    });
    this.formSubmitAttempt = true; // new
   // console.log('formSubmitAttempt value:' + formSubmitAttempt);

 }

  isFieldInvalid(field: string) { // {6}

      return (
        (!this.registercompanyFG.get(field).valid && this.registercompanyFG.get(field).touched) ||
        (this.registercompanyFG.get(field).untouched && this.formSubmitAttempt)

      );

    }

    public confirmation(){
      console.log('confirming company registration')
    }



}
