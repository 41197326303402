<span *ngIf = "data.type=='category'"><i class="material-icons icon-addfolderactive" [ngStyle]="{'color': 'yellow'}">folder_special</i>
    <div mat-dialog-content>
      <p>Enter Toolbar Name</p>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="addCategory(data.name)">Create</button>
      <button mat-raised-button color="primary"  [ngStyle]="{'margin-left': '10px'}"  (click)="onNoClick()">Cancel</button>
     
      
    </div>
</span>


<span *ngIf  = "data.type=='nodeteam'"><i class="material-icons icon-docaddpopup">group</i>
  <span mat-dialog-content>
   Create Toolbar
    
  </span>
  <div mat-dialog-actions>
   
    <mat-card class = "build-container">

    
               <span *ngIf="this.configSvc.teamtype=='node'"><mat-card-title>Toolbar Setup</mat-card-title></span> 
                <mat-horizontal-stepper [linear]="isLinear" #stepper>
               
               
                  <mat-step [stepControl]="step1">
    <form [formGroup]="teamfileFG">
            <ng-template matStepLabel>
                 Toolbar Name
                   </ng-template>
        <mat-form-field appearance="fill" class= "large-item">
            <mat-label>Create a new Toolbar</mat-label>
            <input formControlName ="teamname"  matInput placeholder="Team Name" >
                <i  class="material-icons icon-group" matSuffix style="color:black">group</i>
            </mat-form-field>
<div>
            <mat-form-field appearance="fill" class= "large-item">
              <mat-label>Description of Toolbar</mat-label>
              <input formControlName ="teamdescription"  matInput placeholder="Description" >
                 
              </mat-form-field></div>
  
            <div>
                   
                <button  mat-raised-button color="primary" (click)="this.addMyself();" mat-button matStepperNext>Next</button>
                </div>
            </form>
            </mat-step>

            <mat-step [stepControl]="step2">
              <ng-template matStepLabel>Designers </ng-template>
                
                     <app-teamsecurity></app-teamsecurity>   
                     <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                     <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                     <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                
              </mat-step>

              <mat-step [stepControl]="step3">
                <ng-template matStepLabel>Toolbar Users </ng-template>
                  
                       <app-usersecurity></app-usersecurity>   
                       <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                       <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                       <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                  
                </mat-step>

                <mat-step [stepControl]="step4">
                        <form [formGroup]="teamfileFG">
                                <ng-template matStepLabel>
                                       Design Icon
                                       </ng-template>
                                       <div>
                                        <span>
                                          <app-iconcategories></app-iconcategories></span>
                                              <span class ="colorbox">
                                              <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                                            </span>
                                        
                                            <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px">Back</button>
                                          <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
                                        </div>
                                       </form>
                    </mat-step>

                  

                    <mat-step [stepControl]="step5">
                            <form [formGroup]="teamfileFG">
                                    <ng-template matStepLabel>
                                           Create ToolbarTeam 
                                           </ng-template>
                                         
                                           
                                           <div class = "summary" >
           
            
           
                                            <mat-form-field appearance="outline" floatLabel="always" >
                                            <mat-label >TeamName</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamname}}>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" floatLabel="always" >
                                              <mat-label >TeamDescription</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamdescription}}>
                                              </mat-form-field>
                                         
                                          
                                             
                                              <div class="example-list">
                                                <mat-label>Managers</mat-label>
                                                       <div class="box-list" *ngFor="let manager of this.configSvc.mgrsadded" (click)="this.configSvc.selectMgrRow(manager, true)"
                                                             [style.background]=" this.configSvc.highlightedMgrRow.indexOf(manager) == 0 ? 'lightblue' : ''">
                                                                       <div>                                                                                                      
                                                                                   
                                    </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{manager}}
                                             </div></div>
                                             <mat-label>Users</mat-label>
                                 <div class="box-list" *ngFor="let users of this.configSvc.usersadded" (click)="this.configSvc.selectUserRow(users, true)"
                                               [style.background]=" this.configSvc.highlightedUserRow.indexOf(users) == 0 ? 'lightblue' : ''">
                                                  <div> </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{users}}
                                                                                     </div></div>
                                 
                                                                                   </div>
                                 
                                 
                                                                           
                                                                            
                                                                                <mat-label>Icon</mat-label>
                                                                                <div>
                                                                                  <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || this.configSvc.teamdetailsds?.clr}" >{{this.configSvc.chosenIcon}} </i>
                                                                                 
                                                                                 </div>
  </div>

  <span *ngIf="this.configSvc.teamtype=='folder'">
 <button mat-raised-button color="primary"  (click)="this.configSvc.createTeam('team', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenIcon, true)
      ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Create Folder Team</button></span>
      
      <span *ngIf="this.configSvc.teamtype=='node'">
        <button mat-raised-button color="primary"  (click)="this.configSvc.nodeTeam('nodeteam', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
        this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenIcon, true)
        ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Toolbar Setup</button>
      </span>


              <span>
                    <button mat-raised-button color="primary" mat-button matStepperPrevious style="margin: 10px;">Back</button>
              </span>
                                           </form>
                        </mat-step>

                      
        </mat-horizontal-stepper>
      </mat-card>
  </div>
  </span>

  <span *ngIf = "data.type=='find'">
  <mat-horizontal-stepper>
    <mat-step [stepControl]="findstep">
      <ng-template matStepLabel>Find</ng-template>
    <i class="material-icons icon-stepnode" [ngStyle]="{'color': 'lighblue'}">search</i>
  
    <mat-radio-group  [(ngModel)]="searchtype" class="searchtypes"
                             class="radio-container">
                             <mat-radio-button value="name"> Search by Name</mat-radio-button>
                            

                             <mat-radio-button value="type"> Search by Type</mat-radio-button>
                        

                            <mat-radio-button value="team"> Search by Toolbar Design Team</mat-radio-button>
                          </mat-radio-group>
      
  <div *ngIf="searchtype == 'name'">
    <div mat-dialog-content>
      
      <mat-form-field>
        <mat-label>Name Starts with: </mat-label>
        <input matInput [(ngModel)]="searchname">
      </mat-form-field>
  
  
    </div>
    <div mat-dialog-actions>
      <button matSuffix mat-raised-button color="primary" aria-label="Cancel" (click)="onNoClick()">Cancel</button>
      <button matSuffix mat-raised-button color="primary" aria-label="Find by Name" (click)="this.findItem(data.name, 'name')">Find by Name</button>
    </div>
  
 </div>

 



     
  
     
     
   
    </mat-step>
 
    <mat-step [stepControl]="assignstep">

        <ng-template matStepLabel>Assignment</ng-template>
      
     <app-assignteam></app-assignteam>
    
    
        <button matSuffix mat-raised-button color="primary" aria-label="Add" (click)="this.addtoFlow()">Add to {{this.configSvc.activename}}</button>
    </mat-step>
    
    
    </mat-horizontal-stepper>
    

  
  

      
   
  </span>
<span *ngIf = "data.type=='form' || data.type=='smartfolder'"><i class="material-icons icon-addtageritem" [ngStyle]="{'color': 'pink'}">assignment</i>
    
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <form [formGroup]="formFG">

     
    <mat-step [stepControl]="step1">
    <ng-template *ngIf="data.type =='form'" matStepLabel>Template</ng-template>
    <ng-template *ngIf="data.type =='smartfolder'" matStepLabel>Smartfolder</ng-template>
  
  <div mat-dialog-content>

      <mat-form-field appearance="fill" style ="width: 50vw">
     <span *ngIf="data.type =='form'">
      <input formControlName ="formname"  matInput placeholder="Template Name" ></span>  
     <span *ngIf="data.type =='smartfolder'"> 
        <input formControlName ="formname"  matInput placeholder="Smart Folder Name" ></span> 
      </mat-form-field>

    

      <div>
      <mat-form-field appearance="fill" style ="width: 50vw">
        <span *ngIf="data.type =='form'"> 
          <input formControlName ="formdesc"  matInput placeholder="Template Description" ></span>  
     <span *ngIf="data.type =='smartfolder'"> 
        <input formControlName ="formdesc"  matInput placeholder="Smart Folder Description" ></span>
      </mat-form-field>
    
    </div>
    <mat-radio-group formControlName ="formcopy" class="searchtypes">
      <mat-radio-button value="New" 
      checked= 'New'
      > Create New</mat-radio-button>
     
  
      <mat-radio-button  value="Copy"
      
      > Copy existing</mat-radio-button>
  
  
    
   </mat-radio-group>
    
 <div>
 
      </div>  
      
  
</div>
  <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>

 </mat-step>

 <mat-step *ngIf = "this.formFG.value.formcopy == 'New'" [stepControl]="step2">
 <ng-template matStepLabel>
      
    
  Create New</ng-template>
  <span *ngIf="data.type =='smartfolder'"> 
  <mat-label>Choose what type of Smart Folder you like to create:</mat-label></span>
  <span *ngIf="data.type =='form'"> 
    <mat-label>Choose what type of Template you like to create:</mat-label></span>
 <div> <mat-radio-group formControlName ="formtype"
  >
  <mat-radio-button value="basic">Basic </mat-radio-button> 
 
  <mat-radio-button value="tabbed">Tabbed</mat-radio-button>
  </mat-radio-group></div>
  <div>
    <mat-checkbox formControlName = "textractable" >
      Textract values from attachments</mat-checkbox>

      <span *ngIf="this.formFG.value.textractable">
      <mat-radio-group formControlName ="textractype"
  >
  <mat-radio-button value="form">Form </mat-radio-button> 
 
  <mat-radio-button value="invoice">Invoice</mat-radio-button>
    </mat-radio-group></span>
  </div>

  <div>
  
    <mat-checkbox formControlName = "ischatgpt" >
      Chat GPT Template</mat-checkbox>
    
  </div>

  <div>
  
    <mat-checkbox formControlName = "website" >
      Include link to Website</mat-checkbox>

      <div *ngIf="this.formFG.value.website">
   
          <mat-form-field appearance="outline" floatLabel="always" style="width: 40vw">
            <mat-label >Base WebSite Address </mat-label><input matInput formControlName="weblink" matInput value='https://www.'>
           </mat-form-field>
   
            </div>
    
  </div>

    <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
  </mat-step>

  <mat-step *ngIf = "this.formFG.value.formcopy == 'Copy'" [stepControl]="step2">
    <ng-template matStepLabel>
         
       
    Copy</ng-template>
     <mat-label> Find Template</mat-label>
      <mat-form-field appearance="fill"  class = "indextemplates">
        <mat-label>Template</mat-label>
       
        <input type="text"  
        formControlName="indexname"   
        [matAutocomplete]="auto"
        
        matInput (keyup)="indexFilter($event)" placeholder="Index" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of this.configSvc.finditemds" [value]="item.name"  
    (onSelectionChange)="indexSelected(item)">
    <i class="material-icons icon-size" [ngStyle]="{'color': item.iconcolor}">
      {{item.icontype}}</i>
    {{item.name}}:<i>{{item.desc}} </i>
    
    </mat-option>
        </mat-autocomplete>
    
       
       </mat-form-field>
    
       <div>
      <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
        {{this.configSvc.nodeds[0]?.icontype}}</i>
      {{this.configSvc.nodeds[0]?.name}}:  {{this.configSvc.nodeds[0]?.desc}}
    </mat-label></div>
    
       <span *ngIf="this.indexready">
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
        
       </span>
      
     </mat-step>

  <mat-step [stepControl]="step3">
    <ng-template matStepLabel>
         
       
     Icon Design</ng-template>
     <mat-label>Design your icon below</mat-label>
     <div>
       <app-iconcategories></app-iconcategories>

       
       <span class ="colorbox">
        <app-color-picker (color)="this.configSvc.color"></app-color-picker>
      </span>

      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
       </div>
  </mat-step>

  <mat-step *ngIf="data.type == 'flow'"[stepControl]="step4">
    <ng-template mat StepLabel>
         
       
     Flow Assignment</ng-template>

     <app-flowassignment (inittype)="create"></app-flowassignment>
     <div><button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
    </div>

  </mat-step>

  <mat-step [stepControl]="step5">
    <ng-template matStepLabel>
      
    
      Create</ng-template>
    <div mat-dialog-actions>
      <div>
      <i class="material-icons md-48"
      [ngStyle]="{'color': this.configSvc.color}" >{{this.configSvc.chosenIcon}} </i>{{formFG.value.formname}}
      
      <i>{{formFG.value.formdesc}}</i>  
      <span *ngIf=" this.formFG.value.formcopy =='Copy'">(based on {{this.configSvc.nodeds[0]?.name}})</span>
      </div>
      <button mat-raised-button color="primary" (click)="onNoClick()" class="margin-container">Cancel</button>
     <span *ngIf="this.formFG.value.formcopy =='New' && data.type=='form'">
       
      <button mat-raised-button color="primary"
      (click)="this.createNode(formFG.value.formname, formFG.value.formdesc, formFG.value.formtype)" 
      class="margin-container">Create {{formFG.value.formtype}} Form</button>
    
    </span> 

    <span *ngIf="this.formFG.value.formcopy=='Copy' && data.type=='form'">
      
      <button mat-raised-button color="primary"
      (click)="this.copyDesign(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, this.configSvc.nodeds[0].type,  this.formFG.value.formname, this.formFG.value.formdesc, this.configSvc.chosenIcon, this.configSvc.color, this.statetracker, this.statelabel)" 
      class="margin-container">Create {{formFG.value.formname}}</button>
    
    </span> 
    
     <span *ngIf="data.type=='smartfolder'"> <button mat-raised-button color="primary"(click)="this.createsmartfolder(formFG.value.formname, formFG.value.formdesc, formFG.value.formtype, formFG.value.statetracker, formFG.value.statelabel)" class="margin-container">Create {{this.configSvc.formtypeSelected}} Form Folder</button></span>
      
     <span *ngIf="data.type=='flow'"> <button mat-raised-button color="primary"(click)="this.createFlow(formFG.value.formname, formFG.value.formdesc,  formFG.value.formtype)" class="margin-container">Create {{this.configSvc.formtypeSelected}} Flow </button></span>


    </div>
    </mat-step>
  </form> </mat-horizontal-stepper></span>

    <span *ngIf = "data.type=='flow'"><i class="material-icons icon-addtageritem" [ngStyle]="{'color': 'pink'}">assignment</i>
    
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <form [formGroup]="flowFG">
    
         
        <mat-step [stepControl]="step1">
          <ng-template matStepLabel>
          Flow Name</ng-template>
      
      <div mat-dialog-content>
    
          <mat-form-field appearance="fill">
            <mat-label>Flow Name</mat-label>
            <input formControlName ="flowname"  matInput placeholder="Flow Name" >
          </mat-form-field>
    
        
    
          <div>
          <mat-form-field appearance="fill">
            <mat-label>Form Description</mat-label>
            <input formControlName ="flowdesc"  matInput placeholder="Flow Description" >
          </mat-form-field>
        
        </div>
        <mat-radio-group formControlName ="formcopy" class="searchtypes">
        <mat-radio-button value="New" 
        checked= 'New'
        > Create New</mat-radio-button>
       
    
        <mat-radio-button  value="Copy"
        
        > Copy existing</mat-radio-button>
    
    
      
     </mat-radio-group>
        
  
          
      
    </div>
      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
    
     </mat-step>
    
     <mat-step *ngIf = "this.flowFG.value.formcopy == 'New'" [stepControl]="step2">
     <ng-template matStepLabel>
          
        
      Create New</ng-template>
      <mat-label>Choose what type of Form you like to create:</mat-label>
      <div>
          <mat-radio-group formControlName ="flowtype"
          >
          <mat-radio-button value="basicflow">Basic Flow</mat-radio-button> 
          <mat-radio-button value="wizardflow">Wizard Flow</mat-radio-button>
          <mat-radio-button value="tabbedflow">Tabbled Flow</mat-radio-button>

    <div *ngIf="this.flowFG.value.flowtype == 'basicflow'"><mat-label>This is a basic form for saving sets of data to a folder</mat-label></div> 
    <div *ngIf="this.flowFG.value.flowtype  == 'wizardflow'"><mat-label>Stepper provides a wizard-like workflow by dividing content into logical steps</mat-label></div> 
    <div *ngIf="this.flowFG.value.flowtype  == 'tabbedflow'"><mat-label> Tabs organize content into separate views where only one view can be visible at a time</mat-label></div> 
    
      
        </mat-radio-group></div>
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
      </mat-step>
    
      <mat-step *ngIf = "this.flowFG.value.formcopy == 'Copy'" [stepControl]="step2">
        <ng-template matStepLabel>
             
           
        Copy</ng-template>
         <mat-label>Choose what Form you like to Copy:</mat-label>
         <div>
    
          <i class="material-icons icon-stepnode" [ngStyle]="{'color': 'lighblue'}">search</i>
      
          <mat-radio-group  [(ngModel)]="searchtype" class="searchtypes"
                                   class="radio-container">
                                   <mat-radio-button value="name"> Search by Name</mat-radio-button>
                                  
      
                                   <mat-radio-button value="type"> Search by Type</mat-radio-button>
                              
      
                                  <mat-radio-button value="team"> Search by Toolbar Design Team</mat-radio-button>
                                </mat-radio-group>
            
        <div *ngIf="searchtype == 'name'">
          <div mat-dialog-content>
            
            <mat-form-field>
              <mat-label>Name Starts with: </mat-label>
              <input matInput [(ngModel)]="searchname">
            </mat-form-field>
        
        
          </div>
          <div mat-dialog-actions>
            <button matSuffix mat-raised-button color="primary" aria-label="Cancel" (click)="onNoClick()">Cancel</button>
            <button matSuffix mat-raised-button color="primary" aria-label="Find by Name" (click)="this.findItem(searchname, 'name')">Find by Name</button>
          </div>
        
       </div>
      
       <div *ngIf="searchtype == 'type'">
        <div mat-dialog-content>
          
          <mat-form-field>
            <mat-label>Choose Search Type</mat-label>
            <mat-select 
            > 
            <mat-option *ngFor="let type of searchtypes" [value]="type">
                    {{type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
      
      
        </div>
        <div mat-dialog-actions>
          <button matSuffix mat-raised-button color="primary" aria-label="Cancel" (click)="onNoClick()">Cancel</button>
          <button matSuffix mat-raised-button color="primary" aria-label="Find by Type" (click)="this.findItem(data.name, 'name')">Find by Type</button>
        </div>
      
          </div>
      
      
      
            <div *ngIf="this.configSvc.finditemfound" class="outerbox" >
              <table mat-table [dataSource]="this.configSvc.finditemds"  >
              
              
               <ng-container matColumnDef="icontype" >
                    <th mat-header-cell *matHeaderCellDef>Icon</th>
                 <td mat-cell *matCellDef="let element">
                  <i class="material-icons columnicons" [ngStyle]="{'color': element.iconcolor}" >{{element.icontype}}</i> 
              
                  
               </td></ng-container>
                         
              <ng-container matColumnDef="name" >
                    <th mat-header-cell *matHeaderCellDef>Name </th>
                 <td mat-cell *matCellDef="let element">
                  
                     {{element.name}}
               </td></ng-container>
      
               <ng-container matColumnDef="type" >
                <th mat-header-cell *matHeaderCellDef>Type </th>
             <td mat-cell *matCellDef="let element">
              <span *ngIf="element.type == 'stepper' || element.type == 'wizard'">
                <i class="material-icons columnicons" [ngStyle]="{'color': 'blue'}" >dynamic_feed</i> 
              </span>
      
              <span *ngIf="element.type == 'flow'">
                <i class="material-icons columnicons" [ngStyle]="{'color': 'red'}" >double_arrow</i> 
              </span>
      
              <span *ngIf="element.type == 'node'">
                <i class="material-icons columnicons" [ngStyle]="{'color': 'pink'}" >assignment</i> 
              </span>
      
              <span *ngIf="element.type == 'foldertags'">
                <i class="material-icons columnicons" [ngStyle]="{'color': 'orange'}" >bookmarks</i> 
              </span>
      
              <span *ngIf="element.type == 'kanban'">
                <i class="material-icons columnicons" [ngStyle]="{'color': 'green'}" >view_column</i> 
              </span>
           </td></ng-container>
      
           
           <ng-container matColumnDef="desc" >
            <th mat-header-cell *matHeaderCellDef>Description </th>
         <td mat-cell *matCellDef="let element">
          
             {{element.desc}}
       </td></ng-container>
      
       <ng-container matColumnDef="ab" >
        <th mat-header-cell *matHeaderCellDef>Added By </th>
      <td mat-cell *matCellDef="let element">
      
         {{element.ab}}
      </td></ng-container>
      
      <ng-container matColumnDef="ao" >
        <th mat-header-cell *matHeaderCellDef>Added On </th>
      <td mat-cell *matCellDef="let element">
      
         {{element.ao | date:'medium'}}  
      </td></ng-container>
      
      <tr mat-header-row *matHeaderRowDef="findColumns;  sticky: true"></tr>
      <tr mat-row *matRowDef="let selectedrow; columns: findColumns; let i2 = index;"
      (click)="this.selectItem(selectedrow)"
      [style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"
      
      ></tr>
      
                   
              
                 </table>
            </div>
        
            <div *ngIf="this.itemselected == true">
      
              <span *ngIf="this.configSvc.activetype == 'category'">
                Taking a copy of {{this.itemsadded[0].name}}
      
            
              </span>
      
      
              <span *ngIf="this.configSvc.activetype== 'editflow'">
              <button mat-raised-button color="primary" mat-button matStepperNext style="margin: 10px">Next</button>
            </span>
      
          </div>
           
            </div>
           <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
         </mat-step>
    
      <mat-step [stepControl]="step3">
        <ng-template matStepLabel>
             
           
         Icon Design</ng-template>
         <mat-label>Design your form icon below</mat-label>
         <div>
           <app-iconcategories></app-iconcategories>
    
           
           <span class ="colorbox">
            <app-color-picker (color)="this.configSvc.color"></app-color-picker>
          </span>
    
          <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
           </div>
      </mat-step>
    
      <mat-step [stepControl]="step4">
        <ng-template matStepLabel>
             
           
         Flow Assignment</ng-template>
    
         <app-flowassignment (inittype)="create"></app-flowassignment>
         <div><button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
        </div>
    
      </mat-step>
    
      <mat-step [stepControl]="step5">
        <ng-template matStepLabel>
          
        
          Create New</ng-template>
        <div mat-dialog-actions>
    
          <i class="material-icons md-48"
          [ngStyle]="{'color': this.configSvc.color}" >{{this.configSvc.chosenIcon}} </i> {{this.flowFG.value.flowname}}
          <div>
          <i>{{this.flowFG.value.flowdesc}}</i> 
          </div>
          <button mat-raised-button color="primary" (click)="onNoClick()" class="margin-container">Cancel</button>
         <span *ngIf="formcopy=='New' && data.type=='form'">
           
          <button mat-raised-button color="primary"
          (click)="this.createNode(data.name, data.desc, this.configSvc.formtypeSelected, this.statetracker, this.statelabel)" 
          class="margin-container">Create {{this.configSvc.formtypeSelected}} Form</button>
        
        </span> 
    
        <span *ngIf="formcopy=='Copy' && data.type=='form'">
           
          <button mat-raised-button color="primary"
          (click)="this.copyDesign(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.itemsadded[0]._id, this.itemsadded[0].type,  data.name, data.desc, this.configSvc.chosenIcon, this.configSvc.color, this.statetracker, this.statelabel)" 
          class="margin-container">Copy works{{this.itemsadded[0]?.name}}</button>
        
        </span> 
        
         <span *ngIf="data.type=='smartfolder'"> <button mat-raised-button color="primary"(click)="this.createsmartfolder(data.name, data.desc, this.configSvc.formtypeSelected, this.statetracker, this.statelabel)" class="margin-container">Create {{this.configSvc.formtypeSelected}} Form Folder</button></span>
          
         <span *ngIf="data.type=='flow'"> <button mat-raised-button color="primary"(click)="this.createFlow(this.flowFG.value.flowname,this.flowFG.value.flowdesc, this.flowFG.value.flowtype)" class="margin-container">Create {{this.flowFG.value.flowtype}} </button></span>
    
    
        </div>
        </mat-step>
     </form>   </mat-horizontal-stepper></span>

    <span *ngIf = "data.type=='foldertags'"><i class="material-icons icon-addtageritem" [ngStyle]="{'color': 'gold'}">bookmarks</i>
      <div mat-dialog-content>
        <p>Enter Folder Tag Name</p>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="data.name">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Folder Tag Description</mat-label>
          <input matInput [(ngModel)]="data.desc">
        </mat-form-field>

      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-button (click)="this.createIndexer(data.name, data.desc, data.type)">Create Indexer</button>
        
      </div>
</span>

<span *ngIf = "data.type=='kanban'"><i class="material-icons icon-addkanban" [ngStyle]="{'color': 'green'}">view_column</i>
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
    <form [formGroup]="formFG">

     
    <mat-step [stepControl]="step1">
      <ng-template matStepLabel>
      
    
        Name Kanban</ng-template>
         <div mat-dialog-content>
        <p>Enter Kanban Name</p>
        <mat-form-field  appearance="fill" style ="width: 50vw">
          <mat-label>Kanban Name</mat-label>
          <input formControlName ="formname"  matInput placeholder="Kanban Name" >
        </mat-form-field>
<div>
        <mat-form-field  appearance="fill" style ="width: 50vw">
          <mat-label>Kanban Description</mat-label>
          <input formControlName ="formdesc"  matInput placeholder="Kanban Description" >
        </mat-form-field></div>

        

      </div>

      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>

    </mat-step>

    <mat-step [stepControl]="step2">
      <ng-template matStepLabel>
      
    
        Kanban Type</ng-template>
         <div mat-dialog-content>
      
      


        <div>

          <mat-radio-group formControlName ="kantype" class="searchtypes">
        
            <mat-radio-button value="standard" 
            checked= 'Standard'
            > Track State</mat-radio-button>
        
            <div>
            <mat-radio-button value="time" 
            checked= 'Time'
            > Track Time</mat-radio-button>
          
            <span *ngIf="this.formFG.value.kantype  == 'time'" ><mat-checkbox formControlName = "settotal" >
              Set total</mat-checkbox>
            <span *ngIf="this.formFG.value.settotal">
              <mat-form-field appearance="fill">  
                <input formControlName="timetotal" type = "number" matInput value="{{this.formFG.value.timetotal}}">
              </mat-form-field></span>
            </span>
            </div>
           
            <div>
            <mat-radio-button  value="moneydollars"
            
            > Track Number total</mat-radio-button>
        
          
         

              <span *ngIf="this.formFG.value.kantype  == 'moneydollars' ||  this.formFG.value.kantype  == 'moneypounds' ">
                <mat-radio-group formControlName ="kantype" class="searchtypes">
        <span>
                  <mat-radio-button value="moneydollars" 
                  checked= 'Dollars'
                  > Dollars</mat-radio-button>
                  <mat-radio-button value="moneypounds" 
                  checked= 'Pounds'
                  > Pounds</mat-radio-button></span>
                </mat-radio-group>
                </span>
                         
             
          
          
          </div>
        
        
          
         </mat-radio-group>
        
        
          
        
        
        </div>

      </div>

      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>

    </mat-step>

    <mat-step [stepControl]="step3">
      <ng-template matStepLabel>
           
         
      Set Kanban Colour</ng-template>
       <mat-label>Choose colour below</mat-label> <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || 'green'}" >view_column</i>
       <div>
         
  
         
         <span class ="colorbox">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker>
        </span>
  
        <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
         </div>
    </mat-step>

    <mat-step [stepControl]="step4">
      <ng-template matStepLabel>
        
      
        Create Kanban</ng-template>
 
        <div>
        <i class="material-icons md-48"
        [ngStyle]="{'color': this.configSvc.color}" >view_column </i>{{formFG.value.formname}}
        
        <div><i>{{formFG.value.formdesc}}</i> </div> <span *ngIf=" this.formFG.value.formcopy =='Copy'">(based on {{this.configSvc.nodeds[0]?.name}})</span>
     
       
     
      
      <div>
        <button  mat-raised-button color="primary" style="margin-right: 20px" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" (click)="this.createKanban(formFG.value.formname, formFG.value.formdesc, 'kanban', formFG.value.kantype, formFG.value.settotal, formFG.value.timetotal)">Create Kanban</button>
        
      </div>
  
      </div>
      </mat-step>
    </form>   </mat-horizontal-stepper>
    
</span>+


<span *ngIf = "data.type=='track'"><i class="material-icons icon-addtrack" [ngStyle]="{'color': 'purple'}">sync_alt</i>
<div mat-dialog-content>
  <p>Enter Track Name</p>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Track Description</mat-label>
    <input matInput [(ngModel)]="data.desc">
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="this.addTrack(data.name, data.desc, 'track')">Create Track</button>
  
</div>
</span>

<span *ngIf = "data.type=='batchitems'"><i class="material-icons icon-addtageritem" [ngStyle]="{'color': 'pink'}">library_add</i>
    
  <mat-horizontal-stepper [linear]="isLinear" #stepper>
  

     
    <mat-step [stepControl]="step1">
      <ng-template matStepLabel>
      
    
        Multi Items</ng-template>
  
  <div mat-dialog-content>

      <mat-form-field appearance="fill">
        <mat-label>Batch name</mat-label>
        <input matInput [(ngModel)]="data.name">
      </mat-form-field>

    

      <div>
      <mat-form-field appearance="fill">
        <mat-label>Batch Description</mat-label>
        <input matInput [(ngModel)]="data.desc">
      </mat-form-field>
    
    </div>
  
    
  
      
  
</div>
  <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>

 </mat-step>



  <mat-step [stepControl]="step2">
    <ng-template matStepLabel>
         
       
     Icon Design</ng-template>
     <mat-label>Design your form icon below</mat-label>
     <div>
       <app-iconcategories></app-iconcategories>

       
       <span class ="colorbox">
        <app-color-picker (color)="this.configSvc.color"></app-color-picker>
      </span>

      <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
       </div>
  </mat-step>



  <mat-step [stepControl]="step3">
    <ng-template matStepLabel>
      
    
      Create</ng-template>
    <div mat-dialog-actions>

      <i class="material-icons md-48"
      [ngStyle]="{'color': this.configSvc.color}" >{{this.configSvc.chosenIcon}} </i> {{data.name}}
      <div>
      <i>{{data.desc}}</i> 
      </div>
      <button mat-raised-button color="primary" (click)="onNoClick()" class="margin-container">Cancel</button>

    
     <span *ngIf="data.type=='batchitems'"> <button mat-raised-button color="primary"(click)="this.createNode(data.name, data.desc, 'batchitems', false, '')" class="margin-container">Create Batch Items</button></span>
      
     

    </div>
    </mat-step>
    </mat-horizontal-stepper></span>
    








