import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {DocSearchService} from '../../services/docsearch.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-aisearchresults',
  templateUrl: './aisearchresults.component.html',
  styleUrls: ['./aisearchresults.component.css']
})
export class AisearchresultsComponent implements AfterViewInit {

  // displayedColumns = [];
  public  activeColumns = [];
  public dateTime;
  dataSource = new MatTableDataSource(this.configSvc.teamds);
  public sorted = [];
  public itemselected = false;
  public itemsadded = [];
  public clickCount = 0;
  public lineitemval = '';

  constructor(public configSvc: ConfigService, private activatedRoute: ActivatedRoute,public searchDocsvc: DocSearchService, private router: Router) { }

  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    this.activeColumns = ['mt','name', 'ab',  'mo', 'score', 'aidata'];
  }

  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dateTime = new Date().toISOString()
    this.activatedRoute.queryParams.subscribe(params => {
    if (this.activatedRoute.snapshot.queryParamMap.has('lineitem')){
this.lineitemval = params['lineitem']
console.log('single clicking lineitemval '+this.lineitemval);
this.activeColumns = ['mt','name',  'ab', 'ao', 'mo', 'lineitem'];
    }
  });
  }


public singleClick(){
  console.log('single clicking the search result item');
}

public doubleClick(){
  console.log('double clicking the search result item');
  
}

public getLineitem(lineitems){
  console.log('getting linetem '+this.lineitemval);
  var result = lineitems.filter(option => option.litem.toLowerCase().includes(this.lineitemval.toLowerCase()));
console.log('this is the matched lineitem'+JSON.stringify(result[0].litem));
var lineitem = []
lineitem = result;
return lineitem
}


public pathSort(path){
  this.sorted = [];

this.sorted = path.sort((a, b) => Number(a.level) - Number(b.level));

  return this.sorted

}

clickResult(item, docindex) {
  this.clickCount++;
  this.configSvc.folderitem = docindex;
  this.configSvc.source = 'search'
  this.itemselected = true;
  console.log('is item selected? '+this.itemselected)
  this.itemsadded = []
this.itemsadded.push(item)
  setTimeout(() => {
      if (this.clickCount === 1) {
          console.log('this is a single click')
      
console.log('this is the itemsadded item info'+JSON.stringify(this.itemsadded[0]))

console.log('selected items '+JSON.stringify(this.itemsadded))
      } else if (this.clickCount === 2) {
        console.log('this is a double click')
    
        this.itemselected = true;



var base = '/toolbard/home?'
var itemid = this.itemsadded[0]._id
var teamid = this.itemsadded[0].teamid;
console.log('teamid selected is '+teamid)
var type = this.itemsadded[0].type;
var linked = this.itemsadded[0].linked;
var name = this.itemsadded[0].name;
var level = this.itemsadded[0].level;

console.log('type selected is '+type)
if(type == 'node' ||  type == 'attachnode' ){ 
  console.log('type selected is '+type+'so setting type to nodedirect')
  type = 'nodedirect'}

if(type == 'nodetab' || type == 'attachnodetab'){
  console.log('this is a nodetab')
  type = 'tabbeddirect'}
if(type == 'nodeimage'){ type = 'imagedirect'}; if(type == 'file'){ type = 'filedirect'}

if(type == 'chatgpt'){
  console.log('this is a chatgpt item')
  type = 'chatgptdirect'
}

// var dynamicurl = base+'itemid='+itemid+'&type='+type+'&teamid='+teamid


if(item.linked == false || type == 'fold' ||  type == 'smartfoldertab' ||  type == 'filedirect'){
console.log('this is not a linked item go get it')
console.log('teamid 2 selected is '+teamid)
console.log('level 2 selected is '+level)
var dynamicurl = base+'itemid='+itemid+'&type='+type+'&level='+level+'&teamid='+teamid+'&name='+name
this.configSvc.tageritemready = false;
console.log('dynamicurl 2 selected is '+dynamicurl)
this.router.navigateByUrl(dynamicurl);
      }

      if(item.linked == true){
console.log('this is a linked item getting via different url')
var result = item.path.filter(option => option.type.toLowerCase().includes('nodedirect'));
console.log('result is '+JSON.stringify(result))
        itemid = result[0].pfoldid
        level = result[0].level
        teamid = result[0].teamid
        type = result[0].type
    
        console.log('type is '+type)
       
        

        var dynamicurl = base+'itemid='+itemid+'&type='+type+'&name='+name+'&level='+level+'&teamid='+teamid
        this.configSvc.tageritemready = false;
        this.router.navigateByUrl(dynamicurl);
              }


      }
      this.clickCount = 0;
  }, 250)
}



}
