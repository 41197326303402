

<mat-card >

  <mat-tab-group>
   
    <mat-tab >
      <ng-template mat-tab-label>
      <span *ngIf="this.configSvc.activetype == 'category'"> <i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >folder_special</i></span> 
      <span *ngIf="this.configSvc.activetype == 'nodeteam'"> <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeteamdetailsds[0]?.iconcolor}" >{{this.configSvc.nodeteamdetailsds[0]?.icontype}}</i></span> 
      <span *ngIf="this.configSvc.activetype != 'category' && this.configSvc.activetype != 'nodeteam'"> <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}" >{{this.configSvc.nodeds[0]?.icontype}}</i></span> 
       {{this.configSvc.activename}}
      </ng-template>
      <span class ="nodeconsole-container">
<span *ngIf=" this.configSvc.activetype !='batchitems' && this.configSvc.activetype != 'smartfolder' && this.configSvc.activetype != 'editstepperassign'  && this.configSvc.activetype != 'edittabbedassign' 
&& this.configSvc.activetype != 'smartfoldertab'  
&& this.configSvc.activetype != 'nodetab' && this.configSvc.activetype != 'editflow' && this.configSvc.activetype != 'node' && this.configSvc.activetype != 'basicflow' 
&& this.configSvc.activetype != 'editkanban' && this.configSvc.activetype != 'foldertags' && this.configSvc.activetype != 'smartfolder' "  >
  <mat-card class = "browser">  
  <span >
  
      <table mat-table [dataSource]="this.configSvc.nodeteamds">
    
           <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
    
             <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
           
             ></tr>
             
  
           
    
               <!-- MimeType Column -->
               <ng-container matColumnDef="MimeColumn">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                      <div  *ngIf="element.type == 'nodeteam'"> <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >{{element.icontype}} </i></div>
  
                     <div  *ngIf="element.type == 'category'">
                     
                     <i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >folder_special</i>
                    </div>
                  
                
                  
     
                 
                  
                  </td>
              </ng-container>
    
              <!--Node Column -->
            <ng-container matColumnDef="ItemColumn">
              <th mat-header-cell *matHeaderCellDef></th>
    
              <td mat-cell *matCellDef="let element; let rowcount = index;">
  
              <a *ngIf="element.type == 'nodeteam'" fxFlexFill
             
              [routerLink]="['/toolbard/nodeconsole']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element._id}"
             
              >
              <div *ngIf="element.type == 'nodeteam'" 
              (click)="this.configSvc.currentteam=element; this.clickNodeteam()"> 
              
              {{element.name}} </div>
              
             
              </a>
  
              <a *ngIf="element.type == 'category'" fxFlexFill
             
              [routerLink]="['/toolbard/nodeconsole']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
             
              >
            
              <div *ngIf="element.type == 'category'" (click)="this.configSvc.activetype='category';
          
               this.configSvc.typeenabled = true; 
              this.configSvc.itemlocked = false; this.configSvc.currentrow=element;this.clickCategory()"> {{element.name}} </div>
             
    </a>
  
    <a *ngIf="element.type == 'node' || element.type == 'nodeimage'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div *ngIf="element.type == 'node' || element.type == 'nodeimage'" (click)="this.configSvc.currentrow=element;this.clickNode(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}}    <i class="material-icons icon-string" title="Node">assignment</i> </div>
   
    </a>

    <a *ngIf="element.type == 'basicflow'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'basicflow', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div *ngIf="element.type == 'basicflow'" (click)="this.configSvc.currentrow=element;this.clickNode(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}}  <i class="material-icons icon-string" title="Basic Flow">multiple_stop</i></div>
   
    </a>

  

    <a *ngIf="element.type == 'batchitems'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'batchitems', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div *ngIf="element.type == 'batchitems'" (click)="this.configSvc.currentrow=element;this.clickNode(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}}  <i class="material-icons icon-string" title="Multi">library_add</i> </div>
   
    </a>
  
    <a *ngIf="element.type == 'foldertags'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'foldertags', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickNode(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}}<i class="material-icons icon-string" title="Multi">bookmarks</i> </div>
   
    </a>
  
    <a *ngIf="element.type == 'kanban'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'editkanban', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickKanban(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>
  
    <a *ngIf="element.type == 'flow'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'editflow', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickFlow(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}}<i class="material-icons icon-string" title="Multi">multiple_stop</i> </div>
   
    </a>
  
   

    <a *ngIf="element.type == 'editstepperassign'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'editstepperassign', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickStepper(element)" >
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>

    <a *ngIf="element.type == 'edittabbedassign'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'edittabbedassign', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickTabbed(element)" >
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>

    <a *ngIf="element.type == 'smartfolder'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'smartfolder', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickNode(element)" >
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>

    <a *ngIf="element.type == 'smartfoldertab'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'smartfoldertab', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickStepper(element)" >
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>

    <a *ngIf="element.type == 'nodetab'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:'nodetab', name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div (click)="this.configSvc.currentrow=element;this.clickTabbed(element)" >
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} <i class="material-icons icon-string" title="Multi">tab</i></div>
   
    </a>

  
  
  
    <div *ngIf="element.type == 'tag' || element.tagtype == 'tag'" class = "hover" 
    (click)="this.configSvc.currentrow=element;this.clickTag(element, 'edittag')"
    [style.background]=" this.configSvc.nodeds.indexOf(element) != -1 ? 'lightblue' : ''"
    >
    <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >{{element.icontype}} </i>
    
     {{element.formitem}} </div>
  
  
  
    
  
  
  
  
   
  
     
  
  
  
              
              
           
  
            
  
              <div *ngIf="element.type == 'form' || element.type == 'tagerseq' " class = "formhover" 
              (click)=" this.getActiveform(element) "
              
              [style.background]=" this.configSvc.hlitem.indexOf(element) != -1 ? 'lightblue' : ''"
              > {{element.name}}  
              <span *ngIf="element.status == 1" matTooltip="{{element.track}}" > 
                <i class="material-icons status-inprogress" >history</i>  
              </span>
              <span *ngIf="element.status == 2" matTooltip="{{element.track}}" > 
                <i class="material-icons status-trkcompleted">check_circle_outline </i>
              </span>
  
              <span *ngIf="element.status == 3" matTooltip="{{element.track}}" > 
                <i class="material-icons status-tagercompleted">check_circle_outline </i>
              </span>
           
            
            </div>
  
            <div *ngIf="element.type == 'tageritem'" class = "formhover"
            (click)=" this.getTageritem(element);"
            [style.background]=" this.configSvc.hlitem.indexOf(element) != -1 ? 'lightblue' : ''"
            > {{element.name}}
            <span *ngIf="element.tagged== true && element.type == 'file'" class="fa-1.7x"><span class="fa-layers fa-fw">
              <span ><i  class="material-icons file-tagged"  title="FileTagged">bookmark</i>
              </span></span></span>  
            <span *ngIf="element.cmt== true">
              <i class="material-icons" style="font-size: 15px; color : orange" >chat</i></span>  
  
            </div>
  
    
               
  
              
           
  
                </td>
            </ng-container>
  
            
    
    
        </table></span>
        <span (click)="deselect()" class = "deselect"></span>

       
      </mat-card>
</span>

<span *ngIf= "this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'editstepperassign'
 || this.configSvc.activetype == 'nodetab' || this.configSvc.activetype == 'editflow'  || this.configSvc.activetype == 'basicflow'
|| this.configSvc.activetype == 'node' || this.configSvc.activetype == 'foldertags' || this.configSvc.activetype == 'editkanban' || this.configSvc.activetype == 'smartfoldertab'
|| this.configSvc.activetype == 'smartfolder' || this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'edittabbedassign' "  

[ngClass]= "
{'wideleftpane': this.configSvc.activetype == 'smartfolder' || this.configSvc.activetype == 'editstepperassign' ||
this.configSvc.activetype == 'nodetab' || this.configSvc.activetype == 'editflow'  || this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'edittabbedassign',

 'narrowleftpane': this.configSvc.activetype == 'node' || this.configSvc.activetype == 'foldertags' || this.configSvc.activetype == 'editkanban' || this.configSvc.activetype == 'foldertags' 
 || this.configSvc.activetype == 'smartfolder' || this.configSvc.activetype == 'basicflow' } "
>

  <span class = "innerextrawide" *ngIf= " this.configSvc.activetype == 'editstepperassign'
  || this.configSvc.activetype == 'nodetab' || this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'edittabbedassign' " >
  
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'steps')">
      <div class="example-box" *ngFor="let step of this.configSvc.nodestepds; let stepcount = index;" cdkDrag
      (click)="this.configSvc.currentrow=step;this.clickStep(step, stepcount)"
      [style.background]=" this.configSvc.hlstep.indexOf(step) != -1 ? 'lightblue' : ''">
     <i class="material-icons md-48" [ngStyle]="{'color': step.iconcolor}" >{{step.icontype}}</i>
   {{step.step}}
  </div>
      </div></span>

      <span class = "innerextrawide" *ngIf= "this.configSvc.activetype == 'editflow'" >
  
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'flow')">
          <div class="example-box" *ngFor="let flowitem of this.configSvc.nodeflowds" cdkDrag
          (click)="this.configSvc.currentrow=flowitem;this.clickFlowitem(flowitem)"
          [style.background]=" this.configSvc.flowitemdetailsds.indexOf(flowitem) != -1 ? 'lightblue' : ''">
          <i class="material-icons md-48" [ngStyle]="{'color': flowitem.iconcolor || 'black'}" >{{flowitem.icontype}} </i>
       {{flowitem.name}}
      </div>
          </div></span>

<span *ngIf= "this.configSvc.activetype == 'editstepperassign' 
|| this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'edittabbedassign'" class = "innerextrawide">

<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'tags')">
<div class="example-box" *ngFor="let tag of this.configSvc.nodetagsds" cdkDrag
(click)="this.configSvc.currentrow=tag;this.clickTag(tag, 'wizard')"
[style.background]=" this.configSvc.nodeds.indexOf(tag) != -1 ? 'lightblue' : ''">
<i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >{{tag.icontype}} </i>
{{tag.formitem}}
</div>
</div></span>

<span *ngIf= "this.configSvc.activetype == 'nodetab'" class = "innerextrawide">

  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'tags')">
  <div class="example-box" *ngFor="let tag of this.configSvc.nodetagsds" cdkDrag
  (click)="this.configSvc.currentrow=tag;this.clickTag(tag, 'nodetab')"
  [style.background]=" this.configSvc.nodeds.indexOf(tag) != -1 ? 'lightblue' : ''">
  <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >{{tag.icontype}} </i>
  {{tag.formitem}}
  </div>
  </div></span>

<span *ngIf= "this.configSvc.activetype == 'node' || this.configSvc.activetype == 'foldertags' || this.configSvc.activetype == 'smartfolder' || this.configSvc.activetype == 'basicflow' " class = "innerextrawide">

  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'tags')">
  <div class="example-box" *ngFor="let tag of this.configSvc.nodetagsds" cdkDrag
  (click)="this.configSvc.currentrow=tag;this.clickTag(tag, this.configSvc.activetype)"
  [style.background]=" this.configSvc.nodeds.indexOf(tag) != -1 ? 'lightblue' : ''">
  <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >{{tag.icontype}} </i>
  {{tag.formitem}}
  </div>
  </div></span>

  <span *ngIf= "this.configSvc.activetype == 'editkanban'  " class = "innerextrawide">

    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event, 'track')">
    <div class="example-box" *ngFor="let track of this.configSvc.nodeteamds" cdkDrag
    (click)="this.configSvc.currentrow=tag;this.clickTrack(track)"
    [style.background]=" this.configSvc.trackdetailsds.indexOf(track) != -1 ? 'lightblue' : ''">
    <i class="material-icons md-48" [ngStyle]="{'color': 'purple'}" >sync_alt </i>
    {{track.formitem}}
    </div>
    </div></span> 


</span>

<span *ngIf="this.configSvc.activetype =='batchitems' && this.configSvc.nodedetailsready == true">
  <mat-card *ngFor="let r of reset"  class="editags">
    <app-smartfolders></app-smartfolders></mat-card></span>
    
   
  <span>
               
          
  
  
                <mat-card *ngIf="this.configSvc.activetype== 'nodeteam' && this.configSvc.nodedetailsready == true" 
                [ngClass]= "
                {'edittypelrg': this.configSvc.fullpage == true,
                 'edittypesml': this.configSvc.fullpage == false} "
                >
                 
                  <app-editnodeteam></app-editnodeteam></mat-card>
  
                  
         <mat-card *ngIf="this.configSvc.activetype =='category' && this.configSvc.categoryready"
                      [ngClass]= "
                      {'edittypelrg': this.configSvc.fullpage == true,
                       'edittypesml': this.configSvc.fullpage == false } ">
                        
      <app-editcategory></app-editcategory></mat-card>
  
     
  
      
  
         

              <span *ngIf="this.configSvc.activetype =='smartfolder' && this.configSvc.nodedetailsready == true && this.configSvc.editstepper =='stepper'"
              class ="edittypelrg">
              <mat-card *ngFor="let r of reset"> 
                
                  <app-editstepper></app-editstepper></mat-card></span>
  
                  <span *ngIf="this.configSvc.activetype =='editstepperassign' && this.configSvc.nodedetailsready == true && this.configSvc.editstepper =='stepper'"
                  class ="edittypelrg">
                  <mat-card *ngFor="let r of reset"> 
                    
                      <app-editstepper></app-editstepper></mat-card></span>

                      <span *ngIf="this.configSvc.activetype =='edittabbedassign' && this.configSvc.nodedetailsready == true && this.configSvc.nodetab =='tabbed'"
                  class ="edittypelrg">
                  <mat-card *ngFor="let r of reset"> 
                    
                      <app-editstepper></app-editstepper></mat-card></span>

              
              <span *ngIf="this.configSvc.activetype =='nodetab' && this.configSvc.nodedetailsready == true && this.configSvc.nodetab =='tabbed'"
          class ="edittypelrg">
          <mat-card *ngFor="let r of reset"> 
            
              <app-edittabs></app-edittabs></mat-card></span>

              <span *ngIf="this.configSvc.activetype =='smartfoldertab' && this.configSvc.nodedetailsready == true && this.configSvc.nodetab =='tabbed'"
              class ="edittypelrg">
              <mat-card *ngFor="let r of reset"> 
                
                  <app-edittabs></app-edittabs></mat-card></span>
                         
  <div>
                             <span *ngIf="this.configSvc.activetype =='node' && this.configSvc.editnode =='editnode' && this.configSvc.nodedetailsready == true">
                               <mat-card *ngFor="let r of reset" > 
                       <app-editnode></app-editnode>
                      </mat-card></span> 
  
                      <span *ngIf="this.configSvc.activetype =='foldertags' && this.configSvc.editnode =='editnode' && this.configSvc.nodedetailsready == true">
                        <mat-card *ngFor="let r of reset"> 
                <app-editnode></app-editnode>
  </mat-card></span> 

  <span *ngIf="this.configSvc.activetype =='smartfolder' && this.configSvc.editnode =='editnode' && this.configSvc.nodedetailsready == true">


    <mat-card *ngFor="let r of reset"> 
<app-editnode></app-editnode>
</mat-card>


</span> 



<span *ngIf="this.configSvc.activetype =='basicflow' && this.configSvc.nodedetailsready == true">
  <mat-card *ngFor="let r of reset"> 
<app-editnode></app-editnode>
</mat-card></span> 
  
  <span *ngIf="this.configSvc.activetype =='editkanban' && this.configSvc.editkanban =='edittrack'">
    <mat-card *ngFor="let r of reset"> 
  <app-edittrack></app-edittrack>
  </mat-card></span> 
  
  

  <span *ngIf="this.configSvc.editstepperassign =='editstep' && this.configSvc.activetype == 'editstepperassign'">
    <mat-card *ngFor="let r of reset"> 
  <app-editstep></app-editstep>
  </mat-card></span> 

  <span *ngIf="this.configSvc.edittabbedassign =='editstep' && this.configSvc.activetype == 'edittabbedassign'">
    <mat-card *ngFor="let r of reset"> 
  <app-editstep></app-editstep>
  </mat-card></span> 

  <span *ngIf="this.configSvc.editstepper =='editstep' && this.configSvc.activetype == 'smartfolder'">
    <mat-card *ngFor="let r of reset"> 
  <app-editstep></app-editstep>
  </mat-card></span> 

  <span *ngIf="this.configSvc.nodetab =='editstep' && this.configSvc.activetype == 'nodetab'">
    <mat-card *ngFor="let r of reset"> 
  <app-editstep></app-editstep>
  </mat-card></span> 

  <span *ngIf="this.configSvc.nodetab =='editstep' && this.configSvc.activetype == 'smartfoldertab' && this.configSvc.stepready == true">
    <mat-card *ngFor="let r of reset" > 
  <app-editstep></app-editstep>

  
  </mat-card></span> 

  <span *ngIf="this.configSvc.editflow =='editflow' && this.configSvc.activetype == 'editflow'">
    <mat-card *ngFor="let r of reset"> 
  <app-editflow></app-editflow>
  </mat-card></span> 

  <span *ngIf="this.configSvc.editflow =='editflowitem' && this.configSvc.activetype == 'editflow' && this.configSvc.flowready">
    <mat-card *ngFor="let r of reset"> 
  <app-editflowitem></app-editflowitem>
  </mat-card></span> 
  
                    </div>
  
                      

                        <span *ngIf="this.configSvc.editnode =='edittag' && this.configSvc.activetype == 'node'">
                          <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                          </span>

                          <span *ngIf="this.configSvc.editnode =='edittag' && this.configSvc.activetype == 'basicflow'">
                            <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                            </span>

                          <span *ngIf="this.configSvc.editnode =='edittag' && this.configSvc.activetype == 'foldertags'">
                            <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                            </span>

                       

                        <span *ngIf="this.configSvc.editstepper =='edittag' && this.configSvc.activetype == 'smartfolder'">
                          <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                          </span>

                          <span *ngIf="this.configSvc.editstepperassign =='edittag' && this.configSvc.activetype == 'editstepperassign'">
                            <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                            </span>

                            <span *ngIf="this.configSvc.edittabbedassign =='edittag' && this.configSvc.activetype == 'edittabbedassign'">
                              <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                              </span>

                        <span *ngIf="this.configSvc.nodetab =='edittag' && this.configSvc.activetype == 'nodetab'">
                          <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                          </span>

                          <span *ngIf="this.configSvc.nodetab =='edittag' && this.configSvc.activetype == 'smartfoldertab'">
                            <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                            </span>

                            <span *ngIf="this.configSvc.editnode =='edittag' && this.configSvc.activetype == 'smartfolder'">
                              <mat-card *ngFor="let r of reset"  class="editags"><app-edittag></app-edittag></mat-card>
                              </span>

                        <span *ngIf="this.configSvc.activetype =='editkanban' && this.configSvc.editkanban == 'editkanban' && this.configSvc.nodedetailsready == true">
                          <mat-card *ngFor="let r of reset"  class="editags">
                                <app-editkanban></app-editkanban></mat-card></span>
  
                               
  
  
                    
                 <ng-container  *ngIf="this.configSvc.activetool =='addcomment'">
                      <div class = "addcommentcontainer">
                       <app-addcomments></app-addcomments></div></ng-container>
  
                      </span>
                      <span (click)="deselect()" class = "deselect">
          
                  
                      </span>  </span>  </mat-tab>  
    
    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.type == 'smartfolder' && this.configSvc.nodeds[0]?.folders == true" label="Sub Folders">
      <ng-template mat-tab-label>
        <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}" >folder_special</i>
       Sub Items
      </ng-template>
      <app-smartfolders></app-smartfolders>
    </mat-tab>     

    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.type == 'smartfolder' && this.configSvc.nodeds[0]?.kanbantagged == true" label="Sub Folders">
      <ng-template mat-tab-label>
        <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.nodeds[0]?.kanbancolor}">view_column</i>
        {{this.configSvc.nodeds[0]?.kanbanname}}
      </ng-template>
      <app-kanban></app-kanban>
    </mat-tab> 

    
    </mat-tab-group>       </mat-card>
                                      
  