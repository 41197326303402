import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import {ConfigService} from '../../../services/config.service';
import {SidenavService} from '../../../services/sidenav.service';
import {TagerService} from '../../../services/tager.service';
import { Router } from '@angular/router';
import { PrefsService } from 'src/app/services/prefs.service';

@Component({
  selector: 'app-topright',
  templateUrl: './topright.component.html',
  styleUrls: ['./topright.component.css']
})
export class ToprightComponent implements OnInit{

  constructor(public auth: AuthenticationService, public configSvc: ConfigService, public prefSvc: PrefsService,
    public sidenavSvc: SidenavService, public tagerSvc: TagerService, private router: Router) { }

    ngOnInit() {
     // console.log('getting latest categories')
     // this.tagerSvc.getCategories('root', 1, true)
    }

    getCats(category)
    {
      console.log('this is the category we are getting '+category)
      this.tagerSvc.getTagerbyCategory(category, 1)
     
      this.configSvc.toolmenu = 'category'; 
      console.log('this is the current toolmenu '+this.configSvc.toolmenu )
     
    }

    public gotoVersion(){
      this.router.navigateByUrl('/toolbard/version');
    }

    public searchMode(){
      this.router.navigateByUrl('/toolbard/search');
      this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = ''; this.configSvc.fullpage = true
    }

    public designMode(){
      this.router.navigateByUrl('/toolbard/nodeconsole');
      this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = ''; this.configSvc.fullpage = true;
      this.configSvc.folderpath = []
    }

    public browseMode(){
      this.router.navigateByUrl('/toolbard/home');
      this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = '';
       this.configSvc.fullpage = true
    }

    public myfeedMode(){
      this.router.navigateByUrl('/toolbard/myfeed');
      this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = ''; this.configSvc.fullpage = true
    }


  

    public inboxMode(){
      this.router.navigateByUrl('/toolbard/teamqueue');
      this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = '';
      this.configSvc.fullpage = true
    }

    public listMode(){
      this.router.navigateByUrl('/toolbard/choicesets');
    //  this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = '';
      this.configSvc.fullpage = true
    }

    public promptMode(){
      this.router.navigateByUrl('/toolbard/prompts');
    //  this.configSvc.toolmenu = '';
      this.sidenavSvc.activesidenav = '';
      this.configSvc.fullpage = true
    }


    public gotoPrefs(){
      this.router.navigateByUrl('toolbard/mypreferences');
    }

    public gotoCompany(){
      this.router.navigateByUrl('/toolbard/company');
    }

    public gotoPeople(){
      this.router.navigateByUrl('/toolbard/people');
    }
    
    public getfavTools(){
      console.log('setting the fav tools');
      this.configSvc.toolmenu = 'toolbartools'; console.log('active toolmenu is set to toolbartools')
      this.configSvc.favtools = true;
      this.configSvc.activeitemid = this.configSvc.currentfolder
      this.configSvc.activename = this.configSvc.currentfoldername
      this.configSvc.hlitem = []; this.configSvc.nodeready = false; this.configSvc.nodeds = [];
     this.configSvc.getfavToolbars()

    }

    public getfavLocs(){
      this.configSvc.toolmenu = 'favlocations'; console.log('active toolmenu is set to toolbartools')
      this.prefSvc.getfavLocations()
    }

    public addBookmark(){
      console.log('bookmarking this location');
      console.log('active url  '+this.router.url);
      this.prefSvc.addBookmark(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activename,this.configSvc.activelevel,  this.configSvc.activeteamid )
    }

   


}
