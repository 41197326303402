import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import {ConfigService} from '../../../services/config.service';
import {TagerService} from '../../../services/tager.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';

export interface DialogData {
 
  formitem: string;
  type: string;
  formitemlabel: string;
  defaultvalue: string;
 

}

interface Rowsize {value: string; viewValue: string;
}
interface Imagesize {value: string; viewValue: string;
}

@Component({
  selector: 'app-tagtools',
  templateUrl: './tagtools.component.html',
  styleUrls: ['./tagtools.component.css']
})
export class TagtoolsComponent implements OnInit {




  constructor(public configSvc: ConfigService, public dialog: MatDialog, private authSvc: AuthenticationService) { }

  ngOnInit() {

  }

  openTagtool(type, name): void {

  //  console.log('setting cache clear')
  //  this.authSvc.cachedchoices = [];
    console.log('popup name is'+name)
    console.log('popup type is'+type)
var popupheight = '500px'
var popupwidth = '400px'
    if(type == 'deletecategory' || type == 'deletefile' || type == 'deletetageritem' || type == 'deletedesign')
    {popupheight = '200px'; popupwidth = '600px'}
    if(type == 'section'  || type == 'string' || type == 'number' || type == 'image' ){popupheight = '500px'; popupwidth = '400px'}
    if(type == 'choicelist' || type == 'treelist' || type== 'radiogroup') {popupheight = '500px'; popupwidth = '800px'} 
    if(type == 'boolean' ){popupheight = '500px'; popupwidth = '800px'}
    if(type == 'steppreview' || type == 'nodepreview'){popupheight = '800px'; popupwidth = '1200px'
    console.log('popup width is now '+popupwidth)
    }
    if(type=='find'){popupheight = '800px'; popupwidth = '1400px'}
    
    const dialogRef = this.dialog.open(TagPopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
  
    });
  }

 

}


@Component({
  selector: 'app-tagpopup',
  templateUrl: 'tagpopup.html',
  styleUrls: ['./tagtools.component.css']
})

export class TagPopup implements OnInit{
 public  searchtypechosen
clickchoiceset = false;
hltreepath =[];
  hlchoiceset = [];
  resultColumns = [ 'Icon', 'Name', 'Desc', 'AddedBy', 'AddedOn'];
  treeColumns = ['Name']
  haslabel = false;
  rowsize: number;
  hasdefault = false;
  defaulttoggle = false
 // secondtoggle = false
 // thirdtoggle = false
 // label = false;
  public itemselected = false;
  public activenodeready = false;
  public activestepperready = false;
  public itemsadded = [];
  findColumns = [ 'icontype', 'name', 'type', 'desc', 'ab', 'ao'];
  searchtypes = ['foldertags', 'kanban', 'flow','node', 'stepper' ]
  public searchtype = 'name'
  public searchtypesel = new FormControl('node');
  public newname: string
public formtypes = ['basic', 'stepper', 'tabbed']
  
  
  public previewArray: FormArray;
  public previewFG: FormGroup;
  public choicesetFG: FormGroup;
  public templateFG: FormGroup;
  stpcnt: string;
  public sorted = [];
  public reset: any[] = [{}];
  public trackchoicesetid = [];
  public choicesetselected = false;
  public nodeid: String;
  public stepid: String;
  public selectedRowsize = 'full';
  public selectedImagesize = 'large';
  sizes: Rowsize[] = [
    {value: 'full', viewValue: 'Full'},
    {value: 'half', viewValue: 'Half'},
    {value: 'third', viewValue: 'Third'}
  ];

  imagesizes: Imagesize[] = [
    {value: 'large', viewValue: 'Large'},
    {value: 'medium', viewValue: 'Medium'},
    {value: 'small', viewValue: 'Small'}
  ];

  constructor(private router: Router,private authSvc: AuthenticationService,
    public dialogRef: MatDialogRef<TagPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public configSvc: ConfigService, private fb: FormBuilder, public tagerSvc: TagerService) {}

    
  ngOnInit() {

    this.previewFG = new FormGroup({formArrayName: this.fb.array([])})
    console.log('building form  preview now');

    if(this.configSvc.activetype !='editstep'){
    this.buildstepitems()}

    if(this.configSvc.activetype =='editstep'){
      this.buildstepperitems()}
      
console.log('active type is '+this.configSvc.activetype)

    if(this.configSvc.activetype =='tabbed' || this.configSvc.activetype =='editstepperassign' 
     || this.configSvc.activetype =='smartfoldertab'  || this.configSvc.activetype =='nodetab'
     || this.configSvc.activetype =='edittabbedassign'  ){
      this.stepid =  this.configSvc.stepdetailsds[0]._id
      console.log('active stepid is'+this.stepid )
      this.nodeid =  this.configSvc.nodeds[0]._id}

      if(this.configSvc.activetype !='tabbed' && this.configSvc.activetype !='editstepperassign' 
     && this.configSvc.activetype !='smartfoldertab' && this.configSvc.activetype !='nodetab'
     && this.configSvc.activetype !='edittabbedassign' &&  this.configSvc.activetype !='batchitems'){
        this.nodeid =  this.configSvc.nodeds[0]._id}





    this.choicesetFG = this.fb.group({
      choicesetn: [''],
      choicesetdesc: [''],
       choicesetsearch:[''],
      
     });

     this.templateFG = 
      this.fb.group({
        name: '',
        type: '',
        indexname: '',
        templateready: false,
        assigned: false,
        assignedto: '',
        deadline: false,
        deadlineval: '',
        state: false,
        statetracker: false,
        statelabel: 'Completed',  
       

        
      
      });
}



  onNoClick(): void {
    this.dialogRef.close();
  }



    

      public createLabel(formitem, formitemlabel){
        console.log('creating string formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)
     
     
       
      
        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'label',  true, this.hasdefault, this.selectedRowsize, formitemlabel, false, this.hasdefault, '', 'label', this.stepid)
     

        this.dialogRef.close();
      }


      public createImage(formitem, formitemlabel, defaultvalue, hasdefault){
        console.log('creating image formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)
        console.log('hasdefault value is '+hasdefault)
        if (hasdefault==false) {defaultvalue = ''
        console.log('seeting default value to '+ defaultvalue)}
       
      
        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'image',  this.haslabel, this.hasdefault,this.selectedImagesize, formitemlabel, false, this.hasdefault, defaultvalue, 'photo_camera', this.stepid)
     

        this.dialogRef.close();
      }

 


      togglelabel(){
        this.haslabel = !this.haslabel;
      }
      toggledefault(){
        console.log('toggled the default')
   
        this.hasdefault = !this.hasdefault;
      }


      
      public findItem(type, name?){
        this.itemselected = false;this.configSvc.subitemsadded = []; 
   
        console.log('off to find the item based on this type '+type)

        // if(type == 'kanban'){ this.configSvc.findItem(type)}
        if(type == 'stepper') {
          type = 'wizard'
        }
      this.configSvc.findTemplate(name)
       
      }

      public chooseType(type){
        console.log('chooising type')
        this.searchtypechosen = type
      }

      public selectItem(item){
        this.onRecreate()
        this.activenodeready = false;this.activestepperready = false;
        this.itemselected = true;   this.configSvc.quickadd = false;
        console.log('is item selected? '+this.itemselected)
        this.configSvc.subitemsadded = []
this.configSvc.subitemsadded.push(item)
console.log('selected items '+JSON.stringify(this.configSvc.subitemsadded))
console.log('planning to add them to  '+JSON.stringify(this.configSvc.activeitemid))
this.newname = 'Copy of '+this.configSvc.subitemsadded[0].name;

console.log('type of item is '+this.configSvc.subitemsadded[0].type)
if(this.configSvc.subitemsadded[0].type == 'node' || this.configSvc.subitemsadded[0].type == 'foldertags'){
  this.configSvc.nodeds = this.configSvc.subitemsadded
  this.activenodeready = true;
  console.log('this is the status of activenodeready '+this.activenodeready);

}



if(this.configSvc.subitemsadded[0].type == 'wizard'){
  this.configSvc.nodeds =this.configSvc.subitemsadded
  this.activestepperready = true;
}
      
      }

      templateFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        console.log('filtering index on: '+filterValue)
        if (filterValue != '')
        {
          console.log('filter value befoore sending is : '+filterValue)
        this.configSvc.findTemplate(filterValue)
      console.log('templateFilter value is currently set to '+this.configSvc.finditemds)
    
      }
    }

    selectTemplate(template){
      console.log('this is the selected index '+JSON.stringify(template))
      this.configSvc.nodeds[0] = template
      this.configSvc.subitemsadded = []
      this.configSvc.subitemsadded.push(template)
      console.log('selected items '+JSON.stringify(this.configSvc.subitemsadded))
      console.log('planning to add them to  '+JSON.stringify(this.configSvc.activeitemid))
      this.onRecreate()
      console.log('template type is set to '+template.type)
      this.templateFG.patchValue({type: template.type })
      console.log('folderfg template type is set to '+this.templateFG.value.type)
      this.templateFG.patchValue({templateready: true })
     
            }

      public applyffKanban(itemid, kanbanid)
      {
        console.log('applying kanban to formfolder')
        console.log('itemid is '+itemid)
        console.log('kanbanid is '+kanbanid)
        this.configSvc.applynodeKanban(itemid, kanbanid, 'formfolder')
      }

      public addffItem(formfolderid, nodeid){
        console.log(' adding Item to formfolder')
        console.log('formfolderid is '+formfolderid)
        console.log('nodeid is '+nodeid)
        console.log('icontype is '+this.configSvc.subitemsadded[0].icontype);
        console.log('icontype is '+this.configSvc.subitemsadded[0].iconcolor);
        console.log('subitem type is '+this.configSvc.subitemsadded[0].type);
         var properties ;
         if (this.configSvc.subitemsadded[0].type == 'node')
         {properties =JSON.stringify(this.configSvc.newpropvalues)}

         if (this.configSvc.subitemsadded[0].type == 'wizard' || this.configSvc.subitemsadded[0].type == 'tabbed')
         {properties =JSON.stringify(this.configSvc.stepperprops)}

         console.log('properties on addffitem is '+properties);
         console.log('propertiy 0 on addffitem is '+this.configSvc.newpropvalues[0].itemvalue);

        this.configSvc.addffItem('batchitems', this.configSvc.subitemsadded[0].type,this.configSvc.subitemsadded[0].name, this.configSvc.newpropvalues[0].itemvalue,
          this.configSvc.subitemsadded[0].desc, formfolderid, nodeid, this.configSvc.subitemsadded[0].icontype, 
          this.configSvc.subitemsadded[0].iconcolor, properties)

          this.dialogRef.close()
      }
      
      public createString(formitem, formitemlabel, haslabel, defaultvalue, hasdefault){
        console.log('creating string formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)
        console.log('hasdefault value is '+hasdefault)
        if (hasdefault==false) {defaultvalue = ''
        console.log('seeting default value to '+ defaultvalue)
      
      }
      console.log('haslabel is set to  '+ haslabel)
   
      
        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'string', haslabel, this.hasdefault, this.selectedRowsize, formitemlabel, false, defaultvalue, 'text_format', this.stepid, '', '')
     

        this.dialogRef.close();
      }
    

      public createBoolean(formitem, formitemlabel, defaultvalue, hasdefault, label, itemvalue, textarea ){
        console.log('creating boolean formitem is'+formitem)
        console.log('creating boolean formitemlabel is'+formitemlabel)
        console.log('creating boolean defaultvalue is'+defaultvalue)
        console.log('creating boolean hasdefault is'+hasdefault)
        console.log('creating boolean label is'+label)
        console.log('creating boolean itemvalue is'+itemvalue)
        console.log('creating boolean textarea is'+textarea)

        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'boolean', this.haslabel, this.hasdefault,  this.selectedRowsize, formitemlabel, false, defaultvalue, 'toggle_off', this.stepid, itemvalue, textarea)
     

        this.dialogRef.close();
      }

      public createCheckbox(formitem, formitemlabel, defaultvalue){
        console.log('creating checkbox formitem is'+formitem)
        console.log('creating checkbox formitemlabel is'+formitemlabel)

        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'checkbox',  this.haslabel, this.hasdefault, this.selectedRowsize, formitemlabel, false, defaultvalue, 'check_box', this.stepid, '', '')
     

        this.dialogRef.close();
      }

      public createNumber(formitem, formitemlabel, defaultvalue){
        console.log('creating string formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)

        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'number', this.haslabel, this.hasdefault,   this.selectedRowsize, formitemlabel, false,defaultvalue, 'filter_1', this.stepid, '', '')
     

        this.dialogRef.close();
      }


      public createDate(type, formitem, formitemlabel, defaultvalue){
        console.log('creating string formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)
        console.log('creating string default date value is'+defaultvalue)
        console.log('testing date '+Date.parse(defaultvalue))
       //var datevalue = moment.unix(defaultvalue).format();
       var datevalue = Date.parse(defaultvalue)
        console.log('new datevalue'+datevalue)
        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, type, this.haslabel, this.hasdefault,  this.selectedRowsize, formitemlabel, false, this.hasdefault, 0, 'calendar_today', this.stepid)
     

        this.dialogRef.close();
      }

      public createText(formitem, formitemlabel, defaultvalue){
        console.log('creating text formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)
        console.log('hasdefaul;t is currently set to '+this.hasdefault)
        if (!this.hasdefault) {defaultvalue = ''
      console.log('this is the new defaultvalue '+defaultvalue)}
      console.log('this is the stepid '+this.stepid)
        this.configSvc.createTagitem( false, this.nodeid, this.configSvc.activetype, this.configSvc.nodeds[0].name, formitem, 'text', this.haslabel, this.hasdefault,  this.selectedRowsize, formitemlabel, false, defaultvalue, 'notes', this.stepid)
     

        this.dialogRef.close();
      }

      buildstepitems(){

        console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.nodeds));
              var choicesetids= []
              this.previewArray = this.previewFG.get('formArrayName') as FormArray;
             Object.keys(this.configSvc.nodeds).forEach((i) => {
  
             console.log('this is the stepitem property for step'+i);
             console.log('this is the formstep array'+this.configSvc.nodeds[i].stepitems);
             Object.keys(this.configSvc.nodeds[i].stepitems).forEach((i2) => {
              console.log('tthis is the item number in the step'+i2);
              this.stpcnt = i2;
              console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[i].stepitems[i2].formitem));
           
         // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
             // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);
    
                this.previewArray.push( this.fb.group({
                  formstep: new FormControl({ value: i}),
                   property: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitem, emitEvent:false}),
                   label: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].label, emitEvent:false}),
                   formitemlabel: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitemlabel, emitEvent:false}),
                  itemvalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].itemvalue, disabled: false  }),
                   formtype: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formtype}),
                 formchoices: new FormControl({ value:this.configSvc.nodeds[i].stepitems[i2].formchoices}),
                choicesetid: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicesetid}),
                choicesetname: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicesetname}),
                choicevalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicevalue, disabled: false }),
                textarea: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].textarea, disabled: false }),
                hasdefault: new FormControl(this.configSvc.nodeds[i].stepitems[i2].hasdefault),
                treepath: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].treepath, emitEvent:false}),
                
                  }))
    
                  console.log('preview array is now:'+this.previewArray);
                  
                  if (this.configSvc.nodeds[i].stepitems[i2].formchoices 
                    && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)===null){ 
                     
                     
                    console.log('this property has a list associated with it and its not in cache, lets go and get it')
                 // choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                  console.log('choicesetids list is now'+choicesetids);
                 this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'cache')

                  }

                  if (this.configSvc.nodeds[i].stepitems[i2].formchoices 
                    && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)!==null){ 
                    console.log('check to see if the cache variable has the localstorage cache')
                    console.log('this is the choicesetid: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                 // this.tagerSvc.getFormChoices(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                 var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
                 console.log('this is the localdata im pushing'+localdata);
                 if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[i].stepitems[i2].choicesetid)){
                  this.authSvc.choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                  console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid);
                  console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                  this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
                }
                
                
                }

                if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'treelist'){
                  this.hltreepath = this.configSvc.nodeds[i].stepitems[i2].treepath
                    console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
                   this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'treelist')
                   
                }

                

                  if (!this.configSvc.nodeds[i].stepitems[i2].formchoices){
                    console.log('this property does NOT have a list associated with it, doing nothing')
                  } })
 })}

 buildstepperitems(){

  console.log('this is the stepper node data we are workin with: '+JSON.stringify(this.configSvc.nodeds[0]));
       
        this.previewArray = this.previewFG.get('formArrayName') as FormArray;
       Object.keys(this.configSvc.nodeds[0].stepper).forEach((i) => {

       console.log('this is the stepitem property for step'+i);
       console.log('this is the stepper array'+JSON.stringify(this.configSvc.nodeds[0].stepper[i]));
       Object.keys(this.configSvc.nodeds[0].stepper[i].tags).forEach((i2) => {
        console.log('tthis is the item number in the step'+i2);
        this.stpcnt = i2;
        console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[0].stepper[i].tags[i2].formitem));
     
   // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
       // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);

          this.previewArray.push( this.fb.group({
            formstep: new FormControl({ value: i}),
             property: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitem, emitEvent:false}),
             label: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].label, emitEvent:false}),
             formitemlabel: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitemlabel, emitEvent:false}),
            itemvalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].itemvalue, disabled: false  }),
             formtype: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formtype}),
        //   formchoices: new FormControl({ value:this.configSvc.nodeds[0].stepper[i2].tags[i2].formchoices}),
          choicesetid: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid}),
          choicesetname: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetname}),
          choicevalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicevalue, disabled: false }),
          hasdefault: new FormControl(this.configSvc.nodeds[0].stepper[i].tags[i2].hasdefault),
          treepath: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].treepath, emitEvent:false}),
          
            }))

            console.log('preview array is now:'+this.previewArray);
            
            if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
              && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)===null){ 
               
               
              console.log('this property has a list associated with it and its not in cache, lets go and get it')
           // choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
          
           this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'cache')

            }

            if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
            && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)!==null){ 
              console.log('check to see if the cache variable has the localstorage cache')
              console.log('this is the choicesetid: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
           // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].stepper[i].tags[i2]..choicesetid)
           var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid))
           console.log('this is the localdata im pushing'+localdata);
           if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)){
            this.authSvc.choicesetids.push(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
            console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid);
            console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
            this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
          }
          
          
          }

          if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype == 'treelist'){
            this.hltreepath = this.configSvc.nodeds[0].stepper[i].tags[i2].treepath
            this.clickchoiceset = true;
              console.log('this is it he initial hl stepper tree path '+JSON.stringify( this.hltreepath));
             this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'treelist')
             
          }

          

            if (!this.configSvc.nodeds[0].stepper[i].tags[i2].formchoices){
              console.log('this property does NOT have a list associated with it, doing nothing')
            } })
})}


 public getChoiceset(selectedrow, type){
  console.log('type is set to '+type);
  this.hlchoiceset = [];
  this.hlchoiceset.push(selectedrow)
  this.hltreepath= [];
  this.hltreepath = this.hlchoiceset[0].path
  console.log('clicked on this choiceset '+JSON.stringify(this.hlchoiceset[0]));
  this.clickchoiceset = true; 
  if(selectedrow.haschild && type == 'choicelist' || type == 'radiogroup' || type == 'treelist'){
    console.log('this list has a child list going to get it')
  this.tagerSvc.getChoicesetbyid(selectedrow._id, type)}
  

    }

    createListitem(formitem, formitemlabel, formtype){

      console.log('list appyling formtyep: '+formtype);
      console.log('list appyling choiceset to this type: '+this.configSvc.activetype)

      if (formtype =='choicelist'){ var icontype = 'arrow_drop_down_circle'}
      if (formtype =='treelist'){ var icontype = 'account_tree'}
      if (formtype =='radiogroup'){ var icontype = 'radio_button_checked'}

    this.configSvc.createListitem( true, this.nodeid, this.configSvc.activetype, 
    this.configSvc.nodeds[0].name, formitem, formtype, this.haslabel,  this.selectedRowsize, formitemlabel, 
    this.hlchoiceset[0]._id, this.hlchoiceset[0].name, icontype, this.tagerSvc.choicesetsrch[0].name, this.stepid)
    this.dialogRef.close();
    }




    public sortPath(){
    
    
       //  console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
   this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
   //console.log('this is the sorted result: '+JSON.stringify(this.sorted))
     return this.sorted
      
    }
   
    toggleChange(){
     
    
     //  this.edittagFG.value.hasdefault = !this.edittagFG.value.hasdefault
      this.previewArray.value.hasdefault = !this.previewArray.value.hasdefault 
      console.log('changed current toggle value TO '+this.previewArray.value.hasdefault  )
      this.previewArray.markAsDirty();
    }  

    public choiceChange(){
   
      console.log('choices is changed');
      console.log('itemvalue is set to: '+this.previewFG.value.itemvalue);
    
     }

     public getChoices(choicesetid){
    //  console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
      
      var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
     // console.log('this is the filtered choicelist: '+filtered)
      return filtered;
       }
  
     public hlchoice(selectedrow){
      this.hlchoiceset = [];
      this.hlchoiceset.push(selectedrow)
      this.hltreepath= [];
      this.hltreepath = this.hlchoiceset[0].path
      console.log('this is itthe new selected tree path '+JSON.stringify( this.hltreepath));
     }

     public getChoicetree(choicesetid){
   
      this.getChip(choicesetid)
       this.sorted = [];
    
    
     
      this.clickchoiceset = true; 
       console.log('getting the choice tree for '+choicesetid)
       console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
     
       this.sortPath()
      if(this.authSvc.choicesetids.includes(choicesetid)){
        console.log('this choicetree has a list associated with it and its in cache')
    
      }
    
    
     if(!this.authSvc.choicesetids.includes(choicesetid)){
       console.log('ive never got this list before lets get it')
      this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
    
    }
    
    
    }
    public getChip(choicesetid){
   
     
      this.sorted = [];
      console.log('getting choicesetbyid with getChip type is path')
      this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
    }

    public changeBoolean(hasdefault, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      var bool = false;
      console.log('this is itthe formcontrol index: '+index);
      console.log('this is itthe current hasdefault value: '+hasdefault);
      console.log('this is new hasdefault value: '+!hasdefault)
      

    
     
      this.previewArray.markAsDirty();
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.previewArray.controls[index].patchValue({'hasdefault': !hasdefault})
    }

    public changeRadio(selectedchoice, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      console.log('this is itthe itemvalue: '+selectedchoice.name);
      console.log('this is itthe formcontrol index: '+index);
     
      
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.previewArray.controls[index].patchValue({'itemvalue': selectedchoice.name})
    }
/*
    public addStep(stepname, desc, type){
      this.configSvc.addStep(stepname, desc, type)
      this.dialogRef.close();
    }
    */

    public copy(itemid){
      console.log('copying: '+itemid )
      this.dialogRef.close();
      this.configSvc.copy = true;
    }

    public paste(itemid){
      console.log('pasting: '+itemid )
      this.dialogRef.close();
      this.configSvc.copy = false;

    }

    public deleteDesign(){

     
        console.log('deleting design item '+this.configSvc.activeitemid);
      this.configSvc.deleteDesign(this.configSvc.activeitemid, this.configSvc.nodeds[0].parentid)
      
        

      this.dialogRef.close();
    }
    

 
    public onRecreate(){
      this.reset[0] = {}
    }

    public createSubfolder(type, name, activeitemid){
console.log('folder name'+name)
console.log('create folder for activeitemid'+activeitemid)
this.configSvc.createSubfolder(type, name, activeitemid)
this.dialogRef.close()
    }

  
 




}


