import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ConfigService} from '../../services/config.service';
import { Router} from '@angular/router';
import { PrefsService } from 'src/app/services/prefs.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSize: number;
pageNo: number;
public currentPage = 0;
public totalSize = 0;
public newindex: number;

  constructor(public configSvc: ConfigService, private router: Router, public prefSvc: PrefsService) { }

  ngOnInit(): void {
 this.pageSize = 100;
    this.pageNo = 5;
    console.log('init foot based on type '+this.configSvc.activetype)
  }

  

  handlePage(event: any) {
    console.log('pageindex is '+event.pageIndex); //this is the index of the result set
    console.log('page size is '+event.pageSize); //this is the drop down number of items to show
   this.pageSize=event.pageSize
    console.log('this is the teamds cached list size'+this.configSvc.cachedteamds.length)
    console.log('this is the cachestartindex'+this.configSvc.cachestartindex)
    console.log('this is the cacheendindex'+this.configSvc.cacheendindex)
  //  console.log('page number is' +this.pageNo);
   // this.pageNo =  event.pageSize 
   console.log('previousPageIndex is '+event.previousPageIndex)
   console.log('pageIndex is '+event.pageIndex)
   
   console.log('click on previous button')
   this.currentPage = event.pageIndex;
   this.pageSize = event.pageSize;
   /*
    if (event.previousPageIndex > event.pageIndex) {
      console.log('click on previous button')
      
     
    } else {
      console.log('click on next button ')
 
    }
    */

    this.configSvc.cacheendindex = (this.currentPage + 1) * this.pageSize;
   this.configSvc.cachestartindex = this.currentPage * this.pageSize;
    console.log('cachestartindex?'+this.configSvc.cachestartindex);
    console.log('cacheendindex?'+this.configSvc.cacheendindex);

  //  this.configSvc.cacheendindex = this.configSvc.cachestartindex+this.pageSize
    console.log('cacheendindex?'+this.configSvc.cacheendindex);
    this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
     // The code that you want to execute on clicking on next and previous buttons will be written here.
 }

 handleFeed(event: any) {
  console.log('pageindex is '+event.pageIndex); //this is the index of the result set
  console.log('page size is '+event.pageSize); //this is the drop down number of items to show
 this.pageSize=event.pageSize
  console.log('this is the feed cached list size'+this.configSvc.cachedfeed.length)
  console.log('this is the cachestartindex'+this.configSvc.feedstartindex)
  console.log('this is the cacheendindex'+this.configSvc.feedendindex)
//  console.log('page number is' +this.pageNo);
 // this.pageNo =  event.pageSize 
 console.log('previousPageIndex is '+event.previousPageIndex)
 console.log('pageIndex is '+event.pageIndex)
 
 console.log('click on previous button')
 this.currentPage = event.pageIndex;
 this.pageSize = event.pageSize;
 /*
  if (event.previousPageIndex > event.pageIndex) {
    console.log('click on previous button')
    
   
  } else {
    console.log('click on next button ')

  }
  */

  this.configSvc.feedendindex = (this.currentPage + 1) * this.pageSize;
 this.configSvc.feedstartindex = this.currentPage * this.pageSize;
  console.log('feedstartindex?'+this.configSvc.feedstartindex);
  console.log('feedendindex?'+this.configSvc.feedendindex);

//  this.configSvc.cacheendindex = this.configSvc.cachestartindex+this.pageSize
  console.log('feedendindex?'+this.configSvc.feedendindex);
  this.configSvc.cachedfeed = this.configSvc.myfeed.slice(this.configSvc.feedstartindex, this.configSvc.feedendindex) 
   // The code that you want to execute on clicking on next and previous buttons will be written here.
}


nextinFeed(){
    
  console.log('get next in folder')
  console.log('current index item is '+this.configSvc.feeditem)

  console.log('current feed contents' +JSON.stringify(this.configSvc.myfeed))
  console.log('current feed contents itemk at zero' +JSON.stringify(this.configSvc.myfeed[0]))
  var newindex = this.configSvc.feeditem+1
  console.log('newindex item is '+newindex)
  console.log('next item to process' +JSON.stringify(this.configSvc.myfeed[newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.myfeed[newindex]);
  this.configSvc.feeditem = newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'tabbed' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')
  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

previnFeed(){
  
  console.log('get previous in feed')
  console.log('current index item is '+this.configSvc.feeditem)

  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.myfeed[0]))
  this.newindex = this.configSvc.feeditem-1
  console.log('newindex item is '+this.newindex)
  console.log('previous item to process' +JSON.stringify(this.configSvc.myfeed[this.newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.myfeed[this.newindex]);
  this.configSvc.feeditem = this.newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'tabbed' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')

  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

handleSearch(event: any) {
  console.log('pageindex is '+event.pageIndex); //this is the index of the result set
  console.log('page size is '+event.pageSize); //this is the drop down number of items to show
 this.pageSize=event.pageSize
  console.log('this is the feed cached list size'+this.configSvc.cachedfeed.length)
  console.log('this is the cachestartindex'+this.configSvc.feedstartindex)
  console.log('this is the cacheendindex'+this.configSvc.feedendindex)
//  console.log('page number is' +this.pageNo);
 // this.pageNo =  event.pageSize 
 console.log('previousPageIndex is '+event.previousPageIndex)
 console.log('pageIndex is '+event.pageIndex)
 
 console.log('click on previous button')
 this.currentPage = event.pageIndex;
 this.pageSize = event.pageSize;


  this.configSvc.feedendindex = (this.currentPage + 1) * this.pageSize;
 this.configSvc.feedstartindex = this.currentPage * this.pageSize;
  console.log('feedstartindex?'+this.configSvc.feedstartindex);
  console.log('feedendindex?'+this.configSvc.feedendindex);

  console.log('feedendindex?'+this.configSvc.feedendindex);
  this.configSvc.cachedfeed = this.configSvc.myfeed.slice(this.configSvc.feedstartindex, this.configSvc.feedendindex) 

}

public toggleCriteria(togglevalue){
  console.log('toggling criteria')
  this.configSvc.hc = !togglevalue
  console.log('hide crtieria is set to '+ this.configSvc.hc)
}

nextinFold(){
    
  console.log('get next in folder')
  console.log('current index item is '+this.configSvc.folderitem)

  console.log('current folder contents' +JSON.stringify(this.configSvc.teamds))
  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.teamds[0]))
  var newindex = this.configSvc.folderitem+1
  console.log('newindex item is '+newindex)
  console.log('next item to process' +JSON.stringify(this.configSvc.teamds[newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.teamds[newindex]);
  this.configSvc.folderitem = newindex

  this.configSvc.nodeready = false;
   var directtype = ''
   if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  // if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
   if(this.configSvc.nodeds[0].type == 'nodetab' ) (directtype = 'tabbeddirect')
   if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')


  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

previnFold(){
  
  console.log('get previous in folder')
  console.log('current index item is '+this.configSvc.folderitem)

  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.teamds[0]))
  this.newindex = this.configSvc.folderitem-1
  console.log('newindex item is '+this.newindex)
  console.log('previous item to process' +JSON.stringify(this.configSvc.teamds[this.newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.teamds[this.newindex]);
  this.configSvc.folderitem = this.newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
 // if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'nodetab' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')
  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type: directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}




 

}
