import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-lineitems',
  templateUrl: './lineitems.component.html',
  styleUrls: ['./lineitems.component.css']
})
export class LineitemsComponent implements OnInit {

  public lineitemdata = []
  public textcolumns = [  'litem'];
  public itemselected = false;
  public applyproperty = false;
  public indexselected: Number;
  public itemsadded = [];

  constructor(public configSvc: ConfigService) { }

  ngOnInit(): void {
    console.log('initing lineitems')
    this.lineitemdata = this.configSvc.nodeds[0].lineitems
    console.log('initing textdata '+JSON.stringify(this.lineitemdata))
  }

  public selectItem(item, index){
    
    this.indexselected = index
    console.log('is item selected? '+this.itemselected)
    this.itemsadded = []; 
this.itemsadded.push(item)
console.log('items selected in textraction is '+JSON.stringify(this.itemsadded));


}

}