

        <span [formGroup]="editqueueFG" class = "details-container">
           
            <i class="material-icons md-24" [ngStyle]="{'color': this.editqueueFG.value.iconcolor || 'black'}" > {{this.editqueueFG.value.icontype}}</i>
            <div>
             


                <mat-form-field appearance="fill" floatLabel="always" > 
                  <mat-label>queueName</mat-label>
                      <input formControlName="queuename" matInput value="{{this.editqueueFG.value.queuename}}">
                     </mat-form-field>
              
              </div>
            

              <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Queue Description</mat-label><input matInput [readonly]= "true" matInput value={{this.editqueueFG.value.queuedesc}}>
                </mat-form-field> </div>


              <div> <mat-form-field appearance="outline" floatLabel="always" >
                  <mat-label>QueueID</mat-label><input  matInput placeholder={{this.editqueueFG.value.queueid}}>
               </mat-form-field></div>

             
             
             
              </span>

     
 