<span class="scrollmenu">
<span *ngIf="this.configSvc.activetype == 'nodetab' || this.configSvc.activetype == 'smartfoldertab'">
    <span>
    <i class="material-icons icon-string" title=" Create Tab" 
    (click)="this.openTagtool('step', '')">directions</i>
    </span>

    <span>
        <i class="material-icons icon-string" title=" Apply Kanban" 
        (click)="this.openTagtool('kanban', '')">view_column</i>
        </span>

    <span>
        <i class="material-icons icon-string"  title="Delete Step"
        (click)="this.openTagtool('deletestep', this.configSvc.activename)">delete</i></span>
    </span>


    <span *ngIf="this.configSvc.activetype == 'editkanban'">
        <span>
        <i class="material-icons icon-string" title=" New Track" 
        (click)="this.openTagtool('track', 'Track name')">sync_alt</i></span>

        <span>
            <i class="material-icons icon-string"  title="Delete Track"
            (click)="this.openTagtool('deletetrack', this.configSvc.activename)">delete</i></span>


        </span>

        <span>
            <i class="material-icons icon-string" title=" Create Tab" 
            (click)="this.openTagtool('foldertag', '')">bookmark</i>
            </span>

            <span>
                <i class="material-icons icon-string" title=" Create Link Tab" 
                (click)="this.openTagtool('linktab', '')">link</i>
                </span>

       

</span>