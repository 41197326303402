<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">create_new_folder</mat-icon>
        <span *ngIf="this.configSvc.activetype == 'wizard'"> Wizard Details</span>
        <span *ngIf="this.configSvc.activetype == 'stepperfolder'"> Wizard Folder Details</span>
      </ng-template>
    <form [formGroup]="editstepperFG">
      <span  class="edittageritems">
    <mat-form-field>
      <mat-label>Wizard Name</mat-label>
      <input   formControlName="name" matInput value={{this.configSvc.nodeds[0].name}}>
    </mat-form-field></span>
    <span  class="edittageritems">
    <mat-form-field >
      <mat-label>Wizard Description</mat-label>
      <input  formControlName="desc" matInput value={{this.configSvc.nodeds[0].desc}}>
    </mat-form-field></span>
  
    <span>
      <app-iconcategories></app-iconcategories></span>
  
      <div>
        Choose a colour for Wizard</div>
      
  
          <span class ="colorbox">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker>
        </span>
  
     
          
      
         
  
  <div>
    <button [disabled]= "!this.configSvc.colorchanged && !this.editstepperFG.dirty" (click)="this.updateNode();"
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Update Stepper ">
          
         Update Stepper</button></div>
        </form>
      </mat-tab>

      <span *ngIf="this.configSvc.nodeds[0].type == 'editstepperassign' || this.configSvc.nodeds[0].type == 'edittabbedassign'">
        <mat-tab >
          <ng-template mat-tab-label>
            <i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >security</i>
           <span>FlowAssignment</span>
           
          </ng-template>
          <app-flowassignment></app-flowassignment>
        </mat-tab></span>

      <mat-tab>
        <ng-template mat-tab-label>
          <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >preview</i>
          Preview
        </ng-template>

        <app-activestepper></app-activestepper>

      </mat-tab>
  </mat-tab-group>
  