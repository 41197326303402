import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; 
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-resetpword',
  templateUrl: './resetpword.component.html',
  styleUrls: ['./resetpword.component.css']
})
export class ResetpwordComponent implements OnInit {

  public resetpwordform: FormGroup; 
  private username: string;
  private companyid: string;
  private resetid: string;
  private email: string;
  private resetAttempt = false;
  public error = '';
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, public configSvc: ConfigService) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.companyid = params['companyid'];
      this.resetid = params['resetid'];
      this.username= params['username'];
     

      console.log('activated companyid'+this.companyid);

    });

    this.resetpwordform = this.fb.group({     // {5}
      username: [this.username, Validators.required],
      companyid: [this.companyid, Validators.required],
      resetid: [this.resetid, Validators.required],
     
      password1: ['', Validators.required],
      password2: ['', Validators.required],
    
    });
    console.log('reset form initialized');


  
  }

  isFieldInvalid(field: string) { // {6}

    return (
      (!this.resetpwordform.get(field).valid && this.resetpwordform.get(field).touched) ||
      (this.resetpwordform.get(field).untouched && this.resetAttempt)

    );

  }

  public resetpword(){
    this.resetAttempt = true;
  
      console.log('resetting pword')
      this.configSvc.resetPword( this.resetpwordform.value.resetid,this.resetpwordform.value.username,
         this.resetpwordform.value.companyid, this.resetpwordform.value.password1)
      
    }
  }
  


