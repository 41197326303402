import { Component, ViewChild, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SidenavService } from './services/side-nav.service';
import { MatSidenav } from '@angular/material/sidenav';
import {ConfigService} from './services/config.service';
import {FlowService} from './services/flow.service';
import { Router, ActivatedRoute } from '@angular/router';
import {TagerService} from './services/tager.service';
import {DocactionsService} from './services/docactions.service';
import {UploadService} from './services/upload.service';
import {MatPaginator} from '@angular/material/paginator';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DoccommentsService } from './services/doccomments.service';
import {FormBuilder, FormControl, FormGroup, Validators, FormArray} from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  browseColumns = [ 'ToolbarColumn'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  pageSize: number;
pageNo: number;
public currentPage = 0;
public totalSize = 0;
public newindex: number;

  @ViewChild('sidenav', { static: true }) 
  public sidenav: MatSidenav;
  public sorted = [];
  mode = new FormControl('side');
  public lockFC = new FormControl();
  public appFG: FormGroup;
  toggleActive = false;


  constructor(private _fB: FormBuilder, public auth: AuthenticationService, public docActions: DocactionsService, 
    private uploadSvc: UploadService, public flowSvc: FlowService, public dialog: MatDialog,private doccomments: DoccommentsService, 
    private router: Router,private activatedRoute: ActivatedRoute,  public tagerSvc: TagerService,
    public configSvc: ConfigService, public sidenavSvc: SidenavService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

  }

  ngOnInit() {

    this.matIconRegistry.addSvgIcon('chat1',this.domSanitizer.bypassSecurityTrustResourceUrl('assets/chat1.svg'))
    this.matIconRegistry.addSvgIcon('chat2',this.domSanitizer.bypassSecurityTrustResourceUrl('assets/chat2.svg'))
    this.matIconRegistry.addSvgIcon('logo4',this.domSanitizer.bypassSecurityTrustResourceUrl('assets/logo4.svg'))
       
    this.pageSize = 100;
    this.pageNo = 5;
    console.log('init foot based on type '+this.configSvc.activetype)

    this.appFG = this._fB.group({
      lockFC: [this.configSvc.setlocked],
      converse: ''
    });

       console.log('init header for activetype '+this.configSvc.activetype)

       if(this.configSvc.activetype == 'queuedirect'){
         this.configSvc.nodeds = []
        var base = '/toolbard/teamqueue?'
        var itemid =  this.flowSvc.queueds[0]._id
        var name = this.flowSvc.queueds[0].name
        var teamid = this.flowSvc.queueds[0].teamid;
      this.configSvc.queuedirecturl = base+'itemid='+itemid+'&type=queuedirect&name='+name+'teamid='+teamid
       
      console.log('init header queuedirecturl'+this.configSvc.queuedirecturl)
    }
  
  }

  handlePage(event: any) {
    console.log('pageindex is '+event.pageIndex); //this is the index of the result set
    console.log('page size is '+event.pageSize); //this is the drop down number of items to show
   this.pageSize=event.pageSize
    console.log('this is the teamds cached list size'+this.configSvc.cachedteamds.length)
    console.log('this is the cachestartindex'+this.configSvc.cachestartindex)
    console.log('this is the cacheendindex'+this.configSvc.cacheendindex)
  //  console.log('page number is' +this.pageNo);
   // this.pageNo =  event.pageSize 
   console.log('previousPageIndex is '+event.previousPageIndex)
   console.log('pageIndex is '+event.pageIndex)
   
   console.log('click on previous button')
   this.currentPage = event.pageIndex;
   this.pageSize = event.pageSize;
   /*
    if (event.previousPageIndex > event.pageIndex) {
      console.log('click on previous button')
      
     
    } else {
      console.log('click on next button ')
 
    }
    */

    this.configSvc.cacheendindex = (this.currentPage + 1) * this.pageSize;
   this.configSvc.cachestartindex = this.currentPage * this.pageSize;
    console.log('cachestartindex?'+this.configSvc.cachestartindex);
    console.log('cacheendindex?'+this.configSvc.cacheendindex);

  //  this.configSvc.cacheendindex = this.configSvc.cachestartindex+this.pageSize
    console.log('cacheendindex?'+this.configSvc.cacheendindex);
    this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
     // The code that you want to execute on clicking on next and previous buttons will be written here.
 }

 handleFeed(event: any) {
  console.log('pageindex is '+event.pageIndex); //this is the index of the result set
  console.log('page size is '+event.pageSize); //this is the drop down number of items to show
 this.pageSize=event.pageSize
  console.log('this is the feed cached list size'+this.configSvc.cachedfeed.length)
  console.log('this is the cachestartindex'+this.configSvc.feedstartindex)
  console.log('this is the cacheendindex'+this.configSvc.feedendindex)
//  console.log('page number is' +this.pageNo);
 // this.pageNo =  event.pageSize 
 console.log('previousPageIndex is '+event.previousPageIndex)
 console.log('pageIndex is '+event.pageIndex)
 
 console.log('click on previous button')
 this.currentPage = event.pageIndex;
 this.pageSize = event.pageSize;
 /*
  if (event.previousPageIndex > event.pageIndex) {
    console.log('click on previous button')
    
   
  } else {
    console.log('click on next button ')

  }
  */

  this.configSvc.feedendindex = (this.currentPage + 1) * this.pageSize;
 this.configSvc.feedstartindex = this.currentPage * this.pageSize;
  console.log('feedstartindex?'+this.configSvc.feedstartindex);
  console.log('feedendindex?'+this.configSvc.feedendindex);

//  this.configSvc.cacheendindex = this.configSvc.cachestartindex+this.pageSize
  console.log('feedendindex?'+this.configSvc.feedendindex);
  this.configSvc.cachedfeed = this.configSvc.myfeed.slice(this.configSvc.feedstartindex, this.configSvc.feedendindex) 
   // The code that you want to execute on clicking on next and previous buttons will be written here.
}


nextinFeed(){
    
  console.log('get next in folder')
  console.log('current index item is '+this.configSvc.feeditem)

  console.log('current feed contents' +JSON.stringify(this.configSvc.myfeed))
  console.log('current feed contents itemk at zero' +JSON.stringify(this.configSvc.myfeed[0]))
  var newindex = this.configSvc.feeditem+1
  console.log('newindex item is '+newindex)
  console.log('next item to process' +JSON.stringify(this.configSvc.myfeed[newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.myfeed[newindex]);
  this.configSvc.feeditem = newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  if(this.configSvc.nodeds[0].type == 'chatgpt' ) (directtype = 'chatgptdirect')
  if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'tabbed' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')
  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

previnFeed(){
  
  console.log('get previous in feed')
  console.log('current index item is '+this.configSvc.feeditem)

  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.myfeed[0]))
  this.newindex = this.configSvc.feeditem-1
  console.log('newindex item is '+this.newindex)
  console.log('previous item to process' +JSON.stringify(this.configSvc.myfeed[this.newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.myfeed[this.newindex]);
  this.configSvc.feeditem = this.newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'chatgpt' ) (directtype = 'chatgptdirect')
  if(this.configSvc.nodeds[0].type == 'tabbed' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')

  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

handleSearch(event: any) {
  console.log('pageindex is '+event.pageIndex); //this is the index of the result set
  console.log('page size is '+event.pageSize); //this is the drop down number of items to show
 this.pageSize=event.pageSize
  console.log('this is the feed cached list size'+this.configSvc.cachedfeed.length)
  console.log('this is the cachestartindex'+this.configSvc.feedstartindex)
  console.log('this is the cacheendindex'+this.configSvc.feedendindex)
//  console.log('page number is' +this.pageNo);
 // this.pageNo =  event.pageSize 
 console.log('previousPageIndex is '+event.previousPageIndex)
 console.log('pageIndex is '+event.pageIndex)
 
 console.log('click on previous button')
 this.currentPage = event.pageIndex;
 this.pageSize = event.pageSize;


  this.configSvc.feedendindex = (this.currentPage + 1) * this.pageSize;
 this.configSvc.feedstartindex = this.currentPage * this.pageSize;
  console.log('feedstartindex?'+this.configSvc.feedstartindex);
  console.log('feedendindex?'+this.configSvc.feedendindex);

  console.log('feedendindex?'+this.configSvc.feedendindex);
  this.configSvc.cachedfeed = this.configSvc.myfeed.slice(this.configSvc.feedstartindex, this.configSvc.feedendindex) 

}

public toggleCriteria(togglevalue){
  console.log('toggling criteria')
  this.configSvc.hc = !togglevalue
  console.log('hide crtieria is set to '+ this.configSvc.hc)
}

nextinFold(){
    
  console.log('get next in folder')
  console.log('current index item is '+this.configSvc.folderitem)

  console.log('current folder contents' +JSON.stringify(this.configSvc.teamds))
  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.teamds[0]))
  var newindex = this.configSvc.folderitem+1
  console.log('newindex item is '+newindex)
  console.log('next item to process' +JSON.stringify(this.configSvc.teamds[newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.teamds[newindex]);
  this.configSvc.folderitem = newindex

  this.configSvc.nodeready = false;
   var directtype = ''
   if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
  // if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
  if(this.configSvc.nodeds[0].type == 'chatgpt' ) (directtype = 'chatgptdirect')
   if(this.configSvc.nodeds[0].type == 'nodetab' ) (directtype = 'tabbeddirect')
   if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')


  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type:directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

previnFold(){
  
  console.log('get previous in folder')
  console.log('current index item is '+this.configSvc.folderitem)

  console.log('current folder contents itemk at zero' +JSON.stringify(this.configSvc.teamds[0]))
  this.newindex = this.configSvc.folderitem-1
  console.log('newindex item is '+this.newindex)
  console.log('previous item to process' +JSON.stringify(this.configSvc.teamds[this.newindex]))
  this.configSvc.nodeds = [];
  this.configSvc.nodeds.push(this.configSvc.teamds[this.newindex]);
  this.configSvc.folderitem = this.newindex
  this.configSvc.nodeready = false;
  var directtype = ''
  if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
 // if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
 if(this.configSvc.nodeds[0].type == 'chatgpt' ) (directtype = 'chatgptdirect')
  if(this.configSvc.nodeds[0].type == 'nodetab' ) (directtype = 'tabbeddirect')
  if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')
  this.router.navigate(['/toolbard/home'], { queryParams: 
    //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
    {itemid:this.configSvc.nodeds[0]._id, type: directtype, name: this.configSvc.nodeds[0].name, 
    level:this.configSvc.nodeds[0].level, teamid: this.configSvc.nodeds[0].teamid }

     });
     
     
     
}

  
  

   public elementattach() {
    return this.docActions.currentattach.Filename;
   }

   public docadd() {
    return this.docActions.showprogress;
  }

  public showdetails() {
    return this.docActions.showdetails;
  }

  public showattdetails() {
    return this.docActions.showattdetails;
  }

  
  setActive(item: string){
    console.log('this is the item being processed'+JSON.stringify(item));
    if (item == 'addteam'){this.configSvc.activetype = 'addteam'; console.log('add team active now');} 
    if (item == 'addfolder'){this.configSvc.activetype = 'addfolder';console.log('addfolder active now');} 
    if (item == 'addfile'){this.configSvc.activetype = 'addfile';console.log('add file tool active now');}  
  
  
    
    
}



getPaths(){
  console.log('getting paths now')
 console.log('this is the current path array'+JSON.stringify(this.configSvc.pathds));
 return this.configSvc.pathds;
}

public pathSort(){
  this.sorted = [];
 // console.log('set sorted to blank')
// console.log('current path is set to'+JSON.stringify(this.configSvc.folderpath))
this.sorted = this.configSvc.folderpath.sort((a, b) => Number(a.level) - Number(b.level));
// console.log('this is the sorted result: '+JSON.stringify(this.sorted))
  return this.sorted

}

public clearChoicesets(){
  this.configSvc.pathds=[];
  this.configSvc.activesidepanel = 'browser';
  this.configSvc.toolmenu='choicetools';
  this.configSvc.activetype = 'team' 
  this.configSvc.folderpath = [];
}

setActivetool(type){
  console.log('current type clicked '+type);
  // this.configSvc.buildtool = false; 
  this.configSvc.typeenabled = true;
  if(type == 'fold' || type == 'file'){
    this.configSvc.activetool = 'editfold'
  this.configSvc.activetype = 'fold'}

  /*
  if(this.configSvc.folderdetailsds.fot){
    console.log('this folder has got a task ');
    this.taskSvc.filetasksds =null;
                  this.taskSvc.getFileTasks(this.configSvc.activeitemid)
  }
  */
  if(type == 'team'){this.configSvc.activetool = 'editteam'}
 // if(type == 'file'){this.configSvc.activetool = 'file'}
  
}

public deselect(){
  console.log('deseelcting now')
  this.configSvc.deselect(this.configSvc.activetype)
}

public sortnodedirectPath(){

this.sorted = this.configSvc.nodeds[0].path.sort((a, b) => Number(a.level) - Number(b.level));
//console.log('this is the sorted result: '+JSON.stringify(this.sorted))
  return this.sorted
}

public clickChip(){

 // this.configSvc.nodetagsds = [];this.configSvc.nodeteamds=[];
 // this.configSvc.nodeds = [];this.configSvc.hltag = [];this.configSvc.hlstep = [];
  
  this.configSvc.toolmenu= 'designtools'
  if(this.configSvc.activetype == 'editstep'){
    this.configSvc.activetype = 'wizard'
    this.configSvc.stepclicked = true;
    
  this.configSvc.nodeteamds = this.configSvc.nodeds[0].stepper
  console.log('nodeteamds is now: '+JSON.stringify(this.configSvc.nodeteamds[0]))
  this.configSvc.stepdetailsds = [];
}
if(this.configSvc.activetype == 'node'){
  console.log('activetype is set to node' )
this.configSvc.editnode ='editnode'; this.configSvc.toolmenu = 'tagtools'
}

else{
 this.configSvc.stepclicked = false;

}

  
}

public clickFold(){
  console.log('active type is currently '+this.configSvc.activetype)
  
  this.configSvc.hlitem=[]; this.configSvc.nodeds=[]
  
}

/*
public toggleCriteria(togglevalue){
  console.log('toggling criteria')
  this.configSvc.hc = !togglevalue
  console.log('hide crtieria is set to '+ this.configSvc.hc)
}
*/

public deselectQueue(){
  console.log('deselecting queue')
  this.flowSvc.deselectQueue()
}

getToolbars(type, itemid, teamid, toolbar?){
   
  console.log('the parenttoolbar is: '+JSON.stringify(this.configSvc.parenttoolbar))
this.configSvc.activetoolbar = toolbar
  // this.configSvc.activetype= type; console.log('active type is set to'+type)
  this.configSvc.toolmenu = 'toolbartools'; console.log('active toolmenu is set to toolbartools')
  this.configSvc.favtools = false
  console.log('toolbard favtools is set to '+this.configSvc.favtools)
  console.log('toolbard categories are '+JSON.stringify(this.configSvc.toolbards))
  this.configSvc.getToolbars(type, itemid, teamid); 
  
  this.sidenav.toggle()
}

getParenttoolbar(){
  console.log('the parenttoolbar is: '+JSON.stringify(this.configSvc.parenttoolbar[0]))
  this.configSvc.getToolbars(this.configSvc.parenttoolbar[0].type, 
    this.configSvc.parenttoolbar[0].itemid,this.configSvc.parenttoolbar[0].teamid);
}

getrootToolbars(){
  


 // this.sidenavSvc.toggle('navigation')
  this.configSvc.roottoolbar = true;
  console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
//  this.sidenavSvc.activesidenav = 'toolbars'
 // console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
  this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
  console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
  this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
  
}

public getfavTools(){
  console.log('setting the fav tools');
  this.configSvc.toolmenu = 'favtoolset'; 
  console.log('active toolmenu is set to favtoolset')
  this.configSvc.favtools = true;
  this.configSvc.activeitemid = this.configSvc.currentfolder
  this.configSvc.activename = this.configSvc.currentfoldername
  this.configSvc.hlitem = []; this.configSvc.nodeready = false; this.configSvc.nodeds = [];
 this.configSvc.getfavToolbars()
 this.sidenav.toggle()


}

public toolbarFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  console.log('filtering toolbar on: '+filterValue)
  this.configSvc.toolbarFilter(filterValue)

}

public askChatgpt(process){
  console.log('askimng chatgpt '+this.appFG.value.converse)

  this.doccomments.addcomment(process, false, this.configSvc.activetype, this.appFG.value.converse, 
    'forum', 'purple',  false, 
    '', true);

    this.appFG.patchValue({converse: ''})
    this.appFG.markAsPristine
       
}




  }
