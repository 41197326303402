import {  Component, OnInit, ViewChild, Input } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FlowService} from '../../services/flow.service';
import {SidenavService} from '../../services/sidenav.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {DocactionsService} from '.././../services/docactions.service';
 // import { UploadEvent} from 'ngx-file-drop';
import {UploadService} from '../../services/upload.service';
import { AuthenticationService} from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';


import { DoccommentsService } from '../../services/doccomments.service';
import {TagService} from  '../../services/tag.service';
import {TagerService} from  '../../services/tager.service';


@Component({
  selector: 'app-tagteam',
  templateUrl: './tagteam.component.html',
  styleUrls: ['./tagteam.component.css'],
  
})
export class TagteamComponent implements OnInit{
  // @Input() activetool: string;
  public math = Math;
  public clickCount = 0;
  public currentfolder: string;
  public currentfoldername: string;
  public percenttime: number;
  public lockFC = new FormControl();
  browseColumns = [ 'MimeColumn', 'ItemColumn'];

 // bconfig = true;
  //stproject: String;

  //dbcategory: String;
  //dbsubcategory: String;
  //dbsubtasklist: String;
  //stcategory: String;
  //stsubcategory: String;
  //projectck = false;
 // projectaction = false;
  //clicksubcategory = false;
 // valuelistcard = false;
 // valuelistaction = false;
 // securitycard = false;
  //securityaction = false;
  //fieldck = false;
  //fieldcard = false;
  //fieldaction = false;
 // projecttxtvalue: String;
 // public configitem: String;
 // public txtfieldfc = new FormControl();
 // public txtsectagfc = new FormControl();
 // exceladd = false;

  //color = 'accent';
  //secured = false;

 // editProj = true;
 // systemfields = false;

/*

  bulkaddvaluelist = false;
  manualproj = true;
  subcategory = false;
  secsetting: String;
  vlsetting: String;
  projsetting = 'New Project';
  baprojsetting = 'Manually Add Projects';

  isLinear = false;
*/
  //step1FG: FormGroup;
  //step3FG: FormGroup;
  //step4FG: FormGroup;
 // folderFG: FormGroup;
 // editfolderFG: FormGroup;
 // projectstep1: FormGroup;
 // @ViewChild('stepper') stepper: MatStepper;
  //selectedValue = [];
 // selectedDatatype: string;

  //datatypeControl = new FormControl('', [Validators.required]);

  //datatypeform: FormGroup;

 // bsubcat = false;
 // categoryselected = false;
 // subcatselected = false;
 // taskselected = false;
  type: string;
  currentURL: string;
  public reset: any[] = [{}];
  public dateTime;


  constructor(public configSvc: ConfigService, private uploadSvc: UploadService,  public sidenavSvc: SidenavService, 
     private authService: AuthenticationService,  private docActions: DocactionsService,
    private router: Router, private activatedRoute: ActivatedRoute,private commentSvc: DoccommentsService,
    public tagSvc: TagService, public tagerSvc: TagerService, public flowSvc: FlowService) { }

ngOnInit() {

 // console.log('setting active tool to node tools')
  //this.configSvc.toolmenu='general'
  this.configSvc.mode = 'browse'
  this.configSvc.folderpath = [];
  this.configSvc.teamtype = 'folder'
  this.configSvc.toolmenu='general'
  this.dateTime = new Date().toISOString()
  
  

 this.activatedRoute.queryParams.subscribe(params => {
  this.configSvc.activeitemid = params['itemid'];
  this.configSvc.currentfolder = params['itemid'];
  this.configSvc.activetype = params['type'];
  this.configSvc.activename = params['name'];
  this.configSvc.currentfoldername = params['name'];
  this.configSvc.activelevel = params['level'];
  this.configSvc.activeteamid = params['teamid'];
  this.configSvc.activeurl = "http://localhost:4200"+this.router.url;
  

  console.log('activated itemid'+this.configSvc.activeitemid);
  console.log('activated type'+this.configSvc.activetype);
  if(this.configSvc.activetype == 'nodedirect' || this.configSvc.activetype == 'tabbeddirect' || this.configSvc.activetype == 'chatgptdirect')
  {this.configSvc.toolmenu='nodetools' ;
  this.configSvc.hidebrowser = true
}
  if(this.configSvc.activetype == 'editnode'){this.configSvc.toolmenu='tagtools'}
  if(this.configSvc.activetype == 'fold' ){
  //  this.configSvc.toolmenu='general'
  }
  console.log('activated name'+this.configSvc.activename);
  console.log('activated level'+this.configSvc.activelevel);
  console.log('activated teamid'+this.configSvc.activeteamid);
  console.log('folderready status'+this.configSvc.folderready);
  console.log('sending this url to the auth service'+this.currentURL);
  console.log('sending this url to the auth service'+this.router.url);
  console.log('activated type'+this.configSvc.activetype);
  this.configSvc.quickadd = false
  // this.currentURL = this.router.url;

  this.authService.tagteam(this.router.url).subscribe(browsedata => {
    if(this.configSvc.activetype!='node' && this.configSvc.activetype!='imagedirect' && 
    this.configSvc.activetype!='nodedirect' && this.configSvc.activetype!='nodefeed' 
    && this.configSvc.activetype!='tabbeddirect' && this.configSvc.activetype!='chatgptdirect'
    && this.configSvc.activetype!='filedirect' &&  this.configSvc.activetype!='filedirect'){ 
      this.configSvc.hidebrowser = false;
      this.configSvc.teamds = browsedata
      this.configSvc.fldritemcnt = this.configSvc.teamds.length
      console.log('this is the number of items returned in the folder '+this.configSvc.fldritemcnt)
      console.log('activated type after resyults returned'+this.configSvc.activetype);
      console.log('folderready value'+this.configSvc.folderready);
     // this.configSvc.cachestartindex = 10
  
      this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
    }
   
    if(this.configSvc.activetype=='node' )
    {   
      this.configSvc.tageritemready = true
      this.configSvc.nodeds = browsedata
      console.log('off to get the direct path for'+this.configSvc.activeitemid)
    //  this.configSvc.getpath(this.configSvc.nodeds[0].parentid, this.configSvc.activeteamid) 
     }

      if(this.configSvc.activetype=='nodedirect' || this.configSvc.activetype=='nodefeed' ||this.configSvc.activetype=='chatgptdirect' ||
      this.configSvc.activetype=='filedirect' || this.configSvc.activetype=='filedirect' ||
       this.configSvc.activetype=='tabbeddirect' || 
       this.configSvc.activetype=='imagedirect')
      {   
       
        this.configSvc.nodeds = browsedata
        console.log('othis is tjhe nodeds data are init'+JSON.stringify(this.configSvc.nodeds))
        this.configSvc.hidebrowser = true;
        this.configSvc.tageritemready = true
        this.configSvc.nodeready = true;
        this.configSvc.fileready = true
        console.log('off to get the direct path for'+this.configSvc.activeitemid)
       // this.configSvc.getpath(this.configSvc.nodeds[0].parentid, this.configSvc.activeteamid) 
       }

     
   
 
    
   }, (err) => {
    console.error(err);
   });
  

 
  

   if (this.activatedRoute.snapshot.queryParamMap.has('itemid')){
   // this.configSvc.getpath(this.configSvc.activeitemid, this.configSvc.activeteamid)  
    console.log('this has  an itemid');
     console.log('this is the paramap '+JSON.stringify(this.activatedRoute.snapshot.queryParamMap.get));
    if(this.configSvc.activetype=='fold'  || 
    this.configSvc.activetype=='smartfolder' || this.configSvc.activetype=='smartfoldertab'){
      console.log('active type is '+this.configSvc.activetype);
      console.log('active itemid is '+this.configSvc.activeitemid);
      console.log('active team is '+this.configSvc.activeteamid);
    //  this.configSvc.getTeamDetails(this.configSvc.activeteamid, this.configSvc.activetype)
    //  console.log('off to get the team details');
    this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false)
   console.log('off to get the details');
   this.configSvc.gettingfolder = true;
   console.log('getting folder set to true in tagteam init');
  //  this.configSvc.getTagtemplate(this.configSvc.activeitemid, this.configSvc.activetype)
  //  console.log('getting template details via tagteam component as this is a fold');
  //  this.configSvc.getTags(this.configSvc.activetype, this.configSvc.activeitemid)
 //   this.configSvc.activetype ='fold'; 
     this.configSvc.typeenabled = true;
    

    
  
  }

  if(this.configSvc.activetype=='team'){
   this.configSvc.getTeamDetails(this.configSvc.activeteamid, 'editteam')
   console.log('off to get the team details from tagteam');
   this.configSvc.gettingteam = true;
 //  this.configSvc.getTagtemplate(this.configSvc.activeteamid, this.configSvc.activetype)
 //  console.log('getting tag template details via tagteam component as this is a team');
 //  this.configSvc.getTags(this.configSvc.activetype, this.configSvc.activeteamid)
  }

  if(this.configSvc.activetype=='editteam'){
    this.configSvc.getTeamDetails(this.configSvc.teamdetailsds._id, this.configSvc.activetype)
    console.log('off to get the team details from tagteam');
 //   this.configSvc.getTagtemplate(this.configSvc.teamdetailsds._id, this.configSvc.activetype)
  //  console.log('getting tag  template details via tagteam component as this is a editteam');
 //   this.configSvc.getTags(this.configSvc.activetype, this.configSvc.teamdetailsds._id)
   }

   if(this.configSvc.activetype=='file'){
    console.log('getting tagtemplate details via tagteam component as this is a file')
  //  this.configSvc.getTagtemplate(this.configSvc.currentrow._id, this.configSvc.activetype)
    console.log('getting tag details from the file');
    this.configSvc.getTags(this.configSvc.activetype, this.configSvc.currentrow._id)
   }

   if(this.configSvc.activetype=='tageritem'){
     this.configSvc.buildtool = true
    console.log('getting direct tager item details ')
  //  this.configSvc.getTagtemplate(this.configSvc.currentrow._id, this.configSvc.activetype)
    console.log('getting tag details from the tageritem');
    this.configSvc.getTags(this.configSvc.activetype, this.configSvc.activeitemid)
   }

   
 
   
  }

  if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
this.configSvc.toolmenu = 'teamtools'
console.log('tool menu is set to '+this.configSvc.toolmenu )
this.sidenavSvc.activesidenav = ''; 
this.configSvc.fullpage = false
this.configSvc.activetype = 'base'
console.log('tool menu is set to '+this.configSvc.toolmenu )
this.configSvc.folderpath = [];

  }

  
  
   
});



if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){

  this.configSvc.activetool = '';

}



      
   

  /*
  this.step1FG = this._formBuilder.group({
    txtprojectfc: [''],
    txtfieldfc: ['']
  });


  this.step4FG = this._formBuilder.group({
    fourthCtrl: ['']
  });

  this.folderFG = this._formBuilder.group({
    level1Folder: ['']
  });

  this.editfolderFG = this._formBuilder.group({
    editFolderfc: ['']
  });


  


     this.projectstep1 = this._formBuilder.group({
      projectType: ['', Validators.required]
    });
*/
   

console.log('this is the current time '+this.dateTime)


}


   

/*
   getTeam2 (): void {
    console.log('executing getteam2 process now');
    console.log('this is a is the current url map: '+JSON.stringify(this.activatedRoute.snapshot.paramMap));
    if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
   // var type = this.route.snapshot.paramMap.get('type');
    console.log('this is a root team call: '+JSON.stringify(this.activatedRoute.snapshot.paramMap));

   // var teamurl = 'tager/tagteam/'
    this.authService.rootteam(this.currentURL).subscribe(browsedata => {
 this.configSvc.teamds = browsedata;
}, (err) => {
 console.error(err);
});
    }

    // if (this.route.snapshot.paramMap.has('type') && !this.route.snapshot.paramMap.has('name')){
      if (this.activatedRoute.snapshot.queryParamMap.has('itemid')){

   // const name = this.route.snapshot.paramMap.get('name');
   // const type = this.route.snapshot.paramMap.get('type');
    console.log('this NOT a root team call: '+JSON.stringify(this.activatedRoute.snapshot.paramMap));
  //  console.log('this is the specific team call name: '+this.route.snapshot.paramMap.get('name'));
  //   var teamurl = 'tager/tagteam/'+name;
//     console.log('team url is'+teamurl);
  //   var teamurl2 = this.route.snapshot.data
  //   console.log('team2 url is'+JSON.stringify(teamurl2));
    console.log('cxurrent url: '+this.currentURL)
  //  console.log('cxurrent type: '+this.type)
    this.authService.tagteam(this.currentURL).subscribe(browsedata => {
 this.configSvc.teamds = browsedata;
}, (err) => {
 console.error(err);
});
}
}
*/



  

   getTeam(selectedrow){
  
    console.log('selected row is '+selectedrow);
    this.configSvc.activetype='team'; 
    this.configSvc.currentteam=selectedrow;
    console.log('this is the current team we are clicking on '+JSON.stringify(this.configSvc.currentteam));
  }


scroll(el: HTMLElement) {
  console.log('sorting out the scroll view now')
 console.log('this is the width'+el.scrollWidth);
 console.log('this is the x position'+el.scrollWidth);
 el.scrollTo(el.scrollWidth, 0 )
 console.log('this is the width after scrolling'+el.scrollWidth);
}


itemtoggle(){
  this.configSvc.itemlocked = !this.configSvc.itemlocked;
  if(this.configSvc.itemlocked)
 { this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false)}
}

clickFile(selectedFile, index){
  this.configSvc.nodeready = false;
  this.configSvc.clicksource = 'browse';
  this.configSvc.activetype = 'file'; 
  this.configSvc.gettingfile = false; 
  this.configSvc.typeenabled = true;
  this.configSvc.buildtool = false; 
  this.configSvc.activetool = ''
  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {

  console.log('current file row is'+JSON.stringify(selectedFile))
  console.log('current file index is'+index)
  this.configSvc.itemindex= index
  this.configSvc.activetype = 'file'; 

  this.configSvc.toolmenu='filetools'; this.configSvc.fullpage = false;
  
  this.configSvc.nodeds[0] = selectedFile;
  console.log('current row selected is: '+JSON.stringify(this.configSvc.currentrow));
  
 this.configSvc.hlitem = [];

  this.configSvc.activename = this.configSvc.nodeds[0].name;
  this.configSvc.activeitemid = this.configSvc.nodeds[0]._id;
  
 
    this.configSvc.nodeready = true;
 

 // this.onRecreate()

//  console.log('node is now set to'+JSON.stringify(this.configSvc.nodeds[0]))
 
}

else if (this.clickCount === 2) {
  console.log('this is a double click')
  this.docActions.downloadFile();



}
this.clickCount = 0;
}, 300)
}



setTeamtool(){
  if (this.configSvc.activetool == 'kanban'){
    this.configSvc.fullpage = false
  }

  else{
  this.configSvc.activetool='editteam'}
}

getActiveform(element){
  this.tagerSvc.getActiveform(element._id, true);

  if(element.flow){this.tagerSvc.getActivetager(element.name);}
  if(element.type== 'form'){ this.configSvc.activetype = 'tageritem'}; 
   if(element.type== 'tagerseq'){ this.configSvc.activetype = 'editform'}
 
  this.configSvc.currentform=element; 
  this.configSvc.getForm(element); 
  this.configSvc.activetool = 'activeform'; 
  this.configSvc.fullpage = false;
  this.configSvc.typeenabled = true;this.configSvc.buildtool = false;


}

getActiveflow(element){
  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {
console.log('getting the activeflowid: '+element._id)
this.configSvc.nodeds = [];this.configSvc.hlitem = [];
this.configSvc.hlitem.push(element);
this.configSvc.activetype = 'activeflow'; 
this.configSvc.activeitemid = element._id
console.log('setting activeitem id while getting activeflow: '+this.configSvc.activeitemid)
this.flowSvc.getActiveflow(element._id)
this.configSvc.currentrow = element
console.log('current element: '+JSON.stringify(this.configSvc.currentrow))
this.onRecreate()

      }
      else if (this.clickCount === 2) {
        console.log('this is a double click of an activeflow need to go to inbox')
        console.log('this is the image item info'+JSON.stringify(this.configSvc.nodeds[0]))
        var base = '/toolbard/teamqueue?'
        var itemid = this.configSvc.nodeds[0]._id
        var teamid = this.configSvc.activeteamid;
        var dynamicurl = base+'itemid='+itemid+'&type=queuedirect&teamid='+teamid
          this.router.navigateByUrl(dynamicurl);
      
      
      
      }
      this.clickCount = 0;
      }, 300)
      }
      


getTageritem(element){

  console.log('getting tageritem');
 // this.tagerSvc.getTageritem(element._id, true);

  
  this.configSvc.currentform=element; 
  this.configSvc.getTageritem(element); 
 // this.configSvc.activetool = 'activeform'; 
  this.configSvc.fullpage = true;
  this.configSvc.typeenabled = true;this.configSvc.buildtool = false;
  this.configSvc.activetype = 'edittager'
  if(element.cmt==true ){
    console.log('this node got comments associated with it')
    this.commentSvc.getFileComments(element._id)
  }
 


}

deselect(){
  console.log('deseelcting now')
  this.configSvc.toolmenu = 'general';

  this.configSvc.deselect('fold');
}

clickNode(selectednode, type, itemindex){
  this.configSvc.quickadd = false
  this.configSvc.clicksource = 'browse'
  this.configSvc.nodeready = false;
  this.configSvc.folderitem = itemindex;
  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {
        
  this.configSvc.toolmenu='nodetools'; this.configSvc.fullpage = false;
  console.log('single clicking node now for '+JSON.stringify(selectednode))
  console.log('current path for node '+JSON.stringify(selectednode.path))
  console.log('tageritemready is now '+this.configSvc.tageritemready)
  this.configSvc.activetype = type
  console.log('active type is now set to :'+this.configSvc.activetype);
  console.log('item index for this in the folder is '+itemindex);
  console.log('nodeready  is '+this.configSvc.nodeready);
  this.configSvc.itemindex= itemindex

  this.configSvc.activename = selectednode.name
  this.configSvc.activeitemid = selectednode._id

  this.configSvc.nodeds = [];this.configSvc.hlitem = [];
  this.configSvc.nodeds.push(selectednode);
  this.configSvc.nodeready = true;
  console.log('nodeready  is '+this.configSvc.nodeready);
  this.configSvc.kanbanready = true

  if(selectednode.photo == true){
    console.log('this node has got photos')
    this.configSvc.nodeready = false;
    console.log('lets for get the image version history')
    this.uploadSvc.getLatestphoto(this.configSvc.nodeds[0].latestphotoid)
  }

  if(selectednode.photo == false){
    console.log('this node has no photos')
    this.configSvc.nodeready = true;this.onRecreate()
  }
      } 



else if (this.clickCount === 2) {
        console.log('this is a double click')
    
        this.configSvc.folderitem = itemindex;
        this.configSvc.hidebrowser = true
        this.configSvc.clicksource = 'browse'
        console.log('this is row number of the item i selected '+this.configSvc.folderitem)
        if(selectednode.type == 'node'){
        this.router.navigate(['/toolbard/home'], { queryParams: 
          //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
          {itemid:selectednode._id, type:'nodedirect', name: selectednode.name, 
          level:selectednode.level, teamid: selectednode.teamid }

           });}

           if(selectednode.type == 'wizard'){
            this.router.navigate(['/toolbard/home'], { queryParams: 
              //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
              {itemid:selectednode._id, type:'wizarddirect', name: selectednode.name, 
              level:selectednode.level, teamid: selectednode.teamid }
     });}

     if(selectednode.type == 'nodetab'){
      this.router.navigate(['/toolbard/home'], { queryParams: 
        //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
        {itemid:selectednode._id, type:'tabbeddirect', name: selectednode.name, 
        level:selectednode.level, teamid: selectednode.teamid }

         });}

         if(selectednode.type == 'chatgpt'){
          console.log('this is the selected name '+selectednode.name)
          this.router.navigate(['/toolbard/home'], { queryParams: 
            //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
            {itemid:selectednode._id, type:'chatgptdirect', name: selectednode.name, 
            level:selectednode.level, teamid: selectednode.teamid }
  
             });}
           
       


      }
      this.clickCount = 0;
  }, 250)
}




public onRecreate(){
  this.reset[0] = {}
}

/*
public dropped(files: NgxFileDropEntry[]) {
var indextype = '0'
  if(this.configSvc.folderdetailsds.tagged){
indextype = '1';
  }
  this.uploadSvc.dropped(files, indextype, this.configSvc.textract);
// this.dialogRef.close();
  }
  */
  
  
    public fileOver(event) {
      this.configSvc.docadd = true;
      // this.configSvc.browserspan = 1;
      this.configSvc.fileover= true;
      console.log('docadd value is' + this.configSvc.docadd);
      console.log(event);
    }

}      
