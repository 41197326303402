import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-editnodeteam',
  templateUrl: './editnodeteam.component.html',
  styleUrls: ['./editnodeteam.component.css']
})
export class EditnodeteamComponent implements OnInit {

  editnodeteamFG: FormGroup;

  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit() {

    console.log('initializing editnodeteam')
    console.log('this is the design root need to set design tools')
   // this.configSvc.toolmenu = 'designtools';

    this.editnodeteamFG = this.fb.group({
      teamname: [this.configSvc.nodeteamdetailsds[0].name],
      teamdesc:[this.configSvc.nodeteamdetailsds[0].desc],
      colors: 'black',
      checked: false,
      tagged: [''],
      tagtmpl: [''],
      tagsetid: [''],
      tagsn: [''],
      teams: []
    });
  }

}
