<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">create_new_folder</mat-icon>
       Flow Details
      </ng-template>
    <form [formGroup]="editflowFG">
      <span  class="edittageritems">
    <mat-form-field>
      <mat-label>Flow Name</mat-label>
      <input   formControlName="name" matInput value={{this.configSvc.flowdetailsds[0].name}}>
    </mat-form-field></span>
    <span  class="edittageritems">
    <mat-form-field >
      <mat-label>Flow Description</mat-label>
      <input  formControlName="desc" matInput value={{this.configSvc.flowdetailsds[0].desc}}>
    </mat-form-field></span>
  
    <span>
      <app-iconcategories></app-iconcategories></span>
  
      <div>
        Choose a colour for Flow</div>
      
  
          <span class ="colorbox">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker>
        </span>
  
     
          
      
         
  
  <div>
    <button [disabled]= "!this.configSvc.colorchanged && !this.editflowFG.dirty" (click)="this.updateNode();"
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Update Flow ">
          
         Update Flow</button></div>
        </form>
      </mat-tab>
  </mat-tab-group>
  