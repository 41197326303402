import { Injectable } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';

import {Docversion, Doccomments} from '.././model/docversion';
import {ConfigService} from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DocactionsService {

  details: UserDetails;
  public docdetails: Docversion = null;
  public uploadtime;

  public docstatus;
  public uploadpercent = 0;
  public uploadcount = 0;

  user: TokenPayload;

  // public currentrow: Docversion;

  public currentattach: Docversion;
  public showdetails = false;
  public showattdetails = false;
  public showprogress = true;
  public showtabs= true;
  public mybatch;
  public blobdata;
  public mt;
  highlightedRows = [];
  highlightedAttach = [];



  constructor(private authService: AuthenticationService, public configSvc: ConfigService) { }
  public selectRow(row) {
    this.highlightedRows = [];
    this.showdetails = true;
    this.showprogress = false;
    this.configSvc.currentrow = row;
    this.highlightedRows.push(row);
    this.showtabs = true;
    this.showattdetails = false;
    console.log('show details is set to: ' + this.showdetails);
    console.log('row is:' + JSON.stringify(this.configSvc.currentrow));
    console.log('filename is:' + JSON.stringify(this.configSvc.currentrow.fn));
    console.log('highlighted row index value:' + JSON.stringify(this.highlightedRows.indexOf(row)));
    console.log('highlighted rowse:' + JSON.stringify(this.highlightedRows));
return row;
  }

  public downloadFile() {
    console.log('downloading file now');
    const downloadForm = new FormData();
    downloadForm.append('itemid', this.configSvc.currentrow._id);
    downloadForm.append('docid', this.configSvc.currentrow.pa);
    downloadForm.append('type', 'version');
    
   //  this.authService.downloaddoc(this.user, this.currentrow._id).subscribe(downloaddata => {
    this.authService.downloaddoc(downloadForm, this.user).subscribe(downloadresp => {

     this.blobdata = window.URL.createObjectURL(downloadresp);
     const a = document.createElement('a');
     a.download = this.configSvc.currentrow.name;
     a.href = this.blobdata;
     a.click();
    console.log('did we get a response from node download service?');

     }, (err) => {
        console.error(err); });

    }

    public downloadVersion(itemid, versionid) {
      console.log('downloading version now');
      const downloadForm = new FormData();
      downloadForm.append('itemid', itemid);
      downloadForm.append('docid', versionid);
      downloadForm.append('type', 'version');
     //  this.authService.downloaddoc(this.user, this.currentrow._id).subscribe(downloaddata => {
      this.authService.downloaddoc(downloadForm, this.user).subscribe(downloadresp => {
  
       this.blobdata = window.URL.createObjectURL(downloadresp);
       const a = document.createElement('a');
       a.download = this.configSvc.currentrow.name;
       a.href = this.blobdata;
       a.click();
      console.log('did we get a response from node download service?');
  
       }, (err) => {
          console.error(err); });
  
      }

    
  public getFile(toolbardid, name, itemid) {
    console.log('downloading file now');
  //  console.log('this is the activeflowid '+this.configSvc.currentrow.activeflowid)
    console.log('this is the activetype '+this.configSvc.activetype)
    const downloadForm = new FormData();
// var itemid = this.configSvc.currentrow._id
    
 
    downloadForm.append('itemid', itemid);
    downloadForm.append('docid', toolbardid);
    downloadForm.append('type', 'version');
   //  this.authService.downloaddoc(this.user, this.currentrow._id).subscribe(downloaddata => {
    this.authService.downloaddoc(downloadForm, this.user).subscribe(downloadresp => {

     this.blobdata = window.URL.createObjectURL(downloadresp);
     const a = document.createElement('a');
     a.download = name;
     a.href = this.blobdata;
     a.click();
    console.log('did we get a  response from node download service?');

     }, (err) => {
        console.error(err); });

    }

    public editFile() {


      console.log('this should be the current folderid'+this.configSvc.activeitemid)
      const editfileForm = new FormData();
      this.uploadtime = Date.now();
      editfileForm.append('teamid', this.configSvc.activeteamid);
      editfileForm.append('itemid', this.configSvc.currentrow._id);
      editfileForm.append('folderid', this.configSvc.currentfolder);
      editfileForm.append('addedon', this.uploadtime );
      editfileForm.append('type', 'editfile' );
      // append checkout status here
      this.authService.editFile(editfileForm, this.user).subscribe(editfileresults => {
      
      //this refreshes the container folder
        this.configSvc.teamds = editfileresults;
        this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
       }, (err) => {
          console.error(err); });

      }
    public deleteFile() {
  }
  public emailFile() {
  }

  public selectAttach(attachrow) {
    this.highlightedAttach = [];
    this.showattdetails = true;
    this.showtabs = false;
    console.log('show main search tabs is set to: ' + this.showtabs);
    this.currentattach = attachrow;
    this.highlightedAttach.push(attachrow);
    console.log('show details is set to: ' + this.showattdetails);
    console.log('row is:' + JSON.stringify(this.currentattach));
    console.log('filename is:' + JSON.stringify(this.currentattach.Filename));
    console.log('highlighted row index value:' + JSON.stringify(this.highlightedAttach.indexOf(attachrow)));
    console.log('highlighted rowse:' + JSON.stringify(this.highlightedAttach));
return attachrow;
  }


}
