<div class = "smartfolder-container">
<mat-card class = "browser">
    <table mat-table [dataSource]="this.configSvc.subfolders " matSort >
      

        <tr mat-row *matRowDef="let element; columns: activeColumns; let itemindex = index;"
        
      (click)="this.selectSubitem(element, itemindex)"
      [style.background]=" this.hlsubitem.indexOf(element) == 0 ? 'lightblue' : ''"
      class="table-row" ></tr>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>
     
            <td mat-cell *matCellDef="let element">
                
                <div  *ngIf="element.type  == 'subfolder'">
                    <i class="material-icons md-24" [ngStyle]="{'color':'yellow'}">folder</i>
                    
                    </div>

                    <div  *ngIf="element.type  != 'subfolder'">
                        <i class="material-icons md-24" [ngStyle]="{'color': element.iconcolor}">{{element.icontype}}</i>
                        
                        </div>
                </td>

            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>
         
                <td mat-cell *matCellDef="let element">
                    
                    <div>
                        {{element.name}}
                        
                        </div>
                    </td>
    

                
                </ng-container>
    </table>
</mat-card>
   
 


<span *ngIf="this.subitemselected == true && this.configSvc.linknodeready == true ">
    
  
    <span *ngFor="let r of reset">
<app-activesubitem></app-activesubitem></span>
</span> 
</div>
  