import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.css']
})
export class AssignedComponent implements OnInit {
  @Input() assigntype= '';
  assignFG : FormGroup;
 
  constructor( private _formBuilder: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {
    this.configSvc.filtereduser = [];
  
    if(this.configSvc.activetype == 'file' || this.configSvc.activetype == 'node' || this.configSvc.activetype == 'chatgpt' || this.configSvc.activetype == 'nodetab' || this.configSvc.activetype == 'tabbeddirect'
    || this.configSvc.activetype == 'tabbeddirect' || this.configSvc.activetype == 'chatgptdirect' || this.configSvc.activetype == 'nodedirect' || this.configSvc.activetype == 'filedirect' || this.configSvc.activetype == 'tabbed'  ){
    this.assignFG = this._formBuilder.group({
      username: [this.configSvc.filtereduser, Validators.required],
      assignedto: [this.configSvc.nodeds[0]?.assignedto, Validators.required],
    });
  }

  if(this.configSvc.activetype == 'fold'){
    this.assignFG = this._formBuilder.group({
      username: [this.configSvc.filtereduser, Validators.required],
      assignedto: [this.configSvc.folderdetailsds?.assignedto, Validators.required],
    });
  }
  }

  usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
  console.log('usernameFilter value is currently set to '+filterValue)

  }
  
  }

  public updateAssignment(assignedto){
    console.log('updating assignment for flow '+this.configSvc.activeitemid)

    console.log('this is the new assignment'+JSON.stringify(assignedto))
    this.configSvc.assignedto = assignedto
  //  if(this.assigntype != 'toolbar'){
      this.assignFG.value.assignedto = assignedto
    this.configSvc.updateAssignment(this.configSvc.nodeds[0]._id, assignedto)
    
  
  //  }
    /*
    if(this.assigntype == 'toolbar'){
      this.configSvc.assigned = [];
   this.configSvc.assigned.push(assignedto)
      this.assignFG.markAsPristine()
      this.assignFG.value.assignedto = assignedto
      }
      */
  }

  userSelected(user){
    console.log('this is the selected user '+JSON.stringify(user))
    this.configSvc.assignedto = user.inboxemail
    this.configSvc.userready =true
}

public clearAssignment(){
  console.log('updating assignment for flow '+this.configSvc.activeitemid)


  this.configSvc.clearAssignment(this.configSvc.nodeds[0]._id)


}

}
