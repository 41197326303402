import { Component, OnInit } from '@angular/core';
import {TagerService} from '../../services/tager.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { TagService } from '.././../services/tag.service';
import { ConfigService } from '.././../services/config.service';

@Component({
  selector: 'app-edittabs',
  templateUrl: './edittabs.component.html',
  styleUrls: ['./edittabs.component.css']
})
export class EdittabsComponent implements OnInit {

  edittabsFG: FormGroup;
  foldertagtypes = ['none', 'colorfolder', 'bookmark']

  constructor(private _formBuilder: FormBuilder, public tagerSvc: TagerService, public configSvc: ConfigService,
    public tagSvc: TagService) { }

  ngOnInit(): void  {

    this.configSvc.colorinputtype = 'node';
    this.configSvc.stepclicked = false;
    // this.configSvc.editstepper = '';
    this.edittabsFG = this._formBuilder.group({
     name: [this.configSvc.nodeds[0].name, Validators.required],
     desc: [this.configSvc.nodeds[0].desc, Validators.required],
     icontype: [this.configSvc.nodeds[0].icontype, Validators.required],
     iconcolor: [this.configSvc.nodeds[0].iconcolor, Validators.required],
      type: [this.configSvc.nodeds[0].type, Validators.required],
      textractable: [this.configSvc.nodeds[0].textractable, Validators.required],
      textractype: [this.configSvc.nodeds[0].textractype, Validators.required],
      changecolor: [false, Validators.required],
      defaultvalue: ['', Validators.required],
      ischatgpt: [this.configSvc.nodeds[0].ischatgpt, Validators.required],
      defaultprompt: [this.configSvc.nodeds[0].defaultprompt, Validators.required],
      
    });
  }

  updateNode(){

    // this.tagerSvc.editTager(this.tagerSvc.hlKanbanset[0]._id, 
     //  this.foldertagactionFG.value.nodename, this.foldertagactionFG.value.tagerdesc,
     //   this.foldertagactionFG.value.tagertype, this.foldertagactionFG.value.category)
     //   this.foldertagactionFG.markAsPristine();
     console.log('current level '+this.configSvc.activelevel)
     console.log('current nodepathds '+JSON.stringify(this.configSvc.nodepathds));
     console.log('current nodeds '+JSON.stringify(this.configSvc.nodeds[0]));
     console.log('current nodeds path 1'+JSON.stringify(this.configSvc.nodeds[0].path[1]));
     console.log('current nodepathds level1 '+JSON.stringify(this.configSvc.nodepathds[1]));
    //  var pathid = this.configSvc.nodepathds[this.configSvc.activelevel].path[1]._id
    var pathid =  this.configSvc.nodeds[0].path[1]._id
     console.log('current pathid trying to update '+pathid)
 
     this.configSvc.updateNode(this.configSvc.nodeds[0]._id, this.edittabsFG.value.name,
       this.edittabsFG.value.desc, this.edittabsFG.value.type, this.configSvc.chosenIcon, 
       this.configSvc.color, pathid, this.edittabsFG.value.textractable, this.edittabsFG.value.textractype, this.edittabsFG.value.ischatgpt, this.edittabsFG.value.defaultprompt)
       this.edittabsFG.markAsPristine(); this.configSvc.colorchanged =false;
   }

}
