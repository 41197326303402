import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../../services/config.service';
import {FlowService} from '../../../services/flow.service';

@Component({
  selector: 'app-teamqueue',
  templateUrl: './teamqueue.component.html',
  styleUrls: ['./teamqueue.component.css']
})
export class TeamqueueComponent implements OnInit {

  browseColumns = [ 'MimeColumn', 'ItemColumn'];
  public reset: any[] = [{}];
  public clickCount = 0;


  constructor(private _formBuilder: FormBuilder, public configSvc: ConfigService, 
    private authService: AuthenticationService,  public flowSvc: FlowService,
   private router: Router, private activatedRoute: ActivatedRoute) { }

   ngOnInit() {
   
    this.configSvc.mode = 'queue'
    this.configSvc.activeflow = '';
    this.configSvc.nodeds = [];
    
    this.activatedRoute.queryParams.subscribe(params => {
     this.configSvc.activeitemid = params['itemid'];
     this.configSvc.activetype = params['type'];
     this.configSvc.activename = params['name'];
     this.configSvc.activeteamid = params['teamid'];
     this.configSvc.activeurl = "http://localhost:4200"+this.router.url;
     
   
     console.log('activated itemid'+this.configSvc.activeitemid);
   
     console.log('activated teamid'+this.configSvc.activeteamid);
     console.log('activated team name'+this.configSvc.activename);
     console.log('activated type'+this.configSvc.activetype);
     this.configSvc.queueready = false;
     this.authService.getQueues(this.router.url).subscribe(queuedata => {
      this.configSvc.queueready = false
      if (!this.activatedRoute.snapshot.queryParamMap.has('teamid')){
        this.configSvc.teamqueueds = queuedata;
        this.configSvc.rootqueue = true;
        this.configSvc.activetype = 'rootqueue';
        console.log('activated type'+this.configSvc.activetype);
      }


       
      
       if(this.activatedRoute.snapshot.queryParamMap.has('teamid'))
       {   
        console.log('activated type'+this.configSvc.activetype);
        if(this.configSvc.activetype == 'queuedirect'){
          this.configSvc.nodeds = queuedata
          this.configSvc.queueready = true
        //  this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false)
       // this.flowSvc.getQueueDetails(this.configSvc.activeteamid)
        }
        if(this.configSvc.activetype != 'queuedirect'){
        this.configSvc.queueitemds = queuedata
        console.log('queueitemds is now'+JSON.stringify(this.configSvc.queueitemds));
        this.flowSvc.getQueueDetails(this.configSvc.activeteamid)
       

      }
     

        this.configSvc.queueready = true;
 
       //  this.configSvc.getpath(this.configSvc.nodeds[0].parentid, this.configSvc.activeteamid) 
       this.configSvc.toolmenu = 'queuetool'
       console.log('toolmeu set to '+this.configSvc.toolmenu)
        }
   
       
      
    
       
      }, (err) => {
       console.error(err);
      });
   
   
   
     
     
      
   });
   
   
   
   if (!this.activatedRoute.snapshot.queryParamMap.has('teamid')){
   
     this.configSvc.activetool = '';
     this.configSvc.activetype = 'team';
     console.log('active type is '+this.configSvc.activetype);
   }
   
   
      
      }

      public onRecreate(){
        this.reset[0] = {}
      }


      public clickQueue(queue){
        console.log('clicked queue'+JSON.stringify(queue))
        this.configSvc.hlqueue = []; this.configSvc.activeflow = '';
        console.log('activeflow is set to'+this.configSvc.activeflow);
        this.configSvc.hlqueue.push(queue);
        this.configSvc.queueicon = queue.icontype
        this.configSvc.queuecolor = queue.iconcolor
       
        this.onRecreate()
      }

      public clickActiveflow(activeflow){
        this.configSvc.nodeds=[];
        this.configSvc.nodeds.push(activeflow);
        var base = '/toolbard/teamqueue?'
        var itemid =  this.flowSvc.queueds[0]._id
        var name = this.flowSvc.queueds[0].name
        var teamid = this.configSvc.activeteamid;
      this.configSvc.queuedirecturl = base+'&type=queuedirect&itemid='+itemid+'&name='+name+'&teamid='+teamid
      console.log('this is the  queuedirecturl '+ this.configSvc.queuedirecturl)
    //    this.configSvc.queuedirecturl = ''
        this.flowSvc.queuedetailsready = false
        console.log('this is the  activeflowds: '+JSON.stringify(this.configSvc.nodeds))
        this.clickCount++;
        setTimeout(() => {
            if (this.clickCount === 1) {
        console.log('clicked active flow item')
 //       this.configSvc.nodeds=[];
    //    this.configSvc.nodeds.push(activeflow);
        console.log('this is the  activeflowds: '+JSON.stringify(this.configSvc.nodeds))
       
        //this.configSvc.activeflow = this.configSvc.nodeds[0].launchtype
      //  console.log('this is the  activeflow:'+this.configSvc.activeflow);
    //    
        var phase = this.configSvc.nodeds[0].assignedat
        console.log('this is  at phase : '+phase);
        var next = this.configSvc.nodeds[0].flow[phase].next
        console.log('this is next button : '+next);
        this.configSvc.activetype = this.configSvc.nodeds[0].type
        console.log('this is actiuvetype: '+ this.configSvc.activetype);
        this.flowSvc.queuedetailsready = false
        this.onRecreate()
      }
      else if (this.clickCount === 2) {
      
         this.configSvc.queueready = false
        console.log('this is a double click')
       console.log('this is row number of the item i selected '+this.configSvc.folderitem)
       console.log('this is the teamid'+this.configSvc.nodeds[0].assignedid)
        this.router.navigate(['/toolbard/teamqueue'], { queryParams: 
          //{itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid }
          { type:'queuedirect',itemid: this.configSvc.nodeds[0]._id, name: this.configSvc.nodeds[0].name, 
          teamid: this.configSvc.activeteamid}

           });
      
      }
      this.clickCount = 0;
      }, 300)
      }

      public deselectQueue(){
        console.log('deselecting queue')
        this.flowSvc.deselectQueue()
      }

}
