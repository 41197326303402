
      <mat-tab-group >

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon> search</mat-icon>
        AI Search
          </ng-template>

          <div class="signin-content">
            <mat-card class = "aisearch">
              <mat-card-content>
                <mat-form-field class="example-form-field">
                  <mat-label>Artificial Intelligence Search</mat-label>
                  <input matInput type="text" [(ngModel)]="searchvalue">
                  <button *ngIf="searchvalue" matSuffix mat-icon-button aria-label="Clear" (click)="searchvalue=''; this.configSvc.teamds = null;  this.searchDocsvc.searchstatus = 0">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                 <button  [disabled]= "searchvalue ==''"
                mat-icon-button
               
                (click)="searchAI(this.searchvalue)"
              >
                <mat-icon > send </mat-icon>
            
              </button>
                </mat-card-content>
                </mat-card>
</div>

<mat-card *ngIf=" this.searchDocsvc.searchstatus==4" class = "actualresults-container">

  <app-aisearchresults></app-aisearchresults>
</mat-card>

<mat-card *ngIf=" this.searchDocsvc.searchstatus == 3" class = "results-container">

 Returned no results
</mat-card>

<mat-card *ngIf="this.searchDocsvc.searchstatus == 1" class = "results-container">
  


  <mat-spinner></mat-spinner>
</mat-card>

        </mat-tab>


<mat-tab>
  <ng-template mat-tab-label>
    <mat-icon> search</mat-icon>
Advanced Search
  </ng-template>
  <mat-card class = "search-container" *ngIf="!this.configSvc.hc">
    <form [formGroup]="searchFG">
    <mat-checkbox formControlName="addedby">Added by</mat-checkbox>

    <span *ngIf="this.searchFG.value.addedby">
     

      <span>
        <mat-radio-group formControlName="abtype">
         <mat-radio-button value="ME">Myself</mat-radio-button>
         <mat-radio-button  value="ELSE">Someone else
          <span *ngIf="this.searchFG.value.abtype == 'ELSE'">
          <mat-form-field>
            <mat-label>User</mat-label>
           
            <input type="text"  
            formControlName="username"  
            [matAutocomplete]="auto"
            
            matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
                {{option.inboxemail}}
              </mat-option>
            </mat-autocomplete>
    
           
           </mat-form-field></span>
         </mat-radio-button>
       </mat-radio-group></span>

   

    </span>

    <div>
    <mat-checkbox formControlName="assignedto">Assigned To</mat-checkbox>

    <span *ngIf="this.searchFG.value.assignedto">
     

      <span>
        <mat-radio-group formControlName="attype">
         <mat-radio-button value="ME">Myself</mat-radio-button>
         <mat-radio-button  value="ELSE">Someone else
      
         </mat-radio-button>
         <span *ngIf="this.searchFG.value.attype == 'ELSE'">
          <mat-form-field appearance="fill">
            <mat-label>User</mat-label>
           
            <input type="text"  
            formControlName="username"  
            [matAutocomplete]="auto"
            
            matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
       <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
        {{option.inboxemail}} 
    
       </mat-option>
            </mat-autocomplete>
    
           
           </mat-form-field></span>
       </mat-radio-group></span>

   

    </span>
  </div>

  <div>
    <mat-checkbox formControlName="team">Team</mat-checkbox>
    <span *ngIf="this.searchFG.value.team">
    
    
      
          <mat-form-field appearance="fill"  class = "indextemplates">
            <mat-label>Team Name</mat-label>
           
            <input type="text"  
            formControlName="teamname"   
            [matAutocomplete]="auto"
            
            matInput (keyup)="teamFilter($event)" placeholder="Index" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of this.configSvc.findteamuserds" [value]="item.name"  
        (onSelectionChange)="teamSelected(item)">
        <i class="material-icons icon-size" [ngStyle]="{'color': item.clr}">
          {{item.icontype}}</i>
        {{item.name}}:<i>{{item.desc}} </i>
        
        </mat-option>
            </mat-autocomplete>
        
           
           </mat-form-field>
        
           <div>
          <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
            {{this.configSvc.nodeds[0]?.icontype}}</i>
          {{this.configSvc.nodeds[0]?.name}}:
        </mat-label></div>
  
           <span *ngIf="this.indexready">
            <span  *ngFor="let r of reset">
            <app-activenode></app-activenode>
          </span>
            
        
        <span *ngIf ="!this.folderFG.value.flow">
        <button  *ngIf ="!this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker  "mat-button  matSuffix 
        mat-raised-button color="primary"(click) = "this.saveNode('node');  this.dialogRef.close();">Save Node and Close</button></span>
        
        <span *ngIf ="this.folderFG.value.flow">
          <button  *ngIf ="!this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker "mat-button  matSuffix 
          mat-raised-button color="primary"(click) = "this.launchFlow();  this.dialogRef.close();">Launch</button></span>
        
        
        <button  *ngIf ="this.folderFG.value.attach || this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.statetracker "mat-button  matStepperNext matSuffix 
        mat-raised-button color="primary">Next</button> 
           </span>
    </span>
    </div>

<div>
    <mat-checkbox (change)="setdatetoday()"formControlName="addedon">Added On</mat-checkbox>
    <mat-checkbox formControlName="mo">Modified On</mat-checkbox>
    <span *ngIf="this.searchFG.value.addedon || this.searchFG.value.mo">

      <span>
    
        <mat-form-field class="ex1">
             <mat-select placeholder="Choose Date Range" formControlName="dateop">
               <mat-option *ngFor="let dateopers of dateoperators" [value]="dateopers.value">
                 {{dateopers.viewValue}}
               </mat-option>
             </mat-select>
           </mat-form-field>
         
         
         </span>
      <mat-form-field appearance="fill">
        <input matInput [matDatepicker]="picker1" 
    
       
        formControlName="datebox"
        
       >
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker  #picker1></mat-datepicker>
</mat-form-field>

<span *ngIf="this.searchFG.value.dateop =='between'">AND
  <mat-form-field appearance="fill">
    <input matInput [matDatepicker]="picker1" 

   
    formControlName="datebox2"
    
   >
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker  #picker1></mat-datepicker>
</mat-form-field>
</span>
</span>
</div>
<div>

<mat-checkbox formControlName="name">Name</mat-checkbox>
<span *ngIf="this.searchFG.value.name" >

  <mat-form-field class="ex1">
    <mat-select placeholder="Choose Operator" formControlName="optype" >
      <mat-option *ngFor="let opers of operators" [value]="opers.value">
        {{opers.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"  >
    <mat-label>Enter name criteria here</mat-label>
    <input formControlName="searchBox" matInput value="{{searchFG.value.searchBox}}">
  
  
  </mat-form-field></span>

 
</div>
<div>
<mat-checkbox formControlName="state">State</mat-checkbox>
<span *ngIf="this.searchFG.value.state" >
=
  <mat-form-field class="ex1">
    <mat-select placeholder="Choose Operator" formControlName="stateval">
      <mat-option *ngFor="let opers of boolvalues" [value]="opers.value">
        {{opers.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</span></div>

<div>
  <mat-checkbox formControlName="lineitem">Lineitems</mat-checkbox>
  <span *ngIf="this.searchFG.value.lineitem" >

  <mat-form-field appearance="outline" >
    <mat-label>Lineitem starts with</mat-label>
    <input formControlName="lineitemBox" matInput value="{{searchFG.value.lineitemBox}}">
  
  
  </mat-form-field>
  </span></div>

  <div>
    <mat-checkbox formControlName="prompt">Prompt</mat-checkbox>
    <span *ngIf="this.searchFG.value.prompt" >

      <mat-checkbox formControlName="rate">Ratings</mat-checkbox>
      <span *ngIf="this.searchFG.value.rate">
    
        <mat-form-field class="ex1">
             <mat-select formControlName="rateop">
               <mat-option *ngFor="let rating of ratingoperators" [value]="rating.value">
                 {{rating.viewValue}}
               </mat-option>
             </mat-select>
           </mat-form-field>
       

         <span>
    
          <mat-form-field class="ex1">
               <mat-select placeholder="Choose Prompt Rating" formControlName="rating">
                 <mat-option *ngFor="let rating of ratings" [value]="rating.value">
                   {{rating.viewValue}}
                 </mat-option>
               </mat-select>
             </mat-form-field>
           </span>
          </span>

      <mat-checkbox formControlName="promptstartswith">Prompt starts with</mat-checkbox>
      <span *ngIf="this.searchFG.value.promptstartswith" >
    <mat-form-field appearance="outline" >
      <mat-label>Prompt starts with</mat-label>
      <input formControlName="promptBox" matInput value="{{searchFG.value.promptBox}}">
    
    
    </mat-form-field></span>
    </span></div>

<mat-checkbox 
formControlName="searchtemplates">{{authService.getUserDetails()?.companyname}} Templates</mat-checkbox>

<span *ngIf="this.searchFG.value.searchtemplates">


<mat-radio-group formControlName="findtemplate">
  <mat-radio-button value="search">Search</mat-radio-button>
  <mat-radio-button  value="browse"  (change)="this.getsearchToolbars()">Browse </mat-radio-button>
   <span *ngIf="this.searchFG.value.findtemplate == 'browse'">

<div >
  <span  class= "treecontainer">
    
    <mat-chip-list>
    
            
      <mat-chip *ngFor="let paths of this.sortPath()"
      (click)="getChoicetree(paths.pfoldid)"
      >
        {{paths.name}}
  </mat-chip>
  </mat-chip-list>

    
     <mat-chip-list>

       
       <mat-chip *ngFor="let toolbar of this.configSvc.toolbards"
       (click)="getToolbars(toolbar)"
       >
         {{toolbar.name}}
   </mat-chip>
   </mat-chip-list>



  

<span *ngIf="this.existingsearch">

<mat-label>{{this.configSvc.searchoperator}}</mat-label>

<span appearance="fill" class= "criteria-display" >

<mat-label>{{this.configSvc.searchcriteria}}</mat-label>



</span>


</span>


</span>
</div>
</span>
<div *ngIf="this.searchFG.value.findtemplate == 'search'">
 <mat-label> Find Template</mat-label>
      <mat-form-field appearance="fill"  class = "indextemplates">
        <mat-label>Template</mat-label>
       
        <input type="text"  
        formControlName="indexname"   
        [matAutocomplete]="auto"
        
        matInput (keyup)="templateFilter($event)" placeholder="Index" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let template of this.configSvc.finditemds" [value]="template.name"  
    (onSelectionChange)="selectTemplate(template)">
    <i class="material-icons icon-size" [ngStyle]="{'color': template.iconcolor}">
      {{template.icontype}}</i>
    {{template.name}}:<i>{{template.desc}} </i>
    
    </mat-option>
        </mat-autocomplete>
    
       
       </mat-form-field>
    
       <div>
      <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
        {{this.configSvc.nodeds[0]?.icontype}}</i>
      {{this.searchFG.value.chosenproperty}}
    </mat-label></div>
 
</div>
<span *ngIf = "this.searchFG.value.templateselected && this.searchFG.value.findtemplate == 'browse'">
<mat-form-field class = "icondropdown">
  <mat-label>Choose Property</mat-label>
  <mat-select
  formControlName="property"
  (selectionChange)="changeProperty(this.searchFG.value.property)"
  >
  <mat-option *ngFor="let property of this.configSvc.finditemds" [value]="property"
    >
      {{property.name}}
    </mat-option>

  </mat-select>
</mat-form-field>
</span>
<span *ngIf = "this.searchFG.value.templateselected">
<mat-checkbox formControlName="indexselected">Include Indexes</mat-checkbox></span>

<span *ngIf="this.searchFG.value.indexselected">


<mat-form-field class = "icondropdown">
<mat-label>Choose Index</mat-label>
<mat-select
formControlName="customindex"
(selectionChange)="changeIndex(this.searchFG.value.customindex)">
  <mat-option *ngFor="let customindex of this.activeindexes" [value]="customindex"
  >
  {{customindex.formitem}}
  </mat-option>

</mat-select>

</mat-form-field>

<span *ngIf="this.searchFG.value.customindex.formtype =='number'">
<mat-form-field class="ex1">

<mat-select placeholder="Number Operator" formControlName="optype">
  <mat-option *ngFor="let opers of numoperators" [value]="opers.value">
    {{opers.viewValue}}
  </mat-option>
</mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class= "small-item">  
<mat-label>Number only</mat-label>
   <input formControlName="searchBox" type = "number"
    matInput value="{{this.searchFG.value.searchBox}}">
</mat-form-field>
</span>

<span *ngIf="this.searchFG.value.customindex.formtype =='string' || this.searchFG.value.customindex.formtype =='image' || this.searchFG.value.customindex.formtype =='text'">

<mat-form-field class="ex1">
  <mat-select placeholder="String Operator" formControlName="optype">
    <mat-option *ngFor="let opers of stroperators" [value]="opers.value">
      {{opers.viewValue}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill" class= "medium-item" >
  <mat-label>Enter Custom Index New Criteria here</mat-label>
  <input formControlName="indexval" matInput value="{{searchFG.value.indexval}}">


</mat-form-field>


</span>

<span *ngIf="this.searchFG.value.customindex.formtype =='choicelist'">

<mat-form-field class="ex1">
<mat-select placeholder="String Operator" formControlName="optype">
  <mat-option *ngFor="let opers of stroperators" [value]="opers.value">
    {{opers.viewValue}}
  </mat-option>
</mat-select>
</mat-form-field>

<span class= "itemcontainer">
  <span class = "labels">
  <mat-label >{{this.searchFG.value.customindex.formitemlabel}} </mat-label></span>
<mat-form-field>

 <mat-select formControlName="searchBox"  [(value)]="this.searchFG.value.searchBox" 


 >
<mat-option *ngFor="let choice of getChoices(this.searchFG.value.choicesetid)" [value]="choice.name">
{{choice.name}}
</mat-option>
</mat-select>

</mat-form-field>
</span>



</span>

<span *ngIf="this.searchFG.value.customindex.formtype =='boolean'">
<mat-form-field class="ex1">

<mat-select placeholder="Slider Operator" formControlName="optype">
  <mat-option *ngFor="let opers of booloperators" [value]="opers.value">
    {{opers.viewValue}}
  </mat-option>
</mat-select>
</mat-form-field>
<mat-form-field class="ex1">

<mat-select placeholder="Slider Values" formControlName="indexval">
  <mat-option *ngFor="let opers of boolvalues" [value]="opers.value">
    {{opers.viewValue}}
  </mat-option>
</mat-select>
</mat-form-field>
</span>
</span>


</mat-radio-group>
</span>
<div>
  <button      [disabled] = "!this.searchFG.dirty" mat-raised-button color="primary"  mat-button style="margin: 10px" (click)="newSearch()">
 Search</button> 

 <mat-checkbox formControlName="savedsearch">Save Search</mat-checkbox>


 <span *ngIf="this.searchFG.value.savedsearch">
  <mat-checkbox formControlName="hideafter">Hide Criteria after searching</mat-checkbox>
  <mat-form-field appearance="outline"  >
    <mat-label>Saved Search name</mat-label>
    <input formControlName="ssname" matInput value="{{searchFG.value.ssname}}">
  
  
  </mat-form-field>
 <span *ngIf="this.searchDocsvc.searchstatus==2">
 <button mat-raised-button color="primary"  mat-button style="margin: 10px" (click)="saveSearch(searchFG.value.ssname)">
  Save Search</button> </span>
 </span>




</div>

    </form>
    </mat-card>
    <mat-card *ngIf=" this.searchDocsvc.searchstatus==2" class = "actualresults-container">

      <app-searchresults></app-searchresults>
    </mat-card>

    <mat-card *ngIf=" this.searchDocsvc.searchstatus == 3" class = "results-container">

     Returned no results
    </mat-card>

    <mat-card *ngIf="this.searchDocsvc.searchstatus == 1" class = "results-container">
      


      <mat-spinner></mat-spinner>
    </mat-card></mat-tab>

    <mat-tab *ngIf= "this.configSvc.searchready">
      <ng-template mat-tab-label>
        <mat-icon> saved_search</mat-icon>
    Saved Search
      </ng-template>
      <mat-card>
        Click on one of the saved searches below
<div *ngFor="let savedsearch of this.configSvc.mysearches" class = "savedsearches">

<h1> <mat-icon> saved_search</mat-icon> <a href="{{savedsearch.searchurl}}">{{savedsearch.name}}</a></h1>

</div></mat-card>
      </mat-tab>
 
    </mat-tab-group>
    
    