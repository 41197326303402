<div class="signin-content">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="resetpwordform">
            <mat-icon title="Reset">
                <i class="fas fa-lock" data-fa-transform="grow-2" ></i></mat-icon> Reset Password
                <div><mat-form-field appearance="outline" floatLabel="always">
                  <mat-label >Username</mat-label><input matInput [readonly]= "true" formControlName="username">
                 </mat-form-field></div>

          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="New Password" formControlName="password1" required>
            <mat-error *ngIf="isFieldInvalid('username')">Please enter your password</mat-error>
  
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Confirm Password" formControlName="password2" required>
            <mat-error *ngIf="isFieldInvalid('username')">Re-enter your password</mat-error>
  
          </mat-form-field>
      
          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
   <button [disabled]= "this.resetpwordform.value.password1 != this.resetpwordform.value.password2" 
   mat-raised-button color="primary" 
   (click)="resetpword()">Reset Password</button>
      
     </form>
      </mat-card-content>
    </mat-card>
  </div>