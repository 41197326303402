<form [formGroup]="assignFG">
<span class = "container">
 
        <div *ngIf="this.assigntype != 'toolbar'" class = "space"> 

Assigned To: 
        <button
        matSuffix 
        mat-raised-button color="primary"
        aria-label="AssignedTo ">
{{this.assignFG.value.assignedto}} </button></div>


<div class = "space">
    <mat-form-field appearance="fill">
      <mat-label>Reassign</mat-label>
     
      <input type="text"  
      formControlName="username"  
      [matAutocomplete]="auto"
      
      matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
      
      <mat-autocomplete #auto="matAutocomplete">
 <mat-option *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"
 (onSelectionChange)="userSelected(user)"
 >
  {{user.inboxemail}} 

 </mat-option>
      </mat-autocomplete>

     
     </mat-form-field>
    </div>



<span >  <button class = "save"
    (click)="this.updateAssignment(this.assignFG.value.username)"
    [disabled] = "!this.configSvc.userready"
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Assign">
Assign </button></span> 
 
<span >  <button class = "save"
        (click)="this.clearAssignment()"
       
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Assign">
    Clear Assignment </button></span> 
     


</span>  </form>
