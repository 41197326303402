
<div  [formGroup]="editteamFG"  >
   <mat-card class="findusercard">

                                 
                                   

                                    <div >
                                      <mat-form-field appearance="fill">
                                        <mat-label>Assign</mat-label>
                                       
                                        <input type="text"  
                                      
                                        formControlName="usersearch"  
                                  
                                        [matAutocomplete]="auto"
                                        
                                        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
                                        
                                        <mat-autocomplete #auto="matAutocomplete">
                                   <mat-option *ngFor="let option of this.configSvc.filtereduser" 
                                   [value]="option.inboxemail">
                                    {{option.inboxemail}} 
                                  
                                   </mat-option>
                                        </mat-autocomplete>
                                  
                                       
                                       </mat-form-field>
               </div>
   <span class = "btns">
       <button mat-raised-button color="primary"
       [disabled]= "!this.editteamFG.dirty"
       (click)="this.addteammgr(this.configSvc.activetype,  this.editteamFG.value.usersearch)">Add Manager</button>
                                
           
             <button mat-raised-button color="primary" 
             [disabled]= "!this.mgrselected"
              (click)="this.removeteammgr()">Remove Manager</button>
            </span>
                               
 
    <div class="example-list">
    <div class="box-list" *ngFor="let selectedmgrs of this.configSvc.mgrsadded"
     (click)="this.configSvc.selectMgrRow(selectedmgrs, true); this.mgrselected = true"
      [style.background]=" this.configSvc.highlightedMgrRow.indexOf(selectedmgrs) == 0 ? 'lightblue' : ''">
       <div>
    
     </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{selectedmgrs}}
      </div></div>
    </div>

 
     
               
                  </mat-card>
            </div>

        
                        