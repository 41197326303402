<span [formGroup]="emailtagsFG" class = "emailtags-container">

          
    <div>  
      <mat-form-field appearance="outline" floatLabel="always" class="subject"> 
        <mat-label>Subject</mat-label>
            <input [readonly]= "true"  formControlName="subject" matInput value="{{this.configSvc.emaildata[0]?.subject}}">
           </mat-form-field>
        </div> 

        <div>
          <mat-form-field appearance="outline"    floatLabel="always"  class = "textarea">
            <mat-label >Email Body</mat-label>
            <textarea 
                           
         
            
            formControlName="emailbody"
             matInput value={{this.configSvc.emaildata[0]?.emailbody}}
        
             cdkTextareaAutosize
             cdkAutosizeMinRows=this.textarearowmin
             cdkAutosizeMaxRows=this.textarearowmax
             ></textarea>
            </mat-form-field>
        </div>
     
      <div><mat-form-field appearance="outline" floatLabel="always" >
          
          <mat-label >From</mat-label><input matInput [readonly]= "true" formControlName="from" matInput value={{this.configSvc.emaildata[0]?.from}}>
        </mat-form-field> </div>

  
<span class = "recips-containers">
  <div>
  <fieldset style="border: 1px lightgrey solid; margin-right:20vw; margin-bottom:2vh">
    <legend style="border: 1px lightgrey solid;margin-left: 1em; padding: 0.2em 0.8em ">TO</legend>
          
          <mat-selection-list class = "recips" [multiple]="false">
            <mat-list-option *ngFor="let emailto of this.configSvc.nodeds[0].email[0].to" [value]="emailto">
             {{emailto}}
            </mat-list-option>
          </mat-selection-list>
          </fieldset></div>
<div>
          <fieldset style="border: 1px lightgrey solid ; margin-right:20vw">
            <legend style="border: 1px lightgrey solid;margin-left: 1em; padding: 0.2em 0.8em ">CC</legend>  
          <mat-selection-list class = "recips" [multiple]="false">
            <mat-list-option *ngFor="let emailcc of this.configSvc.nodeds[0].email[0]?.cc" [value]="emailcc">
             {{emailcc}}
            </mat-list-option>
          </mat-selection-list>
</fieldset></div>
        </span>

      </span>