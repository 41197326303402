

<div *ngIf="this.configSvc.activetype!='set' && this.configSvc.activetype!='choice'"  fxLayout="row"  >
  
    <mat-card class= "browsercardlrg">  
      <table mat-table [dataSource]="this.tagerSvc.choiceset">
        <tr mat-header-row *matHeaderRowDef="browseColumns"></tr>
           <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
    
             <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
           
             ></tr>
             
  
             <ng-container matColumnDef="IconColumn">
              <th mat-header-cell *matHeaderCellDef class ="columnicon"> </th>
              <td mat-cell *matCellDef="let element" class = "columnicon">
                <span *ngIf="element.haschild">  
                  <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >expand_more </i>
                  </span>
              </td>
            </ng-container>

    
               <!-- Name Column -->
               <ng-container matColumnDef="NameColumn">
                <th mat-header-cell *matHeaderCellDef class ="columnname">ChoiceSet Name </th>
             
                <td mat-cell *matCellDef="let element" class = "columnname">
                      <a  *ngIf="element.type == 'set'"
                      (click)="clickedSet(element)"
                      [routerLink]="['/toolbard/prompts']"
                      [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level}"
                     
                      > 
                      <span>
                      {{element.name}}</span> 
                </a>
                    
                
                  </td>
              </ng-container>

                   

                 <!-- AddedBy Column -->
                 <ng-container matColumnDef="AddedBy">
                  <th mat-header-cell *matHeaderCellDef>Added By </th>
                  <td mat-cell *matCellDef="let element" class = "alignresults">
                        <div  *ngIf="element.type == 'set'"> 
                       
                        {{element.ab}}
                        </div>
                      
                  
                    </td>
                </ng-container>

                      <!-- AddedOn Column -->
                      <ng-container matColumnDef="AddedOn">
                        <th mat-header-cell *matHeaderCellDef>Added On </th>
                        <td mat-cell *matCellDef="let element" class = "alignresults">
                              <div  *ngIf="element.type == 'set'"> 
                              
                              {{element.ao}}
                              </div>
                            
                        
                          </td>
                      </ng-container>
    
              <!--Node Column -->
            <ng-container matColumnDef="DescColumn" >
              <th mat-header-cell *matHeaderCellDef class ="columndesc"> Description</th>
    
              <td mat-cell *matCellDef="let element" class = "columndesc">
  
            
              <div *ngIf="element.type == 'set'"> 
              
              {{element.desc}} </div>
              
             
  
         
  
    <a *ngIf="element.type == 'node'" fxFlexFill
             
    [routerLink]="['/toolbard/nodeconsole']"
    [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
   
    >
  
    <div *ngIf="element.type == 'node'" (click)="this.configSvc.currentrow=element;this.clickNode(element)">
    <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
     {{element.name}} </div>
   
    </a>
  
  
  
    <div *ngIf="element.type == 'tag'" class = "hover" 
    (click)="this.configSvc.currentrow=element;this.clickTag(element)"
    [style.background]=" this.configSvc.hltag.indexOf(element) != -1 ? 'lightblue' : ''"
    >
    <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >{{element.icontype}} </i>
    
     {{element.formitem}} </div>
  
     
  
  
  
              
              
           
  
            
  
              <div *ngIf="element.type == 'form' || element.type == 'tagerseq' " class = "formhover" 
              (click)=" this.getActiveform(element) "
              
              [style.background]=" this.configSvc.hlitem.indexOf(element) != -1 ? 'lightblue' : ''"
              > {{element.name}}  
              <span *ngIf="element.status == 1" matTooltip="{{element.track}}" > 
                <i class="material-icons status-inprogress" >history</i>  
              </span>
              <span *ngIf="element.status == 2" matTooltip="{{element.track}}" > 
                <i class="material-icons status-trkcompleted">check_circle_outline </i>
              </span>
  
              <span *ngIf="element.status == 3" matTooltip="{{element.track}}" > 
                <i class="material-icons status-tagercompleted">check_circle_outline </i>
              </span>
           
            
            </div>
  
            <div *ngIf="element.type == 'tageritem'" class = "formhover"
            (click)=" this.getTageritem(element);"
            [style.background]=" this.configSvc.hlitem.indexOf(element) != -1 ? 'lightblue' : ''"
            > {{element.name}}
            <span *ngIf="element.tagged== true && element.type == 'file'" class="fa-1.7x"><span class="fa-layers fa-fw">
              <span ><i  class="material-icons file-tagged"  title="FileTagged">bookmark</i>
              </span></span></span>  
            <span *ngIf="element.cmt== true">
              <i class="material-icons" style="font-size: 15px; color : orange" >chat</i></span>  
  
            </div>
  
    
               
  
              
           
  
                </td>
            </ng-container>
  
             <!-- Status Column -->
             <ng-container matColumnDef="StatusColumn">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                      <span *ngIf="element.ed == true">
                              <span class = "fileEdit">
                                <i class="material-icons icon-locked" title="Locked for Editing">lock</i>
                             </span>
                      </span>
               
  
    
  
                                  <span *ngIf="element.kanbantagged== true">
                                      <i class="material-icons status-kanban"   [ngStyle]="{'color': element.kanbancolor}" >view_column </i>
                                    </span>
                          
                                  
              </td>
         </ng-container>
    
    
        </table>
        <span (click)="deselect(this.configSvc.activetype)" class = "deselect"></span>
      </mat-card>
    
    

  </div>

 <div *ngIf="this.configSvc.activetype=='set' && this.tagerSvc.setready"  fxLayout="row"  >
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">create_new_folder</mat-icon>
        Prompts
      </ng-template>
  <mat-card>  
    
      <div class='row'>
        <div class='column'>
          <div>
    <table mat-table [dataSource]="this.tagerSvc.choiceset">
      <tr mat-header-row *matHeaderRowDef="choiceColumns"></tr>
         <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
  
           <tr mat-row *matRowDef="let selectedrow; columns: choiceColumns; let i = index;"
         (click)="this.clickPrompt(selectedrow)"
         [style.background]=" this.prompt.indexOf(selectedrow) != -1 ? 'lightblue' : ''"
           ></tr>
           

       

  
             <!-- Name Column -->
             <ng-container matColumnDef="NameColumn">
              <th mat-header-cell *matHeaderCellDef class ="columnname">Prompt </th>
           
              <td mat-cell *matCellDef="let element" class = "columnname">
                    
                    <span>
                    {{element.name}}</span> 

                  
              
                </td>
            </ng-container>

                    

            

               <!-- AddedBy Column -->
               <ng-container matColumnDef="AddedBy">
                <th mat-header-cell *matHeaderCellDef>Added By </th>
                <td mat-cell *matCellDef="let element" class = "alignresults">
                     
                      {{element.ab}}
                     
                    
                
                  </td>
              </ng-container>

                    <!-- AddedOn Column -->
                    <ng-container matColumnDef="AddedOn">
                      <th mat-header-cell *matHeaderCellDef>Added On </th>
                      <td mat-cell *matCellDef="let element" class = "alignresults">
                           
                            {{element.ao | date: 'medium'}}
                          
                          
                      
                        </td>
                    </ng-container>
  
         
  
      </table></div>
      </div>

     
        <div class='column' *ngIf="this.promptselected">
          <form [formGroup]="promptFG">

            <div>
            <div><mat-label><b> Prompt Title</b></mat-label></div>
              <mat-form-field appearance="outline"   floatLabel="always" >
                <input matInput
                
                formControlName="prompt" matInput value={{this.promptFG.value.prompt}}
                
                
                >
               </mat-form-field>

             


            </div>

          <div>
           <div> <mat-label><b>Prompt </b> </mat-label></div>
            <mat-form-field appearance="outline"   class = "textarea">
              <textarea
              
              formControlName="promptdesc" matInput value={{this.promptFG.value.promptdesc}}
              cdkTextareaAutosize
              cdkAutosizeMinRows=10
              cdkAutosizeMaxRows=15
              
              ></textarea>
             </mat-form-field>
          </div>

          <div>

            <button mat-raised-button color="primary"  [disabled]= " !this.promptFG.dirty" (click)="modifyPrompt()">Save Changes</button>
            <button mat-raised-button color="primary"  (click)="deletePrompt()">Delete Prompt</button>
          </div>
        </form> </div>
      </div>
       
      <span (click)="deselect(this.configSvc.activetype)" class = "deselect"></span>
    </mat-card>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">create_new_folder</mat-icon>
       Prompt Set Details
      </ng-template>
      <app-editset></app-editset>
      </mat-tab>

   </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="folder-icon">create_new_folder</mat-icon>
         Prompt Editors
        </ng-template>
        <app-teamsecurity></app-teamsecurity>
        </mat-tab>

    

        </mat-tab-group>
 </div>

 <div *ngIf="this.configSvc.activetype=='choice'"  fxLayout="row"  >
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="folder-icon">create_new_folder</mat-icon>
        List
      </ng-template>
 
  <mat-card class= "browsercardlrg">  
    <table mat-table [dataSource]="this.tagerSvc.choiceset">
      <tr mat-header-row *matHeaderRowDef="choiceColumns"></tr>
         <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
  
           <tr mat-row *matRowDef="let selectedrow; columns: choiceColumns; let i = index;"
         
           ></tr>
           

           <ng-container matColumnDef="IconColumn">
            <th mat-header-cell *matHeaderCellDef class ="columnicon"> </th>
            <td mat-cell *matCellDef="let element" class = "columnicon">
             <span *ngIf="element.haschild"> <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >
              expand_more</i></span>
            </td>
          </ng-container>

  
             <!-- Name Column -->
             <ng-container matColumnDef="NameColumn">
              <th mat-header-cell *matHeaderCellDef class ="columnname">Choice Value </th>
           
              <td mat-cell *matCellDef="let element" class = "columnname">
                    <a  *ngIf="element.type == 'choice'"
                    [routerLink]="['/toolbard/prompts']"
                    [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level}"
                   
                    > 
                    <span>
                    {{element.name}}</span> 
              </a>
                  
              
                </td>
            </ng-container>

                  <!-- Desc Column -->
                  <ng-container matColumnDef="DescColumn">
                    <th mat-header-cell *matHeaderCellDef class ="columnname">Description</th>
                 
                    <td mat-cell *matCellDef="let element" class = "columnname">
                          <a  *ngIf="element.type == 'choice'"
                          [routerLink]="['/toolbard/prompts']"
                          [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level}"
                         
                          > 
                          <span>
                          {{element.desc}}</span> 
                    </a>
                        
                    
                      </td>
                  </ng-container>

               <!-- AddedBy Column -->
               <ng-container matColumnDef="AddedBy">
                <th mat-header-cell *matHeaderCellDef>Added By </th>
                <td mat-cell *matCellDef="let element" class = "alignresults">
                     
                      {{element.ab}}
                     
                    
                
                  </td>
              </ng-container>

                    <!-- AddedOn Column -->
                    <ng-container matColumnDef="AddedOn">
                      <th mat-header-cell *matHeaderCellDef>Added On </th>
                      <td mat-cell *matCellDef="let element" class = "alignresults">
                           
                            {{element.ao | date: 'medium'}}
                          
                          
                      
                        </td>
                    </ng-container>
  
         
  
      </table>
      <span (click)="deselect(this.configSvc.activetype)" class = "deselect"></span>
    </mat-card>
    </mat-tab>

    <mat-tab>
    
          <ng-template mat-tab-label>
            <mat-icon class="folder-icon">create_new_folder</mat-icon>
            List Details
          </ng-template>
          <app-editlist></app-editlist>
    </mat-tab>


</mat-tab-group>
 </div>
  