import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-companyinfo',
  templateUrl: './companyinfo.component.html',
  styleUrls: ['./companyinfo.component.css']
})
export class CompanyinfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
