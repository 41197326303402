import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {TagerService} from '../../services/tager.service';

@Component({
  selector: 'app-editset',
  templateUrl: './editset.component.html',
  styleUrls: ['./editset.component.css']
})
export class EditsetComponent implements OnInit {
  editsetFG: FormGroup;
  folderrenamed = false;
  newfoldervalue = '';
  
  constructor(private fb: FormBuilder, public tagerSvc: TagerService) { }

  ngOnInit(): void {

    console.log('setdetails'+JSON.stringify(this.tagerSvc.setdetailsds))
    this.editsetFG = this.fb.group({
      setname: this.tagerSvc.setdetailsds[0].name,
      setid: this.tagerSvc.setdetailsds[0]._id,
   
   
   });

   console.log('setdetails id '+this.editsetFG.value.setid)

   this.onChanges();
  }

   
 onChanges(): void {
  this.editsetFG.valueChanges.subscribe(val => {
  console.log('the value has changed to '+JSON.stringify(val))
  this.newfoldervalue = this.editsetFG.get('setname').value;
  
  });
}
  

  reset() {
    this.editsetFG.reset();
    
  }

  renameSet(setid, newsetname){
    console.log('rename set here')
    console.log('setid is '+setid)
    console.log('newsetname is '+newsetname)
  }

  deleteSet(setid){
    console.log('deleting set here')
    console.log('setid is '+setid)
  }

}
