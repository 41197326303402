import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, FormArray, Validators, NgForm} from '@angular/forms';

@Component({
  selector: 'app-usersecurity',
  templateUrl: './usersecurity.component.html',
  styleUrls: ['./usersecurity.component.css']
})
export class UsersecurityComponent implements OnInit {
  public newuser = false;
  public userselected = false;
  finduserclicked = false;
  
  edituserFG: FormGroup;
  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit() {
    this.edituserFG = this.fb.group({
      usersearch: [''],
      username:  [''],
     
   });
  }


  

  public addteamUser(type, user){
    console.log('type is currently: '+type)
    console.log('adding user is currently: '+user)
  if(type == 'team'){
    console.log('this is a create team function')
    this.configSvc.usersadded.push(user);
  }
  if(this.configSvc.activetype == 'editteam'){
    console.log('this is a edit team function')
  this.configSvc.addteamUser(this.configSvc.activeitemid, user)
}

if(type == 'addnodeteam'){
  console.log('this is a create nodeteam function')
  this.configSvc.usersadded.push(user);
}
if(this.configSvc.activetype == 'editnodeteam' || this.configSvc.activetype == 'nodeteam'){
  console.log('this is a edit team function')
this.configSvc.addnodeteamuser(this.configSvc.activeitemid, user)

}

this.edituserFG.markAsPristine()}

  public removeUser(){
    console.log('activetype is '+this.configSvc.activetype)
      
          const index = this.configSvc.mgrsadded.indexOf(this.configSvc.highlightedMgrRow)
          this.configSvc.mgrsadded.splice(index, 1);
        this.configSvc.removeUser(this.configSvc.activeitemid, this.configSvc.highlightedMgrRow)
        this.userselected = false;
        
  }

  usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
  console.log('usernameFilter value is currently set to '+this.configSvc.filtereduser)

  }
}


}

  
