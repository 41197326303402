import { Component, OnInit } from '@angular/core';
import {FlowService} from '../../services/flow.service';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-flowstatus',
  templateUrl: './flowstatus.component.html',
  styleUrls: ['./flowstatus.component.css']
})
export class FlowstatusComponent implements OnInit {

  public flowprocess =[]
  public activeflow = []
  constructor(public flowSvc: FlowService, public configSvc: ConfigService) { }

  ngOnInit() {
    console.log('this is the ACTIVE FLOW '+JSON.stringify(this.configSvc.nodeds[0]))
    console.log('this is the ACTIVE FLOW  name'+JSON.stringify(this.configSvc.nodeds[0].name))
    console.log('this is the  FLOW '+JSON.stringify(this.configSvc.nodeds[0].flow))
    this.flowprocess = this.configSvc.nodeds[0].flow
    this.activeflow = this.configSvc.nodeds
    console.log('this is the flow process'+JSON.stringify(this.flowprocess))
    console.log('this is the activeflow'+JSON.stringify(this.activeflow))
  }

}
