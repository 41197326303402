import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-smartfolders',
  templateUrl: './smartfolders.component.html',
  styleUrls: ['./smartfolders.component.css']
})
export class SmartfoldersComponent implements OnInit {

  public reset: any[] = [{}];
  activeColumns = ['type', 'name']
  public itemsadded = [];
  public hlsubitem = [];
  // public itemselected = false;
  public type = '';
  public subitemselected = false; 
  constructor(public configSvc: ConfigService) { }

  ngOnInit(): void {

    console.log('initing smart folders with these sub items added ')
    console.log(' sub items added '+this.configSvc.subfolders)
   // this.configSvc.subfolders = this.configSvc.nodeds[0].subfolders
    console.log(' this is the subitems '+JSON.stringify(this.configSvc.subfolders))
  }

  public onRecreate(){
    this.reset[0] = {}
  }

  public selectSubitem(selecteditem, index){
    
    this.hlsubitem = [];
    this.hlsubitem.push(selecteditem)
    console.log('cklciked item' +JSON.stringify(this.hlsubitem[0]))
    this.subitemselected = true; 
    this.configSvc.getSubitem(this.hlsubitem[0].linkid,'design' )
    this.type = this.hlsubitem[0].type
    // this.configSvc.linknodeready = true
    
    this.onRecreate()
  }

}
