import { Component, OnInit, Inject } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter,CdkDragExit} from '@angular/cdk/drag-drop';

import {Kanbanmodel, kprops} from '../../model/Kanbanmodel';
import {TagerService} from '../../services/tager.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';


export interface DialogData {
 
  kiname: string;
  
  kiworker: string;
  kitime: number;
  kiid: string;
  track: string;
  
 
  kidata: [];
}


@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanComponent implements OnInit {

  public docarray = [];
  public kanbansorted = []; 
  public kanbaninitial = [];
public current = []
public lasttrack = 0
public lastrackname = '';
public firsttrackname = '';
public status = '';
public reset: any[] = [{}];

  constructor(public configSvc: ConfigService, public tagerSvc: TagerService,  
    public auth: AuthenticationService,  public dialog: MatDialog) { }

  ngOnInit() {

    if (this.configSvc.activetype == 'fold' && !this.configSvc.quickadd){
  console.log('this is the current kanban propers from the folder: '+JSON.stringify(this.configSvc.folderdetailsds.kanbanprops))
  this.configSvc.activekanban = this.configSvc.folderdetailsds.kanbanprops}

  

  if (this.configSvc.activetype != 'smartfolder' && this.configSvc.quickadd){
    console.log('this is the current kanban propers from the quicadd process: '+JSON.stringify(this.configSvc.activekanban ))
   }


  if (this.configSvc.activetype == 'smartfolder')
{console.log('this is an ewditform folder initing kanban')
  this.configSvc.activekanban = this.configSvc.nodeds[0].kanbanprops}
 

 if (this.configSvc.activetype == 'nodetab' && this.configSvc.nodeds[0].nodetabkanban)
 {console.log('this is an nodetab that hads a kanban initing kanban')
   this.configSvc.activekanban = this.configSvc.nodeds[0].kanbanprops
  
   console.log('this is the  number of tracks'+this.configSvc.activekanban.length);
   console.log('this is the  number of tabs'+this.configSvc.nodeds[0].stepper.length);
   let tracks: number =this.configSvc.activekanban.length
   let tabs: number = this.configSvc.nodeds[0].stepper.length
   let tracktabs: number = (tracks-1)*tabs
   console.log('this is the  number of tracktabs'+tracktabs);
  }
// console.log('this is the total kanban '+this.configSvc.activekanban.length-1*this.configSvc.nodeds[0].stepper.length);

  }
  

  

  public sortKanban(){
    
    
  //  console.log('this is the current kanbansorted'+JSON.stringify(this.kanbansorted))
this.kanbansorted = this.configSvc.activekanban.sort((a, b) => Number(a.trackorder) - Number(b.trackorder));
this.lasttrack = this.kanbansorted.length-1

  this.lastrackname = this.kanbansorted[this.lasttrack].track
  this.firsttrackname = this.kanbansorted[0].track

  
return this.kanbansorted
 
}


  
  drop(event: CdkDragDrop<string[]>) {
    console.log('currentindex before drop: '+ JSON.stringify( event.currentIndex))
   
   
    moveItemInArray(this.configSvc.teamds, event.previousIndex, event.currentIndex);
    console.log('currentindex after drop: '+ JSON.stringify( event.currentIndex))
   
  }

  drop2(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      console.log('monving item in same array');

      console.log('current container data= '+event.container.data);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      
      console.log('this is the previous conmtainer data'+event.previousContainer.data)
      console.log('currentindex before drop: '+ JSON.stringify( event.currentIndex))
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    console.log('currentindex after drop: '+ JSON.stringify( event.currentIndex))
  }

  entered(event: CdkDragEnter<string[]>) {
    console.log('Entered', event.item.data);
   }
   exited(event: CdkDragExit<string[]>) {
     console.log('Exited', event.item.data);
   }


  

   onKanbanitemDrop(event: CdkDragDrop<Kanbanmodel[]>) {
    // In case the destination container is different from the previous container, we
    // need to transfer the given task to the target data array. This happens if
    // a task has been dropped on a different track.
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  get tracknames(): string[] {
    if (this.configSvc.activetype != 'smartfolder'){
    return this.configSvc.folderdetailsds.tracks}
    if (this.configSvc.activetype == 'smartfolder'){
      return this.configSvc.nodeds[0].tracks}

   // return this.configSvc.currkanban.map(track2 => track2.kanbanprops.track);
  }

  onTaskDrop(event: CdkDragDrop<string[]>) {
    console.log('new container is'+event.container.id)
    console.log('previous container is'+event.previousContainer.id)
    console.log('prev location in kanban'+event.previousIndex)
    console.log('new location in kanban'+event.currentIndex)
    console.log('moving this data'+JSON.stringify(event.previousContainer.data[event.previousIndex]))
    this.current = event.container.data
    console.log('container data'+this.current)
    // In case the destination container is different from the previous container, we
    // need to transfer the given task to the target data array. This happens if
    // a task has been dropped on a different track.
    if (event.previousIndex === event.currentIndex){
      console.log('have not moved do nothing')
    }
    if (event.previousContainer === event.container) {
      
      console.log('pre container is'+event.previousContainer.id)
      
      console.log('moving on task drop')
      console.log('on moving doc up down  prev index'+event.previousIndex)
    console.log('on moving doc up down   current index'+event.currentIndex)

    console.log('on moving doc up down   current data'+JSON.stringify(event.container.data));
     // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    if (event.previousIndex !== event.currentIndex){
    this.configSvc.kanbanready = false
 
        this.tagerSvc.movePosition(event.previousContainer.data[event.previousIndex], event.previousContainer.id, event.currentIndex,
          this.current[event.previousIndex].kitype) 
          
          this.reset[0] = {}
        }
      }



    
      
      if (event.previousContainer !== event.container)  {
      console.log('moving documents between tracks')
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
        console.log('task drop prev index'+event.previousIndex)
        console.log('task drop current index'+event.currentIndex)
        let movedirection: String;
        let previndex: number = event.previousIndex
        let currindex: number = event.currentIndex
        let moveby: number;
        let datatomove: [string];
        if(event.previousIndex > event.currentIndex){
          console.log('this is a move backwards by ')
          movedirection = 'backwards'
          moveby = previndex-currindex
     
          console.log('movedirection is '+movedirection);
          console.log('moveby is '+moveby);
        }
        if(event.previousIndex < event.currentIndex){
          console.log('this is a move forwards by ')
          movedirection = 'forwards'
          moveby = currindex-currindex
          console.log('movedirection is '+movedirection);
        
          console.log('moveby is '+moveby);
        }
       
        console.log('task drop previous data'+JSON.stringify(event.previousContainer.data))
       
        console.log('task drop previous container id'+JSON.stringify(event.previousContainer.id))
        console.log('task drop current data'+JSON.stringify(event.container.data));
        console.log('event data'+event)
       // this.current = event.container.data
        console.log('container data before moving'+JSON.stringify(this.current))
       
        console.log('kirord '+this.current[0].kiord);
        console.log('task drop kanban containers'+JSON.stringify( this.configSvc.activekanban.length));
        console.log('event container id '+event.container.id)
        console.log('new container is'+event.container.id)
        console.log('lastrackname is'+this.lastrackname);
        if(event.container.id == this.lastrackname){this.status = '2'}
        if(event.container.id == this.firsttrackname){this.status = '0'}
        if(event.container.id != this.lastrackname && event.container.id != this.firsttrackname){this.status = '1'}
        console.log('previous container is'+event.previousContainer.id)
var lasttrack = this.configSvc.activekanban.length-1
    console.log('total number of tracks is '+JSON.stringify( this.configSvc.activekanban.length));
    console.log('last trackorder is '+lasttrack);
    console.log('last trackidis '+this.configSvc.activekanban[lasttrack]);
    datatomove = [event.container.data[event.currentIndex]]
    console.log('data moving forward '+JSON.stringify(datatomove));
    this.configSvc.kanbanready = false
    var newactivetrack 
    console.log('activetype is '+this.configSvc.activetype);
    if(this.configSvc.activetype == 'fold'){
    console.log('new active track of node'+JSON.stringify(this.configSvc.folderdetailsds.kanbanprops[event.container.id].track))
    newactivetrack = this.configSvc.folderdetailsds.kanbanprops[event.container.id].track}
    if(this.configSvc.activetype != 'fold'){
    if(this.configSvc.nodeds[0].type == 'nodetab' && this.configSvc.nodeds[0].nodetabkanban){
      console.log('new active track '+JSON.stringify(this.configSvc.nodeds[0].kanbanprops[event.container.id].track))
      newactivetrack = this.configSvc.nodeds[0].kanbanprops[event.container.id].track}
    }
        this.tagerSvc.moveKanban(JSON.stringify(datatomove), event.previousContainer.id, 
          event.container.id, this.status, this.current[0].kitype, this.current[0].kikanban,  event.currentIndex, newactivetrack)
          this.reset[0] = {}

    }

  }

  onTrackDrop(event: CdkDragDrop<string[]>) {
    console.log('onTrackDrop  prev index'+event.previousIndex)
    console.log('onTrackDrop current index'+event.previousIndex)

    console.log('onTrackDrop current data'+JSON.stringify(event.container.data));
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

 

  openDialog(activekanban, track): void {


    console.log('active kanban '+JSON.stringify(activekanban));
    console.log('active track '+track);
    this.configSvc.finduserdataset = [];
    
    const dialogRef = this.dialog.open(KanbanPopup, {
      width: '20vw', height: '40vh',
      data: {kidata: [activekanban], track: track, kiname: activekanban.kiname, kanbanitemid: activekanban._id, kiworker: activekanban.kiworker, kiid: activekanban.kiid, kitime: parseInt(activekanban.kitime)}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

}

@Component({
  selector: 'app-kanbanpopup',
  templateUrl: 'kanbanpopup.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanPopup implements OnInit{

  kanbanFG: FormGroup;
  finduserclicked = false;
  userselected = false;
  usersearch: string;
  groupmembers = [];
  hluser = [];

  constructor(
    public dialogRef: MatDialogRef<KanbanPopup>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private _formBuilder: FormBuilder,
    public configSvc: ConfigService, public tagerSvc: TagerService) {}

    ngOnInit() {
console.log('initing formgroup')
    this.kanbanFG = this._formBuilder.group({
      kiname: [this.data.kiname, Validators.required],
     kitime: [this.data.kitime, Validators.required],
      kiworker: [this.data.kiworker, Validators.required],
      kiid: [this.data.kiid, Validators.required],
      
 
    });

   
    for (let i = 0; i < this.configSvc.teamdetailsds.mbr.length; i++) {
      this.groupmembers.push(this.configSvc.teamdetailsds.mbr[i])
    console.log('finished pushing mbr into users added '+JSON.stringify(this.groupmembers))
  }

}

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateKanitem(folderid,kidata, kiworker, kitime, track){
  
    console.log('current worker is'+kiworker)
     this.kanbanFG.value.kiworker = kiworker;
     console.log('current time is'+kitime)
     this.kanbanFG.value.kiworker = kiworker;
    console.log('finished')
    this.tagerSvc.updateKanitem(folderid, kidata, kiworker, kitime, track)
    this.dialogRef.close();
    
  }

  
  public  selectUser(user) {
    this.hluser = [];
     console.log('the user  is set to '+user);
     this.hluser.push(user);
     this.kanbanFG.value.kiworker = user;
     
      }

 

  /*
  addFolder(name, indextype){
    this.configSvc.addFolder(name, 
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, indextype);
      this.dialogRef.close();
  }
  */

  

  public findUser(username) {
    this.finduserclicked = true;
    this.configSvc.findUser(username);
  
  }


}
