
    <mat-card class = "build-container">

        <mat-card-header>
          <div class = "cardhdr">
            <div class = "iconbox">
          <mat-card-title  > 
            
            <i class="material-icons icon-team"  title="Team Builder ">group</i> </mat-card-title>
          </div>
    
          <div class = "titlebox">
            <mat-card-title  > 
              <h4 >Team Builder  </h4>
              </mat-card-title>
          </div>
          <div class = "closebox">
            <mat-card-title  > 
              
              <a routerLink="/toolbard/home"> <i class="material-icons icon-tagteam"  title="TagTeam">close</i></a>          
           </mat-card-title>
            </div>
            </div>
    
        </mat-card-header>
                 <span *ngIf="this.configSvc.teamtype=='node'"><mat-card-title>Node Team Setup</mat-card-title></span> 
                  <mat-horizontal-stepper [linear]="isLinear" #stepper>
                 
                 
                    <mat-step [stepControl]="step1">
      <form [formGroup]="teamfileFG">
              <ng-template matStepLabel>
                     Team Name
                     </ng-template>
          <mat-form-field appearance="outline" [style.fontSize.px]=16 [style.font.weight]=200 >
              <mat-label>Create a new Team</mat-label>
              <input formControlName ="teamname"  matInput placeholder="Team Name" >
                  <i  class="material-icons icon-group" matSuffix style="color:black">group</i>
              </mat-form-field>
  <div>
              <mat-form-field class = "desc" appearance="outline" [style.fontSize.px]=16 [style.font.weight]=200>
                <mat-label>Description</mat-label>
                <input formControlName ="teamdescription"  matInput placeholder="Description" >
                   
                </mat-form-field></div>
    
              <div>
                     
                  <button  mat-raised-button color="primary" (click)="this.addMyself();" mat-button matStepperNext>Next</button>
                  </div>
              </form>
              </mat-step>
  
              <mat-step [stepControl]="step2">
                  <ng-template matStepLabel>Security </ng-template>
                    
                         <app-teamsecurity></app-teamsecurity>   
                         <div *ngIf="this.configSvc.activetype == 'team'">     
                         <button mat-raised-button color="primary"  mat-button matStepperPrevious>Back</button>
                         <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                    
                  </mat-step>
  
                  <mat-step [stepControl]="step3">
                          <form [formGroup]="teamfileFG">
                                  <ng-template matStepLabel>
                                         Design Icon
                                         </ng-template>
                                         <div>
                                          <span>
                                            <app-iconcategories></app-iconcategories></span>
                                                <span class ="colorbox">
                                                <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                                              </span>
                                          
                                              <button mat-raised-button color="primary"  mat-button matStepperPrevious>Back</button>
                                            <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
                                          </div>
                                         </form>
                      </mat-step>
  
                    
  
                      <mat-step [stepControl]="step4">
                              <form [formGroup]="teamfileFG">
                                      <ng-template matStepLabel>
                                             Create Team 
                                             </ng-template>
                                           
                                             
                                             <div fxLayout="column" fxLayoutAlign="space-around stretch" class="mat-body-1" >
             
              
             
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                              <mat-label >TeamName</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamname}}>
                                              </mat-form-field>
  
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                                <mat-label >TeamDescription</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamdescription}}>
                                                </mat-form-field>
                                           
                                            
                                            
                                                  <mat-label>Managers</mat-label>
                                              <ul>
                                                  <li *ngFor="let managers of this.configSvc.mgrsadded">
                                                    {{managers.inboxemail}}
                                                  </li>
                                               </ul>

                                               <mat-label>Users</mat-label>
                                               <ul>
                                                   <li *ngFor="let users of this.configSvc.usersadded">
                                                     {{users.inboxemail}}
                                                   </li>
                                                </ul>
                                          
                                           
                                               <mat-label>Icon</mat-label>
                                               <div>
                                                 <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || this.configSvc.teamdetailsds?.clr}" >{{this.configSvc.chosenIcon}} </i>
                                                
                                                </div>
    </div>
  
    <span *ngIf="this.configSvc.teamtype=='folder'">
   <button mat-raised-button color="primary"  (click)="this.configSvc.createTeam(false, 'team', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, false)
        ; this.teamfileFG.reset();" mat-button matStepperNext>Create Folder Team</button></span>
        
        <span *ngIf="this.configSvc.teamtype=='node'">
          <button mat-raised-button color="primary"  (click)="this.configSvc.nodeTeam('nodeteam', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, false)
          ; this.teamfileFG.reset();" mat-button matStepperNext>Create Node Team</button>
        </span>
  
  
                <div>
                      <button mat-raised-button color="primary" mat-button matStepperPrevious>Back</button>
                  </div>
                                             </form>
                          </mat-step>
  
                        
          </mat-horizontal-stepper>
        </mat-card>
  