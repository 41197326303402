import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';
import {TagerService} from '../../../services/tager.service';
import {TagService} from '../../../services/tag.service';
import {FlowService} from '../../../services/flow.service';
import { NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import {UploadService} from '../../../services/upload.service';
import { PrefsService } from 'src/app/services/prefs.service';
import {SidenavService} from '../../../services/sidenav.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';


export interface DialogData {
 
  name: string;
  type: string;
}



@Component({
  selector: 'app-toolbartools',
  templateUrl: './toolbartools.component.html',
  styleUrls: ['./toolbartools.component.css']
})
export class ToolbartoolsComponent implements OnInit {

  constructor(public configSvc: ConfigService, public dialog: MatDialog, public sidenavSvc: SidenavService) { }

  ngOnInit() {
    
  }

  getrootToolbars(){
  


    this.sidenavSvc.toggle('navigation')
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sidenavSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
    
  }


  openToolbar(toolbar): void {

  this.configSvc.nodeds[0] = toolbar
    console.log('highlighted node is '+JSON.stringify(this.configSvc.nodeds))
    console.log('popup name is'+toolbar.name)
    console.log('popup type is'+toolbar.type)
var popupheight = '80vh'
var popupwidth = '80vw'
this.configSvc.activetype = 'fold'
  this.configSvc.activeitemid = this.configSvc.currentfolder
  this.configSvc.activename = this.configSvc.currentfoldername
  this.configSvc.hlitem = []
  this.sidenavSvc.close();
 
    const dialogRef = this.dialog.open(ToolbarPopup, {
      width: popupwidth, height: popupheight,
      data: {name: toolbar.name, type: toolbar.type, desc: toolbar.desc}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
  
    });
  }

}


@Component({
  selector: 'app-toolbarpopup',
  templateUrl: 'toolbarpopup.html',
  styleUrls: ['./toolbartools.component.css']
})

export class ToolbarPopup implements OnInit{
  @ViewChild('stepper') stepper: MatStepper;
public isLinear = true;
  public verifyindexes = false;
  public indexes = false;
  public currentAssigntype = 'toolbar'
  public imageloaded
  public actualimage
  public imageready = false
  public url;
 public stepIndex =0
 public hlsubitem = [];
  haslabel = false;
  hasdefault = false;
  // rowsize = false;
  defaulttoggle = false;
  defaultslider =false;
  public previewArray: FormArray;
  public previewFG: FormGroup;
  public newpropvalues = [];
  public stpcnt: string;
  public hltreepath = [];
  public hlchoiceset = [];
  clickchoiceset = false;
  public sorted = [];
  treeColumns = ['Name']
  foldertypes = ['none', 'coloredfolder', 'bookmarks' ]
  chosentype: string = 'none'
  public createtype = 'manual';
  public csvRecords: any[] = [];
  public csvprocessed = 0;
  public favtool = false;
  public removefavs = false;
  public assigned = false;
  public fileattach = false;
  public deadline = false;
  public deadlineval: Date;
  public activeColumns = ['type', 'name']

  constructor(
    public dialogRef: MatDialogRef<ToolbarPopup>,private ngxCsvParser: NgxCsvParser, private uploadSvc: UploadService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private authSvc: AuthenticationService, public flowSvc: FlowService,
    public configSvc: ConfigService, private fb: FormBuilder, public tagerSvc: TagerService, public tagSvc: TagService,
    public prefSvc: PrefsService) {}

    
  ngOnInit() {
    
    this.configSvc.csvprocessed = 0;
    if(this.configSvc.folderdetailsds.tagged){
      this.indexes = true
    }
    this.previewFG = new FormGroup({formArrayName: this.fb.array([])})
    console.log('building form preview now for '+this.configSvc.nodeds[0].type);
    if(this.configSvc.nodeds[0].type == 'node' || this.configSvc.nodeds[0].type == 'nodeimage' || this.configSvc.nodeds[0].type == 'kanban' ||
     this.configSvc.nodeds[0].type == 'foldertags' || this.configSvc.nodeds[0].launcher == 'node' ||
     this.configSvc.nodeds[0].type == 'smartfolder' || this.configSvc.nodeds[0].type == 'basicflow'
     ){
      this.buildstepitems();
      this.detectNodechanges();
    }
  
      if(this.configSvc.nodeds[0].type == 'wizard' || this.configSvc.nodeds[0].launcher == 'wizard' 
      || this.configSvc.nodeds[0].type == 'nodetab' || this.configSvc.nodeds[0].type == 'smartfolder' || this.configSvc.nodeds[0].type == 'editstepperassign'
      || this.configSvc.nodeds[0].type == 'smartfoldertab' || this.configSvc.nodeds[0].type == 'edittabbedassign'){
        this.buildstepperitems()
    this.detectStepperchanges();
  }
  if(this.configSvc.nodeds[0].type == 'batchitems'){
    this.configSvc.subfolders = this.configSvc.nodeds[0].subfolders
    console.log('this is the subitems'+JSON.stringify(this.configSvc.subfolders))
  }

  }

  
   

  onNoClick(): void {
    this.dialogRef.close();
  }



      public createNode(formitem, formitemlabel, defaultvalue){
        console.log('creating string formitem is'+formitem)
        console.log('creating string formitemlabel is'+formitemlabel)

      //  this.configSvc.createNode( true, this.configSvc.nodeds[0]._id, 'node', this.configSvc.nodeds[0].name, formitem, 'string', this.haslabel,  formitemlabel, false, this.hasdefault, defaultvalue, 'text_format')
     

        this.dialogRef.close();
      }

      
      buildstepitems(){


        console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.nodeds));
        this.newpropvalues = this.configSvc.nodeds[0].stepitems
        console.log('this is the current newpropvalues: '+JSON.stringify(this.newpropvalues));
              
              this.previewArray = this.previewFG.get('formArrayName') as FormArray;
             Object.keys(this.configSvc.nodeds).forEach((i) => {
  
             console.log('this is the stepitem property for step'+i);
             console.log('this is the formstep array'+this.configSvc.nodeds[i].stepitems);
             Object.keys(this.configSvc.nodeds[i].stepitems).forEach((i2) => {
              this.stpcnt = i2;
              console.log('tthis is the item number in the step'+i2);
              console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[i].stepitems[i2].formitem));
             
         // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
             // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);
    
                this.previewArray.push( this.fb.group({
                  formstep: new FormControl({ value: i}),
                  eleord: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].eleord, emitEvent:false}),
                   formitem: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitem, emitEvent:false}),
                   label: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].label, emitEvent:false}),
                   formitemlabel: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitemlabel, emitEvent:false}),
                  itemvalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].itemvalue, disabled: false  }),
                   formtype: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formtype}),
                 formchoices: new FormControl({ value:this.configSvc.nodeds[i].stepitems[i2].formchoices}),
                choicesetid: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicesetid}),
                choicevalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicevalue, disabled: false }),
                hasdefault: new FormControl(this.configSvc.nodeds[i].stepitems[i2].hasdefault),
                rowsize: new FormControl(this.configSvc.nodeds[i].stepitems[i2].rowsize),
                treepath: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].treepath}),
                
                  }))
    
               //   console.log('preview array is now:'+JSON.stringify(this.previewArray));
                  
                  if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'choicelist'
                    && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)===null){ 
                      console.log('current choicesetid '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                      console.log('results of getting choicesetid from local storage'+localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
                    console.log('this property has a list associated with it and its not in cache, lets go and get it')
                 
                 this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'cache')

                  }

                  if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'radiogroup'
                    && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)===null){ 
                      console.log('current choicesetid '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                      console.log('results of getting choicesetid from local storage'+localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
                    console.log('this property has a list associated with it and its not in cache, lets go and get it')
                 
                 this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'cache')

                  }



                  if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'choicelist'
                    
                    && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)!==null){ 
                    console.log('check to see if the cache variable has the localstorage cache')
                    console.log('this is the choicesetid: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                 // this.tagerSvc.getFormChoices(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                 var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
                 console.log('this is the localdata im pushing'+localdata);
                 if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[i].stepitems[i2].choicesetid)){
                  this.authSvc.choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                  console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid);
                  console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                  this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
                }
                
                
                }

                if (
                this.configSvc.nodeds[i].stepitems[i2].formtype == 'radiogroup'
                  
                  && localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)!==null){ 
                  console.log('check to see if the cache variable has the localstorage cache')
                  console.log('this is the choicesetid: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
               // this.tagerSvc.getFormChoices(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
               var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
               console.log('this is the localdata im pushing'+localdata);
               if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[i].stepitems[i2].choicesetid)){
                this.authSvc.choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid);
                console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
              }
              
              
              }

                if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'treelist'){
                  this.hltreepath = this.configSvc.nodeds[i].stepitems[i2].treepath
                    console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
                   this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'treelist')
                   
                }


                

                })

                  
 })}

 buildstepperitems(){

  console.log('this is the stepper node data we are workin with: '+JSON.stringify(this.configSvc.nodeds[0]));
       
        this.previewArray = this.previewFG.get('formArrayName') as FormArray;
       Object.keys(this.configSvc.nodeds[0].stepper).forEach((i) => {

       console.log('this is the stepitem property for step'+i);
       console.log('this is the stepper array'+JSON.stringify(this.configSvc.nodeds[0].stepper[i]));
       Object.keys(this.configSvc.nodeds[0].stepper[i].tags).forEach((i2) => {
        console.log('tthis is the item number in the step'+i2);
        this.stpcnt = i2;
        console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[0].stepper[i].tags[i2].formitem));
     
   // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
       // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);

          this.previewArray.push( this.fb.group({
            step: new FormControl({ value: i, emitEvent:false}),
            stepitem:new FormControl({ value: i2, emitEvent:false}),
             property: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitem, emitEvent:false}),
             label: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].label, emitEvent:false}),
             formitemlabel: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formitemlabel, emitEvent:false}),
            itemvalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].itemvalue, disabled: false  }),
             formtype: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].formtype}),
        //   formchoices: new FormControl({ value:this.configSvc.nodeds[0].stepper[i2].tags[i2].formchoices}),
          choicesetid: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid}),
          choicesetname: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetname}),
          choicevalue: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].choicevalue, disabled: false }),
          hasdefault: new FormControl(this.configSvc.nodeds[0].stepper[i].tags[i2].hasdefault),
          rowsize: new FormControl(this.configSvc.nodeds[0].stepper[i].tags[i2].rowsize),
          treepath: new FormControl({ value:this.configSvc.nodeds[0].stepper[i].tags[i2].treepath, emitEvent:false}),
          
            }))

            console.log('preview array is now:'+this.previewArray);
            
            if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
              && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)===null){ 
               
               
              console.log('this property has a list associated with it and its not in cache, lets go and get it')
           // choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
          
           this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'cache')

            }

            if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepper[i].tags[i2].formtype=='radiogroup'
            && localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)!==null){ 
              console.log('check to see if the cache variable has the localstorage cache')
              console.log('this is the choicesetid: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
           // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].stepper[i].tags[i2]..choicesetid)
           var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid))
           console.log('this is the localdata im pushing'+localdata);
           if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)){
            this.authSvc.choicesetids.push(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
            console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid);
            console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
            this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
          }
          
          
          }

          if (this.configSvc.nodeds[0].stepper[i].tags[i2].formtype == 'treelist'){
            this.hltreepath = this.configSvc.nodeds[0].stepper[i].tags[i2].treepath
            this.clickchoiceset = true;
              console.log('this is it he initial hl stepper tree path '+JSON.stringify( this.hltreepath));
             this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid, 'treelist')
             
          }

          

            if (!this.configSvc.nodeds[0].stepper[i].tags[i2].formchoices){
              console.log('this property does NOT have a list associated with it, doing nothing')
            } })
})}



 public assignNode(){

  console.log('assigning node  for '+this.configSvc.nodeds[0]._id);
  console.log('lastest stepitem properties '+JSON.stringify(this.newpropvalues));
  
  if (this.configSvc.activetype == 'editteam'){
    console.log('cannot apply saveFormassign at the team level')}
  else{
  console.log(' applying saveFormassign at the folder level');

  var assignment = '';
  assignment = this.configSvc.assigned[0]; 
    console.log('this is a manually assigned form using'+assignment)

    var statelabel = '';
    if (this.configSvc.nodeds[0].statetracker){statelabel = this.configSvc.nodeds[0].statelabel}
  
  this.configSvc.assignNode(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'node',
  JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
  this.configSvc.nodeds[0].iconcolor, assignment, this.configSvc.nodeds[0].statetracker, this.deadline, statelabel, this.deadlineval)

  this.dialogRef.close();
 
}
 }
 


 public saveFormassign(formtype){

  console.log('saveFormassign  for '+this.configSvc.nodeds[0]._id);
  console.log('lastest stepitem properties '+JSON.stringify(this.newpropvalues));
  
  if (this.configSvc.activetype == 'editteam'){
    console.log('cannot apply saveFormassign at the team level')}
  else{
  console.log(' applying saveFormassign at the folder level');

  var assignment = '';
  if(formtype == 'launch'){

 

    assignment = JSON.stringify(this.configSvc.nodeds[0].assign)
  console.log('this is a pre assigned form using'+assignment)

}
  if(formtype == 'assign'){assignment = JSON.stringify(this.configSvc.assigned) 
    console.log('this is a manually assigned form using'+assignment)
  }
  this.configSvc.saveFormassign(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'formassign',
  JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
  this.configSvc.nodeds[0].iconcolor, assignment)

  this.dialogRef.close();
 
}
 }
 public saveStepperassign(formtype, wizardtype){

  console.log('saveFovrmassign  for '+this.configSvc.nodeds[0]._id);
  console.log('lastest stepper properties '+JSON.stringify(this.newpropvalues));
  
  if (this.configSvc.activetype == 'editteam'){
    console.log('cannot apply saveFormassign at the team level')}
  else{
  console.log(' applying saveFormassign at the folder level');

  var assignment = '';
  if(formtype == 'launch'){assignment = JSON.stringify(this.configSvc.nodeds[0].assign)
  console.log('this is a pre assigned form using'+assignment)}
  if(formtype == 'assign'){assignment = JSON.stringify(this.configSvc.assigned) 
    console.log('this is a manually assigned form using'+assignment)
  }
  this.configSvc.saveStepperassign(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, wizardtype,
  JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
  this.configSvc.nodeds[0].iconcolor, assignment)

  this.dialogRef.close();
 
}
 }

 public createFormfolder(){

  console.log('saving form folder for '+this.configSvc.nodeds[0]._id);
  console.log('does this superfolder have subfolders?  '+this.configSvc.nodeds[0].folders);
  console.log('lastest formfolder properties '+JSON.stringify(this.newpropvalues));
var subfolders = null;
var kanbanprops = null
if(this.configSvc.nodeds[0].folders == true){
  console.log('this smart folder has subfolders')
  subfolders =this.configSvc.nodeds[0].folders }

  var kanbanid = null;
if(this.configSvc.nodeds[0].kanbantagged == true){
  console.log('this smart folder has a kanban')
 kanbanid =  this.configSvc.nodeds[0].kanbanid}
 
    

    //adding the root form folder
    this.configSvc.addFolder( 'formfolder', this.newpropvalues[0].itemvalue, 
    this.configSvc.activeitemid, this.configSvc.activename, 
    this.configSvc.activeteamid, this.configSvc.activelevel, '3',this.configSvc.nodeds[0].icontype, 
    this.configSvc.nodeds[0].iconcolor, JSON.stringify(this.newpropvalues), 
    this.configSvc.nodeds[0].folders, subfolders, this.configSvc.nodeds[0].kanbantagged, kanbanid)
  

  

  this.dialogRef.close();
 

 }

 public createStepperfolder(){

  console.log('saving form folder for '+this.configSvc.nodeds[0]._id);
  console.log('lastest stepperfolder properties '+JSON.stringify(this.newpropvalues));
  
  this.configSvc.addFolder( 'stepperfolder', this.newpropvalues[0].itemvalue, 
    this.configSvc.activeitemid, this.configSvc.activename, 
    this.configSvc.activeteamid, this.configSvc.activelevel, '4',this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0]._id)

  this.dialogRef.close();
 

 }

 public createTabbedfolder(){

  console.log('creatoing tabbed folder for '+this.configSvc.nodeds[0]._id);
  console.log('lastest smartfoldertab properties '+JSON.stringify(this.newpropvalues));
  
  this.configSvc.addFolder( 'smartfoldertab', this.newpropvalues[0].itemvalue, 
    this.configSvc.activeitemid, this.configSvc.activename, 
    this.configSvc.activeteamid, this.configSvc.activelevel, '4',this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0]._id)

  this.dialogRef.close();
 

 }

 public saveStepper(steppertype){

  console.log('saving stepper for '+this.configSvc.nodeds[0]._id);
  console.log('lastest stepper properties '+JSON.stringify(this.newpropvalues));
  console.log(' steppertype is set to'+steppertype);
  
  if (this.configSvc.activetype == 'editteam'){
    console.log('cannot apply saving node at the team level')}
  else{

/*
    if(steppertype == 'stepper'){
  console.log(' applying saveNode at the folder level');
  this.configSvc.saveStepper(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'stepper', 
  JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
  this.configSvc.nodeds[0].iconcolor)

  this.dialogRef.close();
  }
 

  if(steppertype == 'tabbed'){
    console.log(' applying saveNode at the folder level');
    this.configSvc.saveStepper(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
      this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'tabbed', 
    JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
    this.configSvc.nodeds[0].iconcolor)
  
    this.dialogRef.close();
    }
    
*/
}

 }
 

 detectNodechanges(){
    
  console.log('checking for changes to the node');
  this.previewFG.get('formArrayName').valueChanges
  
  .subscribe(
    data => {
      this.newpropvalues = [];
    console.log('new propvalues: ' + JSON.stringify(data));
    for (let properties of data) {
      console.log('full node property is simply '+JSON.stringify(properties));
     // console.log('this property belongs to step'+i
      console.log('property name is '+JSON.stringify(properties.formitem.value));
      console.log('property value is '+JSON.stringify(properties.itemvalue));
      console.log('label is '+JSON.stringify(properties.label.value));
      console.log('pformitemlabel is '+JSON.stringify(properties.formitemlabel.value));
   //   console.log('checkbox is '+JSON.stringify(properties.checkbox));
   //   console.log('textarea is '+JSON.stringify(properties.textarea));
      if(properties.formtype.value != 'text'){properties.textarea = ''}

      if(properties.formtype.value=='choicelist' ){
        console.log('there ARE choicesets associated with this property')
      this.newpropvalues.push({
        eleord: properties.eleord.value,
        formitem:properties.formitem.value, 
        itemvalue:properties.itemvalue, 
       
        formtype: properties.formtype.value,   
        formitemlabel: properties.formitemlabel.value, 
        textarea: properties.textarea, 
        label: properties.label.value, 
        choicesetid:properties.choicesetid.value, 
        hasdefault: properties.hasdefault,
        rowsize: properties.rowsize,
      })}

      if(properties.formtype.value=='radiogroup'){
        console.log('there ARE choicesets associated with this property')
      this.newpropvalues.push({
        eleord: properties.eleord.value,
        formitem:properties.formitem.value, 
        itemvalue:properties.itemvalue, 
       
        formtype: properties.formtype.value,   
        formitemlabel: properties.formitemlabel.value, 
        textarea: properties.textarea, 
        label: properties.label.value, 
        choicesetid:properties.choicesetid.value, 
        hasdefault: properties.hasdefault,
        rowsize: properties.rowsize,
      })}
        if(properties.formtype.value=='treelist'){
          console.log('pushing new values based on treelist')
          console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
        this.newpropvalues.push({
          eleord: properties.eleord.value,
          formitem:properties.formitem.value, 
          itemvalue:properties.itemvalue, 
         
          formtype: properties.formtype.value,   
          formitemlabel: properties.formitemlabel.value, 
         
          label: properties.label.value, 
          choicesetid:properties.choicesetid.value, 
          hasdefault: properties.hasdefault,
          rowsize: properties.rowsize,

          treepath: this.hltreepath
        })}
      if(properties.formtype.value=='string'|| properties.formtype.value=='image' || properties.formtype.value=='number' ||
      properties.formtype.value=='checkbox' || properties.formtype.value=='date' ||
      properties.formtype.value=='text' || properties.formtype.value=='boolean'
      ){ 
        console.log('there are no choiceset associated with this standard property')
        this.newpropvalues.push({
          eleord: properties.eleord.value,
          formitem:properties.formitem.value, 
          label: properties.label.value, 
          formitemlabel: properties.formitemlabel.value,  
          itemvalue:properties.itemvalue, 
          formtype: properties.formtype.value,
           textarea: properties.textarea, 
           choicesetid: 'nocs',
          hasdefault: properties.hasdefault,
          rowsize: properties.rowsize})
          console.log('this is the updated tags:'+JSON.stringify(this.newpropvalues))
        }
   
        
    //  this.newpropvalues.push({property:properties.property.value, propval:properties.propvalue, formtype:properties.formtype.value})
    }
    
    }
  ); 
}

detectStepperchanges(){
    
  console.log('checking for changes to the stepper');
  this.previewFG.get('formArrayName').valueChanges
  
  .subscribe(
    data => {
      this.newpropvalues = [];
    console.log('new propvalues: ' + JSON.stringify(data));
    for (let properties of data) {
      console.log('full property is simply '+JSON.stringify(properties));
     //console.log('this property belongs to step'+i
    //  console.log('property name is '+JSON.stringify(properties.formitem.value));
    console.log('step is '+JSON.stringify(properties.step));
    console.log('stepitem is '+JSON.stringify(properties.stepitem));
      console.log('property value is '+JSON.stringify(properties.itemvalue));
      console.log('label is '+JSON.stringify(properties.label.value));
      console.log('pformitemlabel is '+JSON.stringify(properties.formitemlabel.value));
      console.log('checkbox is '+JSON.stringify(properties.checkbox));
      console.log('textarea is '+JSON.stringify(properties.textarea));
      if(properties.formtype.value != 'text'){properties.textarea = ''}

      if(properties.formtype.value=='choicelist'){
        console.log('there ARE choicesets associated with this property')
      this.newpropvalues.push({
        step:properties.step.value, 
        stepitem:properties.stepitem.value, 
        formitem:properties.property.value, 
        itemvalue:properties.itemvalue, 
       
        formtype: properties.formtype.value,   
        formitemlabel: properties.formitemlabel.value, 
        textarea: properties.textarea, 
        label: properties.label.value, 
        choicesetid:properties.choicesetid.value, 
        hasdefault: properties.hasdefault,
        rowsize: properties.rowsize
      })}

      if(properties.formtype.value=='radiogroup'){
        console.log('there ARE choicesets associated with this property')
      this.newpropvalues.push({
        step:properties.step.value, 
        stepitem:properties.stepitem.value, 
        formitem:properties.property.value, 
        itemvalue:properties.itemvalue, 
       
        formtype: properties.formtype.value,   
        formitemlabel: properties.formitemlabel.value, 
        textarea: properties.textarea, 
        label: properties.label.value, 
        choicesetid:properties.choicesetid.value, 
        hasdefault: properties.hasdefault,
        rowsize: properties.rowsize
      })}
        if(properties.formtype.value=='treelist'){
          console.log('pushing new values based on treelist')
          console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
        this.newpropvalues.push({
          step:properties.step.value, 
        stepitem:properties.stepitem.value, 
          formitem:properties.property.value, 
          itemvalue:properties.itemvalue, 
         
          formtype: properties.formtype.value,   
          formitemlabel: properties.formitemlabel.value, 
         
          label: properties.label.value, 
          choicesetid:properties.choicesetid.value, 
          hasdefault: properties.hasdefault,
          rowsize: properties.rowsize,
          treepath: this.hltreepath
        })}
      if(properties.formtype.value=='string'|| properties.formtype.value=='number' ||
      properties.formtype.value=='checkbox' || properties.formtype.value=='date' ||
      properties.formtype.value=='text' || properties.formtype.value=='boolean'
      ){ 
        console.log('there are no choiceset associated with this property')
        this.newpropvalues.push({
          step:properties.step.value, 
        stepitem:properties.stepitem.value, 
          formitem:properties.property.value, 
          label: properties.label.value, 
          formitemlabel: properties.formitemlabel.value,  
          itemvalue:properties.itemvalue, 
          formtype: properties.formtype.value,
           textarea: properties.textarea, 
           choicesetid: 'nocs',
          hasdefault: properties.hasdefault,
          rowsize: properties.rowsize })
          console.log('this is the updated tags:'+JSON.stringify(this.newpropvalues))
        }
   
        
    //  this.newpropvalues.push({property:properties.property.value, propval:properties.propvalue, formtype:properties.formtype.value})
    }
    
    }
  ); 
}






 public getChoices(choicesetid){
 // console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))

var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
//console.log('this is the filtered choicelist: '+filtered)
return filtered;
 }

 public choiceChange(){
   
  console.log('choices is changed so setting default value to true');
  console.log('itemvalue is set to: '+this.previewFG.value.itemvalue);

 }

 public sortPath(){
    
    
 // console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
//console.log('this is the sorted result: '+JSON.stringify(this.sorted))
  return this.sorted

}

public getChoicetree(choicesetid){

  this.getChip(choicesetid)
   this.sorted = [];


 
  this.clickchoiceset = true; 
   console.log('getting the choice tree for '+choicesetid)
   console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
 
   this.sortPath()
  if(this.authSvc.choicesetids.includes(choicesetid)){
    console.log('this choicetree has a list associated with it and its in cache')

  }


 if(!this.authSvc.choicesetids.includes(choicesetid)){
   console.log('ive never got this list before lets get it')
  this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')

}


}

public getChip(choicesetid){

 
  this.sorted = [];
  console.log('getting choicesetbyid with getChip type is path')
  this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
}


public hlchoice(selectedrow, index){
  console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
 console.log('this is itthe itemvalue: '+selectedrow.name);
 console.log('this is itthe formcontrol index: '+index);

 
// this.previewArray.controls[index].value.itemvalue= selectedrow.name;
this.previewArray.controls[index].patchValue({'itemvalue': selectedrow.name})
 console.log('this is formtype '+this.previewArray.controls[index].value.formtype.value);
 console.log('this is fitemvalue '+this.previewArray.controls[index].value.itemvalue);
 // this.previewArray.at(index).controls['itemvalue'].patchValue('test');
 console.log('this is itthe current preview array: '+this.previewArray);
 
  this.hlchoiceset = [];
  this.hlchoiceset.push(selectedrow)
  this.hltreepath= [];
  this.hltreepath = this.hlchoiceset[0].path
  console.log('this is itthe new selected tree path '+JSON.stringify( this.hltreepath));
  this.previewArray.controls[index].patchValue({'treepath': this.hltreepath})
 }

 public changeRadio(selectedrow, index){
   console.log('changing radio option')
  this.previewArray.controls[index].patchValue({'itemvalue': selectedrow.name})
 }

 public changeType(type){
  console.log('changing folder type display option for: '+type)
 }

 public appyTags(){

  this.configSvc.applyTags(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'foldertag', 
  JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
  this.configSvc.nodeds[0].iconcolor)
  this.dialogRef.close();
 
 }
/*
 public applyKanban(){
   console.log('need to apply kanban now')

   this.configSvc.applyKanban(this.configSvc.activetype, this.configSvc.activeitemid,
     this.configSvc.nodeds[0]._id, this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].iconcolor, true)
   this.dialogRef.close();
 }
 */

 public changeBoolean(hasdefault, index){
  //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
  var bool = false;
  console.log('this is itthe formcontrol index: '+index);
  console.log('this is itthe current hasdefault value: '+hasdefault);
  console.log('this is new hasdefault value: '+!hasdefault)
  

 
  this.previewArray.markAsDirty();
 // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
 this.previewArray.controls[index].patchValue({'hasdefault': !hasdefault})
}
/*
public launchFlow(){
  console.log('launching flow')
  if(this.configSvc.nodeds[0].launcher == 'wizard'){
    console.log('launching nodeflow for '+JSON.stringify(this.configSvc.nodeds[0]))
    console.log('launching stepperflow with newprop values'+JSON.stringify(this.newpropvalues))
    this.flowSvc.launchFlow(this.configSvc.nodeds[0].name, this.configSvc.nodeds[0]._id,
      JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].icontype, this.configSvc.activeitemid, 'wizard')
    this.dialogRef.close();
  }

  if(this.configSvc.nodeds[0].launcher == 'node'){
    console.log('launching nodeflow for name '+JSON.stringify(this.configSvc.nodeds[0].name))
    console.log('launching nodeflow for flowid '+JSON.stringify(this.configSvc.nodeds[0]._id))
    console.log('launching stepperflow with newprop values'+JSON.stringify(this.newpropvalues))
    this.flowSvc.launchFlow(this.configSvc.nodeds[0].name, this.configSvc.nodeds[0]._id,
      JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].icontype, this.configSvc.activeitemid, 'node')
    this.dialogRef.close();
  }
}

public bulkforms(files: NgxFileDropEntry[], indextype) {
  console.log('bulk processing locally with indextype of'+indextype)
 
  this.configSvc.csvrowcnt = 0;
  this.csvprocessed =0;
  for (const droppedFile of files) {

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
     
      fileEntry.file((fileext) => {
        console.log('cwhat is going on here bulk forms');
  //start of parse process
  this.ngxCsvParser.parse(fileext, { header: true, delimiter: ',' })
  .pipe().subscribe((result: Array<any>) => {

    console.log('Result', result);
    this.csvRecords = result;
    this.configSvc.totalcsvrows = this.csvRecords.length;
    console.log('totalcsvrows is: '+this.configSvc.totalcsvrows)

    for(const newfolder of this.csvRecords){
   //  console.log('this is the skeleton indexes'+JSON.stringify(this.configSvc.teamdetailsds.stepitems))
 //   console.log('newfolder is: '+newfolder.name)
  //  console.log('this is the csv row: '+JSON.stringify(newfolder))
   
 //   
  //  let name = newfolder.name
    this.configSvc.newpropvalues = this.configSvc.nodeds[0].stepitems
    for(const propertyitem of this.configSvc.newpropvalues )
    {

      console.log('property is'+propertyitem.formitem);
        let csvprop= propertyitem.formitem
      console.log('property value is'+propertyitem.itemvalue);
      propertyitem.itemvalue = newfolder[csvprop];
      console.log('newly update property value is'+propertyitem.itemvalue);
    }

    console.log('this is the populated new skeleton indexes'+JSON.stringify(this.configSvc.newpropvalues ))
    
    if( this.assigned){
      console.log('assigning node now')
      this.assignNode()
    } 
    if(!this.assigned){
      var statelabel = '';
      if (this.configSvc.nodeds[0].statetracker){statelabel = this.configSvc.nodeds[0].statelabel}

    this.configSvc.saveNode(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
      this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'node', 
    JSON.stringify(this.newpropvalues), this.configSvc.nodeds[0].icontype, 
    this.configSvc.nodeds[0].iconcolor, indextype, this.configSvc.nodeds[0].statetracker,this.deadline,this.assigned, statelabel, this.deadlineval, this.configSvc.assignedto )
    }

    }
  }, (error: NgxCSVParserError) => {
    console.log('Error', error);
  });
  //end of parse process
});
 
}
}
*/

public batchitems(nodename, icontype, iconcolor, nodeid, indextype){
  console.log('executing batchitems process')
  for(const item of  this.configSvc.subfolders){
    console.log('this is the item name '+item.name)
  console.log('this is the linkid '+item.linkid)
  this.configSvc.batchitems(item.linkid, this.configSvc.activeitemid, item.type, nodename, icontype, iconcolor, nodeid, indextype)
  this.dialogRef.close()
  
  }

}
/*
public addToolfavs(){
  if(this.favtool){
    console.log('adding tool to favourites '+this.configSvc.nodeds[0]._id)
    console.log('tool name '+this.configSvc.nodeds[0].name)
    this.prefSvc.addToolfavs(this.authSvc.getUserDetails()._id, this.configSvc.nodeds[0]._id, 
    this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].name)
  
  }
  if(!this.favtool){console.log('not adding tool to favourites '+this.configSvc.nodeds[0]._id)}
  
}
*/

public removeToolfavs(){
  console.log('removing tool from favourites ')
  this.prefSvc.removeToolfavs(this.authSvc.getUserDetails()._id, this.configSvc.nodeds[0]._id)
  this.dialogRef.close();
}

public imagedropped(event) {
 
  if (event.target.files && event.target.files[0]) {
    this.imageloaded = new FileReader();
this.actualimage = event.target.files[0]
    this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url

    this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
      this.url = event.target.result;
      this.imageready = true
    }
  }

// this.dialogRef.close();
  }


public imageOver(){
  console.log(' image over')
}

public imageLeave(){
  console.log(' image leave')
}


move(index: number) {
  console.log('i am about to move to index '+index)
  this.stepIndex = index;
  console.log('ive moved to index '+this.stepIndex )
}

public selectSubitem(selecteditem, index){
    
  this.hlsubitem = [];
  this.hlsubitem.push(selecteditem)
  console.log('cklciked item' +JSON.stringify(this.hlsubitem[0]))

  
}

}
