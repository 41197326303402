Designed by Toolbard Solutions the automation specialist
<p>Toolbard 12th Sept 2023 Version 2.02</p>
<pre>
Running on Angular Version 10.1
Front End Dependencies:
"@angular/animations": "~10.1.2",
"@angular/cdk": "^10.2.2",
"@angular/common": "~10.1.2",
"@angular/compiler": "~10.1.2",
"@angular/core": "~10.1.2",
"@angular/forms": "~10.1.2",
"@angular/material": "^10.2.2",
"@angular/platform-browser": "~10.1.2",
"@angular/platform-browser-dynamic": "~10.1.2",
"@angular/router": "~10.1.2",
"moment": "^2.29.0",
"ngx-csv-parser": "0.0.6",
"ngx-file-drop": "^9.0.1",
"rxjs": "~6.6.0",
"tslib": "^2.0.0",
"zone.js": "~0.10.2"
</pre>
