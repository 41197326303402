
<mat-card *ngIf="this.configSvc.activetype == 'fold' || this.configSvc.activetype == 'smartfoldertab' || this.configSvc.activetype == 'smartfolder'"  class = comments-container>

    <ul style="list-style-type:none">
        <li *ngFor="let comments of this.configSvc.folderdetailsds.comments" class = "speech">
          <div class="message-container"
          [ngClass]="{'message-container--left': comments.ab != 'ChatGPT', 'message-container--right': comments.ab == 'ChatGPT'} ">
        
        
          <div class="message">
        
            <div class="message__text">
              {{comments.comment}}
            </div>
        
            <div class="message__footer">
              <span class="message__timestamp">
                <span *ngIf="comments.atcmt == true">
                  @{{comments.username}}
                </span>

                <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab != 'ChatGPT'">
                  &nbsp; {{comments.ab}} asked ChatGPT on {{ comments.ao | date:'short'}}
                 </span>

                 <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab == 'ChatGPT'" >
                  &nbsp;  {{comments.ab}} responded  on {{ comments.ao | date:'short'}}
                 </span>

                 <span class="message__timestamp " *ngIf="comments.ischatgpt== false && comments.ab != 'ChatGPT'">
                  &nbsp; {{comments.ab}} commented on {{ comments.ao | date:'short'}}
                 </span>
                
                </span>
            </div>
        
            <div class="scooped-corners scooped-corners-visibler"> </div>
            <div class="scooped-corners scooped-corners-hider"> </div>
          </div>
        </div>
          <br>
        </li>
      </ul>
     </mat-card>
    
 
     <span *ngIf="this.configSvc.activetype != 'fold' && this.configSvc.activetype != 'smartfoldertab' && this.configSvc.activetype != 'smartfolder'" >
     <mat-card [ngClass]="{'comment-container2': this.configSvc.popup, 'comment-container': !this.configSvc.popup}">
 
       <ul style="list-style-type:none">
           <li *ngFor="let comments of this.comments" class = "">
             
    
            <div class="message-container"
            [ngClass]="{'message-container--left': comments.ab != 'ChatGPT', 'message-container--right': comments.ab == 'ChatGPT'} ">
                   
            
            <span *ngIf="comments.ab != 'ChatGPT'">  <i class="material-icons avatar-left" 
              [ngStyle]="{'color': comments.iconcolor}">{{comments.icontype}}</i></span> 
          
            <div class="message">
          
              <div class="message__text">
                  {{comments.comment}}
              </div>
          
              <div class="message__footer">
                <span class="message__timestamp">
                  <span *ngIf="comments.atcmt == true">
                    @{{comments.username}}
                  </span>
  
                  <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab != 'ChatGPT'">
                    &nbsp; {{comments.ab}} asked ChatGPT on {{ comments.ao | date:'medium'}}
                   </span>
  
                   <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab == 'ChatGPT'" >
                    &nbsp;  {{comments.ab}} responded  on {{ comments.ao | date:'medium'}}
                   </span>

                   <span class="message__timestamp " *ngIf="comments.ischatgpt== false && comments.ab != 'ChatGPT'">
                    &nbsp; {{comments.ab}} commented on {{ comments.ao | date:'medium'}}
                   </span>
                  
                  </span>
              </div>
          
              <div class="scooped-corners scooped-corners-visibler"> </div>
              <div class="scooped-corners scooped-corners-hider"> </div>
              
            </div>
            <span *ngIf="comments.ab == auth.getUserDetails()?.username && comments.ischatgpt == false">
              <button mat-button matSuffix 
              mat-raised-button color="primary"(click) = "this.clearComment(this.configSvc.nodeds[0]._id, this.configSvc.nodeds[0].type, comments._id);">Clear </button>
             </span>

              <span *ngIf="comments.ab == 'ChatGPT'">
                <mat-icon class="avatar-right"  title="Ask ChatGPT" svgIcon="chat2"
                (click)="this.openDialog('askchatgpt', 'Ask ChatGPT a Question')"
                ></mat-icon></span>
          </div>
             <br>
           </li>
         </ul>  
        </mat-card>
</span>


<span *ngIf="this.configSvc.activetype == 'activeflow'" >
 
  <ul style="list-style-type:none">
      <li *ngFor="let comments of this.configSvc.currentrow.comments" class = "speech">
        

        <div class="message-container"
        [ngClass]="{'message-container--left': comments.ab != 'ChatGPT', 'message-container--right': comments.ab == 'ChatGPT'} ">
      
      
        <div class="message">
      
          <div class="message__text">
            {{comments.comment}}
          </div>
      
          <div class="message__footer">
            <span class="message__timestamp">
              <span *ngIf="comments.atcmt == true">
                @{{comments.username}}
              </span>

              <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab != 'ChatGPT'">
                &nbsp; {{comments.ab}} asked ChatGPT on {{ comments.ao | date:'short'}}
               </span>

               <span class="message__timestamp" *ngIf="comments.ischatgpt== true && comments.ab == 'ChatGPT'" >
                &nbsp;  {{comments.ab}} responded  on {{ comments.ao | date:'short'}}
               </span>

               <span class="message__timestamp " *ngIf="comments.ischatgpt== false && comments.ab != 'ChatGPT'">
                &nbsp; {{comments.ab}} commented on {{ comments.ao | date:'short'}}
               </span>
              
              </span>
          </div>
      
          <div class="scooped-corners scooped-corners-visibler"> </div>
          <div class="scooped-corners scooped-corners-hider"> </div>
        </div>
      </div>
        <br>
      </li>
    </ul>  </span>
   
    <span *ngIf="this.configSvc.nodeds[0]?.atcmt" >
      
      <button  [disabled]= "this.clear" mat-button (click)="clearNotification()" 
                      
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Clear">Clear @Notification</button>
      </span>


    