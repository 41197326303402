<span class = "top">

    <form id="myForm" [formGroup]="tagsFG" >  
     
      <span [formArrayName]="'formArrayName'" class="flex-container">
    
        <div *ngFor="let property of this.tagsArray.controls; let i = index" >
           <div [formGroupName]="i" >
           
        
              <div *ngIf="property.value.formtype.value == 'string'" > 
                <span class ="itemcontainer">
                <span *ngIf="property.value.label.value==true" class = "labels">
                  <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                 
               
                <mat-form-field appearance="fill"> 
                 <span *ngIf="property.value.label.value==false">
                      <mat-label >{{property.value.formitem.value}} </mat-label></span>
                      <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                     </mat-form-field>
                  
  
                   <div *ngIf="property.value.label.value==false" class = "labels">
                   
                   <mat-form-field appearance="outline" floatLabel="always"> 
                     <mat-label >{{property.value.property.value}} </mat-label>
                         <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                        </mat-form-field>
                       </div> 
                   
                </span>     </div> 
        
                     <div *ngIf="property.value.formtype.value == 'number'"> 
                       <span class ="itemcontainer">
         <span *ngIf="property.value.label.value==true" class = "labels">
                         <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                        
                      <mat-form-field appearance="fill">  
                          
                            <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
          </mat-form-field></span>
         
          <span *ngIf="property.value.label.value==false" class = "labels">
       
           <mat-form-field appearance="outline" floatLabel="always"> 
             <mat-label >{{property.value.property.value}} </mat-label>
                 <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                </mat-form-field>
         </span></span>
       </div>  
         <span *ngIf="property.value.formtype.value == 'boolean'"> 
         
       <div class= "labels"> 
  
         <mat-slide-toggle 
         [checked] = property.value.hasdefault
         (change)="changeBoolean(property.value.hasdefault, i)">
                                 
          {{property.value.formitemlabel.value}}
           </mat-slide-toggle>
        
          
         </div> 
  
                      </span>
        
                     <span *ngIf="property.value.formtype.value == 'date'"> 
                       <span *ngIf="property.value.label.value==true" class = "labels">
                         <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                        </span> 
                     <mat-form-field appearance="fill">
                       <input matInput [matDatepicker]="picker1" placeholder="{{property.value.formitem.value}}" formControlName="itemvalue" >
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker  #picker1></mat-datepicker>
                          </mat-form-field></span>
  
  
                          <div *ngIf="property.value.formtype.value == 'checkbox'"> 
                          
                           <div *ngIf="property.value.label.value==true" class = "labels">
                             <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                           </div> 
                            <mat-checkbox formControlName="hasdefault">
                             {{property.value.formitem.value}} </mat-checkbox>
                   </div>
  
                   <span *ngIf="property.value.formtype.value == 'text'" class = "flex-item" > 
                             
                    <span class = "labels">
                      <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                     </span> 
                   
                     <mat-form-field appearance="outline"   class = "textarea">
                        <textarea
                        
                        formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                       </mat-form-field>
                       
                      </span> 
    
                            <div *ngIf="property.value.formtype.value == 'choicelist'  ">
  
  
                             <span class= "itemcontainer">
                               <span class = "labels">
                               <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
  <mat-form-field>
                           
                              <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue" 
                             
                              (selectionChange)="choiceChange()"
                              >
                          <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                            {{choice.name}}
                          </mat-option>
                        </mat-select>
                       
                      </mat-form-field>
                     </span>
                      
                    
                        </div>
  
                        <div *ngIf="property.value.formtype.value == 'treelist'  ">
                   
                         <span *ngIf="property.value.label.value==true"  class= "treecontainer">
                <span class = "labels">
                <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
  
             
              <mat-chip-list>
        
                
                <mat-chip *ngFor="let paths of this.sortPath()"
                (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
                >
                  {{paths.name}}
            </mat-chip>
            </mat-chip-list>
        
          
              <div *ngIf="clickchoiceset" class="outerbox">  
                <span  >
                <table mat-table [dataSource]="this.tagerSvc.activetree"  >
                
                  <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                  (click)="this.getChoicetree(selectedrow._id); 
                 
                  
                  this.hlchoice(selectedrow,  i);
                  "
                  [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                  class="innerbox"
                  ></tr>
                
                
                           
                <ng-container matColumnDef="Name" >
                      <th mat-header-cell *matHeaderCellDef>Name </th>
                   <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.haschild">  
                      <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                      </span>
                       {{element.name}}
                 </td></ng-container>
                
                   </table> </span>
        </div>
        
       
        <div *ngIf="this.clickchoiceset"><mat-chip-list>
        <mat-chip>
        {{property.value.itemvalue}}
        </mat-chip></mat-chip-list></div>
        
        </span>
                         
                   
                
                   
                 
                   
                    </div>
  
                        <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                  <span  *ngIf="property.value.label.value==true" >
                         <span class= "itemcontainer">
                           <span class = "labels">
                           <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
                         <mat-radio-group
                         class="radio-group">
                         <mat-radio-button class="radio-button" 
                         
                       
                        
                         *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                         [value]="choice.name"
                         [checked]="choice.name == property.value.itemvalue"
                         (change)="changeRadio(choice, i)"
                         >
                           {{choice.name}}
                         </mat-radio-button>
                       </mat-radio-group>
                      
                     </span>
                   </span>
                
                    </div>
                           </div> 
          </div>
            </span>
          
          
    
          <div *ngIf="this.configSvc.tagupload == false"> <button  [disabled]= "!this.tagsFG.dirty" (click)="this.updateTags();"
            mat-raised-button color="primary" [disabled]="!tagsFG.dirty">Apply Changes </button>
         </div>
    
    
        
      </form>
    
    </span>