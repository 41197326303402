
  <mat-card class = flex-container>
    <mat-horizontal-stepper  #stepper  [(selectedIndex)]="stepIndex">
    
      <mat-step [stepControl]="step1">
        <ng-template matStepLabel>User Details</ng-template>
        <mat-card class = "step-card">
          <form novalidate (ngSubmit)="onSubmit(registerFG )" [formGroup]="registerFG" >
            <div><mat-form-field class="full-width-input">
           <input matInput placeholder="Company Manager Full Name" formControlName="name" />
           <mat-error *ngIf="isFieldInvalid('name')">
             Please enter the managers full name
           </mat-error>
         </mat-form-field></div>
 
         <div><mat-form-field class="full-width-input">
           <input matInput placeholder="Email" formControlName="email" required />
           <mat-error *ngIf="isFieldInvalid('email')">
             Please enter your  email address</mat-error>
           </mat-form-field></div>
 
      <div>  <mat-form-field class="full-width-input">
           <input matInput type="password" placeholder="Password" formControlName="password" required />
           <mat-error *ngIf="isFieldInvalid('email')">
           Please enter your password</mat-error>
 
         </mat-form-field></div> 

      


         
         <div *ngIf="error" class="alert alert-danger">{{error}}</div>
       
       
        
       
         <button mat-raised-button color="primary"  mat-button matStepperNext (click)="this.iconNext()">Next</button>
       </form>
        </mat-card>
        </mat-step>


        
    
        <mat-step [stepControl]="step2">
          <ng-template matStepLabel>Home Icon</ng-template>
          <mat-card class = "step-card">

            <form [formGroup]="registerFG">

              <span>
                <app-iconcategories></app-iconcategories></span>
                    <span class ="colorbox">
                    <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                  </span>
                  
        
        
            </form>
           
          <span class= "button-wrapper">
            <button mat-raised-button color="primary"  mat-button matStepperPrevious class="buttonmargin">Back</button>
            <button mat-raised-button color="primary"  mat-button matStepperNext class="buttonmargin">Next</button>
          </span>
          </mat-card>
          </mat-step>
     
      <mat-step [stepControl]="step3">
        
          <ng-template matStepLabel>Register</ng-template>
          <mat-card class = "step-card">
            <form [formGroup]="registerFG">
            <div><mat-form-field class="full-width-input">
              <input matInput placeholder="Home Name" formControlName="teamname" required />
             
              </mat-form-field></div>

              <div><mat-form-field class="full-width-input">
                <input matInput placeholder="Home Description" formControlName="teamdesc" required />
               
                </mat-form-field></div>
   
 
              
           <mat-icon>edit</mat-icon> Please register to access AdHoc Solutions
          
            <button mat-raised-button color="primary"  mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary"  mat-button class="buttonmargin" >Register</button>
         
           
          
      </form>
      </mat-card>
     
    </mat-step>
    
    
     
    </mat-horizontal-stepper>
  
  </mat-card>

