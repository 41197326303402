import { Component, OnInit, Inject } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {TagerService} from '../../../services/tager.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import { AuthenticationService } from '../../../services/authentication.service';


export interface DialogData {
 
  choiceset: string;
  type: string;
 choice: string;
 desc: string;
 prompt: boolean;
}

@Component({
  selector: 'app-prompttools',
  templateUrl: './prompttools.component.html',
  styleUrls: ['./prompttools.component.css']
})
export class PrompttoolsComponent implements OnInit {

  constructor(public configSvc: ConfigService, public dialog: MatDialog, public tagerSvc: TagerService) { }

  ngOnInit(): void {
  }

  openChoicetool(type, name, desc, prompt): void {

    console.log('popup name is'+name)
    console.log('popup type is'+type)
var popupheight = '500px'
var popupwidth = '400px'


    if(type == 'choiceset'){popupheight = '700px'; popupwidth = '800px'}
    if(type == 'choice') {popupheight = '500px'; popupwidth = '800px'} 
    const dialogRef = this.dialog.open(PromptPopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type, desc: desc, prompt: prompt}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
  
    });
  }

}

@Component({
  selector: 'app-promptpopup',
  templateUrl: 'promptpopup.html',
  styleUrls: ['./prompttools.component.css']
})

export class PromptPopup implements OnInit{
clickchoiceset = false;
  hlchoiceset = [];
  resultColumns = [ 'ChoiceSetName', 'ChoiceSetDesc', 'AddedBy', 'AddedOn'];
  haslabel = false;
  hasdefault = false;
  defaulttoggle = false
  
  public previewArray: FormArray;
  public previewFG: FormGroup;
  public choicesetFG: FormGroup;
  stpcnt: string;

  constructor(private router: Router,private authService: AuthenticationService,
    public dialogRef: MatDialogRef<PromptPopup>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public configSvc: ConfigService, private fb: FormBuilder, public tagerSvc: TagerService) {}

    
  ngOnInit() {

 
}



  onNoClick(): void {
    this.dialogRef.close();
  }



      public createChoiceset(name, desc, prompt){
        console.log('creating choiceset name is'+name)
        console.log('choiceset desc is'+desc)
        console.log('choiceset prompt is'+prompt)
        

        this.tagerSvc.createChoiceset( name, desc, prompt, this.configSvc.mgrsadded)
     

        this.dialogRef.close();
      }

      public createChoiceitem(name, desc){
        console.log('creating choiceset name is'+name)
        this.tagerSvc.createChoiceitem( name, desc, this.configSvc.activeitemid, this.configSvc.activelevel)
        this.dialogRef.close();
      }

      public deleteChoiceitem(){
        console.log('deleting choiceid is'+this.configSvc.activeitemid)
        console.log(' set id is'+this.tagerSvc.setdetailsds[0].parentid)

        this.tagerSvc.deleteChoiceitem( this.configSvc.activeitemid, this.tagerSvc.setdetailsds[0].parentid )
        this.dialogRef.close();
      }

      



  


   
    



 public getChoiceset(selectedrow){
  this.hlchoiceset = [];
  this.hlchoiceset.push(selectedrow)
  console.log('clicked on this choiceset '+JSON.stringify(this.hlchoiceset[0]));
  this.clickchoiceset = true; 
    }

    applyChoiceSet(){

      console.log('list type for applyChoiceset is currently set to '+this.tagerSvc.listtype)
    
 
  
  
  if(this.tagerSvc.hlKanbanset[0].tagertype == 'tageritem' || this.tagerSvc.hlKanbanset[0].tagertype == 'foldertags')
  {
    console.log('this is a tageritem or foldertag applying choiceset')
    console.log('this is a hltagonly'+JSON.stringify(this.tagerSvc.hltagonly[0]))
   this.tagerSvc.applytagertagChoiceset(this.tagerSvc.hlKanbanset[0]._id,this.tagerSvc.hltagonly[0].stepitems._id,
   this.hlchoiceset[0]._id, this.tagerSvc.hltagonly[0].stepitems.formitem)
   }
  
   
  
  
  else{
   this.tagerSvc.applyChoiceset( 
   this.tagerSvc.hlStepset[0]._id,
     this.tagerSvc.hlStep[0]._id,
      this.tagerSvc.hlStepitem[0]._id,
    this.hlchoiceset[0]._id)
  
    }}

    public addMyself(){
      this.configSvc.mgrsadded = [];
      const userdetails  = this.authService.getUserDetails();
      console.log('this is the managers details'+JSON.stringify(userdetails))
      const username = userdetails.username
      this.configSvc.mgrsadded.push(username)
      console.log('activetype after adding myself ist'+this.configSvc.activetype)
     // this.configSvc.findUser(mgremail, 'addmyself');
     // this.myself = userdetails;
     this.configSvc.activetype = 'addchoiceset'
    // this.configSvc.mgrsadded.push(userdetails);
  
  
    }

  
  
   
  
   
      

  

}



