<mat-tab-group>


  <mat-tab>
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': this.activeflow[0].iconcolor || 'black'}" > {{this.activeflow[0].icontype}}</i>
      {{this.activeflow[0].name}}
    </ng-template>

      <span *ngIf="this.activeflow[0].status == 0" > 
        <i class="material-icons status-notstarted">pause_circle_outline </i>
        This process has not been started yet
      </span>

      <span *ngIf="this.activeflow[0].status == 1"> 
        <i class="material-icons status-inprogress" >history</i>  
        This is currently being worked on by 
        <i class="material-icons md-24" [ngStyle]="{'color': this.activeflow[0].iconcolor || 'black'}" > {{this.activeflow[0].icontype}}</i>
        {{this.activeflow[0].assignedname}} 
      </span>

      <span *ngIf="this.activeflow[0].status == 2" > 
        <i class="material-icons status-completed">check_circle_outline </i>
        This was completed by 
      </span>

      <span *ngIf="this.activeflow[0].status == 3" > 
        <i class="material-icons status-completed">check_circle_outline </i>
        This was rejected by 
      </span>
   
   
      <span *ngIf="this.activeflow[0].type == 'basicflow' ">
      <app-activenode></app-activenode>
    </span>
    <span *ngIf="this.activeflow[0].type == 'stepperflow'">
      <app-activestepper></app-activestepper>
    </span>

    <span *ngIf="this.activeflow[0].type == 'tabbedflow'">
      <app-activetabs></app-activetabs>
    </span>

    </mat-tab>

    <mat-tab label="Queue Tracker Flow">
      <ng-template mat-tab-label>
        <i class="material-icons icon-tab" 
        [ngStyle]="{'color': 'black'}">update</i> 
        Flow Queue Tracker
        </ng-template>
  
        <mat-card>
        <span *ngFor="let phase of flowprocess; let index = index" class = "phases">
          <i class="material-icons icon-tab" 
          [ngStyle]="{'color': phase.clr}">{{phase.icontype}}</i> 
          {{phase.name}}
          <span *ngIf="phase.status == 0 && index == 0" >Launched by {{this.configSvc.nodeds[0].ab}} on {{phase.ao | date: 'medium'}} <i class="material-icons icon-tab"[ngStyle]="{'color': 'red'}">highlight_off</i> </span>
          <span *ngIf="phase.status == 1"> <i class="material-icons icon-tab"[ngStyle]="{'color': 'blue'}">history</i>In progress</span>
        
          <span *ngIf="phase.status == 3 " ><i class="material-icons icon-tab"[ngStyle]="{'color': 'red'}">highlight_off</i>  Rejected by {{phase.ab}} on {{phase.ao | date: 'medium'}}</span>
      
      <span *ngIf="phase.status == 2"> <i class="material-icons icon-tab"[ngStyle]="{'color': 'green'}">check_circle</i>
        Completed by {{phase.ab}} on {{phase.ao | date: 'medium'}} </span>
      
      {{phase.formname}}
  
      <div class = "arrows">
   <span *ngIf="flowprocess.length-1 != index"> 
     <i class="material-icons"  [ngStyle]="{'color': 'black'}">south</i> 

    </span>
  </div>
        </span></mat-card>
    </mat-tab>

    <mat-tab  *ngIf="this.configSvc.nodeds[0].attach == true" label="Attachments">
      <ng-template mat-tab-label>
        <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >attach_file</i>
        
        </ng-template>
      <span class= "comments">
          <app-nodefiles></app-nodefiles></span>
    
    </mat-tab>

    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.deadline == true" label="Deadline">
      <ng-template mat-tab-label>
       <span> <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >timer</i></span>
       
      
        </ng-template>
      <span class= "comments">
        <app-deadline></app-deadline>
      </span>
      </mat-tab>

    <mat-tab label="Details" >

      <ng-template mat-tab-label>
        <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>
         Details
        </ng-template>
    
          <app-nodedetails></app-nodedetails>
</mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
  <ng-template mat-tab-label>
    <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}" >
      {{this.configSvc.nodeds[0]?.cmticon}}</i>
   
    </ng-template>
  <span class= "comments">
      <app-viewcomments></app-viewcomments></span>

</mat-tab>


    
        </mat-tab-group>


