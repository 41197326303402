import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; 
import { ConfigService } from '../../services/config.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resetemail',
  templateUrl: './resetemail.component.html',
  styleUrls: ['./resetemail.component.css']
})
export class ResetemailComponent implements OnInit {

  resetemailform: FormGroup;    
  private resetAttempt = false;
  error = '';

  constructor(private fb: FormBuilder, public configSvc: ConfigService,  private router: Router) { }

  ngOnInit(): void {
    this.resetemailform = this.fb.group({     // {5}
      username: ['', Validators.required],
    
    });
    console.log('reset form initialized');
  }

  public sendpwordEmail(){
  
  
      console.log('sending email to reset pword for'+this.resetemailform.value.username )
      this.configSvc.sendpwordEmail( this.resetemailform.value.username)
      this.router.navigateByUrl('/emailconfirm');
  }

  isFieldInvalid(field: string) { // {6}

    return (
      (!this.resetemailform.get(field).valid && this.resetemailform.get(field).touched) ||
      (this.resetemailform.get(field).untouched && this.resetAttempt)

    );

  }

}
