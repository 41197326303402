import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {TagerService} from '../../services/tager.service';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, FormArray, Validators, NgForm} from '@angular/forms';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';



@Component({
  selector: 'app-teamsecurity',
  templateUrl: './teamsecurity.component.html',
  styleUrls: ['./teamsecurity.component.css']
})
export class TeamsecurityComponent implements OnInit {
  public newuser = false;

public mgrselected = false;
  finduserclicked = false;
  public selecteduser = ''
 
  editteamFG: FormGroup;


  constructor(private fb: FormBuilder, public configSvc: ConfigService, public tagerSvc: TagerService) { }

  ngOnInit() {

    this.editteamFG = this.fb.group({
      usersearch: [''],
      username:  [''],
     
   });
   
  }

  usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
  console.log('usernameFilter value is currently set to '+this.configSvc.filtereduser)

  
 this.editteamFG.value.usersearch = this.selecteduser

  }
  
  }


  public inviteUser(useremail){
console.log('inviting new user'+useremail )
  }


  public findUser() {
    this.finduserclicked = true;
    this.configSvc.findUser(this.editteamFG.value.usersearch, 'user');
   // this.configSvc.findUser(this.usersearchfc.value);
  
  }

  public addteammgr(type, manager){
    console.log('type is currently: '+type)
    console.log('manage selected is currently: '+manager)
  
  if(type == 'team' ||type == 'createteam' ){
    console.log('this is a create team function '+manager)

    this.configSvc.mgrsadded.push(manager);
  }
  
  if(this.configSvc.activetype == 'editteam' ){
    console.log('this is a create or edit team function')
  this.configSvc.addteammgr(this.configSvc.activeitemid, manager)
  
}

if(this.configSvc.activetype == 'nodeteam'){
  console.log('adding a user to a nodeteam')
this.configSvc.addnodeteammgr(this.configSvc.activeitemid, manager)

}

if(this.configSvc.activetype == 'addnodeteam'){
  console.log('adding a user to a nodeteam')
this.configSvc.mgrsadded.push(manager)

}

if(this.configSvc.activetype == 'addchoiceset'){
  console.log('adding a data user to manage a dataset')
this.configSvc.mgrsadded.push(manager)

}

if(this.configSvc.activetype == 'set'){
  console.log('adding a data user to manage a dataset')
  this.tagerSvc.addsetmgr(this.configSvc.activeitemid, manager)

}

this.editteamFG.markAsPristine()}


  public removeteammgr(){
console.log('activetype is '+this.configSvc.activetype)
  
      const index = this.configSvc.mgrsadded.indexOf(this.configSvc.highlightedMgrRow)
      this.configSvc.mgrsadded.splice(index, 1);
    this.configSvc.removeteammgr(this.configSvc.activeitemid, this.configSvc.highlightedMgrRow)
    this.mgrselected = false;
    
  }


}

  
