import { Component, OnInit, Input } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, FormArray, Validators, NgForm} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-flowassignment',
  templateUrl: './flowassignment.component.html',
  styleUrls: ['./flowassignment.component.css']
})
export class FlowassignmentComponent implements OnInit {
  @Input() inittype: string;
  public changesdetected = false;
  public reset: any[] = [{}];
 // public nextbutton = new FormControl('');
public hlteam = [];
public selecteditemindex: number;
public hlassignment = [];
public itemselected;


public teamchosen =false;
public userchosen =false;
public searchtype = 'team';
public assignchanged = false;
//public flow = [];

  findteamclicked = false;
  finduserclicked = false;
 // public assigned = [];
 
  flowassignFG : FormGroup;

  constructor(private _formBuilder: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {
    this.configSvc.filtereduser = [];
    this.configSvc.filteredteam = [];
  this.configSvc.flow = [];
  console.log('this is the inittype '+this.inittype)
  if(this.inittype == 'edit'){ this.configSvc.flow = this.configSvc.nodeds[0].flow}
  
  //  this.assigned = this.configSvc.assigned
   // this.configSvc.assigned = [];
    this.flowassignFG = this._formBuilder.group({
    //  username: [this.configSvc.filtereduser, Validators.required],
      name: ['', Validators.required],
      itemid: ['', Validators.required],
      clr: ['', Validators.required],
      icontype:['', Validators.required],
      searchtype: ['user', Validators.required],
     
      next:['Next', Validators.required],
      nextc:[false, Validators.required],
      nextcmt:['', Validators.required],
      prev:['Previous', Validators.required],
      prevc:[false, Validators.required],
      prevcmt:['', Validators.required],
      ab:['', Validators.required],
      ao:['', Validators.required],
      

      
    });
    
    
    // this.onChanges();
  }

  onChanges(): void {
    this.flowassignFG.valueChanges.subscribe(val => {
    console.log('the value has changed to '+JSON.stringify(val))
   this.configSvc.flow[this.selecteditemindex]=val
    console.log('teams assigned locally is now set to'+JSON.stringify(this.configSvc.flow))
   
    });}

    public onRecreate(){
      this.reset[0] = {}
    }

   

  usernameFilter(event: Event, type) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, type)
  

  }
  
  }

 

  public addTeamtoflow(team){
    console.log('newly selected team: '+JSON.stringify(team));
    var objTeam =team
    console.log('adding team: '+team)
  
  console.log('objTeam asigned : '+JSON.stringify(objTeam));
  
    this.configSvc.flow.push(objTeam);
    console.log('currently asigned : '+JSON.stringify(this.configSvc.flow))
    this.assignchanged= true;
    if(this.inittype == 'edit'){
    this.configSvc.updateNodeAssignment(this.configSvc.activeitemid, JSON.stringify(this.configSvc.flow))
  }
}

public addUsertoFlow(user){
  console.log('newly selected user: '+JSON.stringify(user));
  var objUser =user
  console.log('adding user: '+user)

console.log('objTeam asigned : '+JSON.stringify(objUser));

  this.configSvc.flow.push(objUser);
  console.log('currently asigned after adding user to flow : '+JSON.stringify(this.configSvc.flow))
  this.assignchanged= true;
  if(this.inittype == 'edit'){
  this.configSvc.updateNodeAssignment(this.configSvc.activeitemid, JSON.stringify(this.configSvc.flow))}
}

public assignUser(user){
  console.log('adding user: '+user)
  this.configSvc.assigned.push({'type':'user', 'name': user})
  this.flowassignFG.markAsPristine()
}

public removefromFlow(){
  console.log('unassigning  user or team')
  console.log('current flow: '+JSON.stringify(this.configSvc.flow))
  this.configSvc.flow.splice(this.selecteditemindex, 1)
  this.assignchanged= true;
  console.log('this is the itemdid iv selected '+this.flowassignFG.value.itemid)
  this.configSvc.removefromFlow(this.configSvc.activeitemid,  this.hlassignment[0]._id)
 
}

public chooseItem(selection, type){
  
  console.log('selected  is '+JSON.stringify(selection))
  console.log('selected team name is '+selection.name)
  this.itemselected= false;
  if(type == 'team'){
  this.flowassignFG.patchValue({'name' : selection.name});
  this.flowassignFG.patchValue({'itemid' : selection._id});
  this.flowassignFG.patchValue({'clr' : selection.clr});
  this.flowassignFG.patchValue({'icontype' : selection.icontype});
  this.teamchosen = true;
  }
  if(type == 'user'){
    this.flowassignFG.patchValue({'name' : selection.inboxemail});
    this.flowassignFG.patchValue({'clr' : 'blue'});
    this.flowassignFG.patchValue({'icontype' : 'person'});
    this.flowassignFG.patchValue({'itemid' : selection._id});
    console.log('selected itemid of user is '+selection._id)
    console.log('selected itemid of user is '+this.flowassignFG.value.itemid)
    this.userchosen = true;
    }
  
}



public  selectTeam(team) {
  this.hlteam = [];this.hlassignment=[]
   console.log('the team seelected is set to '+team);
 
   team['next'] = 'Next';
 
 team['status'] = 0;
 this.hlteam.push(team);

 


 
   console.log('highlighed team: ' + JSON.stringify(this.hlteam));
    }

    public selectAssignment(assigned, index){
   
      this.selecteditemindex = index
      this.hlteam = [];this.hlassignment = [];
      this.hlassignment.push(assigned);
      this.itemselected= true;
    }

    drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.configSvc.assigned, event.previousIndex, event.currentIndex)
    console.log('this is the assignment after moving '+JSON.stringify(this.configSvc.assigned))
    this.configSvc.updateNodeAssignment(this.configSvc.activeitemid, JSON.stringify(this.configSvc.assigned))
    }

   public updateAssignment(activeitemid){
     console.log('updating assignment for flow '+activeitemid)
   //  console.log('updating assignment for item '+this.configSvc.flowitemdetailsds[0].itemid);
     console.log('this is the new assignment'+JSON.stringify(this.configSvc.flow))
     this.configSvc.updateAssignment(activeitemid, JSON.stringify(this.configSvc.flow))
   }


  }


