<mat-card class = "activestepper">

    <mat-tab-group>
      <mat-tab *ngFor="let tab of this.configSvc.nodeds[0].stepper; let tabindex = index; " 
      
      
      >
      <ng-template mat-tab-label> 
          <i class="material-icons icon-size" [ngStyle]="{'color': tab.iconcolor}">{{tab.icontype}}</i>
          {{tab.step}}</ng-template>
        
       <form id="myForm" [formGroup]="previewFG" >
            <span [formArrayName]="'formArrayName'" class="popup-container">
              <span class = "popuptitle">{{tab.desc}}</span> 
            <div *ngFor="let property of this.previewArray.controls; let i = index">
           
              <div [formGroupName]="i" *ngIf="property.value.step.value == tabindex" >
             
               
                <div *ngIf="property.value.formtype.value == 'string'"  > 
                  <span >
                  <span *ngIf="property.value.label.value==true" class = "labels">
                    <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                   
                 
                  <mat-form-field appearance="fill" class= "large-item"> 
                   <span *ngIf="property.value.label.value==false">
                        <mat-label >{{property.value.formitem.value}} </mat-label></span>
                        <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                       </mat-form-field>
                    
    
                     <div *ngIf="property.value.label.value==false" class = "labels">
                     
                     <mat-form-field appearance="outline" floatLabel="always"> 
                       <mat-label >{{property.value.property.value}} </mat-label>
                           <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                          </mat-form-field>
                         </div> 
                     
                  </span>     </div> 
           
                  <div *ngIf="property.value.formtype.value == 'number'" > 
                    <span>
      <span *ngIf="property.value.label.value==true" class = "labels">
                      <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                     
                   <mat-form-field appearance="fill" class= "small-item">  
                       
                         <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
       </mat-form-field></span>
      
       <span *ngIf="property.value.label.value==false" class = "labels">
    
        <mat-form-field appearance="outline" floatLabel="always"> 
          <mat-label >{{property.value.property.value}} </mat-label>
              <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
             </mat-form-field>
      </span></span>
    </div>  
           
    <span *ngIf="property.value.formtype.value == 'boolean'"> 
     
      <div class= "labels"> 
  
        <mat-slide-toggle 
        [checked] = property.value.hasdefault
        (change)="changeBoolean(property.value.hasdefault, i)">
                                
         {{property.value.formitemlabel.value}}
          </mat-slide-toggle>
       
         
        </div> 
  
                     </span>
           
                        <span *ngIf="property.value.formtype.value == 'date'"  > 
                          <span *ngIf="property.value.label.value==true" class = "labels">
                            <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                           </span> 
                        <mat-form-field appearance="fill">
                          
                          <input matInput [matDatepicker]="picker1"  formControlName="itemvalue" class= "medium-date">
                               <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                               <mat-datepicker  #picker1></mat-datepicker>
                             </mat-form-field></span>
    
    
                       <span *ngIf="property.value.formtype.value == 'checkbox'"> 
                             
                              
                               <mat-checkbox formControlName="hasdefault">
                                {{property.value.formitemlabel.value}} </mat-checkbox>
                     </span>
       
  
                     <span *ngIf="property.value.formtype.value == 'text'"  > 
                         
                      <span class = "labels">
                        <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                       </span> 
                     
                       <mat-form-field appearance="outline"   class= "xlarge-item">
                          <textarea
                          
                          formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                         </mat-form-field>
                         
                 </span> 
  
                 <span *ngIf="property.value.formtype.value == 'choicelist'"  class= "medium-item">
  
  
                  <span>
                    <span class = "labels">
                    <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
  <mat-form-field>
                
                   <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue">
               <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                 {{choice.name}}
               </mat-option>
             </mat-select>
            
           </mat-form-field>
          </span>
           
         
        </span>
  
             <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
              <span  *ngIf="property.value.label.value==true" >
                     <span >
                       <div class = "labels">
                       <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
                     <mat-radio-group
                     class="radio-container">
                     <mat-radio-button
                     
                   
                    
                     *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                     [value]="choice.name"
                     [checked]="choice.name == property.value.itemvalue"
                     (change)="changeRadio(choice, i)"
                     >
                       {{choice.name}}
                     </mat-radio-button>
                   </mat-radio-group>
                  
                 </span>
               </span>
            
                </div>
  
                <div *ngIf="property.value.formtype.value == 'treelist'  ">
               
                  <span *ngIf="property.value.label.value==true"  class= "treecontainer">
         <span class = "labels">
         <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
  
      
       <mat-chip-list>
  
         
         <mat-chip *ngFor="let paths of this.sortPath()"
         (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
         >
           {{paths.name}}
     </mat-chip>
     </mat-chip-list>
  
   
       <div *ngIf="this.clickchoiceset == true" class="outerbox">  
         <span  >
         <table mat-table [dataSource]="this.tagerSvc.activetree"  >
         
           <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
           (click)="this.getChoicetree(selectedrow._id); 
          
           
           this.hlchoice(selectedrow,  i);
           "
           [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
           class="innerbox"
           ></tr>
         
         
                    
         <ng-container matColumnDef="Name" >
               <th mat-header-cell *matHeaderCellDef>Name </th>
            <td mat-cell *matCellDef="let element">
             <span *ngIf="element.haschild">  
               <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
               </span>
                {{element.name}}
          </td></ng-container>
         
            </table> </span>
  </div>
  
  
  
  
  </span>
             </div>
  
  
                                   
                            </div> 
            </div>
            
           
          </span>
             </form>
    
             <span class = "actions">
  
         
            
             <span *ngIf = "this.configSvc.quickadd == false" >
              <span *ngIf="this.configSvc.nodeds[0].type == 'nodetab' "><button  [disabled]= "!this.previewFG.dirty" mat-button matSuffix 
              mat-raised-button color="primary"  (click)="updateStepper()">Apply Changes</button></span>

         

              <span *ngIf="this.configSvc.nodeds[0].type == 'activeflow'">  <button  [disabled]= "this.configSvc.nodeds[0].status == 2" (click)="this.nextPhase();"
          mat-raised-button color="primary" >Finish</button></span>

          
          <span  class = "save-container" *ngIf="this.configSvc.activetype =='batchitems' && this.configSvc.mode == 'design'">
            <button  [disabled]= "!this.previewFG.dirty" (click)="this.updateBatchitem();"
              mat-raised-button color="primary" [disabled]="!previewFG.dirty">Update Batch</button>
            </span>
          
      
              </span>
  
              
                  </span>
             </mat-tab>
    </mat-tab-group>
  </mat-card>