
<div *ngIf="auth.isLoggedIn()" class = "header">
        


  <mat-toolbar  class = "toolbar-container">
  
    <mat-toolbar-row class ="custom-toolbar">
   

      <button
      mat-icon-button
      *ngIf="sidenav.mode === 'over'"
      (click)="sidenav.toggle(); getrootToolbars()"
    >
      <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
      <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
    </button>

  

 <span *ngIf="this.configSvc.toolmenu == 'general'" class = "scroller">
<app-generaltools></app-generaltools>
</span>

<span *ngIf="this.configSvc.toolmenu == 'toolbartools' || this.configSvc.toolmenu == 'favtoolset' " class = "scroller">
  <app-generaltools></app-generaltools>
  </span>

<span *ngIf="this.configSvc.toolmenu == 'filetools'" class = "scroller">
  <app-filetools></app-filetools>
  </span>

<span *ngIf="this.configSvc.toolmenu == 'teamtools'" class = "scroller">
  <app-teamtools></app-teamtools>
  </span>

  <span *ngIf="this.configSvc.toolmenu == 'roottools'" class = "scroller">
    <app-roottools></app-roottools>
    </span>

<span *ngIf="this.configSvc.toolmenu == 'findtagers'" class = "scroller">
  <app-softwaretools></app-softwaretools>
  </span>

  <span *ngIf="this.configSvc.toolmenu == 'designtools'" class = "scroller">
    <app-designtools></app-designtools>
    </span>

    <span *ngIf="this.configSvc.toolmenu == 'designteam'" class = "scroller">
      <app-designteam></app-designteam>
      </span>

    <span *ngIf="this.configSvc.toolmenu == 'nodetools'" class = "scroller">
      <app-nodetools></app-nodetools>
      </span>

    <span *ngIf="this.configSvc.toolmenu == 'tagtools'" class = "scroller">
      <app-tagtools></app-tagtools>
      </span>

      <span *ngIf="this.configSvc.toolmenu == 'steptools'" class = "scroller">
        <app-steptools></app-steptools>
        </span>

      <span *ngIf="this.configSvc.toolmenu == 'queuetool'" class = "scroller">
        <app-queuetools></app-queuetools>
        </span>

      
      
        <span *ngIf="this.configSvc.toolmenu == 'choicetools'" class = "scroller">
          <app-choicetools></app-choicetools>
          </span>

          <span *ngIf="this.configSvc.toolmenu == 'prompttools'" class = "scroller">
            <app-prompttools></app-prompttools>
            </span>
      
          


    <span *ngIf="this.configSvc.toolmenu == 'favlocations'">
        <app-favlocations></app-favlocations>
        </span>
    
    <!-- This fills the remaining space of the current row -->
    <span class="filler"></span>
   


  <app-topright></app-topright>
 
 </mat-toolbar-row>
 <mat-toolbar-row >

 

  <span *ngIf="this.configSvc.activetype != 'myfeed' && this.configSvc.activetype != 'nodefeed'">
    <mat-chip-list >

     

      <span *ngIf="this.configSvc.mode == 'design'">
        <mat-chip   [ngStyle]="{
          'color': 'white',
          'background-color': 'purple'}" >
  
          <a routerLink="/toolbard/nodeconsole"> 
            <i class="material-icons icon-home"  title="Design Home"
            (click)="this.configSvc.pathds=[];this.configSvc.activesidepanel = 'browser';this.configSvc.toolmenu='';pathSort();this.configSvc.folderpath=[];
            this.configSvc.activetype = 'team' ">brush</i></a>
  
  
          
        </mat-chip></span>

      <span *ngIf="this.configSvc.activesidepanel == 'choicesets'">
      <i class="material-icons icon-set"  title="ChoiceSet Builder">arrow_drop_down_circle</i>
    </span>
  
   
      <span *ngIf="this.configSvc.queueready" class="queuealign">
    <span *ngIf="this.configSvc.activetype == 'queue' || this.configSvc.activetype == 'rootqueue'
     || this.configSvc.activetype == 'queuedirect'" class="queuealign">
    
      <mat-chip   [ngStyle]="{
        'color': 'white',
        'background-color': 'blue'}" >

        <a routerLink="/toolbard/teamqueue"> 
          <i class="material-icons icon-group"  title="Queue Home"
          (click)="this.configSvc.pathds=[];;
     ">all_inbox</i></a>
      </mat-chip>

      <span *ngIf="this.configSvc.activetype == 'queuedirect'" class="queuealign">
      <mat-chip   class="queuealign" [ngStyle]="{
        'color': 'white',
        'background-color': this.configSvc.nodeds[0].iconcolor}"
        >
        <a  [routerLink]="['/toolbard/teamqueue']"
        [queryParams]="{type:'queue', name: this.configSvc.nodeds[0]?.queuename,  teamid: this.configSvc.nodeds[0]?.queueid }"
      
        >
          <i class="material-icons icon-queuegroup"  title="Queue ">
          
            {{this.configSvc.nodeds[0].icontype}}</i>{{this.configSvc.nodeds[0].name}}</a>
  
    
        
      </mat-chip>
      <mat-chip  [ngStyle]="{
        'color': 'white',
        'background-color': this.configSvc.nodeds[0].flowcolor}" >
  
    
          <i class="material-icons icon-queuegroup"  title="Queue "
          >{{this.configSvc.nodeds[0].flowicon}}</i>{{this.configSvc.nodeds[0].name}}
      </mat-chip></span>

      
   
    </span>

    <span *ngIf="this.configSvc.activetype == 'queue' " class="queuealign">

      
      <mat-chip   [ngStyle]="{
        'color': 'white',
        'background-color': this.flowSvc.queueds[0].clr}" >

    
          <i class="material-icons icon-queuegroup"  title="Queue "
         (click)="deselectQueue()" >{{this.flowSvc.queueds[0].icontype}}</i>{{this.flowSvc.queueds[0].name}}
      </mat-chip>

  </span>

  <span *ngIf="this.configSvc.activetype == 'basicflow'" class="queuealign">

    <mat-chip   [ngStyle]="{
      'color': 'white',
      'background-color': 'blue'}" >

      <a routerLink="/toolbard/teamqueue"> 
        <i class="material-icons icon-group"  title="Queue Home"
        (click)="this.configSvc.pathds=[];;
   ">all_inbox</i></a>
    </mat-chip>
 
    <mat-chip   [ngStyle]="{
      'color': 'white',
      'background-color': this.flowSvc.queueds[0].clr}"
      (click)="deselectQueue()">

  
        <i class="material-icons icon-queuegroup"  title="Queue ">
        
        {{this.flowSvc.queueds[0].icontype}}</i>{{this.flowSvc.queueds[0].name}}
    </mat-chip>
    <mat-chip   [ngStyle]="{
      'color': 'white',
      'background-color': this.configSvc.nodeds[0].flowcolor}" >

  
        <i class="material-icons icon-queuegroup"  title="Queue "
        >{{this.configSvc.nodeds[0].flowicon}}</i>{{this.configSvc.nodeds[0].name}}
    </mat-chip>

</span>



  </span>
<span >
  <span *ngIf="this.configSvc.mode == 'browse'">
        <span *ngIf="this.configSvc.activetype =='fold' || this.configSvc.activetype =='base' || this.configSvc.activetype =='team' ||  this.configSvc.activetype =='activeflow' ||
        this.configSvc.activetype =='editteam' || this.configSvc.activetype =='node' || this.configSvc.activetype =='chatgpt' || this.configSvc.activetype =='nodeimage' || this.configSvc.activetype =='file' ||
         this.configSvc.activetype =='wizard' || this.configSvc.activetype =='smartfolder' || this.configSvc.activetype =='nodetab'
          || this.configSvc.activetype =='smartfoldertab' || this.configSvc.activetype =='smartfoldertab'
       ">
       
       <mat-chip   [ngStyle]="{
        'color': 'white',
        'background-color': 'blue'}" >

        <a routerLink="/toolbard/home"> 
          <i class="material-icons icon-home"  title="Browse Home"
          (click)="this.configSvc.pathds=[];this.configSvc.activesidepanel = 'browser';this.configSvc.toolmenu='general';
          this.configSvc.activetype = 'team' ">home</i></a>


        
      </mat-chip>
      
        <mat-chip *ngFor="let paths of this.pathSort()" 
        (click)="this.clickFold()"
        [ngStyle]="{
          'color': 'white',
          'background-color': paths.iconcolor}" 
        
        >
         
        <a 
        [routerLink]="['/toolbard/home']"
        [queryParams]="{itemid:paths.pfoldid, type:paths.type, name: paths.name, level:paths.level, teamid: paths.teamid }"
        [ngStyle]="{
          'color': 'black',
          'background-color': paths.iconcolor
         }" 
         (click)="this.deselect(this.configSvc.activetype)">

         <span *ngIf="paths.type == 'team'"><i  class="material-icons icon-group"  title="Team">{{this.configSvc.teamdetailsds?.icontype}}</i></span>
       

          <span *ngIf="paths.type =='smartfolder' || paths.type =='smartfoldertab' || paths.type =='smartfoldertab'">
            <span>
              <i  class="material-icons icon-smartfolder"
              [ngStyle]="{'color':'yellow'}">{{paths.icontype}}</i>
        </span></span>
        {{paths.name}}
        
        <span *ngIf="paths.type == 'team' && this.configSvc.teamdetailsds?.tagged">
          <i  class="material-icons icon-tagged"  title={{this.configSvc.teamdetailsds.nodename}}  
          [ngStyle]="{'color': this.configSvc.teamdetailsds.tagcolor}">bookmark</i></span>
        <span *ngIf="paths.type == 'fold' && this.configSvc.folderdetailsds?.tagged && this.configSvc.detailsds?.type == 'team'"><i  class="material-icons icon-teamtagged"  title="FolderTagged">bookmark</i></span>
        <span *ngIf="paths.type == 'fold' && this.configSvc.folderdetailsds?.tagged && this.configSvc.folderdetailsds?.type == 'fold'">
          <i  class="material-icons icon-foldertagged"  title={{this.configSvc.folderdetailsds.nodename}}
          [ngStyle]="{'color': this.configSvc.folderdetailsds.tagcolor}">bookmark</i></span>
    
      </a>
          </mat-chip>
        </span>
        
      </span>
        


          

        <span *ngIf="this.configSvc.mode == 'design'">
      
          <mat-chip *ngFor="let paths of this.pathSort()" 
          (click)=" this.clickChip()"
          [ngStyle]="{'color': 'white','background-color': 'purple'}" 
          
          >
           
          <a 
          [routerLink]="['/toolbard/nodeconsole']"
          [queryParams]="{itemid:paths.pfoldid, type:paths.type, name: paths.name, level:paths.level, teamid: paths.teamid }"
          [ngStyle]="{
            'color': 'white',
            'background-color': 'purple'
           }" 
         >
  
         
           <span ><i  class="material-icons icon-group"   [ngStyle]="{'color': paths.iconcolor || 'black'}"  title="Node">{{paths.icontype}}</i>
          </span>
          <span *ngIf="paths.type =='fold'" class="fa-1x">
            <span class="fa-layers fa-fw"><i class="fas fa-folder-open" style="color:yellow"></i></span>  
        </span>

        <span *ngIf="paths.type =='smartfolder' || paths.type =='smartfoldertab' || paths.type =='smartfoldertab' ">
            <span>
              <i  class="material-icons icon-smartfolder"
          [ngStyle]="{'color':paths.iconcolor}">{{paths.icontype}}</i>
        </span></span>
          {{paths.name}}
          

      
      
        </a>
            </mat-chip>

          
    
      </span>

  

      <span *ngIf="this.configSvc.mode == 'choicetools'">

        <mat-chip   [ngStyle]="{
          'color': 'white',
          'background-color': 'blue'}" >
  
          <a routerLink="/toolbard/choicesets"> 
            <i class="material-icons icon-home"  title="Browse Home"
            (click)="clearChoicesets();">home</i></a>
  
  
          
        </mat-chip>
        
        <mat-chip *ngFor="let paths of this.pathSort()" 
        (click)="this.tagerSvc.hlchoiceset=[]"
        [ngStyle]="{
          'color': 'white',
          'background-color': 'gray'}" 
        
        >
         
        <a 
        [routerLink]="['/toolbard/choicesets']"
        [queryParams]="{itemid:paths.pfoldid, type:paths.type, name: paths.name, level:paths.level}"
        [ngStyle]="{
          'color': 'white',
          'background-color': 'gray'
         }" 
         (click)="this.deselect(this.configSvc.activetype)">

        {{paths.name}}
        
    
      </a>
          </mat-chip>
  </span>

  <span *ngIf="this.configSvc.mode == 'prompttools'">

    <mat-chip   [ngStyle]="{
      'color': 'white',
      'background-color': 'blue'}" >

      <a routerLink="/toolbard/prompts"> 
        <i class="material-icons icon-home"  title="Prompt Home"
        (click)="clearChoicesets();">home</i></a>


      
    </mat-chip>
    
    <mat-chip *ngFor="let paths of this.pathSort()" 
    (click)="this.tagerSvc.hlchoiceset=[]"
    [ngStyle]="{
      'color': 'white',
      'background-color': 'gray'}" 
    
    >
     
    <a 
    [routerLink]="['/toolbard/prompts']"
    [queryParams]="{itemid:paths.pfoldid, type:paths.type, name: paths.name, level:paths.level}"
    [ngStyle]="{
      'color': 'white',
      'background-color': 'gray'
     }" 
     (click)="this.deselect(this.configSvc.activetype)">

    {{paths.name}}
    

  </a>
      </mat-chip></span>
    
       <span *ngIf="this.configSvc.nodeready">
          <span *ngIf="this.configSvc.activetype =='nodedirect' || this.configSvc.activetype =='chatgptdirect'  || this.configSvc.activetype =='filedirect'
          || this.configSvc.activetype =='tabbeddirect'">
          <mat-chip *ngFor="let paths of this.sortnodedirectPath()" 
          
          [ngStyle]="{
            'color': paths.iconcolor,
            'background-color': paths.type==='nodedirect' || paths.type==='chatgptdirect' || paths.type==='nodetab' || paths.type==='tabbeddirect'
            || paths.type==='filedirect' ? 'primary' : paths.iconcolor}" 
          
          >
           
          <a 
          [routerLink]="['/toolbard/home']"
          [queryParams]="{itemid:paths.pfoldid, type:paths.type, name: paths.name, level:paths.level, teamid: paths.teamid }"
          [ngStyle]="{
            'color': 'black'
           
           }" 
           (click)="this.deselect(this.configSvc.activetype)">
  
           <span *ngIf="paths.type == 'team'"><i  class="material-icons icon-group"  title="Team">{{this.configSvc.teamdetailsds?.icontype}}</i></span>
         
           <span  *ngIf="paths.type == 'nodedirect' || paths.type == 'chatgptdirect' || paths.type == 'chatgptdirect' || paths.type == 'nodetab' || paths.type == 'tabbeddirect'">
            <i  class="material-icons icon-group"   
            [ngStyle]="{
              'color': paths.iconcolor}"  title="Node">{{paths.icontype}}</i>
           </span>

           <span  *ngIf=" paths.type == 'filedirect' ">
            <span *ngIf=" paths.icontype == 'msg'" class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span> 
            <!--
             <div  *ngIf="this.configSvc.currentrow.ac > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i> 
                <span class="fa-layers-counter" style="background:Tomato">{{this.configSvc.currentrow.ac }}</span></div>
              -->
            <span *ngIf=" paths.icontype == 'txt'" class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span> 
            <span *ngIf=" paths.icontype == 'xlsx'" class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span> 
            <span *ngIf=" paths.icontype == 'docx'" class="fa-layers fa-fw"><i class="fas fa-file-word" style="color:blue"></i></span>
            <span *ngIf=" paths.icontype == 'pdf'" class="fa-layers fa-fw"><i class="fas fa-file-pdf" style="color:red"></i></span>  
            <span *ngIf=" paths.icontype == 'bmp'|| paths.icontype == 'jpeg'|| paths.icontype == 'jpg' || paths.icontype == 'png'" class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blueviolet"></i></span>   
           </span>
          {{paths.name}}
          
          <span *ngIf="paths.type == 'team' && this.configSvc.teamdetailsds?.tagged"><i  class="material-icons icon-tagged"  title="TeamTagged">bookmark</i></span>
          <span *ngIf="paths.type == 'fold' && this.configSvc.folderdetailsds?.tagged && this.configSvc.folderdetailsds?.type == 'team'"><i  class="material-icons icon-teamtagged"  title="FolderTagged">bookmark</i></span>
          <span *ngIf="paths.type == 'fold' && this.configSvc.folderdetailsds?.tagged && this.configSvc.folderdetailsds?.type == 'fold'"><i  class="material-icons icon-foldertagged"  title="FolderTagged">bookmark</i></span>
      
        </a>
            </mat-chip>
          
          </span>
        </span>

        </span>
        </mat-chip-list>
       
</span>
                         
  
  <app-actioninfo></app-actioninfo>

   <!-- This fills the remaining space of the current row -->
   <span class="filler"></span>

   <span *ngIf="this.configSvc.mode == 'browse'">   
  
    <span class ="templicons">
      <i class="material-icons icon-browse"  title="Browse">account_tree</i> 
          Browse</span>
  </span>
   <span *ngIf="this.configSvc.mode == 'queue'">    <span class ="templicons">
    <i class="material-icons icon-queue"  title="Queue">all_inbox</i> 
        Queue</span></span>
   <span *ngIf="this.configSvc.mode == 'design'">    <span class ="templicons">
    <i class="material-icons icon-design"  title="Design">brush</i> 
        Design</span></span>
   <span *ngIf="this.configSvc.mode == 'myfeed'">     <span class ="templicons">
    <i class="material-icons icon-myfeed"  title="Assigned">upcoming</i> 
        Assigned</span></span>
   
   <span *ngIf="this.configSvc.mode == 'prefs'">    
    <span class ="templicons">
      <i class="material-icons icon-prefs"  title="Prefs">manage_accounts</i> 
          Preferences</span>
   </span>

   <span *ngIf="this.configSvc.mode == 'choicetools'">    
    <span class ="templicons">
      <i class="material-icons icon-lists"  title="Lists">list</i> 
          Lists</span>
   </span>

   <span *ngIf="this.configSvc.mode == 'prompttools'">    
    <span class ="templicons">
      <i class="material-icons icon-prompts"  title="Prompts">chat</i> 
          Prompts</span>
   </span>

   <span *ngIf="this.configSvc.mode == 'search'">  
    <span class = "criteria-toggle">
      <mat-slide-toggle 
      [checked] = this.configSvc.hc 
      (change)="toggleCriteria(this.configSvc.hc)"
      >Hide Search Criteria</mat-slide-toggle>
      <span >
        <i class="material-icons icon-search"  title="Search">search</i> 
            Search</span>
     </span> 
    
     </span>

  <span *ngIf="this.configSvc.move" >
    Moving...
      <span [ngStyle]="{'color': 'white'}">{{this.configSvc.moveitem[0]?.name}}  <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.moveitem[0]?.iconcolor}" >
        {{this.configSvc.moveitem[0]?.icontype}}</i></span>
  </span>   
  

 

 </mat-toolbar-row>
</mat-toolbar>
    
 

</div>

<mat-sidenav-container>
 
  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">
  


    <mat-chip (click)="getfavTools()">
      <i class= 
      "material-icons icon-navopen"  title="Favourite Tools">stars</i>Favourites
    </mat-chip>

    <mat-divider></mat-divider>

 

    <div class ="toolbarzone">
      <mat-form-field>
        Filter Toolbar
           <input matInput (keyup)="toolbarFilter($event)" #input autocomplete="off">
         </mat-form-field>
    
       <span *ngIf = "this.configSvc.roottoolbar == false">
         <mat-chip class = "moveup"><span  (click)="this.getParenttoolbar()"><i class="material-icons icon-moveup">
          home</i>Home</span></mat-chip></span> 
  
       <mat-chip-list *ngFor="let element of this.configSvc.toolbards" class ="chiplist">

        <mat-chip class ="chippy">
          <div  *ngIf="element.type == 'nodeteam'" 
                        
          (click)="this.getToolbars(element.type, element._id, 'nodeteam', element)">
          <span class = "sidenavheader" matTooltip="{{element.desc}}"  matTooltipPosition="right">
              <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black', 'margin-right':'5px'}" >
                {{element.icontype}} </i>{{element.name}}</span>
          
          
          </div>
         <div  *ngIf="element.type == 'category'"
         (click)="this.configSvc.getToolbars(element.type, element._id, element.teamid)"> 
         <span class = "sidenavheader"><i class="material-icons md-24" [ngStyle]="{'color': 'yellow', 'margin-right':'3px'}" >
           folder_special</i>{{element.name}}</span>
        </div>

      

         </mat-chip>
       </mat-chip-list>
      
   
       </div>
                                        
    
  

  </mat-sidenav>
 

  <mat-sidenav-content >
<router-outlet></router-outlet>

<div  class = "footer">
        
  <mat-toolbar class = "footer-container">

    <mat-toolbar-row >
  
        <span *ngIf="this.configSvc.activetype =='team' || this.configSvc.activetype =='editteam' || this.configSvc.activetype == 'fold'
    ">
        <mat-paginator   class ="pager"
        [length]="this.configSvc.teamds?.length"
        [pageSize]="pageSize" 
        [hidePageSize]="true"
         [pageIndex]="0"
         [pageSizeOptions]="[20, 50, 100, 200, 500]"
         (page)="handlePage($event)"
         aria-label="Select page of search results"></mat-paginator>
         
</span>

         <span  *ngIf="this.configSvc.activetype == 'myfeed'">
            <mat-paginator   
            [length]="this.configSvc.myfeed?.length"
            [pageSize]="pageSize" 
            [hidePageSize]="true"
             [pageIndex]="0"
             [pageSizeOptions]="[20, 50, 100, 200, 500]"
             (page)="handleFeed($event)"
             aria-label="Select page of search results"></mat-paginator>
             
             </span>

             <span  *ngIf=" this.configSvc.clicksource =='myfeed' && this.configSvc.nodeready">
               
                {{this.configSvc.feeditem+1}} of {{this.configSvc.myfeed.length}}
                <button [disabled]= "this.configSvc.feeditem == 0" (click)="this.previnFeed();"
                mat-raised-button color="primary" >
                <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
               </button>
           
     <span  class = "button-container"> 
       <button  [disabled]= "this.configSvc.feeditem+1 == this.configSvc.myfeed.length" (click)="this.nextinFeed();"
      mat-raised-button color="primary" >
      <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i></button></span>
   </span>
<span *ngIf=" this.configSvc.clicksource =='browse' && this.configSvc.nodeready">
   <span *ngIf=" this.configSvc.activetype == 'nodedirect' || this.configSvc.activetype == 'chatgptdirect' || this.configSvc.activetype == 'filedirect' || 
   this.configSvc.activetype == 'nodetab'  || this.configSvc.activetype == 'tabbeddirect' ">
               
      {{this.configSvc.folderitem+1}} of {{this.configSvc.teamds?.length}}
      <button [disabled]= "this.configSvc.folderitem == 0" (click)="this.previnFold();"
      mat-raised-button color="primary" >
      <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
 </button>
 
<span  class = "button-container"> 
<button  [disabled]= "this.configSvc.folderitem+1 == this.configSvc.teamds.length" 
(click)="this.nextinFold();"
mat-raised-button color="primary" >
<i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i>
</button></span>
</span>

<span *ngIf="this.configSvc.nodeds[0]?.type == 'chatgpt' && this.configSvc.mode == 'browse'" class = "converse">

  
  <form [formGroup]="appFG">
  <mat-form-field appearance="fill" floatLabel="always" class ="chat-bottom"> 
    <input 
    formControlName =converse
    matInput type="text"
    placeholder="Continue Conversation..."
    onfocus="this.placeholder = ''"
    >
        </mat-form-field>




 
     <button  [disabled]= "!this.appFG.dirty"
     mat-icon-button
    
     (click)="askChatgpt('addcomment')"
   >
     <mat-icon > send </mat-icon>
 
   </button>
   </form>
</span>

<span *ngIf="this.configSvc.nodeds[0]?.type == 'file' && this.configSvc.mode == 'browse'" class = "converse">

  
  <form [formGroup]="appFG">
  <mat-form-field appearance="fill" floatLabel="always" class ="chat-bottom"> 
    <input 
    formControlName =converse
    matInput type="text"
    placeholder="Ask AI about this file..."
    onfocus="this.placeholder = ''"
    >
        </mat-form-field>




 
     <button  [disabled]= "!this.appFG.dirty"
     mat-icon-button
    
     (click)="askChatgpt('file')"
   >
     <mat-icon > send </mat-icon>
 
   </button>
   </form>
</span>


</span>

<span *ngIf="this.configSvc.activetype == 'queuedirect' ">
               
   {{this.configSvc.folderitem+1}} of {{this.configSvc.queueitemds?.length}}
   <button [disabled]= "this.configSvc.folderitem == 0" (click)="this.previnFold();"
   mat-raised-button color="primary" >
   <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
</button>

<span  class = "button-container"> 
<button  [disabled]= "this.configSvc.folderitem+1 == this.configSvc.queueitemds.length" 
(click)="this.nextinFold();"
mat-raised-button color="primary" >
<i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i>
</button></span>
</span>


   <span *ngIf="this.configSvc.activetype == 'search'">
        
    <mat-paginator   
    [length]="this.configSvc.teamds?.length"
    [pageSize]="pageSize" 
    [hidePageSize]="true"
     [pageIndex]="0"
     [pageSizeOptions]="[20, 50, 100, 200, 500]"
     (page)="handleSearch($event)"
     aria-label="Select page of search results"> 
   </mat-paginator>
 
     
    </span>
  

          

  </mat-toolbar-row>

</mat-toolbar>

</div>
</mat-sidenav-content>
</mat-sidenav-container>






