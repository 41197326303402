
<div  [formGroup]="edituserFG"  >
  <mat-card class="findusercard">

                                
                                  

                                   <div >
                                     <mat-form-field appearance="fill">
                                       <mat-label>Assign</mat-label>
                                      
                                       <input type="text"  
                                       formControlName="usersearch"  
                                       [matAutocomplete]="auto"
                                       
                                       matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
                                       
                                       <mat-autocomplete #auto="matAutocomplete">
                                  <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
                                   {{option.inboxemail}} 
                                 
                                  </mat-option>
                                       </mat-autocomplete>
                                 
                                      
                                      </mat-form-field>
              </div>
  <span class = "btns">
      <button mat-raised-button color="primary"
      [disabled]= "!this.edituserFG.dirty"
      (click)="this.addteamUser(this.configSvc.activetype,  this.edituserFG.value.usersearch)">Add User</button>
                               
          
            <button mat-raised-button color="primary" 
            [disabled]= "!this.userselected"
             (click)="this.removeUser()">Remove User</button>
           </span>
                              

   <div class="example-list">
   <div class="box-list" *ngFor="let selectedusers of this.configSvc.usersadded"
    (click)="this.configSvc.selectUserRow(selectedusers, true); this.userselected = true"
     [style.background]=" this.configSvc.highlightedUserRow.indexOf(selectedusers) == 0 ? 'lightblue' : ''">
      <div>
   
    </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{selectedusers}}
     </div></div>
   </div>


    
              
                 </mat-card>
           </div>

       
                       