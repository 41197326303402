<div>
    <mat-form-field>
        <input matInput placeholder="Email" [formControl]="invitedmgrfc">
        <mat-error *ngIf="invitedmgrfc.hasError('email') && !invitedmgrfc.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="invitedmgrfc.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary"
(click)="this.inviteUser( invitedmgrfc.value)">Invite New User</button>

    

  <mat-card >
<mat-grid-list cols= "5" rows = "5" rowHeight = "30px">
  
<mat-grid-tile class="userlist" colspan= "3" rowspan = "10">
  <div class="example-list">
    
 

 <table mat-table [dataSource]="this.configSvc.usersinvited"  >
        
        

            
 <ng-container matColumnDef="email">
       <th mat-header-cell *matHeaderCellDef>Email </th>
    <td mat-cell *matCellDef="let element">
     
        {{element.email}}
  </td></ng-container>


<ng-container matColumnDef="ab">
<th mat-header-cell *matHeaderCellDef>Invited By</th>
<td mat-cell *matCellDef="let element">

{{element.ab}}
</td></ng-container>

<ng-container matColumnDef="ao">
<th mat-header-cell *matHeaderCellDef>Invited On</th>
<td mat-cell *matCellDef="let element">

{{element.ao | date:'medium'}}  
</td></ng-container>

<ng-container matColumnDef="accept">
  <th mat-header-cell *matHeaderCellDef></th>
  <td mat-cell *matCellDef="let element">
    <span *ngIf="element.accept== true">
      <i class="material-icons accepted" [ngStyle]="{'color':'darkkhaki'}">mark_email_read</i></span> 
      <span *ngIf="element.accept== false">
        <i class="material-icons accepted" [ngStyle]="{'color':'darkkhaki'}">unsubscribe</i></span> 
      

  
  </td></ng-container>

  

<tr mat-header-row *matHeaderRowDef="emailColumns;  sticky: true"></tr>
<tr mat-row *matRowDef="let invited; columns: emailColumns; let i2 = index;"
(click)="this.selectInvitedmgrs(invited)"
[style.background]=" this.hlemail.indexOf(invited) == 0 ? 'lightblue' : ''"

></tr>

      
 
    </table></div>
</mat-grid-tile>
                 <mat-grid-tile colspan= "1" rowspan = "5">
                   <span fxLayout="column" fxLayoutAlign="space-around center">
<span > 


<button mat-raised-button color="primary"
(click)="this.inviteUser(this.hlemail[0]?.email)">
Resend Invite to {{this.hlemail[0]?.email}} </button>
</span> 

</span>
                 </mat-grid-tile>

      </mat-grid-list>
    </mat-card>
  </div>
