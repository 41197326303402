import { Component, OnInit } from '@angular/core';
import { AuthenticationService, TokenPayload } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-completeregistration',
  templateUrl: './completeregistration.component.html',
  styleUrls: ['./completeregistration.component.css']
})
export class CompleteregistrationComponent implements OnInit {
  error = '';
  constructor( private auth: AuthenticationService,  private router: Router) { }

  ngOnInit(): void {
    console.log('sending this registration url to the auth service'+this.router.url);
    var regurl = this.router.url
    
    this.auth.completeregistration(regurl).subscribe((registrationresults) => {
      console.log('completed registration');
      if(registrationresults.registration == 'completed'){
      this.router.navigateByUrl('/registeruser?type=companyreg');}
    }, (err) => {
      this.error = err.error.message;
      console.error('An error occurred:', err.error.message);
      console.error(err);
    });
  }

}
