<mat-tab-group >

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon> {{this.configSvc.nodeteamdetailsds[0].icontype}}</mat-icon>
            {{this.configSvc.nodeteamdetailsds[0].name}}
          </ng-template>
     
        <mat-card [formGroup]="editnodeteamFG"  class = "details-container">
        
          <div><mat-form-field appearance="fill" floatLabel="always" class ="large-item">
          
          <mat-label >Node Team Name</mat-label><input matInput [readonly]= "!this.configSvc.itemlocked" formControlName="teamname" matInput value={{this.configSvc.activename}}>
          <mat-icon *ngIf="this.configSvc.itemlocked" matSuffix>mode_edit</mat-icon></mat-form-field></div>
  
          <div><mat-form-field appearance="fill" floatLabel="always" class ="large-item">
          
              <mat-label >Node Team Description</mat-label><input matInput [readonly]= "!this.configSvc.itemlocked" formControlName="teamdesc">
              <mat-icon *ngIf="this.configSvc.itemlocked" matSuffix>mode_edit</mat-icon></mat-form-field></div>
     
              <div class = "medium-item"> 
        <mat-form-field appearance="outline" floatLabel="always">
           <mat-label>Type</mat-label><input  matInput [readonly]= "true" placeholder={{this.configSvc.activetype}}>
        </mat-form-field></div>
     
        <div class = "medium-item"> 
        <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>TeamID</mat-label><input  matInput placeholder={{this.configSvc.activeitemid}}>
         </mat-form-field></div>
  
         <div class = "medium-item"> 
   <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Level</mat-label><input  matInput placeholder={{this.configSvc.activelevel}}>
         </mat-form-field></div>
  
       
           
          
  
       
  
       
       
       
         <mat-card-actions *ngIf="this.configSvc.itemlocked">
  
             <button mat-raised-button color="primary" (click)="this.deleteTeam(currentrow)">Delete Team</button>
  
           <span *ngIf="this.configSvc.datachanged">
           <button mat-raised-button color="primary"  (click)="this.configSvc.renameTeam(this.newfoldervalue, this.configSvc.activename);">Rename {{this.configSvc.activename}}</button>
                       </span>
     
  
           
        </mat-card-actions></mat-card>
  </mat-tab>
  
  
 
  <mat-tab label="Designers">
    <ng-template mat-tab-label>
        <mat-icon>admin_panel_settings</mat-icon>
       Manager
      </ng-template>
         <app-teamsecurity></app-teamsecurity>   
        
                      
    </mat-tab>

    <mat-tab label="Users">
        <ng-template mat-tab-label>
            <mat-icon>admin_panel_settings</mat-icon>
           Users
          </ng-template>
       <app-usersecurity></app-usersecurity>       
                        
      </mat-tab>
  
  <mat-tab label="NodeTeam Design">
    <ng-template mat-tab-label>
        <mat-icon>palette</mat-icon>
       Design </ng-template>
                         <div>
                            <app-iconcategories></app-iconcategories>
                          <app-color-picker (color)="this.configSvc.teamdetailsds.clr"></app-color-picker>
                          <button mat-raised-button color="primary" (click)="this.configSvc.changeteamcolor(this.configSvc.activeitemid, this.configSvc.color)">Change Colour</button>
                          </div>
   </mat-tab>
  
  
  </mat-tab-group>
  
  
  