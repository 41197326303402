import { Injectable } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from './authentication.service';
import {ConfigService} from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  public datenow;
  public flowtextracted: Boolean
  user: TokenPayload;
  public activeflowds = [];
  public queueds = []
  public queuedetailsready = false;
  constructor(private authService: AuthenticationService, public configSvc: ConfigService) { }

  public launchFlow(quickadd: boolean, type: string, flowname: string, flowdesc: string, flowid: string,  newpropvalues: string, iconcolor:string, 
    icontype: string, folderid: string, level: string, attach, textract, indextype, deadline?, deadlineval?, 
    statetracker?: boolean, state?: boolean, statelabel?: string, 
    cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atuser?, emoji?, attachedfile?, textractype?, ischatgpt?)
  {
    this.datenow = Date.now();
    this.flowtextracted = false
    const launchflowForm = new FormData();
    launchflowForm.append('quickadd', quickadd.toString());
    launchflowForm.append('type', type);
    launchflowForm.append('flowname', flowname);
    launchflowForm.append('flowdesc', flowdesc);
    launchflowForm.append('flowid', flowid);
    launchflowForm.append('folderid', folderid);
    launchflowForm.append('level', level);
    launchflowForm.append('newpropvalues', newpropvalues  );
 
    launchflowForm.append('icontype', icontype);
    launchflowForm.append('iconcolor', iconcolor);
    launchflowForm.append('addedon', this.datenow);
    launchflowForm.append('teamid', this.configSvc.activeteamid);
  
   // launchflowForm.append('activestepper', JSON.stringify(this.configSvc.nodeds[0].stepper));
    launchflowForm.append('attach', attach);

    if(attach){
      launchflowForm.append('upload1', attachedfile[0]);
    }
    console.log('textract value on launchflow'+textract)
    launchflowForm.append('textract', textract);

    if(textract){
      launchflowForm.append('textractype', textractype);
    }
  
    launchflowForm.append('deadline', deadline);
 
    if(deadline) {
     // var seconds = deadlineval.getTime() / 1000;
     launchflowForm.append('deadlineval', deadlineval);
    }
    launchflowForm.append('statetracker', statetracker.toString());
    if(statetracker) {
      launchflowForm.append('statelabel', statelabel);
    }
    console.log('this is the cmt folder value '+cmt)
    launchflowForm.append('cmt', cmt);
         if(cmt){
          launchflowForm.append('prompt', cmtval);
          launchflowForm.append('atcmt', atcmt);
        
           

           if(atcmt){
            launchflowForm.append('cmtuser', atuser);
           }
           
          
           launchflowForm.append('cmticon', cmticon);
           launchflowForm.append('cmtclr', cmtclr);

          }

 
   
 this.authService.launchFlow(launchflowForm, this.user).subscribe(launchflowresults => {

    console.log('this is the results from launching flow: ' + JSON.stringify(launchflowresults));
    if(!textract){
     this.configSvc.teamds = launchflowresults;
     this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
     }
     if(textract){
      this.configSvc.nodeds = [launchflowresults]
     this.flowtextracted = true
    console.log('this is the flowtextracted value '+this.flowtextracted)
     }
  }, (err) => {
    console.error(err); });

  }

  getActiveflow(activeflowid: string) {
    this.configSvc.flowready = false;
    console.log('getting active flow now');
    console.log(' active type is now'+this);
    const activeflowForm = new FormData();
    activeflowForm.append('activeflowid', activeflowid);
    activeflowForm.append('type', this.configSvc.activetype);
    this.authService.getActiveflow(activeflowForm, this.user).subscribe(activeflowresults => {
      console.log('this is the search results in json format: ' + JSON.stringify(activeflowresults));
     this.configSvc.nodeds = activeflowresults
     console.log('this is the name results'+JSON.stringify(this.configSvc.nodeds[0].name))
     console.log('this is the getactiveflow results'+JSON.stringify(this.configSvc.nodeds[0]));
  this.configSvc.flowready = true;
  /*

     for (let i = 0; i < activeflowresults.length; i++) {
      console.log('adding active flow number '+[i])
      const flowitem = activeflowresults[i].assign
      for (let i2 = 0; i2 < flowitem.length; i2++) {
        const assignment = [flowitem[i2]]
        assignment.push({'formname':activeflowresults[i].name})
        
      this.configSvc.tracker.push(assignment)}
    
  }
  */

   //  this.configSvc.tracker = activeflowresults.assign
   //  console.log('this is the tracker results'+JSON.stringify(this.configSvc.tracker));
  }, (err) => {
    console.error(err); });

    }

    nextPhase(activeflowid: string, phase: string, status: string, teamid: string, name: string) {
      console.log('getting active flow now');
      this.datenow = Date.now();
      const nextphaseForm = new FormData();
      nextphaseForm.append('activeflowid', activeflowid);
      nextphaseForm.append('phase', phase);
      nextphaseForm.append('status', status);
      nextphaseForm.append('teamid', teamid);
      nextphaseForm.append('addedon',  this.datenow );
      nextphaseForm.append('name', name);
      nextphaseForm.append('type', this.configSvc.activetype);
      this.authService.nextPhase(nextphaseForm, this.user).subscribe(nextphaseresults => {
        console.log('this is the nextphase results in json format: ' + JSON.stringify(nextphaseresults));
       this.configSvc.nodeds = [];
       this.configSvc.queueitemds = nextphaseresults;
       this.configSvc.activetype = 'queue';
       this.configSvc.activeflow = '';
    }, (err) => {
      console.error(err); });
  
}

public prevPhase(activeflowid: string, phase: string, prevphase: string, status: string, teamid: string, name: string, assignedat: string) {
  console.log('getting active flow now');
  const prevphaseForm = new FormData();
  this.datenow = Date.now();
  prevphaseForm.append('activeflowid', activeflowid);
  prevphaseForm.append('phase', phase);
  prevphaseForm.append('prevphase', prevphase);
  prevphaseForm.append('status', status);
  prevphaseForm.append('teamid', teamid);
  prevphaseForm.append('assignedat', assignedat);
  prevphaseForm.append('name', name);
  prevphaseForm.append('addedon', this.datenow);
  prevphaseForm.append('type', this.configSvc.activetype);
  this.authService.prevPhase(prevphaseForm, this.user).subscribe(prevphaseresults => {
    console.log('this is the nextphase results in json format: ' + JSON.stringify(prevphaseresults));
    this.configSvc.nodeds = [];
   this.configSvc.queueitemds = prevphaseresults;
   this.configSvc.activetype = 'queue';
   this.configSvc.activeflow = '';
}, (err) => {
  console.error(err); });

  }

  public createFlow(nodename, nodedesc, type)
{
  console.log('this is the node'+nodename)
  console.log('this is the node desc'+nodedesc)
  console.log('this is the node type'+type)
  this.datenow = Date.now();

  const createNodeForm = new FormData();
  createNodeForm.append('name', nodename);
  createNodeForm.append('desc', nodedesc);
  createNodeForm.append('teamid', this.configSvc.activeteamid);
  createNodeForm.append('level', this.configSvc.activelevel);
  createNodeForm.append('parentid', this.configSvc.activeitemid);
  createNodeForm.append('type', type);
  createNodeForm.append('addedon', this.datenow);
  createNodeForm.append('icontype', this.configSvc.chosenIcon);
  createNodeForm.append('iconcolor', this.configSvc.color);
  createNodeForm.append('flow', JSON.stringify(this.configSvc.flow));
  


this.authService.createFlow(createNodeForm, this.user).subscribe(createnoderesults => {
  console.log('this is the results from createing Node: ' + JSON.stringify(createnoderesults));
   this.configSvc.nodeteamds = createnoderesults;
  // this.colorinputtype = 'tager'
  //this.findkanbansetclicked = true;
  
 
}, (err) => {
  console.error(err); });

}

public getQueueDetails(teamid)  {
  console.log('getting queue details now for '+teamid);
  this.queuedetailsready = false
  const getQueueDetailsForm = new FormData();
  getQueueDetailsForm.append('teamid', teamid);
  
 



  this.authService.getQueueDetails(getQueueDetailsForm, this.user).subscribe(queuedetailsresults => {
    console.log('this is the results from queuedetailsresults: ' + JSON.stringify(queuedetailsresults));

  
     this.queueds= [queuedetailsresults];
     this.queuedetailsready = true
    

   }, (err) => {console.error(err); });


   
}

public deselectQueue(){
 
 console.log('deselecting queue and resetting nodeds to blank')
   this.configSvc.nodeready = false;
  this.configSvc.activetype = 'queue'
  this.configSvc.nodeds = [];  this.configSvc.hlitem = [];
  this.queuedetailsready = true
  this.configSvc.activetype == 'queue'
  
//  this.configSvc.activetype = 'rootqueue'; 
 
}

      
}
