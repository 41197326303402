import { Component, OnInit, Input } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UploadService} from '../../services/upload.service';

@Component({
  selector: 'app-emailtags',
  templateUrl: './emailtags.component.html',
  styleUrls: ['./emailtags.component.css']
})
export class EmailtagsComponent implements OnInit {

  @Input() emailsource: string;
  constructor(private fb: FormBuilder, public configSvc: ConfigService, public uploadSvc: UploadService) { }

  public emailtagsFG: FormGroup;
  public emaildata = [];
  textarearowmin = 5;
texarearowmax = 10;

  ngOnInit(): void {

    console.log('emailsource is '+this.emailsource)
    /*
    if(this.emailsource == 'editfile'){
      this.emaildata = this.configSvc.currentrow.email
      console.log('emaildata is '+JSON.stringify(this.emaildata))
    }
    if(this.emailsource == 'nodeemail'){
     // this.emaildata = this.configSvc.linkeditem[0].email
     this.uploadSvc.getItembyid(this.configSvc.linkeditem[0].attachid)
    }
    */
    console.log('emaildata is '+JSON.stringify(this.configSvc.emaildata))
    this.emailtagsFG = this.fb.group({
     
      subject: [this.configSvc.nodeds[0].email[0].subject, Validators.required],
      emailbody: [this.configSvc.nodeds[0].email[0].emailbody, Validators.required],
      from : [this.configSvc.nodeds[0].email[0].from, Validators.required],
      to: [this.configSvc.nodeds[0].email[0].to, Validators.required],
      cc: [this.configSvc.nodeds[0].email[0].cc, Validators.required],
     
    });
  }


}
