import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {DoccommentsService} from '../../services/doccomments.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UploadService} from '../../services/upload.service';

@Component({
  selector: 'app-editfile',
  templateUrl: './editfile.component.html',
  styleUrls: ['./editfile.component.css']
})
export class EditfileComponent implements OnInit {





  constructor(public uploadSvc: UploadService, private fb: FormBuilder, public configSvc: ConfigService, 
    public commentSvc: DoccommentsService) { }

  ngOnInit() {
   
    console.log('initing the editfile')
    this.configSvc.currentrow = this.configSvc.nodeds[0];



    console.log('finished initing the editfile')
  }

  ngOnDestroy() {
    console.log('destroying editfile component now');
    this.uploadSvc.imageverready = false
  
  }

 


}
