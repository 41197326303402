<div *ngIf="this.sideSvc.activesidenav == 'navigation'"class = "nav-container">
   
   

    <i class="material-icons icon-toolbars"  title="Toolbars"(click)="getrootToolbars()">construction</i>
   

  
 
  </div>


  <div *ngIf="this.sideSvc.activesidenav == 'toolbars'"class = "nav-container"
  
  >
   
   

   <app-toolbard></app-toolbard></div>
