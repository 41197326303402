
import { Injectable, ViewChild } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';
import {ConfigService} from '../services/config.service';
import {Formmodel, Activeformmodel} from '../model/formmodel';

//import { ChartDataSets } from 'chart.js';
//import { Label } from 'ng2-charts';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TagerService {
  public setdetailsds = [];
  public setready = false;
  public treepath = [];
  public activetree = []
  public cachedchoices = [];

  public hlchoiceset = [];
  public rootchoiceconsole = false;
public choicepathds = [];
  
  user: TokenPayload;
  // public categories = [];
  public catpath = [];
  public categories1 = [];
  public categories2 = [];
  public categories3 = [];
  public categorylevel1 = [];
  public categorylevel2 = [];
  public categorylevel3 = [];


  public cattagers = [];
  public hltags = [];
  public hltagonly = [];
  public changesapplied = true;
  public tagitems = [];
  public tags = [];
  public ready =false;
  public activetager = [];
  public activetrack = [];
  public hlTager = [];
  public hlStep = [];
  public gotchartdata =false;

  
  // public barChartLabels: Label[]; 
  // public barChartData: ChartDataSets[] = [null];
  public currFormtemplate : Formmodel;
  public currFormstep : Formmodel;
  public currFormitem : Formmodel;
 
  public currFormChoiceSet : Formmodel;
  public currFormChoiceitem: Formmodel;
  public formpreview :Formmodel;
  public activeform :[Activeformmodel];

  
  public stepitemlist = [null];
  public totalstepitems = 0;
  public totalcompleteditems = 0;
  public totalpercent =0;

  public stepitemcompletedlist = [null];
 public formaction = '';
 public datenow;
 
 public stepsetready = false;
 public steplist = [];
 
 public formtype = 'formtemplate';

 
 public formtemplate = [];  public formtemplatesel = false; public hlStepset = [];
 public formsteps = []; public formstepsel = false; 
 public formitems = [];public formitemsrch= [];public findformitemclicked = false; public formitemsel = false; public hlStepitem = new Array(1)  ;
 public formchoiceset = []; public formchoicesetsrch = []; public findformchoicesetclicked = false;  public choicesetsel = false; public highlightedFormChoiceSet = [];
 public formchoiceitems = []; public highlightedFormChoiceitem = []; public choiceitemsel = false;

 //list variables
 public type: string;
 public listtype: string;
 public choiceset = [];
 public choicepath = [];
 public choiceitems = [];
 public choicesetsrch = [];

 public findchoicesetclicked = false;
 public promptlist = [];
 public hlChoiceitem = [];
 public hlChoiceset = [];

 public kanbans = [];  public kanbansel = false; public hlkanban = [];



  
  public choicesets = [];
 
  public stepresults = false;
  public stepsets = [] // this is the search results stepsets array
  public stepset = []; // this assigned stepset
  public kibrowse = false;
  public findkanbanclicked = false;
  public kanbansrch = [];
  public kanbanset =[];
  public tracks = [];
  public kanbantype = 'kanbanset';
  public tracksrch = [];
  public findkanbanitemclicked = false;
  public findkanbansetclicked = false;
  kanbansetsel = false;
  tracksel = false;
  public hlKanbanset = [];
  public kanbansec = [];
 
  public hltrack = [];
  public hlactivetrack =[];
 // public currKanbanset: Kanbanmodel;
  public tracksearchselected = false;
  public kanbanaction = '';
  public dynapath = [];
  public activepath = '';
  public stepsetactive = false;
  public trknotstrt = false;

  constructor(private authService: AuthenticationService, public configSvc: ConfigService, private authSvc: AuthenticationService) { }

  
  launchTagerseq(folderid, nodename, tagercnt, stepsetid, stepsn, icontype, iconcolor, formdesc, kanbansetid, flow, track){
     
    console.log('kanbanset id is now'+kanbansetid);
    this.datenow = Date.now();
             
    const launchTagerseq = new FormData();
    launchTagerseq.append('stepsetid', stepsetid);
    launchTagerseq.append('nodename', nodename);
    launchTagerseq.append('tagercnt', tagercnt);
    launchTagerseq.append('stepsn', stepsn);
    launchTagerseq.append('folderid', folderid);
    launchTagerseq.append('icontype', icontype);
    launchTagerseq.append('iconcolor', iconcolor);
    launchTagerseq.append('formdesc', formdesc);
    launchTagerseq.append('tagerid', kanbansetid);
    launchTagerseq.append('flow', flow);
    launchTagerseq.append('track', track);
    launchTagerseq.append('addedon', this.datenow);
   
    this.authService.launchTagerseq(launchTagerseq, this.user).subscribe(saveformresults => {
      console.log('this is the results from saving a form: ' + JSON.stringify(saveformresults));
       this.configSvc.teamds = saveformresults;
   
    }, (err) => {
      console.error(err); });

    }

  findFormItem(formitem){
    console.log('this is the tagitem that were looking for: ' + JSON.stringify(formitem));
   this.formitemsrch= [];
    const formitemsrchForm = new FormData();
    formitemsrchForm.append('formitem', formitem);
    this.authService.findFormItem(formitemsrchForm, this.user).subscribe(formitemresults => {
      console.log('this is the results from trying to find form items: ' + JSON.stringify(formitemresults));
       this.formitemsrch = formitemresults;
       this.findformitemclicked = true;
    }, (err) => {
      console.error(err); });
    }

    findFormChoiceset(formchoiceset){
      console.log('this is the form choiceset that were looking for: ' + JSON.stringify(formchoiceset));
     this.formchoicesetsrch= [];
      const choicesetsrchForm = new FormData();
      choicesetsrchForm.append('formchoiceset', formchoiceset);
      this.authService.findFormChoiceset(choicesetsrchForm, this.user).subscribe(formchoicesetresults => {
        console.log('this is the results from trying to find choicesets: ' + JSON.stringify(formchoicesetresults));
         this.formchoicesetsrch = formchoicesetresults;
         this.findformchoicesetclicked = true;
      }, (err) => {
        console.error(err); });
      }

    

  createStepelement(  stepsetid, stepid, stepsn, formitem, formtype, label, formitemlabel)
  {
    const formitemForm = new FormData();
    formitemForm.append('stepsetid', stepsetid);
    formitemForm.append('stepid', stepid);
    formitemForm.append('stepsn', stepsn);
    formitemForm.append('formitem', formitem);
    formitemForm.append('formtype', formtype);
    formitemForm.append('label', label);
    formitemForm.append('formitemlabel', formitemlabel);
    
    
 this.authService.createStepelement(formitemForm, this.user).subscribe(formitemresults => {
    console.log('this is the results from createing Formitem: ' + JSON.stringify(formitemresults));
     this.formitems = formitemresults;
  //   this.getFormsteps(this.hlStepset, true)
    
    
  }, (err) => {
    console.error(err); });

  }

  createStep(  stepsetid, stepsn, step)
  {
    const formitemForm = new FormData();
    formitemForm.append('stepsetid', stepsetid);
    formitemForm.append('stepsn', stepsn);
    formitemForm.append('step', step);

   
 this.authService.createStep(formitemForm, this.user).subscribe(formstepresults => {
    console.log('this is the results from createing Formitem: ' + JSON.stringify(formstepresults));
     this.formsteps = formstepresults;
    // this.getStepsetbyid(this.formsteps[0]._id, true);
     // this.getFormsteps(this.currFormtemplate, true);
    
  }, (err) => {
    console.error(err); });

  }

  /*
  applytagerIcon(  stepsetid,  icontype, iconcolor)
  {
    const applyiconForm = new FormData();
    applyiconForm.append('stepsetid', stepsetid);
    applyiconForm.append('icontype', icontype);
    applyiconForm.append('iconcolor', iconcolor);
   
 this.authService.applyIcon(applyiconForm, this.user).subscribe(applyiconresults => {
    console.log('this is the results from applying icon: ' + JSON.stringify(applyiconresults));
     this.formtemplate= applyiconresults;
    
  }, (err) => {
    console.error(err); });

  }
*/


  createFormChoiceset(stepsetid, stepsn, formstep, formstepid, stepitem, formitemid, formchoicesetn)
  {
    const formchoicesetForm = new FormData();
    formchoicesetForm.append('stepsetid', stepsetid);
    formchoicesetForm.append('stepsn', stepsn);
    formchoicesetForm.append('formstep', formstep);
    formchoicesetForm.append('formstepid', formstepid);
    formchoicesetForm.append('stepitem', stepitem);
 
    formchoicesetForm.append('formitemid', formitemid);
    formchoicesetForm.append('formchoicesetn', formchoicesetn);
 this.authService.createFormChoiceset(formchoicesetForm, this.user).subscribe(formchoicesetresults => {
    console.log('this is the results from createing choiceset: ' + JSON.stringify(formchoicesetresults));
     this.formchoiceset= formchoicesetresults;
   
  }, (err) => {
    console.error(err); });

  }

  createFormChoiceitem(formchoicesetid, formchoiceitemn)
  {
    const formchoiceitemForm = new FormData();
  
    formchoiceitemForm.append('formchoicesetid', formchoicesetid);
    formchoiceitemForm.append('formchoiceitemn', formchoiceitemn);
 this.authService.createFormChoiceitem(formchoiceitemForm, this.user).subscribe(formchoiceitemresults => {
    console.log('this is the results from createing formchoiceitem: ' + JSON.stringify(formchoiceitemresults));
     this.formchoiceitems= formchoiceitemresults;
     this.formtype= 'formchoiceitem';
  }, (err) => {
    console.error(err); });

  }

  public getFormsteps(formtemplate, refresh: Boolean) {
   
    var stepsetid : string;
     if(!refresh) {
      this.hlStepset = [];
      this.currFormtemplate = formtemplate;
      this.hlStepset.push(formtemplate);
     
      stepsetid = this.currFormtemplate._id 
      console.log('currFormtemplate row: ' + JSON.stringify(this.currFormtemplate));
      console.log('first highlighting StepSet row: ' + JSON.stringify(this.hlStepset));

      console.log('refresh is false so hiding other components')
      this.formsteps = [];this.formitems= []; this.formchoiceset= []; this.formchoiceitems = []
    }
      if(refresh) {
        console.log('refresh is true');
        stepsetid = this.hlStepset[0]._id
      }
     const getFormstepsForm = new FormData();
     getFormstepsForm.append('stepsetid', stepsetid);
     this.authService.getFormsteps(getFormstepsForm, this.user).subscribe(getFormstepsresults => {
     console.log('this is the results from getting formsteps: ' + JSON.stringify(getFormstepsresults));
      this.formsteps= getFormstepsresults;

      this.formtemplatesel = true;
      this.formtype = 'formsteps';
      this.hlStepset = [];
     
      this.hlStepset.push(this.currFormtemplate);
      console.log('rehighlighting StepSet'+ JSON.stringify(this.hlStepset));
     
    }, (err) => {console.error(err); });

   }

  public getStepitems(step: String, refresh: Boolean) {
    
    var stepsetid : string;
    this.hlStep = [];
    //  this.hlStep.push({'_id': step._id, 'formstep': step.formstep});
    this.hlStep.push(step);
    console.log('this the 1st getstepitems hl step'+JSON.stringify(this.hlStep));
    if(!refresh){
    
      stepsetid = this.hlStepset[0]._id;
     this.formitems= []; this.formchoiceset= []; this.formchoiceitems = [];
    }

    if(refresh){
      stepsetid = this.hlStepset[0]._id;
    }
     const getStepitemsForm = new FormData();
     getStepitemsForm.append('stepsetid', this.hlStepset[0]._id);
   
     getStepitemsForm.append('stepid', this.hlStep[0]._id);
     this.authService.getStepitems(getStepitemsForm, this.user).subscribe(getFormitemresults => {
     console.log('this is the results from getting form items: ' + JSON.stringify(getFormitemresults));
      this.tagitems= getFormitemresults;
      this.formtemplatesel = true;
      this.formtype = 'formitem';
    //   this.hlStepitem = null;
    this.hlStep = [];
    //  this.hlStep.push({'_id': step._id, 'formstep': step.formstep});
    this.hlStep.push(step);
    console.log('this is the 2nd highlight hl step'+JSON.stringify(this.hlStep))

    }, (err) => {console.error(err); });

   }
     
      

      public getChoiceset(formitem) {
           this.choiceset = [];
   
         this.currFormitem = formitem;
         this.hlStepitem[0] = formitem.stepitems;
         if (this.hlStepitem[0].formchoices)
         {
          console.log('this highlighted step item has a formchoices assgined to it');
                 
         console.log('highlighed stepitem row: ' + JSON.stringify(this.hlStepitem));
         const getFormChoicesetForm = new FormData();
         getFormChoicesetForm.append('itemid', this.hlStepitem[0]._id);
         getFormChoicesetForm.append('choicesetid', this.hlStepitem[0].choicesetid);

     this.authService.getChoiceset(getFormChoicesetForm, this.user).subscribe(getFormChoicesetresults => {
     console.log('this is the results from getting choiceset: ' + JSON.stringify(getFormChoicesetresults));
      this.choiceset= getFormChoicesetresults;
      this.formtype = 'formchoiceset';
    
    }, (err) => {console.error(err); });
  }

  if (!this.hlStepitem[0].formchoices){
    console.log('this hl form item does not have a choiceset associated with it, doing nothing')

  }

   }
   
   public getChoicepath(parentid) {

    const pathForm = new FormData();
 
    pathForm.append('parentid', parentid);
    
    this.authService.getChoicepath(pathForm, this.user).subscribe(choicepathdata => {
    
      this.choicepathds = choicepathdata;
   
    }
     , (err) => {
      console.error(err); });

}
       

public getFormChoices(choicesetid) {

this.formchoiceitems = [];
 const getChoicesetForm = new FormData();
 getChoicesetForm.append('choicesetid', choicesetid);
this.authService.getChoiceitem(getChoicesetForm, this.user).subscribe(getChoiceitemresults => {
console.log('this is the results from getting form choices: ' + JSON.stringify(getChoiceitemresults));
for(var choiceitem of getChoiceitemresults){
this.formchoiceitems.push(choiceitem);}

console.log('this is the actual choices after pushing: ' + JSON.stringify(this.formchoiceitems));
this.formtype = 'formchoiceitem';

this.configSvc.gettingformchoices = true;

}, (err) => {console.error(err); });

}

      public selectFormChoiceitem(formchoiceitem) {
        this.highlightedFormChoiceitem= [];
         this.currFormChoiceitem = formchoiceitem;
         this.highlightedFormChoiceitem.push(formchoiceitem);
         console.log('current form choiceitem row: ' + JSON.stringify(this.currFormChoiceitem));
         console.log('highlighed form choiceitem row: ' + JSON.stringify(this.highlightedFormChoiceitem));
         this.formtype = 'formchoiceitem';
        
         
          }

          public applyFormTemplate(stepsetid, type, itemid)
          {
            const applyFormtemplateForm = new FormData();
            applyFormtemplateForm.append('stepsetid', stepsetid);
            applyFormtemplateForm.append('type', type);
          
            applyFormtemplateForm.append('itemid', itemid);
           
         this.authService.applyFormTemplate(applyFormtemplateForm, this.user).subscribe(applyformresults => {
            console.log('this is the results from createing Tagset: ' + JSON.stringify(applyformresults));
             this.configSvc.folderdetailsds = applyformresults;
          }, (err) => {
            console.error(err); });
      
          }


          public addFormtoFolder(formname, folderid, stepsetid, level) {

            const addformForm = new FormData();
            console.log('formname Im trying to add is' + JSON.stringify(formname));
            addformForm.append('formname', formname);
             
            addformForm.append('folderid', folderid);
            console.log('folderid is' + folderid);
            this.datenow = Date.now();
            addformForm.append('addedon', this.datenow);
            addformForm.append('stepsetid', stepsetid);
            console.log('stepsetid is' + stepsetid);
            addformForm.append('level', level);
            console.log('level is' + level);
            addformForm.append('type', 'Form');
           
            this.authService.addFormtoFolder(addformForm, this.user).subscribe(refreshFolder => {
              this.configSvc.teamds = refreshFolder;
              this.configSvc.buildtool = false;
            
           //  console.log('this is the folderformds'+JSON.stringify(this.folderformds));
            }
             , (err) => {
              console.error(err); });
            }

            public getformPreview(stepsetid){
              const getformtemplateForm = new FormData();
            
              getformtemplateForm.append('stepsetid', stepsetid);
              this.authService.getformPreview(getformtemplateForm, this.user).subscribe(formtemplate => {
              this.formpreview = formtemplate
              this.configSvc.previewactive = true;
              
             //  console.log('this is the folderformds'+JSON.stringify(this.folderformds));
              }
               , (err) => {
                console.error(err); });
              }

              public gettagertagPreview(tagerid){
                console.log('getting tagertagpreview for tagersvc')
                const tagertagpreviewForm = new FormData();
              
                tagertagpreviewForm.append('tagerid', tagerid);
                this.authService.gettagertagPreview(tagertagpreviewForm, this.user).subscribe(previewresults => {
                this.formpreview = previewresults
                this.configSvc.previewactive = true;
                
               //  console.log('this is the folderformds'+JSON.stringify(this.folderformds));
                }
                 , (err) => {
                  console.error(err); });
                }
  

              public getActiveform(activestepsetid, active){
                this.gotchartdata = false;
                this.steplist=[null];
                this.stepitemlist = [null];
                this.stepitemcompletedlist = [];
                console.log('the steplist is now set to'+this.steplist)
                const getactiveform = new FormData();
                 this.stepsetready = false;
                getactiveform.append('activestepsetid', activestepsetid);
                this.authService.getActiveform(getactiveform, this.user).subscribe(activeformresults => {
                this.activeform = activeformresults
                this.stepsetready = true;
                console.log('stepset is ready and status of stepset is '+active)
                this.stepsetactive = active;
                this.trknotstrt = false;
               // this.configSvc.refreshFolder();
              
//chart methods

             
                    
              
              

           //  this.barChartLabels = this.activeform.map(x => x.formstep);
          //   console.log('barchart labels is'+this.barChartLabels)
             this.stepitemlist = this.activeform.map(x => x.stepitems.length)

              console.log('initializing the bar chart data with this data: '+this.stepitemlist)
              this.totalstepitems = this.stepitemlist.reduce((a, b) => a + b, 0);
             console.log('this is the total items'+this.totalstepitems)

             // setting up the stepitemcompleted list
           
               
              
               for (let i = 0; i < this.activeform.length ; i++) {

                var stepcompletedcnt = 0;
                for (let i2 = 0; i2 < this.activeform[i].stepitems.length ; i2++) {
                  console.log('checking the stepitems now for stepitem number '+i2); 
                  console.log('this stepitem is'+JSON.stringify(this.activeform[i].stepitems[i2]))

                  if(this.activeform[i].stepitems[i2].done)
                  {stepcompletedcnt+=1}
                   // = this.activeform[i].stepitems[i2];
                //  this.barChartLabels = this.activeform[i].stepitems[i2].map(x => x.formstep);
               //  if(this.activeform[i].stepitems[i2].value.('itemvalue')){
               //   stepcompletedcnt+=1}
              //    if(this.activeform[i].stepitems.hasOwnProperty('choicevalue')){
                 //   stepcompletedcnt+=1}

              
                  }
                  this.stepitemcompletedlist.push(stepcompletedcnt)
                 console.log ('current stepitem complete list'+this.stepitemcompletedlist);
                 this.totalcompleteditems = this.stepitemcompletedlist.reduce((a, b) => a + b, 0);
                 console.log('this is the total completed items'+this.totalcompleteditems)
                 this.totalpercent = Math.floor(this.totalcompleteditems/this.totalstepitems*100);
                 console.log('this is the total percent completed'+this.totalpercent)
              }
              // end of setting up the stepitemcompleted list
           
/*
                 this.barChartData = [
                  
                  { data: this.stepitemcompletedlist, label: 'Completed Items', backgroundColor: 'darkblue' },
                  { data: this.stepitemlist, label: 'Total Items' , backgroundColor: 'lightblue'}
                ];
                

                 console.log('this is the latest barChartLabels'+this.barChartLabels)
                 console.log('this is the latest bar chart data'+JSON.stringify(this.barChartData))

                 */
                  this.gotchartdata = true;
                 
                  
                  
                 //end of chart methods
                }
                 , (err) => {
                  console.error(err); });
 }

 public getActivetager(atn){
  

  const getactivetagerform = new FormData();

  getactivetagerform.append('atn', atn);
  this.authService.getActivetager(getactivetagerform, this.user).subscribe(activetagerresults => {
  this.activetager = activetagerresults
  for(var activeitems of activetagerresults){

    if (activeitems.active){
      this.activetrack = [];
    this.activetrack.push(activeitems);
  console.log('this is the activetrack'+JSON.stringify(this.activetrack))}
  }}
   , (err) => {
    console.error(err); });
}

public getNodebyid(nodeid){

  const getNodeform = new FormData();

  getNodeform.append('nodeid', nodeid);
  this.authService.getNodebyid(getNodeform, this.user).subscribe(noderesults => {
  this.kanbanset = noderesults
  this.findkanbansetclicked = true;
  }
   , (err) => {
    console.error(err); });
}
 public updateForm(newformvalues, activestepsetid ) {

  console.log('this is the props im updating'+newformvalues)

  const updateForm = new FormData();
 
  updateForm.append('newformvalues', JSON.stringify(newformvalues));
  
  updateForm.append('activestepsetid', activestepsetid);
 

  this.authService.updateForm(updateForm, this.user).subscribe(updateddata => {
  this.activeform = updateddata;
   this.getActiveform(activestepsetid, false)
   
  }
   , (err) => {
    console.error(err); });
  }      
  
  public moveTrack(atn, trkord ) {

    const movetrackForm = new FormData();
    this.datenow = Date.now();
   
    movetrackForm.append('atn', atn);
    movetrackForm.append('trkord', trkord);
    movetrackForm.append('co', this.datenow);
    movetrackForm.append('itemid', this.configSvc.hlitem[0]._id);

    this.authService.moveTrack(movetrackForm, this.user).subscribe(movedtrack => {
    this.activetager = movedtrack;
    this.getActivetager(atn)

    }
     , (err) => {
      console.error(err); });
    }  

  getTotalpercent() {
    const exampleObservable = new Observable<number>((observer) => {
      observer.next(this.totalpercent);
    });
    return exampleObservable;
  }

  createChoiceset(choicesetn, choicesetdesc, prompt, mgrs)
  {
    const createChoicesetForm = new FormData();
   
    this.datenow = Date.now();
    createChoicesetForm.append('choicesetn', choicesetn);
    createChoicesetForm.append('choicesetdesc', choicesetdesc);
    createChoicesetForm.append('prompt', prompt);
    createChoicesetForm.append('addedon', this.datenow);
    createChoicesetForm.append('mgrs', mgrs);
 this.authService.createChoiceset(createChoicesetForm, this.user).subscribe(choicesetresults => {
    console.log('this is the results from createing choiceset: ' + JSON.stringify(choicesetresults));
     this.choiceset= choicesetresults;
   
  }, (err) => {
    console.error(err); });

  }

 

  addCategory(category, parentid, catlevel, includeitems)
  {
    const addCategoryForm = new FormData();
   
    addCategoryForm.append('category', category);
    addCategoryForm.append('parentid', parentid);
    addCategoryForm.append('catlevel', catlevel);
    addCategoryForm.append('includeitems', includeitems);
   
 this.authService.addCategory(addCategoryForm, this.user).subscribe(categoryresults => {
    console.log('this is the results fromadding a new category: ' + JSON.stringify(categoryresults));
    if(catlevel == 1){ 
      console.log('returning top level categories')
      this.categorylevel1= categoryresults};
    if(catlevel == 2){ this.categorylevel2= categoryresults};
    if(catlevel == 3){ this.categorylevel3= categoryresults};
   
  }, (err) => {
    console.error(err); });

  }

  getCategories (parentid, catlevel, includeitems)
  {
    const getCategoriesForm = new FormData();
   
    getCategoriesForm.append('kt', 'category');
    getCategoriesForm.append('catlevel', catlevel);
   //  getCategoriesForm.append('category', category);
    getCategoriesForm.append('parentid', parentid);
    getCategoriesForm.append('includeitems', includeitems);
   
 this.authService.getCategories(getCategoriesForm, this.user).subscribe(categoryresults => {
    console.log('this is the results getting categories: ' + JSON.stringify(categoryresults));
    if(catlevel == 1){ this.categorylevel1= categoryresults};
    if(catlevel == 2){ this.categorylevel2= categoryresults};
    if(catlevel == 3){ this.categorylevel3= categoryresults};
   
  }, (err) => {
    console.error(err); });

  }

  getTagerbyCategory(category, level)
  {
    const getTagerbyCategoryForm = new FormData();
   
    getTagerbyCategoryForm.append('category', category);
    getTagerbyCategoryForm.append('level', level);
   
 this.authService.getTagerbyCategory(getTagerbyCategoryForm, this.user).subscribe(tagerresults => {
    console.log('this is the results getting getTagerbyCategory: ' + JSON.stringify(tagerresults));
     this.cattagers= tagerresults;
   
  }, (err) => {
    console.error(err); });

  }


  createapplyChoiceset(choicesetn, choicesetdesc, stepitemid)
  {
    const createChoicesetForm = new FormData();
   
    createChoicesetForm.append('choicesetn', choicesetn);
    createChoicesetForm.append('choicesetdesc', choicesetdesc);
    createChoicesetForm.append('stepitemid', stepitemid);
 this.authService.createapplyChoiceset(createChoicesetForm, this.user).subscribe(choicesetresults => {
    console.log('this is the results from createing choiceset: ' + JSON.stringify(choicesetresults));
     this.choiceset= choicesetresults;
     
   
  }, (err) => {
    console.error(err); });

  }
  


  createChoiceitem(name, desc, parentid, activelevel)
  {
    this.datenow = Date.now();
    const choiceitemForm = new FormData();

    
    choiceitemForm.append('parentid', parentid);
    choiceitemForm.append('name', name);
    choiceitemForm.append('desc', desc);
    choiceitemForm.append('activelevel', activelevel);
    choiceitemForm.append('addedon', this.datenow);
    choiceitemForm.append('type', 'choice');
 this.authService.createChoiceitem(choiceitemForm, this.user).subscribe(choiceitemresults => {
    console.log('this is the results from createing formchoiceitem: ' + JSON.stringify(choiceitemresults));
     this.choiceset= choiceitemresults;
    
  }, (err) => {
    console.error(err); });

  }

  deleteChoiceitem(choiceid, parentid)
  {
    this.datenow = Date.now();
    const choiceitemForm = new FormData();

    choiceitemForm.append('parentid', parentid);
    choiceitemForm.append('itemid', choiceid);

 this.authService.deleteChoiceitem(choiceitemForm, this.user).subscribe(choiceitemresults => {
    console.log('this is the results from createing formchoiceitem: ' + JSON.stringify(choiceitemresults));
     this.choiceset= choiceitemresults;
    
  }, (err) => {
    console.error(err); });

  }

  unassignChoiceset(tagsetid, tagitemid){

 const unassignForm = new FormData();
 unassignForm.append('tagsetid', tagsetid);
 unassignForm.append('tagitemid', tagitemid);
   
 this.authService.unassignChoiceset(unassignForm, this.user).subscribe(unassignresults => {
    console.log('this is the results from unassigning choiceset: ' + JSON.stringify(unassignresults));
     this.choiceset= unassignresults;
   
  }, (err) => {
    console.error(err); });

  }




     applyChoiceset(stepsetid, stepid, stepitemid, choicesetid){

      const applyChoicesetForm = new FormData();
      applyChoicesetForm.append('stepsetid', stepsetid);
      applyChoicesetForm.append('stepid', stepid);
      applyChoicesetForm.append('stepitemid', stepitemid);
      applyChoicesetForm.append('choicesetid', choicesetid);
     
      this.authService.applyChoiceset(applyChoicesetForm, this.user).subscribe(applyChoicesetresults => {
         console.log('this is the results from applying ChoiceSet to a step item: ' + JSON.stringify(applyChoicesetresults));
          this.choiceset= applyChoicesetresults;
          this.getStepitems(this.hlStep[0], true)
        
       }, (err) => {
         console.error(err); });
     
       }

       applytagertagChoiceset(nodeid,tagid, choicesetid, tagname ){

        const applytagertagForm = new FormData();
        applytagertagForm.append('tagerid', nodeid);
        applytagertagForm.append('tagid', tagid);
        applytagertagForm.append('choicesetid', choicesetid);
        applytagertagForm.append('tagname', tagname);
       
        this.authService.applytagertagChoiceset(applytagertagForm, this.user).subscribe(applyTagertagresults => {
           console.log('this is the  results from applying ChoiceSet to a step item: ' + JSON.stringify(applyTagertagresults));
            this.choiceset= applyTagertagresults;
          //  this.getTagitems(this.hlTager[0]._id)
          this.hltagonly[0].stepitems.formchoices = true;
          this.hltagonly[0].stepitems.choicesetid = choicesetid;
          
         }, (err) => {
           console.error(err); });
       
         }

  findChoiceset(choiceset){
    console.log('this is the choiceset that were looking for: ' + JSON.stringify(choiceset));
   this.choicesetsrch= [];
    const choicesetsrchForm = new FormData();
    choicesetsrchForm.append('choiceset', choiceset);
    this.authService.findChoiceset(choicesetsrchForm, this.user).subscribe(choicesetresults => {
      console.log('this is the results from trying to find choicesets: ' + JSON.stringify(choicesetresults));
       this.choicesetsrch = choicesetresults; 
       this.findchoicesetclicked = true;
    }, (err) => {
      console.error(err); });
    }

    public getsetDetails(itemid, type, teamid)  {
      console.log('getting set details now for '+itemid);
      console.log('getting type now for '+type);
      console.log('getting teamid now for '+teamid);
      this.setready = false; this.configSvc.pathready = false;
     
      const getSetDetailsForm = new FormData();
      getSetDetailsForm.append('itemid', itemid);
      getSetDetailsForm.append('type', type);
      getSetDetailsForm.append('teamid', teamid);
     

  
      this.authService.getsetDetails(getSetDetailsForm, this.user).subscribe(setdetailsresults => {
        console.log('this is the results from getSetDetailsForm: ' + JSON.stringify(setdetailsresults));

     
         this.setdetailsds= [setdetailsresults];
         this.setready = true;
         this.configSvc.folderpath = setdetailsresults.path
         console.log('folderpath is '+JSON.stringify(this.configSvc.folderpath))
       this.configSvc.pathready = true;
        this.configSvc.mgrsadded = setdetailsresults.mgrs;

     
        

       }, (err) => {console.error(err); });


       
}

public addsetmgr(teamitemid, useremail){
  const addsetmgrForm = new FormData();
  
  addsetmgrForm.append('teamitemid', teamitemid);
  addsetmgrForm.append('email', useremail);
  this.authService.addsetmgr(addsetmgrForm, this.user).subscribe(addsetmgrdata => {
    console.log('add user data set returned is' + JSON.stringify(addsetmgrdata));
    this.configSvc.usersadded = addsetmgrdata.mgrs;
    this.configSvc.usersadded = [...this.configSvc.usersadded];  //refresh the mgrs array
   
   }
    , (err) => {
     console.error(err); });
   }

   public getChoicesetbyid(choicesetid, type){
    console.log('this is the current cached list before getting data'+JSON.stringify(this.authSvc.cachedchoices))
    console.log('choicesetid is'+choicesetid)
    console.log('type is'+type)
    const choicesetbyidform = new FormData();
    choicesetbyidform.append('choicesetid', choicesetid);
    choicesetbyidform.append('type', type);
    this.authService.getChoicesetbyid(choicesetbyidform, this.user).subscribe(choicesetresults => {
      console.log('this is the results from trying to find choicesets: ' + JSON.stringify(choicesetresults));
    

 // choicesetids.push(this.configSvc.nodeds[0].stepper[i].tags[i2].choicesetid)
    // choicesetresults.forEach((choice) =>{
       if (type == 'choicelist' || type== 'radiogroup'){
         this.choicesetsrch = choicesetresults
       //  this.listpath= choicesetresults.path
       }

       if (type == 'treelist'){
         this.activetree = choicesetresults
        console.log( 'this was a treelist so populating activetree' +JSON.stringify(this.activetree));
        this.configSvc.nodedetailsready = true;
      //  this.treepath = [choicesetresults.treepath];
     //   console.log( 'this was a tree path so populating treepath' +JSON.stringify(this.treepath));
      }

      

       if(localStorage.getItem(choicesetid)===null)
        {
          console.log('this list '+type+' doesnt exist in cache so putting in there, list is: '+choicesetid )
        localStorage.setItem(choicesetid, JSON.stringify(choicesetresults));
        var localdata = JSON.parse(localStorage.getItem(choicesetid))
      //  this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
        if(!this.authSvc.choicesetids.includes(choicesetid)){
          this.authSvc.choicesetids.push(choicesetid)
          console.log('we havent added this to the auth localcache yet: '+choicesetid);
          console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
          this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
          console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
        }
        this.configSvc.nodedetailsready = true;
      }

     
        
     // })
     
     
       
      
    }, (err) => {
      console.error(err); });
    }

    public getChoiceitem(choiceset) {
      this.hlChoiceset= [];
       this.hlChoiceset.push(choiceset);
       const choiceitemForm = new FormData();
       choiceitemForm.append('choicesetid', this.hlChoiceset[0]._id);
    this.authService.getChoiceitem(choiceitemForm, this.user).subscribe(choiceitemresults => {
      console.log('this is the results from trying to find choiceitem: ' + JSON.stringify(choiceitemresults));
       this.choiceitems = choiceitemresults; 
       this.findchoicesetclicked = true;
    }, (err) => {
      console.error(err); });
    }

    public getPromptlist() {
   
       const promptForm = new FormData();
       promptForm.append('process', 'getpromptlist');
    this.authService.getPromptlist(promptForm, this.user).subscribe(promptlists => {
      console.log('this is the results from trying to find choiceitem: ' + JSON.stringify(promptlists));
       this.promptlist = promptlists; 
       this.findchoicesetclicked = true;
    }, (err) => {
      console.error(err); });
    }

    
  findTager(tagertype: string, nodename: string, category: string){
    console.log('this is the kanban that were looking for: ' + JSON.stringify(nodename));
    this.kanbanset = []; this.tracks= [];
    const findTagerform = new FormData();
    findTagerform.append('tagertype', tagertype);
    findTagerform.append('nodename', nodename);
    findTagerform.append('category', category);
   
    this.authService.findTager(findTagerform, this.user).subscribe(tagerresults => {
      console.log('this is the results from trying to find kanbans: ' + JSON.stringify(tagerresults));
       this.kanbanset = tagerresults;
       this.kanbantype = 'kanbanset';
       this.findkanbansetclicked = true;
    }, (err) => {
      console.error(err); });

    }

    findKanbanItem(track){
      console.log('this is the kanban item that were looking for: ' + JSON.stringify(track));
     this.tracksrch= [];
      const tracksrchForm = new FormData();
      tracksrchForm.append('track', track);
      this.authService.findKanbanItem(tracksrchForm, this.user).subscribe(tagitemresults => {
        console.log('this is the results from trying to find tagitems: ' + JSON.stringify(tagitemresults));
         this.tracksrch = tagitemresults;
         this.findkanbanitemclicked = true;
      }, (err) => {
        console.error(err); });
      }


    createTager(nodename, tagerdesc, tagertype, category, catlevel, catid, catparentid, catpath)
{
  const tagerForm = new FormData();
  tagerForm.append('kanbansetname', nodename);
  tagerForm.append('tagerdesc', tagerdesc);
  tagerForm.append('tagertype', tagertype);
  tagerForm.append('category', category);
  tagerForm.append('catlevel', catlevel);
  tagerForm.append('catid', catid);
  tagerForm.append('catparentid', catparentid);
  tagerForm.append('catpath', catpath);


this.authService.createTager(tagerForm, this.user).subscribe(kanbansetresults => {
  console.log('this is the results from createing Tagset: ' + JSON.stringify(kanbansetresults));
   this.kanbanset = kanbansetresults;
   this.configSvc.colorinputtype = 'tager'
   this.findkanbansetclicked = true;
  
 
}, (err) => {
  console.error(err); });

}



public getCatpath(tagerid: string) {
  this.catpath = [];
  const getCatpathForm = new FormData();
  getCatpathForm.append('tagerid', tagerid);
  
  this.authService.getCatpath(getCatpathForm, this.user).subscribe(getCatpathresults => {
  console.log('this is the results from selecting kanbanset : ' + JSON.stringify(getCatpathresults));
   this.catpath= getCatpathresults;
   for (let i = 0; i < this.catpath.length ; i++) {
    
    
      console.log('getting the categories for catlevel'+ JSON.stringify(this.catpath[i].catpath.catlevel));   
      console.log('getting the categories for catparentid'+ this.catpath[i].catpath.catparentid)   
      this.getCategories(this.catpath[i].catpath.catparentid, this.catpath[i].catpath.catlevel, false)}
   

  
  
 }, (err) => {console.error(err); });

}

public getTager(tagerid: string, track: number) {
 
   const getTagerForm = new FormData();
   getTagerForm.append('tagerid', tagerid);
   getTagerForm.append('track', track.toString());
   this.authService.getTager(getTagerForm, this.user).subscribe(getTagerresults => {
   console.log('this is the results from selecting kanbanset : ' + JSON.stringify(getTagerresults));
    this.hlTager= getTagerresults;
   
   
  }, (err) => {console.error(err); });

 }


public getTagerset(selectedtager) {
  this.hlKanbanset = [];
 //  this.currKanbanset = kanbanset;
   this.hlKanbanset.push(selectedtager);
  // this.ts = '1';
 //  console.log('current tagset row: ' + JSON.stringify(this.currKanbanset));
   console.log(' hlKanbanset row: ' + JSON.stringify(this.hlKanbanset));
   this.tracks= []; 
  // console.log('this is the hltrack'+JSON.stringify(this.hltrack[0]));
   
   if(this.hlKanbanset[0].tagertype != 'foldertags' && this.hlKanbanset[0].tagertype != 'tageritem'){
    console.log(' this is not a tagertag');
    const getKanbanitemForm = new FormData();
    getKanbanitemForm.append('kanbansetid', this.hlKanbanset[0]._id);
    getKanbanitemForm.append('apply', 'false');
   this.authService.getTracks(getKanbanitemForm, this.user).subscribe(getKanbanitemresults => {
   console.log('this is the results from selecting kanbanset : ' + JSON.stringify(getKanbanitemresults));
    this.tracks= getKanbanitemresults;
    this.kanbansetsel=true;this.kanbanaction = 'kanbanset'
   // if(this.hlKanbanset[0].tagertype !='tagerkanban'){ 
   //   this.tracksel=false; this.kanbanaction = 'kanbanset'}
  //  if(this.hlKanbanset[0].tagertype == 'tagerkanban')
   // { this.kanbanaction = 'tagerkanban'}
  }, (err) => {console.error(err); });
}
   
console.log(' hlKanbanset row what: ' + JSON.stringify(this.hlKanbanset[0]));
console.log(' current active tagertype is: ' +this.hlKanbanset[0].tagertype);
     if(this.hlKanbanset[0].tagertype == 'foldertags'){
       
      this.kanbanaction = 'foldertags'
     console.log('this is a tagertag do sth');
     this.getTagitems(this.hlKanbanset[0]._id, this.hlKanbanset[0].tagertype)
    }

    if(this.hlKanbanset[0].tagertype == 'tageritem'){
       
      this.kanbanaction = 'tageritem'
     console.log('this is a tageritem do sth');
     this.getTagitems(this.hlKanbanset[0]._id, this.hlKanbanset[0].tagertype)
    }
   //  this.kanbantype = 'track';
  

 }

 public selectTrack(track){
  
   
   this.tracksel=true;
   this.hltrack = []; this.stepset = [];
   this.hltrack.push(track);
   var tagertype = track.tagertype
   console.log('this is the current tagertype'+tagertype)
   console.log('highlightedTrack row: ' + JSON.stringify(this.hltrack));
   if (tagertype != 'tagerkanban'){ 
     this.kanbanaction = 'kanbanitem';
     console.log('this is a NOT a tager kanban only action') }
   if (tagertype == 'tagerkanban'){ 
    console.log('this is a tager kanban only action') 
    this.kanbanaction = 'tagerkanban';}
   
   
 /*
   if(this.hltrack[0].secured){
   
   console.log('full page is now set to false and active tool is browse and buildtool is true');
   const getKanbansecForm = new FormData();
   getKanbansecForm.append('kanbansetid', this.hlKanbanset[0].kanbansetid);
   getKanbansecForm.append('kanbanitemid', this.hlKanbanset[0]._id);
   this.authService.getTracksec(getKanbansecForm, this.user).subscribe(getKanbansecresults => {
   console.log('this is the results from getting kanban items security: ' + JSON.stringify(getKanbansecresults));
    this.kanbansec= getKanbansecresults;
    
  }, (err) => {console.error(err); });
 }
*/

 if(this.hltrack[0].stepset){
 console.log(' return stepset if it has one '+this.hltrack[0].stepsetid);
  this.getStepsetbyid(this.hltrack[0].stepsetid)
 }
   




 }

 getStepsetbyid(stepsetid: string){

  const stepsetForm = new FormData();
  
    
  // console.log('current stepset id with refresh false'+this.hltrack[0].stepsetid);
  stepsetForm.append('stepsetid', stepsetid);

  
 
  this.authService.getStepsetbyid(stepsetForm, this.user).subscribe(getStepsetbyidresults => {
  console.log('this is the results from getting stepset by id ' + JSON.stringify(getStepsetbyidresults));
   this.stepset= getStepsetbyidresults;
 }, (err) => {console.error(err); });


 }

 getTagitems(tagerid: string, tagertype: string){

  const tagitemsForm = new FormData();
  
    
  // console.log('current stepset id with refresh false'+this.hltrack[0].stepsetid);
  tagitemsForm.append('tagerid', tagerid);
  tagitemsForm.append('tagertype', tagertype);

  
 
  this.authService.getTagitems(tagitemsForm, this.user).subscribe(gettagitemsresults => {
  console.log('this is the results from getting tagitems by id ' + JSON.stringify(gettagitemsresults));
   this.tagitems= gettagitemsresults;
 }, (err) => {console.error(err); });


 }



 editTrack( trackid, trackname, trackdesc)
 {
   const editTrackform = new FormData();
   editTrackform.append('trackid', trackid);
   editTrackform.append('trackname', trackname);
   editTrackform.append('trackdesc', trackdesc);
 
   
this.authService.editTrack(editTrackform, this.user).subscribe(edittrackresults => {
   console.log('this is the results from editing track: ' + JSON.stringify(edittrackresults));
 }, (err) => {
   console.error(err); });

 }

 createTrack( kanbansetid, tagertype, kanbansetname, track, type, trackdesc)
 {
   const kanbanitemForm = new FormData();
   kanbanitemForm.append('kanbansetid', kanbansetid);
   kanbanitemForm.append('tagertype', tagertype);
   kanbanitemForm.append('kanbansetname', kanbansetname);
   kanbanitemForm.append('track', track);
   kanbanitemForm.append('type', 'create');
   kanbanitemForm.append('trackdesc', trackdesc);
  
  
this.authService.createTrack(kanbanitemForm, this.user).subscribe(kanbanitemresults => {
   console.log('this is the results from createing Tagset: ' + JSON.stringify(kanbanitemresults));
    this.tracks = kanbanitemresults;
    
      console.log('current highlighted tager  after creating a new track'+this.hlKanbanset[0])
     // this.getTagerset(this.hlKanbanset[0], true)
      
   
 }, (err) => {
   console.error(err); });

 }

 

    applyIcon(  kanbansetid,  icontype, iconcolor)
    {
      const applyiconForm = new FormData();
      applyiconForm.append('kanbansetid', kanbansetid);
      applyiconForm.append('icontype', icontype);
      applyiconForm.append('iconcolor', iconcolor);
     
   this.authService.applyIcon(applyiconForm, this.user).subscribe(applyiconresults => {
      console.log('this is the results from applying icon: ' + JSON.stringify(applyiconresults));
       this.kanbans= applyiconresults;
     
      
    }, (err) => {
      console.error(err); });

    }

 

 public createKanbantemplate(kanbansetid, type, itemid)
            {
              const applyKanbansetForm = new FormData();
              applyKanbansetForm.append('kanbansetid', kanbansetid);
              applyKanbansetForm.append('type', type);
            
              applyKanbansetForm.append('itemid', itemid);
             
           this.authService.createKanbantemplate(applyKanbansetForm, this.user).subscribe(applykanbanresults => {
              console.log('this is the results from createing Tagset: ' + JSON.stringify(applykanbanresults));
               this.configSvc.folderdetailsds = applykanbanresults;
               this.configSvc.tagtemplactive = true;
            }, (err) => {
              console.error(err); });
        
            }
   
            public moveKanban(kanbandata, source, target, status, kitype, kikanban, newposition, activetrack)
            {

              const moveKanbanForm = new FormData();
              moveKanbanForm.append('kanbandata', kanbandata);
              moveKanbanForm.append('folderid', this.configSvc.activeitemid);
              moveKanbanForm.append('target', target);
              moveKanbanForm.append('source', source);
              moveKanbanForm.append('kanbantagged', 'true');
              moveKanbanForm.append('kandocs', '0');
              moveKanbanForm.append('newposition', newposition);
             
              moveKanbanForm.append('kitype', kitype);
              moveKanbanForm.append('activetrack', activetrack);

             var process = 'kanbanmove'
          //   if(kitype == 'nodetab'){process = 'nodetabmove' }
            //  moveKanbanForm.append('kikanban', kikanban);
             
            
              console.log('activetype is '+this.configSvc.activetype)
              console.log('kikanban is '+kikanban)
              if(this.configSvc.activetype == 'nodetab' && kikanban == true){
                console.log('activetype is nodetab so settintg nodetab details')
                moveKanbanForm.append('nodetabstate', this.configSvc.nodeds[0].nodetabstate);
                moveKanbanForm.append('nodetabtotal', this.configSvc.nodeds[0].nodetabtotal);
                process = 'nodetabmove'
              }
               moveKanbanForm.append('process', process);
                if(this.configSvc.activetype == 'fold'){
                  console.log('activetype is folder so settintg folder kanban details')
                  moveKanbanForm.append('kanstate', target);
                  moveKanbanForm.append('kanbantotal', this.configSvc.folderdetailsds.kanbantotal.toString());
                  moveKanbanForm.append('kanbanpercent', this.configSvc.folderdetailsds.kanbanpercent.toString());
                //  moveKanbanForm.append('kanbanopts', this.configSvc.kanbanopts.toString());
               //   moveKanbanForm.append('itemkanbantotal', this.configSvc.nodeds[0].kanbantotal.toString());
                }  
           this.authService.moveKanban(moveKanbanForm, this.user).subscribe(movekanbanresults => {
              console.log('this is the results from moving kanban: ' + JSON.stringify(movekanbanresults));
            //   this.configSvc.currkanban = movekanbanresults;
            if(movekanbanresults){
            if(this.configSvc.activetype == 'fold'){

            
           this.configSvc.teamds = movekanbanresults;
            this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex)
            this.configSvc.kanbanready = true 
            this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
          }

          
          if(movekanbanresults[0].type == 'nodetab' &&  movekanbanresults[0].nodetabkanban){
            this.configSvc.nodeds = movekanbanresults
            this.configSvc.activekanban = this.configSvc.nodeds[0].kanbanprops
            console.log('kaban nodetab data ready')
            this.configSvc.kanbanready = true
            console.log('kaban nodetab kanbanready')
            this.configSvc.refreshFolder(this.configSvc.folderdetailsds._id, this.configSvc.activetype)
          }
        }

        
            }, (err) => {
              console.error(err); });
 }

 public movePosition(kanbandata, source, newposition, kitype)
 {

   const movePositionForm = new FormData();
   movePositionForm.append('kanbandata', JSON.stringify([kanbandata]));
   movePositionForm.append('source', source);

   movePositionForm.append('folderid', this.configSvc.activeitemid);
   movePositionForm.append('newposition', newposition);
   movePositionForm.append('kanbantagged', 'true');
   movePositionForm.append('kandocs', '0');

  
   movePositionForm.append('kitype', kitype);
 //  moveKanbanForm.append('kikanban', kikanban);
 movePositionForm.append('process', 'moveposition');
   console.log('activetype is '+this.configSvc.activetype)

 
this.authService.movePosition(movePositionForm, this.user).subscribe(movekanbanresults => {
   console.log('this is the results from moving position: ' + JSON.stringify(movekanbanresults));
 //   this.configSvc.currkanban = movekanbanresults;
 if(this.configSvc.activetype == 'fold' && movekanbanresults){

 
this.configSvc.teamds = movekanbanresults;
 this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex)
// this.configSvc.kanbanready = true 
 this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
}

if(this.configSvc.activetype == 'nodetab' && movekanbanresults){
 this.configSvc.nodeds = [movekanbanresults]
 this.configSvc.activekanban = this.configSvc.nodeds[0].kanbanprops
 console.log('kaban nodetab data ready')
 this.configSvc.kanbanready = true
 console.log('kaban nodetab kanbanready')
 this.configSvc.refreshFolder(this.configSvc.folderdetailsds._id, this.configSvc.activetype)
}



 }, (err) => {
   console.error(err); });
}

 

 public updateKanitem(folderid, kidata, kiworker, kitime, track){
   const updatekiForm = new FormData();
   updatekiForm.append('folderid', folderid);

   updatekiForm.append('kanbandata', JSON.stringify(kidata));
   updatekiForm.append('kiworker', kiworker);
   updatekiForm.append('kitime', kitime);
   updatekiForm.append('track', track);
   updatekiForm.append('kantype', this.configSvc.folderdetailsds.kantype);
   if(this.configSvc.folderdetailsds.kantype=='time'){
   updatekiForm.append('timetotal', this.configSvc.folderdetailsds.timetotal.toString());
   updatekiForm.append('time', this.configSvc.folderdetailsds.time.toString());
   }
this.authService.updateKanitem(updatekiForm, this.user).subscribe(kiresults => {
   console.log('this is the results from updating kanban item: ' + JSON.stringify(kiresults));
    this.configSvc.currkanban = kiresults;
    this.configSvc.getFolderDetails(folderid, this.configSvc.type, this.configSvc.activeteamid, false)

}, (err) => {console.error(err); });}





            public applyTracksec(kanbansetid, kanbanitemid,activeteamid, activeteamname, activeitemid, icontype, iconclr){
              console.log('applying kanban securitynow')
              for(var newpath of this.configSvc.pathds){
                this.dynapath.push('\\'+newpath.path.name);}

                this.activepath = this.dynapath.join('');
                console.log('this is the dynamic path'+this.activepath)
            this.dynapath = [];
              const applyTracksecForm = new FormData();
              applyTracksecForm.append('kanbansetid', kanbansetid);
              applyTracksecForm.append('kanbanitemid', kanbanitemid);
              applyTracksecForm.append('activeteamid', activeteamid);
              applyTracksecForm.append('activeteamname', activeteamname);
              applyTracksecForm.append('folderid', activeitemid);
              applyTracksecForm.append('icontype', icontype);
              applyTracksecForm.append('iconclr', iconclr);
              applyTracksecForm.append('activepath', this.activepath);
             
             
             
           this.authService.applyTracksec(applyTracksecForm, this.user).subscribe(applytracksecresults => {
              console.log('this is the results from applying Kanbansec: ' + JSON.stringify(applytracksecresults));
              // this.tracks = applytracksecresults;

  
            }, (err) => {
              console.error(err); });
        
            }

            public applyStepset(kanbansetid,  kanbanitemid, stepsetid, stepsetn){
              console.log('applying kanban stepset now')
              const applyKanbanstepsetForm = new FormData();
              applyKanbanstepsetForm.append('kanbansetid', kanbansetid);
            applyKanbanstepsetForm.append('kanbanitemid', kanbanitemid);
            applyKanbanstepsetForm.append('stepsetid', stepsetid);
            applyKanbanstepsetForm.append('stepsetn', stepsetn);
         
             
           this.authService.applyStepset(applyKanbanstepsetForm, this.user).subscribe(applykanbanstepsetresults => {
              console.log('this is the results from applying kanban stepset: ' + JSON.stringify(applykanbanstepsetresults));
               this.stepset = applykanbanstepsetresults;
               this.hltrack[0].stepsetid = this.stepset[0]._id;
               this.hltrack[0].stepset = true;
  
            }, (err) => {
              console.error(err); });
        
            }

            createStepset(kanbanitemid, stepsetname, stepsetdesc)
            {
              const stepsetForm = new FormData();
              stepsetForm.append('kanbanitemid', kanbanitemid);
              stepsetForm.append('stepsetname', stepsetname);
              stepsetForm.append('stepsetdesc', stepsetdesc);
              this.datenow = Date.now();
              stepsetForm.append('addedon', this.datenow);
           this.authService.createStepset(stepsetForm, this.user).subscribe(stepsetresults => {
              console.log('this is the results from createing Tagset: ' + JSON.stringify(stepsetresults));
             //  this.formtemplate = formtemplateresults;
              this.stepset = stepsetresults
              console.log('this is the stepset results'+JSON.stringify(stepsetresults));
            //  var lastIndex = this.tracks.length
            //  console.log('this is the last index'+lastIndex)
            // var newstepset = {'stepsetid': stepsetresults[0].stepsetid, 'stepset': true}
           //  console.log('this is the tracks '+JSON.stringify(this.tracks));
            // this.stepset[lastIndex].add(newstepset)
           // console.log('this is the latest stepset '+JSON.stringify(this.tracks));
          //  this.tracks[lastIndex-1].stepset = true
          this.hltrack[0].stepset = true
          //  console.log('this is the stepset after settin to true'+JSON.stringify(this.tracks));

            console.log('this is the new stepsetid '+JSON.stringify(stepsetresults[0]._id));
           // this.tracks[lastIndex-1].stepsetid = this.stepset[0]._id
           this.hltrack[0].stepsetid = this.stepset[0]._id
            
          // this.stepset[lastIndex].concat({'stepset': true})
              console.log('this is the stepset results'+JSON.stringify(this.stepset));
               this.stepresults = true;
             // this.getTagerset( this.stepset);
             console.log('off to sgetStepsetbyid'+JSON.stringify(this.stepset));
            // this.getStepsetbyid(this.stepset[0]._id, true)
          //   console.log('off to select track now for'+JSON.stringify(this.hltrack[0]));
            // this.selectTrack(this.hltrack[0])
           //  this.getTagerset(this.hlKanbanset[0], true)
           
             
            }, (err) => {
              console.error(err); });
        
            }

            findstepset(stepsetname){
              console.log('this is the stepset that were looking for: ' + JSON.stringify(stepsetname));
              this.kanbans = []; 
            //  this.tracks= [];
               this.choicesets= []; 
               this.choiceitems = [];
              const findstepsetForm = new FormData();
              findstepsetForm.append('stepsetname', stepsetname);
             
              this.authService.findstepset(findstepsetForm, this.user).subscribe(findstepresults => {
                console.log('this is the results from trying to find stepsets: ' + JSON.stringify(findstepresults));
                 this.stepsets = findstepresults;
                 this.stepresults = true;
          
             
              }, (err) => {
                console.error(err); });
          
              }

              finishTager(atn: string, trkord: string){

                this.datenow = Date.now();
                const finishTagerform = new FormData();
                finishTagerform.append('atn', atn);
                finishTagerform.append('trkord', trkord);
                finishTagerform.append('co', this.datenow);
               
               
                this.authService.finishTager(finishTagerform, this.user).subscribe(finishtagerresults => {
                  console.log('this is the results from trying to finishTager: ' + JSON.stringify(finishtagerresults));
                  this.activetager = finishtagerresults;
                  
            
               
                }, (err) => {
                  console.error(err); });
            
                }

                createTagitem( createcatag, tagerid, tagertype, nodename, formitem, formtype, label,  formitemlabel, checkbox, category, hasdefault, defaultvalue)
    {
      this.datenow = Date.now();

      const tagitemForm = new FormData();
      tagitemForm.append('createcatag', createcatag);
      tagitemForm.append('tagerid', tagerid);
      tagitemForm.append('tagertype', tagertype);
      tagitemForm.append('nodename', nodename);
      tagitemForm.append('formitem', formitem);
      tagitemForm.append('formtype', formtype);
      tagitemForm.append('label', label);
      tagitemForm.append('formitemlabel', formitemlabel);
      tagitemForm.append('checkbox', checkbox);
      tagitemForm.append('category', category);
      tagitemForm.append('addedon', this.datenow);
      tagitemForm.append('hasdefault', hasdefault);
      tagitemForm.append('defaultvalue', defaultvalue);
      
      
     
   this.authService.createTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
      console.log('this is the results from createing tags: ' + JSON.stringify(tagitemresults));
       this.tagitems = tagitemresults;
      
    }, (err) => {
      console.error(err); });

    }

    updateTagitem( tagerid,tagid, tagertype, nodename, formitem, formtype, label,  formitemlabel, hasdefault, defaultvalue)
    {
      const tagitemForm = new FormData();
      tagitemForm.append('tagerid', tagerid);
      tagitemForm.append('tagid', tagid);
      tagitemForm.append('tagertype', tagertype);
      tagitemForm.append('nodename', nodename);
      tagitemForm.append('formitem', formitem);
      tagitemForm.append('formtype', formtype);
      tagitemForm.append('label', label);
      tagitemForm.append('formitemlabel', formitemlabel);
      tagitemForm.append('hasdefault', hasdefault);
      tagitemForm.append('defaultvalue', defaultvalue);
     
   this.authService.updateTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
      console.log('this is the results from createing tags: ' + JSON.stringify(tagitemresults));
       this.tagitems = tagitemresults;
      
    }, (err) => {
      console.error(err); });

    }

    deleteTagitem( nodeid,tagid, type, stepid?)
    {
      //this.configSvc.nodedetailsready = false
     console.log('deleting tag item from tagersvc')
      const deltagitemForm = new FormData();
      deltagitemForm.append('nodeid', nodeid);
 
      deltagitemForm.append('tagid', tagid);
      deltagitemForm.append('type', type);

      if(type == 'smartfoldertab' || type == 'nodetab'){
        deltagitemForm.append('stepid', stepid);
      }
     
     
      
     
   this.authService.deleteTagitem(deltagitemForm, this.user).subscribe(deltagitemresults => {
      console.log('this is the results from deleting tag: ' + JSON.stringify(deltagitemresults));
     //  this.tagitems = deltagitemresults;
       this.configSvc.nodetagsds = deltagitemresults.stepitems
      this.configSvc.nodeds = [deltagitemresults]
     
     this.configSvc.activetype= 'node' 
     this.configSvc.editnode ='editnode'; this.configSvc.toolmenu = 'tagtools'

   
   //  this.configSvc.editnode ='editnode';
    this.configSvc.nodedetailsready = true;
      
    }, (err) => {
      console.error(err); });

    }
    public getTagitem(selectedtager) {
    
      var tagerid = selectedtager._id
   //   console.log('highlighed tagset row: ' + JSON.stringify(this.highlightedTagset));
    //  this.configSvc.color = this.highlightedTagset[0].tagcolor
    //  console.log('configsvc color is now '+this.configSvc.color);
     //  this.currTagset = tagset;
     //  this.ts = '1';
    //   console.log('current tagset row: ' + JSON.stringify(this.currTagset));
      
       this.tagitems= []; this.choiceset= []; this.choiceitems = [];
       const getTagitemForm = new FormData();
       getTagitemForm.append('tagerid', tagerid);
       this.authService.getTagitem(getTagitemForm, this.user).subscribe(getTagitemresults => {
       console.log('this is the results from getting tag items choiceitem: ' + JSON.stringify(getTagitemresults));
        this.tagitems= getTagitemresults;
       // this.tagtype = 'tagitem';
     //   this.configSvc.color = getTagitemresults.color;
      }, (err) => {console.error(err); });

     }

     findTags(category)
     {
       const findTagsform = new FormData();
     
       findTagsform.append('category', category);
       
    this.authService.findTags(findTagsform, this.user).subscribe(findtagresults => {
       console.log('this is the results from finding Tags: ' + JSON.stringify(findtagresults));
       this.tags = findtagresults;
     }, (err) => {
       console.error(err); });
    
     }
    
              }
     

