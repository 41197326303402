

<mat-tab-group  [(selectedIndex)]="this.configSvc.foldertabIndex">

   

 

    



<mat-tab *ngIf="this.configSvc.folderdetailsds?.type == 'smartfolder' && this.configSvc.folderready">
  <ng-template mat-tab-label>
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.iconcolor}">{{this.configSvc.folderdetailsds?.icontype}}</i>
    {{this.configSvc.folderdetailsds?.name}}
  </ng-template>
  <span >
    <app-formfolder></app-formfolder></span>

</mat-tab>
<mat-tab *ngIf="this.configSvc.folderdetailsds?.kanbantagged">
  <ng-template mat-tab-label>
    
    <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'standard'">
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">view_column</i></span>
    
    <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'time'">
      <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">schedule</i></span>
    
      <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneydollars'">
<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">monetization_on</i></span>

<span *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneypounds'">
  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.kanbancolor}">currency_pound</i></span>

   
    <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'moneydollars' || this.configSvc.folderdetailsds?.kantype == 'moneypounds' ">  {{this.configSvc.folderdetailsds?.money}} on {{this.configSvc.folderdetailsds?.kanbanname}} </span> 
    <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'standard'"> {{this.configSvc.folderdetailsds?.kanbanname}}@{{this.editfolderFG.value.kanbanpercent}}%</span>
    <span *ngIf="this.configSvc.folderdetailsds?.kantype == 'time'"> {{this.configSvc.folderdetailsds?.kanbanname}} with {{this.configSvc.folderdetailsds?.time}} / {{this.configSvc.folderdetailsds?.timetotal}} hrs or {{this.percenttime}}% completed</span>
  </ng-template>
  <span>
    <app-kanban></app-kanban></span>

</mat-tab>

<mat-tab *ngIf="this.configSvc.folderdetailsds?.type == 'smartfoldertab' && this.configSvc.folderready">
  <ng-template mat-tab-label>
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.iconcolor}">{{this.configSvc.folderdetailsds?.icontype}}</i>
    {{this.configSvc.folderdetailsds?.name}}
  </ng-template>
  <span >
    <app-smartfoldertab></app-smartfoldertab></span>

</mat-tab>

<mat-tab *ngIf="this.configSvc.folderdetailsds?.tagged">
  <ng-template mat-tab-label>
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
    {{this.configSvc.folderdetailsds?.tagname}}
  </ng-template>
  <span *ngIf="this.configSvc.folderready">
    <app-foldertags  [indexlaunch]="'edit'" ></app-foldertags></span>

</mat-tab>

<mat-tab *ngIf="this.configSvc.folderdetailsds?.attach && this.configSvc.folderready">
  <ng-template mat-tab-label>
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': 'gray'}">attach_file</i>
    {{this.configSvc.folderdetailsds?.name}}
  </ng-template>
  <span >
    <app-nodefiles></app-nodefiles></span>

</mat-tab>





<mat-tab *ngIf="this.configSvc.folderdetailsds?.cmt">
  <ng-template mat-tab-label>
    <i class="material-icons icon-size"  [ngStyle]="{'color': this.configSvc.folderdetailsds?.cmtclr}">{{this.configSvc.folderdetailsds?.cmticon}}</i>
  
  </ng-template>
    <app-viewcomments></app-viewcomments>

</mat-tab>

<mat-tab  *ngIf="this.configSvc.folderdetailsds?.deadline == true" label="Deadline">
  <ng-template mat-tab-label>
   <span> <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >timer</i></span>
   
  
    </ng-template>
  <span class= "comments">
    <app-deadline></app-deadline>
  </span>
  </mat-tab>

<mat-tab  *ngIf="this.configSvc.folderdetailsds?.assigned == true" label="Assigned">
  <ng-template mat-tab-label>
    <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >person</i>
  
    </ng-template>
  <span class= "comments">
    <app-assigned></app-assigned>
  </span>
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.folderdetailsds?.locktoolbar == true" label="Locked Toolbar">
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.folderdetailsds.toolbarclr}" >{{this.configSvc.folderdetailsds.toolbaricon}}</i>
    
      </ng-template>
    <span class= "comments">
      <app-locktoolbar></app-locktoolbar>
    </span>
    </mat-tab>

  <mat-tab> 
    <ng-template mat-tab-label>
    <span *ngIf="this.configSvc.folderdetailsds?.counttotals == false">  <i class="material-icons" [ngStyle]="{'color': 'yellow'}">folder</i>
      {{this.configSvc.activename}} Details</span>
      <span *ngIf="this.configSvc.folderdetailsds?.counttotals == true">  <i class="material-icons" [ngStyle]="{'color': 'yellow'}">folder</i>
        {{this.configSvc.activename}} 
        <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >calculate </i>
        <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">${{this.configSvc.folderdetailsds?.total}}</span>
      </span>



    </ng-template>
      <span [formGroup]="editfolderFG" class = "details-container">
         
          <div> <mat-form-field appearance="fill" floatLabel="always" > 
                <mat-label>name</mat-label>
                    <input formControlName="name" matInput value="{{this.configSvc.folderdetailsds?.name}}">
                   </mat-form-field></div>

                   <div> <mat-form-field appearance="fill" floatLabel="always" > 
                    <mat-label>desc</mat-label>
                        <input formControlName="desc" matInput value="{{this.configSvc.folderdetailsds?.desc}}">
                       </mat-form-field></div>
          

            <div><mat-form-field appearance="outline" floatLabel="always" >
              
                <mat-label >Added By</mat-label><input matInput [readonly]= "true" matInput value={{this.configSvc.folderdetailsds?.ab}}>
              </mat-form-field> </div>

              <div><mat-form-field appearance="outline" floatLabel="always" >
              
                <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
                [ngModelOptions]="{standalone: true}"
                [ngModel]="this.configSvc.folderdetailsds?.ao | date: 'medium'"
                >
              </mat-form-field> </div>

              <div>
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label >Folder Count</mat-label><input matInput [readonly]= "true" formControlName="fldrcnt" matInput value={{this.configSvc.folderdetailsds?.fldrcnt}}>
                 </mat-form-field>
                 </div>

                 <div>
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label >Item Count</mat-label><input matInput [readonly]= "true" formControlName="itemcnt" matInput value={{this.configSvc.folderdetailsds?.itemcnt}}>
                   </mat-form-field>
                   </div>

                   <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label >Kanbanopts</mat-label><input matInput [readonly]= "true" formControlName="kanbanopts" matInput value={{this.configSvc.folderdetailsds?.kanbanopts}}>
                     </mat-form-field>
                     </div>

                     <div>
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label >Kanbanpercent</mat-label><input matInput [readonly]= "true" formControlName="kanbanpercent" matInput value={{this.editfolderFG.value.kanbanpercent}}>
                       </mat-form-field>
                       </div>

                       <div>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label >Kansect</mat-label><input matInput [readonly]= "true" formControlName="kansect" matInput value={{this.configSvc.folderdetailsds?.kansect}}>
                         </mat-form-field>
                         </div>

              <div>
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label >Tagged</mat-label><input matInput [readonly]= "true" formControlName="tagged" matInput value={{this.configSvc.folderdetailsds?.tagged}}>
                 </mat-form-field>
                 </div>

                 <div *ngIf="this.configSvc.folderdetailsds?.tagged">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label >TaggedBy</mat-label><input matInput [readonly]= "true" formControlName="taggedby" matInput value={{this.configSvc.folderdetailsds?.taggedby}}>
                   </mat-form-field>
                   </div>
                 
                 <div *ngIf="this.configSvc.folderdetailsds?.kanbantagged">
                  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label >Kanban Name</mat-label><input matInput [readonly]= "true" formControlName="nodename" matInput value={{this.configSvc.folderdetailsds?.kanbanname}}>
                   </mat-form-field>
                   </div>

                   

                     <div *ngIf="this.configSvc.folderdetailsds?.kanbantagged">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label >Kanban ID</mat-label><input matInput [readonly]= "true" formControlName="kanbanid" matInput value={{this.configSvc.folderdetailsds?.kanbanid}}>
                       </mat-form-field>
                       </div>

                       <div>
                          <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label >Kanban Tagged</mat-label><input matInput [readonly]= "true" formControlName="kanbantagged" matInput value={{this.configSvc.folderdetailsds?.kanbantagged}}>
                           </mat-form-field>
                           </div>


                           <div *ngIf="this.configSvc.folderdetailsds?.cmt">
                            <mat-form-field appearance="outline" floatLabel="always">
                              <mat-label >Comment</mat-label><input matInput [readonly]= "true" formControlName="cmt" matInput value={{this.configSvc.folderdetailsds?.cmt}}>
                             </mat-form-field>
                             </div>

                           <div *ngIf="this.configSvc.folderdetailsds?.kanbantagged">
                              <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label >Kanban</mat-label><input matInput [readonly]= "true" formControlName="kanbanname" matInput value={{this.configSvc.folderdetailsds?.kanbanname}}>
                               </mat-form-field>

                             

                               <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label >Kanban Color</mat-label><input matInput [readonly]= "true" formControlName="kanbancolor" matInput value={{this.configSvc.folderdetailsds?.kanbancolor}}>
                               </mat-form-field>

                               </div>

         
            <div> <mat-form-field appearance="outline" floatLabel="always" >
                <mat-label>FolderID</mat-label><input  matInput placeholder={{this.configSvc.folderdetailsds?._id}}>
             </mat-form-field></div>

             <div> <button  [disabled]= "!this.editfolderFG.dirty" (click)="this.configSvc.renameFolder(this.editfolderFG.value.name, this.editfolderFG.value.desc);"
              mat-raised-button color="primary" [disabled]="!editfolderFG.dirty">Apply Changes</button>
           </div>
           
           
           
            </span>

   
  </mat-tab>

    </mat-tab-group>

   