import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup,FormArray, Validators} from '@angular/forms';
import { ConfigService } from '.././../services/config.service';
import { TagService } from '.././../services/tag.service';
import { TagerService } from '.././../services/tager.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';

interface Rowsize {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-edittag',
  templateUrl: './edittag.component.html',
  styleUrls: ['./edittag.component.css']
})


export class EdittagComponent implements OnInit {

  public hasdefault = false;
  public tagsArray: FormArray;
  public tagsFG: FormGroup;
  hltreepath =[];
  public newpropvalues = [];
  resultColumns = ['Name'];
  hlchoiceset = [];
 public choicesetselected = false;
 public sorted = [];
 stpcnt: string;

 private defaultSelected = 0;
  private selection: number;
  public selectedRowsize = 'full';
  sizes: Rowsize[] = [
    {value: 'full', viewValue: 'Full'},
    {value: 'half', viewValue: 'Half'},
    {value: 'third', viewValue: 'Third'}]

  constructor(private fb: FormBuilder, public configSvc: ConfigService,private authSvc: AuthenticationService, private router: Router,
     public tagSvc: TagService, public tagerSvc: TagerService) { }

  ngOnInit() {

    this.tagsFG = new FormGroup({
      formArrayName: this.fb.array([])
    })
   
    this.buildstepitems();
    this.detectNodechanges();
   
/*
    if (this.configSvc.hltag[0].formchoices){
        
      console.log('this property has a choicelist associated with it, lets go and get it')
      this.tagerSvc.getChoicesetbyid(this.configSvc.hltag[0].choicesetid, 'choicelist')
     //  this.tagSvc.getChoices(this.configSvc.hltag[0].choicesetid,1)
    }
*/
   
  }

  togglelabel(){
  //  this.tagsFG.value.label = !this.tagsFG.value.label;
    this.configSvc.nodeds[0].label = ! this.configSvc.nodeds[0].label 
  this.tagsFG.patchValue({label: this.configSvc.nodeds[0].label})
    
  }
  toggledefault(){
    console.log('toggled the default')

    this.tagsFG.value.hasdefault = !this.tagsFG.value.hasdefault
    
  }

  toggleChange(){
   // console.log('changed current toggle value from '+this.configSvc.nodeds[0].hasdefault  )
   // console.log('changed toggle value')
  
   
   // this.configSvc.nodeds[0].hasdefault = !this.configSvc.nodeds[0].hasdefault
  //  console.log('changed current toggle value TO '+this.configSvc.nodeds[0].hasdefault  )
  this.configSvc.nodeds[0].hasdefault = ! this.configSvc.nodeds[0].hasdefault 
  this.tagsFG.patchValue({hasdefault: this.configSvc.nodeds[0].hasdefault})
   // this.tagsFG.markAsDirty();
  }

  sliderChange(){
    console.log('changed current slider value from '+this.configSvc.hltag[0].slider)
    console.log('changed toggle value')
  
   //  this.tagsFG.value.hasdefault = !this.tagsFG.value.hasdefault
    this.configSvc.hltag[0].hasdefault = !this.configSvc.hltag[0].hasdefault
    console.log('changed current toggle value TO '+this.configSvc.hltag[0].hasdefault  )
    this.tagsFG.markAsDirty();
  }

  public getChoices(choicesetid){
  
    var filtered = this.tagerSvc.choicesetsrch.filter(item => item.parentid === choicesetid)
   
   return filtered;
  }

  public choiceChange(){
   
   console.log('choices is changed');

  // this.tagsFG.value.itemvalue = choice.name

   console.log('itemvalue is set to: '+this.tagsFG.value.itemvalue);
   // console.log('choices level is set to: '+JSON.stringify(choice.level));
    // console.log('choices new level is set to: '+JSON.stringify(choice.level+1));
  //  var newlevel = JSON.stringify(choice.level+1)
  //  console.log('choices new level is set to: '+newlevel);
  // this.tagSvc.getChoices(choice._id, newlevel)
  }

  public getChoiceset(choiceset){
    this.choicesetselected = true;
    this.hlchoiceset = [];
    this.hlchoiceset.push(choiceset)
    
    console.log('this is the hl choiceset'+JSON.stringify(this.hlchoiceset[0]))

    this.tagsFG.value.itemvalue = this.hlchoiceset[0].name
    console.log('this is the ite selected: '+this.tagsFG.value.itemvalue);

    if(this.hlchoiceset[0].haschild){
    this.tagerSvc.getChoicesetbyid(choiceset._id, 'choicelist')}
  }

  public getChip(choicesetid){
   
    this.sorted = [];
    console.log('getting choicesetbyid with getChip')
    this.tagerSvc.getChoicesetbyid(choicesetid, 'choicelist')
  }

  public sortPath(){
    
    
    if(this.choicesetselected){
      this.sorted = [];
      console.log('set sorted to blank')
    this.sorted = this.hlchoiceset[0].path.sort((a, b) => Number(a.level) - Number(b.level));
    // console.log('this is the sorted result: '+JSON.stringify(sorted))
      return this.sorted
    }
  }

 

  buildstepitems(){
    this.configSvc.nodeclicked = true
          console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.nodeds));
          console.log('this is the activflow '+this.configSvc.activeflow)
          console.log('this is the activtype '+this.configSvc.activetype)
          console.log('this is the activeitemid '+this.configSvc.activeitemid)
    
          
    /*
          if(this.configSvc.nodeds[0].type == 'nodeimage'){
            console.log('setting up image now')
    
            let mySrc;
            const reader = new FileReader();
            reader.readAsDataURL(this.uploadSvc.imageurl); 
            reader.onloadend = function() {
               // result includes identifier 'data:image/png;base64,' plus the base64 data
               mySrc = reader.result; 
               console.log('my sourcve is '+mySrc)    
            }
         
          }
          */
          
    
     
    
                var choicesetids= []
                this.tagsArray = this.tagsFG.get('formArrayName') as FormArray;
            
    
             
               console.log('this is the formstep array'+this.configSvc.nodeds[0]);
             
             
             
           // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[0].formitem);
               // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[0].value);
      
               this.tagsFG = new FormGroup({
                  //  formstep: new FormControl({ value: 0}),
                     formitem: new FormControl({ value: this.configSvc.nodeds[0].formitem,  disabled: false }),
                     label: new FormControl({ value: this.configSvc.nodeds[0].label, disabled: false}),
                     formitemlabel: new FormControl({ value: this.configSvc.nodeds[0].formitemlabel,  disabled: false }),
                    itemvalue: new FormControl({ value: this.configSvc.nodeds[0].itemvalue, disabled: false  }),
                     formtype: new FormControl({ value: this.configSvc.nodeds[0].formtype, disabled: false }),
                   formchoices: new FormControl({ value:this.configSvc.nodeds[0].formchoices, disabled: false }),
                  choicesetid: new FormControl({ value: this.configSvc.nodeds[0].choicesetid}),
                  choicesetname: new FormControl({ value: this.configSvc.nodeds[0].choicesetname}),
                  choicevalue: new FormControl({ value: this.configSvc.nodeds[0].choicevalue, disabled: false }),
                  hasdefault: new FormControl({ value: this.configSvc.nodeds[0].hasdefault, disabled: false }),
                  type: new FormControl({ value:this.configSvc.nodeds[0].type, disabled: false }),
                  eleord: new FormControl({ value:this.configSvc.nodeds[0].eleord, disabled: false }),
                  icontype: new FormControl({ value:this.configSvc.nodeds[0].icontype, disabled: false }),
                  property: new FormControl({ value:this.configSvc.nodeds[0].property, disabled: false }),
                  textarea: new FormControl({ value:this.configSvc.nodeds[0].textarea, disabled: false }),
                  rowsize: new FormControl(this.configSvc.nodeds[0].rowsize),
                  treepath: new FormControl({ value: this.configSvc.nodeds[0].treepath, disabled: false}),
                  _id: new FormControl({ value: this.configSvc.nodeds[0]._id, disabled: false}),
                  
                    })
      
                    console.log('formitem is :'+JSON.stringify(this.tagsFG.value.formitem));
                    console.log('itemvalue is :'+JSON.stringify(this.tagsFG.value.itemvalue));
                    
                    if (this.configSvc.nodeds[0].formtype=='choicelist'
                      && localStorage.getItem(this.configSvc.nodeds[0].choicesetid)===null){ 
                     
                        var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].choicesetid))
                   console.log('parta choicelist this is the localstoarage list that im getting '+JSON.stringify(localdata));
                      console.log('this property has a list associated with it and its not in cache, lets go and get it')
                   // choicesetids.push(this.configSvc.nodeds[0].choicesetid)
                 
                   this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].choicesetid, 'cache')
    
                    }
    
    
    
    
        if (this.configSvc.nodeds[0].formtype=='choicelist'
                      && localStorage.getItem(this.configSvc.nodeds[0].choicesetid)!==null){ 
                      console.log('partb choicelist check to see if the cache variable has the localstorage cache')
                      console.log('this is the choicesetid: '+this.configSvc.nodeds[0].choicesetid)
                   // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].choicesetid)
                   var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].choicesetid))
                   console.log('this is the localstoarage '+localdata);
                          if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].choicesetid)){
                    this.authSvc.choicesetids.push(this.configSvc.nodeds[0].choicesetid)
                    console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].choicesetid);
                    console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                    this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)}
          }
    
    
          if (this.configSvc.nodeds[0].formtype=='radiogroup'
          && localStorage.getItem(this.configSvc.nodeds[0].choicesetid)===null){ 
         
            var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].choicesetid))
       console.log('parta radiogroup this is the localstoarage list that im getting '+JSON.stringify(localdata));
          console.log('this property has a list associated with it and its not in cache, lets go and get it')
       // choicesetids.push(this.configSvc.nodeds[0].choicesetid)
     
       this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].choicesetid, 'cache')
    
        }
    
        if (this.configSvc.nodeds[0].formtype=='radiogroup'
        && localStorage.getItem(this.configSvc.nodeds[0].choicesetid)!==null){ 
        console.log('radiogroup partb check to see if the cache variable has the localstorage cache')
        console.log('this is the choicesetid: '+this.configSvc.nodeds[0].choicesetid)
     // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].choicesetid)
     var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].choicesetid))
     console.log('this is the localstoarage '+localdata);
            if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].choicesetid)){
      this.authSvc.choicesetids.push(this.configSvc.nodeds[0].choicesetid)
      console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].choicesetid);
      console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
      this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)}
    }
    
    
    
       if (this.configSvc.nodeds[0].formtype == 'treelist'){
                    this.hltreepath = this.configSvc.nodeds[0].treepath
                      console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
                     this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].choicesetid, 'treelist')
                     
          }

        }
    
       
    
  
    detectNodechanges(){
    
      console.log('checking for changes to the node');
      this.tagsFG.valueChanges
      
      .subscribe(
        data => {
          this.newpropvalues = [];
        console.log('new propvalues: ' + JSON.stringify(data));
        this.tagsFG.markAsDirty();
       
         
          console.log('formitem value is '+this.tagsFG.value.formitem);
          console.log('property value is '+this.tagsFG.value.itemvalue);
          console.log('label is '+this.tagsFG.value.label.value);
          console.log('pformitemlabel is '+this.tagsFG.value.formitemlabel);
          console.log('checkbox is '+this.tagsFG.value.checkbox);
          console.log('textarea is '+this.tagsFG.value.textarea);
          if(this.tagsFG.value.formtype != 'text' && this.tagsFG.value.formtype != 'boolean'){this.tagsFG.value.textarea = ''}
          if(this.tagsFG.value.formtype == 'boolean'){
            if(this.tagsFG.value.label == false ){ this.tagsFG.value.textarea = ''}
         // if(this.tagsFG.value.label == true ){this.tagsFG.value.textarea = this.tagsFG.value.textarea;}
          }
    
          if(this.tagsFG.value.formtype =='choicelist'){
            console.log('there ARE choicesets associated with this choicelist')
          this.newpropvalues.push({
            formitem:this.tagsFG.value.formitem.value, 
            itemvalue:this.tagsFG.value.itemvalue, 
            formchoices:this.tagsFG.value.formchoices.value, 
            checkbox:this.tagsFG.value.checkbox,
            formtype: this.tagsFG.value.formtype.value,   
            formitemlabel: this.tagsFG.value.formitemlabel.value, 
            textarea: this.tagsFG.value.textarea, 
            label: this.tagsFG.value.label.value, 
            choicesetid:this.tagsFG.value.choicesetid.value, 
            hasdefault: this.tagsFG.value.hasdefault,
            rowsize: this.tagsFG.value.rowsize})}
  
            if(this.tagsFG.value.formtype =='radiogroup'){
              console.log('there ARE choicesets associated with this radiogroup')
            this.newpropvalues.push({
              formitem:this.tagsFG.value.formitem.value, 
              itemvalue:this.tagsFG.value.itemvalue, 
              formchoices:this.tagsFG.value.formchoices.value, 
              checkbox:this.tagsFG.value.checkbox,
              formtype: this.tagsFG.value.formtype.value,   
              formitemlabel: this.tagsFG.value.formitemlabel.value, 
              textarea: this.tagsFG.value.textarea, 
              label: this.tagsFG.value.label.value, 
              choicesetid:this.tagsFG.value.choicesetid.value, 
              hasdefault: this.tagsFG.value.hasdefault,
              rowsize: this.tagsFG.value.rowsize})}
            if(this.tagsFG.value.formtype =='treelist'){
              console.log('pushing new values based on treelist')
              console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
            this.newpropvalues.push({
              formitem:this.tagsFG.value.formitem.value, 
              itemvalue:this.tagsFG.value.itemvalue, 
             
              formtype: this.tagsFG.value.formtype.value,   
              formitemlabel: this.tagsFG.value.formitemlabel.value, 
             
              label: this.tagsFG.value.label.value, 
              choicesetid:this.tagsFG.value.choicesetid.value, 
              hasdefault: this.tagsFG.value.hasdefault,
              rowsize: this.tagsFG.value.rowsize,
              treepath: this.hltreepath
            })}
          if(this.tagsFG.value.formtype =='string'|| this.tagsFG.value.formtype =='number' || this.tagsFG.value.formtype =='image' ||
          this.tagsFG.value.formtype =='checkbox' || this.tagsFG.value.formtype =='date' ||
          this.tagsFG.value.formtype =='text'|| this.tagsFG.value.formtype =='boolean' 
          ){ 
            console.log('there are no choiceset associated with this property')
            this.newpropvalues.push({
              checkbox:this.tagsFG.value.checkbox,
              choicevalue: this.tagsFG.value.choicevalue,
              eleord: this.tagsFG.value.eleord,
              formchoices:this.tagsFG.value.formchoices, 
              formitem:this.tagsFG.value.formitem, 
              formitemlabel: this.tagsFG.value.formitemlabel, 
              formtype: this.tagsFG.value.formtype,
              hasdefault: this.tagsFG.value.hasdefault,
               
              icontype: this.tagsFG.value.icontype,
              itemvalue:this.tagsFG.value.itemvalue,
              label: this.tagsFG.value.label, 
              property: this.tagsFG.value.property, 
              rowsize: this.tagsFG.value.rowsize, 
               textarea: this.tagsFG.value.textarea, 
               treepath: this.tagsFG.value.treepath, 
               choicesetid: 'nocs',
             //  _id: this.tagsFG.value._id, 
          
            })
              
            
              console.log('this is the updated tags:'+JSON.stringify(this.newpropvalues))
            }
       
            
        //  this.newpropvalues.push({property:this.tagsFG.value.property.value, propval:this.tagsFG.value.propvalue, formtype:this.tagsFG.value.formtype.value})
        
        
        }
      ); 
    }
  
    public updateTagitems(){
      console.log('updating tag now with new values ')
      console.log('new propvalues: ' + JSON.stringify(this.newpropvalues));
      console.log('this is the nodeitemid '+this.configSvc.activeitemid);

      if(this.configSvc.activetype == 'node'){
        this.configSvc.updateTagitems(this.configSvc.activeitemid, this.tagsFG.value._id, JSON.stringify(this.newpropvalues), this.configSvc.activetype)
      }

      if(this.configSvc.activetype == 'nodetab'){
      this.configSvc.updateTagitems(this.configSvc.activeitemid, this.tagsFG.value._id, JSON.stringify(this.newpropvalues), this.configSvc.activetype, this.configSvc.stepdetailsds[0]._id)
    }
  }

    public deleteTagitem(){

     
      console.log('deleting tag now for nodeid '+this.configSvc.activeitemid)
      console.log('itemid of tag to delete is '+this.configSvc.nodeds[0]._id)
      this.configSvc.nodedetailsready = false;

      
      this.tagerSvc.deleteTagitem(this.configSvc.activeitemid, this.configSvc.nodeds[0]._id, this.configSvc.activetype)}


  
      /*
      var base = '/toolbard/nodeconsole?'
      var itemid = this.configSvc.activeitemid
      var type = this.configSvc.activetype
      var teamid = this.configSvc.activeteamid;
      var name =  this.configSvc.activename
      
      var level = this.configSvc.activelevel;
      var dynamicurl = base+'itemid='+itemid+'&type='+type+'&name='+name+'&level='+level+'&teamid='+teamid
        this.router.navigateByUrl(dynamicurl);
        */
    
}
