<form [formGroup]="lockFG">
    <span class = "container">
     
            <div *ngIf="this.assigntype != 'toolbar'" class = "space"> 
    
    This folder is locked to: 
    <div>
    <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.folderdetailsds.toolbarclr}" >{{this.configSvc.folderdetailsds.toolbaricon}}</i>
    {{this.configSvc.folderdetailsds.toolbarname}}:  {{this.configSvc.folderdetailsds.toolbardesc}}
</div>
</div>
    

    <span >  <button class = "save"
            (click)="this.unlockToolbar()"
           
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Unlock">
        Unlock Toolbar </button></span> 
         
    
    
    </span>  </form>
    