<mat-card   [ngClass]= "
{'activenode-container': this.configSvc.popup == false,
'activenode-popup': this.configSvc.popup == true
} "
>
    <form  [formGroup]="tagsFG" >
  
  <mat-card  [ngClass]= "
  {'activenode': this.configSvc.activetype == 'node',
  'nodedirect': this.configSvc.activetype == 'nodedirect'
  } ">
  
      <div class = "popuptitle">{{this.configSvc.nodeds[0]?.desc}}</div> 
        
      <div *ngIf="this.configSvc.nodeds[0]?.statetracker"> 
         
        <div class= "labels"> 
   
          <mat-slide-toggle [disabled] = "!this.tagsFG.dirty"
          [checked] = this.configSvc.nodeds[0].state
          (change)="changeState()">
                                  
           {{this.configSvc.nodeds[0].statelabel}}
            </mat-slide-toggle>
         <span *ngIf="this.configSvc.nodeds[0].state">
      
          <i class="material-icons md-48" [ngStyle]="{'color': 'green'}">check_circle_outline </i>
         </span>
           
          </div> 
   
         </div>
    
     
      <span [formArrayName]="'formArrayName'" class = "container">
    
        <span *ngFor="let property of this.tagsArray.controls; let i = index"  [ngClass]= "
        {'fullrow': property.value.rowsize == 'full',
        'halfrow': property.value.rowsize == 'half',
        'thirdrow': property.value.rowsize == 'third'}">

         
           <span [formGroupName]="i" >
             
         
      
              <span *ngIf="property.value.formtype.value == 'string'  "
              > 
           
                <span *ngIf="property.value.label.value==true" class = "labels">
                  <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                 
               
                <mat-form-field appearance="fill" [ngClass]= "
                {'matty': property.value.rowsize == 'full',
                'matty2': property.value.rowsize == 'half',
                'matty3': property.value.rowsize == 'third'}"> 
                 <span *ngIf="property.value.label.value==false">
                      <mat-label >{{property.value.formitem.value}} </mat-label></span>
                      <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}" >
                     </mat-form-field>
                  
  
                   <div *ngIf="property.value.label.value==false" class = "labels">
                   
                   <mat-form-field appearance="outline" floatLabel="always"> 
                     <mat-label >{{property.value.property.value}} </mat-label>
                         <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                        </mat-form-field>
                       </div> 
                  
                      </span>  

 <div *ngIf="property.value.formtype.value == 'label' "
 class = "labels2"> 

   <span >
     <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
     
</div>

 <div *ngIf="property.value.formtype.value == 'image' "> 

   <span *ngIf="property.value.label.value==true" class = "labels">
     <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
    
  
   <mat-form-field appearance="fill"> 
    <span *ngIf="property.value.label.value==false">
         <mat-label >{{property.value.formitem.value}} </mat-label></span>
         <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
        </mat-form-field>
     

      <div *ngIf="property.value.label.value==false" class = "labels">
      
      <mat-form-field appearance="outline" floatLabel="always"> 
        <mat-label >{{property.value.property.value}} </mat-label>
            <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
           </mat-form-field>
          </div> 

          <div >
                  
            <img [src]="this.uploadSvc.imageblob" height="200"> <br/>
          </div>
     
     </div> 
                  
                  
        
                     <div *ngIf="property.value.formtype.value == 'number'"> 
                       <span class ="itemcontainer">
         <span *ngIf="property.value.label.value==true" class = "labels">
                         <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                        
                      <mat-form-field appearance="fill">  
                          
                            <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
          </mat-form-field></span>
         
          <span *ngIf="property.value.label.value==false" class = "labels">
       
           <mat-form-field appearance="outline" floatLabel="always"> 
             <mat-label >{{property.value.property.value}} </mat-label>
                 <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                </mat-form-field>
         </span></span>
       </div>  
         <div *ngIf="property.value.formtype.value == 'boolean'"> 
         
       <div class= "labels"> 
  
         <mat-slide-toggle 
         [checked] = property.value.hasdefault
         (change)="changeBoolean(property.value.hasdefault, i)">
                                 
          {{property.value.formitemlabel.value}}
           </mat-slide-toggle>

           
<span *ngIf="property.value.label.value == true">
  <span *ngIf="property.value.hasdefault == true">
           <mat-slide-toggle 
           [checked] = property.value.checkbox
           (change)="changeBoolean2(property.value.checkbox, i)">
                      
         <span *ngIf="property.value.checkbox == false">{{ property.value.itemvalue}}
          <i class="material-icons status-boolean"   [ngStyle]="{'color': 'green'}" 
          >check_circle </i>

         </span> 
         <span *ngIf="property.value.checkbox == true">{{ property.value.textarea}}
   
        
         </span> 
             </mat-slide-toggle>
            </span>
            </span>
        
          
         </div> 
  
        </div>
        
                     <div *ngIf="property.value.formtype.value == 'date'"> 
                       <span *ngIf="property.value.label.value==true" class = "labels">
                         <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                        </span> 
                     <mat-form-field appearance="fill">
                       <input matInput [matDatepicker]="picker1" placeholder="{{property.value.formitem.value}}" formControlName="itemvalue" >
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker  #picker1></mat-datepicker>
                          </mat-form-field></div>

                          <div *ngIf="property.value.formtype.value == 'datetime'"> 
                            <span *ngIf="property.value.label.value==true" class = "labels">
                              <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                             </span> 
                          <mat-form-field appearance="fill">
                            <input matInput  type="datetime-local"  placeholder="{{property.value.formitem.value}}" formControlName="itemvalue" >
                                
                               </mat-form-field></div>
  
  
                          <div *ngIf="property.value.formtype.value == 'checkbox'" class = "checkers"> 
                          
                           
                            <mat-checkbox formControlName="hasdefault">
                             {{property.value.formitemlabel.value}} </mat-checkbox>

                             <span [class.hide]="!property.value.hasdefault" class="textbox">
                              <mat-form-field appearance="fill" > 
                             
                                  
                                     <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                  </mat-form-field>
                             </span> 
                   </div>
  
                   <div *ngIf="property.value.formtype.value == 'text'" class = "flex-item" > 
                             
                    <span class = "labels">
                      <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                     </span> 
                   <div>
                     <mat-form-field appearance="outline"   class = "textarea">
                        <textarea 
                       
                        cdkTextareaAutosize
                        cdkAutosizeMinRows=this.textarearowmin
                        cdkAutosizeMaxRows=this.textarearowmax
                        
                        formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                       </mat-form-field>
                      </div>
                      </div> 
    
                            <div *ngIf="property.value.formtype.value == 'choicelist'  ">
  
  
                             <span class= "itemcontainer">
                               <span class = "labels">
                               <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
                               <span class = "list">
  <mat-form-field>
                           
                              <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue" 
                             
                              (selectionChange)="choiceChange()"
                              >
                          <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                            {{choice.name}}
                          </mat-option>
                        </mat-select>
                       
                      </mat-form-field></span>
                     </span>
                      
                    
                        </div>
  
                        <div *ngIf="property.value.formtype.value == 'treelist'  ">
                   
                         <span *ngIf="property.value.label.value==true"  class= "treecontainer">
                <span class = "labels">
                <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
  
             
              <mat-chip-list>
        
                
                <mat-chip *ngFor="let paths of this.sortPath()"
                (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
                >
                  {{paths.name}}
            </mat-chip>
            </mat-chip-list>
        
          
              <div *ngIf="clickchoiceset" class="outerbox">  
                <span  >
                <table mat-table [dataSource]="this.tagerSvc.activetree"  >
                
                  <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                  (click)="this.getChoicetree(selectedrow._id); 
                 
                  
                  this.hlchoice(selectedrow,  i);
                  "
                  [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                  class="innerbox"
                  ></tr>
                
                
                           
                <ng-container matColumnDef="Name" >
                      <th mat-header-cell *matHeaderCellDef>Name </th>
                   <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.haschild">  
                      <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                      </span>
                       {{element.name}}
                 </td></ng-container>
                
                   </table> </span>
        </div>
        
       
        <div *ngIf="this.clickchoiceset"><mat-chip-list>
        <mat-chip>
        {{property.value.itemvalue}}
        </mat-chip></mat-chip-list></div>
        
        </span>
                         
                   
                
                   
                 
                   
                    </div>
  
                        <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                  <span  *ngIf="property.value.label.value==true" >
                         <span>
                           <span class = "labels">
                           <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
                         <mat-radio-group>
                         <mat-radio-button class="radio-button" 
                          *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                         [value]="choice.name"
                         [checked]="choice.name == property.value.itemvalue"
                         (change)="changeRadio(choice, i)"
                         >
                           {{choice.name}}
                         </mat-radio-button>
                       </mat-radio-group>
                      
                     </span>
                   </span>
                
                    </div>
                 
                
                 
                  </span></span>
            </span>

            <div *ngIf="this.configSvc.nodeds[0]?.website"> 
         
              <div class= "labels"> 
               <div> <mat-label>Link to Webpage</mat-label></div>
                <mat-form-field appearance="fill" style="width: 40vw" > 
              
                
                      <input 
                      matInput value="{{this.configSvc.nodeds[0]?.weblink}}"
                      [ngModelOptions]="{standalone: true}"
                      [ngModel]="this.weblinkFC"
                      (ngModelChange)="webChange($event)"
                    
                       >
                     </mat-form-field>
           
                 
                </div> 
         
               </div>
          
          </mat-card>
    
         
<mat-card *ngIf="this.configSvc.quickadd == false" class = "outer">
    <span *ngIf="this.configSvc.mode == 'queue' || this.configSvc.mode == 'browse'">
<span  class = "save-container" *ngIf="this.configSvc.quickadd == false && this.configSvc.activetype !='queue' &&
 this.configSvc.activetype !='smartfolder' && this.configSvc.activetype !='basicflow' && this.configSvc.nodeds[0]?.type != 'basicflow' ">
<button  [disabled]= "!this.tagsFG.dirty" (click)="this.updateTags();"
  mat-raised-button color="primary" [disabled]="!tagsFG.dirty">Save Changes</button>
</span>



<span  class = "save-container" *ngIf="this.configSvc.activetype =='activeflow' && this.configSvc.nodeds[0].status == 3">
<button  [disabled]= "!this.tagsFG.dirty" (click)="this.reTriggerflow();"
  mat-raised-button color="primary" [disabled]="!tagsFG.dirty">Re-Launch</button>
</span>






    
<div  class ="nextbtns">

  <span *ngIf="this.configSvc.activetype =='basicflow' && this.configSvc.nodeds[0].assignedat !='0'"> <button  (click)="this.prevPhase();"
    mat-raised-button color="primary" >{{this.prev}}</button>
   </span>

  

         <span *ngIf="this.configSvc.activetype =='basicflow' || this.configSvc.nodeds[0].type == 'basicflow'"> 
           <button  (click)="this.nextPhase();"
          mat-raised-button color="primary" >{{this.next}}</button>
      </span>

      

      
      </div>
    
    </span> 

    <span  class = "save-container" *ngIf="this.configSvc.activetype =='batchitems' && this.configSvc.mode == 'design'">
      <button  [disabled]= "!this.tagsFG.dirty" (click)="this.updateBatchitem();"
        mat-raised-button color="primary" [disabled]="!tagsFG.dirty">Update Batch</button>
      </span>

    </mat-card>

    
 

      </form>
     
    </mat-card>