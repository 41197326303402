<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor || 'black'}" >{{this.configSvc.nodeds[0].icontype}}</i>
    
     Details
      </ng-template>
      <mat-card  class = "details-container">
    <form [formGroup]="editnodeFG">
      <span  >
    <mat-form-field appearance="fill" floatLabel="always" >
      <mat-label>Name</mat-label>
      <input   formControlName="name" matInput value={{this.configSvc.nodeds[0].name}}>
    </mat-form-field>
  
    <div>
      <mat-form-field appearance="fill" floatLabel="always" >
        <mat-label>Description</mat-label>
        <input  formControlName="desc" matInput value={{this.configSvc.nodeds[0].desc}}>
      </mat-form-field></div>
    </span>


  
    <span *ngIf=" this.configSvc.activetype != 'foldertags'">
      <app-iconcategories></app-iconcategories></span>
  
      <div *ngIf=" this.configSvc.activetype == 'foldertags'">
        Choose a colour to identify folders that are using this foldertag</div>

        <mat-checkbox formControlName="textractable" >Textract values from attachments</mat-checkbox>
        <span *ngIf="this.editnodeFG.value.textractable">
        <mat-radio-group formControlName ="textractype"
        >

  
        <mat-radio-button value="form">Form </mat-radio-button> 
       
        <mat-radio-button value="invoice">Invoice</mat-radio-button>
          </mat-radio-group></span>

        <div><mat-checkbox formControlName="ischatgpt" >ChatGPT Template</mat-checkbox>
        </div>
        <div *ngIf=" this.editnodeFG.value.ischatgpt">
Default Prompt
<mat-form-field appearance="outline"   class = "textarea">
  <textarea
  matInput value={{this.editnodeFG.value.defaultprompt}}
  formControlName="defaultprompt"></textarea>
  </mat-form-field>

</div>

<mat-checkbox formControlName = "website" >
  Include link to Website</mat-checkbox>

  <div *ngIf="this.editnodeFG.value.website">

      <mat-form-field appearance="outline" floatLabel="always" style="width: 40vw">
        <mat-label >Base WebSite Address </mat-label><input matInput formControlName="weblink" matInput value='https://www.'>
       </mat-form-field>

        </div>
        
          <div>
        <mat-checkbox formControlName="changecolor" >Change Colour</mat-checkbox>
  
          <span class ="colorbox" *ngIf="this.editnodeFG.value.changecolor">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker>
        </span>
      </div>
  
  <div> 
    <button [disabled]= "!this.configSvc.colorchanged && !this.editnodeFG.dirty" (click)="this.updateNode();"
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Update Node ">
          
         Update Node</button></div>
        </form>
    </mat-card> </mat-tab>

<span *ngIf="this.configSvc.nodeds[0].type == 'basicflow'">
      <mat-tab >
        <ng-template mat-tab-label>
          <i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >security</i>
         <span>FlowAssignment</span>
         
        </ng-template>
        <app-flowassignment inittype="edit" ></app-flowassignment>
      </mat-tab></span>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >preview</i>
          Preview
        </ng-template>

        <app-activenode></app-activenode>

      </mat-tab>

      
  </mat-tab-group>
  