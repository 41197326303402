import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {ConfigService} from '../../../services/config.service';
import {TagerService} from '../../../services/tager.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-editflowitem',
  templateUrl: './editflowitem.component.html',
  styleUrls: ['./editflowitem.component.css']
})
export class EditflowitemComponent implements OnInit {
  public choicesetselected = false;
  public sorted = [];
  hlchoiceset = [];
  editflowitemFG: FormGroup;
  public previewFG: FormGroup;
  public choicesetFG: FormGroup;
  public nodeid: String;
  public stepid: String;
  public previewArray: FormArray;
  public stpcnt: string;
  public hltreepath =[];
  public clickchoiceset = false;
  constructor(private fb: FormBuilder, public configSvc: ConfigService, public tagerSvc: TagerService, private authSvc: AuthenticationService) { }

  ngOnInit(): void {

    this.editflowitemFG = this.fb.group({
      itemid: [this.configSvc.flowitemdetailsds[0].itemid, Validators.required],
      type: [this.configSvc.flowitemdetailsds[0].type, Validators.required],
      name: [this.configSvc.flowitemdetailsds[0].name, Validators.required],
      icontype: [this.configSvc.flowitemdetailsds[0].icontype, Validators.required],
      iconcolor: [this.configSvc.flowitemdetailsds[0].iconcolor, Validators.required],
       ab: [this.configSvc.flowitemdetailsds[0].ab, Validators.required],
       ao: [this.configSvc.flowitemdetailsds[0].ao, Validators.required],
       eleord: [this.configSvc.flowitemdetailsds[0].eleord, Validators.required] });


       this.previewFG = new FormGroup({formArrayName: this.fb.array([])})
       console.log('building form preview now');
   
       if(this.configSvc.flowitemdetailsds[0].type == 'node'){
       this.buildstepitems()}
   
       if(this.configSvc.flowitemdetailsds[0].type == 'wizard'){
         this.buildstepperitems()}
   
   
       if(this.configSvc.activetype =='wizard'){
         this.stepid =  this.configSvc.stepdetailsds[0]._id
         this.nodeid =  this.configSvc.flowitempreviewds[0]._id}
   
         if(this.configSvc.activetype !='wizard'){
           this.nodeid =  this.configSvc.flowitempreviewds[0]._id}
   
   
   
   
   
       this.choicesetFG = this.fb.group({
         choicesetn: [''],
         choicesetdesc: [''],
          choicesetsearch:[''],
         
        });
   }


   
   buildstepitems(){

    console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.flowitempreviewds));
          var choicesetids= []
          this.previewArray = this.previewFG.get('formArrayName') as FormArray;
         Object.keys(this.configSvc.flowitempreviewds).forEach((i) => {

         console.log('this is the stepitem property for step'+i);
         console.log('this is the formstep array'+this.configSvc.flowitempreviewds[i].stepitems);
         Object.keys(this.configSvc.flowitempreviewds[i].stepitems).forEach((i2) => {
          console.log('tthis is the item number in the step'+i2);
          this.stpcnt = i2;
          console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.flowitempreviewds[i].stepitems[i2].formitem));
       
     // console.log('this is the stepitem property for step'+this.configSvc.flowitempreviewds.stepitems[i].formitem);
         // console.log('this is the step item value'+this.configSvc.flowitempreviewds.stepitems[i].value);

            this.previewArray.push( this.fb.group({
              formstep: new FormControl({ value: i}),
               property: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].formitem, emitEvent:false}),
               label: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].label, emitEvent:false}),
               formitemlabel: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].formitemlabel, emitEvent:false}),
              itemvalue: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].itemvalue, disabled: false  }),
               formtype: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].formtype}),
             formchoices: new FormControl({ value:this.configSvc.flowitempreviewds[i].stepitems[i2].formchoices}),
            choicesetid: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid}),
            choicesetname: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetname}),
            choicevalue: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].choicevalue, disabled: false }),
            hasdefault: new FormControl(this.configSvc.flowitempreviewds[i].stepitems[i2].hasdefault),
            treepath: new FormControl({ value: this.configSvc.flowitempreviewds[i].stepitems[i2].treepath, emitEvent:false}),
            
              }))

              console.log('preview array is now:'+this.previewArray);
              
              if (this.configSvc.flowitempreviewds[i].stepitems[i2].formchoices 
                && localStorage.getItem(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)===null){ 
                 
                 
                console.log('this property has a list associated with it and its not in cache, lets go and get it')
             // choicesetids.push(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)
              console.log('choicesetids list is now'+choicesetids);
             this.tagerSvc.getChoicesetbyid(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid, 'cache')

              }

              if (this.configSvc.flowitempreviewds[i].stepitems[i2].formchoices 
                && localStorage.getItem(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)!==null){ 
                console.log('check to see if the cache variable has the localstorage cache')
                console.log('this is the choicesetid: '+this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)
             // this.tagerSvc.getFormChoices(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)
             var localdata = JSON.parse(localStorage.getItem(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid))
             console.log('this is the localdata im pushing'+localdata);
             if(!this.authSvc.choicesetids.includes(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)){
              this.authSvc.choicesetids.push(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)
              console.log('we havent added this to the auth localcache yet: '+this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid);
              console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
              this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
            }
            
            
            }

            if (this.configSvc.flowitempreviewds[i].stepitems[i2].formtype == 'treelist'){
              this.hltreepath = this.configSvc.flowitempreviewds[i].stepitems[i2].treepath
                console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
               this.tagerSvc.getChoicesetbyid(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid, 'treelist')
               
            }

            

              if (!this.configSvc.flowitempreviewds[i].stepitems[i2].formchoices){
                console.log('this property does NOT have a list associated with it, doing nothing')
              } })
})}

buildstepperitems(){

console.log('this is the stepper node data we are workin with: '+JSON.stringify(this.configSvc.flowitempreviewds[0]));
   
    this.previewArray = this.previewFG.get('formArrayName') as FormArray;
   Object.keys(this.configSvc.flowitempreviewds[0].stepper).forEach((i) => {

   console.log('this is the stepitem property for step'+i);
   console.log('this is the stepper array'+JSON.stringify(this.configSvc.flowitempreviewds[0].stepper[i]));
   Object.keys(this.configSvc.flowitempreviewds[0].stepper[i].tags).forEach((i2) => {
    console.log('tthis is the item number in the step'+i2);
    this.stpcnt = i2;
    console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formitem));
 
// console.log('this is the stepitem property for step'+this.configSvc.flowitempreviewds.stepitems[i].formitem);
   // console.log('this is the step item value'+this.configSvc.flowitempreviewds.stepitems[i].value);

      this.previewArray.push( this.fb.group({
        formstep: new FormControl({ value: i}),
         property: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formitem, emitEvent:false}),
         label: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].label, emitEvent:false}),
         formitemlabel: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formitemlabel, emitEvent:false}),
        itemvalue: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].itemvalue, disabled: false  }),
         formtype: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype}),
    //   formchoices: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i2].tags[i2].formchoices}),
      choicesetid: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid}),
      choicesetname: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetname}),
      choicevalue: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicevalue, disabled: false }),
      hasdefault: new FormControl(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].hasdefault),
      treepath: new FormControl({ value:this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].treepath, emitEvent:false}),
      
        }))

        console.log('preview array is now:'+this.previewArray);
        
        if (this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype=='radiogroup'
          && localStorage.getItem(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid)===null){ 
           
           
          console.log('this property has a list associated with it and its not in cache, lets go and get it')
       // choicesetids.push(this.configSvc.flowitempreviewds[i].stepitems[i2].choicesetid)
      
       this.tagerSvc.getChoicesetbyid(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid, 'cache')

        }

        if (this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype=='choicelist' ||  this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype=='radiogroup'
        && localStorage.getItem(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid)!==null){ 
          console.log('check to see if the cache variable has the localstorage cache')
          console.log('this is the choicesetid: '+this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid)
       // this.tagerSvc.getFormChoices(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2]..choicesetid)
       var localdata = JSON.parse(localStorage.getItem(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid))
       console.log('this is the localdata im pushing'+localdata);
       if(!this.authSvc.choicesetids.includes(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid)){
        this.authSvc.choicesetids.push(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid)
        console.log('we havent added this to the auth localcache yet: '+this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid);
        console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
        this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
      }
      
      
      }

      if (this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formtype == 'treelist'){
        this.hltreepath = this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].treepath
        this.clickchoiceset = true;
          console.log('this is it he initial hl stepper tree path '+JSON.stringify( this.hltreepath));
         this.tagerSvc.getChoicesetbyid(this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].choicesetid, 'treelist')
         
      }

      

        if (!this.configSvc.flowitempreviewds[0].stepper[i].tags[i2].formchoices){
          console.log('this property does NOT have a list associated with it, doing nothing')
        } })
})}

public getChoices(choicesetid){
  
  var filtered = this.tagerSvc.choicesetsrch.filter(item => item.parentid === choicesetid)
 
 return filtered;
}


public choiceChange(){
   
  console.log('choices is changed');

  console.log('itemvalue is set to: '+this.editflowitemFG.value.itemvalue);

 }



 

 public sortPath(){
   
   
   if(this.choicesetselected){
     this.sorted = [];
     console.log('set sorted to blank')
   this.sorted = this.hlchoiceset[0].path.sort((a, b) => Number(a.level) - Number(b.level));
   // console.log('this is the sorted result: '+JSON.stringify(sorted))
     return this.sorted
   }
 }

 public updateTag(){
   console.log('updating tag now')
 }
 

   

}
