<span>
    <mat-card   [ngClass]= "
    {'edittypelrg': this.configSvc.fullpage == true,
     'edittypesml': this.configSvc.fullpage == false } ">
    <mat-tab-group  [(selectedIndex)]="this.configSvc.tabIndex">

        <mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
            <ng-template mat-tab-label>
                <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"
            
                ></mat-icon>
            
            </ng-template>
            <span class= "comments">
              <app-viewcomments></app-viewcomments></span>
            
    </mat-tab>

    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.assigned == true" label="Assigned">
        <ng-template mat-tab-label>
          <i class="material-icons icon-size" [ngStyle]="{'color': 'purple'}">person_pin_circle</i>
         
          </ng-template>
        <span class= "comments">
          <app-assigned></app-assigned>
        </span>
</mat-tab>

<mat-tab label="Details" >

    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>
  
      </ng-template>
  
        <app-nodedetails></app-nodedetails>
  </mat-tab>

    </mat-tab-group>
</mat-card>
</span>

