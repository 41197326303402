
   <div class = "results-container">

    <table mat-table [dataSource]="dataSource" matSort >

        <tr mat-header-row *matHeaderRowDef="activeColumns; sticky: true" ></tr>

        <tr mat-row *matRowDef="let element; columns: activeColumns; let i = index;"
        
        [routerLink]="['/toolbard/home']"
        [queryParams] = "{
          itemid:element._id, type:element.type, name: element.name, level:element.level, teamid: element.teamid
         }"
        ></tr>

          <!-- Icon Column -->
      <ng-container matColumnDef="icon">
       <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>

       <td mat-cell *matCellDef="let element">

 <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" >{{element.icontype}} </i>
                 
               
             

       </td>

     </ng-container>

  


    
 


        <!-- Addedby Column -->
        <ng-container matColumnDef="ab">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
          <td mat-cell *matCellDef="let element"> {{element.ab}} </td>
        </ng-container>

        

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
          </ng-container>

          <!-- Addedby Column -->
          <ng-container matColumnDef="ao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Added On</th>
            <td mat-cell *matCellDef="let element"> {{element.ao | date: 'medium'}} </td>
          </ng-container>

           <!-- AssignedTo Column -->
       <ng-container matColumnDef="assignedto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Assigned To</th>
        <td mat-cell *matCellDef="let element"> {{element.assignedto}} </td>
      </ng-container>

        

       <!-- Title Column -->
       <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>WorkItem</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

  


    

     


     

    </table>
 
</div>
  
