import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {TagerService} from '../../services/tager.service';

@Component({
  selector: 'app-editlist',
  templateUrl: './editlist.component.html',
  styleUrls: ['./editlist.component.css']
})
export class EditlistComponent implements OnInit {
  editlistFG: FormGroup;
  folderrenamed = false;
  newfoldervalue = '';
  constructor(private fb: FormBuilder, public tagerSvc: TagerService) { }

  ngOnInit(): void {

    console.log('setdetails'+JSON.stringify(this.tagerSvc.setdetailsds))
    this.editlistFG = this.fb.group({
      listname: [this.tagerSvc.setdetailsds[0].name],
   
   
   });

   this.onChanges();
  }

   
 onChanges(): void {
  this.editlistFG.valueChanges.subscribe(val => {
  console.log('the value has changed to '+JSON.stringify(val))
  this.newfoldervalue = this.editlistFG.get('listname').value;
  
  });
}
  

  reset() {
    this.editlistFG.reset();
    
  }

  renameSet(newsetname){
    console.log('rename set here')
  }

}

