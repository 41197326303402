

<span  class ="container">
    <span *ngIf="this.configSvc.activetype == 'rootqueue'" >
      <mat-card class = "browsercardlrg">  
      <span >
      
          <table mat-table [dataSource]="this.configSvc.teamqueueds">
        
               <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
        
                 <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
               
                 ></tr>
                 
      
               
        
                   <!-- IconType Column -->
                   <ng-container matColumnDef="MimeColumn">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                          <div>
                             <i class="material-icons md-48" [ngStyle]="{'color': element.flowcolor || 'black'}" >{{element.flowicon}} 
                               
                             </i></div>
      
                      
                      </td>
                  </ng-container>
        
                  <!--ActiveFlow Column -->
                <ng-container matColumnDef="ItemColumn">
                  <th mat-header-cell *matHeaderCellDef></th>
        
                  <td mat-cell *matCellDef="let element; let rowcount = index;">
      
                  <a *ngIf="element.type == 'team'" fxFlexFill
                 
                  [routerLink]="['/toolbard/teamqueue']"
                  [queryParams] = "{type:'queue', name: element.name, teamid: element._id}"
                 
                  >
                  <div *ngIf="element.type == 'team' && element.inbox == true" 
                  (click)="this.configSvc.currentteam=element; this.clickQueue(element)"> 
                 
                  <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}"  matBadge="{{element.inboxcnt}}" 
                  matBadgePosition="above" matBadgeColor="warn">{{element.icontype}} </i>
                  Inbox</div>

                  <div *ngIf="element.type == 'team' && element.inbox == false && element.inboxcnt != 0 " 
                  (click)="this.configSvc.currentteam=element; this.clickQueue(element)"> 
                  <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}"matBadge="{{element.inboxcnt}}" 
                  matBadgePosition="above" matBadgeColor="warn">{{element.icontype}} </i>
                  {{element.name}} </div>

                  <div *ngIf="element.type == 'team' && element.inbox == false && element.inboxcnt == 0 " 
                  (click)="this.configSvc.currentteam=element; this.clickQueue(element)"> 
                  <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" 
                  >{{element.icontype}} </i>
                  {{element.name}} </div>
                  
                 
                  </a>
      
      
        
      
      
                    </td>
                </ng-container>
      
                
        
        
            </table></span>
            <span (click)="this.deselectQueue()" class = "deselect"></span>
    
           
          </mat-card>

          
    </span>

   

    <span *ngIf=" this.configSvc.queueready && this.configSvc.activetype != 'queuedirect'" >
      <mat-card class = "browsercardlrg">  
      <span >
      
          <table mat-table [dataSource]="this.configSvc.queueitemds">
        
               <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
        
                 <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
               
                 ></tr>
                 
      
               
        
                   <!-- IconType Column -->
                   <ng-container matColumnDef="MimeColumn">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                          <div> <i class="material-icons md-48" [ngStyle]="{'color': element.flowcolor || 'black'}" >{{element.flowicon}} </i></div>
      
                      
                      </td>
                  </ng-container>
        
                  <!--ActiveFlow Column -->
                <ng-container matColumnDef="ItemColumn">
                  <th mat-header-cell *matHeaderCellDef></th>
        
                  <td mat-cell *matCellDef="let element; let rowcount = index;">
      
                 
      
      
        <div *ngIf="element.type == 'basicflow'" class = "hover" 
        (click)="this.configSvc.currentrow=element;this.clickActiveflow(element)"
        [style.background]=" this.configSvc.nodeds.indexOf(element) != -1 ? 'lightblue' : ''"
        >
  
        
         {{element.name}} </div>
      
      
                    </td>
                </ng-container>
      
                
        
        
            </table></span>
            <span (click)="this.deselectQueue()" class = "deselect"></span>
    
           
          </mat-card>


    </span>

    <span *ngIf="this.flowSvc.queuedetailsready &&  this.configSvc.activetype != 'rootqueue'" class = "queueitem">
      <mat-card >
<app-queuedetails></app-queuedetails>

      </mat-card>  </span> 
    
   <div class = "queueitem">
    <mat-tab-group>
    <span *ngIf="this.configSvc.activeflow == 'stepperassign' && this.configSvc.activetype == 'queue'">
      <mat-tab label="Activity">
        <ng-template mat-tab-label>
          <i class="material-icons icon-tab" 
          [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
          {{this.configSvc.nodeds[0].name}}
          </ng-template>
      <span *ngFor="let r of reset" >
        <app-activestepper></app-activestepper>
      </span>
    </mat-tab></span>

    <span *ngIf="this.configSvc.activeflow == 'tabbedassign' && this.configSvc.activetype == 'queue'">
      <mat-tab label="Activity">
        <ng-template mat-tab-label>
          <i class="material-icons icon-tab" 
          [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
          {{this.configSvc.nodeds[0].name}}
          </ng-template>
      <span *ngFor="let r of reset" >
        <app-activetabs></app-activetabs>
      </span>
    </mat-tab></span>
    
    

  <span *ngIf="this.configSvc.queueready && this.configSvc.activetype == 'queuedirect'">
    <mat-tab label="Activity">
      <ng-template mat-tab-label>
        <i class="material-icons icon-tab" 
        [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
        {{this.configSvc.nodeds[0].name}}
        </ng-template>
    <span *ngFor="let r of reset" >
      <app-activenode></app-activenode>
    </span>


  </mat-tab></span>

  <span *ngIf="this.configSvc.queueready && this.configSvc.activetype == 'basicflow'">
    <mat-tab label="Activity">
      <ng-template mat-tab-label>
        <i class="material-icons icon-tab" 
        [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
        {{this.configSvc.nodeds[0].name}}
        </ng-template>
    <span *ngFor="let r of reset" class = "container">
      <app-activenode></app-activenode>
    </span>


  </mat-tab></span>

  <span *ngIf="this.configSvc.queueready && (this.configSvc.activetype == 'queuedirect' || this.configSvc.activetype == 'basicflow')">
  <mat-tab label="Team Queue Tracker ">
    <ng-template mat-tab-label>
      <i class="material-icons icon-tab" 
      [ngStyle]="{'color': 'black'}">{{update}}</i> 
      Team Queue Tracker
      </ng-template>

      <mat-card class = "tracker">
      <span *ngFor="let phase of this.configSvc.nodeds[0].flow; let index = index" class = "phases">
        <i class="material-icons icon-tab" 
        [ngStyle]="{'color': phase.clr}">{{phase.icontype}}</i> 
        {{phase.name}}
        <span *ngIf="phase.status == 0"> <i class="material-icons icon-tab"[ngStyle]="{'color': 'red'}">highlight_off</i> </span>
    <span *ngIf="phase.status == 1"> <i class="material-icons icon-tab"[ngStyle]="{'color': 'blue'}">history</i> </span>
    <span *ngIf="phase.status == 2"> <i class="material-icons icon-tab"[ngStyle]="{'color': 'green'}">check_circle</i> </span>
<div class = "arrows">
  <i class="material-icons" 
  [ngStyle]="{'color': 'black'}">south</i> 
</div>
      </span></mat-card>
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Textraction">
    <ng-template mat-tab-label>
      <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'form'">
        <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">fact_check</i></span>

        <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'invoice'">
          <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">receipt_long</i></span>
     
      </ng-template>
    <span class= "comments">
 <app-textraction></app-textraction></span>
  
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Lineitems">
    <ng-template mat-tab-label>
      <span >
        <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">format_list_numbered</i></span>

        
     
      </ng-template>
    <span class= "comments">
 <app-lineitems></app-lineitems></span>
  
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.queueready && this.configSvc.activetype == 'queuedirect' && this.configSvc.nodeds[0].attach == true" label="Attachments">
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >attach_file</i>
      
      </ng-template>
    <span class= "comments">
        <app-nodefiles></app-nodefiles></span>
  
  </mat-tab>

  
  <mat-tab  *ngIf="this.configSvc.queueready && this.configSvc.nodeds[0]?.deadline == true" label="Deadline">
    <ng-template mat-tab-label>
     <span> <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >timer</i></span>
     
    
      </ng-template>
    <span class= "comments">
      <app-deadline></app-deadline>
    </span>
    </mat-tab>

    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
      <ng-template mat-tab-label>
        <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}" >
          {{this.configSvc.nodeds[0]?.cmticon}}</i>
       
        </ng-template>
      <span class= "comments">
          <app-viewcomments></app-viewcomments></span>
    
    </mat-tab>
</span>






 
</mat-tab-group>
   </div>
        
       
                                </span>
                                          
      