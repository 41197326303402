<span *ngIf="this.configSvc.toolmenu != 'general'" >
  <i class= 
  "material-icons icon-navclosed"  title="General Tools"
  (click)="this.configSvc.toolmenu = 'general'">construction</i>
</span>

<span *ngIf="this.configSvc.toolmenu == 'general'" >
  <i class= 
  "material-icons icon-navopen"  title="General Tools">construction</i>
</span>

<span  *ngFor="let toolbar of configSvc.finditemds" >

    <span>
      <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="Create {{toolbar.name}} "
      (click)="this.openToolbar(toolbar)"
    >{{toolbar.icontype}}</i></span>
  
  </span>
  