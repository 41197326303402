import { Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import {ConfigService} from '../../services/config.service';
import {FlowService} from '../../services/flow.service';
import {TagerService} from '../../services/tager.service';
import { TagService } from '../../services/tag.service';
import {UploadService} from '../../services/upload.service';


import {MatDatepickerInputEvent} from '@angular/material/datepicker';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router} from '@angular/router';

export interface DialogData {
 
  name: string;
  type: string;
}


@Component({
  selector: 'app-activenode',
  templateUrl: './activenode.component.html',
  styleUrls: ['./activenode.component.css']
})
export class activenodeComponent implements OnInit {
 
  @ViewChild('fileUpload')
  fileUpload: ElementRef
 public newindex: number;
  inputFileName: string

  // @Input()
  files: File[] = []
  public imageloaded
  public actualimage
  public imageready = false
  public url;
  public phase: number;
  public status: string;
  public next: String;
  public prev: String;
public showTextBox: boolean;

  public tagsArray: FormArray;

  public tagsFG: FormGroup;

  public boolchoice =[true, false]
  public newpropvalues = [];
  stpcnt: string;
  hltreepath =[];
  sorted=[];
  clickchoiceset = false;
  chipselected = false;
  hlchoiceset = [];
  treeColumns = ['Name'];
  textarearowmin = 1;
  texarearowmax = 5;
  public weblinkFC = new FormControl();
  public websiteFC = new FormControl();



  constructor(private fb: FormBuilder, public configSvc: ConfigService, public tagerSvc: TagerService, 
    public flowSvc: FlowService,private uploadSvc: UploadService,private router: Router,
    public tagSvc: TagService, private authSvc: AuthenticationService, public dialog: MatDialog) { }
    

    ngOnInit() {
     // this.configSvc.toolmenu='nodetools'
      this.tagsFG = new FormGroup({

        formArrayName: this.fb.array([])
      })
 

      if(this.configSvc.nodeds[0].website){
console.log('this has a website with the link a '+this.configSvc.nodeds[0].weblink)
        this.websiteFC = this.configSvc.nodeds[0].website
        this.weblinkFC = this.configSvc.nodeds[0].weblink
        console.log('this has a website with the link b '+this.weblinkFC)
      }
       

      console.log('folderitem count'+this.configSvc.folderitem);
     console.log('this is the quickadd setting: '+this.configSvc.quickadd)
  
     // this.buildTemplate();
     console.log('building activenode now and ready status is set to'+this.configSvc.nodeready);
     console.log('active type is'+this.configSvc.activetype);
     
     this.buildstepitems();
    this.detectNodechanges();
    }

    ngOnDestroy() {
      console.log('destroying component now');
       this.configSvc.nodeclicked =false;
      this.tagsArray = null;
      this.configSvc.hidebrowser = false;
    }
    

    
    
    buildstepitems(){
this.configSvc.nodeclicked = true
this.configSvc.nodechanged = false;
      console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.nodeds));
      this.configSvc.newpropvalues = this.configSvc.nodeds[0].stepitems
      console.log('this is the activflow '+this.configSvc.activeflow)
      console.log('this is the activtype '+this.configSvc.activetype)
      console.log('this is the activeitemid '+this.configSvc.activeitemid)

      
/*
      if(this.configSvc.nodeds[0].type == 'nodeimage'){
        console.log('setting up image now')

        let mySrc;
        const reader = new FileReader();
        reader.readAsDataURL(this.uploadSvc.imageurl); 
        reader.onloadend = function() {
           // result includes identifier 'data:image/png;base64,' plus the base64 data
           mySrc = reader.result; 
           console.log('my sourcve is '+mySrc)    
        }
     
      }
      */
      

      if(this.configSvc.nodeds[0].type == 'basicflow' && this.configSvc.nodeds[0].status != '0' )
      {
      this.phase = this.configSvc.nodeds[0].assignedat
        console.log('this is  at phase : '+this.phase);
        this.next = this.configSvc.nodeds[0].flow[this.phase].next
        this.prev = this.configSvc.nodeds[0].flow[this.phase].prev
        console.log('this is next button : '+this.next);}

            var choicesetids= []
            this.tagsArray = this.tagsFG.get('formArrayName') as FormArray;
           Object.keys(this.configSvc.nodeds).forEach((i) => {

           console.log('this is the stepitem property for step'+i);
           console.log('this is the formstep array'+this.configSvc.nodeds[i].stepitems);
           Object.keys(this.configSvc.nodeds[i].stepitems).forEach((i2) => {
            console.log('tthis is the item number in the step'+i2);
            this.stpcnt = i2;
            console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[i].stepitems[i2].formitem));
         
       // console.log('this is the stepitem property for step'+this.configSvc.nodeds.stepitems[i].formitem);
           // console.log('this is the step item value'+this.configSvc.nodeds.stepitems[i].value);
  
              this.tagsArray.push( this.fb.group({
                formstep: new FormControl({ value: i}),
                 formitem: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitem, emitEvent:false}),
                 label: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].label, emitEvent:false}),
                 formitemlabel: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formitemlabel, emitEvent:false}),
                itemvalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].itemvalue, disabled: false  }),
                 formtype: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].formtype}),
               formchoices: new FormControl({ value:this.configSvc.nodeds[i].stepitems[i2].formchoices}),
              choicesetid: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicesetid}),
              choicesetname: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicesetname}),
              choicevalue: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].choicevalue, disabled: false }),
              hasdefault: new FormControl(this.configSvc.nodeds[i].stepitems[i2].hasdefault),
              checkbox: new FormControl(this.configSvc.nodeds[i].stepitems[i2].checkbox),
              textarea: new FormControl({value: this.configSvc.nodeds[i].stepitems[i2].textarea, disabled: false }),
              rowsize: new FormControl(this.configSvc.nodeds[i].stepitems[i2].rowsize),
              treepath: new FormControl({ value: this.configSvc.nodeds[i].stepitems[i2].treepath, emitEvent:false}),
              
                }))
                console.log('label is '+this.configSvc.nodeds[i].stepitems[i2].label);
                console.log('tags array is now:'+this.tagsArray);
               if( this.configSvc.nodeds[i].stepitems[i2].formtype=='choicelist' || this.configSvc.nodeds[i].stepitems[i2].formtype=='radiogroup'){

              
                if ( localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)===null){ 
                 
                    var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
               console.log('parta choicelist this is the localstoarage list that im getting '+JSON.stringify(localdata));
                  console.log('this property has a list associated with it and its not in cache, lets go and get it')
               // choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
             
               this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'cache')

                }




    if (localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid)!==null){ 
                  console.log('partb choicelist check to see if the cache variable has the localstorage cache')
                  console.log('this is the choicesetid: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid)
               // this.tagerSvc.getFormChoices(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
               var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[i].stepitems[i2].choicesetid))
               console.log('this is the localstoarage '+localdata);
                      if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[i].stepitems[i2].choicesetid)){
                this.authSvc.choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
                console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[i].stepitems[i2].choicesetid);
                console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)}
      }
    } 


    if( this.configSvc.nodeds[i].stepitems[i2].formtype=='text' ){
      this.textarearowmin = 10
this.texarearowmax = 10
    }
      

    



   if (this.configSvc.nodeds[i].stepitems[i2].formtype == 'treelist'){
                this.hltreepath = this.configSvc.nodeds[i].stepitems[i2].treepath
                  console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
                 this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[i].stepitems[i2].choicesetid, 'treelist')
                 
      }

   })
})}
   
    

  completeTags(type){
    console.log('type is: '+type)



  console.log ('get the folder details now')

  }

  public getChoices(choicesetid){
  //  console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
    
    var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
   // console.log('this is the filtered choicelist: '+filtered)
    return filtered;
     }

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log('event firing')
    this.events.push(`${type}: ${event.value}`);
    console.log('events'+this.events)
  }
  
  detectNodechanges(){
    
    console.log('checking for changes to the node');
    this.tagsFG.get('formArrayName').valueChanges
    
    .subscribe(
      data => {
        this.configSvc.newpropvalues = [];
      console.log('new propvalues: ' + JSON.stringify(data));
      this.tagsFG.markAsDirty(); this.configSvc.nodechanged = true;
      for (let properties of data) {
        console.log('full property is simply '+JSON.stringify(properties));
        console.log('property name is '+JSON.stringify(properties.formitem.value));
        console.log('property value is '+JSON.stringify(properties.itemvalue));
        console.log('label is '+JSON.stringify(properties.label.value));
        console.log('pformitemlabel is '+JSON.stringify(properties.formitemlabel.value));
        console.log('checkbox is '+JSON.stringify(properties.checkbox));
        console.log('textarea is '+JSON.stringify(properties.textarea));
        if(
         // properties.formtype.value != 'text' && 
          properties.formtype.value != 'boolean'){properties.textarea = ''}
  
        if(properties.formtype.value=='choicelist'){
          console.log('there ARE choicesets associated with this choicelist')
        this.configSvc.newpropvalues.push({
          formitem:properties.formitem.value, 
          itemvalue:properties.itemvalue, 
          formchoices:properties.formchoices.value, 
          checkbox:properties.checkbox,
          formtype: properties.formtype.value,   
          formitemlabel: properties.formitemlabel.value, 
          textarea: properties.textarea, 
          label: properties.label.value, 
          choicesetid:properties.choicesetid.value, 
          hasdefault: properties.hasdefault,
          rowsize: properties.rowsize})}

          if(properties.formtype.value=='radiogroup'){
            console.log('there ARE choicesets associated with this radiogroup')
          this.configSvc.newpropvalues.push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
            formchoices:properties.formchoices.value, 
            checkbox:properties.checkbox,
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
            textarea: properties.textarea, 
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault,
            rowsize: properties.rowsize})}
          if(properties.formtype.value=='treelist'){
            console.log('pushing new values based on treelist')
            console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
          this.configSvc.newpropvalues.push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
           
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
           
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault,
            rowsize: properties.rowsize,
            treepath: this.hltreepath
          })}
        if(properties.formtype.value=='string'|| properties.formtype.value=='label' || properties.formtype.value=='number' || properties.formtype.value=='image' ||
        properties.formtype.value=='checkbox' || properties.formtype.value=='date' || properties.formtype.value=='datetime' ||
        properties.formtype.value=='text'|| properties.formtype.value=='boolean' 
        ){ 
          console.log('there are no choiceset associated with this property')
          this.configSvc.newpropvalues.push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
            formchoices:properties.formchoices.value, 
            checkbox:properties.checkbox,
            formtype: properties.formtype.value,
             formitemlabel: properties.formitemlabel.value,  
            textarea: properties.textarea, 
             label: properties.label.value, 
             choicesetid: 'nocs',
           
            hasdefault: properties.hasdefault,
            rowsize: properties.rowsize
          })
            
          
            console.log('this is the updated tags:'+JSON.stringify(this.configSvc.newpropvalues))
          }
     
          console.log('here is the website value '+this.weblinkFC)
      //  this.configSvc.newpropvalues.push({property:properties.property.value, propval:properties.propvalue, formtype:properties.formtype.value})
      }
      
      }
    ); 
  }

  updateTags(){
    console.log('updating the followingtags')

   // if(this.configSvc.activetype == 'tageritem'){
   //   this.configSvc.updateTags(this.configSvc.newpropvalues, this.configSvc.activetype, this.configSvc.nodeds[0]._id)
  //  }
 //   else
  //  {


      this.configSvc.updateNodetags(this.configSvc.newpropvalues, this.configSvc.activetype,  this.configSvc.nodeds[0]._id, this.weblinkFC.value)
      //  }    
    this.tagsFG.markAsPristine();
  }

  webChange(updatedweblink){
    console.log('changing weblink')
    this.weblinkFC = updatedweblink
    this.configSvc.weblink = updatedweblink
    console.log('config weblink is '+this.configSvc.weblink )
  }

  



  changeState(){
    console.log('changing state')
  // this.tagsArray.markAsDirty();
    this.configSvc.nodeds[0].state = !this.configSvc.nodeds[0].state
    
  }



  updateNodeimage(){
    console.log('updating nodeimage')
    this.tagsFG.markAsTouched();
    
    console.log('marking nodeimage as dirty here is newprops '+JSON.stringify(this.configSvc.newpropvalues))
    
   // if(this.configSvc.activetype == 'tageritem'){
   //   this.configSvc.updateTags(this.configSvc.newpropvalues, this.configSvc.activetype, this.configSvc.nodeds[0]._id)
  //  }
 //   else
  //  {

  
      this.configSvc.updateNodeimage(this.configSvc.newpropvalues, this.configSvc.activetype, this.configSvc.nodeds[0]._id,  this.actualimage, 
        this.configSvc.nodeds[0].ver, this.configSvc.currentfolder, this.configSvc.nodeds[0].vk )
      //  }    
    this.tagsFG.markAsPristine();
  }

  toggleChange(){
     
    
    //  this.edittagFG.value.hasdefault = !this.edittagFG.value.hasdefault
     this.tagsFG.value.hasdefault = !this.tagsFG.value.hasdefault 
     console.log('changed current toggle value TO '+this.tagsFG.value.hasdefault  )
     this.tagsFG.markAsDirty();
   }  

   updateSlider(trueorfalse, count){
    console.log('changed current toggle value TO '+trueorfalse  )
    console.log('count '+count  )
    this.tagsFG.markAsDirty();
   }

   public sortPath(){
    
    
    // console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
   this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
   //console.log('this is the sorted result: '+JSON.stringify(this.sorted))
     return this.sorted
   
   }

 
   
   public getChoicetree(choicesetid){
   
     this.getChip(choicesetid)
      this.sorted = [];
   
   
    
     this.clickchoiceset = true; 
      console.log('getting the choice tree for '+choicesetid)
      console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
    
      this.sortPath()
     if(this.authSvc.choicesetids.includes(choicesetid)){
       console.log('this choicetree has a list associated with it and its in cache')
   
     }
   
   
    if(!this.authSvc.choicesetids.includes(choicesetid)){
      console.log('ive never got this list before lets get it')
     this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
   
   }
   
   
   }
   
   public getChip(choicesetid){
   
    
     this.sorted = [];
     console.log('getting choicesetbyid with getChip type is path')
     this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
   }
   
   
   public hlchoice(selectedrow, index){
     console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
    console.log('this is itthe itemvalue: '+selectedrow.name);
    console.log('this is itthe formcontrol index: '+index);
   
    
   // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
   this.tagsArray.controls[index].patchValue({'itemvalue': selectedrow.name})
    console.log('this is formtype '+this.tagsArray.controls[index].value.formtype.value);
    console.log('this is fitemvalue '+this.tagsArray.controls[index].value.itemvalue);
    // this.previewArray.at(index).controls['itemvalue'].patchValue('test');
    console.log('this is itthe current preview array: '+this.tagsArray);
    
     this.hlchoiceset = [];
     this.hlchoiceset.push(selectedrow)
     this.hltreepath= [];
     this.hltreepath = this.hlchoiceset[0].path
     console.log('this is itthe new selected tree path '+JSON.stringify( this.hltreepath));
     this.tagsArray.controls[index].patchValue({'treepath': this.hltreepath})
    }
    
    public changeRadio(selectedchoice, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      console.log('this is itthe itemvalue: '+selectedchoice.name);
      console.log('this is itthe formcontrol index: '+index);
     
      
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.tagsArray.controls[index].patchValue({'itemvalue': selectedchoice.name})
    }

    public changeBoolean(hasdefault, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      var bool = false;
      console.log('this is itthe formcontrol index: '+index);
      console.log('this is itthe current hasdefault value: '+hasdefault);
      console.log('this is new hasdefault value: '+!hasdefault)
      

    
     
      this.tagsArray.markAsDirty();
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.tagsArray.controls[index].patchValue({'hasdefault': !hasdefault})
    }

    public changeBoolean2(checkbox, index){
    
     
      this.tagsArray.markAsDirty();
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     console.log('this is new itemvalue value: '+this.tagsArray.controls[index].value.itemvalue)
     console.log('this is new textarea value: '+this.tagsArray.controls[index].value.textarea)
     this.tagsArray.controls[index].patchValue({'checkbox': !checkbox})
  //   this.tagsArray.controls[index].patchValue({'textarea': textarea})
     console.log('this is itthe formcontrol index: '+index);
      console.log('this is itthe current checkbox value: '+checkbox);
      console.log('this is new checkbox value: '+!checkbox)
      console.log('this is new true value: '+this.tagsArray.controls[index].value.textarea)
    }

    public nextPhase(){
      console.log('current phase is'+this.phase)
      var totalphases = this.configSvc.nodeds[0].flow.length-1
      console.log('this is the total number of steps in the flow '+this.configSvc.nodeds[0].flow.length)

      if(this.phase == totalphases){
        var nextphase = this.phase
        console.log('this is the last item in the process')
        this.configSvc.nodeds[0].flow[this.phase].status=2
        this.status = '2'
      }

      if(this.phase != totalphases){
        var nextphase = this.phase+1
        console.log('this is not the last item in the process')
        this.configSvc.nodeds[0].flow[nextphase].status=2
        this.status = '1'
      }
      var nextphasestring = nextphase.toString()

      console.log('this is the activeflowid'+this.configSvc.nodeds[0].activeflowid);
      this.flowSvc.nextPhase(this.configSvc.nodeds[0].activeflowid, nextphasestring,this.status, 
        this.configSvc.activeteamid, this.configSvc.nodeds[0].flow[this.phase].name)

    }

    public prevPhase(){
      console.log('current phase is'+this.phase)
      var totalphases = this.configSvc.nodeds[0].flow.length-1
      console.log('this is the total number of assigned '+totalphases)

      var prevphase = 0
if(this.phase != 0){ prevphase = this.phase-1}
        console.log('this is the previous phase '+prevphase)
        this.configSvc.nodeds[0].flow[prevphase].status=3 // 3 means its rejected
        this.status = '3'
      
      var prevphasestring = prevphase.toString()
      var phasestring = this.phase.toString()

      console.log('this is the activeflowid'+this.configSvc.nodeds[0].activeflowid);
      this.flowSvc.prevPhase(this.configSvc.nodeds[0].activeflowid, phasestring, prevphasestring,this.status, 
        this.configSvc.nodeds[0].flow[this.phase]._id, this.configSvc.nodeds[0].flow[this.phase].name,this.configSvc.nodeds[0].assignedat )

    }

    onClick(event) {
      if (this.fileUpload)
        this.fileUpload.nativeElement.click()
        this.tagsFG.markAsDirty();
    }

    onInput(event) {

    }

    public updateImage(event) {
    //  this.fileUpload.nativeElement.click()
      if (event.target.files && event.target.files[0]) {
        this.imageloaded = new FileReader();
    this.actualimage = event.target.files[0]
        this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url
    
        this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
          this.uploadSvc.imageblob = event.target.result;
          this.imageready = true
          if(!this.tagsFG.touched){
          this.configSvc.newpropvalues = this.configSvc.nodeds[0].stepitems}
        }
      }
    
    // this.dialogRef.close();
      }
    imageOver(){
      console.log(' image over')
    }
    
    imageLeave(){
      console.log(' image leave')
    }

    public reTriggerflow(){

      console.log('saveFormassign  for '+this.configSvc.nodeds[0]._id);
      console.log('lastest stepitem properties '+JSON.stringify(this.configSvc.newpropvalues));
      
    
      var assignment = '';
    
        assignment = JSON.stringify(this.configSvc.nodeds[0].assign)
      console.log('this is a pre assigned form using'+assignment)
      console.log('current nodeid element is '+JSON.stringify(this.configSvc.currentrow))
    
    
    
      this.configSvc.reTriggerflow(this.configSvc.activeitemid, JSON.stringify(this.configSvc.newpropvalues), assignment, this.configSvc.currentrow.nodeid)
    
     
     
    
     }

     public updateBatchitem(){

      console.log('updating batchitem '+this.configSvc.nodeds[0].linkid);

this.configSvc.updateBatchitem(this.configSvc.nodeds[0].linkid, 'node',  JSON.stringify(this.configSvc.newpropvalues))}
     
    
}


