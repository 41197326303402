<button mat-icon-button  [matMenuTriggerFor]="activetoolmenu">
    <mat-icon title="Tools">
        <i class="fas fa-caret-square-down" data-fa-transform="grow-2" ></i></mat-icon>
</button>


        <mat-menu #activetoolmenu="matMenu" yPosition="below" class= "box">
               
           
                       <button  mat-menu-item (click)="this.gotoPrefs();" class ="templicons">
                        <i class="material-icons icon-prefs"  title="Preferences"
                       >manage_accounts</i> {{ auth.getUserDetails()?.username}}</button>
              
                        <button mat-menu-item (click)="this.browseMode();" class ="templicons">
                        <i class="material-icons icon-browse"  title="Browse">account_tree</i> 
                            Browse</button>

                            <button mat-menu-item (click)="this.myfeedMode();" class ="templicons">
                                <i class="material-icons icon-myfeed"  title="Assigned">upcoming</i> 
                                    Assigned</button>

                  
                        <button mat-menu-item (click)="this.searchMode();" class ="templicons">
                            <i class="material-icons icon-search"  title="Search">search</i> 
                            Search</button>
                        <button mat-menu-item (click)="this.designMode();" class ="templicons">
                            <i class="material-icons icon-design"  title="Design">brush</i>
                            Design</button>
                        <button mat-menu-item (click)="this.inboxMode();" class ="templicons">
                            <i class="material-icons icon-inbox"  title="QueueMode">all_inbox</i>
                            Queue</button>
                        <button mat-menu-item (click)="this.listMode();" class ="templicons">
                            <i class="material-icons icon-lists"  title="List Management">list</i>
                            Lists</button>

                            <button mat-menu-item (click)="this.promptMode();" class ="templicons">
                                <i class="material-icons icon-prompts"   title="Prompts" 
            
                                >chat</i>
                                Prompts</button>
    

                        
                      
               
                        <button mat-menu-item [matMenuTriggerFor]="about">About</button>
                        <button mat-menu-item (click)="auth.logout();  this.sidenavSvc.close();">Logout</button>
                        <button mat-menu-item (click)="this.gotoVersion();">Version</button>
                        
             
                       
                   
                    </mat-menu>

                    <mat-menu #categories="matMenu">
              
                  
            </mat-menu>

            <mat-menu #about="matMenu">
                    <button  mat-menu-item (click)="this.gotoCompany();">Company</button>
                    <button  mat-menu-item (click)="this.gotoPeople();">People</button>
                </mat-menu>

             

                
           
