import { Component, OnInit} from '@angular/core';
import { AuthenticationService, TokenPayload } from '../../services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // new
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';


@Component({
  selector: 'app-registeruser',
  templateUrl: './registeruser.component.html',
  styleUrls: ['./registeruser.component.css']
})
export class RegisteruserComponent implements OnInit {
    error = ''; // new
    registeruserFG: FormGroup;  // new
    step1 = "step1";
    step2= "step2";
    step3= "step3";
   

    public selectedIndex: number
    public stepIndex = 0;
    public icontype: string;
    public usersadded = [];
    public mgradded = [];
    private teamid: string;
    private companyid: string;
    private companyname: string;
    private email: string;
    private type: string;
 private username = '';
    private formSubmitAttempt: boolean;  // new

  credentials: TokenPayload = {
    username: '',
    password: ''

  };

  constructor(
          private fb: FormBuilder, // new
          private auth: AuthenticationService,
          private router: Router, private activatedRoute: ActivatedRoute,
          public configSvc: ConfigService
          ) {}

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.companyid = params['companyid'];
      this.companyname = params['companyname'];
      this.email= params['email'];
      this.configSvc.activetype = params['type'];
      this.teamid = params['teamid'];
      console.log('activated teamid'+this.teamid);
      console.log('activated type'+this.configSvc.activetype);
      console.log('activated companyid'+this.companyid);

    });

      this.registeruserFG = this.fb.group({
        username: [this.username, Validators.required],
        password: ['', Validators.required],
        teamname: ['Home', Validators.required],
        teamdesc: ['My data', Validators.required],
        icontype: [this.configSvc.chosenIcon, Validators.required],
        iconcolor: [this.configSvc.color, Validators.required],
        type:  [this.configSvc.activetype, Validators.required],
        /*
        email: ['', Validators.required],
        companyid: [this.companyid, Validators.required],
        type: [ this.configSvc.activetype, Validators.required],
        teamid: [this.teamid, Validators.required],
        
       
       */
 
      });

      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'register'
      this.configSvc.chosenIcon = 'face'
      console.log(' STEP1 register component ng on init phase');

    }
  registerUser() 
  {
    console.log('value collected from submit' + this.registeruserFG);
    const regUserform = new FormData();
    regUserform.append('username', this.registeruserFG.value.username);
    regUserform.append('password', this.registeruserFG.value.password);
    regUserform.append('teamname', this.registeruserFG.value.teamname);
    regUserform.append('teamdesc', this.registeruserFG.value.teamdesc);
    regUserform.append('icontype', this.configSvc.chosenIcon);
    regUserform.append('iconcolor', this.configSvc.color);
    regUserform.append('type', this.configSvc.activetype);

    if(this.configSvc.activetype == 'teaminvite'){
      regUserform.append('companyid', this.companyid);
      regUserform.append('teamid', this.teamid);
     // regUserform.append('companyname', this.companyname);
      regUserform.append('email', this.email);
    }
  
   // this.authService.getCustomColumns(projectData, this.user).subscribe(projectresults => {
  
    this.auth.registerUser(regUserform).subscribe(result => {
      console.log('confirm by email '+result);
      console.log(' rego result '+result.registration);
      console.log(' email result '+result.email);
      this.router.navigateByUrl('/toolbard/home');
    }, (err) => {
      this.error = err.error.message;
      console.error('A registration error occurred:', err.error.message);
    });
    this.formSubmitAttempt = true; // new
   // console.log('formSubmitAttempt value:' + formSubmitAttempt);

 }

  isFieldInvalid(field: string) { // {6}

      return (
        (!this.registeruserFG.get(field).valid && this.registeruserFG.get(field).touched) ||
        (this.registeruserFG.get(field).untouched && this.formSubmitAttempt)

      );

    }

    public confirmation(){
      console.log('confirming company registration')
    }

    public iconNext(){
      this.configSvc.colorinputtype = 'register'
      console.log('this is a register color input type')
     // this.username = this.registeruserFG.value.username
    //  console.log('username after clicking next'+this.username);
    }

    public finishReg(){
      console.log('finished reg')
    }
/*
    public registerUser(){
      this.usersadded.push(this.registeruserFG.value.email)
      console.log('users added are: '+JSON.stringify(this.usersadded))
      this.configSvc.registerUser(this.companyid, this.teamid, this.type, 'team', 'base', this.registeruserFG.value.teamname,this.registeruserFG.value.teamdesc, 0, this.mgradded, this.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, true)
    }
    */

    public createTeam(){
      this.mgradded.push(this.registeruserFG.value.name)
      console.log('users added are: '+JSON.stringify(this.mgradded))
      this.configSvc.createTeam(true, 'team', 'base', this.registeruserFG.value.teamname,this.registeruserFG.value.teamdesc, 0, this.mgradded, this.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, true, this.companyid, this.teamid)
    }



}
