import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import { ConfigService } from '.././../services/config.service';
@Component({
  selector: 'app-edittrack',
  templateUrl: './edittrack.component.html',
  styleUrls: ['./edittrack.component.css']
})
export class EdittrackComponent implements OnInit {

  edittrackFG: FormGroup;
  constructor(private _formBuilder: FormBuilder,  public configSvc: ConfigService) { }

  ngOnInit() {
    // this.configSvc.nodeteamds = [];
    this.configSvc.colorinputtype = 'kanban';

    console.log('initializing the editkanban with: '+JSON.stringify(this.configSvc.trackdetailsds[0]))
    this.configSvc.toolmenu = 'steptools'
    this.edittrackFG = this._formBuilder.group({
      name: [this.configSvc.trackdetailsds[0].name, Validators.required],
      addedby: [this.configSvc.trackdetailsds[0].ab, Validators.required],
      addedon: [this.configSvc.trackdetailsds[0].ao, Validators.required],
      desc: [this.configSvc.trackdetailsds[0].desc, Validators.required],
      icontype: [this.configSvc.trackdetailsds[0].icontype, Validators.required],
      iconcolor: [this.configSvc.trackdetailsds[0].iconcolor, Validators.required],
       type: [this.configSvc.trackdetailsds[0].type, Validators.required],
     
     
       
     });
  }

  public updateTrack(){
    console.log('updating track now')
  }

  public deleteTrack(){
    console.log('deleting track now')
  }

}
