import { Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserDetails, TokenPayload } from './authentication.service';
import {Configmodel, Usermodel, Groupmodel} from '.././model/configmodel';
import {Tagmodel} from '.././model/tagmodel';
import {Kanbanmodel} from '.././model/kanbanmodel';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './side-nav.service';



@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public kandocs = '0';
  public attachednodedata = []
  public activetoolbar =[];
  public kanbanready = false;
  public kanbanopts = 0
  public attachednode = [];
  public attachednodetab = [];
  public linkready = false;
  public emaildata = [];
  public linkeditem = [];
  public stepready = false;
  public stepperprops = [];
  public source = 'direct'
  public popupheight = '80vh';
  public popupwidth = '80vw';

  public queuedirecturl = ''
  public flow = [];
  public userready = false;
  public nodechanged = false;
  public assignedto = '';
  public indextemplate = false
  public quickadd = false;
  public clicksource = 'direct';
  public textract = false;
  public linknodeready = false
  public linknodeds = [];
  public queueicon = '';
  public queuecolor = '';
  public hc = false; //HIDE SEARCH CRITERIA
  public feedready = false;
  public myfeed = [];
  public filtereduser = [];
  public filteredteam = [];
  public filterfoldertags = [];
  public fldritemcnt: number;
  public folderitem: number;
  public searchready = false;
  public mysearches = [];
  public usersinvited = [];
  public move =false;
  public moveitem = [];
  public favtools = false;
  public favtoolset = false;
  
  // public usernameFilter =[];
  public ready = false;
  public activeflowds = [];
  public editstepperassign = ''; 
  public edittabbedassign = ''; 

  public formstyle = 'form'
  public formtypeSelected = 'basic'
public stepindex = 0
    public csvrowcnt = 0
    public totalcsvrows = 0;
    public csvprocessed = 0;
  public activekanban = [];
  public queueready = false;
  public hlqueue = [];
  public activesidepanel = '';
  // public hlflow = [];
  public launchtype = 'launcher';
  public activeflow: string;
  public rootqueue = false;
  public teamqueueds = [];
  public queueitemds = [];
  public assigned = [];
 
  // public userassigned = [];
  public teamcurrentlyadded = false;
  public flowitempreviewds = [];
public atcmt = false;
  public flowitemdetailsds = [];
  public finditemds = []
  public toolbaritems = []
  public findsmartfolderds = [];
  public findkanbands = []
  public finditemfound = false;
  public flowready = false
  public flowdetailsds = [];
  public nodestepds = [];
  public nodetagsds = [];
  public nodeflowds = [];
  public editstepper = '';
  public nodetab = '';
  public editnode = '';
  public editkanban = '';
  public editflow = '';
  public stepclicked = false;
 
  public copy =false;
  public  chosenIcon: string;
  public newpropvalues = [];
  public weblink: string;
  public newfoldertags = [];
  public tagupload = false;
  public popup = false;
  public teamdetailsready = false;
  public currentfolder: string;
  public currentfoldername: string;

  public tabIndex = 0;
 
  public foldertabIndex = 0;
  
  public activestep = 0;
  public stepdetailsds = [];
  public roottoolbar = false;
  public parenttoolbar = [];
  public hidebrowser=false;
  public nodeclicked = false;
  public toolbards = [];

  // public toolbarnodes = [];

  public activeindexes = [];
  public hltag = []
  public hlstep = [];

  public nodedetailsready = false;
  public hlnode = []
  public rootnodeconsole = false;
  public mode = '';
  public teamtype = '';
  public edittageritemIndex = 0;
  public registericon = false; 
  public commentsds = [];
  public favmenutype = '';
  public tageritemready = false;
  public fileready = false;
  public colorinputtype ='team';
  public buildtool = false;
  public searchtool = false;
  public fullpage = true;
  public previewactive = false;
  public resultclick = false;
  public tagstep = 1;
  public  teamdetails =[];
  public teamdetailsds;
  public access: String;
  public nodeteamdetailsds=[];
  public nodeteamds =[];
  public nodeready = false;
  public nodetextracted = false
  
  public docdetailstab = 0;
  public filetaskactive = false;
 
  public chosenregisterIcon: string;
 
  public activeteamid;
  public activelevel;
  public activecolor;
  public activeurl;


  public searchtype: string;
  public searchproperty: string;
  public searchoperator: string;
  public searchcriteria: string;
  public lineitemcriteria: string;
  public foldertag: string;
  public choicecriteria: string;
  public searchresults = [];
  public queueresults = [];
  public commenticon: string;

  public chipbgcolor = 'gray';
  public usercurrentlyadded =false;
  public mgrcurrentlyadded =false;
  public addteamuserds= [];
  datenow;
  public itemlocked = false;
  datachanged = false;
  highlightedRows = [];
  public toolmenu = 'general';
  // public buildtool = false;
  public typeenabled = false;
  addfolderactive: boolean = false;
  editfolderactive: boolean = true;
  addfileactive: boolean = false;
  addtaskactive: boolean = false;
  addteamactive: boolean = false;
  gettingfile = false;
  gettingfolder= false;
  categoryready = false
  gettingkanban = false;
  gettingteam = false;
  gettingnodeteam = false
  gettingchoices = false;
  gettingformchoices = false;
  public activetool = '';
  public inactivetool = '';
  public locked = false;
  public activeitemid = '';
  public subitemsadded = [];
  public activepath = [];
  public activetype = 'base';
  public itemindex = 0;
  public activename = '';

  public activenav = 'tagteam';
  public navaction = false;


  public fileover = false;
  public docadd = false;
 // public browseaction ='none';
  public action = false;
  public browserspan =10;
  public editrows = 10;
  // public actionrows = 5;
  public hue: string;
  public color: string;
  public colorchanged = false;
  public usersadded= [];
  public mgrsadded= [];
  public path = [];
  public activeteam: string;
  public chipitem: string;
  public lockedchipitem: string;
  public rootselected = false;
  public subflowds = [];
  public subflowlevel = 0;
  public currentURL: string;
  public projitemsel = false;
  public currentrow: Configmodel;
  public currentfile: Configmodel;
  public currentform: Configmodel;
  
  public currentteam: Configmodel;
  public projectrow: Configmodel;
public tasksetactive = false;
public activeset = false;
// public activetsn: String;
  public activetsn: string;
  public projectactive = false;
// public activetsn: String;
  public activeprojname: string;
  public activeprojid: string;
  // public editprojflow = false;
  public setlocked = false;
  public setactive= false;

  public activetsid: string;
  public activetsitemcnt: number;
  public activeprojitemcnt: number;
 // public tasksetds: Configmodel [];
  // public questionds = [];
 //  public taskds = [];
  public hlitem = [];

  public hlprojitem = [];
  public itemds = [];

  user: TokenPayload;
  public currentlevel = 0;
 // public currentpath = [0];
  public type: String;
  // public basetypesds = ['Tasks','TaskSet', 'Projects', 'ProjectSet' , 'FileMetadata']
  public level1ds: Configmodel;
  public teamds: [Configmodel];
  public chatgptresponse = false;
  public toolbardresponse = false;
  public formfolderid;
  public cachedteamds = []
  public summary = [];
  public response = [];
  public cachestartindex = 0
  public cacheendindex = 100
  public cachedfeed = [];
  public feedcnt: number;
  public feeditem = 0;
  public feedstartindex = 0
  public feedendindex = 10
  public nodeds = [];
  public foldertabsds = [];
  public subfolders = [];
  public nodedetails = [];
  public tracker =[];
  public folderdetailsds: Configmodel;
  public folderstepitems = [];
  public folderready = false;
  public smartfolderitems = [];
public smartfolderitemsready = false
  public pathready = false;
  public folderpath = [];
  public categorydetailsds: Configmodel;
  public kanbandetailsds= [];
  public trackdetailsds= [];
  public pathds = [];
  public nodepathds = [];
  public fileds: Configmodel;
  public fileverds;
  public fileverready = false;
 
  public selectedItem: Configmodel [];
  
 

  public taskonomyds = [];
  public currentpath = [];
  
  public levelarray = [];
  // public tasksds: Configmodel;
  public datatypeds= [];
  public userdataset;
  public userdetailset: Usermodel;
  public adduserdataset: Usermodel;
  public grouplist = [];
  public groupselected;
  // public categoryds;
  public categoryds;
  public columnds = [];
  // public tasksds = [];
  public systemcolumnds= [];
  public customcolumnds= [];
  public categorysds= [];
 // public projectsds= [];
  //public projectds= [];
  public projectds: Configmodel [];
  // public subcategorysds=[];
  // public tasksds: Configmodel;
  public tasksds = [];
  public selectedType = 'base';
  public selectedParentid: string;
  public selectedFolder: string;
  public isFolder: string;
  @ViewChild('sidenav', { static: true }) 
 public sidenav: MatSidenav;

  public subcategoryds = [];
  public membershipdataset;
  public finduserdataset;
  public findteamds = [];public finduserds = []; public findteamuserds =[];
  
  
  public  hlTaskrow = [];
  public highlightedGroupRow = [];
  public highlightedUserRow = [];
  public highlightedMgrRow = [];
  
  public hlCategoryrow = [];
  public hlSubcategoryrow = [];
  public hltaskrow  = [];
  public highlightedMembershipRow  = [];
  public projectvalue;
  public currprojectrow: Configmodel;
 //  public propertyvalue;
  public currcategoryrow: Configmodel;
  public currsubcategoryrow: Configmodel;
  public currtaskrow: Configmodel;
  public currentuserrow: Usermodel;
  public currentmgrrow: Usermodel;
  public currentgrouprow: Groupmodel;
  public currentmembershiprow: Groupmodel;
  public stproject;
  public bsysfields = 'false';

  public daysvalue =0;
  public hoursvalue =2;
  public minutesvalue =0;
  public secondsvalue = 0;
  blasttask = true;
  subtaskcnt = 1;
  taskname = '';
  tasklevel = 1;
  bsubcat = false;
  projectselected = false;
  categoryselected = false;
  subcategoryselected = false;
  configlevel = 'base';
  public securityconfig = false;
 // myprojectsds = [];
  // tasksetds = [];


  // public tableresult = [{level: 0, active: true}];
  // public tableresult = [{level: 0}];
  public  map = new Map();
  public taskonomydsfiltered = [];
  public listoflevels = [0];
  public rootLevelNodes: string[] = [];
  public taskonomymap = new Map<string, string[]>([]);

  public currtagtempl: [Tagmodel];
  public currtags;
  public tagtemplactive = false;
  public tagsactive = false;
  public kanbanactive = false;
  public currkanban: [Kanbanmodel];

public actionenabled = false;

public currkanbantempl: [Kanbanmodel];

public kanbantemplactive = false;

  constructor(private authService: AuthenticationService, private router: Router, public sidenavSvc: SidenavService ) { }

  searchconfig(typerow) {
    this.tasksds = null;
    this.level1ds = null;
   // this.currTagset = null;
    this.datatypeds = [ 'String', 'Number', 'Date', 'Boolean'];
    const formData = new FormData();
    formData.append('Type', typerow);
    this.authService.searchconfig(formData, this.user).subscribe(configresults => {
      console.log('this is the email user data: ' + JSON.stringify(this.user));
      console.log('this is the search results in json format: ' + JSON.stringify(configresults));
     this.level1ds = configresults;
  }, (err) => {
    console.error(err); });

    }
getDuration(){

if (this.currentrow.type =='Task')
{
    var seconds = this.currentrow.tt
    this.daysvalue = Math.floor(seconds / (3600*24));
    seconds  -= this.daysvalue*3600*24;
    this.hoursvalue   = Math.floor(seconds / 3600);
    seconds  -= this.hoursvalue*3600;
    this.minutesvalue = Math.floor(seconds / 60);
   //  this.daysvalue = this.configSvc.currtaskrow.tt/3600
    console.log(this.daysvalue+" days, "+this.hoursvalue+" Hrs, "+this.minutesvalue+" Minutes, ");
  }
  }

    getbasetasks() {
      this.tasksds = null;
      this.datatypeds = [ 'String', 'Number', 'Date', 'Boolean'];
      const formData = new FormData();
      formData.append('Category', 'Category1');
      this.authService.getbasetasks(formData, this.user).subscribe(basetaskresults => {
        console.log('this is the email user data: ' + JSON.stringify(this.user));
        console.log('this is the getbasetasks results in json format: ' + JSON.stringify(basetaskresults));
       this.tasksds = basetaskresults;
    }, (err) => {
      console.error(err); });
      }

      



    

          

                        
                        getFile(selectedrow){
                       
                        
                          console.log('getting file is set to'+this.gettingfile)

                          this.activetype = 'file';
                         
                          this.currentrow = selectedrow;
                          console.log('current row selected is: '+JSON.stringify(this.currentrow));
                         
                       
                          this.action = false;
                          
                          this.selectedType = selectedrow.type;
                         //  this.activeitemid = selectedrow._id;
                           this.hlitem = [];
                          this.hlitem.push(selectedrow);
                          const formData = new FormData();
                                formData.append('type', this.selectedType);
                                formData.append('fileid', this.activeitemid);
                              
                           this.authService.getFile(formData, this.user).subscribe(fileresult => {
  
                           
                              console.log('this is a file that was selected: ');
                             
                              this.currentfile = fileresult;
                              this.gettingfile = true;
                              console.log('getting file is now set to'+this.gettingfile) 
                            
  
  
  
  
                          this.currentlevel = 2;
                          this.chipIt();
                          
                          }, (err) => {console.error(err); });} 
                          
                          getVersions(selectedrow){
                            this.fileverready = false;
                           // this.currentrow = selectedrow;
                            console.log('current row selected is: '+JSON.stringify(this.currentrow));
                          
                            // this.action = false;
                            
                         //   this.selectedType = selectedrow.type;
                         if (selectedrow.ver >1){
                             const fileid = selectedrow.vk;
                          //   this.hlitem = [];
                          //  this.hlitem.push(selectedrow);
                            const formData = new FormData();
                                //  formData.append('type', this.selectedType);
                                  formData.append('fileid', fileid);
                                
                             this.authService.getVersions(formData, this.user).subscribe(fileverresult => {
    
                             
                                console.log('this is a file that was selected: ');
                                
                                this.fileverds = fileverresult;
                                this.fileverready = true;
                            }, (err) => {console.error(err); });} 
                          }

                      
                        
                         

   


    getusers() {
      console.log('getting the users now');
      const usersForm = new FormData();
      this.authService.getusers(usersForm, this.user).subscribe(usersresults => {
        console.log('this is the search results in json format: ' + JSON.stringify(usersresults));
       this.userdataset = usersresults;
    }, (err) => {
      console.error(err); });

      }

   

      getgroups() {
        this.grouplist = null;
        const groupsForm = new FormData();
        this.authService.getgroups(groupsForm, this.user).subscribe(groupsresults => {
          console.log('this is the groups results in json format: ' + JSON.stringify(groupsresults));
         this.grouplist = groupsresults;
      }, (err) => {
        console.error(err); });
        }

        getmemberships() {
          this.membershipdataset = null;
          const membershipForm = new FormData();
          this.authService.getgroups(membershipForm, this.user).subscribe(membershipresults => {
            console.log('this is the membership results in json format: ' + JSON.stringify(membershipresults));
           this.membershipdataset = membershipresults;
        }, (err) => {
          console.error(err); });
          }


          findUser(username, searchtype?) {
            this.finduserdataset = null;
            const finduserForm = new FormData();
            finduserForm.append('username', username);
            this.authService.findUser(finduserForm, this.user).subscribe(finduserresults => {
              console.log('this is the user results : ' + JSON.stringify(finduserresults));
             this.findteamuserds = finduserresults;
             if(searchtype == 'addmyself'){this.mgrsadded = finduserresults}
          }, (err) => {
   console.error(err); });
 }



 findTeam(team, searchtype) {
  this.findteamds = []
  const findteamForm = new FormData();
  findteamForm.append('teamname', team);
  findteamForm.append('searchtype', searchtype);
  this.authService.findTeam(findteamForm, this.user).subscribe(findteamresults => {
    console.log('this is the team results: ' + JSON.stringify(findteamresults));
   

   this.findteamuserds = findteamresults;
}, (err) => {
console.error(err); });
}





 public getForm(formrow){
  this.currentrow = formrow;
  this.hlitem = [];
  console.log('current form row: ' + JSON.stringify(formrow));
  this.hlitem.push(formrow);
 }

 public getTageritem(selectedtageritem){
 

  this.currentrow = selectedtageritem;
  this.hlitem = [];
  console.log('current tageritem row: ' + JSON.stringify(selectedtageritem));
  this.hlitem.push(selectedtageritem);
  
  
    this.tagsactive = false;
         this.tageritemready = false; 
        console.log('tageritemready is now falsw '+ this.tageritemready);
        this.activetype = 'tageritem';
   
   
    const tagsForm = new FormData();
     tagsForm.append('itemid', selectedtageritem._id);
      tagsForm.append('type', this.activetype);
        
     this.authService.getTags(tagsForm, this.user).subscribe(tagsresult => {
          
          this.currtags = tagsresult;
         this.tageritemready = true;
          console.log('current tags are '+JSON.stringify(this.currtags));
        //  console.log('current tag template is '+JSON.stringify(this.currtagtempl));
          if (this.currtags.length <1){
            this.tagsactive = false;
          }
          else{
          this.tagsactive = true;}
         // console.log('tagtemplactive is '+this.tagtemplactive);
          console.log('tagsactive is '+this.tagsactive);
      
    
    }, (err) => {console.error(err); });} 
  

 

  


  public  getSystemColumns(stproject) {
    this.systemcolumnds = null;
    const projectData = new FormData();
    projectData.append('project', stproject);
    console.log('current project data form: ' + JSON.stringify(projectData));
    this.authService.getSystemColumns(projectData, this.user).subscribe(projectresults => {

      console.log('this is the results from getting the system columns: ' + JSON.stringify(projectresults));
     this.systemcolumnds = projectresults;

  }, (err) => {
    console.error(err); });

  }

  public  getCustomColumns(stproject) {
    this.customcolumnds = null;
    this.categorysds = null;
    const projectData = new FormData();
    projectData.append('project', stproject);
    console.log('current project data form: ' + JSON.stringify(projectData));
    this.authService.getCustomColumns(projectData, this.user).subscribe(projectresults => {

      console.log('this is the results from getting the custom columns: ' + JSON.stringify(projectresults));
     this.customcolumnds = projectresults;
     this.categorysds = this.customcolumnds.map( a => a.Category);
     console.log('this is the results from getting the categorysds: ' + JSON.stringify(this.categorysds));


  }, (err) => {
    console.error(err); });

  }


      public createTeam(join, type,  parentid, teamname, teamdescription, level , mgrsadded, usersadded, color, icontype, register: Boolean, companyid?, invitedteamid? ) {
          this.teamdetailsds = [];
        const createTeamForm = new FormData();
        this.activetype = type;
        console.log('create team join value '+join);
       
        //console.log('folder im trying to add is: ' + JSON.stringify(name));
        createTeamForm.append('type', type);
        console.log('type im trying to add is: ' + JSON.stringify(type));
        createTeamForm.append('parentid', parentid);
        console.log('parentid im trying to add is: ' + JSON.stringify(parentid));
        createTeamForm.append('teamname', teamname);
        console.log('teamname im trying to add is: ' + teamname);
        createTeamForm.append('teamdescription', teamdescription);
        console.log('managers im trying to add is: ' + JSON.stringify(mgrsadded));
        createTeamForm.append('mgrsadded', JSON.stringify(mgrsadded));
        console.log('users im trying to add is: ' + JSON.stringify(usersadded));
        createTeamForm.append('usersadded', JSON.stringify(usersadded));
        console.log('colors im trying to add to createTeam: ' + JSON.stringify(color));
        createTeamForm.append('color', JSON.stringify(color));
        

        createTeamForm.append('icontype', JSON.stringify(icontype));
        console.log('icontype  im trying to add to createTeam: ' + JSON.stringify(icontype));
        if(register){
          createTeamForm.append('register', '1');
         
        }
        if(!register){
          createTeamForm.append('register', '0');
        }

     
        
        if (parentid == 'base'){
          console.log('the level is currently at base so not incrementing');
          createTeamForm.append('level', '0');}

          if (parentid !='base'){
          console.log('the level is currently not at base so incrementing');
        var newlevel = level+1;
        var leveladdedto = newlevel.toString();
        createTeamForm.append('level', leveladdedto);
        }

if(!join){
        this.authService.createTeam(createTeamForm, this.user).subscribe(rootTeams => {
          this.teamds = rootTeams;
          this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
          this.activetype = '';
          this.activetool = '';
          console.log('active tool is now bnlank at createteam ');
          if(register){
            console.log('this is a registration so createteam redirects to tagquad home ');
            this.router.navigateByUrl('/toolbard/home');
          }
        }
         , (err) => {
          console.error(err); });
        }

        if(join){

          createTeamForm.append('companyid', companyid);
          createTeamForm.append('invitedteamid', invitedteamid);
          this.authService.createandjoinTeam(createTeamForm, this.user).subscribe(rootTeams => {
            this.teamds = rootTeams;
            this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
            this.activetype = '';
            this.activetool = '';
            console.log('active tool is now bnlank at createteam ');
            if(register){
              console.log('this is a registration so createteam redirects to tagquad home ');
              this.router.navigateByUrl('/toolbard/home');
            }
            if(!register){
              this.teamds =rootTeams 
              this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
            }

          }
           , (err) => {
            console.error(err); });
          }
}





        public nodeTeam(type,  parentid, teamname, teamdescription, level , mgrsadded, usersadded, color, icontype, register: Boolean ) {
          this.nodeteamdetailsds = [];
        const createNodeteamform = new FormData();
        this.activetype = type;
        console.log('this is a create team function so setting active type to team');
        //console.log('folder im trying to add is: ' + JSON.stringify(name));
        createNodeteamform.append('type', type);
        console.log('type im trying to add is: ' + JSON.stringify(type));
        createNodeteamform.append('parentid', parentid);
        console.log('parentid im trying to add is: ' + JSON.stringify(parentid));
        createNodeteamform.append('teamname', teamname);
        createNodeteamform.append('teamdescription', teamdescription);
        console.log('users im trying to add is: ' + JSON.stringify(mgrsadded));
        createNodeteamform.append('mgrsadded', JSON.stringify(mgrsadded));
        console.log('users im trying to add is: ' + JSON.stringify(usersadded));
        createNodeteamform.append('usersadded', JSON.stringify(usersadded));
        console.log('colors im trying to add to createnodeTeam: ' + JSON.stringify(color));
        createNodeteamform.append('color', JSON.stringify(color));
        

        createNodeteamform.append('icontype', icontype);
        console.log('icontype  im trying to add to createnodeTeam: ' + icontype);
       
     
        
        if (parentid == 'base'){
          console.log('the level is currently at base so not incrementing');
          createNodeteamform.append('level', '0');}

          if (parentid !='base'){
          console.log('the level is currently not at base so incrementing');
        var newlevel = level+1;
        var leveladdedto = newlevel.toString();
        createNodeteamform.append('level', leveladdedto);
        }


        this.authService.nodeTeam(createNodeteamform, this.user).subscribe(rootTeams => {
          this.nodeteamds = rootTeams;
          this.activetype = '';
          this.activetool = '';
          console.log('active tool is now bnlank at createteam ');
          if(register){
            console.log('this is not a registration so createteam redirects to node console');
            this.router.navigateByUrl('/toolbard/nodeconsole');
          }
        }
         , (err) => {
          console.error(err); });

        }

        public addFolder(type, name, desc, parentid,  parentname, teamid, level, indextype, icontype, iconcolor, nodename?, nodeid?, smartfolderprops?, folders?, subfolders?, 
          folderkanban?, kanbanid?, kanbancolor?,kanbanname?,  kanbantotal?, 
          locktoolbar?, toolbarid?, toolbarname?, toolbardesc?, toolbaricon?, toolbarclr?,
          quickadd?, assigned?, assignedto?, deadline?, 
          deadlineval?, cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atuser?,
          foldertags?, tagname?, tagdesc?, tagtype?, kantype?, settotal?, timetotal?, counttotals?) {
          console.log('this is using indextype '+indextype);
          const folderForm = new FormData();
          console.log('counttotals on add folder is : ' + counttotals);
          console.log('assigned on add folder is : ' + assigned );
           folderForm.append('type', type);
           folderForm.append('counttotals', counttotals);
           folderForm.append('teamid', teamid);
          console.log('teamid is : ' + JSON.stringify(teamid));
          folderForm.append('name', name);
          folderForm.append('desc', desc);
          console.log('name im trying to create is: ' + JSON.stringify(name));
         console.log('folder level im trying to add to is: ' + JSON.stringify(level));
         folderForm.append('level', level);
          folderForm.append('parentid', parentid);
          console.log('parentid im trying to add to is: ' + JSON.stringify(parentid));
          folderForm.append('parentname', parentname);
          console.log('parentname im trying to add to is: ' + JSON.stringify(parentname));
          this.datenow = Date.now();
          folderForm.append('addedon', this.datenow);
          folderForm.append('quickadd', quickadd);

          if(folderkanban){
           
          folderForm.append('kanbantagged', 'true')
          folderForm.append('kanbanid', kanbanid)
          folderForm.append('kanbancolor', kanbancolor)
          folderForm.append('kanbantotal', kanbantotal)
          folderForm.append('kanbanname', kanbanname)
         console.log('settotal is set to '+settotal)
          if(settotal){
            console.log('timetotal is set to '+timetotal)
            folderForm.append('settotal', 'true')
            folderForm.append('timetotal', timetotal)
          }
          if(!settotal){
            folderForm.append('settotal', 'false')
          }
          folderForm.append('kantype', kantype)

        }
          if(!folderkanban){
            folderForm.append('kanbantagged', 'false');}

            if(locktoolbar){
           
              folderForm.append('locktoolbar', 'true')
              folderForm.append('toolbarid', toolbarid)
              folderForm.append('toolbarname', toolbarname)
              folderForm.append('toolbardesc', toolbardesc)
              folderForm.append('toolbaricon', toolbaricon)
              folderForm.append('toolbarclr', toolbarclr)
             
            }
              if(!locktoolbar){
                folderForm.append('locktoolbar', 'false');}

          folderForm.append('assigned', assigned);
          if(assigned){
            folderForm.append('assignedto', assignedto);
          }

          folderForm.append('deadline', deadline);
          if(deadline){
            folderForm.append('deadlineval', deadlineval);
          }
          console.log('this is the cmt folder value '+cmt)
          folderForm.append('cmt', cmt);
          if(cmt){
            folderForm.append('prompt', cmtval);
            folderForm.append('atcmt', atcmt);
         
            

            if(atcmt){
              folderForm.append('cmtuser', atuser);
            }
            
           
              folderForm.append('cmticon', cmticon);
            folderForm.append('cmtclr', cmtclr);
           
          }

          
  
          console.log('padded on is set to : ' + JSON.stringify( this.datenow));
            console.log('the level is currently not at base so incrementing');
            folderForm.append('indextype', indextype);
            console.log('tbefore going further this is the indextype '+indextype);
            if (type == 'fold') {
              console.log('this isnt a smartfolder')
            folderForm.append('icontype', 'folder');
            folderForm.append('iconcolor', 'yellow');
          console.log('activetype before adding basic folder'+this.activetype)
          console.log('quickadd before adding folder'+this.quickadd)
          }
            
            if (indextype == '1' ||indextype == '2' || indextype == '5'){
              console.log('this is using folder tags with indextype = 1,2 or 5');
              //  this is inheriting folder tags with indextype = 1
                   //  this is inheriting team tags with indextype = 2
                          //  this is applying NEW folder tags with indextype = 5
            if(type != 'smartfoldertab'){ 
              folderForm.append('folderprops', foldertags);}
             
              folderForm.append('tagname', tagname)
              folderForm.append('tagdesc', tagdesc)
            
              folderForm.append('tagicon', icontype);
              folderForm.append('tagcolor', iconcolor)
              }
    
          

                if (type == 'smartfolder'){
                  //  this is a basic node smartfolder
                  console.log('smartfolder detected')
                
                  folderForm.append('nodename', nodename)
                  folderForm.append('nodeid', nodeid)
                 
                  folderForm.append('smartfolder', smartfolderprops);
                  folderForm.append('icontype', icontype);
                  folderForm.append('iconcolor', iconcolor);
                 
               
                  }

                  if (type == 'smartfoldertab'){
                   //  //  this is a basic stepper smartfolder which is tabbed or wizard
                   folderForm.append('icontype', icontype);
                  folderForm.append('iconcolor', iconcolor);
                    console.log('stepper folder props are'+smartfolderprops)
                    folderForm.append('smartfoldertab', smartfolderprops);
                  //  folderForm.append('nodeid', nodeid);
                  folderForm.append('tagtype', tagtype)
                    folderForm.append('nodename', nodename)
                  folderForm.append('nodeid', nodeid)
                   
                 
                    }

                   

                    if(type == 'smartfolder'){
                      console.log('this is a smartfolder')
                   
                      
                      if(folders == true){
                        console.log('this smart folder has subfolders')
                        folderForm.append('folders', folders);
                        folderForm.append('subfolders', JSON.stringify(subfolders));
                      }
                      if(folders == false){
                        console.log('this smart folder has no subfolders')
                        folderForm.append('folders', folders);
                      }
                   //   folderForm.append('kanbantagged', folderkanban);

                      if(folderkanban == true){
                        console.log('this this smart folder has a kanban')
                        var nodeid = kanbanid
                        folderForm.append('nodeid', nodeid);
                        folderForm.append('kanbancolor',  kanbancolor);

                      }
                     
                      
                    }

                  

          this.authService.addFolder(folderForm, this.user).subscribe(foldercreatedata => {
            console.log('got folder created reults' )
            this.teamds = foldercreatedata;

           this.formfolderid = foldercreatedata[0]._id
           console.log('got folder results for type '+type )
           console.log(' folder  '+folders )
            if(folders == true && type == 'smartfolder'){
           const formfolderparentid = this.teamds[this.teamds.length-1]._id
           console.log('succeesfully created the smart folder with the id '+formfolderparentid )

             //now looping through the subfolders but need to get the itemid of the formfolder root i just crreated
    for(const folderitem of this.nodeds[0].subfolders){
      var x = this.activelevel;
var subfolderleveltemp: number = +x;
 var subfolderlevel: number = subfolderleveltemp+1
  console.log('subfolder level '+subfolderlevel)
      if(folderitem.type == 'subfolder'){
      this.addFolder( 'fold', folderitem.name, 
      formfolderparentid, name, 
      this.activeteamid, subfolderlevel, '0','folder', 'yellow', null,
       null, false,null, false, null,null, false, false,true,false,
      null, false,null,false, null, null, null,null,null,null,null,null,null,null,false,0,false )
      }0

      if(folderitem.type == 'node'){
        console.log('saving node items here for '+JSON.stringify(folderitem))
    //    this.saveNode( true, true, formfolderparentid,  this.activeteamid, this.activelevel+1, '3', folderitem.name, 
    //  formfolderparentid, name, 
// this.activeteamid, '3', '0','folder', 'yellow', null, null, false,null )
this.batchitems(folderitem.linkid, formfolderparentid, folderitem.type, '0')
        }

    }
console.log('redirecting to the smart folder');
var base = '/toolbard/home?'
var itemid = this.activeitemid
var teamid = this.activeteamid;
var dynamicurl = base+'itemid='+this.formfolderid+'&type=smartfolder&name='+name+'&level=2&teamid='+teamid


this.router.navigateByUrl(dynamicurl);
    // this.refreshFolder(this.activeitemid, 'formfolder')

  
  
  }
            this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
            this.csvrowcnt++;
            this.csvprocessed = this.csvrowcnt/this.totalcsvrows*100
         
          }
           , (err) => {
            console.error(err); });
  
}

 public addTeamTagFolder(name, parentid,  parentname, teamid, level  ) {

            const folderForm = new FormData();
             folderForm.append('type', 'fold');
             folderForm.append('tagtype', 'team');
             folderForm.append('teamid', teamid);
           folderForm.append('name', name);
           folderForm.append('level', level);
            folderForm.append('parentid', parentid);
           folderForm.append('parentname', parentname);
            this.datenow = Date.now();
            folderForm.append('addedon', this.datenow);
          this.authService.addTeamTagFolder(folderForm, this.user).subscribe(foldercreatedata => {
            
              this.teamds = foldercreatedata;
              this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
           
            }
             , (err) => {
              console.error(err); });
    
}

public addCategory(categoryname, parentid,  parentname, teamid, level  ) {

  const categoryForm = new FormData();
  categoryForm.append('type', 'category');
  categoryForm.append('icontype', 'folder_special');
  categoryForm.append('iconcolor', 'yellow');
  categoryForm.append('teamid', teamid);
  console.log('teamid is : ' + JSON.stringify(teamid));
  categoryForm.append('name', categoryname);
  console.log('categoryname im trying to create is: ' + JSON.stringify(categoryname));
 console.log('category level im trying to add to is: ' + JSON.stringify(level));
 categoryForm.append('level', level);
 categoryForm.append('parentid', parentid);
  console.log('parentid im trying to add to is: ' + JSON.stringify(parentid));
  categoryForm.append('parentname', parentname);
  console.log('parentname im trying to add to is: ' + JSON.stringify(parentname));
  this.datenow = Date.now();
  categoryForm.append('addedon', this.datenow);
  console.log('padded on is set to : ' + JSON.stringify( this.datenow));
    console.log('the level is currently not at base so incrementing');
  this.authService.addCategory(categoryForm, this.user).subscribe(categories => {
  console.log('updating the teamds for categories')
    this.nodeteamds = categories;
    console.log('finished updating the teamds for categories')

 
  }
   , (err) => {
    console.error(err); });

  }
/*
  public getnodepath(parentid, teamid) {

    const pathForm = new FormData();
 
    pathForm.append('parentid', parentid);
    pathForm.append('teamid', teamid);
    this.authService.getNodepath(pathForm, this.user).subscribe(nodepathdata => {
    
      this.nodepathds = nodepathdata;
   
    }
     , (err) => {
      console.error(err); });

}
*/

public createNode(nodename, nodedesc, type, style, textractable, textractype, ischatgpt, website, weblink)
{
  console.log('this is the node'+nodename)
  console.log('this is the node desc'+nodedesc)
  console.log('this is the node type'+type)
  this.datenow = Date.now();

  const createNodeForm = new FormData();
  createNodeForm.append('name', nodename);
  createNodeForm.append('desc', nodedesc);
  createNodeForm.append('teamid', this.activeteamid);
  createNodeForm.append('level', this.activelevel);
  createNodeForm.append('parentid', this.activeitemid);
  createNodeForm.append('type', type);
  createNodeForm.append('addedon', this.datenow);
  createNodeForm.append('icontype', this.chosenIcon);
  createNodeForm.append('iconcolor', this.color);
  createNodeForm.append('style', style);
  createNodeForm.append('textractable', textractable);
  createNodeForm.append('textractype', textractype);
  createNodeForm.append('ischatgpt', ischatgpt);
  createNodeForm.append('website', website);
  if(website){
  createNodeForm.append('weblink', weblink);}


  if(type == 'basicflow' || type == 'editstepperassign' || type == 'edittabbedassign')
  {
    createNodeForm.append('assigned', JSON.stringify(this.assigned));
  }


this.authService.createNode(createNodeForm, this.user).subscribe(createnoderesults => {
  console.log('this is the results from createing Node: ' + JSON.stringify(createnoderesults));
   this.nodeteamds = createnoderesults;
  // this.colorinputtype = 'tager'
  //this.findkanbansetclicked = true;
  
 
}, (err) => {
  console.error(err); });

}

public createKanban(nodename, nodedesc, type, kantype, settotal, timetotal)
{
  console.log('this is the node'+nodename)
  console.log('this is the node desc'+nodedesc)
  console.log('this is the node type'+type)
  console.log('this is the settotal '+settotal)
  console.log('this is the timetotal'+timetotal)
  this.datenow = Date.now();

  const createNodeForm = new FormData();
  createNodeForm.append('name', nodename);
  createNodeForm.append('desc', nodedesc);
  createNodeForm.append('teamid', this.activeteamid);
  createNodeForm.append('level', this.activelevel);
  createNodeForm.append('parentid', this.activeitemid);
  createNodeForm.append('type', type);
  createNodeForm.append('addedon', this.datenow);
  createNodeForm.append('icontype', this.chosenIcon);
  createNodeForm.append('iconcolor', this.color);
  createNodeForm.append('kantype', kantype);
  createNodeForm.append('settotal', settotal);
  if(settotal){
  createNodeForm.append('timetotal', timetotal);}


this.authService.createNode(createNodeForm, this.user).subscribe(createnoderesults => {
  console.log('this is the results from createing Node: ' + JSON.stringify(createnoderesults));
   this.nodeteamds = createnoderesults;
  // this.colorinputtype = 'tager'
  //this.findkanbansetclicked = true;
  
 
}, (err) => {
  console.error(err); });

}

public createSubfolder(type, name, formfolderid)
{
  console.log('this is the name'+name)
  console.log('this is the formfolderid'+formfolderid)

  this.datenow = Date.now();

  const createSubfolderForm = new FormData();
  createSubfolderForm.append('type', type);
  
  createSubfolderForm.append('name', name);
  createSubfolderForm.append('formfolderid', formfolderid);
  createSubfolderForm.append('teamid', this.activeteamid);
  createSubfolderForm.append('addedon', this.datenow);
  createSubfolderForm.append('level', this.activelevel);
  





this.authService.createSubfolder(createSubfolderForm, this.user).subscribe(creatsubfolderresults => {
  console.log('this is the results from createing subfolder: ' + JSON.stringify(creatsubfolderresults));
   this.nodeteamds = creatsubfolderresults;

  
 
}, (err) => {
  console.error(err); });

}

public addffItem(process, type, nodename, name, desc, formfolderid, nodeid, icontype, iconcolor, newpropvalues)
{
  console.log('this is the itemname'+name)
  console.log('this is the formfolderid'+formfolderid)
  console.log('this is the nodeid'+nodeid)

  this.datenow = Date.now();

  const createSubfolderForm = new FormData();
  createSubfolderForm.append('type', type);
  createSubfolderForm.append('nodename', nodename);
  createSubfolderForm.append('name', name);
  createSubfolderForm.append('desc', desc);
  createSubfolderForm.append('nodeid', nodeid);
  createSubfolderForm.append('formfolderid', formfolderid);
  createSubfolderForm.append('icontype', icontype);
  createSubfolderForm.append('iconcolor', iconcolor);
  createSubfolderForm.append('teamid', this.activeteamid);
  createSubfolderForm.append('addedon', this.datenow);
  createSubfolderForm.append('level', this.activelevel);
  createSubfolderForm.append('stepitems', newpropvalues);
  createSubfolderForm.append('process', process);





this.authService.addffItem(createSubfolderForm, this.user).subscribe(creatsubfolderresults => {
  console.log('this is  the results from adding a subitem: ' + JSON.stringify(creatsubfolderresults));
   this.nodeteamds = creatsubfolderresults;
   this.subfolders = creatsubfolderresults.subfolders

  
 
}, (err) => {
  console.error(err); });

}

public createIndexer(nodename, nodedesc, type, style)
{
  console.log('this is the node'+nodename)
  console.log('this is the node desc'+nodedesc)
  console.log('this is the node type'+type)
  this.datenow = Date.now();

  const createIndexerForm = new FormData();
  createIndexerForm.append('name', nodename);
  createIndexerForm.append('desc', nodedesc);
  createIndexerForm.append('teamid', this.activeteamid);
  createIndexerForm.append('level', this.activelevel);
  createIndexerForm.append('parentid', this.activeitemid);
  createIndexerForm.append('type', type);
  createIndexerForm.append('addedon', this.datenow);
  createIndexerForm.append('icontype', this.chosenIcon);
  createIndexerForm.append('iconcolor', this.color);
  createIndexerForm.append('style', style);



this.authService.createIndexer(createIndexerForm, this.user).subscribe(createindexerresults => {
  console.log('this is the results from createing Node: ' + JSON.stringify(createindexerresults));
   this.nodeteamds = createindexerresults;
  // this.colorinputtype = 'tager'
  //this.findkanbansetclicked = true;
  
 
}, (err) => {
  console.error(err); });

}


public addTrack(trackname, trackdesc, type)
{
  console.log('this is the trackname'+trackname)
  console.log('this is the node desc'+trackdesc)

  this.datenow = Date.now();

  const addtrackForm = new FormData();
  addtrackForm.append('name', trackname);
  addtrackForm.append('desc', trackdesc);
  addtrackForm.append('teamid', this.activeteamid);
  addtrackForm.append('level', this.activelevel);
  addtrackForm.append('nodeid', this.activeitemid);
  addtrackForm.append('addedon', this.datenow);
  addtrackForm.append('type', type)

this.authService.addTrack(addtrackForm, this.user).subscribe(addtrackresults => {
  console.log('this is the results from adding a track: ' + JSON.stringify(addtrackresults));
  this.nodeds = addtrackresults
   this.nodeteamds = addtrackresults.stepitems;

  
 
}, (err) => {
  console.error(err); });

}



public addStep(stepname, stepdesc, type, icons, icontype?, iconcolor?)
{
  this.nodedetailsready = false;
  console.log('this is the trackname'+stepname)
  console.log('this is the node desc'+stepdesc)

  this.datenow = Date.now();

  const addstepForm = new FormData();
  addstepForm.append('step', stepname);
  addstepForm.append('desc', stepdesc);
  addstepForm.append('teamid', this.activeteamid);
  addstepForm.append('level', this.activelevel);
  addstepForm.append('nodeid', this.activeitemid);
  addstepForm.append('addedon', this.datenow);
  addstepForm.append('type', type)
  addstepForm.append('icons', icons)
  if(!icons){
    addstepForm.append('icontype', 'directions')
    addstepForm.append('iconcolor', 'red')
  }
  if(icons){
    addstepForm.append('icontype', icontype)
    addstepForm.append('iconcolor', iconcolor)
  }

this.authService.addStep(addstepForm, this.user).subscribe(addstepresults => {
  console.log('this is the results from adding a step: ' + JSON.stringify(addstepresults));
  this.nodeds = [addstepresults]
  console.log('this is the nodeds after adding a step: ' + JSON.stringify(this.nodeds));
   // this.nodeds[0] = addstepresults.stepper;
   this.nodestepds = addstepresults.stepper
   console.log('this is the nodeteamds after adding a step: ' + JSON.stringify(this.nodeteamds));
   this.nodedetailsready = true;
  
 
}, (err) => {
  console.error(err); });

}

public createLinktab(stepname, stepdesc, type, toolindex, restrict, tool, toolinval, targeticon, targetcolor)
{
  
  this.nodedetailsready = false;
  console.log('this is the trackname'+stepname)
  console.log('this is the node desc'+stepdesc)
  console.log('this is the restrict '+restrict)

  this.datenow = Date.now();

  const creatlinktabForm = new FormData();
  creatlinktabForm.append('step', stepname);
  creatlinktabForm.append('desc', stepdesc);
  creatlinktabForm.append('teamid', this.activeteamid);
  creatlinktabForm.append('level', this.activelevel);
  creatlinktabForm.append('nodeid', this.activeitemid);
  creatlinktabForm.append('addedon', this.datenow);
  creatlinktabForm.append('type', type)
  creatlinktabForm.append('toolindex', toolindex)
  creatlinktabForm.append('restrict', restrict.toString())

  if(restrict == 'true'){

  
  creatlinktabForm.append('tool', tool)

  creatlinktabForm.append('toolinval', toolinval)
  creatlinktabForm.append('targeticon', targeticon)
  creatlinktabForm.append('targetcolor', targetcolor)
}




 

this.authService.createLinktab(creatlinktabForm, this.user).subscribe(createlinktabresults => {
  console.log('this is the results from creating a link tab: ' + JSON.stringify(createlinktabresults));
  this.nodeds = [createlinktabresults]
  console.log('this is the nodeds after adding a step: ' + JSON.stringify(this.nodeds));
   // this.nodeds[0] = addstepresults.stepper;
   this.nodestepds = createlinktabresults.stepper
   console.log('this is the nodeteamds after adding a step: ' + JSON.stringify(this.nodeteamds));
   this.nodedetailsready = true;
  
 
}, (err) => {
  console.error(err); });

}


          public getpath(parentid, teamid) {

            const pathForm = new FormData();
         
            pathForm.append('parentid', parentid);
            pathForm.append('teamid', teamid);
            this.authService.getpath(pathForm, this.user).subscribe(pathdata => {
            
              this.pathds = pathdata;
           
            }
             , (err) => {
              console.error(err); });
    
            }

           
    
        public addsectag( stproject, stcategory, stsubcategory, stsubtask) {

          const subtaskForm = new FormData();
          console.log('subtask im trying add it to is: ' + JSON.stringify(stsubtask));
          console.log('valuelist Im trying to add is' + JSON.stringify(stsubcategory));
          console.log('category Im trying to add it to is' + JSON.stringify(stcategory));
          console.log('project im trying to add it to is: ' + JSON.stringify(stproject));
          subtaskForm.append('subtask', stsubtask);
          subtaskForm.append('subcategory', stsubcategory);
          subtaskForm.append('category', stcategory);
          subtaskForm.append('project', this.stproject);


          this.authService.addsectag(subtaskForm, this.user).subscribe(subtaskdata => {

           this.tasksds = subtaskdata;
          }
           , (err) => {
            console.error(err); });

          }

          public  selectUserRow(userrow, type: boolean) {
           this.highlightedUserRow = [];
            this.currentuserrow = userrow;
            console.log('the type is set to '+type);
            if (type == false) {
              console.log('the type is set to false to setting usercurrentlyadded to');
              this.usercurrentlyadded = false;}
            if (type == true){
              this.usercurrentlyadded = true;}
            this.highlightedUserRow.push(userrow);
            console.log('currentuser row: ' + JSON.stringify(this.currentuserrow));
           //  console.log('currentuser: ' + JSON.stringify(this.currentuserrow.email));
            console.log('highlighed row: ' + JSON.stringify(this.highlightedUserRow));
             }

             public  selectMgrRow(mgrrow, type: boolean) {
              this.highlightedMgrRow = [];
               this.currentmgrrow = mgrrow;
               console.log('the type is set to '+type);
               if (type == false) {
                 console.log('the type is set to false to setting mgrcurrentlyadded to');
                 this.mgrcurrentlyadded = false;}
               if (type == true){
                 this.mgrcurrentlyadded = true;}
               this.highlightedMgrRow.push(mgrrow);
               
               console.log('highlighed row: ' + JSON.stringify(this.highlightedMgrRow));
                }

            

         
        

           public addgroup(stgroup, membersarray) {

            const groupForm = new FormData();
            console.log('group Im trying to add is' + JSON.stringify(stgroup));
            groupForm.append('group', stgroup);
            groupForm.append('groupmembers', membersarray);

            this.authService.addgroup(groupForm, this.user).subscribe(groupdata => {
             this.grouplist = groupdata;
            }
             , (err) => {
              console.error(err); });

            }

           public adduser(stusername, stemail, arrgroups) {
            const adduserForm = new FormData();
            console.log('user Im trying to add is' + JSON.stringify(stusername));
            adduserForm.append('user', stusername);
            adduserForm.append('email', stemail);
            adduserForm.append('groups', arrgroups);
            console.log('groups Im trying to add is' + JSON.stringify(arrgroups));
            console.log('add user form is ' + JSON.stringify(adduserForm));
            this.authService.adduser(adduserForm, this.user).subscribe(adduserdata => {
              console.log('add user data set returned is' + JSON.stringify(adduserdata));
              this.adduserdataset = adduserdata;

             }
              , (err) => {
               console.error(err); });
             }

             public  findUserRow(stusername) {
              this.highlightedUserRow = [];

               const userForm = new FormData();
               userForm.append('user', stusername);
               console.log('currentuser data form: ' + JSON.stringify(userForm));
               this.authService.searchuserclick(userForm, this.user).subscribe(userresults => {
               console.log('this is the results from finsing user: ' + JSON.stringify(userresults));
               this.userdetailset = userresults;
             }, (err) => {
               console.error(err); });

             }

             public addteamUser(teamitemid, useremail){
              const addteamuserForm = new FormData();
              
              addteamuserForm.append('teamitemid', teamitemid);
              addteamuserForm.append('email', useremail);
              this.authService.addteamuser(addteamuserForm, this.user).subscribe(addteamuserdata => {
                console.log('add user data set returned is' + JSON.stringify(addteamuserdata));
                this.usersadded = addteamuserdata.users;
                console.log('new members only' + JSON.stringify( this.addteamuserds ));
               }
                , (err) => {
                 console.error(err); });
               }

               public addteammgr(teamitemid, useremail){
                const addteamuserForm = new FormData();
                
                addteamuserForm.append('teamitemid', teamitemid);
                addteamuserForm.append('email', useremail);
                this.authService.addteammgr(addteamuserForm, this.user).subscribe(addteammgrdata => {
                  console.log('add user data set returned is' + JSON.stringify(addteammgrdata));
                  this.mgrsadded = addteammgrdata.mgr;
                  console.log('new members only' + JSON.stringify( this.mgrsadded ));
                 }
                  , (err) => {
                   console.error(err); });
                 }

               public addnodeteamuser(teamitemid, username){
                const addteamuserForm = new FormData();
                
                addteamuserForm.append('teamitemid', teamitemid);
                addteamuserForm.append('username', username);
                this.authService.addnodeteamuser(addteamuserForm, this.user).subscribe(addteamuserdata => {
                  console.log('add user data set returned is' + JSON.stringify(addteamuserdata));
                  this.usersadded = addteamuserdata.users;
                  console.log('new members only' + JSON.stringify( this.addteamuserds ));
                 }
                  , (err) => {
                   console.error(err); });
                 }

                 public addnodeteammgr(teamitemid, useremail){
                  const addnodeteammgrForm = new FormData();
                  
                  addnodeteammgrForm.append('teamitemid', teamitemid);
                  addnodeteammgrForm.append('email', useremail);
                  this.authService.addnodeteammgr(addnodeteammgrForm, this.user).subscribe(addteamuserdata => {
                    console.log('add user data set returned is' + JSON.stringify(addteamuserdata));
                    this.usersadded = addteamuserdata.mbr;
                    console.log('new members only' + JSON.stringify( this.addteamuserds ));
                   }
                    , (err) => {
                     console.error(err); });
                   }
  

             public removeteamuser(teamitemid, useremail){

              const removeteamuserForm = new FormData();
              
              removeteamuserForm.append('teamitemid', teamitemid);
              removeteamuserForm.append('email', useremail);
              this.authService.removeteamuser(removeteamuserForm, this.user).subscribe(updatedteamuserdata => {
                console.log('add user data set returned is' + JSON.stringify(updatedteamuserdata));
                this.usersadded = updatedteamuserdata.mbr;
                console.log('new members only' + JSON.stringify( this.addteamuserds ));
               }
                , (err) => {
                 console.error(err); });
               }

               public removeteammgr(teamitemid, useremail){

                const removeteammgrForm = new FormData();
                
                removeteammgrForm.append('teamitemid', teamitemid);
                removeteammgrForm.append('email', useremail);
                this.authService.removeteammgr(removeteammgrForm, this.user).subscribe(updatedteammgrdata => {
                  console.log('add user data set returned is' + JSON.stringify(updatedteammgrdata));
                  this.mgrsadded = updatedteammgrdata.mgr;
                  console.log('new managers set to' + JSON.stringify( this.mgrsadded ));
                 }
                  , (err) => {
                   console.error(err); });
                 }

                 public removeUser(teamitemid, useremail){

                  const removeuserForm = new FormData();
                  
                  removeuserForm.append('teamitemid', teamitemid);
                  removeuserForm.append('email', useremail);
                  this.authService.removeUser(removeuserForm, this.user).subscribe(updateduserdata => {
                    console.log('add user data set returned is' + JSON.stringify(updateduserdata));
                    this.usersadded = updateduserdata.user;
                    console.log('new users set to' + JSON.stringify( this.usersadded ));
                   }
                    , (err) => {
                     console.error(err); });
                   }

                 public inviteUser(teamitemid, teamname, useremail){
                  console.log('inviting a team mgr');
                  
                  const inviteuserForm = new FormData();
                  this.datenow = Date.now();
                  inviteuserForm.append('addedon', this.datenow);
                  
                  inviteuserForm.append('teamitemid', teamitemid);
                  inviteuserForm.append('teamname', teamname);
                  inviteuserForm.append('email', useremail);
                 
                  this.authService.inviteUser(inviteuserForm, this.user).subscribe(updateduserdata => {
                    console.log('add user data set returned is' + JSON.stringify(updateduserdata));
                    this.usersinvited = updateduserdata.inv;
                    console.log('invited users' + JSON.stringify( this.usersinvited ));
                   }
                    , (err) => {
                     console.error(err); });
  }

  public resetPword(resetid, username, companyid, password){
    console.log('resetting pword');
    
    const resetForm = new FormData();
   
    
    resetForm.append('username', username);
    resetForm.append('resetid', resetid);
    resetForm.append('companyid', companyid);
    resetForm.append('password', password);
   
    this.authService.resetPword(resetForm).subscribe(resetpword => {
      console.log('reset pwrod results' + JSON.stringify(resetpword));
      this.router.navigateByUrl('/toolbard/home');
    
     }
      , (err) => {
       console.error(err); });
     }

     public sendpwordEmail(username){
      console.log('sending pword email');
      
      const sendpwordForm = new FormData();
     
      
      sendpwordForm.append('username', username);
     
     
      this.authService.sendpwordEmail(sendpwordForm).subscribe(emailresult => {
        console.log('emailresult ' + JSON.stringify(emailresult));
      
       }
        , (err) => {
         console.error(err); });
       }

  

               public changeteamcolor(teamitemid, color){
                const changeteamcolorForm = new FormData();
                
                changeteamcolorForm.append('teamitemid', teamitemid);
                changeteamcolorForm.append('color', color);
                this.authService.changeteamcolor(changeteamcolorForm, this.user).subscribe(changeteamcolordata => {
                  console.log('add user data set returned is' + JSON.stringify(changeteamcolordata));
                  this.color = changeteamcolordata.color;
                
                 }
                  , (err) => {
                   console.error(err); });
                 }
  
            
              


             

              public addtoProject(task){

              }

              public saveDuration(task){

              }

              public addGroup(group){

              }

              public createProject(projectName){

              }



              public removeTask(projectName){

              }

              public deleteProject(projectName){

              }

              

              chipIt(){
                
                if(this.currentrow.type=='team'){this.path.push(this.currentrow.teamn)}
                if(this.currentrow.type=='fold'){this.path.push(this.currentrow.fn)}
                if(this.currentrow.type=='Task'){ this.chipitem = this.currentrow.tn}
                if(this.currentrow.type=='Qaa'){this.chipitem = this.currentrow.qn}
                if(this.currentrow.type=='TaskSet'){this.chipitem = this.currentrow.tsn}
                if(this.currentrow.type=='Project'){this.chipitem = this.currentrow.projn}
                this.path.splice(this.currentlevel-1);
                console.log('finished chipping it');
           
              }

              scroll(el: HTMLElement) {
                console.log('sorting out the scroll view now')
               console.log('this is the width'+el.scrollWidth);
               console.log('this is the x position'+el.scrollWidth);
               el.scrollTo(el.scrollWidth, 0 )
               console.log('this is the width after scrolling'+el.scrollWidth);
              }

              //this is the new toggleset

              settoggle(){
                this.setlocked = !this.setlocked;
                /*
               if(this.setlocked){this.setactive = true;
                
              console.log('set is now locked which means active set is true')
              }
               if(!this.setlocked){this.setactive = false;
                console.log('set is now unlocked which means active set is false')
              }
              */
              }
/*
              toggleSet(set, toggleb, chipitem) {
               // console.log('taskset is now'+this.lockFC.value);
               this.activeset = set;
               console.log('now toggling for'+set);
                this.setlocked = toggleb
               if  (this.setlocked){this.browserspan = 5} else {this.browserspan = 10;}

                console.log('is this true or false'+this.setlocked);
               // this.configSvc.editprojflow = !this.lockFC.value
               console.log('chip item working on'+chipitem);
                if (set=='taskset') {
                  this.tasksetactive = true
                  this.lockedchipitem = chipitem;}
                if (set=='project') {
                this.projectactive = true
                 this.lockedchipitem = chipitem;}
              } 
              */

              removeuserfromTeam(){
                console.log('remove user from team');
              }

              addUsertoTeam(currentuser){
                console.log('adding user to team'+JSON.stringify(currentuser));
                this.usersadded.push(currentuser)
                console.log('users selected array'+JSON.stringify(this.usersadded));
              }

         

                                
                 public renameFolder(newfoldername, newdesc) {
                
                   const renamefolderForm = new FormData();
                 
                   renamefolderForm.append('newfoldername', newfoldername);
                   renamefolderForm.append('newdesc', newdesc);
                   renamefolderForm.append('folderid', this.activeitemid);
                   renamefolderForm.append('level', this.activelevel.toString());
                   renamefolderForm.append('type', this.activetype);
                   renamefolderForm.append('parentid', this.folderdetailsds.parentid);
               this.authService.renameFolder(renamefolderForm, this.user).subscribe(folderresults => {
               console.log('this is the results from renaming a folder: ' + JSON.stringify(folderresults));
                this.pathds= folderresults;
                this.teamds = null;
              }, (err) => {console.error(err); });
             }

             public getFolderDetails(itemid, type, teamid, quickadd)  {
              console.log('getting details now for '+itemid);
              console.log('getting type now for '+type);
              console.log('getting teamid now for '+teamid);
              this.gettingfolder = true;
              console.log('getting folder is set to '+this.gettingfolder );
              const getFolderDetailsForm = new FormData();
              getFolderDetailsForm.append('itemid', itemid);
              getFolderDetailsForm.append('type', type);
              if(type== 'team'){
                console.log('this type is team so setting teamid to itemid'+type);
                teamid = itemid}
              getFolderDetailsForm.append('teamid', teamid);

              if(quickadd == true){getFolderDetailsForm.append('quickadd', 'true');}
              if(quickadd == false){getFolderDetailsForm.append('quickadd', 'false');}
              this.folderready = false; this.pathready = false;

          
              this.authService.getFolderDetails(getFolderDetailsForm, this.user).subscribe(folderdetailsresults => {
                console.log('this is the results from getFolderDetails: ' + JSON.stringify(folderdetailsresults));

               if (type == 'team'){
                 this.activecolor = folderdetailsresults.clr;
                 this.fullpage = false;
               }
               if (type == 'fold' || type == 'nodetab' ){
       
                this.fullpage = true;
                
              }
                 this.folderdetailsds= folderdetailsresults;

               

                 if(this.folderdetailsds.type == 'smartfoldertab' ){
                  this.foldertabsds[0] = this.folderdetailsds
              
                 }

                 if(this.folderdetailsds.locktoolbar ){
                  this.toolmenu = 'toolbartools'; console.log('active toolmenu is set to toolbartools')
                  this.favtools = false
              this.getToolbars('nodeteam', this.folderdetailsds.toolbarid, 'nodeteam')
                 }
                 if(!this.folderdetailsds.locktoolbar ){
                  this.toolmenu = 'general';
                 }



               

                 

               //  this.folderstepitems= folderdetailsresults.stepitems;
                // console.log('this is the folderstepitems: '+JSON.stringify(this.folderstepitems))
                 this.folderready = true;
                 console.log('folder is noew ready to be displayed')
                 this.folderpath = folderdetailsresults.path
                 console.log('folderpath is '+JSON.stringify(this.folderpath))
               // this.currentrow = folderdetailsresults;
               this.pathready = true;
                console.log('get team details current row is '+JSON)
                this.usersadded = folderdetailsresults.mbr;

                if(this.folderdetailsds.kanbantagged && folderdetailsresults)
                {
                  console.log('this is a kanban folder');
                  this.activekanban = this.folderdetailsds.kanbanprops
                  console.log('this is this kanban'+JSON.stringify(this.activekanban));
                  this.kanbanready = true;
               //   this.kanbanopts = this.folderdetailsds.itemcnt*this.folderdetailsds.kanbantotal
               //   console.log('this is this kanbanopts'+this.kanbanopts);
                }
             
                

               }, (err) => {console.error(err); });
   

               
}

public findSmartfolderitems(restrict,toolindex, toolinval,  tool?)  {

this.smartfolderitemsready = false
  const smartfolderitemForm = new FormData();
  smartfolderitemForm.append('restrict', restrict.toString());
  smartfolderitemForm.append('toolindex', toolindex);
  smartfolderitemForm.append('toolinval', toolinval);
  if(restrict == 'true'){
    smartfolderitemForm.append('tool', tool);
  }




  this.authService.findSmartfolderitems(smartfolderitemForm, this.user).subscribe(smartfolderitemresults => {
    console.log('this is the results from findSmartfolderitems: ' + JSON.stringify(smartfolderitemresults));

    
     this.smartfolderitems =smartfolderitemresults
     this.smartfolderitemsready = true
    
   }, (err) => {console.error(err); });


   
}

public getCategoryDetails(itemid, type, teamid)  {

  this.categoryready= false;
  const getCategoryDetailsForm = new FormData();
  getCategoryDetailsForm.append('itemid', itemid);
  getCategoryDetailsForm.append('type', type);
  if(type== 'team'){
    console.log('this type is team so setting teamid to itemid'+type);
    teamid = itemid}
    getCategoryDetailsForm.append('teamid', teamid);



  this.authService.getCategoryDetails(getCategoryDetailsForm, this.user).subscribe(categorydetailsresults => {
    console.log('this is the results from getCategoryDetails: ' + JSON.stringify(categorydetailsresults));

     this.activecolor = categorydetailsresults.clr;
  
     this.categorydetailsds= categorydetailsresults;
     this.folderpath = categorydetailsresults.path
     console.log('node folderpath is '+JSON.stringify(this.folderpath))
     this.pathready = true;this.categoryready= true;
    
   }, (err) => {console.error(err); });


   
}

public getFlowDetails(itemid, teamid)  {
  this.flowready = false;
  
  const getFlowDetailsForm = new FormData();
  getFlowDetailsForm.append('itemid', itemid);
  getFlowDetailsForm.append('teamid', teamid);



  this.authService.getFlowDetails(getFlowDetailsForm, this.user).subscribe(flowdetailsresults => {
    console.log('this is the results from getFlowDetails: ' + JSON.stringify(flowdetailsresults));

     this.activecolor = flowdetailsresults.clr;
     this.flowdetailsds= [flowdetailsresults];
     this.flowready = true;
  
   }, (err) => {console.error(err); });


   
}

public getKanbanDetails(itemid, type, teamid)  {
  this.gettingkanban = false
  
  const getKanbanDetailsForm = new FormData();
  getKanbanDetailsForm.append('itemid', itemid);
  getKanbanDetailsForm.append('type', type);
  if(type== 'team'){
    console.log('this type is team so setting teamid to itemid'+type);
    teamid = itemid}
    getKanbanDetailsForm.append('teamid', teamid);



  this.authService.getKanbanDetails(getKanbanDetailsForm, this.user).subscribe(kanbandetailsresults => {
    console.log('this is the results from getKanbanDetails: ' + JSON.stringify(kanbandetailsresults));

    // this.activecolor = kanbandetailsresults.clr;
  
     this.kanbandetailsds[0]= kanbandetailsresults;
     this.gettingkanban = true;
  //  this.usersadded = kanbandetailsresults.mbr;
   }, (err) => {console.error(err); });


   
  }



              public getTeamDetails(teamid, type)  {
                this.teamdetailsready = false; this.pathready = false;
                console.log('getting team details now for '+teamid);
                this.gettingteam = true;
                console.log('getting team is set to '+this.gettingteam );
                this.usersadded = [];this.finduserdataset = [];this.highlightedUserRow = [];
                const getFolderDetailsForm = new FormData();
                getFolderDetailsForm.append('teamid', teamid);
                getFolderDetailsForm.append('folderid', 'root');
              
                this.authService.getTeamDetails(getFolderDetailsForm, this.user).subscribe(teamdetailresults => {
                  console.log('this is the results from getTeamDetails: ' + JSON.stringify(teamdetailresults));
               
                   this.activecolor = teamdetailresults[0].clr;
                   this.teamdetailsds = teamdetailresults[0];
                  this.access = teamdetailresults[1].access
                    this.activetype = 'editteam';
                 //   for (let i = 0; i < this.teamdetailsds.mbr.length; i++) {
                 //   this.usersadded.push(this.teamdetailsds.mbr[i])
               //   console.log('finished pushing mbr into users added '+JSON.stringify(this.usersadded))}
               this.usersadded = this.teamdetailsds.users
               console.log('usersadded is '+JSON.stringify(this.usersadded))
               this.mgrsadded = this.teamdetailsds.mgr
               console.log('mgrsadded is '+JSON.stringify(this.mgrsadded))
               this.usersinvited = this.teamdetailsds.inv
               console.log('usersinvited is '+JSON.stringify(this.usersinvited))

             //     if (this.teamdetailsds.tagtmpl== true && this.activetype == 'editteam'){
                  
                //    this.getTagtemplate(this.teamdetailsds._id, 'team') }
//
                    if (this.teamdetailsds.tagged== true && this.activetype == 'editteam'){
                      console.log('getting tags because active type is edit team and team details is tagged')
                   // this.getTags(this.activetype, this.teamdetailsds._id)
                  }
               
                  this.activetype = type;
                 //  this.teamdetailsds = teamdetailresults;
                 
                 this.folderpath = teamdetailresults[0].path
                 console.log('folderpath is '+JSON.stringify(this.folderpath))
                 this.pathready = true; this.nodeready = true
                 this.teamdetailsready = true; }, (err) => {console.error(err); });
     
}

public getNodeTeamDetails(teamid, type)  {
  console.log('getting nodeteam details now for '+teamid);
  this.nodedetailsready = false;
 
  this.usersadded = [];this.finduserdataset = [];this.highlightedUserRow = [];
  const getNodeTeamDetailsForm = new FormData();
  getNodeTeamDetailsForm.append('teamid', teamid);

  this.authService.getNodeTeamDetails(getNodeTeamDetailsForm, this.user).subscribe(nodeteamdetailresults => {
    console.log('this is the results from getNodeteamDetails: ' + JSON.stringify(nodeteamdetailresults));
    this.nodeteamdetailsds = [];
    // this.activecolor = nodeteamdetailresults.clr;
     this.nodeteamdetailsds = nodeteamdetailresults;
     console.log('this is the team color: '+this.nodeteamdetailsds[0].clr)
    
     this.usersadded = this.nodeteamdetailsds[0].users
     console.log('usersadded is '+JSON.stringify(this.usersadded))
     this.mgrsadded = this.nodeteamdetailsds[0].mgr
     console.log('mgrsadded is '+JSON.stringify(this.mgrsadded))
    console.log('this is the nodeteamdetailsds values: '+JSON.stringify(this.nodeteamdetailsds))
    this.folderpath = this.nodeteamdetailsds[0].path
    console.log('node folderpath is '+JSON.stringify(this.folderpath))
    this.pathready = true;
    this.nodedetailsready = true;
    console.log('nodedetailsready is set to'+this.nodedetailsready);
    
   }, (err) => {console.error(err); });

  }

  public updateTagitems(nodeid, itemid, updatedproperties, type, stepperid?){
    console.log('updating tagitems');
    const updateTagitemsForm = new FormData();
    updateTagitemsForm.append('nodeid', nodeid);
   
    updateTagitemsForm.append('itemid', itemid);
    updateTagitemsForm.append('updatedproperties', updatedproperties);
    updateTagitemsForm.append('type', type);

    if(type == 'nodetab'){
      updateTagitemsForm.append('stepperid', stepperid);
    }
  
    this.authService.updateTagitems(updateTagitemsForm, this.user).subscribe(updatetagitemresults => {
      console.log('this is the results from updatetagitemresults: ' + JSON.stringify(updatetagitemresults));
    
    
       this.nodeteamds = updatetagitemresults.stepitems
       console.log('this is the tag stepitems in nodeteamds: '+JSON.stringify(this.nodeteamds))
   
      
     }, (err) => {console.error(err); });
  
    }

    public deleteTagitem(nodeid, itemid){
      console.log('deleting tagitem');
      const deleteTagitemForm = new FormData();
      deleteTagitemForm.append('nodeid', nodeid);
      deleteTagitemForm.append('itemid', itemid);

    
      this.authService.deleteTagitem(deleteTagitemForm, this.user).subscribe(deletetagitemresults => {
        console.log('this is the result from deleting tagitem: ' + JSON.stringify(deletetagitemresults));
      
      
         this.nodeteamds = deletetagitemresults.stepitems
         console.log('this is the tag stepitems in nodeteamds: '+JSON.stringify(this.nodeteamds))
     
        
       }, (err) => {console.error(err); });
    
      }

      public deleteStep(nodeid, stepid){
        console.log('deleting step');
        const deleteStepForm = new FormData();
        deleteStepForm.append('nodeid', nodeid);
        deleteStepForm.append('stepid', stepid);
  
      
        this.authService.deleteStep(deleteStepForm, this.user).subscribe(deletestepresults => {
          console.log('this is the result from deleting Step: ' + JSON.stringify(deletestepresults));
        
        
           this.nodeteamds = deletestepresults.stepper
           console.log('this is the stepper nodeteamds: '+JSON.stringify(this.nodeteamds))
       
          
         }, (err) => {console.error(err); });
      
        }

  

  public getNodeDetails(nodeid)  {
    console.log('getting node details now for '+nodeid);
    // this.gettingnode = true;
  //  console.log('getting team is set to '+this.gettingteam );
   // this.usersadded = [];this.finduserdataset = [];this.highlightedUserRow = [];
    const getNodeDetailsForm = new FormData();
    getNodeDetailsForm.append('nodeid', nodeid);
  
    this.authService.getNodeDetails(getNodeDetailsForm, this.user).subscribe(nodedetailresults => {
      console.log('this is the results from getNodeDetails: ' + JSON.stringify(nodedetailresults));
    
       
       this.hlnode[0] = nodedetailresults;
       this.nodeteamds = nodedetailresults.stepitems
       console.log('this is the tag stepitems in nodeteamds: '+JSON.stringify(this.nodeteamds))
      this.nodedetailsready = true;
      
      this.toolmenu = 'tagtools'
      console.log('toolmenu set to tagtools and nodedetails are ready')
      
     }, (err) => {console.error(err); });
  
    }

public refreshFolder(parentfolderid, type)  {
                  console.log('refreshing folder');
                  const refreshFolderForm = new FormData();
                  refreshFolderForm.append('folderid', parentfolderid);
                  refreshFolderForm.append('type', type);
                //  this.folderdetailsds= null;
                  this.authService.refreshFolder(refreshFolderForm, this.user).subscribe(refreshfolderresults => {
                    console.log('this is the results from refreshfolder: ' + JSON.stringify(refreshfolderresults));
                  
                     this.teamds = refreshfolderresults;
                     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 

                     if(this.nodeds[0].nodekanbantagged && refreshfolderresults)
                     {
                       console.log('this is a kanban folder');
                       this.activekanban = this.nodeds[0].kanbanprops
                       console.log('this is this kanban'+JSON.stringify(this.activekanban));
                       this.kanbanready = true;
               
                     }
                    
                   }, (err) => {console.error(err); });
       
}

public ratePrompt(parentid, itemid, rating)  {
  console.log('rating item');
  const rateitemForm = new FormData();
  rateitemForm.append('process', 'rateprompt');
  rateitemForm.append('ischatgpt', 'true');
  rateitemForm.append('parentid', parentid);
  rateitemForm.append('itemid', itemid);
  rateitemForm.append('rating', rating);
//  this.folderdetailsds= null;
  this.authService.ratePrompt(rateitemForm, this.user).subscribe(rateitemresults => {
    console.log('this is the results from rating item: ' + JSON.stringify(rateitemresults));
  
     this.teamds = rateitemresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.chatgptresponse = false;
    
    
   }, (err) => {console.error(err); });
}

                  /*
                  public applyTemplate(props, type, itemid, tabvalue ) {

                    console.log('this is the props im adding'+props)
                    console.log('this is the type im adding'+type)
                    const templateForm = new FormData();
                   
                    templateForm.append('props', JSON.stringify(props));
                    templateForm.append('type', type);
                    templateForm.append('taggedby', type);
                   
                    templateForm.append('itemid', itemid);
                    templateForm.append('tabvalue', tabvalue);
                   
              
                    this.authService.applyTemplate(templateForm, this.user).subscribe(templatedata => {
                     if(type == 'fold'){ this.folderdetailsds = templatedata;
                      console.log('applying template and current folder details is now" '+JSON.stringify(this.folderdetailsds));}
                     if(type == 'team'){ this.teamdetailsds = templatedata;
                      console.log('applying template and current team details is now" '+JSON.stringify(this.teamdetailsds));}

                      console.log('after applying tag template getting tags');
                      this.getTags(this.activetype, this.activeitemid)
                    }
                     , (err) => {
                      console.error(err); });
                    }
                    */

                    //public applyKanban(activeitemid, activetype, kanbansetid, nodename, icontype, iconcolor ) {
 public applyKanban(activetype, folderid,  kanbanid, kanbanname, kanbancolor, quickadd, settotal, kantype, kanbantotal) {

                     
                      const applyKanbanform = new FormData();
                     
                      /*
                      applyKanbanform.append('itemid', activeitemid);
                      applyKanbanform.append('kanbansetid', kanbansetid);
                      applyKanbanform.append('type', activetype);
                      applyKanbanform.append('apply', 'true');
                      applyKanbanform.append('nodename', nodename);
                      applyKanbanform.append('icontype', icontype);
                      applyKanbanform.append('iconcolor', iconcolor);
                     */

                    applyKanbanform.append('itemid', folderid);
                    applyKanbanform.append('folderid', folderid);
                    applyKanbanform.append('kanbanid', kanbanid);
                    applyKanbanform.append('type', activetype);
                    applyKanbanform.append('kanbanname', kanbanname);
                    applyKanbanform.append('kanbancolor', kanbancolor);
                    applyKanbanform.append('kanbantagged', 'true');
                    applyKanbanform.append('quickadd', quickadd);
                    applyKanbanform.append('settotal', settotal);
                    applyKanbanform.append('kantype', kantype);
                    applyKanbanform.append('kanbantotal', kanbantotal);

                    
                
                      this.authService.applyKanban(applyKanbanform, this.user).subscribe(kanbandata => {
                       if(activetype == 'fold' && quickadd == false){ 
                         
                        this.folderdetailsds = kanbandata;
                        console.log('applying template and current folder details is now" '+JSON.stringify(this.folderdetailsds));}
                       this.setActivetab('fold')
                       if(activetype == 'fold' && quickadd == true){
                       this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
                       console.log('this is the refreshed folder results after applying kanbans via quickadd'+JSON.stringify(this.teamds))
                       }
                        if(activetype == 'team'){ this.teamdetailsds = kanbandata;
                        console.log('applying template and current team details is now" '+JSON.stringify(this.teamdetailsds));}
                      
                        
                       //  this.getKanban(this.activetype, this.activeitemid)
                      }
                       , (err) => {
                        console.error(err); });
}

public removeKanban(activetype, folderid, quickadd) {

                     
  const removeKanbanform = new FormData();
  removeKanbanform.append('process', 'removekanban');
  removeKanbanform.append('folderid', folderid);
  removeKanbanform.append('itemid', folderid);
  removeKanbanform.append('type', activetype);

  this.authService.removeKanban(removeKanbanform, this.user).subscribe(kanbandata => {
   if(activetype == 'fold' && quickadd == false){ 
     
    this.folderdetailsds = kanbandata;
    console.log('applying template and current folder details is now" '+JSON.stringify(this.folderdetailsds));}
   this.setActivetab('fold')
   if(activetype == 'fold' && quickadd == true){
   this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
   console.log('this is the refreshed folder results after applying kanbans via quickadd'+JSON.stringify(this.teamds))
   }
    if(activetype == 'team'){ this.teamdetailsds = kanbandata;
    console.log('applying template and current team details is now" '+JSON.stringify(this.teamdetailsds));}
  
    
   //  this.getKanban(this.activetype, this.activeitemid)
  }
   , (err) => {
    console.error(err); });
}

 public updateFoldertags(newprops,  type,  itemid) {

                      console.log('this is the props im updating'+newprops)
                      console.log('this is the type im adding'+type)
                      console.log('folderid is '+this.activeitemid)
                      this.datenow = Date.now();
                      
                      const updatetagsForm = new FormData();

                    
                     
                      updatetagsForm.append('newprops', JSON.stringify(newprops));
                      updatetagsForm.append('type', type);
                     updatetagsForm.append('itemid', itemid);
                     updatetagsForm.append('folderid', this.currentfolder);
                     updatetagsForm.append('mo', this.datenow);
                   
                
                      this.authService.updateFoldertags(updatetagsForm, this.user).subscribe(updateddata => {
                       if(type == 'fold'){ 
                          this.folderdetailsds = updateddata;
                        console.log('updating tags and current folder details is now" '+JSON.stringify(this.folderdetailsds));}
                      

                      if(type == 'node' || type == 'file'){ 
                         this.teamds = updateddata;
                         this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
                         console.log('updating node tags and current teamds is now" '+JSON.stringify(this.teamds));}

                       
                     
                         if(type == 'team'){ this.teamdetailsds = updateddata;
                        console.log('updating tags and current team details is now" '+JSON.stringify(this.teamdetailsds));}
  
                      }
                       , (err) => {
                        console.error(err); });
}

public updateNodetags(newprops,  type,  itemid, total?) {

  console.log('this is the props im updating'+newprops)
  console.log('this is the type im adding'+type)
  console.log('folderid is '+this.activeitemid)
  this.datenow = Date.now();
  
  const updatetagsForm = new FormData();


 
  updatetagsForm.append('newprops', JSON.stringify(newprops));
  updatetagsForm.append('type', type);
 updatetagsForm.append('itemid', itemid);
 updatetagsForm.append('folderid', this.folderdetailsds._id);
 updatetagsForm.append('mo', this.datenow);
 updatetagsForm.append('newstate', this.nodeds[0].state);
 if(this.folderdetailsds.kanbantagged && this.folderdetailsds.kantype == 'moneydollars'){
 updatetagsForm.append('total', total);}
 

  this.authService.updateNodetags(updatetagsForm, this.user).subscribe(updateddata => {

     this.teamds = updateddata;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     console.log('updating node tags and current teamds is now" '+JSON.stringify(this.teamds))


  }
   , (err) => {
    console.error(err); });
}

public lockToolbar(folderid, toolbarid, toolbarname, toolbardesc, toolbaricon, toolbarcolor ) {



  this.datenow = Date.now();
  
  const locktoolbarForm = new FormData();
  locktoolbarForm.append('process', 'locktoolbar');
  locktoolbarForm.append('locktoolbar', 'true');
 
  locktoolbarForm.append('itemid', folderid);
  locktoolbarForm.append('toolbarid', toolbarid);
  locktoolbarForm.append('toolbarname', toolbarname);
  locktoolbarForm.append('toolbardesc', toolbardesc);
  locktoolbarForm.append('toolbaricon', toolbaricon);
  locktoolbarForm.append('toolbarclr', toolbarcolor);
 
 

  this.authService.lockToolbar(locktoolbarForm, this.user).subscribe(toolbarfolder => {

     this.teamds = toolbarfolder;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     console.log('applying toolbar to folder and current teamds is now" '+JSON.stringify(this.teamds))


  }
   , (err) => {
    console.error(err); });
}

public unlockToolbar(folderid, toolbarid ) {



  this.datenow = Date.now();
  
  const locktoolbarForm = new FormData();
  locktoolbarForm.append('process', 'locktoolbar');
  locktoolbarForm.append('locktoolbar', 'false');
 
  locktoolbarForm.append('itemid', folderid);
  locktoolbarForm.append('toolbarid', toolbarid);
 
 
 

  this.authService.unlockToolbar(locktoolbarForm, this.user).subscribe(toolbarfolder => {

     this.teamds = toolbarfolder;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     console.log('applying toolbar to folder and current teamds is now" '+JSON.stringify(this.teamds))


  }
   , (err) => {
    console.error(err); });
}

                      

                      public updateNodeimage(newprops, type, itemid, newimage, version, folderid, vk) {

                        console.log('this is the props im updating'+newprops)
                        console.log('this is the type im adding'+type)
                        this.datenow = Date.now();
                        
                        const updatetagsForm = new FormData();
                       
                          updatetagsForm.append('upload1',   newimage);
                        
                        updatetagsForm.append('stepitems', JSON.stringify(newprops));
                        updatetagsForm.append('type', type);
                       updatetagsForm.append('itemid', itemid);
                       updatetagsForm.append('nodeimageid', this.nodeds[0]._id);
                       updatetagsForm.append('version', version);
                       updatetagsForm.append('addedon', this.datenow);
                       updatetagsForm.append('folderid', folderid);
                       updatetagsForm.append('indextype', '3');
                       updatetagsForm.append('vk', vk);
                       updatetagsForm.append('version', this.nodeds[0].ver);
                  
                        this.authService.updateNodeimage(updatetagsForm, this.user).subscribe(updatenodeimage => {
                       
                        
                           this.nodeds = updatenodeimage;
                           console.log('updating node tags and current teamds is now" '+JSON.stringify(this.teamds));
  
                        }
                         , (err) => {
                          console.error(err); });
                        }
/*
                    getTagtemplate(activeitemid, type){

                      // this.currtagtempl = selectedFolder;
                      this.currtagtempl = null;
                      console.log('current getTagtemplate selected is: '+JSON.stringify(this.currtagtempl));
                     
                      const formData = new FormData();
                         
                            formData.append('itemid', activeitemid);
                          
                       this.authService.getTagtemplate(formData, this.user).subscribe(tagtemplateresult => {
                            
                            this.currtagtempl = tagtemplateresult;
                            console.log('current tag template is '+JSON.stringify(this.currtagtempl));
                            if (this.currtagtempl.length <1){
                              this.tagtemplactive  = false;
                            }
                            else{
                            this.tagtemplactive = true;}
                            console.log('tagtemplactive is '+this.tagtemplactive);
                            console.log('current type for tagtemplate is '+type);

                            if(type == 'team'){this.activetype = 'editteam';}
                            if(type == 'Form'){this.activetype = 'Form';}
                            if(type == 'fold'){this.activetype = 'fold';}
                      }, (err) => {console.error(err); });} 
                      */

                      getTags(activetype, activeitemid){
                        this.tagsactive = false;
                     //   this.tageritemready = false; 
                    //    console.log('tageritemready is now falsw '+ this.tageritemready);
                    //    this.activetype = 'tageritem';
                       
                    
                        // this.currtagtempl = selectedFolder;
                        console.log('current item selected is: '+JSON.stringify(activeitemid));
                       
                        const tagsForm = new FormData();
                         tagsForm.append('itemid', activeitemid);
                          tagsForm.append('type', activetype);
                            
                         this.authService.getTags(tagsForm, this.user).subscribe(tagsresult => {
                              
                              this.currtags = tagsresult;
                            //  this.tageritemready = true;
                              console.log('current tags are '+JSON.stringify(this.currtags));
                            //  console.log('current tag template is '+JSON.stringify(this.currtagtempl));
                              if (this.currtags.length <1){
                                this.tagsactive = false;
                              }
                              else{
                              this.tagsactive = true;}
                             // console.log('tagtemplactive is '+this.tagtemplactive);
                              console.log('tagsactive is '+this.tagsactive);
                          
                        
                        }, (err) => {console.error(err); });} 

                     

                        getKanban(activetype, activeitemid){
                          this.kanbanactive = false;
                      
                          // this.currtagtempl = selectedFolder;
                          console.log('current item selected is: '+JSON.stringify(activeitemid));
                         
                          const kanbanForm = new FormData();
                          kanbanForm.append('folderid', activeitemid);
                          kanbanForm.append('type', activetype);
                              
                           this.authService.getKanban(kanbanForm, this.user).subscribe(kanbanresult => {
                                
                                this.currkanban = kanbanresult;
                                
                                console.log('results from getting kanban '+JSON.stringify(this.currkanban));
                              //  console.log('current tag template is '+JSON.stringify(this.currtagtempl));
                                if (this.currkanban.length <1){
                                  this.kanbanactive = false;
                                  console.log('kanbanactive is '+this.kanbanactive);
                                }
                                else{
                                this.kanbanactive = true;}
                               // console.log('tagtemplactive is '+this.tagtemplactive);
                                console.log('kanbanactive is '+this.kanbanactive);
                            
                          
                          }, (err) => {console.error(err); });} 
                  
                  
                          getKanbantemplate(kanbansetid, type){

                            // this.currtagtempl = selectedFolder;
                            this.currkanbantempl = null;
                            console.log('current getKanbantemplate selected is: '+JSON.stringify(this.currkanbantempl));
                           
                            const formData = new FormData();
                               
                                  formData.append('kanbansetid', kanbansetid);
                                
                             this.authService.getKanbantemplate(formData, this.user).subscribe(kanbantemplateresult => {
                                  
                                  this.currkanbantempl = kanbantemplateresult;
                                  console.log('current kanban template results '+JSON.stringify(this.currkanbantempl));
                                  if (this.currkanbantempl.length <1){
                                    this.kanbantemplactive  = false;
                                  }
                                  else{
                                  this.kanbantemplactive = true;}
                                  console.log('kanban templactive is '+this.kanbantemplactive);
                              
              
                   }, (err) => {console.error(err); });} 


 public getComments(iic){
          const formData = new FormData();
          formData.append('iic', iic);
          this.authService.getFileComments(formData, this.user).subscribe(commentresult => {
          this.commentsds = commentresult;
          }, (err) => {console.error(err); });} 
    

    public deleteFile(itemid, folderid){
      const deletefileForm = new FormData();
      deletefileForm.append('itemid', itemid);
      deletefileForm.append('folderid', folderid);
      this.authService.deleteFile(deletefileForm, this.user).subscribe(deletefileresult => {
      this.teamds = deletefileresult;
      this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
      }, (err) => {console.error(err); });} 

      public deleteDesign(itemid, parentid){
        const deletedesignForm = new FormData();
        
        deletedesignForm.append('itemid', itemid);
        deletedesignForm.append('parentid', parentid);
       
        this.authService.deleteDesign(deletedesignForm, this.user).subscribe(deletedesignresult => {
        this.teamds = deletedesignresult;
        this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
        }, (err) => {console.error(err); });} 
                      


public deleteFolder(folderid, parentid, teamid){
  const deletefolderForm = new FormData();
  deletefolderForm.append('folderid', folderid);
  deletefolderForm.append('parentid', parentid);
  this.authService.deleteFolder(deletefolderForm, this.user).subscribe(deletefolderresult => {
  this.teamds = deletefolderresult;
  this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
//  this.getpath(parentid, teamid)
  }, (err) => {console.error(err); });} 

  public deleteItem(itemid, folderid, teamid, kanbantagged,activetrack?){
    const deletefolderForm = new FormData();
    deletefolderForm.append('itemid', itemid);
    deletefolderForm.append('folderid', folderid);
    deletefolderForm.append('type', this.activetype);
    deletefolderForm.append('counttotals', this.folderdetailsds.counttotals.toString());
   
    if(kanbantagged){
      
      
      deletefolderForm.append('kanbantagged', 'true');
      deletefolderForm.append('activetrack', activetrack);
      deletefolderForm.append('total', this.nodeds[0].total);
    
    
    }
    if(!kanbantagged){deletefolderForm.append('kanbantagged', 'false');
    }

if(this.folderdetailsds.counttotals){
  deletefolderForm.append('total', this.nodeds[0].total);
}

    this.authService.deleteItem(deletefolderForm, this.user).subscribe(deleteitemresult => {
    this.teamds = deleteitemresult;
    this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    this.folderready = false;
    this.getFolderDetails(folderid, 'fold', teamid, false)
    this.getpath(folderid, teamid)
    }, (err) => {console.error(err); });} 
                  

public updateNode( nodeid, name, desc, type, icontype, iconcolor, pathid, textractable, textractype, ischatgpt, defaultprompt)
{
  const editTagerform = new FormData();
  editTagerform.append('nodeid', nodeid);
  editTagerform.append('name', name);
  editTagerform.append('desc', desc);
  editTagerform.append('type', type);
  editTagerform.append('icontype', icontype);
  editTagerform.append('iconcolor', iconcolor);
  editTagerform.append('pathid', pathid);
  editTagerform.append('textractable', textractable);

  if(textractable){
  editTagerform.append('textractype', textractype);}
  editTagerform.append('ischatgpt', ischatgpt);

  if(ischatgpt){
  editTagerform.append('defaultprompt', defaultprompt);}

  
this.authService.updateNode(editTagerform, this.user).subscribe(updatenoderesults => {
  console.log('this is the results from editing node: ' + JSON.stringify(updatenoderesults));
 // this.getnodepath(this.hlnode[0].parentid, this.hlnode[0].teamid)
}, (err) => {
  console.error(err); });

}

public updateTeam( teamname, teamdesc, teamid)
{
  const updateteamform = new FormData();
  updateteamform.append('teamname', teamname);
  updateteamform.append('teamdesc', teamdesc);
  updateteamform.append('teamid', teamid);


  
this.authService.updateTeam(updateteamform, this.user).subscribe(updateteamresults => {
  console.log('this is the results from editing team: ' + JSON.stringify(updateteamresults));

}, (err) => {
  console.error(err); });

}

public updateStep( nodeid, stepid, step, desc, icontype, iconcolor)
{
  this.datenow = Date.now();
  const updatestepform = new FormData();
  updatestepform.append('nodeid', nodeid);
  updatestepform.append('stepid', stepid);
  updatestepform.append('step', step);
  updatestepform.append('desc', desc);

  updatestepform.append('icontype', icontype);
  updatestepform.append('iconcolor', iconcolor);
  updatestepform.append('mo', this.datenow );
  
 ;

  
this.authService.updateStep(updatestepform, this.user).subscribe(updatenoderesults => {
  console.log('this is the results from editing node: ' + JSON.stringify(updatenoderesults));
 // this.getnodepath(this.hlnode[0].parentid, this.hlnode[0].teamid)
}, (err) => {
  console.error(err); });

}

public updateKanban( kanbanid, name, desc, type, icontype, iconcolor, pathid)
{
  const editTagerform = new FormData();
  editTagerform.append('nodeid', kanbanid);
  editTagerform.append('name', name);
  editTagerform.append('desc', desc);
  editTagerform.append('type', type);
  editTagerform.append('icontype', icontype);
  editTagerform.append('iconcolor', iconcolor);
  editTagerform.append('pathid', pathid);
  
this.authService.updateNode(editTagerform, this.user).subscribe(updatenoderesults => {
  console.log('this is the results from editing node: ' + JSON.stringify(updatenoderesults));
 // this.getnodepath(this.hlnode[0].parentid, this.hlnode[0].teamid)
}, (err) => {
  console.error(err); });

}

createTagitem( createcatag, nodeid, nodetype, nodename, formitem, formtype, haslabel, hasdefault,  rowsize, formitemlabel, checkbox, defaultvalue, icontype, stepid?, itemvalue?, textarea?)
{
  console.log('creatingtagitem for this nodetype '+nodetype)
  this.datenow = Date.now();

  const tagitemForm = new FormData();
  tagitemForm.append('createcatag', createcatag);
  tagitemForm.append('nodeid', nodeid);
  tagitemForm.append('nodetype', nodetype);
  tagitemForm.append('nodename', nodename);
  tagitemForm.append('formitem', formitem);
  tagitemForm.append('formtype', formtype);
  if(formtype == 'boolean' && haslabel){
    tagitemForm.append('itemvalue', itemvalue);
    tagitemForm.append('textarea', textarea);
  }
 // if(formtype != 'boolean' ){tagitemForm.append('textarea', '');}
  tagitemForm.append('label', haslabel);
  tagitemForm.append('rowsize', rowsize);
  tagitemForm.append('formitemlabel', formitemlabel);
  tagitemForm.append('checkbox', checkbox);
   tagitemForm.append('addedon', this.datenow);
  tagitemForm.append('hasdefault', hasdefault);
 
  tagitemForm.append('defaultvalue', defaultvalue);
  tagitemForm.append('icontype', icontype);
  if(nodetype =='wizard' || nodetype =='nodetab' || nodetype =='smartfoldertab' 
   || nodetype =='editstepperassign' || nodetype =='edittabbedassign' ){
    console.log('stepid is '+stepid)
    tagitemForm.append('stepid', stepid)}
  
    this.nodedetailsready = false
 
this.authService.createTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
  console.log('this is the results from createing tags: ' + JSON.stringify(tagitemresults));
  
  this.nodeds = [tagitemresults]
  console.log('this is the nodeds after adding a step: ' + JSON.stringify(this.nodeds));
console.log('nodetype is curently set to '+nodetype)
  if(nodetype =='wizard' || nodetype =='nodetab' || nodetype =='editstepperassign'){
    console.log('active step is set to '+this.activestep)
   this.nodetagsds = tagitemresults.stepper[this.activestep].tags;
  

   console.log('this is the nodetagsds after adding a tag to a step: ' + JSON.stringify(this.nodetagsds));}

   if(nodetype !='wizard' && nodetype !='nodetab' && nodetype !='editstepperassign'){
    this.nodetagsds  = tagitemresults.stepitems;
   
  
    console.log('this is the nodeteamds after adding a step: ' + JSON.stringify(this.nodeteamds));}

   this.nodedetailsready = true;
  
}, (err) => {
  console.error(err); });

}

createListitem(createcatag, nodeid, nodetype, nodename, formitem, formtype, label,  rowsize, formitemlabel, choicesetid, choicesetname, icontype, defaultvalue?, stepid?){
  this.datenow = Date.now();

  const listitemForm = new FormData();
  listitemForm.append('createcatag', createcatag);
  listitemForm.append('nodeid', nodeid);
  listitemForm.append('nodetype', nodetype);
  listitemForm.append('nodename', nodename);
  listitemForm.append('formitem', formitem);
  listitemForm.append('formtype', formtype);
  listitemForm.append('label', label);
  listitemForm.append('rowsize', rowsize);
  listitemForm.append('formitemlabel', formitemlabel);

  listitemForm.append('addedon', this.datenow);
  listitemForm.append('choicesetid', choicesetid);
  listitemForm.append('choicesetname', choicesetname);
  listitemForm.append('icontype', icontype);

  if(formtype == 'radiogroup'){
    listitemForm.append('defaultvalue', defaultvalue);
  }

  if(nodetype =='wizard' || nodetype == 'nodetab' || nodetype == 'smartfoldertab'){
    listitemForm.append('stepid', stepid)}
  
    this.nodedetailsready = false
 
this.authService.createListitem(listitemForm, this.user).subscribe(listitemresults => {
  console.log('this is the results from createing listitem: ' + JSON.stringify(listitemresults));
   this.nodetagsds = listitemresults.stepitems;
   this.nodeds = [listitemresults]
  
  console.log('this is the nodeds after adding a step: ' + JSON.stringify(this.nodeds));
  this.nodedetailsready = true
}, (err) => {
  console.error(err); });


}

getToolbars(type, itemid, teamid){
  if(type == 'roottoolbars') {
    this.roottoolbar = true
  
  }
  else {
    this.roottoolbar = false}

   // this.parenttoolbar = [];
  // this.parenttoolbar.shift();
  /*
  this.parenttoolbar.splice(0,0, {'type': type, 'itemid': itemid, 'teamid':teamid})
  console.log('the parenttoolbar is now set to: '+JSON.stringify(this.parenttoolbar))
  console.log('the parenttoolbar size is '+this.parenttoolbar.length)
  if(this.parenttoolbar.length>2){
    this.parenttoolbar.splice(2);
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.parenttoolbar))
  }
  */
  
  const toolbarForm = new FormData();
  toolbarForm.append('type', type);
  toolbarForm.append('itemid', itemid);
  toolbarForm.append('teamid', teamid);
  
  
 
  this.authService.getToolbars(toolbarForm, this.user).subscribe(toolbarresults => {
    console.log('this is the results from getting toolbars ' + JSON.stringify(toolbarresults));
     this.toolbards = toolbarresults
     
     function isCategory(element) { 
      return (element.type == 'category'|| element.type == 'nodeteam') ; 
   } 
   
   this.toolbards = toolbarresults.filter(isCategory);

     function isNode(element) { 
      return (element.type == 'node' ||element.type == 'nodeimage' ||  element.type == 'foldertags'  || element.type == 'tabbed'
       || element.type == 'kanban'  || element.type == 'wizard' || element.type == 'flow'   || element.type == 'batchitems' 
       || element.type == 'smartfolder'  || element.type == 'smartfolder' || element.type == 'smartfoldertab'
       || element.type == 'basicflow'  || element.type == 'editstepperassign' || element.type == 'nodetab' || element.type == 'edittabbedassign'
       ); 
   } 
  // this.toolbaritems = []
     this.toolbaritems = toolbarresults.filter(isNode)

console.log('toolbaritems' +JSON.stringify(this.toolbaritems))
console.log('activetype is' +this.activetype)
console.log('folderready is set to '+this.folderready)
console.log('favtoolsis set to '+this.favtools)
console.log('toolbards is '+JSON.stringify(this.toolbards))
console.log('toolbaritems is '+JSON.stringify(this.toolbaritems))
if(!this.toolbards.length){
 // console.log('nothing here so close side panel')

 // this.sidenav.close()
 this.sidenavSvc.close()
}
/*
this.activeindexes = [];
if (this.activetype == 'search') {

          for(const index of this.toolbarnodes){
            if (index.type =='wizard'){
              console.log('current stepperstuff is '+JSON.stringify(index.stepper))
              for(const tagitems of index.stepper){
                console.log('current tagitems '+JSON.stringify(tagitems))
                const newtagitems = tagitems.tags
                console.log('new tagitems '+JSON.stringify(newtagitems))
                for(const tags of newtagitems){
                  console.log('current tags '+JSON.stringify(tags))
                  console.log('current tags property '+JSON.stringify(tags))
                  this.activeindexes.push(tags)
                }
                
              }}
      if (index.type =='node'){
        console.log('current nodestuff is '+JSON.stringify(index.stepitems))
        for(const nodeitems of index.stepitems){
        this.activeindexes.push(nodeitems)}}
    }

      console.log('current index list '+JSON.stringify(this.activeindexes))
      
}

*/
    
  }, (err) => {
    console.error(err); });
  
  }

  getfavToolbars(){
  
 
    
    const toolbarForm = new FormData();
    toolbarForm.append('type', 'favtools');
  
    
    
   
    this.authService.getfavToolbars(toolbarForm, this.user).subscribe(favtoolbarresults => {
      console.log('this is the results from getting fav toolbars ' + JSON.stringify(favtoolbarresults));
      this.favtoolset = favtoolbarresults
  console.log('favtoolset' +JSON.stringify(this.favtoolset))
  console.log('activetype is' +this.activetype)
 this.toolmenu = 'favtoolset'
      
    }, (err) => {
      console.error(err); });
    
    }

   

  public saveNode(fileinfolder, quickadd, folderid, teamid,  level, nodeid, 
    nodename, nodedesc,  type,  
    stepitems, icontype, iconcolor,
     attach, textract, indextype, 
     assigned?, 
     assignedto?,  deadline?, deadlineval?, 
     statetracker?: boolean, statelabel?: string, 
     cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atuser?, attachedfile?, total?, textractype?,
     ischatgpt?, defaultprompt?, temp?, responses?, website?, weblink?)
  {
    this.nodetextracted = false
    console.log('assigned is set to '+assigned)
    console.log('assignedto is set to '+assignedto)
    this.datenow = Date.now();
    const savenodeForm = new FormData();
    savenodeForm.append('fileinfolder', fileinfolder.toString());
    var parentfolderid = folderid
    if(!fileinfolder){parentfolderid = 'root'}
    savenodeForm.append('process', 'savenode');
    savenodeForm.append('folderid', parentfolderid);
    savenodeForm.append('batchtype', 'single');
    savenodeForm.append('quickadd', quickadd);
   
    savenodeForm.append('teamid', teamid);
    savenodeForm.append('level', level  );
    savenodeForm.append('type', type);
    savenodeForm.append('nodeid', nodeid);
    savenodeForm.append('nodename', nodename);
    savenodeForm.append('stepitems', stepitems);
    savenodeForm.append('icontype', icontype);
    savenodeForm.append('iconcolor', iconcolor);
    savenodeForm.append('attach', attach);
    savenodeForm.append('total', total);
    savenodeForm.append('counttotals', this.folderdetailsds.counttotals.toString());
   // savenodeForm.append('kanbantagged', this.folderdetailsds.kanbantagged);

    if(this.folderdetailsds.kanbantagged){
      savenodeForm.append('kantype', this.folderdetailsds.kantype);
   
    }

    if(attach){
      savenodeForm.append('upload1', attachedfile[0]);
    }
    savenodeForm.append('textract', textract);
    savenodeForm.append('ischatgpt', ischatgpt);
    if(ischatgpt){
      savenodeForm.append('prompt', defaultprompt);
      savenodeForm.append('temp', temp);
      savenodeForm.append('responses', responses);
    }
    if(textract){
      savenodeForm.append('textractype', textractype);
    }

    savenodeForm.append('nodedesc', nodedesc);
    savenodeForm.append('addedon', this.datenow);
    savenodeForm.append('batchtype','none');
  

    savenodeForm.append('statetracker', statetracker.toString());
    savenodeForm.append('deadline', deadline);
    savenodeForm.append('assigned', assigned);

    if(statetracker) {
      savenodeForm.append('statelabel', statelabel);
    }
    savenodeForm.append('website', website);
    if(website) {
      savenodeForm.append('weblink', weblink);
    }
    if(deadline) {
     // var seconds = deadlineval.getTime() / 1000;
      savenodeForm.append('deadlineval', deadlineval);
    }

    if(assigned) {
      // var seconds = deadlineval.getTime() / 1000;
       savenodeForm.append('assignedto', assignedto);
     }
     console.log('this is the cmt folder value '+cmt)
     savenodeForm.append('cmt', cmt);
          if(cmt){
            savenodeForm.append('prompt', cmtval);
            savenodeForm.append('atcmt', atcmt);
         
            

            if(atcmt){
              savenodeForm.append('cmtuser', atuser);
            }
            
           
            savenodeForm.append('cmticon', cmticon);
            savenodeForm.append('cmtclr', cmtclr);

          }

          if(fileinfolder){
           // savenodeForm.append('fileinfolder', 'true')

    if (this.folderdetailsds.kanbantagged){
      savenodeForm.append('kanbantagged', 'true');
      savenodeForm.append('kanbanid', this.folderdetailsds.kanbanid);
     
      var trackcount = this.folderdetailsds.kanbanprops.length -1

      savenodeForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
      savenodeForm.append('trackcount', trackcount.toString());
      savenodeForm.append('kanbantotal', this.folderdetailsds.kanbantotal.toString());
      savenodeForm.append('kanstate', this.folderdetailsds.kanstate.toString());
      savenodeForm.append('itemcnt', this.folderdetailsds.itemcnt.toString());
    }
   

    if (!this.folderdetailsds.kanbantagged){
      savenodeForm.append('kanbantagged', 'false');
    
    }

   
    if (this.folderdetailsds.tagged){
     // savenodeForm.append('indextype', '1');
    savenodeForm.append('foldertags', JSON.stringify(this.newfoldertags));
    savenodeForm.append('tagcolor', this.folderdetailsds.tagcolor);
  }
          }
          if(!fileinfolder){
            savenodeForm.append('fileinfolder', 'false')}

            savenodeForm.append('indextype', indextype);
            if (indextype == '2'){
          
              savenodeForm.append('foldertags', JSON.stringify(this.newpropvalues));
              savenodeForm.append('nodename', this.teamdetailsds.nodename)
              savenodeForm.append('tagcolor', this.teamdetailsds.tagcolor)
              }

         


    
   
 this.authService.saveNode(savenodeForm, this.user).subscribe(noderesults => {
    console.log('this is the results from saving Node: ' + JSON.stringify(noderesults));

    if(!textract && !ischatgpt){


     this.teamds = noderesults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.csvrowcnt++;
     this.csvprocessed = this.csvrowcnt/this.totalcsvrows*100

     if(this.csvprocessed == 100){
     this.getFolderDetails(folderid, type, teamid, false)} 

     if(this.folderdetailsds.kanbantagged){
      this.getFolderDetails(folderid, type, teamid, false)
     }}

     if(textract || ischatgpt){
       this.nodeds = noderesults
      this.nodetextracted = true

      this.chatgptresponse = true;
      console.log('got a response')
      this.activetype = 'chatgpt'

     }


  }, (err) => {
    console.error(err); });

  }

  public askChatgpt(fileinfolder, quickadd, folderid, teamid,  level, name, icontype, iconcolor, prompt, temp, responses){
    this.datenow = Date.now();
    const askchatgptForm = new FormData();
    askchatgptForm.append('fileinfolder', fileinfolder.toString());
    var parentfolderid = folderid
    if(!fileinfolder){parentfolderid = 'root'}
    askchatgptForm.append('process', 'askchatgpt');
    askchatgptForm.append('type', 'askchatgpt');
    askchatgptForm.append('folderid', parentfolderid);
    askchatgptForm.append('quickadd', quickadd);
    askchatgptForm.append('teamid', teamid);
    askchatgptForm.append('level', level  );
    askchatgptForm.append('name', name);
    askchatgptForm.append('addedon', this.datenow);
    askchatgptForm.append('icontype', icontype);
    askchatgptForm.append('iconcolor', iconcolor);
    askchatgptForm.append('prompt', prompt);
    askchatgptForm.append('ischatgpt', 'true');
    askchatgptForm.append('temp', temp);
    askchatgptForm.append('responses', responses);
   // askchatgptForm.append('summarize', 'true');
    askchatgptForm.append('indextype', '0');
    askchatgptForm.append('kanbantagged', this.folderdetailsds.kanbantagged);
    if (this.folderdetailsds.kanbantagged){
      var trackcount = this.folderdetailsds.kanbanprops.length -1
   
      askchatgptForm.append('kanbanid', this.folderdetailsds.kanbanid);
      askchatgptForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
      askchatgptForm.append('trackcount', trackcount.toString());
      askchatgptForm.append('kanbantotal', this.folderdetailsds.kanbantotal.toString());
      askchatgptForm.append('kanstate', this.folderdetailsds.kanstate.toString());
      askchatgptForm.append('total', '0');
      askchatgptForm.append('kantype', this.folderdetailsds.kantype);
      askchatgptForm.append('kansect', this.folderdetailsds.kansect.toString());

      if (!this.folderdetailsds.kandocs){
        this.kandocs = '0'}
        if (this.folderdetailsds.kandocs){
          this.kandocs = '1'
        
        }
  
 
        askchatgptForm.append('kandocs', this.kandocs);
     
    }
    this.chatgptresponse = false;
this.authService.askChatgpt(askchatgptForm, this.user).subscribe(askchatgptresults => {
console.log('this is the results from askchatgpt: ' + JSON.stringify(askchatgptresults));

//this.response = askchatgptresults
this.nodeds = askchatgptresults;


this.chatgptresponse = true;
console.log('got a response')
this.activetype = 'chatgpt'

if(this.folderdetailsds.kanbantagged){
this.getFolderDetails(folderid, 'fold', teamid, false)
}






}, (err) => {
console.error(err); });

}

public askToolbard(fileinfolder, quickadd, folderid, teamid,  level, name, icontype, iconcolor, prompt, temp, responses){
  this.datenow = Date.now();
  const asktoolbardForm = new FormData();
  asktoolbardForm.append('fileinfolder', fileinfolder.toString());
  var parentfolderid = folderid
  if(!fileinfolder){parentfolderid = 'root'}
  asktoolbardForm.append('process', 'asktoolbard');
  asktoolbardForm.append('type', 'asktoolbard');
  asktoolbardForm.append('folderid', parentfolderid);
  asktoolbardForm.append('quickadd', quickadd);
  asktoolbardForm.append('teamid', teamid);
  asktoolbardForm.append('level', level  );
  asktoolbardForm.append('name', name);
  asktoolbardForm.append('addedon', this.datenow);
  asktoolbardForm.append('icontype', icontype);
  asktoolbardForm.append('iconcolor', iconcolor);
  asktoolbardForm.append('prompt', prompt);
  asktoolbardForm.append('ischatgpt', 'true');
  asktoolbardForm.append('temp', temp);
  asktoolbardForm.append('responses', responses);
 // askchatgptForm.append('summarize', 'true');
 asktoolbardForm.append('indextype', '0');
 asktoolbardForm.append('kanbantagged', this.folderdetailsds.kanbantagged);
  this.chatgptresponse = false;
this.authService.askToolbard(asktoolbardForm, this.user).subscribe(asktoolbardresults => {
console.log('this is the results from askchatgpt: ' + JSON.stringify(asktoolbardresults));

//this.response = askchatgptresults
this.nodeds = asktoolbardresults;


this.chatgptresponse = true;
console.log('got a response')
this.activetype = 'chatgpt'

if(this.folderdetailsds.kanbantagged){
this.getFolderDetails(folderid, 'fold', teamid, false)
}






}, (err) => {
console.error(err); });

}
  

  public saveFormassign(folderid, teamid,  level, nodeid, nodename, nodedesc, type, stepitems, icontype, iconcolor, assignment)
  {
    this.datenow = Date.now();
    const formassignForm = new FormData();
    formassignForm.append('folderid', folderid);
    formassignForm.append('teamid', teamid);
    formassignForm.append('level', level  );
    formassignForm.append('nodeid', nodeid);
    formassignForm.append('nodename', nodename);
    formassignForm.append('type', type);
    formassignForm.append('stepitems', stepitems);
    formassignForm.append('icontype', icontype);
    formassignForm.append('iconcolor', iconcolor);
    formassignForm.append('nodedesc', nodedesc);
    formassignForm.append('addedon', this.datenow);
    formassignForm.append('assignment',assignment);

    if (this.folderdetailsds.kanbantagged){
      formassignForm.append('kanbantagged', 'true');
      formassignForm.append('kanbanid', this.folderdetailsds.kanbanid);
      formassignForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
    }

    if (!this.folderdetailsds.kanbantagged){
      formassignForm.append('kanbantagged', 'false');
    
    }

    if (!this.folderdetailsds.tagged){formassignForm.append('indextype', '0');}
    if (this.folderdetailsds.tagged){formassignForm.append('indextype', '1');
    formassignForm.append('foldertags', JSON.stringify(this.newfoldertags));
  }

  
    
   
 this.authService.saveFormassign(formassignForm, this.user).subscribe(formassignresults => {
    console.log('this is the results from saving formassign: ' + JSON.stringify(formassignresults));
     this.teamds = formassignresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.fullpage = false
    
  }, (err) => {
    console.error(err); });

  }

  public reTriggerflow(folderid, stepitems, assignment, nodeid)
  {
    this.datenow = Date.now();
    const retriggerflowForm = new FormData();
    retriggerflowForm.append('activeitemid', this.nodeds[0].activeflowid);
    retriggerflowForm.append('folderid', folderid);
    retriggerflowForm.append('stepitems', stepitems);
    retriggerflowForm.append('addedon', this.datenow);
    retriggerflowForm.append('assignment',assignment);
    retriggerflowForm.append('nodeid',nodeid);
    retriggerflowForm.append('process','retrigger');

   
 this.authService.reTriggerflow(retriggerflowForm, this.user).subscribe(retriggerresults => {
    console.log('this is the results from  retriggerresults: ' + JSON.stringify(retriggerresults));
     this.teamds = retriggerresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    
  }, (err) => {
    console.error(err); });

  }

  public assignNode(folderid, teamid,  level, nodeid, nodename, nodedesc, type, stepitems, icontype, iconcolor, assignment, statetracker, deadline, statelabel, deadlineval)
  {
    this.datenow = Date.now();
    const formassignForm = new FormData();
    formassignForm.append('folderid', folderid);
    formassignForm.append('teamid', teamid);
    formassignForm.append('level', level  );
    formassignForm.append('nodeid', nodeid);
    formassignForm.append('nodename', nodename);
    formassignForm.append('type', type);
    formassignForm.append('stepitems', stepitems);
    formassignForm.append('icontype', icontype);
    formassignForm.append('iconcolor', iconcolor);
    formassignForm.append('nodedesc', nodedesc);
    formassignForm.append('addedon', this.datenow);
    formassignForm.append('assignment',assignment);

    if (this.folderdetailsds.kanbantagged){
      formassignForm.append('kanbantagged', 'true');
      formassignForm.append('kanbanid', this.folderdetailsds.kanbanid);
      formassignForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
    }
    formassignForm.append('statetracker', statetracker);
    formassignForm.append('deadline', deadline);

    if(statetracker) {
      formassignForm.append('statelabel', statelabel);
    }

    if(deadline) {
      formassignForm.append('deadlineval', deadlineval);
    }

    if (!this.folderdetailsds.kanbantagged){
      formassignForm.append('kanbantagged', 'false');
    
    }

    if (!this.folderdetailsds.tagged){formassignForm.append('indextype', '0');}
    if (this.folderdetailsds.tagged){
      formassignForm.append('indextype', '1');
      formassignForm.append('foldertags', JSON.stringify(this.newfoldertags));
      formassignForm.append('tagcolor', this.folderdetailsds.tagcolor);
  }

  
    
   
 this.authService.assignNode(formassignForm, this.user).subscribe(assignnoderesults => {
    console.log('this is the results from saving formassign: ' + JSON.stringify(assignnoderesults));
     this.teamds = assignnoderesults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.csvrowcnt++;
     this.csvprocessed = this.csvrowcnt/this.totalcsvrows*100

     this.getFolderDetails(folderid, type, teamid, false) 
    
  }, (err) => {
    console.error(err); });

  }

  public saveStepper(fileinfolder, quickadd, folderid, teamid,  level, nodeid, nodename, nodedesc,  type, stepperprops, icontype, iconcolor,
    attach, textract, indextype, 
    assigned?, 
    assignedto?,  deadline?, deadlineval?, 
    statetracker?: boolean,  statelabel?: string, 
    cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atuser?,  attachedfile?, nodetabtagged?, total?,
    textractype?,ischatgpt?, defaultprompt?, temp?, responses?)
  {
    this.datenow = Date.now();
    const savestepperForm = new FormData();
    savestepperForm.append('batchtype', 'single');
    savestepperForm.append('process', 'saveStepper');
    savestepperForm.append('folderid', folderid);
    savestepperForm.append('quickadd', quickadd);
    savestepperForm.append('teamid', teamid);
    savestepperForm.append('level', level  );

    // if(type == 'nodetab')
    // {type = 'tabbed'}
    savestepperForm.append('type', type);
    savestepperForm.append('nodeid', nodeid);
    savestepperForm.append('nodename', nodename);
   
    savestepperForm.append('activestepper', stepperprops);
    savestepperForm.append('icontype', icontype);
    savestepperForm.append('iconcolor', iconcolor);
    savestepperForm.append('nodedesc', nodedesc);
    savestepperForm.append('addedon', this.datenow);
    savestepperForm.append('indextype', indextype);
    savestepperForm.append('total', total);

    if (this.folderdetailsds.kanbantagged){
      var trackcount = this.folderdetailsds.kanbanprops.length -1
      savestepperForm.append('kanbantagged', 'true');
      savestepperForm.append('kanbanid', this.folderdetailsds.kanbanid);
      savestepperForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
      savestepperForm.append('trackcount', trackcount.toString());
      savestepperForm.append('kanbantotal', this.folderdetailsds.kanbantotal.toString());
      savestepperForm.append('kanstate', this.folderdetailsds.kanstate.toString());
      savestepperForm.append('kantype', this.folderdetailsds.kantype);
     
    }

    if (!this.folderdetailsds.kanbantagged){
      savestepperForm.append('kanbantagged', 'false');
    }

    if (nodetabtagged){
      savestepperForm.append('nodetabkanban', 'true');
      savestepperForm.append('nodetabkanbanid', this.nodeds[0].kanbanid);
    //  savestepperForm.append('activetrack', this.nodeds[0].kanbanprops[0].track);
      let kanbanprops: number = this.nodeds[0].kanbanprops.length
      let stepper: number = this.nodeds[0].stepper.length
      let statustotal: number = (kanbanprops-1)*stepper
      console.log('statustotal is: '+ statustotal)
      savestepperForm.append('nodetabtotal', statustotal.toString());
      this.kanbanready = false
    }
    if (!nodetabtagged){
      savestepperForm.append('nodetabkanban', 'false');
     
    }

    savestepperForm.append('attach', attach);

    if(attach){
      savestepperForm.append('upload1', attachedfile[0]);
    }
    savestepperForm.append('textract', textract);
    savestepperForm.append('ischatgpt', ischatgpt);
    if(ischatgpt){
      savestepperForm.append('prompt', defaultprompt);
      savestepperForm.append('temp', temp);
      savestepperForm.append('responses', responses);
    }
    
  

    savestepperForm.append('statetracker', statetracker.toString());
    savestepperForm.append('deadline', deadline);
    

    if(statetracker) {
      savestepperForm.append('statelabel', statelabel);
    }
    if(deadline) {
     // var seconds = deadlineval.getTime() / 1000;
     savestepperForm.append('deadlineval', deadlineval);
    }

    savestepperForm.append('assigned', assigned);
    if(assigned) {
      // var seconds = deadlineval.getTime() / 1000;
      savestepperForm.append('assignedto', assignedto);
     }
     console.log('this is the cmt  value '+cmt)
     savestepperForm.append('cmt', cmt);
          if(cmt){
            savestepperForm.append('prompt', cmtval);
            savestepperForm.append('atcmt', atcmt);
         
            

            if(atcmt){
              savestepperForm.append('cmtuser', atuser);
            }
            
           
            savestepperForm.append('cmticon', cmticon);
            savestepperForm.append('cmtclr', cmtclr);

          }

          if(fileinfolder){
           // savenodeForm.append('fileinfolder', 'true')

 

 

   
    if (this.folderdetailsds.tagged){
     // savenodeForm.append('indextype', '1');
     savestepperForm.append('foldertags', JSON.stringify(this.newfoldertags));
     savestepperForm.append('tagcolor', this.folderdetailsds.tagcolor);
  }
          }
          if(!fileinfolder){
            savestepperForm.append('fileinfolder', 'false')}

          //  savestepperForm.append('indextype', indextype);
            if (indextype == '2'){
          
              savestepperForm.append('foldertags', JSON.stringify(this.newpropvalues));
              savestepperForm.append('nodename', this.teamdetailsds.nodename)
              savestepperForm.append('tagcolor', this.teamdetailsds.tagcolor)
              }

  
   
 this.authService.saveStepper(savestepperForm, this.user).subscribe(stepperresults => {
    console.log('this is the results from saving Stepper: ' + JSON.stringify(stepperresults));
    if(!textract && !ischatgpt){


      this.teamds = stepperresults;
      this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
      this.csvrowcnt++;
      this.csvprocessed = this.csvrowcnt/this.totalcsvrows*100
 
      if(this.csvprocessed == 100){
      this.getFolderDetails(folderid, type, teamid, false)} 
 
      if(this.folderdetailsds.kanbantagged){
       this.getFolderDetails(folderid, type, teamid, false)
      }}
     if(textract || ischatgpt){
      this.nodeds = stepperresults
     this.nodetextracted = true

     this.chatgptresponse = true;
     console.log('got a response')
     this.activetype = 'chatgpt'

    }

     if (nodetabtagged){
      this.kanbanready = true
     }
     
     this.getFolderDetails(folderid, type, teamid, false) 
  }, (err) => {
    console.error(err); });

  }

  public saveStepperassign(folderid, teamid,  level, nodeid, nodename, nodedesc, type, stepper, icontype, iconcolor, assignment)
  {
    this.datenow = Date.now();
    const stepperassignForm = new FormData();
    stepperassignForm.append('folderid', folderid);
    stepperassignForm.append('teamid', teamid);
    stepperassignForm.append('level', level  );
    stepperassignForm.append('nodeid', nodeid);
    stepperassignForm.append('nodename', nodename);
    stepperassignForm.append('type', type);
    stepperassignForm.append('stepper', stepper);
    stepperassignForm.append('icontype', icontype);
    stepperassignForm.append('iconcolor', iconcolor);
    stepperassignForm.append('nodedesc', nodedesc);
    stepperassignForm.append('addedon', this.datenow);
    stepperassignForm.append('assignment',assignment);
    stepperassignForm.append('indextype', '4');

    if (this.folderdetailsds.kanbantagged){
      stepperassignForm.append('kanbantagged', 'true');
      stepperassignForm.append('kanbanid', this.folderdetailsds.kanbanid);
      stepperassignForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
    }

    if (!this.folderdetailsds.kanbantagged){
      stepperassignForm.append('kanbantagged', 'false');
    
    }


  
    
   
 this.authService.saveStepperassign(stepperassignForm, this.user).subscribe(stepperassignresults => {
    console.log('this is the results from saving formassign: ' + JSON.stringify(stepperassignresults));
     this.teamds = stepperassignresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    
  }, (err) => {
    console.error(err); });

  }

  public applyTags(itemid, teamid,  level, tagid, tagname, tagdesc,  type,  stepitems, icontype, iconcolor)
            {
              this.datenow = Date.now();
              const applyTagsform = new FormData();
              applyTagsform.append('itemid', itemid);
              applyTagsform.append('teamid', teamid);
              applyTagsform.append('level', level  );
              applyTagsform.append('type', type);
              applyTagsform.append('nodeid', tagid);
              applyTagsform.append('nodename', tagname);
              applyTagsform.append('stepitems', stepitems);
              applyTagsform.append('icontype', icontype);
              applyTagsform.append('tagcolor', iconcolor);
              applyTagsform.append('nodedesc', tagdesc);
              applyTagsform.append('addedon', this.datenow);
              applyTagsform.append('indextype', '1');
            
              
             
           this.authService.applyTags(applyTagsform, this.user).subscribe(applytagresults => {
              console.log('this is the results from saving Node: ' + JSON.stringify(applytagresults));
             //  this.folderdetailsds[0] = [noderesults];
               this.folderdetailsds= applytagresults;
               this.setActivetab('fold')
             
            }, (err) => {
              console.error(err); });
          
 }

 public updateStepper(type, itemid,updatedstepper)
 {
   this.datenow = Date.now();
   const updateStepperform = new FormData();
   updateStepperform.append('itemid', itemid);
  
   updateStepperform.append('updatedstepper', updatedstepper);
   updateStepperform.append('folderid', this.folderdetailsds._id);
   updateStepperform.append('mo', this.datenow);
   updateStepperform.append('newstate', this.nodeds[0].state);
   updateStepperform.append('nodeid', this.nodeds[0]._id);
   updateStepperform.append('type', type);
  
this.authService.updateStepper(updateStepperform, this.user).subscribe(stepperresults => {
   console.log('this is the results from updating Stepper: ' + JSON.stringify(stepperresults));
    this.teamds = stepperresults;
    this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
 }, (err) => {
   console.error(err); });

 }

 public updateFoldertabs(type, itemid,updatedstepper)
 {
   this.datenow = Date.now();
   const updateStepperform = new FormData();
   updateStepperform.append('itemid', itemid);
  
   updateStepperform.append('updatedstepper', updatedstepper);
   updateStepperform.append('folderid', this.folderdetailsds._id);
   updateStepperform.append('mo', this.datenow);
   updateStepperform.append('newstate', this.foldertabsds[0].state);
   updateStepperform.append('nodeid', this.foldertabsds[0]._id);
   updateStepperform.append('type', type);
  
this.authService.updateFoldertabs(updateStepperform, this.user).subscribe(stepperresults => {
   console.log('this is the results from updating Stepper: ' + JSON.stringify(stepperresults));
    this.teamds = stepperresults;
    this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
 }, (err) => {
   console.error(err); });

 }

 public setActivetab(type){
   if (type == 'fold'){
  if(this.folderdetailsds.tagged){
    console.log('this folder is tagged')
   this.foldertabIndex= 2

   if(this.folderdetailsds.kanbantagged){
     console.log('this folder has a  kanban')
     this.foldertabIndex= 3}

     if(!this.folderdetailsds.tagged){
       console.log('this folder is not  tagged')
      this.foldertabIndex= 1}

      if(!this.folderdetailsds.kanbantagged){
        console.log('this folder has no kanban as well as no foldertags')
        this.foldertabIndex= 1}

  }
  if(!this.folderdetailsds.tagged && !this.folderdetailsds.kanbantagged){
   this.foldertabIndex= 1
  }

  if(!this.folderdetailsds.tagged && this.folderdetailsds.kanbantagged){
   this.foldertabIndex= 2
  }
 }

 if (type != 'fold'){
  if(this.nodeds[0].tagged){
    console.log('this file is tagged')
   this.foldertabIndex= 2

  

     if(!this.nodeds[0].tagged){
       console.log('this file is not  tagged')
      this.foldertabIndex= 1}
  }
  

 }
}

public deselect(selectedtype: string){
  console.log('deselect type is '+selectedtype)
  this.activetype = selectedtype
  this.popup = false
  if(selectedtype == 'fold'){
  this.activeitemid = this.currentfolder
  this.activename = this.currentfoldername
  this.hlitem = []; this.nodeready = false; this.nodeds = [];
  console.log('successfully deselected browse mode')}

  if(selectedtype == 'design'){
    console.log('successfully deselected design mode')
    console.log('active type is now'+this.activetype)
  }

  if(this.folderdetailsds.locktoolbar ){
    this.toolmenu = 'toolbartools'; console.log('active toolmenu is set to toolbartools')
    this.favtools = false
this.getToolbars('nodeteam', this.folderdetailsds.toolbarid, 'nodeteam')
   }

}

public findTemplate(itemname?: string  ){
  console.log('itemname is ' +itemname);
 
 this.assigned = [];
              const finditemsForm = new FormData();

             
                finditemsForm.append('itemname', itemname);
              
             
           
           this.authService.findTemplate(finditemsForm, this.user).subscribe(findtemplateresults => {
              console.log('this is the results from finditems: ' + JSON.stringify(findtemplateresults));
              this.finditemds = findtemplateresults;
               this.finditemfound = true
             
            }, (err) => {
              console.error(err); });
          
}

public searchallTemplates(itemname?: string  ){
  console.log('itemname is ' +itemname);
 
 this.assigned = [];
              const finditemsForm = new FormData();

             
                finditemsForm.append('itemname', itemname);
              
             
           
           this.authService.searchallTemplates(finditemsForm, this.user).subscribe(findtemplateresults => {
              console.log('this is the results from finditems: ' + JSON.stringify(findtemplateresults));
              this.finditemds = findtemplateresults;
               this.finditemfound = true
             
            }, (err) => {
              console.error(err); });
          
}

public findFoldertags(itemname?: string  ){
  console.log('itemname is ' +itemname);
 
 this.assigned = [];
              const finditemsForm = new FormData();

             
                finditemsForm.append('itemname', itemname);
              
             
           
           this.authService.findFoldertags(finditemsForm, this.user).subscribe(foldertagsresults => {
              console.log('this is the results from finditems: ' + JSON.stringify(foldertagsresults));
              this.finditemds = foldertagsresults;
               this.finditemfound = true
             
            }, (err) => {
              console.error(err); });
          
}

public findSmartfolder(itemname?: string  ){
  console.log('itemname is ' +itemname);
 
 this.assigned = [];
              const finditemsForm = new FormData();

             
                finditemsForm.append('itemname', itemname);
              
             
           
           this.authService.findSmartfolder(finditemsForm, this.user).subscribe(smartfolderresults => {
              console.log('this is the results from finditems: ' + JSON.stringify(smartfolderresults));
              this.findsmartfolderds = smartfolderresults;
               this.finditemfound = true
             
            }, (err) => {
              console.error(err); });
          
}

public findKanban(itemname?: string  ){
  console.log('itemname is ' +itemname);
 
 this.assigned = [];
              const finditemsForm = new FormData();

             
                finditemsForm.append('itemname', itemname);
              
             
           
           this.authService.findKanban(finditemsForm, this.user).subscribe(kanbanresults => {
              console.log('this is the results from finditems: ' + JSON.stringify(kanbanresults));
              this.findkanbands = kanbanresults;
               this.finditemfound = true
             
            }, (err) => {
              console.error(err); });
          
}

public getFlowitembyid(itemid: string, launcher: string ){
  console.log('itemid is ' +itemid);console.log('launcher is ' +launcher);
  
 
              const getflowitemForm = new FormData();
              getflowitemForm.append('launcher', launcher);
              getflowitemForm.append('itemid', itemid);
             
           
           this.authService.getFlowitembyid(getflowitemForm, this.user).subscribe(getflowitemresults => {
              console.log('this is the results from getflowitemresults: ' + JSON.stringify(getflowitemresults));

              if(launcher == 'false'){this.flowitempreviewds = getflowitemresults;}
              if(launcher == 'true'){this.nodeds = getflowitemresults;}

               this.flowready = true;
             
            }, (err) => {
              console.error(err); });
          
}

public addtoFlow(flowid: string, itemid: string, name:string, type: string, icontype: string, iconcolor: string,
  assignedteam?){
  this.datenow = Date.now();

  const addtoflowForm = new FormData();
  addtoflowForm.append('flowid', flowid);
  addtoflowForm.append('itemid', itemid);
  addtoflowForm.append('name', name);
  addtoflowForm.append('type', type);
  addtoflowForm.append('icontype', icontype);
  addtoflowForm.append('iconcolor', iconcolor);
  addtoflowForm.append('addedon', this.datenow);
  addtoflowForm.append('assignedteam', JSON.stringify(assignedteam));
  


  if(this.nodeds[0].flow.length == 0){
    addtoflowForm.append('launcher', 'true')
    addtoflowForm.append('eleord', '0')
  }
  if(this.nodeds[0].flow.length > 0){
    var order = this.nodeds[0].flow.length
    var eleord =  order.toString();
    addtoflowForm.append('launcher', 'false')

    addtoflowForm.append('eleord', eleord)
  }

this.authService.addtoFlow(addtoflowForm, this.user).subscribe(addtoflowresults => {
  console.log('this is the results from adding item to flow: ' + JSON.stringify(addtoflowresults));
  this.editflow= 'editflow'
  this.flowdetailsds= [addtoflowresults];
    console.log('this is the current flow details: '+JSON.stringify(this.flowdetailsds))
    
      this.editstepper = ''; this.editnode = ''; this.editflow = 'editflow'
      console.log('this is the current node details: '+JSON.stringify(this.nodeds))
      this.nodeflowds = addtoflowresults.flow
       console.log('this is the current nodeflowds details: '+JSON.stringify(this.nodeflowds))
       if( this.nodeflowds.length == 0) {
         console.log('this is a launch phase')
        this.launchtype = 'launcher'}
         if( this.nodeflowds.length != 0) {
          console.log('this is not launch phase')
          this.launchtype = 'nonlauncher'
        }
  
 
}, (err) => {
  console.error(err); });



}

public moveFlowitem(newflowds: string, launcherchanged: boolean, launcheritemid: string){
  

  this.datenow = Date.now();

  const modeflowitemForm = new FormData();
  modeflowitemForm.append('newflowds', newflowds);
  modeflowitemForm.append('launcher', launcherchanged.toString());
  modeflowitemForm.append('itemid', launcheritemid);
  modeflowitemForm.append('flowid', this.activeitemid);
  this.authService.moveFlowitem(modeflowitemForm, this.user).subscribe(moveflowitemresults => {
    console.log('this is the results from adding item to flow: ' + JSON.stringify(moveflowitemresults));
    this.nodeflowds = [moveflowitemresults];
   
   
  }, (err) => {
    console.error(err); });
  
}

public updateAssignment(itemid: string, newassignment: string){

 this.datenow = Date.now();

  const updateassignmentForm = new FormData();
 // updateassignmentForm.append('flowid', flowid);
  updateassignmentForm.append('docid', itemid);
  updateassignmentForm.append('itemid', this.folderdetailsds.parentid);
  updateassignmentForm.append('newassignment', newassignment);
  
  this.authService.updateAssignment(updateassignmentForm, this.user).subscribe(updateassignmentresults => {
    console.log('this is the results from adding updating assignment: ' + JSON.stringify(updateassignmentresults));
  //  this.teamds = [updateassignmentresults];
    this.teamds = updateassignmentresults;
    this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    this.tabIndex = 2
    this.nodeready = true;
    console.log('current row selected for comment is: '+JSON.stringify(this.currentrow));
    this.nodeds[0] = this.teamds[this.itemindex]
    this.userready
   
  }, (err) => {
    console.error(err); });
  
}

public clearAssignment(itemid: string){

  this.datenow = Date.now();
 
   const clearassignmentForm = new FormData();
  // updateassignmentForm.append('flowid', flowid);
  clearassignmentForm.append('itemid', itemid);

  this.nodeready = false;
   
   this.authService.clearAssignment(clearassignmentForm, this.user).subscribe(clearassignmentresults => {
     console.log('this is the results from clearing assignment: ' + JSON.stringify(clearassignmentresults));
   //  this.teamds = [updateassignmentresults];
   this.nodeds[0] = clearassignmentresults
   this.nodeready = true;
   /*
     this.teamds = [clearassignmentresults];
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.tabIndex = 2
     this.nodeready = true;
     console.log('current row selected for comment is: '+JSON.stringify(this.currentrow));
     this.nodeds[0] = this.teamds[this.itemindex]
     this.userready
     console.log('redirectin to the source folder');
     var base = '/toolbard/home?'
     var itemid = this.folderdetailsds._id
     var teamid = this.folderdetailsds.teamid;
     var level = this.folderdetailsds.level;
     var name = this.folderdetailsds.name;
     var dynamicurl = base+'itemid='+itemid+'&type=fold&name='+name+'&level='+level+'&teamid='+teamid
     
     
     this.router.navigateByUrl(dynamicurl);
     */
    
   }, (err) => {
     console.error(err); });
   
}

public clearDeadline(itemid: string){

 
 
   const cleardeadlineForm = new FormData();
  // updateassignmentForm.append('flowid', flowid);
  cleardeadlineForm.append('itemid', itemid);

  this.nodeready = false;
   
   this.authService.clearDeadline(cleardeadlineForm, this.user).subscribe(cleardeadlineresults => {
     console.log('this is the results from clearing assignment: ' + JSON.stringify(cleardeadlineresults));
   //  this.teamds = [updateassignmentresults];
   this.nodeds[0] = cleardeadlineresults
   this.nodeready = true;
   
    
   }, (err) => {
     console.error(err); });
   
 }

public updateNodeAssignment(itemid: string, newassignment: string){

  this.datenow = Date.now();
 
   const updatenodeassignmentForm = new FormData();
  // updateassignmentForm.append('flowid', flowid);
  updatenodeassignmentForm.append('docid', itemid);

  updatenodeassignmentForm.append('newassignment', newassignment);
   
   this.authService.updateNodeAssignment(updatenodeassignmentForm, this.user).subscribe(updatenodeassignmentresults => {
     console.log('this is the results from adding updating assignment: ' + JSON.stringify(updatenodeassignmentresults));
   //  this.teamds = [updateassignmentresults];
   
     this.nodeready = true;
     this.nodeds[0] = updatenodeassignmentresults
    
   }, (err) => {
     console.error(err); });
   
 }

 public removeAssignment(itemid: string, newassignment: string){

  this.datenow = Date.now();
 
   const updatenodeassignmentForm = new FormData();
  // updateassignmentForm.append('flowid', flowid);
  updatenodeassignmentForm.append('docid', itemid);

  updatenodeassignmentForm.append('newassignment', newassignment);
   
   this.authService.updateNodeAssignment(updatenodeassignmentForm, this.user).subscribe(updatenodeassignmentresults => {
     console.log('this is the results from adding updating assignment: ' + JSON.stringify(updatenodeassignmentresults));
   //  this.teamds = [updateassignmentresults];
   
     this.nodeready = true;
     this.nodeds[0] = updatenodeassignmentresults
    
   }, (err) => {
     console.error(err); });
   
 }

 public removefromFlow(flowid: string, itemid: string){

  this.datenow = Date.now();
 
   const removefromFlowForm = new FormData();
  // updateassignmentForm.append('flowid', flowid);
  removefromFlowForm.append('flowid', flowid);

  removefromFlowForm.append('itemid', itemid);
   
   this.authService.removefromFlow(removefromFlowForm, this.user).subscribe(removefromflowresults => {
     console.log('this is the results from removing assignment from flow: ' + JSON.stringify(removefromflowresults));
   //  this.teamds = [updateassignmentresults];
   
     this.nodeready = true;
     this.nodeds[0] = removefromflowresults
    
   }, (err) => {
     console.error(err); });
   
 }



 public setDeadline(itemid: string, deadlineval: string, quickadd: boolean, folderid: string){

 const deadlineForm = new FormData();
  deadlineForm.append('itemid', itemid);
  deadlineForm.append('deadlineval', deadlineval);
  deadlineForm.append('quickadd', quickadd.toString());
  deadlineForm.append('folderid', folderid);
  deadlineForm.append('deadline', 'true');
  deadlineForm.append('type', this.activetype);
  this.nodeready = false; this.fileready = false;
   
   this.authService.setDeadline(deadlineForm, this.user).subscribe(deadlineresults => {
     console.log('this is the results from adding updating assignment: ' + JSON.stringify(deadlineresults));
   //  this.teamds = [updateassignmentresults];
     this.teamds = deadlineresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.tabIndex = 2
     this.nodeready = true; this.fileready = true;
     console.log('current row selected for comment is: '+JSON.stringify(this.currentrow));
     this.nodeds[0] = this.teamds[this.itemindex]
    
   }, (err) => {
     console.error(err); });
   
 }

 public setState(itemid: string, statevalue: boolean, statelabel: string, quickadd: boolean, folderid: string){

  const setstateForm = new FormData();
  setstateForm.append('itemid', itemid);
  setstateForm.append('statevalue', statevalue.toString());
  setstateForm.append('statelabel', statelabel);
  setstateForm.append('setstate', 'true');
  setstateForm.append('quickadd', quickadd.toString());
  setstateForm.append('folderid', folderid);
 
  setstateForm.append('type', this.activetype);
   this.nodeready = false; this.fileready = false;
    
    this.authService.setState(setstateForm, this.user).subscribe(deadlineresults => {
      console.log('this is the results from adding updating assignment: ' + JSON.stringify(deadlineresults));
    //  this.teamds = [updateassignmentresults];
      this.teamds = deadlineresults;
      this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
      this.tabIndex = 0
      this.nodeready = true; this.fileready = true;
      console.log('current row selected for comment is: '+JSON.stringify(this.currentrow));
      this.nodeds[0] = this.teamds[this.itemindex]
     
    }, (err) => {
      console.error(err); });
    
  }

 public setAssignedto(type: string, itemid: string, assignedto: string, quickadd: boolean, folderid: string){
  console.log('assigned to: '+assignedto);
  const setassignedForm = new FormData();
  setassignedForm.append('type', type);
  setassignedForm.append('assigned', 'true');
  setassignedForm.append('itemid', itemid);
  setassignedForm.append('assignedto', assignedto);
  setassignedForm.append('quickadd', quickadd.toString());
  setassignedForm.append('folderid', folderid);
    
    this.authService.setAssignedto(setassignedForm, this.user).subscribe(assignedtoresults => {
      console.log('this is the results from adding updating assignment: ' + JSON.stringify(assignedtoresults));
    //  this.teamds = [updateassignmentresults];
      this.teamds = assignedtoresults;
      this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
      this.tabIndex = 2
      this.nodeready = true;
      
      console.log('current row selected for comment is: '+JSON.stringify(this.currentrow));
      this.nodeds[0] = this.teamds[this.itemindex]
      this.userready
    }, (err) => {
      console.error(err); });
    
  }
 


public moveTagitem(nodetagsds: string, itemid: string){
  

  this.datenow = Date.now();

  const tagitemForm = new FormData();
  tagitemForm.append('nodetagsds', nodetagsds);
  tagitemForm.append('itemid', itemid);
  tagitemForm.append('nodeid', this.activeitemid);
  this.authService.moveTagitem(tagitemForm, this.user).subscribe(tagitemresults => {
    console.log('this is the results from adding item to flow: ' + JSON.stringify(tagitemresults));
    this.nodetagsds = tagitemresults;
   
   
  }, (err) => {
    console.error(err); });
  
}

public moveTrackitem(nodeteamds: string){
  

  this.datenow = Date.now();

  const trackitemForm = new FormData();
  trackitemForm.append('nodeteamds', nodeteamds);

  trackitemForm.append('kanbanid', this.activeitemid);
  this.authService.moveTrackitem(trackitemForm, this.user).subscribe(trackitemresults => {
    console.log('this is the results from moving a track item: ' + JSON.stringify(trackitemresults));
    this.nodeteamds = trackitemresults;
   
   
  }, (err) => {
    console.error(err); });
  
}


public copyDesign(activeitemid, teamid, level, formid, formtype, newname, desc, icontype, iconcolor, statetracker, statelabel){

  
  this.datenow = Date.now();

  const copyDesignForm = new FormData();
  copyDesignForm.append('parentid', activeitemid);
  copyDesignForm.append('teamid', teamid);
  copyDesignForm.append('level', level);
  copyDesignForm.append('formid', formid);
  copyDesignForm.append('type', formtype);
  copyDesignForm.append('name', newname);
  copyDesignForm.append('desc', desc);
  copyDesignForm.append('icontype', icontype);
  copyDesignForm.append('iconcolor', iconcolor);
  copyDesignForm.append('statetracker', statetracker);
  copyDesignForm.append('statelabel', statelabel);
  copyDesignForm.append('addedon', this.datenow);
 
  this.authService.copyDesign(copyDesignForm, this.user).subscribe(copyDesignitemresults => {
    console.log('this is the results from adding item to flow: ' + JSON.stringify(copyDesignitemresults));
    this.nodeteamds  = copyDesignitemresults;
   
   
  }, (err) => {
    console.error(err); });
  
}

public folderFilter(parentid, folderstartswith)  {
 
  console.log('getting filtered folders now for parentid '+parentid);
  console.log('folder starts with  '+folderstartswith);
  
  const folderfilterForm = new FormData();
  folderfilterForm.append('parentid', parentid);
  folderfilterForm.append('fldrsw', folderstartswith);

  this.authService.folderFilter(folderfilterForm, this.user).subscribe(folderfilterresults => {
    console.log('this is the results from folderfilterForm: ' + JSON.stringify(folderfilterresults));
     this.teamds = folderfilterresults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    
  

   
    
   }, (err) => {console.error(err); });

}

public toolbarFilter(toolbarstartswith)  {
 

  console.log('toolbar starts with  '+toolbarstartswith);
  
  const toolbarfilterForm = new FormData();
 
  toolbarfilterForm.append('toolbarsw', toolbarstartswith);

  this.authService.toolbarFilter(toolbarfilterForm, this.user).subscribe(toolbarfilterresults => {
    console.log('this is the results from toolbarfilter: ' + JSON.stringify(toolbarfilterresults));
     this.toolbards  = toolbarfilterresults;
    
   }, (err) => {console.error(err); });

}

public usernameFilter(usernamestartswith, type)  {
 
  console.log('going to');
  console.log('going to get username starting with  '+usernamestartswith);
  
  const usernameFilterForm = new FormData();

  usernameFilterForm.append('usernamesw', usernamestartswith);
  usernameFilterForm.append('type', type);

  this.authService.usernameFilter(usernameFilterForm, this.user).subscribe(usernameFilterresults => {
    console.log('this is the results from filtering emails for the queuesearch: ' + JSON.stringify(usernameFilterresults));
     
    if(type == 'user'){
    this.filtereduser = usernameFilterresults;
    console.log('this is the filtereduser: '+this.filtereduser)
  }
    
    if(type == 'team'){
      this.filteredteam = usernameFilterresults;
      console.log('this is the filteredteam: '+this.filteredteam)}

   
    
   }, (err) => {console.error(err); });

}

public foldertagsFilter(foldertagsstartswith)  {
 
  console.log('going to');
  console.log('going to get username starting with  '+foldertagsstartswith);
  
  const foldertagsFilterForm = new FormData();

  foldertagsFilterForm.append('foldertagssw', foldertagsstartswith);

  this.authService.foldertagsFilter(foldertagsFilterForm, this.user).subscribe(foldertagsFilterresults => {
    console.log('this is the results from filtering emails for the queuesearch: ' + JSON.stringify(foldertagsFilterresults));
     this.filterfoldertags = foldertagsFilterresults;
    
  

   
    
   }, (err) => {console.error(err); });

}










public moveItem(itemid, parentid, type, level, teamid, name, icontype, iconcolor, sourcekanban, sourcekanbanid?, activetrack?)  {
 
  console.log('moving item '+name)
  console.log('icontype item '+icontype)
  console.log('iconcolor item '+iconcolor)
  
  const moveitemForm = new FormData();
  moveitemForm.append('process', 'moveItem');
  moveitemForm.append('itemid', itemid);
  moveitemForm.append('folderid', parentid);
  moveitemForm.append('oldfolderid', this.activeitemid);
  moveitemForm.append('type', type);
  moveitemForm.append('level', level);
  moveitemForm.append('teamid', teamid);
  moveitemForm.append('name', name);
  moveitemForm.append('icontype', icontype);
  moveitemForm.append('iconcolor', iconcolor);

  if(this.folderdetailsds.kanbantagged){
    moveitemForm.append('kanbantagged', 'true');
  }
  if(!this.folderdetailsds.kanbantagged){
    moveitemForm.append('kanbantagged', 'false');
  }


  if(sourcekanban){
    moveitemForm.append('sourcekanban', 'true');
    moveitemForm.append('sourcekanbanid', sourcekanbanid);
    moveitemForm.append('activetrack', activetrack);
  }
  if(!sourcekanban){
    moveitemForm.append('sourcekanban', 'false');
    
  }
  

  this.authService.moveItem(moveitemForm, this.user).subscribe(moveitemrsults => {
    console.log('this is the results from moving an item: ' + JSON.stringify(moveitemrsults));
     this.teamds = moveitemrsults;
     this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
     this.moveitem = [];this.move = false;
     this.getFolderDetails(this.activeitemid, 'fold', teamid, false) 
    
   }, (err) => {console.error(err); });

}



public getsavedSearch()  {
 
  console.log('get search now');
  
  const savedsearchForm = new FormData();

  this.authService.getsavedSearch(savedsearchForm, this.user).subscribe(savedsearchresults => {
    console.log('this is the results from getting saved searches: ' + JSON.stringify(savedsearchresults));
     this.mysearches = savedsearchresults;
     this.activetype = 'search'
 
    
  this.searchready = true;
 

   
    
   }, (err) => {console.error(err); });

}

public applynodeKanban( nodeid, kanbanid, type)
{

  this.datenow = Date.now();

  const kanbanForm = new FormData();
  kanbanForm.append('nodeid', nodeid);
  kanbanForm.append('kanbanid', kanbanid);
  kanbanForm.append('type', type);
  kanbanForm.append('kanbantagged', 'true');



 
 
this.authService.applynodeKanban(kanbanForm, this.user).subscribe(kanbanresults => {
  console.log('this is the results from createing tags: ' + JSON.stringify(kanbanresults));
  
  this.nodeds = [kanbanresults]
  console.log('this is the nodeds after adding a step: ' + JSON.stringify(this.nodeds));



  
}, (err) => {console.error(err); });

}

public applySmartfoldertag( nodeid,  name, desc, type, tagtype, foldertags)
{

  this.datenow = Date.now();

  const smartfoldertagForm = new FormData();
  smartfoldertagForm.append('nodeid', nodeid);
  smartfoldertagForm.append('tagname', name);
  smartfoldertagForm.append('tagdesc', desc);
  smartfoldertagForm.append('type', type);
  smartfoldertagForm.append('tagtype', tagtype);
  smartfoldertagForm.append('tagcolor', 'orange');
  smartfoldertagForm.append('foldertags', JSON.stringify(foldertags));
  



 
 
this.authService.applySmartfoldertag(smartfoldertagForm, this.user).subscribe(smartfoldertagresults => {
  console.log('this is the results from applying foldertags to smartfolder: ' + JSON.stringify(smartfoldertagresults));
  
  this.nodeds = [smartfoldertagresults]
  console.log('this is the nodeds after applying foldertags to smartfolder: ' + JSON.stringify(this.nodeds));



  
}, (err) => {console.error(err); });

}

public getSubitem(linkid, type){
  this.linknodeready = false

  const getsubitemForm = new FormData();
  getsubitemForm.append('linkid', linkid);
  getsubitemForm.append('batchtype', type);

 
 
this.authService.getSubitem(getsubitemForm, this.user).subscribe(linkidresults => {
  console.log('this is the results from linkidresults: ' + JSON.stringify(linkidresults));
  
  this.nodeds = linkidresults
  console.log('this is the linknodeds after linkidresults: ' + JSON.stringify(this.linknodeds));
  this.linknodeready = true

console.log('this is the linknodeready value '+ this.linknodeready)

  
}, (err) => {console.error(err); });


}

public updateBatchitem(linkid, type, properties){
  this.linknodeready = false

  const getsubitemForm = new FormData();
  getsubitemForm.append('linkid', linkid);
  getsubitemForm.append('type', type);
  getsubitemForm.append('batchtype', 'design');

  if(type == 'node'){
    getsubitemForm.append('stepitems', properties);
  }

  if(type == 'wizard' || type == 'tabbed'){
    getsubitemForm.append('stepper', properties);
  }

 
 
this.authService.updateBatchitem(getsubitemForm, this.user).subscribe(updatedbatchresults => {
  console.log('this is the results from updateBatchitem: ' + JSON.stringify(updatedbatchresults));
  
  this.nodeds = updatedbatchresults
  console.log('this is the nodeds after updatedbatchresults: ' + JSON.stringify(this.nodeds));
  this.linknodeready = true

console.log('this is the linknodeready value '+ this.linknodeready)

  
}, (err) => {console.error(err); });


}

public batchitems(linkid, folderid, type, indextype, nodename?, icontype?, iconcolor?, nodeid?, batchtotal?, batchindex? ){
  this.datenow = Date.now();
  const batchitemsForm = new FormData();
  batchitemsForm.append('batchtype', 'launch');
  batchitemsForm.append('process', 'batchitems');
  batchitemsForm.append('nodename', nodename);
  batchitemsForm.append('icontype', icontype);
  batchitemsForm.append('iconcolor', iconcolor);
  batchitemsForm.append('nodeid', nodeid);
  batchitemsForm.append('type', type);
  batchitemsForm.append('linkid', linkid);
  batchitemsForm.append('folderid', folderid);
  batchitemsForm.append('addedon',  this.datenow);
  batchitemsForm.append('indextype',  indextype);

  if (indextype =='1'){
    batchitemsForm.append('foldertags',  JSON.stringify(this.newfoldertags));
  }
  batchitemsForm.append('teamid',  this.activeteamid);
  batchitemsForm.append('level',   this.activelevel);
  batchitemsForm.append('deadline',  'false');
  batchitemsForm.append('statetracker',   'false');
  batchitemsForm.append('batchtotal',   batchtotal);
  batchitemsForm.append('batchindex',   batchindex);
  if (this.folderdetailsds.kanbantagged){
    batchitemsForm.append('kanbantagged', 'true');
    batchitemsForm.append('kantype', this.folderdetailsds.kantype);
    batchitemsForm.append('kanbanid', this.folderdetailsds.kanbanid);
    batchitemsForm.append('activetrack', this.folderdetailsds.kanbanprops[0].track);
    batchitemsForm.append('kanbanopts',   this.folderdetailsds.kanbanopts.toString());
    batchitemsForm.append('kanbanpercent',   this.folderdetailsds.kanbanpercent.toString());

   
    var trackcount = this.folderdetailsds.kanbanprops.length -1


    batchitemsForm.append('trackcount', trackcount.toString());
    batchitemsForm.append('kanbantotal', this.folderdetailsds.kanbantotal.toString());
    batchitemsForm.append('kanstate', this.folderdetailsds.kanstate.toString());
  }
  

  if (!this.folderdetailsds.kanbantagged){
    batchitemsForm.append('kanbantagged', 'false');
  
  }
  this.authService.batchitems(batchitemsForm, this.user).subscribe(batchitemsresults => {
    console.log('this is the results from batchitemsresults: ' + JSON.stringify(batchitemsresults));
    
    this.teamds = batchitemsresults;
    this.cachedteamds = this.teamds.slice(this.cachestartindex, this.cacheendindex) 
    this.nodedetailsready = true
  
    if(this.folderdetailsds.kanbantagged){
      this.getFolderDetails(folderid, type, this.activeteamid, false)
     }

    
  }, (err) => {console.error(err); });
  
}


}


