import { Component, OnInit } from '@angular/core';
import {ConfigService} from './../../services/config.service';
import {SidenavService} from './../../services/sidenav.service';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.css']
})
export class SidepanelComponent implements OnInit {

  constructor(public configSvc: ConfigService, public sideSvc: SidenavService) { }

  ngOnInit(): void {
  }

  getrootToolbars(){
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sideSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sideSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
  }
}
