<span>
    <mat-card   [ngClass]= "
    {'edittypelrg': this.configSvc.fullpage == true,
     'edittypesml': this.configSvc.fullpage == false } ">
    <mat-tab-group  [(selectedIndex)]="this.configSvc.tabIndex">
     
      <mat-tab label="Node Item">
        <ng-template mat-tab-label>
          <i class="material-icons icon-tab" 
          [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
      
          </ng-template>
  <span *ngIf="this.configSvc.activetype == 'nodedirect' ">
    <app-activenode></app-activenode>
  </span>



  <span *ngIf="this.configSvc.activetype == 'tabbeddirect' ">
    <app-activetabs></app-activetabs>
  </span>

  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.tagged == true" label="Tagged">
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'orange'}" >bookmark</i>
      
      </ng-template>
    <span class= "comments">
      <app-foldertags [indexlaunch]="'edit'" ></app-foldertags>
    </span>
    </mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
<ng-template mat-tab-label>
<i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}" >{{this.configSvc.nodeds[0]?.cmticon}}</i>

</ng-template>
<span class= "comments">
  <app-viewcomments></app-viewcomments></span>

</mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.assigned == true" label="Assigned">
  <ng-template mat-tab-label>
    <i class="material-icons icon-size" [ngStyle]="{'color': 'purple'}">person_pin_circle</i>
   
    </ng-template>
  <span class= "comments">
    <app-assigned></app-assigned>
  </span>
  </mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.photo == true" label="Photos">
<ng-template mat-tab-label>
<i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >photo_camera</i>

</ng-template>
<span class= "comments">
  <app-nodephotos></app-nodephotos></span>

</mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.attach == true" label="Attachments">
<ng-template mat-tab-label>
<i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >attach_file</i>

</ng-template>
<span class= "comments">
  <app-nodefiles></app-nodefiles></span>

</mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Textraction">
  <ng-template mat-tab-label>
    <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'form'">
      <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">fact_check</i></span>

      <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'invoice'">
        <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">receipt</i></span>
   
    </ng-template>
  <span class= "comments">
<app-textraction></app-textraction></span>

</mat-tab>

<mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Lineitems">
  <ng-template mat-tab-label>
    <span >
      <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">format_list_numbered</i></span>

      
   
    </ng-template>
  <span class= "comments">
<app-lineitems></app-lineitems></span>

</mat-tab>

<mat-tab label="Details" >

  <ng-template mat-tab-label>
    <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>

    </ng-template>

      <app-nodedetails></app-nodedetails>
</mat-tab>



</mat-tab-group>
</mat-card>
</span>