
    

<span class = "flex-container" *ngIf="this.configSvc.nodeready">


    <span *ngIf="this.configSvc.activetype =='node' || this.configSvc.activetype =='nodeimage' || this.configSvc.activetype =='nodefeed'"  >
      <a 
      [routerLink]="['/toolbard/home']"
      [queryParams]="{itemid:this.configSvc.nodeds[0]?._id, type:'nodedirect', name: this.configSvc.nodeds[0]?.name, level:this.configSvc.nodeds[0]?.level, teamid: this.configSvc.nodeds[0]?.teamid }"
    
      >
      <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor || 'black'}" >
        {{this.configSvc.nodeds[0]?.icontype}}</i>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}}</span> </a>
    </span>

    <span *ngIf="this.configSvc.activetype =='chatgpt'"  >
      <a 
      [routerLink]="['/toolbard/home']"
      [queryParams]="{itemid:this.configSvc.nodeds[0]?._id, type:'chatgptdirect', name: this.configSvc.nodeds[0]?.name, level:this.configSvc.nodeds[0]?.level, teamid: this.configSvc.nodeds[0]?.teamid }"
    
      >
      <mat-icon class="custom2"  title="ChatGPT" svgIcon="chat2"
            
      ></mat-icon>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}}</span> </a>
    </span>

    <span *ngIf="this.configSvc.activetype =='activeflow' "  >
     
      <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor || 'black'}" >
        {{this.configSvc.nodeds[0]?.icontype}}</i>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}}</span> 
    </span>

 

    <span *ngIf="this.configSvc.activetype =='nodetab'"  >
     
      <a 
      [routerLink]="['/toolbard/home']"
      [queryParams]="{itemid:this.configSvc.nodeds[0]?._id, type:'tabbeddirect', name: this.configSvc.nodeds[0]?.name, level:this.configSvc.nodeds[0]?.level, teamid: this.configSvc.nodeds[0]?.teamid }"
    
      >
      <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor || 'black'}" >
        {{this.configSvc.nodeds[0]?.icontype}}</i>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}} </span> </a>
    </span>

    <span *ngIf="this.configSvc.activetype =='file' || this.configSvc.activetype =='filedirect'  "  >
      <a 
      [routerLink]="['/toolbard/home']"
      [queryParams]="{itemid:this.configSvc.nodeds[0]?._id, type:'filedirect', name: this.configSvc.nodeds[0]?.name, level:this.configSvc.nodeds[0]?.level, teamid: this.configSvc.nodeds[0]?.teamid }"
    
      >
      <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'pdf'" class="fa-1x">
        <span class="fa-layers fa-fw"><i class="fas fa-file-pdf" style="color:red;"></i></span>
         </span>
    <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'msg'" class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span> 
    <!--
     <div  *ngIf="this.configSvc.nodeds[0].ac > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i> 
        <span class="fa-layers-counter" style="background:Tomato">{{this.configSvc.nodeds[0].ac }}</span></div>
      -->
    <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'txt'" class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span> 
    <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'xlsx'" class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span> 
    <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'docx'" class="fa-layers fa-fw"><i class="fas fa-file-word" style="color:blue"></i></span> 
    <span *ngIf=" this.configSvc.nodeds[0]?.mt == 'bmp'|| this.configSvc.nodeds[0].mt == 'jpeg' || this.configSvc.nodeds[0].mt == 'png' || this.configSvc.nodeds[0].mt == 'jpg'" class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blueviolet"></i></span>   
        
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}} </span> </a>

       
    </span>

    <span *ngIf="this.configSvc.activetype =='editteam' && this.configSvc.teamdetailsds?.fldrcnt >100">
      <mat-form-field>
        <mat-label>Filter Folders</mat-label>
        <input matInput (keyup)="folderFilter($event)" placeholder="name" #input>
      </mat-form-field>
    </span>

    <span *ngIf="this.configSvc.activetype =='fold' && this.configSvc.folderdetailsds?.fldrcnt >100">
      <mat-form-field>
        <mat-label>Filter Folders</mat-label>
        <input matInput (keyup)="folderFilter($event)" placeholder="name" #input>
      </mat-form-field>
    </span>

    <span *ngIf="this.configSvc.activetype =='queue' "  >
      <a 
      [routerLink]="['/toolbard/teamqueue']"
      [queryParams]="{itemid:this.configSvc.nodeds[0]?._id, type:'queuedirect', name: this.configSvc.nodeds[0]?.name, teamid: this.configSvc.activeteamid }"
    
      >
      <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor || 'black'}" >
        {{this.configSvc.nodeds[0]?.icontype}}</i>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}} </span> </a>
    </span>

    <span *ngIf="this.configSvc.activetype =='queuedirect'"  >
   
      <i class="material-icons md-60" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor || 'black'}" >
        {{this.configSvc.nodeds[0]?.icontype}}</i>
        <span [ngStyle]="{'color': 'white'}">{{this.configSvc.nodeds[0]?.name}} </span>
    </span>


  

  
  
  
  
       

          <span *ngIf="this.configSvc.activetype =='edittager'" >
          <span matTooltip="Based on {{this.configSvc.hlitem[0]?.bo}}">
            <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.hlitem[0]?.iconcolor}" >
              {{this.configSvc.hlitem[0]?.icontype}}</i></span>
              
              <a  [ngStyle]="{'color': 'white',  'cursor':'pointer'}"
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:this.configSvc.hlitem[0]?._id, type:this.configSvc.hlitem[0]?.mt, name: this.configSvc.hlitem[0]?.name,
               level:this.configSvc.hlitem[0]?.level, teamid: this.configSvc.hlitem[0]?.teamid}"> {{this.configSvc.hlitem[0]?.name}}</a>
            </span>

            <span *ngIf="this.configSvc.activetype =='tageritem'" >
              <span matTooltip="Based on {{this.configSvc.hlitem[0]?.bo}}">
                <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.hlitem[0]?.iconcolor}" >
                  {{this.configSvc.hlitem[0]?.icontype}}</i></span>
                  
                  {{this.configSvc.hlitem[0]?.name}}
             </span>

             <span *ngIf="this.configSvc.stepclicked" >
              <span matTooltip="Step">
                <i class="material-icons md-48" [ngStyle]="{'color': 'darkred'}" >
                  directions</i>
                {{this.configSvc.stepdetailsds[0].step}}</span>
                  
                  
                </span>

        
        
        




</span>

