import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {ConfigService} from '../../services/config.service';
import {DoccommentsService} from '../../services/doccomments.service';


@Component({
  selector: 'app-editfolder',
  templateUrl: './editfolder.component.html',
  styleUrls: ['./editfolder.component.css'],
  
})
export class EditfolderComponent implements OnInit {
 

  editfolderFG: FormGroup;
 
  someError = '';
  folderrenamed = false;
  newfoldervalue = '';
  newdescvalue = '';
  public percenttime: number;

 
   public newcmtfc = new FormControl();

  constructor(private fb: FormBuilder, public configSvc: ConfigService, 
    public commentSvc: DoccommentsService )
     { 
  }
 



  ngOnInit() {
    this.configSvc.fullpage= false
    console.log('initing the edit folder page '+this.configSvc.fullpage)
    console.log('fullpage setto '+this.configSvc.fullpage)
  //  this.configSvc.toolmenu='general'
  let percentage = Math.round(this.configSvc.folderdetailsds.kanbanpercent)
    
    this.editfolderFG = this.fb.group({
      name: [this.configSvc.folderdetailsds.name],
      desc: [this.configSvc.folderdetailsds.desc],
      tagged: [this.configSvc.folderdetailsds.tagged],
      fldrcnt: [this.configSvc.folderdetailsds.fldrcnt],
      itemcnt: [this.configSvc.folderdetailsds.itemcnt],
      kanbanopts: [this.configSvc.folderdetailsds.kanbanopts],
      kanbanpercent: [percentage],
      kansect: [this.configSvc.folderdetailsds.kansect],
      cmt: [this.configSvc.folderdetailsds.cmt],
      nodename: [''],
      tagsetid: [''],
      kanbanid: [this.configSvc.folderdetailsds.kanbanid],
      tagsn: [''],
      kanbantagged: [this.configSvc.folderdetailsds.kanbantagged],
      taggedby: [this.configSvc.folderdetailsds.taggedby],
      kanbanedby: [''],
      kanbanname:[this.configSvc.folderdetailsds.kanbanname],
      kanbanicon: ['green'],
      kanbancolor:['view_column'],
   });

   this.onChanges();

   if(this.configSvc.folderdetailsds.kantype == 'time'){
this.percenttime = this.configSvc.folderdetailsds.time / this.configSvc.folderdetailsds.timetotal * 100
console.log('percenttime is '+this.percenttime)
   }


  




   
 }





 
 onChanges(): void {
  this.editfolderFG.valueChanges.subscribe(val => {
  console.log('the value has changed to '+JSON.stringify(val))
  this.newfoldervalue = this.editfolderFG.get('name').value;
  this.newdescvalue = this.editfolderFG.get('desc').value;
  this.configSvc.datachanged = true;
  });
}
  

  reset() {
    this.editfolderFG.reset();
    this.someError = '';
  }

 

 

 public addcomment() {
  const stcommentval = this.newcmtfc.value;
    // this.commentSvc.addcomment(stcommentval);
   this.newcmtfc.reset();
     }

     public getComments() {
      this.commentSvc.getComments(); }


     
}
