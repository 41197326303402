import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {ConfigService} from '.././../services/config.service';

@Component({
  selector: 'app-nodedetails',
  templateUrl: './nodedetails.component.html',
  styleUrls: ['./nodedetails.component.css']
})
export class NodedetailsComponent implements OnInit {

  public detailsFG: FormGroup;

  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit() {
console.log('this is thew nodetails were working with '+JSON.stringify(this.configSvc.nodeds[0]))
console.log('hidebrowser set to '+this.configSvc.hidebrowser)
    this.detailsFG = this.fb.group({
      name : [this.configSvc.nodeds[0].name, Validators.required],
      title: [this.configSvc.nodeds[0].ti, Validators.required],
      parentid: [this.configSvc.nodeds[0].parentid, Validators.required],
      type: [this.configSvc.nodeds[0].type, Validators.required],
      addedon: [this.configSvc.nodeds[0].ao, Validators.required],
      addedby: [this.configSvc.nodeds[0].ab, Validators.required],
      desc: [this.configSvc.nodeds[0].desc, Validators.required],
      basedon: [this.configSvc.nodeds[0].bo, Validators.required],
      checked: false,
    });
  }

  public renameItem(newname: string, itemid: string)
  {
    console.log('this is the new name '+newname)
    console.log('this is the itemid '+itemid)
  }

}
