<mat-toolbar class = "toolbar-container">

    <mat-toolbar-row >
       <span class = "footer" >
        <span *ngIf="this.configSvc.activetype =='team' || this.configSvc.activetype =='editteam' || this.configSvc.activetype == 'fold'
    ">
        <mat-paginator   
        [length]="this.configSvc.teamds?.length"
        [pageSize]="pageSize" 
        [hidePageSize]="true"
         [pageIndex]="0"
         [pageSizeOptions]="[20, 50, 100, 200, 500]"
         (page)="handlePage($event)"
         aria-label="Select page of search results"></mat-paginator>
         
</span>

         <span  *ngIf="this.configSvc.activetype == 'myfeed'">
            <mat-paginator   
            [length]="this.configSvc.myfeed?.length"
            [pageSize]="pageSize" 
            [hidePageSize]="true"
             [pageIndex]="0"
             [pageSizeOptions]="[20, 50, 100, 200, 500]"
             (page)="handleFeed($event)"
             aria-label="Select page of search results"></mat-paginator>
             
             </span>

             <span  *ngIf=" this.configSvc.clicksource =='myfeed' && this.configSvc.nodeready">
               
                {{this.configSvc.feeditem+1}} of {{this.configSvc.myfeed.length}}
                <button [disabled]= "this.configSvc.feeditem == 0" (click)="this.previnFeed();"
                mat-raised-button color="primary" >
                <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
               </button>
           
     <span  class = "button-container"> 
       <button  [disabled]= "this.configSvc.feeditem+1 == this.configSvc.myfeed.length" (click)="this.nextinFeed();"
      mat-raised-button color="primary" >
      <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i></button></span>
   </span>
<span *ngIf=" this.configSvc.clicksource =='browse' && this.configSvc.nodeready">
   <span *ngIf=" this.configSvc.activetype == 'nodedirect' || this.configSvc.activetype == 'filedirect' || 
   this.configSvc.activetype == 'nodetab'  || this.configSvc.activetype == 'tabbeddirect' ">
               
      {{this.configSvc.folderitem+1}} of {{this.configSvc.teamds?.length}}
      <button [disabled]= "this.configSvc.folderitem == 0" (click)="this.previnFold();"
      mat-raised-button color="primary" >
      <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
 </button>
 
<span  class = "button-container"> 
<button  [disabled]= "this.configSvc.folderitem+1 == this.configSvc.teamds.length" 
(click)="this.nextinFold();"
mat-raised-button color="primary" >
<i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i>
</button></span>
</span>
</span>

<span *ngIf="this.configSvc.activetype == 'queuedirect' ">
               
   {{this.configSvc.folderitem+1}} of {{this.configSvc.queueitemds?.length}}
   <button [disabled]= "this.configSvc.folderitem == 0" (click)="this.previnFold();"
   mat-raised-button color="primary" >
   <i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_left</i>
</button>

<span  class = "button-container"> 
<button  [disabled]= "this.configSvc.folderitem+1 == this.configSvc.queueitemds.length" 
(click)="this.nextinFold();"
mat-raised-button color="primary" >
<i MatPrefix class="material-icons icon-string"  [style.color]="'black'">chevron_right</i>
</button></span>
</span>


   <span *ngIf="this.configSvc.activetype == 'search'">
        
    <mat-paginator   
    [length]="this.configSvc.teamds?.length"
    [pageSize]="pageSize" 
    [hidePageSize]="true"
     [pageIndex]="0"
     [pageSizeOptions]="[20, 50, 100, 200, 500]"
     (page)="handleSearch($event)"
     aria-label="Select page of search results"> 
   </mat-paginator>
 
     
    </span>
  

          

   </span></mat-toolbar-row>

</mat-toolbar>
