import { Component, OnInit, Input } from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-imageversion',
  templateUrl: './imageversion.component.html',
  styleUrls: ['./imageversion.component.css']
})
export class ImageversionComponent implements OnInit {
  @Input() imagesource: string;
  public reset: any[] = [{}];
  constructor(public uploadSvc: UploadService,public configSvc: ConfigService) { }

  ngOnInit(): void {
  //  this.uploadSvc.imageready = false;
    console.log('image sourece is '+this.imagesource)
    console.log('this is an image file lets go get it');
    if(this.imagesource == 'nodefile'){
      this.uploadSvc.getImagebyid(this.configSvc.linkeditem[0].pa, 1, this.imagesource)
    }

    if( this.imagesource == 'imageversion'){
      console.log('docid '+this.configSvc.linkeditem[0].docid);
      console.log('version '+this.configSvc.linkeditem[0].ver);
      this.uploadSvc.getImagebyver(this.configSvc.linkeditem[0].docid, this.configSvc.linkeditem[0].ver, this.imagesource)
    }

    if(this.imagesource != 'nodefile' && this.imagesource != 'imageversion'){
this.uploadSvc.getImagebyid(this.configSvc.nodeds[0].pa, 1, this.imagesource)
    }
  }

    public onRecreate(){
    this.reset[0] = {}
  }

}

