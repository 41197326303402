import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-editteam',
  templateUrl: './editteam.component.html',
  styleUrls: ['./editteam.component.css']
})
export class EditteamComponent implements OnInit {

  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
    propertyFG: FormGroup;
    editteamFG: FormGroup;
    teamrenamed = false;
    newteamvalue = '';
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
  
    public tabvalue = new FormControl();
  

    public controlArray: FormArray;
    public tagsArray: FormArray;
    public teampropvalues = [];
    constructor(private fb: FormBuilder, public configSvc: ConfigService) { }
  
    ngOnInit() {
  console.log('initializing editteam')
  this.configSvc.toolmenu = 'general'
  this.configSvc.activetype = 'editteam'
  this.configSvc.color = this.configSvc.teamdetailsds.clr  
  console.log('color of editteam: '+ this.configSvc.color)
  
  
  console.log('tool menu is set to '+this.configSvc.toolmenu )
      this.editteamFG = this.fb.group({
        teamname: [this.configSvc.teamdetailsds.name],
        teamdesc:[this.configSvc.teamdetailsds.desc],
        colors: 'black',
        checked: false,
        tagged: [this.configSvc.teamdetailsds.tagged],
        taggedby: [this.configSvc.teamdetailsds.taggedby],
        fldrcnt:[this.configSvc.teamdetailsds.fldrcnt],
        foldertagsearch: []
      });

      this.onChanges();

      this.propertyFG = new FormGroup({
        formArrayName: this.fb.array([])
      })

      
    }

    public appyTags(){
      console.log('this is the selected foldertag '+JSON.stringify(this.editteamFG.value.foldertagsearch));
    this.configSvc.nodeds[0] = this.editteamFG.value.foldertagsearch
    console.log('this is the nodeds value '+JSON.stringify(this.configSvc.nodeds[0]))
      this.configSvc.applyTags(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
        this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'foldertag', 
      JSON.stringify(this.configSvc.nodeds[0].stepitems), this.configSvc.nodeds[0].icontype, 
      this.configSvc.nodeds[0].iconcolor)

     
     }

     public chooseIndex(data){
     console.log('this is the chosen nodeds data: '+data)
     }

  onChanges(): void {
    this.editteamFG.valueChanges.subscribe(val => {
    console.log('the value has changed to '+JSON.stringify(val))
    this.newteamvalue = this.editteamFG.get('teamname').value;
    this.configSvc.datachanged = true;
    });
  }

 
  
  buildTags() {
    if(this.configSvc.teamdetailsds.tagged){
    this.tagsArray = this.propertyFG.get('formArrayName') as FormArray;
  
    Object.keys(this.configSvc.teamdetailsds.props).forEach((i) => {
      this.tagsArray.push(
        this.fb.group({
          tagitem: new FormControl({ value: this.configSvc.teamdetailsds.props[i].property}),
         
         tagitemvalue: new FormControl({ value: this.configSvc.teamdetailsds.props[i].value, disabled: false }),
         
        })
      )
    })
    console.log('finished building tags array controls');
    console.log(this.tagsArray.controls)
   
    }
  }

  foldertagsFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering foldertags on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.foldertagsFilter(filterValue)
  console.log('usernameFilter value is currently set to '+this.configSvc.filterfoldertags)

  }
}

public updateTeam(teamname, teamdesc, teamid){
  console.log('updating team with teamname '+teamname)
  console.log('updating team with teamdesc '+teamdesc)
  console.log('updating team with teamid '+teamid)
  this.configSvc.updateTeam(teamname, teamdesc, teamid)
}

public deleteTeam(teamname, teamid){
  console.log('deleting team with teamname '+teamname)
  console.log('deleting team with teamid '+teamid)
}


  
}
