<span>
 
  
    <h4> <i class="material-icons icon-track" 
      [ngStyle]="{'color': 'purple'}" >
      sync_alt </i>Track Details </h4>
      <mat-card  class = "details-container">
    <form [formGroup]="edittrackFG">

    


      
            <mat-form-field appearance="fill"> 
              
                   <mat-label >Track Name</mat-label>
                   <input formControlName="name" matInput value="{{this.configSvc.trackdetailsds[0].formitem}}">
                  </mat-form-field>
               <div>  <mat-form-field appearance="fill"> 
                  <mat-label >Track Description</mat-label>
                  <input formControlName="desc" matInput value="{{this.configSvc.trackdetailsds[0].itemvalue}}">
                 </mat-form-field></div> 
    <div>
    <mat-form-field appearance="outline" floatLabel="always" >
                  
      <mat-label >Added By</mat-label>
      <input 
      matInput [readonly]= "true" 
      formControlName="addedby">
    </mat-form-field> </div>
    <div>
    <mat-form-field appearance="outline" floatLabel="always" >
    
      <mat-label >Added On</mat-label>
      <input 
      matInput [readonly]= "true" 
      [ngModelOptions]="{standalone: true}"
      [ngModel]="this.configSvc.trackdetailsds[0].ao | date: 'medium'"
      ></mat-form-field></div>
    
   

    
    
    
    
    
  
    <div class="track-items">
    <span>
      <button [disabled]= "!this.configSvc.colorchanged && !this.edittrackFG.dirty" (click)="this.updateTrack();"
               matSuffix 
               mat-raised-button color="primary"
               aria-label="Update Track ">
     Update Track</button></span>
  
  <span>
     <button (click)="this.deleteTrack();"
     matSuffix 
     mat-raised-button color="primary"
     aria-label="Delete Track ">
  
  Delete Track</button></span></div>

</form>
</mat-card>
  
    </span>
  