import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // new
import { AuthenticationService, TokenPayload } from '../../services/authentication.service';
import { Router} from '@angular/router';
import {ConfigService} from '../../services/config.service';
import { PrefsService } from 'src/app/services/prefs.service';

@Component({
    selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']// new
})
export class LoginComponent implements OnInit {
 // returnUrl: string;
    error = '';
    form: FormGroup;                    // new
private formSubmitAttempt: boolean;  // new
  credentials: TokenPayload = {username: '', password: ''};

  constructor(public configSvc: ConfigService, private fb: FormBuilder, public prefSvc: PrefsService,
          private auth: AuthenticationService,private router: Router) {}

  ngOnInit() {
      this.form = this.fb.group({     // {5}
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
      console.log('login component ng on init phase');
    
    }

    isFieldInvalid(field: string) { // {6}

      return (
        (!this.form.get(field).valid && this.form.get(field).touched) ||
        (this.form.get(field).untouched && this.formSubmitAttempt)

      );

    }

    login() {

        console.log('login method trying to run');
      if (this.form.valid) {
        localStorage.setItem('mybatch', '0') ;
         // console.log('form value is' + JSON.stringify(this.form.value));
          this.auth.login(this.form.value).subscribe((loginresults) => {

            console.log('this is the new login results: '+JSON.stringify(loginresults));
           console.log('this is the new redirect url based on prefs: '+loginresults.defloc)

           this.prefSvc.defloc = loginresults.defloc;
           this.prefSvc.toolfavs = loginresults.toolfavs;
           this.prefSvc.bookmarks = loginresults.bookmarks;
           this.prefSvc.filter = loginresults.filter;
           this.prefSvc.userfilter = loginresults.userfilter;
           this.prefSvc.search = loginresults.search;
           this.prefSvc.feedsearch = loginresults.myfeed;
             this.router.navigateByUrl('toolbard/'+this.prefSvc.defloc);
           //  localStorage.setItem('toolfavs', loginresults.toolfavs) ;
          
           
           //    this.router.navigateByUrl('/tager');
             }
              , (err) => {
                  console.log('error in the new post url');
                  // console.error(err);
                  this.error = err.error.message;
                  console.error('An error occurred:', err.error.message);
                  // alert(err.error.message)


                });
        // {7}
        console.log('this is a valid authenticationr');


      }
      this.formSubmitAttempt = true; // {8}
     // console.log('this is a true submit attempt' + this.formSubmitAttempt);
       console.log('payload is' + JSON.stringify(this.credentials));



    }
  }
