<div [formGroup]="flowassignFG" class="flowassigncard"  >
<div class= "assign" >
    <mat-card > 
     <mat-radio-group formControlName="searchtype" class="indextypes"> 
     
 
            
     
           <span>
               <mat-radio-button value="team">Assign Team
             
               </mat-radio-button>
               
               <mat-radio-button value="user">Assign User
               
               </mat-radio-button>
             </span>
 
                                  
             <span *ngIf="this.flowassignFG.value.searchtype == 'team'">
                                     
              <mat-form-field appearance="fill">
                <mat-label>Team Name</mat-label>
               
                <input type="text"  
                formControlName="name"  
                [matAutocomplete]="auto"
               
            
                matInput (keyup)="usernameFilter($event, 'team')" placeholder="Name" autocomplete="off" >
                
                <mat-autocomplete #auto="matAutocomplete">
           <mat-option    
           *ngFor="let team of this.configSvc.filteredteam" [value]="team.name"  (click)="chooseItem(team, 'team')">
            {{team.name}} 
          
           </mat-option>
           
                </mat-autocomplete>
          
               
               </mat-form-field>

               <div > 
                <span *ngIf="this.userchosen || this.teamchosen"> 
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Next</mat-label>
                    <input 
                    matInput placeholder="Next"
                    formControlName="next"  
                    value="Next">
                  </mat-form-field>
                  <mat-checkbox formControlName="nextc">
                   Include Comment</mat-checkbox>
<div>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Prev</mat-label>
                    <input 
                    matInput placeholder="Previous"
                    formControlName="prev"  
                    value="Previous">
                  </mat-form-field>
                  <mat-checkbox formControlName="prevc">
                    Include Comment</mat-checkbox>
                </div>
              </span>
             </div>
               
           
             <div><button  [disabled]= "!this.teamchosen" mat-raised-button color="primary"
              (click)="this.addTeamtoflow(this.flowassignFG.value)">Add Team to Flow</button></div>  
               </span>
 
                                       <span *ngIf="this.flowassignFG.value.searchtype == 'user'">
                                     
                                        <mat-form-field appearance="fill">
                                          <mat-label>Add User</mat-label>
                                         
                                          <input type="text"  
                                          formControlName="name"  
                                          [matAutocomplete]="auto"
                                          
                                          matInput (keyup)="usernameFilter($event, 'user')" placeholder="Username" autocomplete="off" >
                                          
                                          <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option    
                                            *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"  (click)="chooseItem(user, 'user')">
                                             {{user.inboxemail}} 
                                           
                                            </mat-option>
                                          </mat-autocomplete>
                                    
                                         
                                         </mat-form-field>
                                         <div > 
                                          <span *ngIf="this.userchosen || this.teamchosen"> 
                                            <mat-form-field class="example-full-width" appearance="fill">
                                              <mat-label>Next</mat-label>
                                              <input 
                                              matInput placeholder="Next"
                                              formControlName="next"  
                                              value="Next">
                                            </mat-form-field>
                                            <mat-checkbox formControlName="nextc">
                                             Include Comment</mat-checkbox>
<div>
                                            <mat-form-field class="example-full-width" appearance="fill">
                                              <mat-label>Prev</mat-label>
                                              <input 
                                              matInput placeholder="Previous"
                                              formControlName="prev"  
                                              value="Previous">
                                            </mat-form-field>
                                            <mat-checkbox formControlName="prevc">
                                              Include Comment</mat-checkbox>
                                          </div>
                                        </span>
                                       </div>
                                      
                                         <button [disabled]= "!this.userchosen" mat-raised-button color="primary"
                                         (click)="this.addUsertoFlow(this.flowassignFG.value)">Add User to Flow</button>
                                         </span>
               
             </mat-radio-group>
           </mat-card>
             </div>
 
             
 
               <mat-card class="selectusercard">
         
               
           
                         
                
 <div cdkDropList  (cdkDropListDropped)="drop($event)" class="dropbox-list">
 <div class="box-list" *ngFor="let assigned of this.configSvc.flow, let i = index " cdkDrag 
   (click)="this.selectAssignment(assigned, i)"
   [style.background]=" this.hlassignment.indexOf(assigned) == 0 ? 'lightblue' : ''">
    <div>
 
  
   <i class="material-icons md-48" [ngStyle]="{'color': assigned.clr || 'black'}" >{{assigned.icontype}} </i>{{assigned.name}}

   <button mat-button matSuffix mat-raised-button color="primary"> {{assigned.next}}</button>
   <button mat-button matSuffix mat-raised-button color="primary"> {{assigned.prev}}</button>
   </div>
 
 </div>
 </div>  

 <span > <button [disabled]="!this.itemselected"  mat-raised-button color="primary" 
  (click)="this.updateAssignment(this.configSvc.activeitemid)">Update Assignment</button>
</span>

<span > <button [disabled]="!this.itemselected"  mat-raised-button color="primary" 
  (click)="this.removefromFlow()">Remove</button>
</span>

  
 
 
                  
                 
                  
                    
 
                   
                 
               
               </mat-card>
 
            
               
            </div>       
                         