<mat-tab-group >

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon> {{this.configSvc.teamdetailsds.icontype}}</mat-icon>
        {{this.configSvc.teamdetailsds.name}}
      </ng-template>
      <mat-card [formGroup]="editteamFG"  class = "details-container">
        
        <div><mat-form-field appearance="fill" floatLabel="always" class ="large-item"> 
            <mat-label>TeamName</mat-label>
                <input formControlName="teamname"  matInput value="{{this.configSvc.teamdetailsds?.name}}">
               </mat-form-field> </div>

               <div><mat-form-field appearance="fill" floatLabel="always" class ="large-item"> 
                <mat-label>Role Description</mat-label>
                    <input formControlName="teamdesc" matInput value="{{this.configSvc.teamdetailsds?.desc}}">
                   </mat-form-field> </div>
     
                   <div class = "medium-item"> 
        <mat-form-field appearance="outline" floatLabel="always">
           <mat-label>Type</mat-label><input  matInput [readonly]= "true" placeholder={{this.configSvc.activetype}}>
        </mat-form-field></div>
     
        <div>
        <mat-form-field appearance="outline" floatLabel="always" class = "medium-item">
            <mat-label>TeamID</mat-label><input  matInput placeholder={{this.configSvc.activeitemid}}>
         </mat-form-field></div>

         <div>
   <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Level</mat-label><input  matInput placeholder={{this.configSvc.activelevel}}>
         </mat-form-field></div>

         <div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label >Tagged</mat-label><input matInput [readonly]= "true" formControlName="tagged" matInput value={{this.configSvc.teamdetailsds?.tagged}}>
           </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label >Folder Count</mat-label><input matInput [readonly]= "true" formControlName="fldrcnt" matInput value={{this.configSvc.teamdetailsds?.fldrcnt}}>
             </mat-form-field>
            </div>
           
         

           <div *ngIf="this.configSvc.teamdetailsds.tagged">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label >Tagged By</mat-label><input matInput [readonly]= "true" formControlName="taggedby" matInput value={{this.configSvc.teamdetailsds?.taggedby}}>
             </mat-form-field>
             </div>
       

       
       
       
         <mat-card-actions >

             <button mat-raised-button color="primary" (click)="this.deleteTeam(this.editteamFG.value.teamname, this.configSvc.activeteamid)">Delete Team</button>

           <span >
           <button [disabled]= "!this.editteamFG.dirty" mat-raised-button color="primary"  (click)="this.updateTeam(this.editteamFG.value.teamname, this.editteamFG.value.teamdesc, this.configSvc.activeteamid);">Update Team</button>
                       </span>


     

           
        </mat-card-actions></mat-card>
</mat-tab>

<mat-tab *ngIf="this.configSvc.access == 'mgr'">
  <ng-template mat-tab-label>
    <mat-icon>admin_panel_settings</mat-icon>
   Manager
  </ng-template>
  <mat-card class = "details-container">
      <app-teamsecurity></app-teamsecurity></mat-card>
</mat-tab>



  <mat-tab *ngIf="this.configSvc.access == 'mgr'">
    <ng-template mat-tab-label>
      <mat-icon>admin_panel_settings</mat-icon>
     Users
    </ng-template>
    <mat-card class = "details-container">
        <app-usersecurity></app-usersecurity></mat-card>
  </mat-tab>
  
  <mat-tab *ngIf="this.configSvc.access == 'mgr'">
  <ng-template mat-tab-label>
    <mat-icon>palette</mat-icon>
   Design </ng-template>
                        
                          <span *ngIf="this.configSvc.teamdetailsready">
                            <mat-card class = "details-container">
                            <app-iconcategories></app-iconcategories>
                          <app-color-picker (color)="this.configSvc.teamdetailsds.clr"></app-color-picker>
                        </mat-card></span>
                          <button mat-raised-button color="primary" (click)="this.configSvc.changeteamcolor(this.configSvc.activeitemid, this.configSvc.color)">Change Colour</button>
                       
</mat-tab>

<mat-tab *ngIf="this.configSvc.access == 'mgr'">
  <ng-template mat-tab-label>
    <mat-icon>send</mat-icon>
   Invites </ng-template>
                        
                          <span *ngIf="this.configSvc.teamdetailsready">
                            <mat-card class = "details-container">
                        <app-invites></app-invites>
                        </mat-card></span>
                         
                       
   </mat-tab>


   <mat-tab  [formGroup]="editteamFG"  >
    <ng-template mat-tab-label>
      <span *ngIf="!this.configSvc.teamdetailsds?.tagged">   <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': 'orange'}">bookmarks</i></span>
      <span *ngIf="this.configSvc.teamdetailsds?.tagged">   <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i></span>
     <span *ngIf="this.configSvc.teamdetailsds?.tagged">{{this.configSvc.teamdetailsds?.nodename}}</span> 
    
    </ng-template>
    <span *ngIf="this.configSvc.teamdetailsready && this.configSvc.teamdetailsds?.tagged">
      <app-teamtags></app-teamtags></span>

      <span *ngIf="this.configSvc.teamdetailsready && !this.configSvc.teamdetailsds?.tagged">
      No Team Tags are Applied

      <div >
        <mat-form-field appearance="fill">
          <mat-label>Assign</mat-label>
         
          <input type="text"  
          formControlName="foldertagsearch"  
          [matAutocomplete]="auto"
        
          
          matInput (keyup)="foldertagsFilter($event)" placeholder="Team Tags" autocomplete="off" >
          
          <mat-autocomplete #auto="matAutocomplete">
     <mat-option *ngFor="let foldertags of this.configSvc.filterfoldertags" [value]="foldertags">
      {{foldertags.name}} 
    
     </mat-option>
          </mat-autocomplete>
    
         
         </mat-form-field>

         <button mat-raised-button color="primary"
        
         (click)="this.appyTags()">Apply 
         FolderTags</button>
                                  
             
              

</div>
    
    </span>  
  
  </mat-tab>


</mat-tab-group>


