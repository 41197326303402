import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import { DocactionsService } from '../../../services/docactions.service';

@Component({
  selector: 'app-actioninfo',
  templateUrl: './actioninfo.component.html',
  styleUrls: ['./actioninfo.component.css']
})
export class ActioninfoComponent implements OnInit {

  constructor(public configSvc: ConfigService, public docActions: DocactionsService) { }

  ngOnInit() {
    console.log('action info init for type'+this.configSvc.activetype);
    console.log('action info mode'+this.configSvc.mode);
  }

  folderFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering folder on: '+filterValue)
    this.configSvc.folderFilter(this.configSvc.activeitemid, filterValue)
  }
}
