<span  *ngFor="let favourite of prefSvc.favlocations">

   <i class="material-icons" [ngStyle]="{'color': 'yellow'}">folder</i>
    
     


         <a fxFlexFill
            [routerLink]="['/toolbard/home']"
            [queryParams] = "{itemid:favourite.itemid, type:favourite.type, name: favourite.name, level: favourite.level, teamid: favourite.teamid}"
              > {{favourite.name}} 
              </a>


  
  </span>