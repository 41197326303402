import { Component, OnInit } from '@angular/core';

import {TagerService} from '../../services/tager.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { TagService } from '.././../services/tag.service';
import { ConfigService } from '.././../services/config.service';

@Component({
  selector: 'app-editflow',
  templateUrl: './editflow.component.html',
  styleUrls: ['./editflow.component.css']
})
export class EditflowComponent implements OnInit {

  editflowFG: FormGroup;
  foldertagtypes = ['none', 'colorfolder', 'bookmark']

  constructor(private _formBuilder: FormBuilder, public tagerSvc: TagerService, public configSvc: ConfigService,
    public tagSvc: TagService) { }

  ngOnInit() {

    this.configSvc.colorinputtype = 'flow';
    
    this.editflowFG = this._formBuilder.group({
     name: [this.configSvc.flowdetailsds[0].name, Validators.required],
     desc: [this.configSvc.flowdetailsds[0].desc, Validators.required],
     statetracker:[this.configSvc.flowdetailsds[0].statetracker, Validators.required],
     statelabel:[this.configSvc.flowdetailsds[0].statelabel, Validators.required],
     icontype: [this.configSvc.flowdetailsds[0].icontype, Validators.required],
     iconcolor: [this.configSvc.flowdetailsds[0].iconcolor, Validators.required],
      type: [this.configSvc.flowdetailsds[0].type, Validators.required],
      textractable: [this.configSvc.flowdetailsds[0].textractable, Validators.required],
      defaultvalue: ['', Validators.required],
      ischatgpt: [this.configSvc.nodeds[0].ischatgpt, Validators.required],
      defaultprompt: [this.configSvc.nodeds[0].defaultprompt, Validators.required],
      
    });
  }

  updateNode(){

    // this.tagerSvc.editTager(this.tagerSvc.hlKanbanset[0]._id, 
     //  this.foldertagactionFG.value.nodename, this.foldertagactionFG.value.tagerdesc,
     //   this.foldertagactionFG.value.tagertype, this.foldertagactionFG.value.category)
     //   this.foldertagactionFG.markAsPristine();
     console.log('current level '+this.configSvc.activelevel)
     console.log('current nodepathds '+JSON.stringify(this.configSvc.nodepathds));
     console.log('current nodepathds level1 '+JSON.stringify(this.configSvc.nodepathds[1]));
     var pathid = this.configSvc.nodepathds[this.configSvc.activelevel].path._id
     console.log('current pathid trying to update '+pathid)
 
     this.configSvc.updateNode(this.configSvc.flowdetailsds[0]._id, this.editflowFG.value.name,
       this.editflowFG.value.desc, this.editflowFG.value.type, this.configSvc.chosenIcon, 
       this.configSvc.color, pathid, this.editflowFG.value.textractable,  this.editflowFG.value.textractype,
       this.editflowFG.value.ischatgpt, this.editflowFG.value.defaultprompt )
       this.editflowFG.markAsPristine(); this.configSvc.colorchanged =false;
   }
}
