import { Component, OnInit } from '@angular/core';
import { DoccommentsService } from '../../services/doccomments.service';
import {ConfigService} from '.././../services/config.service';
import { AuthenticationService } from '.././../services/authentication.service';

@Component({
  selector: 'app-viewcomments',
  templateUrl: './viewcomments.component.html',
  styleUrls: ['./viewcomments.component.scss']
})
export class ViewcommentsComponent implements OnInit {

   public clear = false
public textarearowmin = 1;
public textarearowmax = 10;
public comments = []

  constructor(private commenttSvc: DoccommentsService, public configSvc: ConfigService, public auth: AuthenticationService) { }

  ngOnInit() {

    console.log('initing viewcomments')
    if(this.configSvc.popup){
      console.log('popup is '+this.configSvc.popup);
     // const lastItem = myArray.pop();
      this.comments = [this.configSvc.nodeds[0].comments.pop()]

 // this.comments = this.configSvc.nodeds}
  console.log('last comments is '+JSON.stringify(this.comments));
  }

  if(!this.configSvc.popup){
    console.log('popup is '+this.configSvc.popup);
   // const lastItem = myArray.pop();
    this.comments = this.configSvc.nodeds[0].comments

// this.comments = this.configSvc.nodeds}
console.log('last comments is '+JSON.stringify(this.comments));
}


}

  clearNotification(){
    console.log('clearing notification')
    this.commenttSvc.clearNotification();
    this.clear = true
  }

  clearComment(itemid, type, commentid){
    console.log('clearing comment for itemid '+itemid)
    console.log('clearing comment for comment id'+commentid)
var last  = false
    if(this.configSvc.nodeds[0].comments.length == 1){
      last = true
    }
    this.commenttSvc.clearComment(itemid, type, commentid, last);
  }

}
