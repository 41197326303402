import { Component, OnInit, Inject, Input } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../../services/config.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import {SidenavService} from '../../../services/sidenav.service';

export interface DialogData {
 
  name: string;
  type: string;
}

@Component({
  selector: 'app-roottools',
  templateUrl: './roottools.component.html',
  styleUrls: ['./roottools.component.css']
})
export class RoottoolsComponent implements OnInit {

  constructor(public configSvc: ConfigService,  public dialog: MatDialog, public sidenavSvc: SidenavService) { }

  ngOnInit(): void {
  }

  getrootToolbars(){
    this.sidenavSvc.toggle('navigation')
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sidenavSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
  }

  openDialog(type, name): void {

    console.log('popup name is'+name)
    console.log('popup type is'+type)
  
var popupheight = '500px'
var popupwidth= '400px'

      popupheight = '650px'; popupwidth= '1200px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'team';
    this.configSvc.activetype = 'createteam';
    
    if(type == 'comment'){
       popupheight = '650px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'orange'
      this.configSvc.commenticon = 'chat'
    }
    const dialogRef = this.dialog.open(RootToolsPopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.tagupload = false;
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

}

@Component({
  selector: 'app-roottoolspopup',
  templateUrl: 'roottoolspopup.html',
  styleUrls: ['./roottools.component.css']
})
export class RootToolsPopup {


  @Input() teamtags: boolean;
  @Input() foldertags = true;
  @Input() kanbanned: boolean;
  public indextype = '0';
  
  isLinear = true;
  step1 = "step1";
  step2= "step2";
  step3= "step3";
  step4= "step4";

  @Input() teamtype: string;


  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;
    teamfileFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    public csvRecords: any[] = [];
    public header = true;
 

  constructor(private _formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<RootToolsPopup>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,  private authService: AuthenticationService, 
    public configSvc: ConfigService, 
) {}

    ngOnInit() {
  
      this.teamfileFG = this._formBuilder.group({
        teamname: [''],
        teamdescription : '',
        colors: 'black',
        checked: false,
        teams: [],
        tagstep: 1
      });

    

      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /*
  addFolder(name, indextype){
    this.configSvc.addFolder('fold', name, 
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');
      this.dialogRef.close();
  }
  */



  public addMyself(){
    this.configSvc.mgrsadded = [];
    const userdetails  = this.authService.getUserDetails();
    console.log('this is the managers details'+JSON.stringify(userdetails))
    const username = userdetails.username
    this.configSvc.mgrsadded.push(username)
    console.log('activetype after adding myself ist'+this.configSvc.activetype)
   // this.configSvc.findUser(mgremail, 'addmyself');
   // this.myself = userdetails;
   this.configSvc.activetype = 'team'
   this.configSvc.usersadded = []
  // this.configSvc.mgrsadded.push(userdetails);


  }

  

}

