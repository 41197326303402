import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {DocSearchService} from '../../services/docsearch.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-queueresults',
  templateUrl: './queueresults.component.html',
  styleUrls: ['./queueresults.component.css']
})
export class QueueresultsComponent implements AfterViewInit {

// displayedColumns = [];
public  activeColumns = ['icon','ab','status', 'ao', 'assignedto', 'name'];

dataSource = new MatTableDataSource(this.configSvc.queueresults);


// customColumns = [];

constructor(public configSvc: ConfigService, public searchDocsvc: DocSearchService) { }

@ViewChild(MatSort) sort: MatSort;

ngAfterViewInit() {
  this.dataSource.sort = this.sort;
}






}
