<span *ngIf = "data.type=='choiceset'"><i class="material-icons icon-docaddpopup">chat</i>
    <span mat-dialog-content>
     Create Prompt Set
      
    </span>
    <div mat-dialog-actions>
     
      <mat-card class = "build-container">
  
      
                 <span ><mat-card-title>Prompt Set Configuration</mat-card-title></span> 
                  <mat-horizontal-stepper [linear]="isLinear" #stepper>
          
                    <mat-step [stepControl]="step1"> 
                      <ng-template matStepLabel>Prompt Set </ng-template>
                      <mat-form-field>
                        <mat-label>Prompt Name</mat-label>
                        <input matInput [(ngModel)]="data.name">
                      </mat-form-field>
                
                <div>
                      <mat-form-field>
                        <mat-label>Prompt Desciption</mat-label>
                        <input matInput [(ngModel)]="data.desc">
                      </mat-form-field></div>
  
                   
  
                      <div><button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                      <button mat-raised-button color="primary"  mat-button matStepperNext (click)="this.addMyself()">Next</button></div>
              </mat-step>
  
              <mat-step [stepControl]="step2">
                <ng-template matStepLabel>Prompt Set Managers </ng-template>
                  
                       <app-teamsecurity></app-teamsecurity>   
                       <div mat-dialog-actions>
                        <button mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
                        <button mat-raised-button color="primary"  (click)="createChoiceset(data.name, data.desc, true)">Create Prompt Set</button>
                        
                      </div>    
                                  
                </mat-step>
                        
          </mat-horizontal-stepper>
        </mat-card>
    </div>
    </span>
  
  <span *ngIf = "data.type=='choice'"> 
    <i MatPrefix class="material-icons icon-edit" title="Add Prompt" >add_comment</i><b>Add Prompt</b>
      <div mat-dialog-content>

        <mat-form-field class = "textarea">
          <mat-label>Prompt Name</mat-label>
          <input matInput [(ngModel)]="data.choice">
        </mat-form-field>
  
      
<div>
        <mat-form-field appearance="outline"   class = "textarea">
          <mat-label>Prompt Description</mat-label>
          <textarea
          
          cdkTextareaAutosize
          cdkAutosizeMinRows=10
          cdkAutosizeMaxRows=15
          
          matInput [(ngModel)]="data.desc" placeholder="Prompt"></textarea>
         </mat-form-field></div>
  
  
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button color="primary" class = "btn1" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary"  (click)="createChoiceitem(data.choice, data.desc)">Create Prompt</button>
        
      </div>
  </span>
  
  <span *ngIf = "data.type=='delete'"><i class="material-icons" [ngStyle]="{'color': 'black'}">delete</i>
    Delete Choice
    <div mat-dialog-content>
   
  
  
    
  
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="deleteChoiceitem()">Delete Choice</button>
      
    </div>
  </span>