<span class="scrollmenu">
 

    
   
    
    <span *ngIf="this.configSvc.activetool == 'addcomment'">
        <i class="material-icons icon-edit" title="Add Comment">chat</i>
      </span>
    
    <span *ngIf="this.configSvc.activetool != 'addcomment'">
    <i class="material-icons icon-comment" title="Add Comment" 
     (click)="this.openDialog('comment', '')">chat</i>
    </span>

   
  


    <span>
      <mat-icon class="custom"  title="Ask ChatGPT" svgIcon="chat1"
      (click)="this.openDialog('askchatgpt', 'Ask ChatGPT a Question')"
      ></mat-icon></span>


    
     <span>
    
    
      <span *ngIf="this.configSvc.nodeds[0]?.ed == false">
         <i class="material-icons icon-edit" title="Open and Edit {{this.configSvc.nodeds[0]?.name}}" 
         (click)="this.docActions.downloadFile();this.docActions.editFile();">lock</i></span>
    
    
      <span *ngIf="this.configSvc.nodeds[0]?.ed == true && this.configSvc.nodeds[0]?.mb == auth.getUserDetails()?.username "> 
        <i class="material-icons icon-edit" title="Checkin {{this.configSvc.nodeds[0]?.name}}" 
        (click)="this.openDialog('unlock', '')">lock_open</i></span>
  
        <span>
          <i  class="material-icons icon-edit"  title="Download {{this.configSvc.nodeds[0]?.name}}" 
          (click)=" this.docActions.downloadFile();">save_alt</i></span>
      
     
    
         <span > 
        
          <i class="material-icons icon-edit"  title="Delete {{this.configSvc.nodeds[0]?.name}}"
           (click)="this.openDialog('deletefile', this.configSvc.activename)">delete</i></span>
      
    
          
    
      <span  *ngIf="this.configSvc.activetype != 'undefined'"><a href="mailto:fehin@example.com?subject=Please find attached toolbard link for your reference {{this.configSvc.activename}}&body=Find below a link for you to review {{this.configSvc.activeurl}}"><i class="material-icons icon-edit"  title="Email {{this.configSvc.hlitem[0]?.name}}" >email</i></a> </span>
    </span>
  

  
       <span>  <i class="material-icons icon-edit"  title="Move {{this.configSvc.nodeds[0]?.name}}"
         (click)="this.openDialog('cutItem', this.configSvc.activename)">content_cut</i></span>
  
         <span *ngIf="this.configSvc.activetype == 'fold' && this.configSvc.move"> 
      
          <i class="material-icons icon-edit"  title="Paste {{this.configSvc.moveitem[0]?.name}}"
           (click)="this.openDialog('pasteItem', this.configSvc.moveitem[0]?.name)">content_paste</i></span>
  
           <span>
            <i class="material-icons icon-edit"  title="Assign File"
            (click)="this.openDialog('assign', this.configSvc.activename)">person_pin_circle</i></span>
    


  
  
            <span>
              <i class="material-icons icon-edit"  title="Deadline"
              (click)="this.openDialog('deadline', this.configSvc.activename)">timer</i></span>

              <span>
      
                <i class="material-icons icon-edit" title="State"
                 (click)="this.openDialog('state', this.configSvc.activename)">check_circle_outline </i>
              </span>
        
          
    
              
               
    
    