import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {ConfigService} from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenav: MatSidenav;
  public activesidenav = 'nonavigation'

  constructor(public configSvc: ConfigService) { }

 

 public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
   this.sidenav.open();
  }


  public close() {
    this.sidenav.close();
  }

  public toggle(type): void {
    console.log('active sidenav is '+this.activesidenav);
    console.log('trying to toggle'+type)
    this.activesidenav = type
    this.sidenav.toggle(!this.configSvc.fullpage);
    this.configSvc.fullpage = !this.configSvc.fullpage
    console.log('full page is'+this.configSvc.fullpage);
    console.log('hide browser is '+this.configSvc.hidebrowser);
    console.log('active sidenav is '+this.activesidenav);
    console.log('active type is currently before deselect'+this.configSvc.activetype);
    this.configSvc.deselect(this.configSvc.activetype);
   
  }
}
