import { Component, OnInit } from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ConfigService} from '../../services/config.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-imageversions',
  templateUrl: './imageversions.component.html',
  styleUrls: ['./imageversions.component.css']
})
export class ImageversionsComponent implements OnInit {
  public reset: any[] = [{}];
  versionColumns = [ 'ver' ,'name', 'ab', 'ao', 'desc'];
  public itemselected = false;
  public itemsadded = [];
  public clickCount = 0;

  constructor(public uploadSvc: UploadService, public configSvc: ConfigService, private router: Router) { }

  ngOnInit(): void {
    console.log('lets get latest photo')
    this.uploadSvc.getLatestphoto(this.configSvc.nodeds[0].photoid)
  }

  public selectItem(item){
    this.configSvc.tageritemready = false
    this.reset[0] = {}
    this.itemselected = false;
    console.log('is item selected? '+this.itemselected)
    this.itemsadded = []
this.itemsadded.push(item)
console.log('selected  items '+JSON.stringify(this.itemsadded))
this.configSvc.nodeds[0]= item
console.log('this is the active node details '+JSON.stringify(this.configSvc.nodeds[0]))
this.configSvc.tageritemready = true;  this.itemselected = true;
console.log('tageritemready set to '+this.configSvc.tageritemready);
// this.uploadSvc.getImagebyid(item.vk, item.ver)

  
  }

 

click(item) {
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 1) {
            console.log('this is a single click')
            this.itemselected = true;
    console.log('is item selected? '+this.itemselected)
    this.itemsadded = []
this.itemsadded.push(item)
console.log('selected items '+JSON.stringify(this.itemsadded))
        } else if (this.clickCount === 2) {
          console.log('this is a double click')
      
          this.itemselected = true;
  
  this.itemsadded = []
this.itemsadded.push(item)
console.log('this is the image item info'+JSON.stringify(this.itemsadded[0]))
var base = '/toolbard/imagevers?'
var itemid = this.itemsadded[0]._id
var teamid = this.configSvc.nodeds[0].teamid;
var dynamicurl = base+'itemid='+itemid+'&type=imagedirect&teamid='+teamid
this.configSvc.tageritemready = false;

this.router.navigateByUrl(dynamicurl);


        }
        this.clickCount = 0;
    }, 250)
}

}
