import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
// import { RightSidenavComponent } from '../right-sidenav/right-sidenav.component';

@Injectable()
export class SidenavService {
	private sidenav: MatSidenav;
  public opened = false;
  public getScreenWidth: any;
  public demo = 'ecm'
  public getScreenHeight: any;
	public setSidenav(sidenav: MatSidenav) {
		this.sidenav = sidenav;
    console.log('setting sidenav on sbvc init')
	}

	public open() {
		return this.sidenav.open();
	}


	public close() {
		return this.sidenav.close();
	}

	public close2(state) {
		this.sidenav.toggle(!state);
	}

	public toggle() {
    console.log(this.sidenav);
		return this.sidenav.toggle();
	}
}