<div class="signin-content">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="login()">
            <mat-icon title="Login">
                <i class="fas fa-lock" data-fa-transform="grow-2" ></i></mat-icon> Please login to access AdHoc Solutions
          <mat-form-field class="full-width-input">
            <input matInput placeholder="Username" formControlName="username" required>
            <mat-error *ngIf="isFieldInvalid('username')">
              Please enter your user name
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-error *ngIf="isFieldInvalid('username')">Please enter your password</mat-error>
  
          </mat-form-field>
          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
          <button mat-raised-button color="primary">Login</button>
        </form>
        <p></p>
          No company registered yet? Please <a routerLink="/registercompany">Register Company</a> here.
          <p></p>
          Forgotten password? Please <a routerLink="/resetemail">Reset Password</a> here.
      </mat-card-content>
    </mat-card>
  </div>
  