import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import { ConfigService } from '.././../services/config.service';

@Component({
  selector: 'app-editstep',
  templateUrl: './editstep.component.html',
  styleUrls: ['./editstep.component.css']
})
export class EditstepComponent implements OnInit {

  editstepFG: FormGroup;
  public stepid: String;
  constructor(private _formBuilder: FormBuilder,  public configSvc: ConfigService) { }

  ngOnInit() {
 

    console.log('initializing the step with: '+JSON.stringify(this.configSvc.stepdetailsds[0]))
    
    this.configSvc.toolmenu = 'tagtools'
    this.stepid =  this.configSvc.stepdetailsds[0]._id
  console.log('active stepid is'+this.stepid )
    this.configSvc.color = this.configSvc.stepdetailsds[0].iconcolor
    this.configSvc.chosenIcon = this.configSvc.stepdetailsds[0].icontype
    this.editstepFG = this._formBuilder.group({
      step: [this.configSvc.stepdetailsds[0].step, Validators.required],
      addedby: [this.configSvc.stepdetailsds[0].ab, Validators.required],
      modifiedby: [this.configSvc.stepdetailsds[0].mb, Validators.required],
      addedon: [this.configSvc.stepdetailsds[0].ao, Validators.required],
      modifiedon: [this.configSvc.stepdetailsds[0].mo, Validators.required],
      desc: [this.configSvc.stepdetailsds[0].desc, Validators.required],
      icontype: [this.configSvc.stepdetailsds[0].icontype, Validators.required],
      iconcolor: [this.configSvc.stepdetailsds[0].iconcolor, Validators.required],
      stepid: [this.configSvc.stepdetailsds[0]._id, Validators.required],
      tool: [this.configSvc.stepdetailsds[0]?.tool, Validators.required],
      toolindex: [this.configSvc.stepdetailsds[0]?.toolindex, Validators.required],
      toolinval: [this.configSvc.stepdetailsds[0]?.toolinval, Validators.required],
       changecolor: [false, Validators.required],
     
     
       
     });
     this.configSvc.stepready
  }

  public updateStep(){
    console.log('updating tab step design now')

    this.configSvc.updateStep(this.configSvc.nodeds[0]._id, this.editstepFG.value.stepid, this.editstepFG.value.step,
      this.editstepFG.value.desc, this.configSvc.chosenIcon, 
      this.configSvc.color)
      this.editstepFG.markAsPristine(); 
      this.configSvc.colorchanged =false;
  }
  

  public deleteStep(){
    console.log('deleting step now')
    this.configSvc.deleteStep(this.configSvc.activeitemid, this.configSvc.stepdetailsds[0]._id);

  }

}
