
  
  
  
  <span>
    <i MatPrefix class="material-icons icon-edit"  title="Add Choiceset" 
    (click)="this.openChoicetool('choiceset', 'add', 'desc', false)"
    >playlist_add</i><span>
  
  
  <span>
    <i MatPrefix class="material-icons icon-edit" title="Add Choice" 
    (click)="this.openChoicetool('choice', 'Enter Choice', 'desc', false)"
  >sort_by_alpha</i><span>
  
 