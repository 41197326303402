import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {TagService} from '../../services/tag.service';

@Component({
  selector: 'app-textraction',
  templateUrl: './textraction.component.html',
  styleUrls: ['./textraction.component.css']
})
export class TextractionComponent implements OnInit {

   public textdata = []
   public textcolumns = [ 'key', 'value', 'match', 'link'];
   public itemselected = false;
   public applyproperty = false;
   public indexselected: Number;
   public itemsadded = [];
   public indexoptions = [];
   public selectedProperty = []
   public property = [];
  constructor(public configSvc: ConfigService, public tagSvc: TagService) { }

  ngOnInit(): void {
    console.log('initing textraction')
    this.textdata = this.configSvc.nodeds[0].textraction
    console.log('initing textdata '+JSON.stringify(this.textdata))

  }

  public selectItem(item, index){
    
    this.indexselected = index
    console.log('is item selected? '+this.itemselected)
    this.itemsadded = []; this.indexoptions = []
this.itemsadded.push(item)
console.log('items selected in textraction is '+JSON.stringify(this.itemsadded));
for(const nodeitems of this.configSvc.nodeds[0].stepitems){
  this.indexoptions.push(nodeitems)}
  this.itemselected = true;
  }

  public chooseProperty(){
    console.log('you have chosen to set the property '+this.selectedProperty)
    console.log('you have chosen to set maimn property '+JSON.stringify(this.selectedProperty))
    this.property = [];
    this.property.push(this.selectedProperty)
    this.applyproperty = true
  }

  public assignKey(){
 
    console.log('find this property '+this.property[0].formitem)
    console.log('id of the property '+this.property[0]._id)
   // console.log('selected property  '+JSON.stringify(this.itemsadded));
    console.log('update to this value'+this.itemsadded[0].value);
    console.log('from this property'+this.itemsadded[0].key);
    console.log('update for '+this.configSvc.nodeds[0]._id);
    this.tagSvc.assignKey(this.configSvc.nodeds[0]._id, this.property[0].formitem,this.itemsadded[0].key, this.itemsadded[0].keyid, this.itemsadded[0].value, this.property[0]._id )
    this.applyproperty = false
  }

}
