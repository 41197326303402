import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { ConfigService } from '../services/config.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
  
    private auth: AuthenticationService, 
    private router: Router, 
    private configSvc: ConfigService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      //Get URL from State
      let url: string = state.url;
    if (!this.auth.isLoggedIn()) {
      this.configSvc.currentURL = url;
      console.log('this is the path we are trying to get to: '+this.configSvc.currentURL)
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
