
 
   

        <span [formGroup]="detailsFG" class = "details-container">

          <div *ngIf="this.configSvc.nodeds[0]?.statetracker"> 
         
            <div class= "labels"> 
       
              <mat-slide-toggle
              [checked] = this.configSvc.nodeds[0].state
              (change)="setState(this.configSvc.nodeds[0].statelabel)">
                                      
               {{this.configSvc.nodeds[0].statelabel}}
                </mat-slide-toggle>
          
               
              </div> 
       
             </div>
          
            <div>  
              <mat-form-field appearance="fill" floatLabel="always" > 
                <mat-label>Title</mat-label>
                    <input formControlName="title" matInput value="{{this.configSvc.nodeds[0].ti}}">
                   </mat-form-field>
                </div> 
             
              <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                  <mat-label >FileName</mat-label><input matInput [readonly]= "true" formControlName="name" matInput value={{this.configSvc.nodeds[0].name}}>
                </mat-form-field> </div>
  
                <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                    <mat-label >Added By</mat-label><input matInput [readonly]= "true" formControlName="addedby" matInput value={{this.configSvc.nodeds[0].ab}}>
                  </mat-form-field> </div>
  
                  <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                    <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
                    [ngModelOptions]="{standalone: true}"
                    [ngModel]="this.configSvc.nodeds[0].ao | date: 'medium'"
                    >
                  </mat-form-field> </div>

                  <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                    <mat-label >Last Modified On</mat-label><input matInput [readonly]= "true" 
                    [ngModelOptions]="{standalone: true}"
                    [ngModel]="this.configSvc.nodeds[0].mo | date: 'medium'"
                    >
                  </mat-form-field> </div>
  
                  <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                      <mat-label >Version</mat-label><input matInput [readonly]= "true" formControlName="ver" matInput value={{this.configSvc.nodeds[0].ver}}>
                    </mat-form-field> </div>
                  
                    <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                        <mat-label >Locked for Editing</mat-label><input matInput [readonly]= "true" formControlName="ed" matInput value={{this.configSvc.nodeds[0].ed}}>
                      </mat-form-field> </div>

                      <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                        <mat-label >Last Modified By</mat-label><input matInput [readonly]= "true" formControlName="mb" matInput value={{this.configSvc.nodeds[0].mb}}>
                      </mat-form-field> </div>
  
                    
  
                        <div><mat-form-field appearance="outline" floatLabel="always" >
                  
                          <mat-label >Tagged</mat-label><input matInput [readonly]= "true" formControlName="tagged" matInput value={{this.configSvc.nodeds[0].tagged}}>
                        </mat-form-field> </div>
  
                        <div *ngIf="this.configSvc.nodeds[0].tagged"><mat-form-field appearance="outline" floatLabel="always" >
                  
                          <mat-label >Tags Based On</mat-label><input matInput [readonly]= "true" formControlName="nodename" matInput value={{this.configSvc.nodeds[0].nodename}}>
                        </mat-form-field> </div>
  
             
                
             
                <div> <mat-form-field appearance="outline" floatLabel="always" >
                    <mat-label>DocID</mat-label><input  formControlName="id" matInput placeholder={{this.configSvc.nodeds[0]._id}}>
                 </mat-form-field></div>
               
  
                
                 <div> <mat-form-field appearance="outline" floatLabel="always" >
                    <mat-label>MimeType</mat-label><input formControlName="mt" matInput placeholder={{this.configSvc.nodeds[0].mt}}>
                 </mat-form-field></div>
               
  
                
                 <div> <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>BatchNumber</mat-label><input  formControlName="bn" matInput placeholder={{this.configSvc.nodeds[0].bn}}>
                 </mat-form-field></div>
                
  
                
                 <div>  <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>FileCount</mat-label><input  formControlName="fc" matInput placeholder={{this.configSvc.nodeds[0].fc}}>
                 </mat-form-field></div>
                </span>
                 <div> <button  [disabled]= "!this.detailsFG.dirty" (click)="this.renameItem(this.detailsFG.value.title, this.configSvc.activeitemid);"
                  mat-raised-button color="primary" [disabled]="!detailsFG.dirty">Apply Changes</button>
               </div>
               
  