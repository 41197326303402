<mat-card>
<form [formGroup]="deadlineFG" >
<div class = "labels">
    
    <mat-label>Deadline for {{this.configSvc.activename}}:</mat-label>
</div> 



    <span>
      <mat-form-field appearance="fill">
        <input matInput [matDatepicker]="picker1" placeholder="{{this.deadlineFG.value.deadlinefc | date : 'dd/MM/yyyy'}}" formControlName="deadlinefc">
             <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
             <mat-datepicker  #picker1></mat-datepicker>
           </mat-form-field>
</span>

<div >  <button class = "save"
  (click)="this.clearDeadline()"
 
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Assign">
Clear Deadline </button></div> 

</form>
</mat-card>