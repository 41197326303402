<form [formGroup]="iconcategoriesFG">
  <span  *ngIf = " this.configSvc.formstyle != 'smartfolder'">
    <mat-form-field class = "icondropdown">
      <mat-label>Icon Set</mat-label>
       <mat-select  (selectionChange)="onChange()" [(value)]="selectedCategory" formControlName="iconCategory">
        
       
        <mat-option *ngFor="let category of categories" [value]="category">
          {{category}}
         
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    
      <span *ngIf = "selectedCategory == 'Action'"><mat-form-field class = "icondropdown">
    <mat-label>{{selectedCategory}} Icon</mat-label>
     <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
      <mat-option *ngFor="let actionicon of action" [value]="actionicon"> <mat-icon >{{actionicon}}</mat-icon>
    </mat-option> </mat-select> </mat-form-field></span>

    <span *ngIf = "selectedCategory == 'Alert'"> <mat-form-field class = "icondropdown">
      <mat-label>{{selectedCategory}} Icon</mat-label>
       <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
        <mat-option *ngFor="let alerticon of alert" [value]="alerticon"> <mat-icon >{{alerticon}}</mat-icon>
      </mat-option> </mat-select> </mat-form-field></span>
    
    <span *ngIf = "selectedCategory == 'AV'"> <mat-form-field class = "icondropdown">
    <mat-label>{{selectedCategory}} Icon</mat-label>
     <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
      <mat-option *ngFor="let avicon of av" [value]="avicon"> <mat-icon >{{avicon}}</mat-icon>
        </mat-option> </mat-select> </mat-form-field></span>

        <span *ngIf = "selectedCategory == 'Communication'"> <mat-form-field class = "icondropdown">
          <mat-label>{{selectedCategory}} Icon</mat-label>
           <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
            <mat-option *ngFor="let item of communication" [value]="item"> <mat-icon >{{item}}</mat-icon>
              </mat-option> </mat-select> </mat-form-field></span>
    
     
    
          <span *ngIf = "selectedCategory == 'Connection'"> <mat-form-field class = "icondropdown">
            <mat-label>{{selectedCategory}} Icon</mat-label>
             <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
              <mat-option *ngFor="let connectionicon of connection" [value]="connectionicon"> <mat-icon >{{connectionicon}}</mat-icon>
            </mat-option> </mat-select> </mat-form-field></span>
    
            <span *ngIf = "selectedCategory == 'Device'"> <mat-form-field class = "icondropdown">
              <mat-label>{{selectedCategory}} Icon</mat-label>
               <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                <mat-option *ngFor="let deviceicon of device" [value]="deviceicon"> <mat-icon >{{deviceicon}}</mat-icon>
              </mat-option> </mat-select> </mat-form-field></span>

              <span *ngIf = "selectedCategory == 'Editor'"> <mat-form-field class = "icondropdown">
                <mat-label>{{selectedCategory}} Icon</mat-label>
                 <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                  <mat-option *ngFor="let item of editor" [value]="item"> <mat-icon >{{item}}</mat-icon>
         </mat-option> </mat-select> </mat-form-field></span>

         <span *ngIf = "selectedCategory == 'File'"> <mat-form-field class = "icondropdown">
          <mat-label>{{selectedCategory}} Icon</mat-label>
           <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
            <mat-option *ngFor="let item of file" [value]="item"> <mat-icon >{{item}}</mat-icon>
              </mat-option> </mat-select> </mat-form-field></span>

                    <span *ngIf = "selectedCategory == 'Hardware'"> <mat-form-field class = "icondropdown">
                      <mat-label>{{selectedCategory}} Icon</mat-label>
                       <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                        <mat-option *ngFor="let item of hardware" [value]="item"> <mat-icon >{{item}}</mat-icon>
              </mat-option> </mat-select> </mat-form-field></span>
    
              <span *ngIf = "selectedCategory == 'Home'"> <mat-form-field class = "icondropdown">
                <mat-label>{{selectedCategory}} Icon</mat-label>
                 <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                  <mat-option *ngFor="let item of home" [value]="item"> <mat-icon >{{item}}</mat-icon>
         </mat-option> </mat-select> </mat-form-field></span>


         <span *ngIf = "selectedCategory == 'Maps'"> <mat-form-field class = "icondropdown">
          <mat-label>{{selectedCategory}} Icon</mat-label>
           <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
            <mat-option *ngFor="let item of maps" [value]="item"> <mat-icon >{{item}}</mat-icon>
   </mat-option> </mat-select> </mat-form-field></span>


              <span *ngIf = "selectedCategory == 'Image'"> <mat-form-field class = "icondropdown">
                <mat-label>{{selectedCategory}} Icon</mat-label>
                 <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                  <mat-option *ngFor="let imageicon of image" [value]="imageicon"> <mat-icon >{{imageicon}}</mat-icon>
                </mat-option> </mat-select> </mat-form-field></span>
    
                <span *ngIf = "selectedCategory == 'Navigation'"> <mat-form-field class = "icondropdown">
                  <mat-label>{{selectedCategory}} Icon</mat-label>
                   <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                    <mat-option *ngFor="let navigationicon of navigation" [value]="navigationicon"> <mat-icon >{{navigationicon}}</mat-icon>
                  </mat-option> </mat-select> </mat-form-field></span>
    
                  <span *ngIf = "selectedCategory == 'Notification'"> <mat-form-field class = "icondropdown">
                    <mat-label>{{selectedCategory}} Icon</mat-label>
                     <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                      <mat-option *ngFor="let notificationicon of notification" [value]="notificationicon"> <mat-icon >{{notificationicon}}</mat-icon>
              </mat-option> </mat-select> </mat-form-field></span>
           
              <span *ngIf = "selectedCategory == 'Places'"> <mat-form-field class = "icondropdown">
                <mat-label>{{selectedCategory}} Icon</mat-label>
                 <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                  <mat-option *ngFor="let placesicon of places" [value]="placesicon"> <mat-icon >{{placesicon}}</mat-icon>
                </mat-option> </mat-select> </mat-form-field></span>

                <span *ngIf = "selectedCategory == 'Search'"> <mat-form-field class = "icondropdown">
                  <mat-label>{{selectedCategory}} Icon</mat-label>
                   <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                    <mat-option *ngFor="let item of search" [value]="item"> <mat-icon >{{item}}</mat-icon>
           </mat-option> </mat-select> </mat-form-field></span>

           <span *ngIf = "selectedCategory == 'Toggle'"> <mat-form-field class = "icondropdown">
            <mat-label>{{selectedCategory}} Icon</mat-label>
             <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
              <mat-option *ngFor="let item of toggle" [value]="item"> <mat-icon >{{item}}</mat-icon>
     </mat-option> </mat-select> </mat-form-field></span>

     
    
    
          <span *ngIf = "selectedCategory == 'Social'"> <mat-form-field class = "icondropdown">
            <mat-label>{{selectedCategory}} Icon</mat-label>
             <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
              <mat-option *ngFor="let socialicon of social" [value]="socialicon"> <mat-icon >{{socialicon}}</mat-icon>
                </mat-option> </mat-select> </mat-form-field></span>
    
    
              </span>
    
              <span  *ngIf = " this.configSvc.formstyle == 'smartfolder'">
                <mat-form-field class = "icondropdown">
                  <mat-label>Folder Icon</mat-label>
                   <mat-select  (selectionChange)="onChange()"  [(value)]="iconcategoriesFG.value.icontype" formControlName="icontype" required>
                    <mat-option *ngFor="let foldericon of folders" [value]="foldericon"> <mat-icon >{{foldericon}}</mat-icon>
                  </mat-option> </mat-select> </mat-form-field>
              </span>

             
    
      </form>