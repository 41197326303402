import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {ConfigService} from '.././../services/config.service';

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.component.html',
  styleUrls: ['./editcategory.component.css']
})
export class EditcategoryComponent implements OnInit {

  editcategoryFG: FormGroup;
  newfoldervalue = '';
  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit() {

    this.editcategoryFG = this.fb.group({
      name: [''],
      categoryname:  [''],
     
   });

   this.onChanges();
 }





 
 onChanges(): void {
  this.editcategoryFG.valueChanges.subscribe(val => {
  console.log('the value has changed to '+JSON.stringify(val))
  this.newfoldervalue = this.editcategoryFG.get('categoryname').value;
  this.configSvc.datachanged = true;
  });
}
  

  reset() {
    this.editcategoryFG.reset();
   
  }
  
}
