<span class="scrollmenu">



    <span >
      <i MatPrefix class="material-icons icon-string" title=" Find Existing Forms, Tags or Kanbans"
      (click)="this.openTagtool('find', '')">search</i>
   </span>
  
  <span>
    <i MatPrefix class="material-icons icon-string" title="Add String" 
    (click)="this.openTagtool('string', 'stringname')"
    >text_format</i><span>

     

  
  
  <span>
    <i MatPrefix class="material-icons icon-string" title="Add Number" 
    (click)="this.openTagtool('number', 'Enter Number')"
  >filter_1</i><span>
  
    <span>
      <i MatPrefix class="material-icons icon-string" title="Add Slider" 
      (click)="this.openTagtool('boolean', 'Slider name')"
    >toggle_off</i><span>
  
      <span>
        <i MatPrefix class="material-icons icon-string" title="Add Date" 
        (click)="this.openTagtool('date', 'Enter Date')"
  >calendar_month</i><span>

    <span>
      <i MatPrefix class="material-icons icon-string" title="Add DateTime" 
      (click)="this.openTagtool('datetime', 'Enter DateTime')"
>schedule</i><span>

  
  
    <span>
      <i MatPrefix class="material-icons icon-string" title="Add Checkbox" 
      (click)="this.openTagtool('checkbox', 'Enter Checkbox')"
    >check_box</i><span>

   
  
      <span>
        <i MatPrefix class="material-icons icon-string" title="Add TextBox" 
        (click)="this.openTagtool('text', 'Enter Text')"
      >notes</i><span>
  
        <span>
    <i MatPrefix class="material-icons icon-string" title="Assign Choicelist" 
     (click)="this.openTagtool('choicelist', 'Assign Choicelist')" >arrow_drop_down_circle</i><span>
       
      
      <span>
        <i MatPrefix class="material-icons icon-string" title="Assign Treelist" 
        (click)="this.openTagtool('treelist', 'Assign Treelist')" >account_tree</i><span>
  
  
          <span>
            <i MatPrefix class="material-icons icon-string" title="Assign Radio Group" 
            (click)="this.openTagtool('radiogroup', 'Assign Radio Group')"
          >radio_button_checked</i><span>

            <span>
              <i MatPrefix class="material-icons icon-string" title="Add Section" 
              (click)="this.openTagtool('label', 'Enter Label')"
        >label</i><span>
           
  
              <span>
                <i class="material-icons icon-string"  title="Delete Design Item"
                (click)="this.openTagtool('deletedesign', this.configSvc.activename)">delete</i></span>

  
          <span *ngIf="this.configSvc.activetype=='editstep'">
            <i MatPrefix class="material-icons icon-string" title="Stepper Preview" 
            (click)="this.openTagtool('steppreview', 'Step Preview'); "
          >preview</i></span>

          <span> 
      
            <i class="material-icons icon-string"  title="Copy {{this.configSvc.activename}}"
             (click)="this.openTagtool('copy', this.configSvc.activename)">content_copy</i></span>
        
        
             <span *ngIf="!this.configSvc.copy"> 
              
              <i class="material-icons icon-string"  title="Paste {{this.configSvc.activename}}"
               (click)="this.openTagtool('paste', this.configSvc.activename)">content_paste</i></span>
  

               <span  *ngIf = "this.configSvc.activetype == 'smartfolder'">
              
                <i class="material-icons icon-string"  title="Create subfolder"
                 (click)="this.openTagtool('subfolder', this.configSvc.activename)">create_new_folder</i>

                 <span>
                  <i class="material-icons icon-string" title=" Apply Kanban" 
                  (click)="this.openTagtool('kanban', 'Find Kanban')">view_column</i>
                  </span>
                
                </span>

                </span>
          
  
     