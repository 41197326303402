<span *ngIf = "data.type=='choiceset'"><i class="material-icons icon-docaddpopup">group</i>
  <span mat-dialog-content>
   Create Data Set
    
  </span>
  <div mat-dialog-actions>
   
    <mat-card class = "build-container">

    
               <span ><mat-card-title>Data List Setup</mat-card-title></span> 
                <mat-horizontal-stepper [linear]="isLinear" #stepper>
               
               
                  <mat-step [stepControl]="step1">
                    <mat-form-field>
                      <mat-label>Name</mat-label>
                      <input matInput [(ngModel)]="data.name">
                    </mat-form-field>
              
                    <p>ChoiceSet Description</p>
                    <mat-form-field>
                      <mat-label>Desciption</mat-label>
                      <input matInput [(ngModel)]="data.desc">
                    </mat-form-field>

                  <div>
                    <mat-checkbox matInput [(ngModel)]="data.prompt"> Prompt List</mat-checkbox></div>

                    <div><button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                    <button mat-raised-button color="primary"  mat-button matStepperNext (click)="this.addMyself()">Next</button></div>
            </mat-step>

            <mat-step [stepControl]="step2">
              <ng-template matStepLabel>Data Set Managers </ng-template>
                
                     <app-teamsecurity></app-teamsecurity>   
                     <div mat-dialog-actions>
                      <button mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
                      <button mat-raised-button color="primary"  (click)="createChoiceset(data.name, data.desc, data.prompt)">Create ChoiceSet</button>
                      
                    </div>    
                                
              </mat-step>
                      
        </mat-horizontal-stepper>
      </mat-card>
  </div>
  </span>

<span *ngIf = "data.type=='choice'"><i class="material-icons icon-string" [ngStyle]="{'color': 'black'}">sort_by_alpha</i>
    <div mat-dialog-content>
      <p>Enter Choice Value</p>
      <mat-form-field>
        <mat-label>Choice</mat-label>
        <input matInput [(ngModel)]="data.choice">
      </mat-form-field>

      <div>
        <p>Enter Choice Description</p>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput [(ngModel)]="data.desc">
        </mat-form-field>
      </div>


    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="createChoiceitem(data.choice, data.desc)">Create Choice</button>
      
    </div>
</span>

<span *ngIf = "data.type=='delete'"><i class="material-icons" [ngStyle]="{'color': 'black'}">delete</i>
  Delete Choice
  <div mat-dialog-content>
 


  

  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="deleteChoiceitem()">Delete Choice</button>
    
  </div>
</span>