

  <span *ngIf="this.configSvc.nodeds[0].type == 'node' ">
    <app-activenode></app-activenode>
      </span>

  <span *ngIf="this.configSvc.nodeds[0].type == 'wizard' ">

<app-activestepper></app-activestepper>
  </span>

  <span *ngIf="this.configSvc.nodeds[0].type == 'tabbed' ">
    <app-activetabs></app-activetabs>
      </span>
