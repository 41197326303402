import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-filesummary',
  templateUrl: './filesummary.component.html',
  styleUrls: ['./filesummary.component.css']
})
export class FilesummaryComponent implements OnInit {

  public summary = '';
textarearowmin = 5;
texarearowmax = 10;
summaryFG: FormGroup;

  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {

      this.summaryFG = this.fb.group({
  
        prompt: [this.configSvc.nodeds[0].prompt, Validators.required],
        summary: [this.configSvc.nodeds[0].summary, Validators.required],
        
      });
    
  }

}
