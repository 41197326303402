<div class=color-wrapper>
    <app-color-palette [hue]="this.configSvc.hue" (color)="this.configSvc.color = $event"></app-color-palette>
    <app-color-slider (color)="this.configSvc.hue=$event" style="margin-left:16px"></app-color-slider>
  </div>
  <div class="input-wrapper">
   
    <span *ngIf=" this.configSvc.activetype == 'team' || this.configSvc.activetype == 'editteam' ">
      <i class="material-icons md-48"
       [ngStyle]="{'color': this.configSvc.color }" >{{this.configSvc.chosenIcon}} </i>
      </span>

      <span *ngIf=" this.configSvc.activetype == 'nodeteam'">
        <i class="material-icons md-48"
         [ngStyle]="{'color': this.configSvc.nodeteamdetailsds[0].clr}" >{{this.configSvc.nodeteamdetailsds[0].icontype}} </i>
        </span>
  
      <span *ngIf=" this.configSvc.colorinputtype == 'tagerset'">
        <i class="material-icons md-48"
         [ngStyle]="{'color': this.configSvc.color ||this.tagerSvc.hlKanbanset[0]?.iconcolor}" >{{this.tagerSvc.hlKanbanset[0]?.icontype}} </i>
        </span>
  
        <span *ngIf=" this.configSvc.colorinputtype == 'node' || this.configSvc.colorinputtype == 'comment'">
          <i class="material-icons md-48"
           [ngStyle]="{'color': this.configSvc.color || this.configSvc.nodeds[0]?.iconcolor}" >{{this.configSvc.chosenIcon}} </i>
          </span>

          <span *ngIf=" this.configSvc.colorinputtype == 'smartfolder'">
            <i class="material-icons md-48"
             [ngStyle]="{'color': this.configSvc.color || this.configSvc.nodeds[0]?.iconcolor}" >{{this.configSvc.chosenIcon}} </i>
            </span>

          <span *ngIf=" this.configSvc.quickadd">
            <i class="material-icons md-48"
             [ngStyle]="{'color': this.configSvc.color}" >{{this.configSvc.chosenIcon}} </i>
            </span>

          
        <span *ngIf=" this.configSvc.colorinputtype == 'flow'">
          <i class="material-icons md-48"
           [ngStyle]="{'color': this.configSvc.color || this.configSvc.flowdetailsds[0]?.iconcolor}" >{{this.configSvc.chosenIcon}} </i>
          </span>
  
          <span *ngIf=" this.configSvc.colorinputtype == 'kanban'">
            <i class="material-icons md-48"
             [ngStyle]="{'color': this.configSvc.color || this.configSvc.nodeds[0]?.iconcolor}" >view_column </i>
            </span>
  
        <span *ngIf=" this.configSvc.colorinputtype == 'register'">
          <i class="material-icons md-48"
           [ngStyle]="{'color': this.configSvc.color}" >{{this.configSvc.chosenIcon}} </i>
          </span>   

          
  
   
    
    <span  *ngIf=" this.configSvc.colorinputtype == 'tag'">
      <i class="material-icons md-48" 
      [ngStyle]="{'color': this.configSvc.color || this.tagSvc.highlightedTagset[0].tagcolor}" >bookmark </i>
  
    </span>
    </div>