import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-nodedirect',
  templateUrl: './nodedirect.component.html',
  styleUrls: ['./nodedirect.component.css']
})
export class NodedirectComponent implements OnInit {

  constructor(public configSvc: ConfigService) { }

  ngOnInit(): void {
    this.configSvc.activetool = 'nodetools'
    this.configSvc.fullpage = true;
    this.configSvc.tabIndex = 0;
  }

}
