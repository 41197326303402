import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import { ConfigService } from '.././../services/config.service';

@Component({
  selector: 'app-editkanban',
  templateUrl: './editkanban.component.html',
  styleUrls: ['./editkanban.component.css']
})
export class EditkanbanComponent implements OnInit {

  
  editkanbanFG: FormGroup;
  constructor(private _formBuilder: FormBuilder,  public configSvc: ConfigService) { }

  ngOnInit() {
    // this.configSvc.nodeteamds = [];
    this.configSvc.colorinputtype = 'kanban';
    this.configSvc.toolmenu = 'steptools'

    console.log('initializing the editkanban with: '+JSON.stringify(this.configSvc.nodeds[0]))
    
    this.editkanbanFG = this._formBuilder.group({
      name: [this.configSvc.nodeds[0].name, Validators.required],
      addedby: [this.configSvc.nodeds[0].ab, Validators.required],
      addedon: [this.configSvc.nodeds[0].ao, Validators.required],
      desc: [this.configSvc.nodeds[0].desc, Validators.required],
      icontype: [this.configSvc.nodeds[0].icontype, Validators.required],
      iconcolor: [this.configSvc.nodeds[0].iconcolor, Validators.required],
      changecolor: [false, Validators.required],
       type: [this.configSvc.nodeds[0].type, Validators.required],
       settotal:[this.configSvc.nodeds[0].settotal, Validators.required],
       kantype:[this.configSvc.nodeds[0].kantype, Validators.required],
       timetotal:[this.configSvc.nodeds[0].timetotal, Validators.required],
     
     
       
     });
  }

  updateKanban(){

     console.log('current level '+this.configSvc.activelevel)
     console.log('current nodepathds '+JSON.stringify(this.configSvc.nodepathds));
     console.log('current nodepathds level1 '+JSON.stringify(this.configSvc.nodepathds[1]));
     var pathid = this.configSvc.nodepathds[this.configSvc.activelevel].path._id
     console.log('current pathid trying to update '+pathid)
 
     this.configSvc.updateKanban(this.configSvc.nodeds[0]._id, this.editkanbanFG.value.name,
       this.editkanbanFG.value.desc, this.editkanbanFG.value.type, this.configSvc.nodeds[0].icontype, 
       this.configSvc.color, pathid)
       this.editkanbanFG.markAsPristine(); this.configSvc.colorchanged =false;
   }

}
