<mat-tab-group >

    <mat-tab>
        <ng-template mat-tab-label>
            <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.flowitemdetailsds[0].iconcolor || 'black'}" >
                {{this.configSvc.flowitemdetailsds[0].icontype}} </i>
            {{this.configSvc.flowitemdetailsds[0].name}}
          </ng-template>
      <span [formGroup]="editflowitemFG" class = "flex-container" >
        
        <span>
        <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Flow Item Name</mat-label>
            <input matInput [readonly]= "true"   formControlName="name" matInput value={{this.configSvc.flowitemdetailsds[0].name}}>
          </mat-form-field></span>
          <span>
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Flow Item Type</mat-label>
            <input  matInput [readonly]= "true"  formControlName="type" matInput value={{this.configSvc.flowitemdetailsds[0].type}}>
          </mat-form-field></span>
          <span>
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Flow ItemID</mat-label>
            <input  matInput [readonly]= "true"  formControlName="itemid" matInput value={{this.configSvc.flowitemdetailsds[0].itemid}}>
          </mat-form-field></span>
          <span>
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Added By</mat-label>
            <input  matInput [readonly]= "true" formControlName="ab" matInput value={{this.configSvc.flowitemdetailsds[0].ab}}>
          </mat-form-field></span>
          <span>
          <mat-form-field appearance="outline" floatLabel="always" >
            <mat-label>Added On</mat-label>
            <input matInput [readonly]= "true"  formControlName="ao" matInput value={{this.configSvc.flowitemdetailsds[0].ao}}>
          </mat-form-field></span>
        
        
        
        
        
        </span>
  
       
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
        <i class="material-icons md-48" [ngStyle]="{'color':  'black'}" >
          assignment_ind</i>
       Assignment
      </ng-template>
      <app-flowassignment></app-flowassignment>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
        <i class="material-icons md-48" [ngStyle]="{'color':  'black'}" >
           preview</i>
       Preview
      </ng-template>

      <span *ngIf = "this.configSvc.flowitemdetailsds[0].type == 'wizard' && this.configSvc.flowready == true"><i class="material-icons icon-popup" [ngStyle]="{'color': this.configSvc.flowitempreviewds[0].iconcolor}">{{this.configSvc.flowitempreviewds[0].icontype}}</i>
        <div mat-dialog-content>
          <div>
      
            <mat-horizontal-stepper [linear]="isLinear" #stepper >
              <mat-step *ngFor="let step of this.configSvc.flowitempreviewds[0].stepper; let stepindex = index;" >
                <ng-template matStepLabel>{{ step.step }}</ng-template>
               <form id="myForm" [formGroup]="previewFG" >
                    <span [formArrayName]="'formArrayName'" class="popup-container">
                      <span class = "popuptitle">{{step.desc}}</span> 
                    <div *ngFor="let property of this.previewArray.controls; let i = index">
                   
                      <div [formGroupName]="i" *ngIf="property.value.formstep.value == stepindex" >
                     
                       
                        <div *ngIf="property.value.formtype.value == 'string'"  > 
                          <span >
                          <span *ngIf="property.value.label.value==true" class = "labels">
                            <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                           
                         
                          <mat-form-field appearance="fill" class= "large-item"> 
                           <span *ngIf="property.value.label.value==false">
                                <mat-label >{{property.value.formitem.value}} </mat-label></span>
                                <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                               </mat-form-field>
                            
            
                             <div *ngIf="property.value.label.value==false" class = "labels">
                             
                             <mat-form-field appearance="outline" floatLabel="always"> 
                               <mat-label >{{property.value.property.value}} </mat-label>
                                   <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                  </mat-form-field>
                                 </div> 
                             
                          </span>     </div> 
                   
                          <div *ngIf="property.value.formtype.value == 'number'" > 
                            <span>
              <span *ngIf="property.value.label.value==true" class = "labels">
                              <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                             
                           <mat-form-field appearance="fill" class= "small-item">  
                               
                                 <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
               </mat-form-field></span>
              
               <span *ngIf="property.value.label.value==false" class = "labels">
            
                <mat-form-field appearance="outline" floatLabel="always"> 
                  <mat-label >{{property.value.property.value}} </mat-label>
                      <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                     </mat-form-field>
              </span></span>
            </div>  
                   
            <span *ngIf="property.value.formtype.value == 'boolean'"> 
             
              <div class= "labels"> 
         
                <mat-slide-toggle 
                [checked] = property.value.hasdefault
                (change)="changeBoolean(property.value.hasdefault, i)">
                                        
                 {{property.value.formitemlabel.value}}
                  </mat-slide-toggle>
               
                 
                </div> 
         
                             </span>
                   
                                <span *ngIf="property.value.formtype.value == 'date'"  > 
                                  <span *ngIf="property.value.label.value==true" class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   </span> 
                                <mat-form-field appearance="fill">
                                  
                                  <input matInput [matDatepicker]="picker1"  formControlName="itemvalue" class= "medium-date">
                                       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                       <mat-datepicker  #picker1></mat-datepicker>
                                     </mat-form-field></span>
            
            
                               <span *ngIf="property.value.formtype.value == 'checkbox'"> 
                                     
                                      
                                       <mat-checkbox formControlName="hasdefault">
                                        {{property.value.formitemlabel.value}} </mat-checkbox>
                             </span>
               
      
                             <span *ngIf="property.value.formtype.value == 'text'"  > 
                                 
                              <span class = "labels">
                                <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                               </span> 
                             
                               <mat-form-field appearance="outline"   class= "xlarge-item">
                                  <textarea
                                  
                                  formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                                 </mat-form-field>
                                 
                         </span> 
      
                         <span *ngIf="property.value.formtype.value == 'choicelist'"  class= "medium-item">
      
      
                          <span>
                            <span class = "labels">
                            <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
      <mat-form-field>
                        
                           <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue">
                       <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                         {{choice.name}}
                       </mat-option>
                     </mat-select>
                    
                   </mat-form-field>
                  </span>
                   
                 
                </span>
      
                     <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                      <span  *ngIf="property.value.label.value==true" >
                             <span >
                               <div class = "labels">
                               <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
                             <mat-radio-group
                             class="radio-container">
                             <mat-radio-button
                             
                           
                            
                             *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                             [value]="choice.name"
                             [checked]="choice.name == property.value.itemvalue"
                             (change)="changeRadio(choice, i)"
                             >
                               {{choice.name}}
                             </mat-radio-button>
                           </mat-radio-group>
                          
                         </span>
                       </span>
                    
                        </div>
      
                        <div *ngIf="property.value.formtype.value == 'treelist'  ">
                       
                          <span *ngIf="property.value.label.value==true"  class= "treecontainer">
                 <span class = "labels">
                 <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
      
              
               <mat-chip-list>
         
                 
                 <mat-chip *ngFor="let paths of this.sortPath()"
                 (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
                 >
                   {{paths.name}}
             </mat-chip>
             </mat-chip-list>
         
           
               <div *ngIf="clickchoiceset" class="outerbox">  
                 <span  >
                 <table mat-table [dataSource]="this.tagerSvc.activetree"  >
                 
                   <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                   (click)="this.getChoicetree(selectedrow._id); 
                  
                   
                   this.hlchoice(selectedrow,  i);
                   "
                   [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                   class="innerbox"
                   ></tr>
                 
                 
                            
                 <ng-container matColumnDef="Name" >
                       <th mat-header-cell *matHeaderCellDef>Name </th>
                    <td mat-cell *matCellDef="let element">
                     <span *ngIf="element.haschild">  
                       <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                       </span>
                        {{element.name}}
                  </td></ng-container>
                 
                    </table> </span>
         </div>
         
        
       
         
         </span>
                     </div>
      
      
                                           
                                    </div> 
                    </div>
                    
                   
                  </span>
                     </form>
            
                </mat-step>
            </mat-horizontal-stepper>
            </div>
      </div>
      
        
        <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">Cancel</button>
        
          
        </div>
      </span>
      
      
      
      <span *ngIf = "this.configSvc.flowitemdetailsds[0].type == 'node' && this.configSvc.flowready == true">
        
        <div mat-dialog-content>
        
          <i class="material-icons icon-preview" [ngStyle]="{'color': this.configSvc.flowitempreviewds[0].iconcolor}">{{this.configSvc.flowitempreviewds[0].icontype}}</i>
       <span class = "popuptitle">{{this.configSvc.flowitempreviewds[0].desc}}</span> 
       
               <form id="myForm" [formGroup]="previewFG" >
                    <span [formArrayName]="'formArrayName'">
            
                    <div *ngFor="let property of this.previewArray.controls; let i = index"
                   
                    
                    >
                      <div [formGroupName]="i"  >
            
                         <div *ngIf="property.value.formtype.value == 'string'" > 
                           <span class ="itemcontainer">
                           <span *ngIf="property.value.label.value==true" class = "labels">
                             <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                            
                          
                           <mat-form-field appearance="fill"> 
                            <span *ngIf="property.value.label.value==false">
                                 <mat-label >{{property.value.property.value}} </mat-label></span>
                                 <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                </mat-form-field>
                             
      
                              <div *ngIf="property.value.label.value==false" class = "labels">
                              
                              <mat-form-field appearance="outline" floatLabel="always"> 
                                <mat-label >{{property.value.property.value}} </mat-label>
                                    <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                   </mat-form-field>
                                  </div> 
                              
                           </span>     </div> 
                   
                                <div *ngIf="property.value.formtype.value == 'number'"> 
                                  <span class ="itemcontainer">
                    <span *ngIf="property.value.label.value==true" class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   
                                 <mat-form-field appearance="fill">  
                                     
                                       <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                     </mat-form-field></span>
                    
                     <span *ngIf="property.value.label.value==false" class = "labels">
                  
                      <mat-form-field appearance="outline" floatLabel="always"> 
                        <mat-label >{{property.value.property.value}} </mat-label>
                            <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                           </mat-form-field>
                    </span></span>
                  </div>  
                    <span *ngIf="property.value.formtype.value == 'boolean'"> 
                    
                  <div class= "labels"> 
      
                    
                   
                    <mat-slide-toggle 
                    [checked] = property.value.hasdefault.value
                    
                                                (change)="toggleChange()" >
                     {{property.value.formitemlabel.value}}
                      </mat-slide-toggle>
                   
                    
                    </div> 
      
                                 </span>
                   
                                <span *ngIf="property.value.formtype.value == 'date'"> 
                                  <span *ngIf="property.value.label.value==true" class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   </span> 
                                <mat-form-field appearance="fill">
                                  <input matInput [matDatepicker]="picker1" placeholder="{{property.value.formitem}}" formControlName="itemvalue" >
                                       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                       <mat-datepicker  #picker1></mat-datepicker>
                                     </mat-form-field></span>
            
            
                                     <div *ngIf="property.value.formtype.value == 'checkbox'"> 
                                     
                                       
                                       <mat-checkbox formControlName="hasdefault"
                                      >
                                        {{property.value.formitemlabel.value}}   {{property.value.hasdefault.value}}  </mat-checkbox>
                                </div>
      
                                <div *ngIf="property.value.formtype.value == 'text'">
                                  <span  class = "labels">
                                    <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                   </span> 
                                   <mat-form-field appearance="outline"   class = "textarea">
                                    <textarea
                                    
                                    formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                                   </mat-form-field>
                                </div>
      
               
                                       <div *ngIf="property.value.formtype.value == 'choicelist'  ">
         
      
                                        <span *ngIf="property.value.label.value==true" class= "itemcontainer">
                                          <span class = "labels">
                                          <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
         <mat-form-field>
                                      
                                         <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue" 
                                        
                                         (selectionChange)="choiceChange()"
                                         >
                                     <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                                       {{choice.name}}
                                     </mat-option>
                                   </mat-select>
                                  
                                 </mat-form-field>
                                </span>
                                 
                               
                                   </div>
      
                                   <div *ngIf="property.value.formtype.value == 'treelist'  ">
         
      
                                    <span *ngIf="property.value.label.value==true" class= "treecontainer">
                                      <span class = "labels">
                                      <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
      
                                   
                                    <mat-chip-list>
                              
                                      
                                      <mat-chip *ngFor="let paths of this.sortPath()"
                                      (click)="getChoicetree(paths.pfoldid) "
                                      >
                                        {{paths.name}}
                                  </mat-chip>
                                  </mat-chip-list>
                              
                                
                                    <div class="outerbox">  
                                      <span  >
                                      <table mat-table [dataSource]="this.tagerSvc.activetree"  >
                                      
                                        <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i = index;"
                                        (click)="this.getChoicetree(selectedrow._id); this.hlchoice(selectedrow)"
                                        [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                                        class="innerbox"
                                        ></tr>
                                      
                                      
                                                 
                                      <ng-container matColumnDef="Name" >
                                            <th mat-header-cell *matHeaderCellDef>Name </th>
                                         <td mat-cell *matCellDef="let element">
                                          <span *ngIf="element.haschild">  
                                            <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                                            </span>
                                             {{element.name}}
                                       </td></ng-container>
                                      
                                         </table> </span>
                              </div>
                              
                              Value selected: 
                              <div  *ngIf="this.choicesetselected"><mat-chip-list>
                              <mat-chip>
                              {{this.edittagFG.value.itemvalue}}
                              </mat-chip></mat-chip-list></div>
                              
                              </span>
                              
                               </div>
      
                                   <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                             <span  *ngIf="property.value.label.value==true" >
                                    <span class= "itemcontainer">
                                      <span class = "labels">
                                      <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
                                    <mat-radio-group
                                    class="radio-group">
                                    <mat-radio-button class="radio-button" 
                                    
                                  
                                   
                                    *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                                    [value]="choice.name"
                                    [checked]="choice.name == property.value.itemvalue"
                                    (change)="property.value.itemvalue = $event.value.name"
                                    >
                                      {{choice.name}}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                 
                                </span>
                              </span>
                           
                               </div>
                                      </div> 
                    </div>
                    
                   
                  </span>
                     </form>
            
          
          
           
      </div>
      
        
        <div mat-dialog-actions>
          <button mat-button (click)="onNoClick()">Cancel</button>
        
          
        </div>
      </span>

  </mat-tab>
  </mat-tab-group>
  
