<div class="mat-elevation-z8" >
    <table mat-table [dataSource]="this.configSvc.nodeds[0].photos">
      
  
 


<ng-container matColumnDef="ab" >
<th mat-header-cell *matHeaderCellDef>Photo By </th>
<td mat-cell *matCellDef="let element">

{{element.ab}}
</td></ng-container>

<ng-container matColumnDef="ao" >
<th mat-header-cell *matHeaderCellDef>Uploaded On </th>
<td mat-cell *matCellDef="let element">

{{element.ao | date:'medium'}}  
</td></ng-container>

<ng-container matColumnDef="vk" >
    <th mat-header-cell *matHeaderCellDef>PhotoID</th>
    <td mat-cell *matCellDef="let element">
    
    {{element.photoid}}
    </td></ng-container>


<tr mat-header-row *matHeaderRowDef="versionColumns;  sticky: true"></tr>
<tr mat-row *matRowDef="let selectedrow; columns: versionColumns; let i2 = index;"
(click)="this.selectItem(selectedrow)"
[style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"

></tr>

         
    
       </table>
<div >
    <span>
        
        <div >
             
            <img [src]="this.uploadSvc.imageblob" height="200"> <br/>
          </div>
      </span></div>  </div>