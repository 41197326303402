
  
  
  
  <span >
    <i MatPrefix class="material-icons icon-edit" title="Create Prompt set" 
    (click)="this.openChoicetool('choiceset', '', '', false)"
    >dataset</i><span>
  
  
  <span>
    <i MatPrefix class="material-icons icon-edit" title="Add Prompt" 
    (click)="this.openChoicetool('choice', '', '', false)"
  >add_comment</i><span>
  
  
