import { Injectable } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';
// import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {Docversion} from '../model/docversion';
import { DomSanitizer } from '@angular/platform-browser';
import {ConfigService} from '../services/config.service';


export interface DocUpload {
  docid: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public imageverds = [];
  public imageurl:any;
  public imageblob: any;
  public versionblob: any;
  public imageready
  public imageverready = false;
  public totalfilesuploaded = 0;
  public fileuploadcount = 0;
  public uploadfinished = true;
  public nodetextracted = false
  public filesprocessed = 0
  public kanbantagged = '0';
  public kandocs = '0';
  public uploadinprogess = false;
  details: UserDetails;
  public docupload: DocUpload = null;
  public files: NgxFileDropEntry[] = [];
  public fileext: File[] = [];
  public docstatus;
  public uploadpercent = 0;
  public uploadcount = 0;
  public indexcount = 0;
  public indexpercent = 0;
  public jaeindexing = false;
  // public color = 'primary';
  public mode = 'determinate';
  public value = 0;

  user: TokenPayload;

  currentrow: Docversion = null;
  public showdetails = false;
  public mybatch = null;
  public uploader;
  public blobdata;
  public mt;
  public uploadtime;
  public foldertag: string;
  public kanbanned: string;
  public stepitems =[];
  public nodename: string;
  public iconcolor: string;
  


 // public datemoded: Date[]= [];
 displayedColumns: string[] = ['FileCount', 'Mt', 'Status', 'CommentCnt', 'Addedby', 'BatchNumber', 'Filename',
 'Title', 'Path', 'Filesize',
'From', 'To', 'CC', 'BCC',
 'Clientid', 'ClientName', 'Coverno', 'DocType' ];

 displayedsubColumns2: string[] = ['property', 'value'];
 highlightedRows = [];

  constructor(private authService: AuthenticationService, private sanitizer: DomSanitizer, private configSvc: ConfigService) { }


/*
  public msgdropped(fileinfolder: boolean, process: string, files: NgxFileDropEntry[],indextype: string, assigned: boolean, assignedto: string, deadline: boolean, deadlineval: string,
    statetracker: boolean, state: boolean, statelabel: string, 
    cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atcmtuser?) {

    this.files = files;
    
    console.log(' emails object: ' + this.files);
    this.uploadcount = 0;
    this.mybatch = localStorage.getItem('mybatch');

    console.log(' my local storage batch value is ' + this.mybatch);
    this.mybatch = parseFloat(localStorage.getItem('mybatch') );
    this.mybatch ++;
    console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
     localStorage.setItem('mybatch', this.mybatch.toString()) ;



    console.log('current batch number:' + this.mybatch);
    
    
  
  


    for (const droppedFile of files) {


      if (droppedFile.fileEntry.isFile) {
        this.uploadinprogess = true;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        console.log(' fileEntry.file upload: ' + fileEntry.file);

        fileEntry.file((fileext: File) => {
          console.log('cwhat is going on here dropped');
          this.uploadcount++;
       //   console.log ('this is the folder i want to file in' +this.configSvc.currentrow._id)
          const formData = new FormData();
          formData.append('upload1', fileext);
          formData.append('process', process);
          formData.append('batchtotaldocs', JSON.stringify(files.length));
         formData.append('batchnumber', this.mybatch);
          formData.append('uploadcount', JSON.stringify(this.uploadcount));
          formData.append('pp', JSON.stringify(10));
          formData.append('histtype', JSON.stringify(3));
          this.uploadtime = Date.now();
          formData.append('addedon', this.uploadtime);
          console.log('formdata toalcount value is: ' + formData.get('batchtotaldocs'));
          console.log('formdata running uploadcount value is: ' + formData.get('uploadcount'));
          console.log('Sending doc across to api now');
          formData.append('folderid', this.configSvc.activeitemid);
          formData.append('teamid',  this.configSvc.activeteamid);
          formData.append('type',  'fileadd');
         
          formData.append('level', this.configSvc.activelevel);
          formData.append('textract', textract.toString());

         // if (indextype == '0'){formData.append('indextype', '0');}
        
         
          
       

          if (indextype == '2'){
          
            formData.append('foldertags', JSON.stringify(this.configSvc.newpropvalues));
              formData.append('nodename', this.configSvc.teamdetailsds.nodename)
              formData.append('tagcolor', this.configSvc.teamdetailsds.tagcolor)
            }
         // if(!this.configSvc.folderdetailsds.tagged){
         //   formData.append('foldertags', '0');
       //   }

          if (!this.configSvc.folderdetailsds.kanbantagged){
            this.kanbantagged = '0'

          }
            if (this.configSvc.folderdetailsds.kanbantagged){
              this.kanbantagged = '1'
              formData.append('kanbanid', this.configSvc.folderdetailsds.kanbanid);
              formData.append('activetrack', this.configSvc.folderdetailsds.kanbanprops[0].track);

            
            }

              if (!this.configSvc.folderdetailsds.kandocs){
                this.kandocs = '0'}
                if (this.configSvc.folderdetailsds.kandocs){
                  this.kandocs = '1'
                
                }
          
          formData.append('kanbantagged', this.kanbantagged);
          formData.append('kandocs', this.kandocs);

          if (this.configSvc.folderdetailsds.tagged){
           
            formData.append('foldertags', JSON.stringify(this.configSvc.newfoldertags));
            formData.append('nodename', this.configSvc.folderdetailsds.nodename)
            formData.append('tagcolor', this.configSvc.folderdetailsds.tagcolor)
        }
        formData.append('indextype', indextype);
          console.log('before Sending doc across to api now the current row data is'+this.configSvc.currentrow);
          this.authService.upload(formData, this.user).subscribe(uploadresults => {

           this.configSvc.teamds = uploadresults
           console.log('this is the refreshed folder results'+JSON.stringify(this.configSvc.teamds))
           this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
           this.fileuploadcount++;
           this.filesprocessed = this.fileuploadcount/this.totalfilesuploaded*100
           console.log('this is the filesprocessed'+this.filesprocessed)
      
           if(this.filesprocessed== 100)
           {this.uploadfinished = true;    }
         //this.configSvc.getKanban('fold', this.configSvc.activeitemid)
         this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
          // this.configSvc.activetool = ''
    
       }, (err) => {
          console.error(err); });

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }



  }
/*
  // send the data to be indexed by jae
  this.jaeindexing = true; this.mode = 'indeterminate';
  console.log('send the data to be indexed by jae');
  const jaeData = new FormData();
  jaeData.append('batchtotaldocs', JSON.stringify(files.length));
  jaeData.append('batchnumber', this.mybatch);

  this.authService.sendtojae(jaeData, this.user).subscribe(jaedata => {
    // this.indexcount++;
   // this.indexpercent =  ((this.indexcount  / files.length) * 100);
   // console.log('trying to get the indexing data from jae now: ' + JSON.stringify(jaedata));
    this.mode = 'determinate'; this.value = 100;
    this.searchDocsvc.currentrow = jaedata;

}, (err) => {
  console.error(err); });

  }
    */

  public filedropped(fileinfolder: boolean, process: string, mt: string, files, indextype: string, assigned: boolean, assignedto: string, deadline: boolean, deadlineval: string,
    statetracker: boolean, state: boolean, statelabel: string, 
    cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atcmtuser?, total?, extract?, askchatgpt?, prompt?, temp?, responses?
    ) {
      console.log('how many msg files are there being dropped: '+files.length)
   // this.files = files;
   console.log('mt is '+mt)
    this.uploadcount = 0;
    this.mybatch = localStorage.getItem('mybatch');

    console.log(' my local storage batch value is ' + this.mybatch);
    this.mybatch = parseFloat(localStorage.getItem('mybatch') );
    this.mybatch ++;
    console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
     localStorage.setItem('mybatch', this.mybatch.toString()) ;


    console.log(' Total Number of docs trying to upload: ' + this.files.length);
    console.log('current batch number:' + this.mybatch);
    
    
  
  


    
        this.uploadinprogess = true;
     


          console.log('cwhat is going on here filedropped');
          console.log('mt '+mt);
          this.uploadcount++;
       //   console.log ('this is the folder i want to file in' +this.configSvc.currentrow._id)
          const formData = new FormData();
          formData.append('upload1', files);
          formData.append('process', process);
          formData.append('mt', mt);
          formData.append('quickadd', 'true');
        //  formData.append('batchtotaldocs', JSON.stringify(files.length));
         formData.append('batchnumber', this.mybatch);
          formData.append('uploadcount', JSON.stringify(this.uploadcount));
          formData.append('pp', JSON.stringify(10));
          formData.append('histtype', JSON.stringify(3));
          this.uploadtime = Date.now();
          formData.append('addedon', this.uploadtime);
          console.log('formdata toalcount value is: ' + formData.get('batchtotaldocs'));
          console.log('formdata running uploadcount value is: ' + formData.get('uploadcount'));
          console.log('Sending doc across to api now');

          if(fileinfolder){
          formData.append('folderid', this.configSvc.activeitemid);}
          if(!fileinfolder){
            formData.append('folderid', 'root');}


          formData.append('teamid',  this.configSvc.activeteamid);
          formData.append('type',  'fileadd');
         
          formData.append('level', this.configSvc.activelevel);
          formData.append('assigned', assigned.toString());
          if(assigned){formData.append('assignedto', assignedto);}

          formData.append('deadline', deadline.toString());
          if(deadline){formData.append('deadlineval', deadlineval);
          }
          formData.append('statetracker', statetracker.toString());
          if(statetracker){
            formData.append('statelabel', statelabel);
            formData.append('state', state.toString());
          }
          console.log('this is the cmt folder value '+cmt)
          formData.append('cmt', cmt);
          if(cmt){
            formData.append('prompt', cmtval);
            formData.append('atcmt', atcmt);
         
            

            if(atcmt){
              formData.append('atcmtuser', atcmtuser);
            }
            
           
            formData.append('cmticon', cmticon);
            formData.append('cmtclr', cmtclr);
         

          }
       
if(fileinfolder){
  formData.append('fileinfolder', 'true')
          if (indextype == '2'){
       
              formData.append('stepitems', JSON.stringify(this.stepitems));
              formData.append('nodename', this.configSvc.teamdetailsds.nodename)
              formData.append('tagcolor', this.configSvc.teamdetailsds.tagcolor)
            }
         // if(!this.configSvc.folderdetailsds.tagged){
         //   formData.append('foldertags', '0');
       //   }

       if (this.configSvc.folderdetailsds.kanbantagged){
        var trackcount = this.configSvc.folderdetailsds.kanbanprops.length -1
        formData.append('kanbantagged', 'true');
        formData.append('kanbanid', this.configSvc.folderdetailsds.kanbanid);
        formData.append('activetrack', this.configSvc.folderdetailsds.kanbanprops[0].track);
        formData.append('trackcount', trackcount.toString());
        formData.append('kanbantotal', this.configSvc.folderdetailsds.kanbantotal.toString());
        formData.append('kanstate', this.configSvc.folderdetailsds.kanstate.toString());
        formData.append('total', total);
        formData.append('kantype', this.configSvc.folderdetailsds.kantype);
        formData.append('kansect', this.configSvc.folderdetailsds.kansect.toString());
       
      }
  
      if (!this.configSvc.folderdetailsds.kanbantagged){
        formData.append('kanbantagged', 'false');
      
      }

              if (!this.configSvc.folderdetailsds.kandocs){
                this.kandocs = '0'}
                if (this.configSvc.folderdetailsds.kandocs){
                  this.kandocs = '1'
                
                }
          
         
          formData.append('kandocs', this.kandocs);

          if (this.configSvc.folderdetailsds.tagged){
         
            formData.append('foldertags', JSON.stringify(this.configSvc.folderdetailsds.foldertags));
            formData.append('nodename', this.configSvc.folderdetailsds.nodename)
            formData.append('tagcolor', this.configSvc.folderdetailsds.tagcolor)
        }

      }

      if(!fileinfolder){formData.append('fileinfolder', 'false')}


        formData.append('indextype', indextype)
        formData.append('extract', extract)
        formData.append('askchatgpt', askchatgpt);

        if(mt == 'jpg' || mt == 'jpeg'){

          if(extract){
          formData.append('textract', 'true')
          formData.append('textractype', 'invoice')
          formData.append('attach', 'true')}

        }

        if(askchatgpt){
          formData.append('prompt', prompt);
          formData.append('temp', temp);
          formData.append('responses', responses);

        }
       
     //   formData.append('textract', textract.toString());
     this.uploadfinished = false;
     var foldertotal = this.configSvc.teamds.length
     console.log('current folder total is '+foldertotal)
          console.log('before Sending doc across to api now the current row data is'+this.configSvc.currentrow);
          this.authService.upload(formData, this.user).subscribe(uploadresults => {

         
            if(fileinfolder && !askchatgpt){
              this.configSvc.teamds = uploadresults
              this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
              console.log('this is the refreshed folder results'+JSON.stringify(this.configSvc.teamds))
            }
           
           
                if(fileinfolder && askchatgpt){
                  this.configSvc.nodeds = uploadresults
                 this.nodetextracted = true
           
                 this.configSvc.chatgptresponse = true;
                 console.log('got a response')
                 this.configSvc.activetype = 'chatgpt'
           
                
            }
           this.fileuploadcount++;
           this.filesprocessed = this.fileuploadcount/this.totalfilesuploaded*100
           this.uploadfinished = true;
        //   console.log('this is the filesprocessed'+this.filesprocessed)
         //  this.configSvc.nodeds[0] = this.configSvc.teamds.slice(-1)[0]
      
           if(this.filesprocessed== 100)
           {this.uploadfinished = true;   
          
          }
         //this.configSvc.getKanban('fold', this.configSvc.activeitemid)
         if(fileinfolder){
         this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
         }
       
       }, (err) => {
          console.error(err); });

        
     
  

  }

  public createImage(instructions: string, title: string, process: string, mt: string, size: string, indextype: string, assigned: boolean, assignedto: string, 
    deadline: boolean, deadlineval: string,
    statetracker: boolean, state: boolean, statelabel: string, 
    cmt?, cmtval?,  cmticon?, cmtclr?, atcmt?, atcmtuser?, total?, ischatgpt?){

      this.mybatch = localStorage.getItem('mybatch');

      console.log(' my local storage batch value is ' + this.mybatch);
      this.mybatch = parseFloat(localStorage.getItem('mybatch') );
      this.mybatch ++;

    const imageData = new FormData();
    imageData.append('process', process);
    var msg =[]
    msg.push({'content': instructions, 'role': 'user'})
   //  imageData.append('instructions', instructions);
    imageData.append('name', title);
    imageData.append('level', this.configSvc.activelevel);
    imageData.append('batchnumber',  this.mybatch);
    imageData.append('uploadcount',  '1');
    imageData.append('batchtotaldocs',  '1');
    imageData.append('mt', mt);
    imageData.append('size', size);
    imageData.append('indextype', indextype);
    imageData.append('assigned', assigned.toString());
    imageData.append('assignedto', assignedto);
    imageData.append('deadline', deadline.toString());
    imageData.append('deadlineval', deadlineval);
    imageData.append('statetracker', statetracker.toString());
    imageData.append('state', state.toString());
    imageData.append('statelabel', statelabel);
    imageData.append('cmt', cmt);
    imageData.append('cmtval', cmtval);
    imageData.append('cmticon', cmticon);
    imageData.append('cmtclr', cmtclr);
    imageData.append('atcmt', atcmt);
    imageData.append('atcmtuser', atcmtuser);
    imageData.append('total', total);
    imageData.append('ischatgpt', ischatgpt);
    
    if (this.configSvc.folderdetailsds.kanbantagged){
      var trackcount = this.configSvc.folderdetailsds.kanbanprops.length -1
      imageData.append('kanbantagged', 'true');
      imageData.append('kanbanid', this.configSvc.folderdetailsds.kanbanid);
      imageData.append('activetrack', this.configSvc.folderdetailsds.kanbanprops[0].track);
      imageData.append('trackcount', trackcount.toString());
      imageData.append('kanbantotal', this.configSvc.folderdetailsds.kanbantotal.toString());
      imageData.append('kanstate', this.configSvc.folderdetailsds.kanstate.toString());
      imageData.append('total', total);
      imageData.append('kantype', this.configSvc.folderdetailsds.kantype);
      imageData.append('kansect', this.configSvc.folderdetailsds.kansect.toString());
     
    }

    if (!this.configSvc.folderdetailsds.kanbantagged){
      imageData.append('kanbantagged', 'false');
    
    }
   
    this.uploadtime = Date.now();
    imageData.append('addedon', this.uploadtime);
    imageData.append('teamid',  this.configSvc.activeteamid);
    imageData.append('fileinfolder', 'true')
    imageData.append('folderid', this.configSvc.activeitemid);
    this.authService.createImage(imageData, this.user).subscribe(imageresults => {

      this.imageurl = imageresults
      let objectURL = URL.createObjectURL(imageresults);       
  this.imageblob = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.configSvc.chatgptresponse = true
  

   this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
   
 
 }, (err) => {
    console.error(err); });
  }
/*
  public dropversion(files: NgxFileDropEntry[], teamtags: boolean, foldertags: boolean) {
    this.files = files;
    this.uploadcount = 0;
    this.mybatch = localStorage.getItem('mybatch');

    console.log(' my local storage batch value is ' + this.mybatch);
    this.mybatch = parseFloat(localStorage.getItem('mybatch') );
    this.mybatch ++;
    console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
     localStorage.setItem('mybatch', this.mybatch.toString()) ;
    console.log(' Total Number of docs trying to upload: ' + this.files.length);
    console.log('current batch number:' + this.mybatch);
    var teamtag: string;
    if(teamtags){  teamtag= '1'} else {teamtag = '0'}
    if(foldertags){  
      console.log('this file has got foldertag indexes')
      this.foldertag= '1';
      console.log('here are the indexes: '+JSON.stringify(this.configSvc.nodeds[0].stepitems))
    this.stepitems = this.configSvc.nodeds[0].stepitems
    this.nodename=  this.configSvc.nodeds[0].nodename
    this.iconcolor= this.configSvc.nodeds[0].tagcolor
    
    
    } if(!foldertags){
      console.log('this file has got NO foldertag indexes')
      this.foldertag = '0'
    
      
    }


    for (const droppedFile of files) {

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((fileext) => {
         
          this.uploadcount++;
          console.log ('this is the folder i want to file in' +this.configSvc.currentrow._id)
          const formData = new FormData();
          formData.append('type', 'fileadd')
          formData.append('path', JSON.stringify(this.configSvc.nodeds[0].path))
          formData.append('foldertags', this.foldertag);
          formData.append('teamtag', teamtag);
          if(this.foldertag= '1'){
          formData.append('stepitems', JSON.stringify(this.stepitems))
          formData.append('nodename', this.configSvc.folderdetailsds.nodename)
          formData.append('tagcolor', this.configSvc.folderdetailsds.tagcolor)
        }
         
          formData.append('upload1', fileext);
          formData.append('batchtotaldocs', JSON.stringify(files.length));
         formData.append('batchnumber', this.mybatch);
          formData.append('uploadcount', JSON.stringify(this.uploadcount));
    
          this.uploadtime = Date.now();
          formData.append('addedon', this.uploadtime);
          formData.append('folderid', this.configSvc.currentfolder);
          if(this.configSvc.currentrow.ver == 1){
            formData.append('vk', this.configSvc.currentrow._id);}
          if(this.configSvc.currentrow.ver > 1){
            formData.append('vk', this.configSvc.currentrow.vk);}
          formData.append('version', JSON.stringify(this.configSvc.currentrow.ver));
          formData.append('team',  this.configSvc.activeteamid);
         // if (this.configSvc.currentrow.tasktype=='filetask'){formData.append('tasktype', 'filetask');}
         // else{formData.append('tasktype', 'none');}

        //  if (this.configSvc.currentrow.fq){formData.append('fq', 'true');}
           // else{formData.append('fq', 'false');}
          
            if (this.configSvc.currentrow.fc){formData.append('fc', 'true');}
            else{formData.append('fc', 'false');}

         
          formData.append('preversion',  this.configSvc.currentrow._id);
          formData.append('level', JSON.stringify(this.configSvc.currentrow.level));
          console.log('before Sending doc across to api now the current row data is'+this.configSvc.currentrow);
          this.authService.uploaddocver(formData, this.user).subscribe(uploaddocverresults => {
           this.configSvc.teamds = uploaddocverresults
           this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
            console.log('in add cpmonentn get the db client data now' + JSON.stringify(uploaddocverresults));
            console.log('get the file doccount from json' + uploaddocverresults.FileCount);
            this.uploadpercent =  ((this.uploadcount  / files.length) * 100);
            console.log('upload percent is' + this.uploadpercent, ' and uploadcount is: ' + this.uploadcount);
           
       }, (err) => {
          console.error(err); });

        });
     


  }
/*
  // send the data to be indexed by jae
  this.jaeindexing = true; this.mode = 'indeterminate';
  console.log('send the data to be indexed by jae');
  const jaeData = new FormData();
  jaeData.append('batchtotaldocs', JSON.stringify(files.length));
  jaeData.append('batchnumber', this.mybatch);

  this.authService.sendtojae(jaeData, this.user).subscribe(jaedata => {
    // this.indexcount++;
   // this.indexpercent =  ((this.indexcount  / files.length) * 100);
   // console.log('trying to get the indexing data from jae now: ' + JSON.stringify(jaedata));
    this.mode = 'determinate'; this.value = 100;
    this.searchDocsvc.currentrow = jaedata;

}, (err) => {
  console.error(err); });
  
  }
*/
  public dropversion(files: NgxFileDropEntry[]) {
    this.files = files;
    this.uploadcount = 0;
    this.mybatch = localStorage.getItem('mybatch');

    console.log(' my local storage batch value is ' + this.mybatch);
    this.mybatch = parseFloat(localStorage.getItem('mybatch') );
    this.mybatch ++;
    console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
     localStorage.setItem('mybatch', this.mybatch.toString()) ;
    console.log(' Total Number of docs trying to upload: ' + this.files.length);
    console.log('current batch number:' + this.mybatch);
  
   


    for (const droppedFile of files) {

      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((fileext) => {
         
          this.uploadcount++;
          console.log ('this is the folder i want to file in' +this.configSvc.currentrow._id)
          const formData = new FormData();
          formData.append('type', 'newver')
       
         
         
          formData.append('upload1', fileext);
          formData.append('batchtotaldocs', JSON.stringify(files.length));
         formData.append('batchnumber', this.mybatch);
          formData.append('uploadcount', JSON.stringify(this.uploadcount));
    
          this.uploadtime = Date.now();
          formData.append('addedon', this.uploadtime);
          formData.append('folderid', this.configSvc.currentfolder);
       //   if(this.configSvc.currentrow.ver == 1){
        //    formData.append('vk', this.configSvc.currentrow._id);}
     //    if(this.configSvc.currentrow.ver > 1){
        //    formData.append('vk', this.configSvc.currentrow.vk);}
        formData.append('itemid', this.configSvc.currentrow._id);
          formData.append('version', JSON.stringify(this.configSvc.currentrow.ver));
          formData.append('team',  this.configSvc.activeteamid);
          formData.append('mt', this.configSvc.currentrow.mt);
         // if (this.configSvc.currentrow.tasktype=='filetask'){formData.append('tasktype', 'filetask');}
         // else{formData.append('tasktype', 'none');}

        //  if (this.configSvc.currentrow.fq){formData.append('fq', 'true');}
           // else{formData.append('fq', 'false');}
          
        //    if (this.configSvc.currentrow.fc){formData.append('fc', 'true');}
        //    else{formData.append('fc', 'false');}

         
       //   formData.append('preversion',  this.configSvc.currentrow._id);
          formData.append('level', JSON.stringify(this.configSvc.currentrow.level));
          console.log('before Sending doc across to api now the current row data is'+this.configSvc.currentrow);
          this.authService.uploaddocver(formData, this.user).subscribe(uploaddocverresults => {
           this.configSvc.teamds = uploaddocverresults
           this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
            console.log('in add cpmonentn get the db client data now' + JSON.stringify(uploaddocverresults));
            console.log('get the file doccount from json' + uploaddocverresults.FileCount);
            this.uploadpercent =  ((this.uploadcount  / files.length) * 100);
            console.log('upload percent is' + this.uploadpercent, ' and uploadcount is: ' + this.uploadcount);
           
       }, (err) => {
          console.error(err); });

        });
     


  }
/*
  // send the data to be indexed by jae
  this.jaeindexing = true; this.mode = 'indeterminate';
  console.log('send the data to be indexed by jae');
  const jaeData = new FormData();
  jaeData.append('batchtotaldocs', JSON.stringify(files.length));
  jaeData.append('batchnumber', this.mybatch);

  this.authService.sendtojae(jaeData, this.user).subscribe(jaedata => {
    // this.indexcount++;
   // this.indexpercent =  ((this.indexcount  / files.length) * 100);
   // console.log('trying to get the indexing data from jae now: ' + JSON.stringify(jaedata));
    this.mode = 'determinate'; this.value = 100;
    this.searchDocsvc.currentrow = jaedata;

}, (err) => {
  console.error(err); });
  */
  }


  public dropallconfig(files: NgxFileDropEntry[], taskName, taskLevel) {


    for (const droppedFile of files) {


      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((excelfile) => {

          const uploadconfigForm = new FormData();
          uploadconfigForm.append('rawfiledata', excelfile);
          uploadconfigForm.append('batchtotaldocs', JSON.stringify(files.length));

          console.log('Sending excel config doc across to api now');
          this.authService.configupload(uploadconfigForm, this.user).subscribe(configuploaddata => {
            console.log('in add cpmonentn get the db client data now' + JSON.stringify(configuploaddata));
            console.log('get the file doccount from json' + configuploaddata.FileCount);

       }, (err) => {
          console.error(err); });

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }



  }
  // send the data to be indexed by jae
  console.log('send the data to be indexed by jae');
  const configIndexform = new FormData();
  configIndexform.append('batchtotaldocs', JSON.stringify(files.length));
  configIndexform.append('configbatch', this.mybatch);
 //  configIndexform.append('configdoctype', stdoctype);
 // configIndexform.append('configdocproperty', stproperty);
 // configIndexform.append('configchoicelist', stchoicelist);

  this.authService.configupload(configIndexform, this.user).subscribe(configdata => {
   console.log('trying to insert the configIndex data via the node java process now: ' + JSON.stringify(configdata));

   // this.searchDocsvc.currentrow = configdata;

}, (err) => {
  console.error(err); });

  }

  public bulkprocess(files: NgxFileDropEntry[]) {
    this.files = files;
   
    for (const droppedFile of files) {


      if (droppedFile.fileEntry.isFile) {
        this.uploadinprogess = true;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;


        fileEntry.file((fileext) => {
         
          const formData = new FormData();
          formData.append('bulkfile', fileext);
       
          this.uploadtime = Date.now();
          formData.append('addedon', this.uploadtime);
         
          formData.append('folderid', this.configSvc.activeitemid);
          formData.append('teamid',  this.configSvc.activeteamid);
          formData.append('type',  'bulkadd');
         
          formData.append('level', this.configSvc.activelevel);
          
          this.authService.bulkprocess(formData, this.user).subscribe(uploadresults => {

           this.configSvc.teamds = uploadresults
           this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
           console.log('this is the refreshed folder results'+JSON.stringify(this.configSvc.teamds))
      
         //this.configSvc.getKanban('fold', this.configSvc.activeitemid)
         this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid, false) 
          // this.configSvc.activetool = ''
           
       }, (err) => {
          console.error(err); });

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
  }

  }

  public saveNodeimage(imagefile, indextype: string, folderid, teamid,  level, nodeid, nodename, textract, ischatgpt) {
    
    {
    // this.files = imagefiles;
    this.nodetextracted = false
    this.uploadcount = 0;
    this.mybatch = localStorage.getItem('mybatch');

    console.log(' my local storage batch value is ' + this.mybatch);
    this.mybatch = parseFloat(localStorage.getItem('mybatch') );
    this.mybatch ++;
    console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
     localStorage.setItem('mybatch', this.mybatch.toString()) ;


    console.log(' Total Number of docs trying to upload: ' + this.files.length);
    console.log('current batch number:' + this.mybatch);
    // var teamtag: string;
    
   // if(indextype == '2'){  
   // this.stepitems = this.configSvc.teamdetailsds.stepitems} 
 //   if(indextype =='1' || indextype =='2'){  
     
  //  this.stepitems = this.configSvc.newpropvalues
  //  }
  


    // for (const droppedFile of imagefiles) {
      //  const fileEntry = imagefile.fileEntry as FileSystemFileEntry;


       //  fileEntry.file((fileext) => {
          console.log('cwhat is going on here savenodeimage');
          this.uploadcount++;
       //   console.log ('this is the folder i want to file in' +this.configSvc.currentrow._id)
          const nodeimageForm = new FormData();
          nodeimageForm.append('upload1', imagefile);
     
     //     nodeimageForm.append('batchnumber', this.mybatch);
      //    nodeimageForm.append('uploadcount', JSON.stringify(this.uploadcount));
     //     nodeimageForm.append('pp', JSON.stringify(10));
     //     nodeimageForm.append('histtype', JSON.stringify(3));
          this.uploadtime = Date.now();
          nodeimageForm.append('addedon', this.uploadtime);
          nodeimageForm.append('folderid', folderid);
          nodeimageForm.append('teamid',  teamid);
          nodeimageForm.append('type',  'nodeimage');
          nodeimageForm.append('level', level);
     //     nodeimageForm.append('indextype',indextype);
    
          nodeimageForm.append('nodeid', nodeid);
          nodeimageForm.append('nodename', nodename);
          nodeimageForm.append('textracted', textract);
          nodeimageForm.append('newpropvalues',  JSON.stringify(this.configSvc.newpropvalues));
        
        
         
          /*
          if (indextype == '1'){
          //  formData.append('foldertags', '1');
         
          nodeimageForm.append('nodename', this.configSvc.folderdetailsds.nodename)
          nodeimageForm.append('tagcolor', this.configSvc.folderdetailsds.tagcolor)
          }

          if (indextype == '2'){
            nodeimageForm.append('nodename', this.configSvc.teamdetailsds.nodename)
            nodeimageForm.append('tagcolor', this.configSvc.teamdetailsds.tagcolor)
            }
       
          if (!this.configSvc.folderdetailsds.kanbantagged){
            this.kanbantagged = '0'

          }
            if (this.configSvc.folderdetailsds.kanbantagged){
              this.kanbantagged = '1'
              nodeimageForm.append('kanbanid', this.configSvc.folderdetailsds.kanbanid);
              nodeimageForm.append('activetrack', this.configSvc.folderdetailsds.kanbanprops[0].track);

            
            }

              if (!this.configSvc.folderdetailsds.kandocs){
                this.kandocs = '0'}
                if (this.configSvc.folderdetailsds.kandocs){
                  this.kandocs = '1'
                
                }
               
          
                nodeimageForm.append('kanbantagged', this.kanbantagged);
                nodeimageForm.append('kandocs', this.kandocs);
                 */
        
          this.authService.saveNodeimage(nodeimageForm, this.user).subscribe(nodeimageresults => {
           this.configSvc.nodeds = nodeimageresults
           console.log('this is the refreshed folder results'+JSON.stringify(this.configSvc.teamds))
       if(this.configSvc.nodeds[0].textracted){
console.log('this file was successfully textracted')
console.log('this is the textraction '+JSON.stringify(this.configSvc.nodeds[0].textraction))
this.nodetextracted = true

       }
      
          this.uploadfinished = true;

       //  this.configSvc.getFolderDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid) 
   
        
       }, (err) => {
          console.error(err); });

       // });
     



  

  }
}

public getImagebyid(imageid, version, imagesource){
  console.log('lets go get the image from uploadsvc');
  this.imageready = false;
  const imageForm = new FormData();
  imageForm.append('docid', imageid);
  imageForm.append('ver', version);
  this.authService.getImagebyid(imageForm, this.user).subscribe(imageidresults => {
    /*
    this.imageurl = imageidresults
    console.log('got a response for image');
    // let mySrc;
    const reader = new FileReader();
    reader.readAsDataURL(this.imageurl); 
    reader.onload = (e) => {
      // result includes identifier 'data:image/png;base64,' plus the base64 data
      this.imageblob = reader.result; 
        }
*/

let objectURL = URL.createObjectURL(imageidresults);   

if(imagesource == 'nodefile' || imagesource == 'image' ){
this.imageblob = this.sanitizer.bypassSecurityTrustUrl(objectURL);}

if(imagesource == 'imageversion'){
  this.versionblob = this.sanitizer.bypassSecurityTrustUrl(objectURL);}


      this.imageready = true;
      console.log('image is now ready');
      this.configSvc.linkready = true

  
  

     
   
   
    /*
    this.blobdata = window.URL.createObjectURL(imageidresults);
    const a = document.createElement('a');
    a.download = this.configSvc.currentrow.name;
    a.href = this.blobdata;
    a.click();
    */
   
 //  this.imageurl = imageidresults
   



 
}, (err) => {
   console.error(err); });

}

public getImagebyver(imageid, version, imagesource){
  console.log('lets go get getImagebyver from uploadsvc with image source of '+imagesource);
  this.imageverready = false;
  const imageForm = new FormData();
  imageForm.append('docid', imageid);
  imageForm.append('ver', version);
  
  
  this.authService.getImagebyver(imageForm, this.user).subscribe(imageidresults => {
    /*
    this.imageurl = imageidresults
    console.log('got a response for image');
    // let mySrc;
    const reader = new FileReader();
    reader.readAsDataURL(this.imageurl); 
    reader.onload = (e) => {
      // result includes identifier 'data:image/png;base64,' plus the base64 data
      this.imageblob = reader.result; 
        }
*/

this.versionblob = null
  let objectURL = null

objectURL = URL.createObjectURL(imageidresults);   




  console.log('got the image for version '+version)
  this.versionblob = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  


      this.imageverready = true;
      console.log('image is now ready');
      this.configSvc.linkready = true

  
  

     
   
   
    /*
    this.blobdata = window.URL.createObjectURL(imageidresults);
    const a = document.createElement('a');
    a.download = this.configSvc.currentrow.name;
    a.href = this.blobdata;
    a.click();
    */
   
 //  this.imageurl = imageidresults
   



 
}, (err) => {
   console.error(err); });

}

public getItembyid(itemid, type){
  console.log('lets go get the item by id ' );
  console.log('type is '+type );
 // this.itemready = false;
 this.configSvc.linkready = false
  const itemForm = new FormData();
  itemForm.append('itemid', itemid);

  if(type == 'chatgpt'){itemForm.append('ischatgpt', 'true');}
  if(type != 'chatgpt'){itemForm.append('ischatgpt', 'false');
  }
 
  this.authService.getItembyid(itemForm, this.user).subscribe(itemresults => {
    
 //   this.imageurl = itemresults
    console.log('got a response for item '+itemresults);
    if(type == 'msg'){this.configSvc.emaildata = itemresults.email}
    if(type == 'attachnode' ){
      
      this.configSvc.attachednode = itemresults}
    if(type == 'attachnodetab'){
      
      this.configSvc.attachednodetab = [itemresults]}

    this.configSvc.linkready = true
    if(this.configSvc.activetype == 'tabbeddirect'){
    this.configSvc.hidebrowser = true
    }
     
    if(type == 'chatgpt'){
      this.configSvc.nodeds[0] = itemresults[0]
      console.log('this is the results of refreshing the chatgpt item '+JSON.stringify(this.configSvc.nodeds[0]))
      this.configSvc.nodeready = true
      this.configSvc.nodeready = true
    }
   


 
}, (err) => {
   console.error(err); });

}

public getLatestphoto(photoid){
  console.log('lets go get the image versions from uploadsvc');
  const imageForm = new FormData();
  imageForm.append('docid', photoid);

  this.authService.getImagebyid(imageForm, this.user).subscribe(imageidresults => {
    console.log('got a response for image');
   
    this.imageurl = imageidresults
    let objectURL = URL.createObjectURL(imageidresults);       
this.imageblob = this.sanitizer.bypassSecurityTrustUrl(objectURL);

this.configSvc.nodeready = true;
      console.log('image is now ready');
   



 
}, (err) => {
   console.error(err); });

}
  
public addNodefiles(itemid, files, textract: boolean, icontype, iconcolor, nodename, mt?) {
 // this.files = files;
 this.configSvc.nodeready = false
 this.nodetextracted = false
  this.uploadcount = 0;
  this.mybatch = localStorage.getItem('mybatch');
  // this.files = files;
  console.log(' my local storage batch value is ' + this.mybatch);
  this.mybatch = parseFloat(localStorage.getItem('mybatch') );
  this.mybatch ++;
  console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
   localStorage.setItem('mybatch', this.mybatch.toString()) ;


  console.log(' Total Number of docs trying to upload: ' + files.length);
  console.log('current batch number:' + this.mybatch);
 
      this.uploadinprogess = true;
    //  const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;


     
        console.log('uploading nodefiles');
        this.uploadcount++;
  
        const formData = new FormData();
        formData.append('upload1',  files);
        formData.append('batchtotaldocs', JSON.stringify(files.length));
       formData.append('batchnumber', this.mybatch);
        formData.append('uploadcount', JSON.stringify(this.uploadcount));
    
        this.uploadtime = Date.now();
        formData.append('addedon', this.uploadtime);
        formData.append('itemid', itemid);
        formData.append('process', mt);
        formData.append('teamid',  this.configSvc.activeteamid);
        formData.append('type',  'nodefile');
        formData.append('textract',  textract.toString());
        formData.append('attach',  'true');
        formData.append('folderid',  this.configSvc.folderdetailsds._id);
        formData.append('icontype',  icontype);
        formData.append('iconcolor',  iconcolor);
        formData.append('nodename',  nodename);
        formData.append('level',  '0');

        if(textract){
        formData.append('newpropvalues',  JSON.stringify(this.configSvc.newpropvalues));}
        this.authService.addNodefiles(formData, this.user).subscribe(addnodefileresults => {

         this.configSvc.teamds = addnodefileresults
         this.configSvc.nodeds[0] = this.configSvc.teamds[this.configSvc.itemindex]
         this.configSvc.tabIndex = 1
         this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
         this.fileuploadcount++;
         this.filesprocessed = this.fileuploadcount/this.totalfilesuploaded*100
         console.log('this is the filesprocessed'+this.filesprocessed)
         this.configSvc.nodeready = true
         if(this.filesprocessed== 100)
         {this.uploadfinished = true;
          this.nodetextracted = true
     
      }
     }, (err) => {
        console.error(err); });

    
    






}

public attachNode(itemid, nodeid, icontype, iconcolor, nodename, attachprops, parentid, process ) {
  // this.files = files;
  this.configSvc.nodeready = false
 
 
  
      
   
         const formData = new FormData();
   
     
     
         this.uploadtime = Date.now();
         formData.append('addedon', this.uploadtime);
         formData.append('itemid', itemid);
         formData.append('nodeid', nodeid);
         formData.append('type', 'attachnode');
         formData.append('process', process);
         formData.append('teamid',  this.configSvc.activeteamid);
 
         formData.append('attach',  'true');
         formData.append('folderid',  parentid);
         formData.append('icontype',  icontype);
         formData.append('iconcolor',  iconcolor);
         formData.append('nodename',  nodename);
         formData.append('bo',  nodename);
         formData.append('level',  '0');
       
 
      
         formData.append('attachprops',  JSON.stringify(attachprops));
         this.authService.attachNode(formData, this.user).subscribe(attachnoderesults => {
          console.log('this is the hidebrowser '+this.configSvc.hidebrowser)
          if(process == 'browse'){
            this.configSvc.teamds = attachnoderesults
            this.configSvc.nodeds[0] = this.configSvc.teamds[this.configSvc.itemindex]
            this.configSvc.tabIndex = 1
            this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
           
          }
        
          if(process == 'direct'){
            this.configSvc.nodeds[0] = attachnoderesults
            this.configSvc.hidebrowser = true
          }
          console.log('this is the nodeds data '+JSON.stringify(this.configSvc.nodeds[0]))
          this.configSvc.nodeready = true
          
      }, (err) => {
         console.error(err); });
 }



public fileReport(file, reportname) {
 
  this.uploadcount = 0;
  this.mybatch = localStorage.getItem('mybatch');

  console.log(' my local  storage batch value is ' + this.mybatch);
  this.mybatch = parseFloat(localStorage.getItem('mybatch') );
  this.mybatch ++;
  console.log(' my local storage after incrementing new batch integer value is ' + this.mybatch);
   localStorage.setItem('mybatch', this.mybatch.toString()) ;
 

      this.uploadinprogess = true;
    //  const fileEntry = file.fileEntry as FileSystemFileEntry;


     
        console.log('uploading nodefiles');
       
  
        const formData = new FormData();
       
        formData.append('batchtotaldocs', JSON.stringify(1));
       formData.append('batchnumber', this.mybatch);
        formData.append('uploadcount', JSON.stringify(this.uploadcount));
    
        this.uploadtime = Date.now();
        formData.append('addedon', this.uploadtime);
        formData.append('nodeid', this.configSvc.activeitemid);
        formData.append('teamid',  this.configSvc.activeteamid);
        formData.append('reportname',  reportname);
        formData.append('reportfile', file);
        formData.append('type', 'report');
        formData.append('mt', 'pdf');
        this.uploadfinished = false;
        this.authService.fileReport(formData, this.user).subscribe(addnodefileresults => {

         this.configSvc.nodeds = addnodefileresults
         this.uploadfinished = true;
         this.fileuploadcount++;
         this.filesprocessed = this.fileuploadcount/this.totalfilesuploaded*100
         console.log('this is the filesprocessed'+this.filesprocessed)
    
         if(this.filesprocessed== 100)
         {this.uploadfinished = true;
     
      }
     }, (err) => {
        console.error(err); });

    
     






}

public editnewImage(chatgptcomment, name, mt, size, itemid, teamid, folderid, version){
console.log('editing new image')
  const editnewimageform = new FormData();
  editnewimageform.append('process', 'editnewImage')
  // editnewimageform.append('instructions', chatgptcomment)
  editnewimageform.append('name', name)
  editnewimageform.append('mt', mt)
  editnewimageform.append('size', size)
  editnewimageform.append('itemid', itemid)
  editnewimageform.append('teamid', teamid)
  editnewimageform.append('folderid', folderid)
  editnewimageform.append('version', version)
  var msg =[]
  msg.push({'content': chatgptcomment, 'role': 'system'})
  // for(const content of this.configSvc.nodeds[0].comments){
   for(let i = 0; i < this.configSvc.nodeds[0].comments.length; i++) {
     let content = this.configSvc.nodeds[0].comments[i];
     console.log('here is the content' +JSON.stringify(content))
    
     msg.unshift({'content': content.comment, 'role': 'system'})
    }
  this.uploadtime = Date.now();
  editnewimageform.append('addedon', this.uploadtime);
  editnewimageform.append('msg', JSON.stringify(msg));
  this.authService.editnewImage(editnewimageform, this.user).subscribe(imageresults => {
    this.configSvc.teamds = imageresults
    this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
     console.log('imageresults ' + JSON.stringify(imageresults));
    
    
}, (err) => {
   console.error(err); });


 
}


public editexistingImage(chatgptcomment, mt, size, pa, name,itemid, teamid, folderid, version){
  console.log('editing new image')
    const editexistingform = new FormData();
    editexistingform.append('process', 'editexistingImage')
    editexistingform.append('name', name)
    editexistingform.append('itemid', itemid)
    editexistingform.append('teamid', teamid)
    editexistingform.append('folderid', folderid)
    editexistingform.append('version', version)
    editexistingform.append('chatgptcomment', chatgptcomment)
    editexistingform.append('mt', mt)
    editexistingform.append('size', size)
    editexistingform.append('docid', pa)
  
    this.uploadtime = Date.now();
    editexistingform.append('addedon', this.uploadtime);
  
    this.authService.editexistingImage(editexistingform, this.user).subscribe(imageresults => {
      this.configSvc.teamds = imageresults
      this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
       console.log('imageresults ' + JSON.stringify(imageresults));
      
      
  }, (err) => {
     console.error(err); });
  
  
   
  }








}
