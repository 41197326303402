<span class="scrollmenu">


            
    
  
  <span *ngIf="this.configSvc.activetool == 'addcomment'">
    <i class="material-icons icon-edit" title="Add Comment">chat</i>
  </span>

<span *ngIf="this.configSvc.activetool != 'addcomment'">
<i class="material-icons icon-edit" title="Add Comment" 
 (click)="this.openDialog('comment', '')">chat</i>
</span>





<span>
<mat-icon class="custom"  title="Ask ChatGPT" svgIcon="chat1"
(click)="this.openDialog('askchatgpt', 'Ask ChatGPT a Question')"
></mat-icon></span>
  

  
         
     
        <span>
          <i class="material-icons icon-edit"  title="Upload Files"
          (click)="this.openDialog('addfiles', this.configSvc.activename)">attach_file</i></span>
         
          <span>
            <i class="material-icons icon-edit"  title="Assign"
            (click)="this.openDialog('assign', this.configSvc.activename)">person_pin_circle</i></span>
  
  
            <span>
              <i class="material-icons icon-edit"  title="Deadline"
              (click)="this.openDialog('deadline', this.configSvc.activename)">timer</i></span>

              <span>
                <i class="material-icons icon-edit"  title="Folder Details"
                (click)="this.openDialog('folderdetails', 'Folder Details')">source</i></span>
            
               
                    <span>
                      <i class="material-icons icon-edit"  title="Generate Node Report"
                      (click)="this.openDialog('nodereport', 'Generate Node Report')">picture_as_pdf</i></span>
  
  

       <span> 
      
        <i class="material-icons icon-edit"  title="Delete {{this.configSvc.nodeds[0]?.name}}"
         (click)="this.openDialog('deletefile', this.configSvc.activename)">delete</i></span>
    
  
        
  
    <span  *ngIf="this.configSvc.activetype != 'undefined'"><a href="mailto:fehin@example.com?subject=Please find attached toolbard link for your reference {{this.configSvc.activename}}&body=Find below a link for you to review {{this.configSvc.activeurl}}"><i class="material-icons icon-edit"  title="Email {{this.configSvc.hlitem[0]?.name}}" >email</i></a> </span>




     <span> 
    
      <i class="material-icons icon-edit"  title="Move {{this.configSvc.nodeds[0]?.name}}"
       (click)="this.openDialog('cutitem', this.configSvc.activename)">content_cut</i></span>

       <span *ngIf="this.configSvc.move"> 
    
        <i class="material-icons icon-edit"  title="Paste {{this.configSvc.moveitem[0]?.name}}"
         (click)="this.openDialog('pasteItem', this.configSvc.moveitem[0]?.name)">content_paste</i></span>


  
      
      
        
  
            
             
  
  