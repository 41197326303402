import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import {ConfigService} from '../../services/config.service';
import {TagerService} from '../../services/tager.service';
import { TagService } from '../../services/tag.service';
import {FlowService} from '../../services/flow.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smartfoldertab',
  templateUrl: './smartfoldertab.component.html',
  styleUrls: ['./smartfoldertab.component.css']
})
export class SmartfoldertabComponent implements OnInit {

  public phase: number;
  public status: string;
  public next: String;
  isLinear = true;
  haslabel = false;
  hasdefault = false;
  defaulttoggle = false;
  defaultslider =false;
  public clickCount = 0;
  public itemready = false;
  public previewArray: FormArray;
  public previewFG: FormGroup;
  public newpropvalues = [];
  public stpcnt: string;
  public hltreepath = [];
  public hlchoiceset = [];
  public clickchoiceset = false;
  public sorted = [];
  treeColumns = ['Name']
  foldertypes = ['none', 'coloredfolder', 'bookmarks' ]
  chosentype: string = 'none'
  itemColumns = ['icon', 'name', 'attach', 'desc', 'mb', 'mo'];

  constructor(private authSvc: AuthenticationService, public flowSvc: FlowService,
    public configSvc: ConfigService, private fb: FormBuilder, private router: Router,
    public tagerSvc: TagerService, public tagSvc: TagService) { }

  ngOnInit() {

    this.previewFG = new FormGroup({formArrayName: this.fb.array([])})
    console.log('initing smartfoldertab page');
  
  
        this.buildstepperitems()
    this.detectStepperchanges();
  
  }
   

  buildstepperitems(){

    console.log('this is the stepper node data we are workin with: '+JSON.stringify(this.configSvc.foldertabsds[0]));

    if(this.configSvc.foldertabsds[0].type == 'activeflow')
    {
    this.phase = this.configSvc.foldertabsds[0].assignedto
    console.log('this is at phase : '+this.phase);
    this.next = this.configSvc.foldertabsds[0].assign[this.phase].next
    console.log('this is next button : '+this.next);
    }
         
          this.previewArray = this.previewFG.get('formArrayName') as FormArray;
         Object.keys(this.configSvc.foldertabsds[0].stepperfolder).forEach((i) => {
  
         console.log('this is the stepitem property for step'+i);
         console.log('this is the stepper array'+JSON.stringify(this.configSvc.foldertabsds[0].stepperfolder[i]));
         Object.keys(this.configSvc.foldertabsds[0].stepperfolder[i].tags).forEach((i2) => {
          console.log('tthis is the item number in the step'+i2);
          this.stpcnt = i2;
          console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formitem));
       
     // console.log('this is the stepitem property for step'+this.configSvc.foldertabsds.stepitems[i].formitem);
         // console.log('this is the step item value'+this.configSvc.foldertabsds.stepitems[i].value);
  
            this.previewArray.push( this.fb.group({
              step: new FormControl({ value: i, emitEvent:false}),
              stepitem:new FormControl({ value: i2, emitEvent:false}),
               property: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formitem, emitEvent:false}),
               label: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].label, emitEvent:false}),
               formitemlabel: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formitemlabel, emitEvent:false}),
              itemvalue: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].itemvalue, disabled: false  }),
               formtype: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype}),
          //   formchoices: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i2].tags[i2].formchoices}),
            choicesetid: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid}),
            choicesetname: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetname}),
            choicevalue: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicevalue, disabled: false }),
            hasdefault: new FormControl(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].hasdefault),
            treepath: new FormControl({ value:this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].treepath, emitEvent:false}),
            
              }))
  
              console.log('preview array is now:'+this.previewArray);
              
              if (this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype=='choicelist'
                && localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)===null){ 
                 
                
                console.log('this foldertab property has a list associated with it and its not in cache, lets go and get it')
             // choicesetids.push(this.configSvc.foldertabsds[i].stepitems[i2].choicesetid)
            
             this.tagerSvc.getChoicesetbyid(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid, this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype)
  
              }
  
              if (this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype=='choicelist'
              && localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)!==null){ 
                console.log('check to see if the cache variable has the localstorage cache')
                console.log('this is the choicesetid: '+this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)
             // this.tagerSvc.getFormChoices(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2]..choicesetid)
             var localdata = JSON.parse(localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid))
             console.log('this is the localdata im pushing'+localdata);
             if(!this.authSvc.choicesetids.includes(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)){
              this.authSvc.choicesetids.push(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)
              console.log('we havent added this to the auth localcache yet: '+this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid);
              console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
              this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
            }
            
            
            }

            if (this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype=='radiogroup'
            && localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)===null){ 
             
            
            console.log('this foldertab property has a list associated with it and its not in cache, lets go and get it')
         // choicesetids.push(this.configSvc.foldertabsds[i].stepitems[i2].choicesetid)
        
         this.tagerSvc.getChoicesetbyid(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid, this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype)

          }

          if (this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype=='radiogroup'
          && localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)!==null){ 
            console.log('check to see if the cache variable has the localstorage cache')
            console.log('this is the choicesetid: '+this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)
         // this.tagerSvc.getFormChoices(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2]..choicesetid)
         var localdata = JSON.parse(localStorage.getItem(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid))
         console.log('this is the localdata im pushing'+localdata);
         if(!this.authSvc.choicesetids.includes(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)){
          this.authSvc.choicesetids.push(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid)
          console.log('we havent added this to the auth localcache yet: '+this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid);
          console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
          this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
        }
        
        
        }

  
            if (this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formtype == 'treelist'){
              this.hltreepath = this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].treepath
            //  this.clickchoiceset = true;
                console.log('this is it he initial hl stepper tree path '+JSON.stringify( this.hltreepath));
               this.tagerSvc.getChoicesetbyid(this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].choicesetid, 'treelist')
               
            }
  
            
  
              if (!this.configSvc.foldertabsds[0].stepperfolder[i].tags[i2].formchoices){
                console.log('this property does NOT have a list associated with it, doing nothing')
              }

          
            
            })

            if (this.configSvc.foldertabsds[0].stepperfolder[i].type == 'linktab'){
              console.log('this has a linktab')
              this.configSvc.findSmartfolderitems(this.configSvc.foldertabsds[0].stepperfolder[i].restrict, this.configSvc.foldertabsds[0].stepperfolder[i].toolindex, this.configSvc.foldertabsds[0].stepperfolder[i].toolinval, this.configSvc.foldertabsds[0].stepperfolder[i].tool)
              
                            }
  })}

  detectStepperchanges(){
    
    console.log('checking for changes to the stepper');
    this.previewFG.get('formArrayName').valueChanges
    
    .subscribe(
      data => {
        this.newpropvalues = [];
      console.log('new propvalues: ' + JSON.stringify(data));
      for (let properties of data) {
        console.log('full property is simply '+JSON.stringify(properties));
       //console.log('this property belongs to step'+i
      //  console.log('property name is '+JSON.stringify(properties.formitem.value));
      console.log('step is '+JSON.stringify(properties.step));
      console.log('stepitem is '+JSON.stringify(properties.stepitem));
        console.log('property value is '+JSON.stringify(properties.itemvalue));
        console.log('label is '+JSON.stringify(properties.label.value));
        console.log('pformitemlabel is '+JSON.stringify(properties.formitemlabel.value));
        console.log('checkbox is '+JSON.stringify(properties.checkbox));
        console.log('textarea is '+JSON.stringify(properties.textarea));
        if(properties.formtype.value != 'text'){properties.textarea = ''}
  
        if(properties.formtype.value=='choicelist' ){
          console.log('there ARE choicesets associated with this property')
        this.newpropvalues.push({
          step:properties.step.value, 
          stepitem:properties.stepitem.value, 
          formitem:properties.property.value, 
          itemvalue:properties.itemvalue, 
         
          formtype: properties.formtype.value,   
          formitemlabel: properties.formitemlabel.value, 
          textarea: properties.textarea, 
          label: properties.label.value, 
          choicesetid:properties.choicesetid.value, 
          hasdefault: properties.hasdefault})}

          if( properties.formtype.value=='radiogroup'){
            console.log('there ARE choicesets associated with this property')
          this.newpropvalues.push({
            step:properties.step.value, 
            stepitem:properties.stepitem.value, 
            formitem:properties.property.value, 
            itemvalue:properties.itemvalue, 
           
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
            textarea: properties.textarea, 
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault})}
          if(properties.formtype.value=='treelist'){
            console.log('pushing new values based on treelist')
            console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
          this.newpropvalues.push({
            step:properties.step.value, 
          stepitem:properties.stepitem.value, 
            formitem:properties.property.value, 
            itemvalue:properties.itemvalue, 
           
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
           
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault,
            treepath: this.hltreepath
          })}
        if(properties.formtype.value=='string'|| properties.formtype.value=='number' ||
        properties.formtype.value=='checkbox' || properties.formtype.value=='date' ||
        properties.formtype.value=='text' || properties.formtype.value=='boolean'
        ){ 
          console.log('there are no choiceset associated with this property')
          this.newpropvalues.push({
            step:properties.step.value, 
          stepitem:properties.stepitem.value, 
            formitem:properties.property.value, 
            label: properties.label.value, 
            formitemlabel: properties.formitemlabel.value,  
            itemvalue:properties.itemvalue, 
            formtype: properties.formtype.value,
             textarea: properties.textarea, 
             choicesetid: 'nocs',
            hasdefault: properties.hasdefault})
            console.log('this is the updated tags:'+JSON.stringify(this.newpropvalues))
          }
     
          
      //  this.newpropvalues.push({property:properties.property.value, propval:properties.propvalue, formtype:properties.formtype.value})
      }
      
      }
    ); 
  }

  
 public getChoices(choicesetid){
  //console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
  
  var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
  //console.log('this is the filtered choicelist: '+filtered)
  return filtered;
   }
  
   public choiceChange(){
     
    console.log('choices is changed so setting default value to true');
    console.log('itemvalue is set to: '+this.previewFG.value.itemvalue);
  
   }
  
   public sortPath(){
      
      
   // console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
  this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
  //console.log('this is the sorted result: '+JSON.stringify(this.sorted))
    return this.sorted
  
  }
  
  public getChoicetree(choicesetid){
    
  
    this.getChip(choicesetid)
     this.sorted = [];
  
  
   
    this.clickchoiceset = true; 
    this.previewArray.markAsDirty();
     console.log('getting the choice tree for '+choicesetid)
     console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
   
     this.sortPath()
    if(this.authSvc.choicesetids.includes(choicesetid)){
      console.log('this choicetree has a list associated with it and its in cache')
  
    }
  
  
   if(!this.authSvc.choicesetids.includes(choicesetid)){
     console.log('ive never got this list before lets get it')
    this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
  
  }
  
  
  }
  
  public getChip(choicesetid){
  
   
    this.sorted = [];
    console.log('getting choicesetbyid with getChip type is path')
    this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
  }
  
  
  public hlchoice(selectedrow, index){
    console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
   console.log('this is itthe itemvalue: '+selectedrow.name);
   console.log('this is itthe formcontrol index: '+index);
  
   
  // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
  this.previewArray.controls[index].patchValue({'itemvalue': selectedrow.name})
   console.log('this is formtype '+this.previewArray.controls[index].value.formtype.value);
   console.log('this is fitemvalue '+this.previewArray.controls[index].value.itemvalue);
   // this.previewArray.at(index).controls['itemvalue'].patchValue('test');
   console.log('this is itthe current preview array: '+this.previewArray);
   
    this.hlchoiceset = [];
    this.hlchoiceset.push(selectedrow)
    this.hltreepath= [];
    this.hltreepath = this.hlchoiceset[0].path
    console.log('this is itthe new selected tree path '+JSON.stringify( this.hltreepath));
    this.previewArray.controls[index].patchValue({'treepath': this.hltreepath})
    this.previewArray.markAsDirty();
   }
  
   public changeRadio(selectedrow, index){
     console.log('changing radio option')
    this.previewArray.controls[index].patchValue({'itemvalue': selectedrow.name})
    this.previewArray.markAsDirty();
   }

   public changeBoolean(hasdefault, index){
   
    console.log('this is itthe formcontrol index: '+index);
    console.log('this is itthe current hasdefault value: '+hasdefault);
    console.log('this is new hasdefault value: '+!hasdefault)
    this.previewArray.markAsDirty();
   // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
   this.previewArray.controls[index].patchValue({'hasdefault': !hasdefault})
  }

  
  
   public changeType(type){
    console.log('changing folder type display option for: '+type)
   }
  
   public appyTags(){
  
    this.configSvc.applyTags(this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.foldertabsds[0]._id, 
      this.configSvc.foldertabsds[0].name, this.configSvc.foldertabsds[0].desc, 'foldertag', 
    JSON.stringify(this.newpropvalues), this.configSvc.foldertabsds[0].icontype, 
    this.configSvc.foldertabsds[0].iconcolor)
   }

   public updateFoldertabs(){
    console.log('updating stepper for  stepperid: '+this.configSvc.foldertabsds[0]._id)
    console.log('updating stepper for  stepper name: '+this.configSvc.foldertabsds[0].name)
    console.log('new properties for stepper: '+ JSON.stringify(this.newpropvalues));

    this.configSvc.updateFoldertabs(this.configSvc.foldertabsds[0].type, this.configSvc.foldertabsds[0]._id, 
   JSON.stringify(this.newpropvalues))
   this.previewArray.markAsPristine();
  }
  

   public nextPhase(){
    console.log('current phase is'+this.phase)
    console.log('current nodeds is'+JSON.stringify(this.configSvc.foldertabsds[0]))
    var totalphases = this.configSvc.foldertabsds[0].assign.length-1

    console.log('this is the total number of assigned '+this.configSvc.foldertabsds[0].assign.length)

    if(this.phase == totalphases){
      var nextphase = this.phase
      console.log('this is the last item in the process')
      this.configSvc.foldertabsds[0].assign[this.phase].status=2
      this.status = '2'
    }

    if(this.phase != totalphases){
      var nextphase = this.phase+1
      console.log('this is not the last item in the process')
      console.log('nextphase is'+nextphase)
      this.configSvc.foldertabsds[0].assign[nextphase].status=2
      this.status = '1'
    }

    var nextphasestring = nextphase.toString()


    



    console.log('this is the activeflowid'+this.configSvc.foldertabsds[0].activeflowid);
 //   console.log('this is the next team'+this.configSvc.foldertabsds[0].assign[this.phase].name);
 //   console.log('this is the next teamid'+this.configSvc.foldertabsds[0].assign[this.phase]._id);
    this.flowSvc.nextPhase(this.configSvc.foldertabsds[0].activeflowid, nextphasestring,this.status, 
      this.configSvc.foldertabsds[0].assign[this.phase]._id, this.configSvc.foldertabsds[0].assign[this.phase].name)

  }

  selectItem(item) {
this.itemready= false
    this.configSvc.attachednode =[]
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 1) {
            console.log('this is a single click')
       
    this.configSvc.linkeditem = []
  this.configSvc.attachednode.push(item)
  console.log('selected items '+JSON.stringify(this.configSvc.attachednode))
this.itemready =true
console.log('this attached item is an attachnode or attachnodetab');

   
        } else if (this.clickCount === 2) {
          console.log('this is a double click')
          console.log('active type is '+this.configSvc.activetype)
          this.configSvc.attachednode=[];
          this.configSvc.attachednode.push(item)
          console.log('selected items '+JSON.stringify(this.configSvc.attachednode))
          var base = '/toolbard/home?'
var itemid = this.configSvc.attachednode[0]._id
var teamid = this.configSvc.attachednode[0].teamid;
var type = 'nodedirect';
var name = this.configSvc.attachednode[0].name;
var level = this.configSvc.attachednode[0].level;
        
      var dynamicurl = base+'itemid='+itemid+'&type='+type+'&name='+name+'&level='+level+'&teamid='+teamid
      console.log('dynamic url is set to : '+dynamicurl);
      this.router.navigateByUrl(dynamicurl);
         
  
  
  
        }
        this.clickCount = 0;
    }, 250)
  }

}
