import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common'; 
import { NgModule } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { SidenavService } from './services/side-nav.service';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SidepanelComponent } from './navigation/sidepanel/sidepanel.component';

import { MatFormFieldModule } from '@angular/material/form-field';

import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './navigation/login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { TagteamComponent } from './navigation/tagteam/tagteam.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import { MAT_DATE_LOCALE} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';

import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlider, MatSliderModule} from '@angular/material/slider';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import { HeaderComponent } from './navigation/header/header.component';
import { ToprightComponent } from './navigation/header/topright/topright.component';
import { ActioninfoComponent } from './navigation/header/actioninfo/actioninfo.component';
import { GeneraltoolsComponent, GeneralPopup } from './navigation/header/generaltools/generaltools.component';
import { NodetoolsComponent, NodePopup } from './navigation/header/nodetools/nodetools.component';
import { designtoolsComponent, DesignPopup } from './navigation/header/designtools/designtools.component';
import { TagtoolsComponent, TagPopup } from './navigation/header/tagtools/tagtools.component';
import { SteptoolsComponent, StepPopup } from './navigation/header/steptools/steptools.component';
import { ToolbartoolsComponent, ToolbarPopup } from './navigation/header/toolbartools/toolbartools.component';
import { EditteamComponent } from './team/editteam/editteam.component';
import { EditfolderComponent } from './folder/editfolder/editfolder.component';
import { EditfileComponent } from './file/editfile/editfile.component';
import { activenodeComponent } from './node/activenode/activenode.component';
import { activesubitemComponent } from './node/activesubitem/activesubitem.component';
import { NodedetailsComponent } from './node/nodedetails/nodedetails.component';
import { ViewcommentsComponent } from './comments/viewcomments/viewcomments.component';
import { ActivestepperComponent } from './stepper/activestepper/activestepper.component';
import { TeamsecurityComponent } from './security/teamsecurity/teamsecurity.component';
import { ColorPickerComponent } from './design/color-picker/color-picker.component';
import { ColorPaletteComponent } from './design/color-palette/color-palette.component';
import { IconcategoriesComponent } from './design/iconcategories/iconcategories.component';
import { ColorSliderComponent } from './design/color-slider/color-slider.component';
import { DocdetailsComponent } from './file/docdetails/docdetails.component';
import { NodeconsoleComponent } from './builder/nodeconsole/nodeconsole.component';
import { EditnodeComponent } from './builder/editnode/editnode.component';
import { EditstepComponent } from './builder/editstep/editstep.component';
import { EditstepperComponent } from './builder/editstepper/editstepper.component';
import { EdittagComponent } from './builder/edittag/edittag.component';
import { EdittrackComponent } from './builder/edittrack/edittrack.component';
import { FoldertagsComponent } from './foldertags/foldertags/foldertags.component';
import { EditnodeteamComponent } from './team/editnodeteam/editnodeteam.component';
import { EditcategoryComponent } from './builder/editcategory/editcategory.component';
import { ToolbardComponent } from './navigation/toolbard/toolbard.component';
import { SearchcriteriaComponent } from './search/searchcriteria/searchcriteria.component';
import { SearchresultsComponent } from './search/searchresults/searchresults.component';
import { CreateteamComponent } from './team/createteam/createteam.component';
import { ChoicesetsComponent } from './lists/choicesets/choicesets.component';
import { RegisterComponent } from './security/register/register.component';
import { TeamtagsComponent } from './team/teamtags/teamtags.component';
import { FiletagsComponent } from './file/filetags/filetags.component';
import { KanbanComponent, KanbanPopup } from './kanban/kanban/kanban.component';
import { EmailtagsComponent } from './file/emailtags/emailtags.component';
import { EditflowComponent } from './builder/editflow/editflow.component';
import { EditflowitemComponent } from './builder/editflow/editflowitem/editflowitem.component';
import { AssignmentComponent } from './security/assignment/assignment.component';
import { TeamqueueComponent } from './queue/teamqueue/teamqueue/teamqueue.component';
import { FlowstatusComponent } from './queue/flowstatus/flowstatus.component';
import { ChoicetoolsComponent, ChoicePopup } from './navigation/header/choicetools/choicetools.component';
import { EditkanbanComponent } from './builder/editkanban/editkanban.component';
import { EdittabsComponent } from './builder/edittabs/edittabs.component';
import { ActivetabsComponent } from './stepper/activetabs/activetabs.component';
import { FormfolderComponent } from './formfolder/formfolder/formfolder.component';
import { StepperfolderComponent } from './stepper/stepperfolder/stepperfolder.component';
import { SmartfoldertabComponent } from './stepper/smartfoldertab/smartfoldertab.component';
import { QueueresultsComponent } from './search/queueresults/queueresults.component';
import { QueuesearchComponent } from './search/queuesearch/queuesearch.component';
import { UsersecurityComponent } from './security/usersecurity/usersecurity.component';
import { EditsetComponent } from './lists/editset/editset.component';
import { EditlistComponent } from './lists/editlist/editlist.component';
import { PrefsComponent } from './preferences/prefs/prefs.component';
import { VersioninfoComponent } from './preferences/versioninfo/versioninfo.component';
import { CompanyinfoComponent } from './preferences/companyinfo/companyinfo.component';
import { PeopleComponent } from './preferences/people/people.component';
import { FavlocationsComponent } from './navigation/header/favlocations/favlocations.component';
import { DocversionsComponent } from './file/docversions/docversions.component';
import { RegisteruserComponent } from './security/registeruser/registeruser.component';
import { RegistercompanyComponent } from './security/registercompany/registercompany.component';
import { EmailconfirmComponent } from './security/emailconfirm/emailconfirm.component';
import { CompleteregistrationComponent } from './security/completeregistration/completeregistration.component';
import { InvitesComponent } from './security/invites/invites.component';
import { ImageversionsComponent } from './node/imageversions/imageversions.component';
import {DatePipe} from '@angular/common';
import { NodephotosComponent } from './node/nodephotos/nodephotos.component';
import { NodefilesComponent } from './node/nodefiles/nodefiles.component';
import { NodedirectComponent } from './node/nodedirect/nodedirect.component';
import { ResetpwordComponent } from './security/resetpword/resetpword.component';
import { ResetemailComponent } from './security/resetemail/resetemail.component';
import { WelcomeComponent } from './navigation/welcome/welcome.component';
import { TeamtoolsComponent, TeamToolsPopup } from './navigation/header/teamtools/teamtools.component';
import { RoottoolsComponent, RootToolsPopup } from './navigation/header/roottools/roottools.component';
import { designteamComponent, DesignTeamPopup } from './navigation/header/designteam/designteam.component';
import { FlowassignmentComponent } from './security/flowassignment/flowassignment.component';
import { AssignedComponent } from './security/assigned/assigned.component';
import { FiletoolsComponent, FilePopup } from './navigation/header/filetools/filetools.component';
import { FileimageComponent } from './file/fileimage/fileimage.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { MyfeedComponent } from './myfeed/myfeed/myfeed.component';
import { QueuetoolsComponent, QueuePopup } from './navigation/header/queuetools/queuetools.component';
import { DeadlineComponent } from './security/deadline/deadline.component';
import { SmartfoldersComponent } from './builder/smartfolders/smartfolders.component';
import { TextractionComponent } from './node/textraction/textraction.component';
import { QueuedetailsComponent } from './queue/queuedetails/queuedetails.component';
import { WizarddirectComponent } from './stepper/wizarddirect/wizarddirect.component';
import { AttachednodeComponent } from './node/attachednode/attachednode.component';
import { AttachedtabsComponent } from './stepper/attachedtabs/attachedtabs.component';
import { PreviewkanbanComponent } from './builder/previewkanban/previewkanban.component';
import { LineitemsComponent } from './node/lineitems/lineitems.component';
import { LocktoolbarComponent } from './folder/locktoolbar/locktoolbar.component';
import { FilesummaryComponent } from './file/filesummary/filesummary.component';
import { ImageversionComponent } from './file/imageversion/imageversion.component';
import { ChatgptdirectComponent } from './comments/chatgptdirect/chatgptdirect.component';
import { PromptsComponent } from './lists/prompts/prompts.component';
import { PrompttoolsComponent, PromptPopup } from './navigation/header/prompttools/prompttools.component';
import { AisearchresultsComponent } from './search/aisearchresults/aisearchresults.component';






const routes: Routes = [
  { path: '', redirectTo: 'toolbard/home', pathMatch: 'full' },
//  { path: 'pnf', component: PnfComponent},
 { path: 'login', component: LoginComponent },
{ path: 'toolbard/register', component: RegisterComponent },
{ path: 'registercompany', component: RegistercompanyComponent },
{ path: 'resetemail', component: ResetemailComponent },
{ path: 'resetpword', component: ResetpwordComponent},
{ path: 'registeruser', component: RegisteruserComponent },
{ path: 'completeregistration', component: CompleteregistrationComponent },
{ path: 'emailconfirm', component: EmailconfirmComponent },
 { path: 'toolbard/mypreferences', component: PrefsComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/version', component: VersioninfoComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/company', component: CompanyinfoComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/people', component: PeopleComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/home', component: TagteamComponent , canActivate: [AuthGuardService]},

 { path: 'toolbard/imagevers', component: TagteamComponent , canActivate: [AuthGuardService]},
 //{ path: 'toolbard/builder', component: TagerbuilderComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/teams', component: CreateteamComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/search', component: SearchcriteriaComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/myfeed', component: MyfeedComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/nodeconsole', component: NodeconsoleComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/teamqueue', component: TeamqueueComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/choicesets', component: ChoicesetsComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard/prompts', component: PromptsComponent , canActivate: [AuthGuardService]},
 { path: 'toolbard', redirectTo: 'toolbard/home' },
// { path: '/', redirectTo: 'toolbard/team' },
 { path: '**', redirectTo: 'pnf' }


];

@NgModule({
  declarations: [
    
    AppComponent,
    SidepanelComponent,
    LoginComponent,
    TagteamComponent,
    HeaderComponent,
    ToprightComponent,
    ActioninfoComponent,
    GeneraltoolsComponent,
    designtoolsComponent,
    TagtoolsComponent,
    ToolbartoolsComponent,
    ToolbarPopup,
    GeneralPopup,
    QueuePopup,
    TeamToolsPopup,
    FilePopup,
    RootToolsPopup,
    NodePopup,
    DesignPopup,
    DesignTeamPopup,
    TagPopup,
    StepPopup,
    KanbanPopup,
    ChoicePopup,
    PromptPopup,
    EditteamComponent,
    EditfolderComponent,
    EditfileComponent,
    activenodeComponent,
    activesubitemComponent,
    NodedetailsComponent,
    ViewcommentsComponent,
    ActivestepperComponent,
    TeamsecurityComponent,
    ColorPickerComponent,
    ColorPaletteComponent,
    IconcategoriesComponent,
    ColorSliderComponent,
    DocdetailsComponent,
    NodeconsoleComponent,
    EditnodeComponent,
    EditstepComponent,
    EditstepperComponent,
    EdittagComponent,
    EdittrackComponent,
    FoldertagsComponent,
    EditnodeteamComponent,
    EditcategoryComponent,
    ToolbardComponent,
    SearchcriteriaComponent,
    SearchresultsComponent,
    CreateteamComponent,
    ChoicesetsComponent,
    RegisterComponent,
    TeamtagsComponent,
    FiletagsComponent,
    KanbanComponent,
    EmailtagsComponent,
    EditflowComponent,
    EditflowitemComponent,
    AssignmentComponent,
    TeamqueueComponent,
    FlowstatusComponent,
    ChoicetoolsComponent,
    EditkanbanComponent,
    EdittabsComponent,
    ActivetabsComponent,
    FormfolderComponent,
    StepperfolderComponent,
    SmartfoldertabComponent,
    QueueresultsComponent,
    QueuesearchComponent,
    UsersecurityComponent,
    EditsetComponent,
    EditlistComponent,
    PrefsComponent,
    VersioninfoComponent,
    CompanyinfoComponent,
    PeopleComponent,
    FavlocationsComponent,
    DocversionsComponent,
    RegisteruserComponent,
    RegistercompanyComponent,
    CompleteregistrationComponent,
    EmailconfirmComponent,
    InvitesComponent,
    ImageversionsComponent,
    NodetoolsComponent,
    NodephotosComponent,
    NodefilesComponent,
    NodedirectComponent,
    ResetpwordComponent,
    ResetemailComponent,
    WelcomeComponent,
    TeamtoolsComponent,
    RoottoolsComponent,
    designteamComponent,
    FlowassignmentComponent,
    AssignedComponent,
    FiletoolsComponent,
    FileimageComponent,
    FooterComponent,
    MyfeedComponent,
    QueuetoolsComponent,
    DeadlineComponent,
    SmartfoldersComponent,
    TextractionComponent,
    QueuedetailsComponent,
    WizarddirectComponent,
    AttachednodeComponent,
    AttachedtabsComponent,
    SteptoolsComponent,
    PreviewkanbanComponent,
    LineitemsComponent,
    LocktoolbarComponent,
    FilesummaryComponent,
    ImageversionComponent,
    ChatgptdirectComponent,
    PromptsComponent,
    PrompttoolsComponent,
    AisearchresultsComponent,
  
 
  
  ],
  imports: [
    MatListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatExpansionModule,    
    MatMenuModule,
    MatToolbarModule,
    MatTooltipModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatTabsModule,
    DragDropModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatSortModule,
    MatAutocompleteModule,
    HttpClientModule,
    NgxFileDropModule,
     MatInputModule,
    MatStepperModule,
    MatGridListModule,
    MatDatepickerModule,
    RouterModule.forRoot(routes), 
   
  ],

  entryComponents: [
 
  ToolbartoolsComponent, ToolbarPopup,
  GeneraltoolsComponent, GeneralPopup, 
  FiletoolsComponent, FilePopup,
  TeamtoolsComponent, TeamToolsPopup,
  RoottoolsComponent, RootToolsPopup,
  designtoolsComponent, DesignPopup,
  designteamComponent, DesignTeamPopup,
  NodetoolsComponent, NodePopup,
  TagtoolsComponent, TagPopup,
  SteptoolsComponent, StepPopup,
  KanbanComponent, KanbanPopup,
  ChoicetoolsComponent, ChoicePopup,
  PrompttoolsComponent, PromptPopup,
  QueuetoolsComponent, QueuePopup

],
  providers: [
    SidenavService,
    AuthenticationService,
    AuthGuardService,
    DatePipe,


    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  
  bootstrap: [AppComponent],
  exports:
  [ 
    CdkAccordionModule,
    MatFormFieldModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    MatStepperModule,
    MatGridListModule,

    ScrollingModule,
    MatTableModule,
  CdkTableModule, 
  DragDropModule,
  MatExpansionModule,

  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
 
]
})
export class AppModule { }
