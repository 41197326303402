import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import { AuthenticationService } from '../../services/authentication.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter,CdkDragExit} from '@angular/cdk/drag-drop';


import { Router, ActivatedRoute } from '@angular/router';
import { PrefsService } from 'src/app/services/prefs.service';

export interface Operator {
  value: string;
  viewValue: string;
  type: string;
}

@Component({
  selector: 'app-prefs',
  templateUrl: './prefs.component.html',
  styleUrls: ['./prefs.component.css']
})


export class PrefsComponent implements OnInit {



  editprefFG: FormGroup;
  locations = ['home', 'search', 'myfeed', 'nodeconsole', 'teamqueue', 'choicesets']
  boolvalues = [true, false]
  searchcolumns = ['name', 'searchurl'];
  toolfavscolumns = ['icon', 'name', 'toolid', 'link'];
  public itemsadded = [];
  public feedsearchselected = '';
  public indexselected: number;
  public newindex = 0

  public favselected  = false
  public movecompleted  = false
  public reset: any[] = [{}];
 
  constructor(
    private authService: AuthenticationService,  public prefSvc: PrefsService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder, public configSvc: ConfigService, public auth: AuthenticationService)  { }

  ngOnInit(): void {

    this.configSvc.mode = 'prefs'
    console.log('initi prefs')
    this.prefSvc.profileready = false;
    this.configSvc.activetype = 'prefs'
  
     console.log('off to the the profile data')
        
    this.movecompleted  = false
       

 this.authService.getPreferences(this.router.url).subscribe(profiledata => {
  console.log('got the profile data')
  this.prefSvc.defloc = profiledata[0].defloc
  this.prefSvc.filter = profiledata[0].filter
  this.prefSvc.userfilter = profiledata[0].userfilter
  this.prefSvc.search = profiledata[0].search
  this.prefSvc.feedsearch =profiledata[0].feedsearch;
  this.prefSvc.feedurl =profiledata[0].feedurl;
  this.prefSvc.toolfavs = profiledata[0].toolfavs
  this.prefSvc.hc = profiledata[0].hc
  this.prefSvc.assignedto = profiledata[0].assignedto
  this.prefSvc.state = profiledata[0].state
  this.prefSvc.pagesize = profiledata[0].pagesize


  this.prefSvc.profileready = true;
     this.editprefFG = this.fb.group({
       defloc: [this.prefSvc.defloc],
      filter: [this.prefSvc.filter, Validators.required],
      userfilter: [this.prefSvc.userfilter, Validators.required],
      search: [this.prefSvc.search, Validators.required],
      feeds:  [[], Validators.required],
      feedsearch:  [this.prefSvc.feedsearch, Validators.required],
      feedurl:  [this.prefSvc.feedurl, Validators.required],
      toolfavs: [this.prefSvc.toolfavs, Validators.required],
      hc: [this.prefSvc.hc, Validators.required],
      username: [this.configSvc.filtereduser, Validators.required],
      assignedto: [this.prefSvc.assignedto, Validators.required],
      state: [this.prefSvc.state, Validators.required],
      pagesize: [this.prefSvc.pagesize, Validators.required],
      
    });
    
    console.log('default location on init is '+this.editprefFG.value.defloc);
    console.log('filter on init is '+this.editprefFG.value.filter);
    console.log('userfilter on init is '+this.editprefFG.value.userfilter);
    console.log('toolfavs on init is '+this.editprefFG.value.toolfavs);
    console.log('state on init is '+this.editprefFG.value.state);
    this.prefSvc.profileready = true;

}, (err) => {
     console.error(err);
   
    });

     

   
  }

  public onRecreate(){
    this.reset[0] = {}
  }
 

  public updatePrefs(){
    console.log('updating preferences')
    console.log('new default location is '+this.editprefFG.controls['defloc'].value);
    
    var defloc = this.editprefFG.controls['defloc'].value
    var hc = this.editprefFG.controls['hc'].value
    var userfilter = this.editprefFG.controls['userfilter'].value
    var filter = this.editprefFG.controls['filter'].value
    var pagesize = this.editprefFG.controls['pagesize'].value
  
    var assignedto = this.editprefFG.controls['assignedto'].value
    var state = this.editprefFG.controls['state'].value
 //   var feedurl = this.editprefFG.controls['feedurl'].value
    this.prefSvc.updatePrefs(defloc, hc, pagesize, assignedto, state, filter, userfilter)
  }

  

  public changeFeed(event){
    console.log('changed my feed'+event)
  }

  public clickFav(fav, index){
    console.log('clicked fav')
  }

  public selectItem(item, index){
   
    this.itemsadded = []
    this.favselected = true;
    this.indexselected = index
this.itemsadded.push(item)
console.log('selected items '+JSON.stringify(this.itemsadded))
console.log('planning to add them to  '+JSON.stringify(this.configSvc.activeitemid))

  
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.prefSvc.toolfavs, event.previousIndex, event.currentIndex)
     
    }

  usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
  console.log('usernameFilter value is currently set to '+this.editprefFG.value.usernameFilter)

  }
  
  }

  public removeToolfavs(toolid, name)
{
  console.log('deleting fav toolid '+toolid)
  console.log('for tool '+name)
  this.prefSvc.removeToolfavs(toolid, name)
  this.favselected = false
}

public moveupToolfavs(toolid, name){
  console.log('moving toolid up '+toolid)
  console.log('moving name up '+name)
  
  this.arraymove(this.prefSvc.toolfavs,this.indexselected,  this.indexselected+1)
  
 
}

public movedownToolfavs(toolid, name){
  console.log('moving toolid down '+toolid)
  console.log('moving name down '+name)
  console.log('indexselected '+this.indexselected)
  this.newindex = this.indexselected-1
  console.log('newindexselected '+this.newindex)
  this.arraymove(this.prefSvc.toolfavs,this.indexselected,  this.newindex )

}

public updateToolfavs(){
  console.log('new fav positions '+JSON.stringify(this.prefSvc.toolfavs))
 
  this.prefSvc.updateToolfavs(JSON.stringify(this.prefSvc.toolfavs));

}

public arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  console.log('this is the new favs array '+JSON.stringify(this.prefSvc.toolfavs))
}
 
 

}
