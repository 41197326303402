
<div class= "assign" >
   <mat-card class="findusercard">

    

           
    
          <span>
           Assign User
            </span>

                                 
                                    

                                      <span >
                                    
                                          <mat-form-field [floatLabel]=true><input [formControl]="usersearchfc" matInput placeholder="Search By user Name"></mat-form-field>
                                   
                                          <button mat-stroked-button (click)="this.findUser(); this.configSvc.usercurrentlyadded = false;"  Width="89px"
                               
                                          >Find User</button>
                                        
                                        </span>
              
       
          </mat-card>
            </div>

            

              <mat-card class="selectusercard">
            <mat-grid-list cols= "7" rows = "5" rowHeight = "50px">
              
            <mat-grid-tile class="userlist" colspan= "2" rowspan = "5">
           

             <div  class="example-list">
              <div class="box-list" *ngFor="let user of configSvc.findteamuserds" (click)="this.selectUser(user)"
                [style.background]=" this.hluser.indexOf(user) == 0 ? 'lightblue' : ''">
                <div>
                  <i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >person</i>{{user.username}}
                </div></div>
         </div>
            </mat-grid-tile>
                             <mat-grid-tile colspan= "1" rowspan = "5">
                               <span fxLayout="column" fxLayoutAlign="space-around center">
         <span *ngIf="this.userselected" > 
           <button mat-raised-button color="primary"
           (click)="this.updateAssignment(this.hluser)">Assign</button>
         </span> 
         <span *ngIf="!this.userselected" > <button mat-raised-button color="primary" 
          (click)="this.unassignUser()">Remove Assignment</button>
        </span>
      </span>
                             </mat-grid-tile>
<mat-grid-tile class="selectedusers" colspan= "2" rowspan = "5">
<div cdkDropList  (cdkDropListDropped)="drop($event)">
<div class="box-list" *ngFor="let assigned of this.configSvc.assigned, let i = index " cdkDrag 
  (click)="this.selectAssignment(assigned, i)"
  [style.background]=" this.hlassignment.indexOf(assigned) == 0 ? 'lightblue' : ''">
   <div>

 
  <i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >person</i>{{assigned}}
  </div>

</div>
</div>  </mat-grid-tile>


  </mat-grid-list>
                 
                
                 
                   

                  
                
              
              </mat-card>

      
              
           
                        