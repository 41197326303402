<div class="mat-elevation-z8" >
    <table mat-table [dataSource]="this.uploadSvc.imageverds">
      
    <ng-container matColumnDef="name" >
          <th mat-header-cell *matHeaderCellDef>FileName </th>
       <td mat-cell *matCellDef="let element">
        
           {{element.name}}
     </td></ng-container>

     <ng-container matColumnDef="ver" >
        <th mat-header-cell *matHeaderCellDef>Version </th>
        <td mat-cell *matCellDef="let element">
        
        {{element.ver}}
        </td></ng-container>
 


<ng-container matColumnDef="ab" >
<th mat-header-cell *matHeaderCellDef>Added By </th>
<td mat-cell *matCellDef="let element">

{{element.ab}}
</td></ng-container>

<ng-container matColumnDef="ao" >
<th mat-header-cell *matHeaderCellDef>Added On </th>
<td mat-cell *matCellDef="let element">

{{element.ao | date:'medium'}}  
</td></ng-container>

<ng-container matColumnDef="desc" >
    <th mat-header-cell *matHeaderCellDef>Description </th>
    <td mat-cell *matCellDef="let element">
    
    {{element.stepitems[0]?.itemvalue}}  
    </td></ng-container>

<tr mat-header-row *matHeaderRowDef="versionColumns;  sticky: true"></tr>
<tr mat-row *matRowDef="let selectedrow; columns: versionColumns; let i2 = index;"
(click)="this.selectItem(selectedrow)"
[style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"

></tr>

         
    
       </table>
<div *ngIf="this.configSvc.tageritemready && this.itemselected" >
    <span *ngFor="let r of reset" >
        
        <app-activenode></app-activenode>
      </span></div>  </div>