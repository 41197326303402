import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {DocSearchService} from '../../services/docsearch.service';
import { PrefsService } from 'src/app/services/prefs.service';
import { Router, ActivatedRoute, NavigationExtras  } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-myfeed',
  templateUrl: './myfeed.component.html',
  styleUrls: ['./myfeed.component.css']
})
export class MyfeedComponent implements OnInit, AfterViewInit {

  // displayedColumns = [];
  public  activeColumns = ['mt','name', 'ab', 'ao', 'atcmt', 'desc', 'ti'];

 //  dataSource = new MatTableDataSource(this.configSvc.myfeed);
  public sorted = [];
  public itemselected = false;
  public itemsadded = [];
  public clickCount = 0;
  public dateTime;

  constructor(public configSvc: ConfigService, public searchDocsvc: DocSearchService, public prefSvc: PrefsService,
    private authService: AuthenticationService,  private router: Router) { }

 // @ViewChild(MatSort) sort: MatSort;

  ngOnInit(){

    this.configSvc.mode = 'myfeed'
    console.log('off to get my feed base don this url '+this.router.url)
    console.log('off to get feedsearch '+this.prefSvc.feedsearch)
    this.dateTime = new Date().toISOString()
    this.configSvc.nodeready =false

    this.authService.myFeed(this.router.url).subscribe(myfeeddata => {
      console.log('off to the the feed data')
    
      this.configSvc.myfeed = myfeeddata
      this.configSvc.cachedfeed = this.configSvc.myfeed.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
        
         console.log('got the feed data '+JSON.stringify(this.configSvc.myfeed))
         this.configSvc.activetype = 'myfeed'
         this.configSvc.feedready = true;
         this.configSvc.feedcnt = this.configSvc.myfeed.length
         this.configSvc.nodeready =true
      
     
         
       }, (err) => {
         console.error(err);
       
        });}

  ngAfterViewInit() {
  //  this.configSvc.myfeed.sort = this.sort;
  }


public singleClick(){
  console.log('single clicking the search result item');
}

public doubleClick(){
  console.log('double clicking the search result item');
  
}

public pathSort(path){
  this.sorted = [];

this.sorted = path.sort((a, b) => Number(a.level) - Number(b.level));

  return this.sorted

}

clickFeeditem(item, itemindex) {
 
  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {
          console.log('this is a single click')
          this.itemselected = true;
  console.log('is item selected? '+this.itemselected)
  this.itemsadded = []
this.itemsadded.push(item)
console.log('selected items '+JSON.stringify(this.itemsadded))
      } else if (this.clickCount === 2) {
        console.log('this is a double click on item number'+itemindex)
        console.log('should clear the @comment here if there is one')
        
    this.configSvc.feeditem = itemindex
        this.itemselected = true;

this.itemsadded = []
this.itemsadded.push(item)
console.log('this is the image item info'+JSON.stringify(this.itemsadded[0]))
var base = '/toolbard/home?'
var itemid = this.itemsadded[0]._id
var teamid = this.itemsadded[0].teamid;
var type = this.itemsadded[0].type;
if(type == 'node'){ type = 'nodedirect'}

if(type == 'nodetab'){ type = 'tabbeddirect'}
if(type == 'nodeimage'){ type = 'imagedirect'};
 if(type == 'file'){ type = 'filedirect'}
 if(type == 'chatgpt'){ type = 'chatgptdirect'}

var name = this.itemsadded[0].name;
var level = this.itemsadded[0].level;
// var dynamicurl = base+'itemid='+itemid+'&type='+type+'&teamid='+teamid
var dynamicurl = base+'itemid='+itemid+'&type='+type+'&level='+level+'&teamid='+teamid+'&name='+name
this.configSvc.tageritemready = false;
this.configSvc.clicksource = 'myfeed';
this.router.navigateByUrl(dynamicurl);


      }
      this.clickCount = 0;
  }, 250)
}



}
