
<span *ngIf = "data.type=='folder' || data.type=='smartfolder'|| data.type=='smartfoldertab' || data.type=='kanban' ">

  
  <div mat-dialog-content>
   <span>
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <form [formGroup]="folderFG">

     
      <mat-step [stepControl]="step1">
        <ng-template matStepLabel>
         
      
         Folder Features </ng-template>


         


<mat-radio-group formControlName ="options" class="indextypes">
  <mat-radio-button value="0">No Tags</mat-radio-button> 
<span *ngIf="this.configSvc.activelevel != '0'">
  <span  *ngIf="this.configSvc.folderdetailsds.tagged" >
<mat-radio-button value="1">Tagged with {{this.configSvc.folderdetailsds.tagname}}
<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
{{this.configSvc.folderdetailsds?.nodename}}
</mat-radio-button>
<mat-checkbox formControlName="tagreview" > Review Tags</mat-checkbox>
</span></span>
<span *ngIf="this.configSvc.teamdetailsds?.tagged  ">
<mat-radio-button value="2">Index with Teamtags
<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
{{this.configSvc.teamdetailsds?.nodename}}
</mat-radio-button></span>

<span *ngIf="!this.configSvc.folderdetailsds?.tagged ">
  <mat-radio-button value="5">Apply New Folder Tags

  </mat-radio-button></span>


<mat-radio-button value="3">Bulk Add Folders with CSV</mat-radio-button>
<mat-radio-button value="4"> Smart Folder</mat-radio-button>
</mat-radio-group>

<span *ngIf ="this.folderFG.value.options != '3' && this.folderFG.value.options != '4'">
  <mat-form-field  appearance="fill"  class = "folders" >
    
    <input formControlName ="name"  matInput placeholder="Folder Name"  autocomplete="off" (keyup.enter)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;">
  </mat-form-field>

  <mat-form-field  appearance="fill"  class = "folderdesc" >
    
    <input formControlName ="desc"  matInput placeholder="Describe what the folder is for (Optional)"  autocomplete="off">
  </mat-form-field>

</span>

<span *ngIf ="this.folderFG.value.options != '3' && this.folderFG.value.options != '4'">
<div >  <mat-checkbox formControlName = "kanban">Apply Kanban</mat-checkbox>  </div>
<div >  <mat-checkbox formControlName = "locktoolbar">Set default Toolbar</mat-checkbox>  </div>
<div  *ngIf ="this.folderFG.value.flow == false">  <mat-checkbox formControlName ="assigned">Assign to User</mat-checkbox> </div>
<div >  <mat-checkbox  formControlName ="deadline">Set Deadline</mat-checkbox>  </div> 
<div >  <mat-checkbox formControlName ="addcomment">Add Comment</mat-checkbox>  </div>
<div >  <mat-checkbox formControlName ="counttotals">Count Totals</mat-checkbox>  </div>

</span>

<span *ngIf ="this.folderFG.value.options == '2'">
  <button mat-button matStepperNext matSuffix 
  mat-raised-button color="primary" 
  >Next </button></span>

  <span *ngIf ="this.folderFG.value.options == '5' ">
    <button [disabled]="!this.folderFG.get('name').dirty" mat-button matStepperNext matSuffix 
    mat-raised-button color="primary"  [ngStyle]="{'margin-right': '10px'}"
    >Find Foldertags Next </button></span>

    <span  *ngIf ="this.folderFG.value.options == '3' " >
      <button mat-button matStepperNext matSuffix 
      mat-raised-button color="primary"  [ngStyle]="{'margin-right': '10px'}"
      >Bulk Add Folders Next </button></span>

      <span  *ngIf ="this.folderFG.value.options == '4' " >
        <button mat-button matStepperNext matSuffix 
        mat-raised-button color="primary"  [ngStyle]="{'margin-right': '10px'}"
        >Smart Folder Next </button></span>





  <span *ngIf ="this.folderFG.value.options == '0'">
    <span *ngIf ="this.folderFG.value.kanban || this.folderFG.value.locktoolbar || this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment">
    <button [disabled]="!this.folderFG.get('name').dirty"  mat-raised-button color="primary" matStepperNext   [ngStyle]="{'margin-right': '10px'}">Got Folder name Next</button>
  </span>

  <span *ngIf ="!this.folderFG.value.kanban && !this.folderFG.value.locktoolbar  && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment">
    <button [disabled] ="!folderFG.get('name').dirty" mat-raised-button color="primary" (click)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;"   [ngStyle]="{'margin-right': '10px'}">Create Folder & Close</button>
  </span>

  

 
</span>

<span *ngIf ="this.folderFG.value.options == '1'">
  <span *ngIf ="this.folderFG.value.kanban || this.folderFG.value.locktoolbar || this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.tagreview =='1'">
  <button [disabled]="!this.folderFG.get('name').dirty"  mat-raised-button color="primary" matStepperNext   [ngStyle]="{'margin-right': '10px'}">Got Folder name Next</button>
</span>

<span *ngIf ="!this.folderFG.value.kanban && !this.folderFG.value.locktoolbar && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && this.folderFG.value.tagreview =='0'">
  <button [disabled] ="!folderFG.get('name').dirty" mat-raised-button color="primary" (click)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;"   [ngStyle]="{'margin-right': '10px'}">Create Folder & Close</button>
</span>




</span>





<button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>

</mat-step>

<mat-step [stepControl]="step2" *ngIf="this.folderFG.value.options =='1' && this.folderFG.value.tagreview" >  <ng-template matStepLabel>{{this.configSvc.folderdetailsds.tagname}}</ng-template>
<app-foldertags  [indexlaunch]="'toolbar'"></app-foldertags>

<span >
  <button *ngIf ="!this.folderFG.value.kanban && !this.folderFG.value.locktoolbar && !this.folderFG.value.folderassign && 
  !this.folderFG.value.deadline && !this.folderFG.value.addcomment" 
  mat-raised-button color="primary"  (click)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;"  [ngStyle]="{'margin-right': '10px'}">Create Folder</button>

  <button *ngIf ="this.folderFG.value.kanban || this.folderFG.value.locktoolbar || this.folderFG.value.folderassign || 
  this.folderFG.value.deadline || this.folderFG.value.addcomment" 
  mat-raised-button color="primary"  matStepperNext  [ngStyle]="{'margin-right': '10px'}">Next</button>
 
  <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
</span>

</mat-step>

<mat-step [stepControl]="step2" *ngIf="this.folderFG.value.options =='2'" >  <ng-template matStepLabel>Complete Team Indexing</ng-template>

<app-teamtags></app-teamtags>

<span class= "genpopupbottom">
  <button  mat-raised-button color="primary"  (click)="addFolder(data.name, indextype)"  [ngStyle]="{'margin-right': '10px'}">Create</button>
  <button mat-button matStepperPrevious matSuffix 
  mat-raised-button color="primary">Previous </button>
  <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
</span>


</mat-step>

<mat-step [stepControl]="step3" *ngIf="this.folderFG.value.options =='3'" >  <ng-template matStepLabel>Bulk Add Folders</ng-template>

  <span mat-dialog-content>
    Bulk Upload folders to {{this.configSvc.activename}}
     
   </span>
   <div mat-dialog-actions>
    
 <ngx-file-drop dropZoneLabel="Drop csv file here"
    (onFileDrop)="bulkAddfolders($event, bulkindex)"
             (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
            >
           
           </ngx-file-drop>
 
           <mat-card>
             <mat-card-content>
               <h2 class="progress-h2">Automation Progess</h2>
               <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
             
             </mat-card-content>
           </mat-card>
           
 <span *ngIf="this.configSvc.activetype == 'fold'">
           <mat-radio-group formControlName = "bulkindex" class="indextypes"> 
             <span>
             <mat-radio-button value="1">Index with
               <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
               {{this.configSvc.folderdetailsds?.nodename}}
               </mat-radio-button></span>
           
       
            
            </mat-radio-group></span>
 
            <span *ngIf="this.configSvc.activetype == 'editteam' || this.configSvc.activetype == 'team' ">
             <mat-radio-group formControlName = "bulkindex" class="indextypes"> 
             
               <span *ngIf="this.configSvc.teamdetailsds.tagged">
               <mat-radio-button value="2">Index with Teamtags
                 <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
                 {{this.configSvc.teamdetailsds?.nodename}}
               </mat-radio-button></span>
         
               <mat-radio-button value="0">No Team indexing</mat-radio-button>
              </mat-radio-group>
            </span>
 
           <span class= "genpopupbottom">
            <button mat-button (click)="onNoClick()" 
                      
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Close Upload">Finished Bulk </button></span>
       
   </div>
  
 
  
  
  </mat-step>

  <mat-step [stepControl]="step3" *ngIf="this.folderFG.value.options =='4'" >  <ng-template matStepLabel>Find Smart Folder</ng-template>
    Find Smart Folder

    <div >
      <mat-form-field appearance="fill">
        <mat-label>Smart Folder</mat-label>
       
        <input type="text"  
        matInput formControlName = "nodename"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="smartfolderFilter($event)" placeholder="Smart Folder" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of this.configSvc.findsmartfolderds" [value]="item.name"
    (onSelectionChange)="smartfolderSelected(item)">
    {{item.name}} 
    
    </mat-option>
        </mat-autocomplete>
    
       
       </mat-form-field>
    
       <span *ngIf="this.smartfolderready">
    
        <app-activenode></app-activenode>
    
        
       </span>

       <span *ngIf="this.smartfoldertabready">
    
        <app-activestepper></app-activestepper>
    
        
       </span>
    </div>
<span *ngIf="this.smartfolderready || this.smartfoldertabready">
    <button 
    mat-button (click)="createSmartfolder(); this.dialogRef.close(); this.configSvc.quickadd = false;" 
                      
    matSuffix  mat-raised-button color="primary" 
    aria-label="Close Upload">Create {{this.configSvc.stepperprops[0]?.itemvalue}} and Close </button>
  
 
  
  
  </span>
</mat-step>

  <mat-step [stepControl]="step4" *ngIf="this.folderFG.value.options =='5'" >  <ng-template matStepLabel>Find Folder Tags</ng-template>
    Find Folder Tags

    <div >
      <mat-form-field appearance="fill">
        <mat-label>Folder Tags</mat-label>
       
        <input type="text"  
        matInput formControlName = "nodename"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="foldertagFilter($event)" placeholder="Folder Tags" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let item of this.configSvc.finditemds" [value]="item.name"
    (onSelectionChange)="foldertagSelected(item)">
    {{item.name}} 
    
    </mat-option>
        </mat-autocomplete>
    
       
       </mat-form-field>
    
       <span *ngIf="this.indexready">
    
        <app-activenode></app-activenode>
    
        <div *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
          <div >
          <mat-label>Total</mat-label>
          <input formControlName ="total">
        
      
          </div></div>
      
        
        
        <button  *ngIf ="this.folderFG.value.assigned || this.folderFG.value.deadline "mat-button  matStepperNext matSuffix 
    mat-raised-button color="primary"> Next</button> 
    
    
    <button  *ngIf ="this.folderFG.value.foldertags "mat-button  matSuffix 
    mat-raised-button color="primary"(click) = "this.applyTags();  this.dialogRef.close(); this.configSvc.quickadd = false;">Apply Folder Tags and Close</button>
    

       </span>
    </div>
<span *ngIf="this.indexready">
    <button  *ngIf ="!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.kanban && !this.folderFG.value.locktoolbar && !addcomment " 
    mat-button (click)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;" 
                      
    matSuffix  mat-raised-button color="primary" 
    aria-label="Close Upload">Create Folder with new Folder Tags and Close </button>
  
    <button  *ngIf ="this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.kanban || this.folderFG.value.locktoolbar || addcomment " 
    mat-button matStepperNext
                      
    matSuffix  mat-raised-button color="primary" 
    aria-label="Close Upload">Next</button>
  
  
  </span>
</mat-step>

<mat-step [stepControl]="step5" *ngIf="this.folderFG.value.kanban" >
  <ng-template matStepLabel>Select Kanban</ng-template>

  Find Kanban

  <div >
    <mat-form-field appearance="fill" class ="indextemplates">
      <mat-label>Kanbans</mat-label>
     
      <input type="text"  
      matInput formControlName = "kanbanname"  
      [matAutocomplete]="auto"
      
      matInput (keyup)="kanbanFilter($event)" placeholder="Kanban" autocomplete="off" >
      
      <mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let kanban of this.configSvc.findkanbands" [value]="kanban.name"
  (onSelectionChange)="kanbanSelected(kanban)">
<span class="searchname">
  <i class="material-icons icon-size" [ngStyle]="{'color': kanban.iconcolor}">
    {{kanban.icontype}}</i>
  <b>{{kanban.name}}</b>:<i>{{kanban.desc}} </i></span>
  
  </mat-option>
      </mat-autocomplete>
  
     
     </mat-form-field>
  
     <span *ngIf="this.kanbanready" class = "flex-container"  >
  
      <div  class="kanban-container"
      
      *ngFor="let track of this.configSvc.activekanban" 
    >
    
      <mat-card><span class= "tracks">
        <h2  class="mat-h2">{{track.formitem}}</h2>
        <i class="material-icons md-24" [ngStyle]="{'color': 'green'}"
        matTooltip={{track.itemvalue}}  matTooltipPosition= 'after'
        >info</i></span>
       </mat-card>
       </div>
      
      </span>
      
      <button  *ngIf ="this.folderFG.value.assigned || this.folderFG.value.locktoolbar || this.folderFG.value.deadline || this.folderFG.value.addcomment"mat-button  matStepperNext matSuffix 
  mat-raised-button color="primary">got Folder & Kanban details Next</button> 
  
  
  <button  *ngIf ="!this.folderFG.value.assigned && !this.folderFG.value.locktoolbar && !this.folderFG.value.deadline && !this.folderFG.value.addcomment  "mat-button  matSuffix 
  mat-raised-button color="primary"(click) = "this.addFolder();  this.dialogRef.close(); this.configSvc.quickadd = false;">Add Folder & Apply Kanban and Close</button>
  

    
  </div>
</mat-step>

<mat-step [stepControl]="step6" *ngIf="this.folderFG.value.locktoolbar" >
  <ng-template matStepLabel>Select Toolbar</ng-template>

  Choose the Toolbar that you would like to appear when folder opened

  <div >
    <mat-form-field appearance="fill" class ="toolbartemplates">
      <mat-label>Toolbars</mat-label>
     
      <input type="text"  
      matInput formControlName = "toolbarname"  
      [matAutocomplete]="auto"
      
      matInput (keyup)="toolbarFilter($event)" placeholder="Toolbar" autocomplete="off" >
      
      <mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let toolbar of this.configSvc.toolbards" [value]="toolbar.name"
  (onSelectionChange)="toolbarSelected(toolbar)">
<span class="searchname">
  <i class="material-icons icon-size" [ngStyle]="{'color': toolbar.clr}">
    {{toolbar.icontype}}</i>
  <b>{{toolbar.name}}</b>:<i>{{toolbar.desc}} </i></span>
  
  </mat-option>
      </mat-autocomplete>
  
     
     </mat-form-field>
  
    
      
      <button  *ngIf ="this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment"mat-button  matStepperNext matSuffix 
  mat-raised-button color="primary">got Folder & Kanban & Toolbar details Next</button> 
  
  
  <button  *ngIf ="!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment  "mat-button  matSuffix 
  mat-raised-button color="primary"(click) = "this.addFolder();  this.dialogRef.close(); this.configSvc.quickadd = false;">Add Folder & Lock Toolbar and Close</button>
  

    
  </div>
</mat-step>

<mat-step [stepControl]="step7" *ngIf="this.folderFG.value.assigned" >
  <ng-template matStepLabel>Assign</ng-template>

  

    <div class = "space">
      <mat-form-field appearance="fill">
        <mat-label>Reassign</mat-label>
       
        <input type="text"  
        formControlName="username"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"
   (onSelectionChange)="userSelected(user)"
   >
    {{user.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      </div>
  
  
  
  


    <button  *ngIf="!this.folderFG.value.addcomment && !this.folderFG.value.deadline && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.addFolder();this.dialogRef.close(); this.configSvc.quickadd = false; this.configSvc.quickadd = false; ">AddFolder & Assign & Close </button>



    <button  *ngIf=" this.configSvc.userready && (this.folderFG.value.addcomment || this.folderFG.value.deadline )" mat-button matSuffix matStepperNext
    mat-raised-button color="primary">Assign & Next </button>
    

</mat-step>

<mat-step [stepControl]="step8" *ngIf="this.folderFG.value.deadline" >
  <ng-template matStepLabel>Deadline</ng-template>


    <span class = "labels">
      <mat-label>Folder Deadline</mat-label>
     </span> 
  <mat-form-field appearance="fill">
    <input matInput [matDatepicker]="picker1" placeholder="Folder Deadline" formControlName="deadlineval" >
         <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
         <mat-datepicker  #picker1></mat-datepicker>
       </mat-form-field>
       <button  *ngIf=" !this.folderFG.value.addcomment" mat-button matSuffix 
       mat-raised-button color="primary" (click)="this.addFolder();this.dialogRef.close(); this.configSvc.quickadd = false; ">Create Folder and Close </button>
       <button  *ngIf="this.folderFG.value.addcomment" mat-button matSuffix 
       mat-raised-button color="primary" matStepperNext>Next</button>


</mat-step>

<mat-step [stepControl]="step9" *ngIf="this.folderFG.value.addcomment" >
  <ng-template matStepLabel>Add Comment</ng-template>

  <span>
    <div>
      Comment on {{this.configSvc.activename}}
  
    </div>


    <mat-form-field appearance="outline"   class = "textarea">
      <textarea
      
      matInput formControlName="cmtval"></textarea>
     </mat-form-field>
  
  </span>
<div>
  <mat-button-toggle-group formControlName="cmticon"  aria-label="Comment Icon" (change)="onIconChange($event.value)">
    <mat-button-toggle value="cmt"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
    <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
    <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
    <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
    <mat-button-toggle value="question_mark"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >question_mark</i></mat-button-toggle>
  </mat-button-toggle-group></div>

<div >
<mat-checkbox formControlName="emoji" >Change Emoji
  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
  
  </mat-checkbox>

  

</div>

<div>
<mat-checkbox formControlName="atcmt" ><i class="material-icons icon-customtoolbar" [ngStyle]="{'color': darkblue}">alternate_email</i>User
  
  
  </mat-checkbox>
</div>
 

  <div *ngIf="this.folderFG.value.emoji">
    <span>
   <app-iconcategories></app-iconcategories></span>
   <span class ="colorbox">
    <app-color-picker (color)="this.configSvc.color"></app-color-picker>
  </span>
  </div>

  <div *ngIf="this.folderFG.value.atcmt" class = space>

      <mat-form-field appearance="fill">
        <mat-label>Reassign</mat-label>
       
        <input type="text"  
        formControlName="atcmtuser"
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
    {{option.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      
 
 
 
 
  </div>

  <button mat-button (click)="addFolder(); this.dialogRef.close(); this.configSvc.quickadd = false;" 
                      
  matSuffix 
  mat-raised-button color="primary"
  aria-label="Add Comment">Create Folder & Comment & Close</button>

    

</mat-step>

</form></mat-horizontal-stepper>
  </span>
</div>
</span>

<span *ngIf  = "data.type=='bulkadd'"><i class="material-icons icon-docaddpopup">add_box</i>
  <span mat-dialog-content>
   Bulk Upload folders to {{this.configSvc.activename}}
    
  </span>
  <div mat-dialog-actions>
   
<ngx-file-drop dropZoneLabel="Drop csv file here"
   (onFileDrop)="bulkprocesslocal($event, indextype)"
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
           >
          
          </ngx-file-drop>

          <mat-card>
            <mat-card-content>
              <h2 class="progress-h2">Automation Progess</h2>
              <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
            
            </mat-card-content>
          </mat-card>
          
<span *ngIf="this.configSvc.activetype == 'fold'">
          <mat-radio-group formControlName="indextype" class="indextypes"> 
            <span>
            <mat-radio-button value="1">Index with
              <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
              {{this.configSvc.folderdetailsds?.nodename}}
              </mat-radio-button></span>
          
      
           </mat-radio-group></span>

           <span *ngIf="this.configSvc.activetype == 'editteam' || this.configSvc.activetype == 'team' ">
            <mat-radio-group formControlName="indextype" class="indextypes"> 
            
              <span *ngIf="this.configSvc.teamdetailsds.tagged">
              <mat-radio-button value="2">Index with Teamtags
                <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
                {{this.configSvc.teamdetailsds?.nodename}}
              </mat-radio-button></span>
        
              <mat-radio-button value="0">No Team indexing</mat-radio-button>
             </mat-radio-group>
           </span>

          <span class= "genpopupbottom">
           <button mat-button (click)="onNoClick()" 
                     
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Close Upload">Finished Bulk </button></span>
      
  </div>
  </span>

  <span *ngIf = "data.type=='folderdetails'">
    <app-editfolder></app-editfolder>
  </span>

  <span *ngIf = "data.type=='report'">
   <form [formGroup]="folderFG">
    <div>
      <i class="material-icons icon-report">picture_as_pdf</i> 
     Generate report on {{this.configSvc.activename}} 
    </div>
    <div mat-dialog-actions>
      <span class= "report-container">
       
  

        <mat-label>Report Style</mat-label>
        <span ><mat-form-field class="family" >
          <mat-label>Family</mat-label>
           <mat-select  formControlName="family" required>
            <mat-option *ngFor="let style of family" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field>

         <span *ngIf="this.folderFG.value.family == 'Courier' || this.folderFG.value.family == 'Helvetica'" ><mat-form-field >
          <mat-label>FontStyle</mat-label>
           <mat-select  formControlName="style" required>
            <mat-option *ngFor="let style of familystyle" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field></span>

         <span *ngIf="this.folderFG.value.family == 'Times'" ><mat-form-field >
          <mat-label>FontStyle</mat-label>
           <mat-select  formControlName="style" required>
            <mat-option *ngFor="let style of timestyle" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field></span>
        </span>

        <span class="reportincludes">
          <div>
        <mat-checkbox class = "reportitem" formControlName="filereport" >File Report</mat-checkbox>
      </div>
       
     
  </span>

  
        <div class="reportbuttons"><button class = "reportitem"mat-button (click)="onNoClick()" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Cancel">Cancel</button>
  
        <button class = "reportitem" mat-button (click)="folderReport(filereport)" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Generate">Generate PDF</button>
      
      
      </div>
      </span>
      
    </div>
    </form></span>

    <span *ngIf = "data.type=='chatimage'" class = "cmt">
    
      <div mat-dialog-actions>
 
 
        <form [formGroup]="folderFG">
       <span>
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
      
          <mat-step [stepControl]="createImagestep1">
            <ng-template matStepLabel>
             
          
             ChatGPT Request</ng-template>
 
         <div  >
         Ask ChatGPT to create an image and store it in {{this.configSvc.activename}}
       
         </div>
     
     
         <div>
          <div class ="prompter"> <b>Prompt</b></div>
           <mat-form-field appearance="fill"  class ="textwidth">
           <textarea 
           cdkTextareaAutosize
           cdkAutosizeMinRows=6
           cdkAutosizeMaxRows=6
           
           matInput value={{this.folderFG.value.cmtval}}
           formControlName="cmtval"></textarea>
           </mat-form-field>
           </div>
<div>
          <mat-form-field appearance="outline" appearance="outline"  class = "textarea"  >
            <mat-label >Title</mat-label>
            <input  formControlName="title"  matInput placeholder={{this.folderFG.value.title}}>
            </mat-form-field></div>

          <div ><mat-form-field class="family" >
            <mat-label>Type</mat-label>
             <mat-select  formControlName="mt" required>
              <mat-option *ngFor="let type of mimetypes" [value]="type">
           {{type}} </mat-option> </mat-select> </mat-form-field></div>

           <div ><mat-form-field class="family" >
            <mat-label>Size</mat-label>
             <mat-select  formControlName="size" required>
              <mat-option *ngFor="let size of sizes" [value]="size">
           {{size}} </mat-option> </mat-select> </mat-form-field></div>

           <div>
            <button mat-button (click)="createImage(this.folderFG.value.cmtval, this.folderFG.value.title, this.folderFG.value.mt, this.folderFG.value.size)" 
                                
            matSuffix matStepperNext
            mat-raised-button color="primary"
            aria-label="Create Image">Create Image</button></div>

       </mat-step>

       <mat-step [stepControl]="createImagestep2">

        <ng-template matStepLabel>
         
      
         ChatGPT Response</ng-template>
    
         <span *ngIf="!this.configSvc.chatgptresponse">
          <mat-spinner></mat-spinner>
         Waiting on response from ChatGPT......
        </span>
    
        <span *ngIf="this.configSvc.chatgptresponse" class ="responses">
          
          <div >
           <app-fileimage></app-fileimage>
          </div>
    
        
       
    
         <div>
          <button [disabled] ="this.folderFG.value.saveprompt" mat-button matSuffix 
          mat-raised-button color="primary"(click) = "this.refreshFolder(); this.dialogRef.close();">Finish </button></div>
    
          <mat-checkbox formControlName="saveprompt" >Save Prompt</mat-checkbox>
    
          <div>
            <button [disabled] ="!this.folderFG.value.saveprompt" mat-button matSuffix matStepperNext
            mat-raised-button color="primary">Next </button></div>
    
          </span>
    </mat-step>
  </mat-horizontal-stepper>
  
</span>
</form>

     
 
 
      </div>
      </span>

      <span *ngIf = "data.type=='askchatgpt'" class = "cmt">
    
        <div mat-dialog-actions>
          <mat-horizontal-stepper [linear]="isLinear" #stepper>
            <form [formGroup]="folderFG">
            <mat-step [stepControl]="stepa">
              <ng-template matStepLabel>
               
            
               ChatGPT Request</ng-template>
   
          <form [formGroup]="folderFG">

            
            <span class= "itemcontainer">

              <div>
                <div class ="prompter"> <b>Prompt</b></div>
                 <mat-form-field appearance="fill"  class ="textwidth">
                 <textarea 
                 cdkTextareaAutosize
                 cdkAutosizeMinRows=6
                 cdkAutosizeMaxRows=6
                 
                 matInput value={{this.folderFG.value.prompt}}
                 formControlName="prompt"></textarea>
                 </mat-form-field>
                 </div>

                 <span class = "labels"><mat-label >Focussed</mat-label></span>
                 <mat-slider 
                 formControlName="temp"
[min]="0" 
[max]="2" 
[step]="0.1" 
thumbLabel 
color="accent"
(change)="onTemperatureChange()">
Temperature: {{this.folderFG.value.temp}}
</mat-slider>
<mat-label >Random</mat-label>
  
<span class ="responses">
<mat-label >Responses</mat-label>
<mat-form-field appearance="outline" class = "small-item">  

<input 
formControlName = responses
matInput type="number"
matInput value="1">
</mat-form-field></span>
             <div>
                 <mat-checkbox formControlName="promptopts" >Prompt Options</mat-checkbox></div>
                 <mat-card *ngIf="this.folderFG.value.promptopts">

              <mat-accordion>
                <!-- #docregion basic-panel -->
                <!-- #docregion hide-toggle -->
                  <mat-expansion-panel (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false">
                <!-- #enddocregion hide-toggle -->
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                      Prompt Lists
                      </mat-panel-title>
                      <mat-panel-description>
                      Click panel to choose from a set of prompt lists
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
                    <mat-form-field>
              
                      <mat-select (selectionChange)="getPrompt()"formControlName="promptlist">
                  <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
                    {{prompt.name}}
                  </mat-option>
                </mat-select>
               
              </mat-form-field>

              <span *ngIf="this.gotprompt">
                <span class = "labels"><mat-label >Choose Prompt</mat-label></span>
              <mat-form-field>
              
                <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedPrompt" formControlName="promptbuild">
              <mat-option *ngFor="let chosenprompt of getChoices(this.folderFG.value.promptlist.path[0].pfoldid)" [value]="chosenprompt.desc">
              {{chosenprompt.name}}
              </mat-option>
              </mat-select>
              
              </mat-form-field>
              </span>

              <div>
                <mat-form-field appearance="outline"  class ="textwidth">
                <textarea
                matInput value={{this.folderFG.value.promptbuild}}
                formControlName="promptbuild"></textarea>
                </mat-form-field>
                </div>

                <div>
                  <button [disabled]= "!addactive" mat-button matSuffix  
                  mat-raised-button color="primary" (click)="addPrompt(this.folderFG.value.promptbuild);  " >Add </button></div>

                  </mat-expansion-panel>
               
                  <mat-expansion-panel (opened)="panelOpenState = true; getFoldercontext();"
                  (closed)="panelOpenState = false">
                    <!-- #enddocregion hide-toggle -->
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                           Folder context
                          </mat-panel-title>
                          <mat-panel-description>
                            Click panel to choose from parent folder context
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                  
                        <span class = "labels"><mat-label >Folder Context</mat-label></span>
                        <mat-form-field>
                  
                          <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedcontext" formControlName="contextlist">
                      <mat-option *ngFor="let context of this.foldercontext" [value]="context">
                        {{context}}
                      </mat-option>
                    </mat-select>
                   
                  </mat-form-field>
    
                  <div>
                    <button [disabled]= "!addactive" mat-button matSuffix  
                    mat-raised-button color="primary" (click)="addContext(this.folderFG.value.contextlist);  " >Add </button>
                  
                    <button [disabled]= "!addallactive" mat-button matSuffix  
                    mat-raised-button color="primary" (click)="addallContext();  " >Add All</button>
                  
                  </div>
    
                  </mat-expansion-panel>

      
               
                </mat-accordion>
         </mat-card>
          </span>

     

          <div class = "askchat">
            <button mat-button (click)="askChatgpt(this.folderFG.value.prompt); " 
                                
            matSuffix matStepperNext
            mat-raised-button color="primary"
            aria-label="Ask ChatGPT">Ask ChatGPT</button></div>
          
           
  </form>
  </mat-step>

  <mat-step [stepControl]="stepb">

    <ng-template matStepLabel>
     
  
     ChatGPT Response</ng-template>

     <span *ngIf="!this.configSvc.chatgptresponse">
      <mat-spinner></mat-spinner>
     Waiting on response from ChatGPT......
    </span>

    <span *ngIf="this.configSvc.chatgptresponse" class ="responses">
      
      <div >
       <app-viewcomments></app-viewcomments>
      </div>

      <div>
        Rate response
      
        <div class="star-container">
          <button mat-icon-button *ngFor="let star of stars" (click)="rate(star)">
            <mat-icon class="yellow-icon">{{ starIcon(star) }}</mat-icon>
          </button>
      </div>
      </div>
   

     <div>
      <button [disabled] ="this.folderFG.value.saveprompt" mat-button matSuffix 
      mat-raised-button color="primary"(click) = "this.refreshFolder(); this.dialogRef.close();">Finish </button></div>

      <mat-checkbox formControlName="saveprompt" >Save Prompt</mat-checkbox>

      <div>
        <button [disabled] ="!this.folderFG.value.saveprompt" mat-button matSuffix matStepperNext
        mat-raised-button color="primary">Next </button></div>

      </span>
</mat-step>

  <mat-step [stepControl]="stepc" *ngIf="this.folderFG.value.saveprompt">
   
    <ng-template matStepLabel>
     
  
    Save Prompt</ng-template>

<div>
  Prompt provided to ChatGPT
</div>
    <div>

</div>

<div>
  <mat-form-field appearance="fill" class= "large-item">
  
    <input formControlName ="prompt" matInput [value]=this.folderFG.value.promptsent>
     
    </mat-form-field>
  </div>

  
    <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
    <mat-form-field>

      <mat-select (selectionChange)="getPrompt()" formControlName="promptlist">
  <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
    {{prompt.name}}
  </mat-option>
</mat-select>
</mat-form-field>
<div>
<mat-form-field appearance="fill" class= "large-item">
  <mat-label>Prompt Name</mat-label>
  <input formControlName ="promptname"  matInput placeholder="Enter name" >
   
  </mat-form-field>
</div>



<div>
<button mat-button matSuffix 
      mat-raised-button color="primary"(click) = "this.savePrompt(); this.dialogRef.close();">Save Prompt & Finish </button></div>



</mat-step>
</form>

  </mat-horizontal-stepper>
       

   
        </div>
        </span>

        <span *ngIf = "data.type=='asktoolbard'" class = "cmt">
    
          <div mat-dialog-actions>
            <mat-horizontal-stepper [linear]="isLinear" #stepper>
              <form [formGroup]="folderFG">
              <mat-step [stepControl]="stepa">
                <ng-template matStepLabel>
                 
              
               Ask Toolbard</ng-template>
     
            <form [formGroup]="folderFG">
  
              
              <span class= "itemcontainer">
  
                <div>
                  <div class ="prompter"> <b>Prompt</b></div>
                   <mat-form-field appearance="fill"  class ="textwidth">
                   <textarea 
                   cdkTextareaAutosize
                   cdkAutosizeMinRows=6
                   cdkAutosizeMaxRows=6
                   
                   matInput value={{this.folderFG.value.prompt}}
                   formControlName="prompt"></textarea>
                   </mat-form-field>
                   </div>
  
                   <span class = "labels"><mat-label >Focussed</mat-label></span>
                   <mat-slider 
                   formControlName="temp"
  [min]="0" 
  [max]="2" 
  [step]="0.1" 
  thumbLabel 
  color="accent"
  (change)="onTemperatureChange()">
  Temperature: {{this.folderFG.value.temp}}
  </mat-slider>
  <mat-label >Random</mat-label>
    
  <span class ="responses">
  <mat-label >Responses</mat-label>
  <mat-form-field appearance="outline" class = "small-item">  
  
  <input 
  formControlName = responses
  matInput type="number"
  matInput value="1">
  </mat-form-field></span>
               <div>
                   <mat-checkbox formControlName="promptopts" >Prompt Options</mat-checkbox></div>
                   <mat-card *ngIf="this.folderFG.value.promptopts">
  
                <mat-accordion>
                  <!-- #docregion basic-panel -->
                  <!-- #docregion hide-toggle -->
                    <mat-expansion-panel (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                  <!-- #enddocregion hide-toggle -->
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                        Prompt Lists
                        </mat-panel-title>
                        <mat-panel-description>
                        Click panel to choose from a set of prompt lists
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
                      <mat-form-field>
                
                        <mat-select (selectionChange)="getPrompt()"formControlName="promptlist">
                    <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
                      {{prompt.name}}
                    </mat-option>
                  </mat-select>
                 
                </mat-form-field>
  
                <span *ngIf="this.gotprompt">
                  <span class = "labels"><mat-label >Choose Prompt</mat-label></span>
                <mat-form-field>
                
                  <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedPrompt" formControlName="promptbuild">
                <mat-option *ngFor="let chosenprompt of getChoices(this.folderFG.value.promptlist.path[0].pfoldid)" [value]="chosenprompt.desc">
                {{chosenprompt.name}}
                </mat-option>
                </mat-select>
                
                </mat-form-field>
                </span>
  
                <div>
                  <mat-form-field appearance="outline"  class ="textwidth">
                  <textarea
                  matInput value={{this.folderFG.value.promptbuild}}
                  formControlName="promptbuild"></textarea>
                  </mat-form-field>
                  </div>
  
                  <div>
                    <button [disabled]= "!addactive" mat-button matSuffix  
                    mat-raised-button color="primary" (click)="addPrompt(this.folderFG.value.promptbuild);  " >Add </button></div>
  
                    </mat-expansion-panel>
                 
                    <mat-expansion-panel (opened)="panelOpenState = true; getFoldercontext();"
                    (closed)="panelOpenState = false">
                      <!-- #enddocregion hide-toggle -->
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                             Folder context
                            </mat-panel-title>
                            <mat-panel-description>
                              Click panel to choose from parent folder context
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                    
                          <span class = "labels"><mat-label >Folder Context</mat-label></span>
                          <mat-form-field>
                    
                            <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedcontext" formControlName="contextlist">
                        <mat-option *ngFor="let context of this.foldercontext" [value]="context">
                          {{context}}
                        </mat-option>
                      </mat-select>
                     
                    </mat-form-field>
      
                    <div>
                      <button [disabled]= "!addactive" mat-button matSuffix  
                      mat-raised-button color="primary" (click)="addContext(this.folderFG.value.contextlist);  " >Add </button>
                    
                      <button [disabled]= "!addallactive" mat-button matSuffix  
                      mat-raised-button color="primary" (click)="addallContext();  " >Add All</button>
                    
                    </div>
      
                    </mat-expansion-panel>
  
        
                 
                  </mat-accordion>
           </mat-card>
            </span>
  
       
  
            <div class = "askchat">
              <button mat-button (click)="askToolbard(this.folderFG.value.prompt); " 
                                  
              matSuffix matStepperNext
              mat-raised-button color="primary"
              aria-label="Ask ChatGPT">Ask Toolbard</button></div>
            
             
    </form>
    </mat-step>
  
    <mat-step [stepControl]="stepb">
  
      <ng-template matStepLabel>
       
    
       Toolbard Response</ng-template>
  
       <span *ngIf="!this.configSvc.chatgptresponse">
        <mat-spinner></mat-spinner>
       Waiting on response from Toolbard......
      </span>
  
      <span *ngIf="this.configSvc.chatgptresponse" class ="responses">
        
        <div >
         <app-viewcomments></app-viewcomments>
        </div>
  
        <div>
          Rate response
        
          <div class="star-container">
            <button mat-icon-button *ngFor="let star of stars" (click)="rate(star)">
              <mat-icon class="yellow-icon">{{ starIcon(star) }}</mat-icon>
            </button>
        </div>
        </div>
     
  
       <div>
        <button [disabled] ="this.folderFG.value.saveprompt" mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.refreshFolder(); this.dialogRef.close();">Finish </button></div>
  
        <mat-checkbox formControlName="saveprompt" >Save Prompt</mat-checkbox>
  
        <div>
          <button [disabled] ="!this.folderFG.value.saveprompt" mat-button matSuffix matStepperNext
          mat-raised-button color="primary">Next </button></div>
  
        </span>
  </mat-step>
  
    <mat-step [stepControl]="stepc" *ngIf="this.folderFG.value.saveprompt">
     
      <ng-template matStepLabel>
       
    
      Save Prompt</ng-template>
  
  <div>
    Prompt provided to ChatGPT
  </div>
      <div>
  
  </div>
  
  <div>
    <mat-form-field appearance="fill" class= "large-item">
    
      <input formControlName ="prompt" matInput [value]=this.folderFG.value.promptsent>
       
      </mat-form-field>
    </div>
  
    
      <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
      <mat-form-field>
  
        <mat-select (selectionChange)="getPrompt()" formControlName="promptlist">
    <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
      {{prompt.name}}
    </mat-option>
  </mat-select>
  </mat-form-field>
  <div>
  <mat-form-field appearance="fill" class= "large-item">
    <mat-label>Prompt Name</mat-label>
    <input formControlName ="promptname"  matInput placeholder="Enter name" >
     
    </mat-form-field>
  </div>
  
  
  
  <div>
  <button mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.savePrompt(); this.dialogRef.close();">Save Prompt & Finish </button></div>
  
  
  
  </mat-step>
  </form>
  
    </mat-horizontal-stepper>
         
  
     
          </div>
          </span>

 
 <span *ngIf  = "data.type=='addfiles' || data.type=='addtemplate' || data.type=='addfile' || data.type=='node'  || data.type=='batchitems' || data.type=='nodetab' || data.type == 'rootfolder' || data.type=='basicflow'"><i class="material-icons icon-docaddpopup">offline_bolt</i>
   <span *ngIf  = "data.type=='addfiles' || data.type=='addtemplate'  || data.type=='addfile' || data.type=='node'  || data.type=='batchitems'  || data.type=='nodetab' " mat-dialog-content>
   Add to folder {{this.configSvc.activename}} 
  
   <span *ngIf="this.configSvc.folderdetailsds.kanbantagged== true && this.configSvc.folderdetailsds.kantype == 'moneydollars'">
    <i class="material-icons status-kanban"   [ngStyle]="{'color': this.configSvc.folderdetailsds.kanbancolor}" >monetization_on </i>{{this.configSvc.folderdetailsds.kanbanname}} applied
    </span> 
  </span>

  <span *ngIf  = "data.type=='basicflow' " mat-dialog-content>
   Launch {{data.name}} 
   
 
   </span>

   <span *ngIf  = "data.type == 'rootfolder'" mat-dialog-content>
    Add to team {{data.name}}
    </span>


   <div mat-dialog-actions>
    
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
  
      <form [formGroup]="folderFG">

        <mat-step [stepControl]="step1" *ngIf="this.folderFG.value.indextemplate">   <ng-template matStepLabel>Template</ng-template>



          <span *ngIf="this.configSvc.toolmenu != 'toolbartools' && this.configSvc.toolmenu != 'favtoolset'  ">
            
        <mat-label> Find Template</mat-label>
          <mat-form-field appearance="fill"  class = "indextemplates">
            <mat-label>Template</mat-label>
           
            <input type="text"  
            formControlName="indexname"   
            [matAutocomplete]="auto"
            
            matInput (keyup)="templateFilter($event)" placeholder="Index" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let item of this.configSvc.finditemds" [value]="item.name"  
        (onSelectionChange)="selectTemplate(item)">
        <i class="material-icons icon-size" [ngStyle]="{'color': item.iconcolor}">
          {{item.icontype}}</i>
        {{item.name}}:<i>{{item.desc}} </i>
        
        </mat-option>
            </mat-autocomplete>
        
           
           </mat-form-field>
        
           <div>
          <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
            {{this.configSvc.nodeds[0]?.icontype}}</i>
          {{this.configSvc.nodeds[0]?.name}}:  {{this.configSvc.nodeds[0]?.desc}}
        </mat-label></div>
        </span>
           <span *ngIf="this.indexready && !this.folderFG.value.bulkfiles">
            <span  *ngFor="let r of reset">
        
              <span *ngIf="this.folderFG.value.type == 'node' || this.folderFG.value.type == 'basicflow' || this.folderFG.value.type == 'smartfolder'">
                <span *ngIf="!this.folderFG.value.textract && !this.folderFG.value.bulkfiles">
                <app-activenode></app-activenode>
              </span>


              <div *ngIf="this.folderFG.value.textract">
     
              <div class="expand">
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      AI will automatically fill the fields of this
                      <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
                       {{this.configSvc.nodeds[0]?.icontype}}</i>
                     {{this.configSvc.nodeds[0]?.name}} template for you.
                    </mat-panel-title>
                    <mat-panel-description>
                     Click to preview template
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <app-activenode></app-activenode>
                </mat-expansion-panel>
              </div>
              </div>

           
            <mat-card *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
            
              <mat-label>Dollars</mat-label>
              <mat-form-field appearance="fill">  
                                  
                <input formControlName="total" type = "number" matInput value="0">
        </mat-form-field>
        </mat-card>
            </span>
        
            <span *ngIf="this.folderFG.value.type == 'batchitems'">
              <span class = "smart-table">
                <table mat-table [dataSource]="this.configSvc.nodeds[0].subfolders " matSort >
                    <tr mat-header-row *matHeaderRowDef="activeColumns; sticky: true" ></tr>
            
                    <tr mat-row *matRowDef="let element; columns: activeColumns; let itemindex = index;"
                    
                  (click)="this.selectSubitem(element, itemindex)"
                  [style.background]=" this.hlsubitem.indexOf(element) == 0 ? 'lightblue' : ''"
                    ></tr>
            
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>
                 
                        <td mat-cell *matCellDef="let element">
                            
                       
            
                                <div>
                                    <i class="material-icons md-24" [ngStyle]="{'color': element.iconcolor}">{{element.icontype}}</i>
                                    
                                    </div>
                            </td>
            
                        </ng-container>
            
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>
                     
                            <td mat-cell *matCellDef="let element">
                                
                                <div>
                                    {{element.name}}
                                    
                                    </div>
                                </td>
                
            
                            
                            </ng-container>
                </table>
            </span></span>
        
            <span *ngIf="this.folderFG.value.type == 'nodetab' ">
          
              <app-activestepper></app-activestepper>
            
              <mat-card *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
            
                <mat-label>Dollars</mat-label>
                <mat-form-field appearance="fill">  
                                    
                  <input formControlName="total" type = "number" matInput value="0">
          </mat-form-field>
          </mat-card>
        </span>
        
              
          
          </span>
        
        
            
        
        <span *ngIf ="this.folderFG.value.type == 'node'">
        
         
        
     
        
        
     
        
       <span *ngIf="!this.folderFG.value.advanced">

        <button  *ngIf ="!this.folderFG.value.ischatgpt && !this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline 
        && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker  "mat-button  matSuffix 
        mat-raised-button color="primary"(click) = "this.saveNode('node');  this.dialogRef.close(); this.configSvc.quickadd = false;">
        Save {{this.configSvc.nodeds[0]?.name}} and Close</button>
        
        <button  *ngIf =" this.folderFG.value.ischatgpt && !this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline 
        && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker  "mat-button  matSuffix 
        mat-raised-button color="primary"   matStepperNext>
        Next</button>
        
          <button  *ngIf ="this.folderFG.value.type == 'batchitems'"mat-button  matSuffix 
          mat-raised-button color="primary"(click) = "this.batchItems(this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0]._id );  this.dialogRef.close(); this.configSvc.quickadd = false; this.configSvc.quickadd = false;;">
          Bulk Add</button>
        
        
        
        <span *ngIf ="this.folderFG.value.type == 'nodetab' ">
          <button  *ngIf ="!this.folderFG.value.ischatgpt && !this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker  "mat-button  matSuffix 
          mat-raised-button color="primary"(click) = "this.saveStepper();  this.dialogRef.close(); this.configSvc.quickadd = false; this.configSvc.quickadd = false;;">Finish Tabbed Template</button>
        
          <button  *ngIf ="this.folderFG.value.ischatgpt && !this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker  "mat-button  matSuffix 
          mat-raised-button matStepperNext color="primary">Next</button>
        
        
        </span>
        
        <span *ngIf ="this.folderFG.value.flow">
          <button  *ngIf ="!this.folderFG.value.attach && !this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker "mat-button  matSuffix 
          mat-raised-button color="primary"(click) = "this.launchFlow();  this.dialogRef.close(); this.configSvc.quickadd = false;">Launch</button></span>
        
        
        <button  *ngIf ="this.folderFG.value.attach || this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.statetracker "mat-button  matStepperNext matSuffix 
        mat-raised-button color="primary">Next</button> 
           </span>

           <span *ngIf="this.folderFG.value.advanced">
            
        <button  mat-button  matStepperNext matSuffix 
        mat-raised-button color="primary">Advanced Options</button> 
           </span>

           <div><mat-checkbox formControlName="advanced" >Advanced Options</mat-checkbox>
         
          </div>
 <span  *ngIf="!this.folderFG.value.textract">
            <mat-checkbox formControlName="bulkfiles" >Create Multiple templates with CSV</mat-checkbox></span>  
          </span>

          </span>
          <div *ngIf="this.folderFG.value.bulkfiles">
            AI will automatically fill the fields via a csv file
            <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
             {{this.configSvc.nodeds[0]?.icontype}}</i>
           {{this.configSvc.nodeds[0]?.name}} for you.

           <ngx-file-drop dropZoneLabel="Drop csv file here"
           (onFileDrop)="bulkFiledropped($event)"
                   
                   >
                  
                  </ngx-file-drop>
        
                  <mat-card>
                    <mat-card-content>
                      <h2 class="progress-h2">Automation Progess</h2>
                      <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
                    
                    </mat-card-content>
                  </mat-card>
                  <span  *ngIf="!this.folderFG.value.textract">
                    <mat-checkbox formControlName="bulkfiles" >Create Multiple templates with CSV</mat-checkbox></span>  </div>
           
        
        
        </mat-step>

        


<mat-step [stepControl]="step1" *ngIf="this.folderFG.value.bulkfiles1 ">   <ng-template matStepLabel>Bulk</ng-template>
  Bulk Automation 
  
      
    <ngx-file-drop dropZoneLabel="Drop csv file here"
     (onFileDrop)="bulkFiledropped($event)"
             
             >
            
            </ngx-file-drop>
  
            <mat-card>
              <mat-card-content>
                <h2 class="progress-h2">Automation Progess</h2>
                <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
              
              </mat-card-content>
            </mat-card>
  
            <div><mat-checkbox formControlName="advanced" >Advanced Options</mat-checkbox>
              <mat-checkbox formControlName="bulkfiles" >Bulk with CSV</mat-checkbox>
            </div>

  

</mat-step>



<mat-step [stepControl]="step3" *ngIf="this.folderFG.value.indextype =='1' && this.folderFG.value.tagreview" >  <ng-template matStepLabel>{{this.configSvc.folderdetailsds?.nodename}}</ng-template>
<app-foldertags  [indexlaunch]="'toolbar'" ></app-foldertags>

<button mat-button matStepperNext matSuffix 
mat-raised-button color="primary">Next </button>
</mat-step>

<mat-step [stepControl]="step3" *ngIf="this.folderFG.value.indextype =='2'" >  <ng-template matStepLabel>Complete Team Indexing</ng-template>

<app-teamtags></app-teamtags>

<button mat-button matStepperNext matSuffix 
mat-raised-button color="primary">Next </button>
</mat-step>

<mat-step [stepControl]="step4" *ngIf="!this.folderFG.value.template && this.folderFG.value.attach && !this.folderFG.value.bulkfiles  && this.folderFG.value.type != 'basicflowtest'">
  <ng-template matStepLabel>Add File</ng-template>
  <span *ngIf="!this.folderFG.value.bulkfiles">
   <div *ngIf="!this.folderFG.value.textract">
              <input type='file' (change)="prepareFile($event)"></div>
              <div *ngIf="this.folderFG.value.textract">
                <input type='file' accept=".pdf,.jpeg,.jpg" (change)="prepareFile($event)"></div>
           <span *ngIf="!this.folderFG.value.textract && this.folderFG.value.mt != 'jpeg' && this.folderFG.value.mt != 'jpg'">
           
              <ngx-file-drop dropZoneLabel="Drop Email from Outlook"
              (onFileDrop)="prepareMsg($event)"
                      
                      >
                     
                     </ngx-file-drop></span>

                    

              <div *ngIf="imageready">
              
                <img [src]="url" height="200"> <br/>

             
              </div>
     
              <div *ngIf="fileready">
              
                <span *ngIf="this.folderFG.value.mt == 'docx'" class="fa-layers fa-fw"><i class="fas fa-file-word" style="color:blue"></i> </span>
                <span *ngIf="this.folderFG.value.mt == 'xlsx'" class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i> </span>
                
                {{this.filename}}
              </div>

              <div *ngIf="pdfready">
                <span *ngIf="this.folderFG.value.mt== 'pdf'" class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i> </span>
               
                {{this.filename}}
                
              </div>

                 <span *ngIf="msgready" class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i> </span>
                 {{this.filename}}


                 <span *ngIf="!this.folderFG.value.advanced" >

              <div *ngIf="this.fileready || this.imageready || this.pdfready || this.msgready">
                <div *ngIf="this.folderFG.value.indextemplate || this.folderFG.value.attach"> 
                  <div *ngIf="this.fileready || this.imageready || this.pdfready">
                    <mat-card *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
    
                      <mat-label>Dollars</mat-label>
                      <mat-form-field appearance="fill">  
                                          
                        <input formControlName="total" type = "number" matInput value="0">
                </mat-form-field>
                </mat-card>
          

                  <div *ngIf="this.folderFG.value.ischatgpt && this.folderFG.value.mt !='pdf'">
             This is not a PDF document, unable to converse with ChatGPT
                  </div>
                
                
                </div></div> 

             

                  <span  *ngIf="this.imageready">
                <button *ngIf="!this.folderFG.value.indextemplate && !this.folderFG.value.deadline && !this.folderFG.value.assigned && !this.folderFG.value.addcomment " mat-raised-button color="primary" 
                (click)="filedropped('file'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Save Image </button>
              
               
              
              </span>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.textract && this.imageready && this.folderFG.value.type != 'basicflow'" mat-raised-button color="primary" matStepperNext
                matStepperNext (click)="saveNode('node');" class = "buttonstyle">Next & Eventually do Image Textract</button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.textract && this.imageready && this.folderFG.value.type == 'basicflow'" mat-raised-button color="primary" matStepperNext
                matStepperNext (click)="launchFlow();" class = "buttonstyle">Launch Basic Flow and Textract</button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.textract && this.pdfready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" matStepperNext
                matStepperNext  (click)="saveNode('node');" class = "buttonstyle">Do PDF Textract</button>
               
                <button *ngIf="this.folderFG.value.indextemplate && !this.folderFG.value.attach && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker)" mat-raised-button color="primary" matStepperNext
                (click)="saveNode('node'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Save IndexTemplate Only</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.fileready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker)" mat-raised-button color="primary" 
                (click)="filedropped('file'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Save File Only</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.fileready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.statetracker) " mat-raised-button color="primary" 
              matStepperNext  class = "buttonstyle">Next</button>

              <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.fileready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.statetracker)" mat-raised-button color="primary" 
              matStepperNext  class = "buttonstyle">Next</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.pdfready && this.folderFG.value.ischatgpt && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.settotal) " mat-raised-button color="primary" 
               matStepperNext   class = "buttonstyle">Next</button>

               <button *ngIf="!this.folderFG.value.indextemplate && this.msgready && this.folderFG.value.ischatgpt && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.settotal) " mat-raised-button color="primary" 
               matStepperNext   class = "buttonstyle">Next</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.pdfready && !this.folderFG.value.ischatgpt && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment && !this.folderFG.value.settotal) " mat-raised-button color="primary" 
                (click)="filedropped('file'); this.dialogRef.close();  this.configSvc.quickadd = false; " class = "buttonstyle">Save PDF Only No Chatgpt</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.msgready && !this.folderFG.value.ischatgpt && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment) " mat-raised-button color="primary" 
                (click)="filedropped('msg'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Outlook Email No Chatgpt</button>

                

                <button *ngIf="this.folderFG.value.attach && this.pdfready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.settotal) " mat-raised-button color="primary" 
                matStepperNext class = "buttonstyle">Next</button>

                <button *ngIf="!this.folderFG.value.indextemplate && this.msgready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.settotal) " mat-raised-button color="primary" 
                matStepperNext class = "buttonstyle">Next</button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.pdfready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.settotal ) " mat-raised-button color="primary" 
                matStepperNext class = "buttonstyle">Attach PDF to Index Template & Next </button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.msgready && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment || this.folderFG.value.settotal) " mat-raised-button color="primary" 
                matStepperNext class = "buttonstyle">Attach Outlook email to Index Template & Next </button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.type == 'node' && this.folderFG.value.attach && this.fileready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="saveNode('node'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach File to Node & Close</button>

               
                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.type == 'nodetab'  && this.folderFG.value.attach && this.fileready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="saveStepper(); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach File to Tabbed Template & Close</button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.pdfready && !this.folderFG.value.textract && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="saveNode('node'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach PDF to Index Template & Close</button>

                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.msgready && !this.folderFG.value.textract && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="saveNode('node'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach Outlook Email to Index Template & Close</button>

                <button *ngIf="!this.folderFG.value.flow && this.folderFG.value.indextemplate && this.folderFG.value.attach && !this.folderFG.value.textract && this.imageready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="saveNode('node'); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach Image to Index Template & Close</button>
              
                <button *ngIf="this.folderFG.value.flow && this.folderFG.value.indextemplate && this.folderFG.value.attach && !this.folderFG.value.textract && this.imageready && (!this.folderFG.value.assigned && !this.folderFG.value.deadline && !this.folderFG.value.addcomment)" mat-raised-button color="primary" 
                (click)="launchFlow(); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach Image to Flow Template & Close</button>
              
                <button *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.attach && this.imageready && !this.folderFG.value.textract && (this.folderFG.value.assigned || this.folderFG.value.deadline || this.folderFG.value.addcomment)" mat-raised-button color="primary" 
              matStepperNext class = "buttonstyle">Next</button>
            </div>
            </span>

          </span>

          
            <div *ngIf="this.folderFG.value.advanced" >
              <button mat-raised-button color="primary" 
              matStepperNext  class = "buttonstyle">Next</button>
            </div>
            
           
            <div *ngIf="this.fileready || this.imageready || this.pdfready || this.msgready" ><mat-checkbox formControlName="advanced" >Advanced File Options</mat-checkbox>
            
            </div> </mat-step>


<mat-step [stepControl]="step2" *ngIf="this.folderFG.value.advanced">
  <ng-template matStepLabel>Options</ng-template>

  <span *ngIf="!this.folderFG.value.fileinfolder">
    Adding without filing in folder
  </span>

<mat-radio-group formControlName="indextype" class="indextypes"> 
<span *ngIf="this.configSvc.folderdetailsds?.tagged">
<mat-radio-button value="1">Index with
<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
{{this.configSvc.folderdetailsds?.nodename}}
</mat-radio-button>
<mat-checkbox formControlName="tagreview" > Review Tags</mat-checkbox>

</span>
<span *ngIf="this.configSvc.teamdetailsds?.tagged">
<mat-radio-button value="2">Index with Teamtags
<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
{{this.configSvc.teamdetailsds?.nodename}}
</mat-radio-button></span>

</mat-radio-group>







<div *ngIf ="this.folderFG.value.template">
<div *ngIf ="this.folderFG.value.mt == 'pdf' ||  this.folderFG.value.mt == 'jpeg' || this.folderFG.value.mt == 'jpg'">
<mat-checkbox formControlName="ischatgpt" (change)="onaskingChatgpt()">Ask OpenAI ChatGPT
  <mat-icon class="custom2"  title="ChatGPT" svgIcon="chat2"

  ></mat-icon>
  </mat-checkbox>
  <span *ngIf ="this.folderFG.value.ischatgpt">
  <mat-radio-group formControlName="textractype" class="indextypes"> 
  <mat-radio-button value="invoice">Invoice

    </mat-radio-button>
    <mat-radio-button value="form">Form

    </mat-radio-button>
    </mat-radio-group></span>
  </div>
</div>
<div *ngIf ="this.folderFG.value.mt == 'pdf' || this.folderFG.value.mt == 'jpeg' ||  this.folderFG.value.mt == 'jpg' || this.folderFG.value.mt == 'msg'">  <mat-checkbox  formControlName="extract">Extract Text for AI</mat-checkbox> 
<span *ngIf ="this.folderFG.value.extract " class = "aicheck"><mat-checkbox  formControlName="askchatgpt">Ask ChatGPT about this extracted text</mat-checkbox> </span>
</div>
<div *ngIf ="this.folderFG.value.flow == false && (this.folderFG.value.attach || this.folderFG.value.indextemplate || this.folderFG.value.ischatgpt)" >  
<mat-checkbox  formControlName="assigned">Assign to User</mat-checkbox> 
</div>


<div>  <mat-checkbox  formControlName="deadline">Set Deadline</mat-checkbox>  </div> 
<div>  <mat-checkbox  formControlName="addcomment">Add Comment</mat-checkbox>  </div>
<div>  <mat-checkbox  formControlName="statetracker">Track State</mat-checkbox>  </div>







<button *ngIf= "this.folderFG.value.askchatgpt || this.folderFG.value.deadline || this.folderFG.value.assigned  || this.folderFG.value.addcomment || this.folderFG.value.statetracker"  mat-button matStepperNext matSuffix 
mat-raised-button color="primary" (click)="getPromptlist()">Next</button>

<button *ngIf= "!this.folderFG.value.askchatgpt && !this.folderFG.value.deadline && !this.folderFG.value.assigned && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker"  mat-button matStepperNext matSuffix 
mat-raised-button color="primary" (click)="this.filedropped('file');this.dialogRef.close(); this.configSvc.quickadd = false; ">Save File</button>


</mat-step>




        

  <mat-step [stepControl]="step4" *ngIf="this.folderFG.value.attach && !this.folderFG.value.bulkfiles && this.folderFG.value.type == 'basicflowtest'">
    <ng-template matStepLabel>Link Files</ng-template>
    <span> 
      <a fxFlexFill
             
      [routerLink]="['/toolbard/home']"
      [queryParams] = "{itemid:this.configSvc.activeitemid, type:this.configSvc.activetype, name: this.configSvc.activename, level: this.configSvc.activelevel, teamid: this.configSvc.activeteamid}"
     
      >{{this.configSvc.activename}}</a></span>

    <button mat-raised-button color="primary" 
    (click)="launchFlow(); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Launch & Close</button>
  </mat-step>
         
 



 <mat-step [stepControl]="step5" *ngIf ="this.folderFG.value.assigned ">
  <ng-template matStepLabel>
   

    Assign</ng-template>

   
   
    <div *ngIf = "this.folderFG.value.assigned">

    <div class = "space">
      <mat-form-field appearance="fill">
        <mat-label>Reassign</mat-label>
       
        <input type="text"  
        formControlName="username"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"
   (onSelectionChange)="userSelected(user)"
   >
    {{user.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      </div>
  
  
  
  </div>


    <button  *ngIf="!this.folderFG.value.indextemplate && !this.folderFG.value.bulkfiles && this.folderFG.value.attach && !this.folderFG.value.addcomment && !this.folderFG.value.ischatgpt && !this.folderFG.value.deadline && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.filedropped('file');this.dialogRef.close(); this.configSvc.quickadd = false; ">Save File & Assign</button>

    <button  *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.type == 'node' && this.folderFG.value.attach && !this.folderFG.value.addcomment && !this.folderFG.value.deadline && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false;;
    ">Save & Attach File & Assign</button>

    <button  *ngIf="this.folderFG.value.type == 'node' && this.folderFG.value.indextemplate  && !this.folderFG.value.addcomment && !this.folderFG.value.deadline && !this.folderFG.value.statetracker && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false;
    ">Save Form & Assign</button>

    <button  *ngIf="this.folderFG.value.type == 'wizard' && this.folderFG.value.indextemplate  && !this.folderFG.value.addcomment && !this.folderFG.value.deadline && !this.folderFG.value.statetracker && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Wizard & Assign</button>

    <button  *ngIf="this.folderFG.value.type == 'nodetab' && this.folderFG.value.indextemplate  && !this.folderFG.value.addcomment && !this.folderFG.value.deadline && !this.folderFG.value.statetracker && this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Tabbed & Assign</button>

    <button  *ngIf="this.configSvc.userready && (this.folderFG.value.addcomment || this.folderFG.value.ischatgpt || this.folderFG.value.deadline || this.folderFG.value.singletemplate == '0' || !this.folderFG.value.bulkfiles == 'bulk')" mat-button  matSuffix 
    matStepperNext mat-raised-button color="primary" >Next</button>

    


</mat-step>

<mat-step [stepControl]="step6" *ngIf ="this.folderFG.value.deadline">
  <ng-template matStepLabel>
   Deadline</ng-template>
<div>
      <span class = "labels">
        <mat-label>Set Deadline</mat-label>
       </span> 
    <mat-form-field appearance="fill">
      <input matInput [matDatepicker]="picker1" placeholder="Set Deadline"  formControlName="deadlineval" >
           <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
           <mat-datepicker  #picker1></mat-datepicker>
         </mat-form-field></div>
         <span *ngIf=" this.folderFG.value.attach && !this.folderFG.value.indextemplate && !this.folderFG.value.bulkfiles">
         <button  *ngIf="!this.folderFG.value.addcomment" mat-button matSuffix 
         mat-raised-button color="primary" (click)="this.filedropped('file');this.dialogRef.close(); this.configSvc.quickadd = false; ">Set Deadline and Close </button>

     
      
</span>


<button  *ngIf ="!this.folderFG.value.bulkfiles == 'bulk' || this.folderFG.value.addcomment || this.folderFG.value.statetracker"mat-button  matStepperNext matSuffix 
mat-raised-button color="primary">Next</button> 

<span *ngIf="this.folderFG.value.type == 'node'  && !this.folderFG.value.attach && this.folderFG.value.indextemplate && this.folderFG.value.singletemplate == '1' && !this.folderFG.value.bulkfiles">
  <button  *ngIf="!this.folderFG.value.addcomment" mat-button matSuffix 
  mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false; ">Set Deadline and Close </button>


</span>

<span *ngIf="this.folderFG.value.type == 'node' &&  !this.folderFG.value.flow && this.folderFG.value.attach && this.folderFG.value.indextemplate  && this.folderFG.value.singletemplate == '1' && !this.folderFG.value.bulkfiles">
  <button  *ngIf="!this.folderFG.value.addcomment" mat-button matSuffix 
  mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false; ">Set Deadline and Close </button>

</span>



<button *ngIf="this.folderFG.value.flow && this.folderFG.value.indextemplate && this.folderFG.value.attach && !this.folderFG.value.textract && this.imageready && !this.folderFG.value.addcomment" mat-raised-button color="primary" 
(click)="launchFlow(); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Attach Image to Flow Template & Launch</button>



<button  *ngIf="this.folderFG.value.type == 'wizard' && this.folderFG.value.indextemplate  && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker" mat-button  matSuffix 
mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Wizard & Close</button>

<button  *ngIf="this.folderFG.value.type == 'nodetab' && this.folderFG.value.indextemplate  && !this.folderFG.value.addcomment && !this.folderFG.value.statetracker" mat-button  matSuffix 
mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Tabbed & Close</button>

 </mat-step>

<mat-step [stepControl]="step7" *ngIf ="this.folderFG.value.addcomment " class = "cmt">
 
  <ng-template matStepLabel>Add Comment</ng-template>

  <span>
    <div>
      Comment on {{this.configSvc.activename}}
  
    </div>


    <mat-form-field appearance="outline"   class = "textarea">
      <textarea
      
      matInput formControlName="cmtval"></textarea>
     </mat-form-field>
  
  </span>
<div>
  <mat-button-toggle-group formControlName="cmticon"  aria-label="Comment Icon" (change)="onIconChange($event.value)">
    <mat-button-toggle value="cmt"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
    <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
    <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
    <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
  </mat-button-toggle-group></div>

<div >
<mat-checkbox formControlName="emoji" >Change Emoji
  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
  
  </mat-checkbox>

  

</div>

<div>
<mat-checkbox formControlName="atcmt" ><i class="material-icons icon-customtoolbar" [ngStyle]="{'color': darkblue}">alternate_email</i>User
  
  
  </mat-checkbox>
</div>
 

  <div *ngIf="this.folderFG.value.emoji">
    <span>
   <app-iconcategories></app-iconcategories></span>
   <span class ="colorbox">
    <app-color-picker (color)="this.configSvc.color"></app-color-picker>
  </span>
  </div>

  <div *ngIf="this.folderFG.value.atcmt" class = space>

      <mat-form-field appearance="fill">
        <mat-label>Reassign</mat-label>
       
        <input type="text"  
        formControlName="atcmtuser"
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
    {{option.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      
 
 
 
 
  </div>
<span >
  <span *ngIf="this.folderFG.value.attach && !this.folderFG.value.indextemplate && this.folderFG.value.singletemplate == '1' && !this.folderFG.value.bulkfiles && !this.folderFG.value.statetracker">
    <button  mat-button matSuffix 
    mat-raised-button color="primary" (click)="this.filedropped('file');this.dialogRef.close(); this.configSvc.quickadd = false; ">Upload File </button>
   </span>

   

   <span *ngIf="this.folderFG.value.type == 'node' && !this.folderFG.value.flow && this.folderFG.value.singletemplate == '1' && this.folderFG.value.attach && this.folderFG.value.indextemplate && !this.folderFG.value.textract && !this.folderFG.value.bulkfiles  && !this.folderFG.value.statetracker">
    <button  mat-button matSuffix 
    mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false; ">Save Node with File </button>
   </span>

  

   <button  *ngIf="this.folderFG.value.type == 'wizard' && !this.folderFG.value.flow && this.folderFG.value.indextemplate  && this.folderFG.value.singletemplate == '1'  && !this.folderFG.value.statetracker && !this.folderFG.value.bulkfiles" mat-button  matSuffix 
mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Wizard & Close</button>

<button  *ngIf="this.folderFG.value.type == 'nodetab' && !this.folderFG.value.flow && this.folderFG.value.indextemplate  && this.folderFG.value.singletemplate == '1'  && !this.folderFG.value.statetracker && !this.folderFG.value.bulkfiles" mat-button  matSuffix 
mat-raised-button color="primary" (click)="this.saveStepper();this.dialogRef.close(); this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false;; this.configSvc.quickadd = false; ">Save Tabbed & Close</button>

<button  *ngIf="this.folderFG.value.statetracker " mat-button matStepperNext matSuffix 
mat-raised-button color="primary" >Next</button>

   <span *ngIf="this.folderFG.value.flow &&  this.folderFG.value.singletemplate == '1' && this.folderFG.value.attach && this.folderFG.value.indextemplate && !this.folderFG.value.textract && !this.folderFG.value.bulkfiles">
    <button  mat-button matSuffix 
    mat-raised-button color="primary" (click)="this.launchFlow();this.dialogRef.close(); this.configSvc.quickadd = false; ">Launch Flow with Comments</button>
   </span>

   <button *ngIf="this.folderFG.value.flow && this.folderFG.value.singletemplate == '1' && this.folderFG.value.indextemplate && !this.folderFG.value.attach && !this.folderFG.value.textract && !this.folderFG.value.statetracker && !this.folderFG.value.bulkfiles" mat-raised-button color="primary" 
(click)="launchFlow(); this.dialogRef.close(); this.configSvc.quickadd = false;" class = "buttonstyle">Launch</button>

   



   <span *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.singletemplate == '1' && this.folderFG.value.textract && this.folderFG.value.flow && !this.folderFG.value.bulkfiles">
    <button  mat-button matSuffix 
    mat-raised-button color="primary" matStepperNext (click)="this.launchFlow();">Launch </button>
   </span>

   <span *ngIf="this.folderFG.value.type == 'node' && !this.folderFG.value.flow && this.folderFG.value.singletemplate == '1' && !this.folderFG.value.attach && this.folderFG.value.indextemplate && !this.folderFG.value.bulkfiles && !this.folderFG.value.statetracker">
    <button  mat-button matSuffix 
    mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false; ">Save Node </button>
   </span>

   
</span>






</mat-step>

<mat-step [stepControl]="step8" *ngIf ="this.folderFG.value.statetracker">
  <ng-template matStepLabel>
  Track State</ng-template>
<div >
  <mat-label>State Label</mat-label>
  <input formControlName ="statelabel">

<i class="material-icons icon-customtoolbar" [ngStyle]="{'color': 'green'}">{{this.stateicon}}</i>
  </div>


         <span *ngIf="this.folderFG.value.attach && !this.folderFG.value.indextemplate && this.folderFG.value.singletemplate == '1' && !this.folderFG.value.bulkfiles != 'bulk'">
         <button   mat-button matSuffix 
         mat-raised-button color="primary" (click)="this.filedropped('file');this.dialogRef.close(); this.configSvc.quickadd = false; ">Add and Close </button>


</span>

<span *ngIf=" this.folderFG.value.type == 'node' && this.folderFG.value.indextemplate && !this.folderFG.value.textract && this.folderFG.value.singletemplate == '1'">
  <button   mat-button matSuffix 
  mat-raised-button color="primary" (click)="this.saveNode('node');this.dialogRef.close(); this.configSvc.quickadd = false; ">Create and Close </button>
</span>

<button  *ngIf ="!this.folderFG.value.attach && this.folderFG.value.flow && this.folderFG.value.indextemplate && !this.folderFG.value.textract && this.folderFG.value.singletemplate == '1' "mat-button  matSuffix 
mat-raised-button color="primary"(click) = "this.launchFlow();  this.dialogRef.close(); this.configSvc.quickadd = false;">Launch and Close</button>

<button  *ngIf ="!this.folderFG.value.bulkfiles &&  this.folderFG.value.type == 'wizard' || this.folderFG.value.type == 'nodetab'"mat-button  matSuffix 
mat-raised-button color="primary"(click) = "this.saveStepper();  this.dialogRef.close(); this.configSvc.quickadd = false;">Save Wizard and Close</button>

<button  *ngIf ="this.folderFG.value.textract || this.folderFG.value.singletemplate == '0' || !this.folderFG.value.bulkfiles == 'bulk'"mat-button  matSuffix 
mat-raised-button matStepperNext color="primary">Next</button>


 </mat-step>

<mat-step [stepControl]="step9" *ngIf="this.folderFG.value.textract && this.folderFG.value.mt== 'pdf' && this.pdfready">   <ng-template matStepLabel>Confirm Textraction</ng-template>
  <span *ngIf="this.configSvc.nodetextracted">
    <app-activenode></app-activenode>
    <mat-card *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
    
      <mat-label>Dollars</mat-label>
      <mat-form-field appearance="fill">  
                          
        <input formControlName="total" type = "number" matInput value="0">
</mat-form-field>
</mat-card>
    <button *ngIf="!this.configSvc.nodechanged" mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.closeTextraction()">Finish </button>

        <button *ngIf="this.configSvc.nodechanged || this.folderFG.value.total != 0 " mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.updateNodetags()">Update </button>

  </span>

  <span *ngIf="this.flowSvc.flowtextracted">
    <app-activenode></app-activenode>
   
    <button *ngIf="!this.configSvc.nodechanged" mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.closeTextraction()">Finish </button>

        <button *ngIf="this.configSvc.nodechanged || this.folderFG.value.total != 0 " mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.updateNodetags()">Update </button>

  </span>
  <span *ngIf="!this.configSvc.nodetextracted && this.folderFG.value.type == 'node'">
    <mat-spinner></mat-spinner>
    Textract running on node Amazon Web Services......
  </span>

  <span *ngIf="!this.flowSvc.flowtextracted && this.folderFG.value.type == 'basicflow'">
    <mat-spinner></mat-spinner>
    Textract running on flow Amazon Web Services......
  </span>
</mat-step>

<mat-step [stepControl]="step9b" *ngIf="this.folderFG.value.textract && this.folderFG.value.mt== 'jpg' || this.folderFG.value.mt== 'jpeg'">   <ng-template matStepLabel>Confirm Textraction</ng-template>
  <span *ngIf="this.configSvc.nodetextracted">
    <app-activenode></app-activenode>
    <mat-card *ngIf="this.configSvc.folderdetailsds.kanbantagged && this.configSvc.folderdetailsds.kantype == 'moneydollars'">    
    
      <mat-label>Dollars</mat-label>
      <mat-form-field appearance="fill">  
                          
        <input formControlName="total" type = "number" matInput value="0">
</mat-form-field>
</mat-card>
    <button *ngIf="!this.configSvc.nodechanged" mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.closeTextraction()">Finish </button>

        <button *ngIf="this.configSvc.nodechanged || this.folderFG.value.total != 0 " mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.updateNodetags()">Update </button>

  </span>

  <span *ngIf="this.flowSvc.flowtextracted">
    <app-activenode></app-activenode>
   
    <button *ngIf="!this.configSvc.nodechanged" mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.closeTextraction()">Finish </button>

        <button *ngIf="this.configSvc.nodechanged || this.folderFG.value.total != 0 " mat-button matSuffix 
        mat-raised-button color="primary"(click) = "this.updateNodetags()">Update </button>

  </span>
  <span *ngIf="!this.configSvc.nodetextracted && this.folderFG.value.type == 'node'">
    <mat-spinner></mat-spinner>
    Textract running on node Amazon Web Services......
  </span>

  <span *ngIf="!this.flowSvc.flowtextracted && this.folderFG.value.type == 'basicflow'">
    <mat-spinner></mat-spinner>
    Textract running on flow Amazon Web Services......
  </span>
</mat-step>




  



<mat-step [stepControl]="step12" *ngIf="this.folderFG.value.askchatgpt">   <ng-template matStepLabel>Prompt</ng-template>
    
  <span class= "itemcontainer">
    <div>
      <div class ="prompter"> <b>What would you like to discuss with AskChatGPT?</b></div>
       <mat-form-field appearance="fill"  class ="textwidth">
       <textarea 
       cdkTextareaAutosize
       cdkAutosizeMinRows=10
       cdkAutosizeMaxRows=10
       
       matInput value={{this.folderFG.value.prompt}}
       formControlName="prompt"></textarea>
       </mat-form-field>
       </div>   
       
       <mat-checkbox formControlName="promptopts" >Prompt Options</mat-checkbox>
       <mat-card *ngIf="this.folderFG.value.promptopts">
    <mat-accordion>
      <!-- #docregion basic-panel -->
      <!-- #docregion hide-toggle -->
        <mat-expansion-panel (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
      <!-- #enddocregion hide-toggle -->
          <mat-expansion-panel-header>
            <mat-panel-title>
            Prompt Lists
            </mat-panel-title>
            <mat-panel-description>
            Click panel to choose from a set of prompt lists
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
          <mat-form-field>
    
            <mat-select (selectionChange)="getPrompt()" formControlName="promptlist">
        <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
          {{prompt.name}}
        </mat-option>
      </mat-select>
     
    </mat-form-field>

    <span>
      <span class = "labels"><mat-label >Choose Prompt</mat-label></span>
    <mat-form-field>
    
      <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedPrompt" formControlName="promptbuild">
    <mat-option *ngFor="let chosenprompt of getChoices(this.folderFG.value.promptlist)" [value]="chosenprompt.desc">
    {{chosenprompt.name}}
    </mat-option>
    </mat-select>
    
    </mat-form-field>
    </span>

    <div>
      <mat-form-field appearance="outline"  class ="textwidth">
      <textarea
      matInput value={{this.folderFG.value.promptbuild}}
      formControlName="promptbuild"></textarea>
      </mat-form-field>
      </div>

      <div>
        <button [disabled]= "!addactive" mat-button matSuffix  
        mat-raised-button color="primary" (click)="addPrompt(this.folderFG.value.promptbuild);  " >Add </button></div>

        </mat-expansion-panel>
     
        <mat-expansion-panel (opened)="panelOpenState = true; getFoldercontext();"
        (closed)="panelOpenState = false">
          <!-- #enddocregion hide-toggle -->
              <mat-expansion-panel-header>
                <mat-panel-title>
                 Folder context
                </mat-panel-title>
                <mat-panel-description>
                  Click panel to choose from parent folder context
                </mat-panel-description>
              </mat-expansion-panel-header>
        
              <span class = "labels"><mat-label >Folder Context</mat-label></span>
              <mat-form-field>
        
                <mat-select (selectionChange)="selectPrompt()" [(value)]="selectedcontext" formControlName="contextlist">
            <mat-option *ngFor="let context of this.foldercontext" [value]="context">
              {{context}}
            </mat-option>
          </mat-select>
         
        </mat-form-field>

        <div>
          <button [disabled]= "!addactive" mat-button matSuffix  
          mat-raised-button color="primary" (click)="addContext(this.folderFG.value.contextlist);  " >Add </button>
        
          <button [disabled]= "!addallactive" mat-button matSuffix  
          mat-raised-button color="primary" (click)="addallContext();  " >Add All</button>
        
        </div>

        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true;"
        (closed)="panelOpenState = false">
          <!-- #enddocregion hide-toggle -->
              <mat-expansion-panel-header>
                <mat-panel-title>
                Focus 
                </mat-panel-title>
                <mat-panel-description>
                  Click panel to set focus level
                </mat-panel-description>
              </mat-expansion-panel-header>
        
              <span class = "labels"><mat-label >Focussed</mat-label></span>
              <mat-slider 
              formControlName="temp"
[min]="0" 
[max]="2" 
[step]="0.1" 
thumbLabel 
color="accent"
(change)="onTemperatureChange()">
Temperature: {{this.folderFG.value.temp}}
</mat-slider>
<mat-label >Random</mat-label>
<div>
  <mat-label >Responses</mat-label>
<mat-form-field appearance="outline" class = "small-item">  
 
  <input 
  formControlName = responses
  matInput type="number"
  matInput value="1">
 </mat-form-field></div>

            </mat-expansion-panel>
    

     
      </mat-accordion>
    </mat-card>

</span>



   <div *ngIf="!this.folderFG.value.indextemplate && !this.folderFG.value.ischatgpt" >
    <button mat-button matSuffix  matStepperNext
    mat-raised-button color="primary" (click)="filedropped('file'); this.configSvc.quickadd = false; " >Save File </button>
  </div>

  <div *ngIf="this.folderFG.value.ischatgpt && !this.folderFG.value.attach && !this.folderFG.value.indextemplate">
    <button mat-button matSuffix  matStepperNext
    mat-raised-button color="primary" (click)="askChatgpt(this.folderFG.value.prompt); this.configSvc.quickadd = false;" >Ask ChatGPT</button>
  </div>

  <div *ngIf="this.folderFG.value.ischatgpt && this.folderFG.value.attach && !this.folderFG.value.indextemplate">
    <button mat-button matSuffix  matStepperNext
    mat-raised-button color="primary" (click)="filedropped('file'); this.configSvc.quickadd = false;" >Ask ChatGPT about attachment</button>
  </div>

  <div *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.type =='node'">
    <button mat-button matSuffix  matStepperNext
    mat-raised-button color="primary" (click)="saveNode('node'); this.configSvc.quickadd = false; " >Save Template & ChatGPT </button>
  </div>

  <div *ngIf="this.folderFG.value.indextemplate && this.folderFG.value.type =='nodetab'">
    <button mat-button matSuffix  matStepperNext
    mat-raised-button color="primary" (click)="saveStepper(); this.configSvc.quickadd = false; " >Save Tabbed Template & ChatGPT </button>
  </div>
  


    </mat-step>


  <mat-step [stepControl]="step13" *ngIf="this.folderFG.value.askchatgpt">   <ng-template matStepLabel>ChatGPT</ng-template>
    
    <span *ngIf="!this.configSvc.chatgptresponse">
      <mat-spinner></mat-spinner>
     Waiting on response from ChatGPT......
    </span>

    <span *ngIf="this.configSvc.chatgptresponse" class ="responses">
      
      <div >
       <app-viewcomments></app-viewcomments>
      </div>

      <div>
        Rate response
      
        <div class="star-container">
          <button mat-icon-button *ngFor="let star of stars" (click)="rate(star)">
            <mat-icon class="yellow-icon">{{ starIcon(star) }}</mat-icon>
          </button>
      </div>
      </div>
   

     <div>
      <button [disabled] ="this.folderFG.value.saveprompt" mat-button matSuffix 
      mat-raised-button color="primary"(click) = "this.refreshFolder(); this.dialogRef.close();">Finish </button></div>

      <mat-checkbox formControlName="saveprompt" >Save Prompt</mat-checkbox>

      <div>
        <button [disabled] ="!this.folderFG.value.saveprompt" mat-button matSuffix matStepperNext
        mat-raised-button color="primary">Next </button></div>

      </span>
  

      </mat-step>





 
 

 


   </form> </mat-horizontal-stepper>
   </div>
   </span>

   <span *ngIf  = "data.type=='createteam'"><i class="material-icons icon-docaddpopup">group</i>
    <span mat-dialog-content>
     Create new Team
      
    </span>
    <div mat-dialog-actions>
     
      <mat-card class = "build-container">

      
                 <span *ngIf="this.configSvc.teamtype=='node'"><mat-card-title>Node Team Setup</mat-card-title></span> 
                  <mat-horizontal-stepper [linear]="isLinear" #stepper>
                 
                 
                    <mat-step [stepControl]="step1">
      <form [formGroup]="teamfileFG">
              <ng-template matStepLabel>
                     Team Name
                     </ng-template>
          <mat-form-field appearance="fill" class= "large-item">
              <mat-label>Create a new Team</mat-label>
              <input formControlName ="teamname"  matInput placeholder="Team Name" >
                  <i  class="material-icons icon-group" matSuffix style="color:black">group</i>
              </mat-form-field>
  <div>
              <mat-form-field appearance="fill" class= "large-item">
                <mat-label>Description of Team</mat-label>
                <input formControlName ="teamdescription"  matInput placeholder="Description" >
                   
                </mat-form-field></div>
    
              <div>
                     
                  <button  mat-raised-button color="primary" (click)="this.addMyself();" mat-button matStepperNext>Next</button>
                  </div>
              </form>
              </mat-step>
  
              <mat-step [stepControl]="step2">
                  <ng-template matStepLabel>Managers </ng-template>
                    
                         <app-teamsecurity></app-teamsecurity>   
                         <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                         <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                         <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                    
                  </mat-step>

                  <mat-step [stepControl]="step3">
                    <ng-template matStepLabel>Users </ng-template>
                      
                           <app-usersecurity></app-usersecurity>   
                           <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                           <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                           <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                      
                    </mat-step>
  
                  <mat-step [stepControl]="step4">
                          <form [formGroup]="teamfileFG">
                                  <ng-template matStepLabel>
                                         Design Icon
                                         </ng-template>
                                         <div>
                                          <span>
                                            <app-iconcategories></app-iconcategories></span>
                                                <span class ="colorbox">
                                                <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                                              </span>
                                          
                                              <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px">Back</button>
                                            <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
                                          </div>
                                         </form>
                      </mat-step>
  
                    
  
                      <mat-step [stepControl]="step5">
                              <form [formGroup]="teamfileFG">
                                      <ng-template matStepLabel>
                                             Create Team 
                                             </ng-template>
                                           
                                             
                                             <div class = "summary" >
             
              
             
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                              <mat-label >TeamName</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamname}}>
                                              </mat-form-field>
  
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                                <mat-label >TeamDescription</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamdescription}}>
                                                </mat-form-field>
                                           
                                            
                                              
                                                <div class="example-list">
               <mat-label>Managers</mat-label>
                      <div class="box-list" *ngFor="let manager of this.configSvc.mgrsadded" (click)="this.configSvc.selectMgrRow(manager, true)"
                            [style.background]=" this.configSvc.highlightedMgrRow.indexOf(manager) == 0 ? 'lightblue' : ''">
                                      <div>                                                                                                      
                                                  
   </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{manager}}
            </div></div>
            <mat-label>Users</mat-label>
<div class="box-list" *ngFor="let users of this.configSvc.usersadded" (click)="this.configSvc.selectUserRow(users, true)"
              [style.background]=" this.configSvc.highlightedUserRow.indexOf(users) == 0 ? 'lightblue' : ''">
                 <div> </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{users}}
                                                    </div></div>

                                                  </div>


                                          
                                           
                                               <mat-label>Icon</mat-label>
                                               <div>
                                                 <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || this.configSvc.teamdetailsds?.clr}" >{{this.configSvc.chosenicon}} </i>
                                                
                                                </div>
    </div>
  
    <span *ngIf="this.configSvc.teamtype=='folder'">
   <button mat-raised-button color="primary"  (click)="this.configSvc.createTeam(false, 'team', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
   this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenicon, false)
        ; this.teamfileFG.reset(); this.dialogRef.close(); this.configSvc.quickadd = false;" mat-button matStepperNext>Create Folder Team</button></span>
        
        <span *ngIf="this.configSvc.teamtype=='node'">
          <button mat-raised-button color="primary"  (click)="this.configSvc.nodeTeam('nodeteam', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
          this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, false)
          ; this.teamfileFG.reset(); this.dialogRef.close(); this.configSvc.quickadd = false;" mat-button matStepperNext>Create Node Team</button>
        </span>
  
  
                <span>
                      <button mat-raised-button color="primary" mat-button matStepperPrevious style="margin: 10px;">Back</button>
                </span>
                                             </form>
                          </mat-step>
  
                        
          </mat-horizontal-stepper>
        </mat-card>
    </div>
    </span>


    <span *ngIf = "data.type=='comment'" class = "cmt">
      <form [formGroup]="folderFG">
      <div mat-dialog-actions>
 
 
 
       <span>
         <div>
           Comment on <i class="material-icons md-48" [ngStyle]="{'color': 'yellow'}" >folder</i> {{this.configSvc.activename}}
       
         </div>
     
     
         <mat-form-field appearance="outline"   class = "textarea">
           <textarea
           
           matInput formControlName="cmtval"></textarea>
          </mat-form-field>
       
       </span>
     <div>
       <mat-button-toggle-group   formControlName="cmticon"  aria-label="Comment Icon" (change)="onIconChange($event.value)">
         <mat-button-toggle value="chat"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
         <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
         <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
         <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
       </mat-button-toggle-group></div>
     
     <div >
     <mat-checkbox formControlName="emoji" >Change Emoji
       <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
       
       </mat-checkbox>
     
       
     
     </div>
     
     <div>
     <mat-checkbox formControlName="atcmt" ><i class="material-icons icon-customtoolbar" [ngStyle]="{'color': darkblue}">alternate_email</i>User
       
       
       </mat-checkbox>
     </div>
      
     
       <div *ngIf="this.folderFG.value.emoji">
         <span>
        <app-iconcategories></app-iconcategories></span>
        <span class ="colorbox">
         <app-color-picker (color)="this.configSvc.color"></app-color-picker>
       </span>
       </div>
     
       <div *ngIf="this.folderFG.value.atcmt" class = space>
     
           <mat-form-field appearance="fill">
             <mat-label>Reassign</mat-label>
            
             <input type="text"  
             formControlName="atcmtuser"
             [matAutocomplete]="auto"
             
             matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
             
             <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
         {{option.inboxemail}} 
       
        </mat-option>
             </mat-autocomplete>
       
            
            </mat-form-field>
           
      
      
      
      
       </div>
 
   <button mat-button (click)="addComment(); this.dialogRef.close(); this.configSvc.quickadd = false;" 
                       
   matSuffix 
   mat-raised-button color="primary"
   aria-label="Add Comment">Add Comment</button>
 
      </div>
    </form> </span>


 
  <span *ngIf = "data.type=='deletefold'">
  
   <div mat-dialog-content>
     <i class="material-icons icon-delete">delete</i> 
     Are you sure you want to delete folder {{data.name}}    
   </div>
   <div mat-dialog-actions>
     <span class= "genpopupbottom">
       <button mat-button (click)="onNoClick()" 
             
       matSuffix 
       mat-raised-button color="primary"
       aria-label="Close Upload">Cancel</button>
 
       <button mat-button (click)="delete('folder')" 
       
             
       matSuffix 
       mat-raised-button color="primary"
       aria-label="Delete"
       
       >Delete Folder</button>
     
     </span>
     
   </div>
   </span>
 
   <span *ngIf = "data.type=='deletefile'">
     <div mat-dialog-content>
       <i class="material-icons icon-delete">delete</i> 
       Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
     </div>
     <div mat-dialog-actions>
       <span class= "genpopupbottom">
         <button mat-button (click)="onNoClick()" 
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Cancel">Cancel</button>
   
         <button mat-button (click)="delete('item')" 
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Delete">Delete</button>
       
       </span>
       
     </div>
     </span>

     <span *ngIf = "data.type=='moveFolder'">
      <div mat-dialog-content>
        <i class="material-icons icon-delete">content_cut</i> 
        Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
      </div>
      <div mat-dialog-actions>
        <span class= "genpopupbottom">
          <button mat-button (click)="onNoClick()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Cancel">Cancel</button>
    
          <button mat-button (click)="moveFolder(this.configSvc.currentrow._id)" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Delete">Move Folder</button>
        
        </span>
        
      </div>
      </span>

      <span *ngIf = "data.type=='cutItem'">
        <div mat-dialog-content>
          <i class="material-icons icon-delete">content_cut</i> 
          Are you sure you want to move genreral item {{this.configSvc.currentrow.name}} ?
        </div>
        <div mat-dialog-actions>
          <span class= "genpopupbottom">
            <button mat-button (click)="onNoClick()" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Cancel">Cancel</button>
      
            <button mat-button (click)="cutItem(this.configSvc.currentrow._id, 
            this.configSvc.currentrow.type, this.configSvc.currentrow.level, 
            this.configSvc.currentrow.teamid, this.configSvc.currentrow.name, 
            this.configSvc.currentrow.icontype, this.configSvc.currentrow.iconcolor, this.configSvc.folderdetailsds.kanbantagged)" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Cut Item">Cut Item</button>
          
          </span>
          
        </div>
        </span>

        <span *ngIf = "data.type=='pasteItem'">
          <div mat-dialog-content>
            <i class="material-icons icon-delete">content_paste</i> 
            Are you sure you want to paste {{this.configSvc.moveitem[0].name}} ?
          </div>
          <div mat-dialog-actions>
            <span class= "genpopupbottom">
              <button mat-button (click)="onNoClick()" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cancel">Cancel</button>
        
              <button mat-button (click)="pasteItem(this.configSvc.moveitem[0]?.itemid, this.configSvc.activeitemid,
              this.configSvc.moveitem[0]?.type,this.configSvc.moveitem[0]?.level,
               this.configSvc.moveitem[0]?.teamid, this.configSvc.moveitem[0]?.name, 
               this.configSvc.moveitem[0]?.icontype, this.configSvc.moveitem[0]?.iconcolor, this.configSvc.moveitem[0]?.sourcekanban, this.configSvc.moveitem[0]?.sourcekanbanid, this.configSvc.moveitem[0]?.activetrack)" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Paste Item">Paste Item</button>
            
            </span>
            
          </div>
          </span>

          <span *ngIf = "data.type=='locktoolbar'">
            <form [formGroup]="folderFG">
            <div mat-dialog-content>
              Choose the Toolbar that you would like to appear when folder opened

              <div >
                <mat-form-field appearance="fill" class ="toolbartemplates">
                  <mat-label>Toolbars</mat-label>
                 
                  <input type="text"  
                  matInput formControlName = "toolbarname"  
                  [matAutocomplete]="auto"
                  
                  matInput (keyup)="toolbarFilter($event)" placeholder="Toolbar" autocomplete="off" >
                  
                  <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let toolbar of this.configSvc.toolbards" [value]="toolbar.name"
              (onSelectionChange)="toolbarSelected(toolbar)">
            <span class="searchname">
              <i class="material-icons icon-size" [ngStyle]="{'color': toolbar.clr}">
                {{toolbar.icontype}}</i>
              <b>{{toolbar.name}}</b>:<i>{{toolbar.desc}} </i></span>
              
              </mat-option>
                  </mat-autocomplete>
              
                 
                 </mat-form-field>
              
                
              </div>
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="lockToolbar()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Lock Toolbar">Lock Toolbar</button>
              
              </span>
              
            </div>
          </form>    </span>
       
          <span *ngIf = "data.type=='unlocktoolbar'">
            <form [formGroup]="folderFG">
            <div mat-dialog-content>
              Unlock toolbar from folder

         
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="unlockToolbar()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Unlock Toolbar">Unlock Toolbar</button>
              
              </span>
              
            </div>
          </form>    </span>

          <span *ngIf = "data.type=='removeKanban'">
            <form [formGroup]="folderFG">
            <div mat-dialog-content>
             Remove Kanban from folder

         
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="removeKanban()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Remove Kanban">Remove Kanban</button>
              
              </span>
              
            </div>
          </form>    </span>

          <span *ngIf = "data.type=='applyKanban'">
            <form [formGroup]="folderFG">
            <div mat-dialog-content>
              Choose Kanban to apply to folder

              <div >
                <mat-form-field appearance="fill" class ="indextemplates">
                  <mat-label>Kanbans</mat-label>
                 
                  <input type="text"  
                  matInput formControlName = "kanbanname"  
                  [matAutocomplete]="auto"
                  
                  matInput (keyup)="kanbanFilter($event)" placeholder="Kanban" autocomplete="off" >
                  
                  <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let kanban of this.configSvc.findkanbands" [value]="kanban.name"
              (onSelectionChange)="kanbanSelected(kanban)">
            <span class="searchname">
              <i class="material-icons icon-size" [ngStyle]="{'color': kanban.iconcolor}">
                {{kanban.icontype}}</i>
              <b>{{kanban.name}}</b>:<i>{{kanban.desc}} </i></span>
              
              </mat-option>
                  </mat-autocomplete>
              
                 
                 </mat-form-field>
              
                 <span *ngIf="this.kanbanready" class = "flex-container"  >
              
                  <div  class="kanban-container"
                  
                  *ngFor="let track of this.configSvc.activekanban" 
                >
                
                  <mat-card><span class= "tracks">
                    <h2  class="mat-h2">{{track.formitem}}</h2>
                    <i class="material-icons md-24" [ngStyle]="{'color': 'green'}"
                    matTooltip={{track.itemvalue}}  matTooltipPosition= 'after'
                    >info</i></span>
                   </mat-card>
                   </div>
                  
                  </span>
                  
              </div>
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="applyKanban()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Apply Kanban">Apply Kanban</button>
              
              </span>
              
            </div>
          </form>    </span>
 
     