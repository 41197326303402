import { Component, OnInit, Inject, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import {ConfigService} from '../../../services/config.service';
import { DocactionsService } from '../../../services/docactions.service';
import {TagService} from '../../../services/tag.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TagerService} from '../../../services/tager.service';
import {UploadService} from '../../../services/upload.service';
import { NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import { DoccommentsService } from '../../../services/doccomments.service';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import { AuthenticationService, TokenPayload } from '../../../services/authentication.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { jsPDF, AcroFormCheckBox } from "jspdf";
import {SidenavService} from '../../../services/sidenav.service';




export interface DialogData {
 
  name: string;
  type: string;
}

@Component({
  selector: 'app-nodetools',
  templateUrl: './nodetools.component.html',
  styleUrls: ['./nodetools.component.css']
})
export class NodetoolsComponent implements OnInit {

 

  constructor(public configSvc: ConfigService, public docActions: DocactionsService, public sidenavSvc: SidenavService,
    public dialog: MatDialog, public tagerSvc: TagerService) { }

  ngOnInit() {

    
    
   
    
  }

  

  openDialog(type, name): void {

    console.log('popup name is'+name)
    console.log('popup type is'+type)
    this.configSvc.userready = false;
    this.configSvc.tagupload = true;
  //  this.configSvc.popup =true
//var this.configSvc.popupheight = '500px'
// var this.configSvc.popupwidth= '400px'
    if(type == 'deletefold' || type == 'deletefile' || type == 'deletetageritem' || type =='cutitem' || type =='pasteitem'){
  
      this.configSvc.popupheight = '20vh'; this.configSvc.popupwidth = '30vw'

  }
    if(type == 'folder' || type == 'bulkadd'){
      this.configSvc.popupheight = '450px'; this.configSvc.popupwidth = '500px'}
 
    if(type == 'addfiles'){this.configSvc.popupwidth = '700px'}
    if(type == 'report'){this.configSvc.popupwidth = '700px'}
    if(type == 'folderdetails'){this.configSvc.popupwidth = '600px'}
    if(type == 'assign'){
      this.configSvc.popupheight = '40vh'; this.configSvc.popupwidth = '40vw'}
      if(type == 'nodereport'){
        this.configSvc.popupheight = '40vh'; this.configSvc.popupwidth = '30vw'}
    if(type == 'createteam'){
      this.configSvc.popupheight = '650px'; this.configSvc.popupwidth= '1200px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'team';
    this.configSvc.activetype = 'createteam';
    }
    if(type == 'comment'){
      this.configSvc.popupheight = '60vh'; this.configSvc.popupwidth= '50vw'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'orange'
    //  this.configSvc.commenticon = 'chat'
      this.configSvc.chosenIcon = 'chat'
    }

    if(type == 'askchatgpt'){
      this.configSvc.popupheight = '60vh'; this.configSvc.popupwidth= '50vw'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'blue'
    //  this.configSvc.commenticon = 'chat'
      this.configSvc.chosenIcon = 'psychology_alt'
    }
    console.log('popup width is'+this.configSvc.popupwidth)
    console.log('popup height is'+this.configSvc.popupheight)

    const dialogRef = this.dialog.open(NodePopup, {
      width: this.configSvc.popupwidth, height: this.configSvc.popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.tagupload = false;
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

  getrootToolbars(){
    this.sidenavSvc.toggle('navigation')
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sidenavSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
  }

  hideBrowse(){
    //this.tagerSvc.kibrowse=false;
  //  this.configSvc.buildtool = true;
    console.log('kibrowse is now: '+this.tagerSvc.kibrowse)
  }


  

  
  


}

@Component({
  selector: 'app-nodepopup',
  templateUrl: 'nodepopup.html',
  styleUrls: ['./nodetools.component.css']
})
export class NodePopup {
  public otherattach = 0
  user: TokenPayload;
  public  deadlineval = ''
  @Input() teamtags: boolean;
  @Input() foldertags = true;
  @Input() kanbanned: boolean;
  public indextype = '0';
  public attachtype: String;
  public attachname: String;
  public attachready = false;
  
  isLinear = true;
  step1 = "step1";
  step2= "step2";

  @Input() teamtype: string;
 // isLinear = true;
 // step1 = "step1";
 // step2= "step2";
  step3= "step3";
  step4= "step4";
  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
  public username = '';
    teamfileFG: FormGroup;
    nodeFG
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    public csvRecords: any[] = [];
    public header = true;
    public imageloaded
    public actualimage
    public imageready = false
    public url;
    public imageurl:any;
  public imageblob: any;
  public gotallphotos = false;
  public filereport = false;
  public filext = '';
    public fileready = false
    public pdfready = false
    public msgattready = false
    public msgfiles
 
    public filename = '';
 
    public allfiles
    public reset: any[] = [{}];
    public myDate 

    // public fontstyle = ['normal', 'italic', 'bold']
    public family = [
      'Courier', 'Helvetica', 'Times',
    ]

    public familystyle = [
      'Regular', "Oblique ", 'Bold', "Bold-Oblique "
    ]

    public timestyle = [
      'Roman',  'Italic', 'Bold', 'Bold-Italic'
    ]

    stars = [1, 2, 3, 4, 5];
    selectedStar = 0;
   
  constructor(private _formBuilder: FormBuilder, private router: Router,
    public dialogRef: MatDialogRef<NodePopup>, private doccomments: DoccommentsService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public tagSvc: TagService, private authService: AuthenticationService, 
    public configSvc: ConfigService, public tagerSvc: TagerService, private uploadSvc: UploadService,
    private ngxCsvParser: NgxCsvParser, private datePipe: DatePipe, private auth: AuthenticationService, ) {}

    ngOnInit() {
  
      this.nodeFG = 
      this._formBuilder.group({
        name: [''],
        indextype: '0',
        options: '0',
        kanban: false,
        kanbanname: '',
        kanbanid: '',
        kanbancolor: 'green',
        assigned: false,
        assignedto: '',
        deadline: false,
        deadlineval: '',
        addcomment: false,
        bulkindex: '0',
     attach: false,
     attachchoice: '0',
     filereport: false,
     inclcmts: this.configSvc.nodeds[0].cmt,
     inclattach: this.configSvc.nodeds[0].attach,
        username: '',
        cmticon: 'chat',
        cmtclr: 'blue',
        emoji: false,
        icontype: '',
        iconcolor: '',
       
        cmtval: '',
        atcmt: false,
        atcmtuser: '',
        attachreport: false,
        indextemplate: false,
        indexname: '',
        ischatgpt: false,
        
        singleitem: '1',
        attachimage: false,
        nodename: '',
        foldericon: 'folder',
        foldercolor: 'yellow',
        foldertags: [],
        textract: false,
        family:'Courier',
        style:'Regular',
        saveprompt: false,
        promptname: '',
        temp: 1.0,
        promptopts: false,
        responses: 1
        
       
  
        
      
      });

      this.uploadSvc.filesprocessed = 0;this.uploadSvc.uploadfinished = false;

      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  

 
  createCardbyid(tagerid, tagertype, name): void {
    console.log('saving tageritem now'+name)
    console.log('saving tageritem id'+tagerid)
    console.log('saving tageritem id with level set to '+this.configSvc.activelevel)
    console.log('saving tageritem id with teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createCardbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }

  createStepsbyid(tagerid, tagertype, name): void {
    console.log('creating Steps now'+name)
    console.log('id'+tagerid)
    console.log('level set to '+this.configSvc.activelevel)
    console.log(' teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createStepsbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }
  
  /*
  addFolder(name, indextype){
    this.configSvc.addFolder('fold', name, 
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');
      this.dialogRef.close();
  }
  */

  public addNodefiles(process) {
    var filetoupload
    if(process == 'msg'){
      console.log('this is an dragged file')
      filetoupload = this.msgfiles
    }

    if(process != 'msg'){
      console.log('this is a file system file')
      filetoupload = this.allfiles[0]
    }
   // this.uploadSvc.totalfilesuploaded =  this.allfiles.length
  //  console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
    this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
    this.uploadSvc.addNodefiles(this.configSvc.nodeds[0]._id, filetoupload,  false,this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].name, this.filext );
    }

    attachNode(){
      console.log('attaching node')
  
     // if(this.configSvc.activetype == 'tageritem'){
     //   this.configSvc.updateTags(this.configSvc.newpropvalues, this.configSvc.activetype, this.configSvc.attachednode[0]._id)
    //  }
   //   else
    //  {
      
  
  
        this.uploadSvc.attachNode(this.configSvc.activeitemid,this.configSvc.attachednode[0]._id, this.configSvc.attachednode[0].icontype, this.configSvc.attachednode[0].iconcolor, this.configSvc.attachednode[0].name,  this.configSvc.newpropvalues, this.configSvc.nodeds[0].parentid, this.configSvc.clicksource  )
        //  }    
     
    }

    public fileReport(doc) {
      console.log('trying to attach the report to the item')
      var reportname =  this.configSvc.activename+' Report.pdf';
      this.uploadSvc.totalfilesuploaded = doc.length
      console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
      this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
      this.uploadSvc.fileReport(doc, reportname);
      }

    /*
    public bulkprocess(files: NgxFileDropEntry[]) {
      console.log('bulk processing now')
      this.uploadSvc.bulkprocess(files);
    }

    public bulkprocesslocal(files: NgxFileDropEntry[], indextype) {
      console.log('bulk processing locally with indextype of'+indextype)
      for (const droppedFile of files) {


      
          
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  
  
          fileEntry.file((fileext) => {
            console.log('cwhat is going on here');
      //start of parse process
      this.ngxCsvParser.parse(fileext, { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
 
        console.log('Result', result);
        this.csvRecords = result;

        for(const csvitem of this.csvRecords){
         console.log('this is the skeleton indexes'+JSON.stringify(this.configSvc.teamdetailsds.stepitems))
        console.log('csvitem is: '+csvitem.Name)
        console.log('this is the csv row: '+JSON.stringify(csvitem))
        this.configSvc.totalcsvrows = this.csvRecords.length;
    console.log('totalcsvrows is: '+this.configSvc.totalcsvrows)
    
        let name = csvitem.name
        if(this.configSvc.activetype == 'fold' && this.configSvc.folderdetailsds.tagged){
          console.log('this is folder tagged')
        this.configSvc.newpropvalues = this.configSvc.folderdetailsds.stepitems}
        if(this.configSvc.activetype == 'team' && this.configSvc.folderdetailsds.tagged){
          console.log('this is team tagged')
          this.configSvc.newpropvalues = this.configSvc.teamdetailsds.stepitems}

          if(this.configSvc.activetype == 'fold' && !this.configSvc.folderdetailsds.tagged){
            console.log('this is a plain old folder')
          }

        for(const propertyitem of this.configSvc.newpropvalues )
        {

          console.log('property is'+propertyitem.formitem);
            let csvprop= propertyitem.formitem
            console.log('csvprop is'+csvprop);
          console.log('property value is'+propertyitem.itemvalue);
          propertyitem.itemvalue = csvitem[csvprop];
          console.log('newly update property value is'+propertyitem.itemvalue);
        }

        console.log('this is the populated new skeleton indexes'+JSON.stringify(this.configSvc.newpropvalues ))
        
          this.configSvc.addFolder('fold', name, 
          this.configSvc.activeitemid, this.configSvc.activename, 
          this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');


        }
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      //end of parse process
    });
     
  }
    }
    */
 
  
    
    
      public fileOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

      public addComment(ischatgpt) {
    


        if (this.nodeFG.value.indextemplate) {this.configSvc.activetype = 'node'};
        if (!this.nodeFG.value.indextemplate && this.nodeFG.value.attach) {this.configSvc.activetype = 'file'};
     
        if(this.nodeFG.value.cmticon == 'thumb_up'){  this.nodeFG.patchValue({cmtclr: 'green'})}
        if(this.nodeFG.value.cmticon == 'cmt'){ this.nodeFG.patchValue({cmtclr: 'black'})}
        if(this.nodeFG.value.cmticon == 'thumb_down'){ this.nodeFG.patchValue({cmtclr: 'blue'})}
        if(this.nodeFG.value.cmticon == 'priority_high'){ this.nodeFG.patchValue({cmtclr: 'red'})}
   
        if(this.nodeFG.value.emoji){ 
          console.log('changed to custom emoji')
          this.nodeFG.patchValue({cmticon: this.configSvc.chosenIcon})}


      if(ischatgpt){ 
        this.nodeFG.patchValue({cmticon: 'forum'})
        this.nodeFG.patchValue({cmtclr: 'purple'})
        this.nodeFG.patchValue({icontype: 'live_help'})
        this.nodeFG.patchValue({iconcolor: 'green'})
      }
         

      this.doccomments.addcomment('addcomment', false, this.configSvc.activetype, this.nodeFG.value.cmtval, this.nodeFG.value.cmticon, this.nodeFG.value.cmtclr,  this.nodeFG.value.atcmt, this.nodeFG.value.atcmtuser, ischatgpt);
        }
        public getItembyId(){
          this.uploadSvc.getItembyid(this.configSvc.activeitemid, 'chatgpt')
        
        }

            changeChoice(){
              console.log('changed choice to'+  this.nodeFG.value.attachchoice)
              if(this.nodeFG.value.attachchoice == 1){
                console.log('lets go get favtools')
                this.configSvc.getfavToolbars()
              }
              if(this.nodeFG.value.attachchoice == 0){
                console.log('just attache somethnig')
              }

              if(this.nodeFG.value.attachchoice == 2){
                console.log('get locked toolbar')
                this.configSvc.getToolbars('attach',this.configSvc.folderdetailsds.toolbarid, 'nodeteam')
              }
}

  clickFav(type, name, toolbar){
console.log('type is '+type)
console.log('name is '+name)
console.log('toolbar is '+JSON.stringify(toolbar));
this.attachready = false
this.onRecreate()
this.attachtype = type
this.attachname = name
if(this.attachtype == 'node'){
  this.configSvc.attachednode= [toolbar]
console.log('attachednode is '+JSON.stringify(this.configSvc.attachednode))
this.attachready = true
}
if(this.attachtype == 'nodetab'){
  this.configSvc.attachednodetab= [toolbar]
  console.log('attachednodetab is '+JSON.stringify(this.configSvc.attachednodetab))}


            }

            onIconChange(cmticon){
              this.nodeFG.value.cmticon = cmticon
              console.log('changing the cmticon '+cmticon)
              if(this.nodeFG.value.cmticon == 'thumb_up'){  this.nodeFG.patchValue({cmtclr: 'green'})}
              if(this.nodeFG.value.cmticon == 'cmt'){ this.nodeFG.patchValue({cmtclr: 'black'})}
              if(this.nodeFG.value.cmticon == 'thumb_down'){ this.nodeFG.patchValue({cmtclr: 'blue'})}
              if(this.nodeFG.value.cmticon == 'priority_high'){ this.nodeFG.patchValue({cmtclr: 'red'})}
          
            }

   public dropversion(files: NgxFileDropEntry[]) {
    this.uploadSvc.dropversion(files);
    }

    
    
      public fileverOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

      public fileverLeave(event) {
        console.log(event);
    
      }

      public delete(deletetype){

        if(this.configSvc.activetype == 'fold' && deletetype == 'folder'){
         
          console.log('this is the pathds'+JSON.stringify(this.configSvc.pathds));
          console.log('this is the current level'+this.configSvc.activelevel);
          console.log('this is the parent path'+JSON.stringify(this.configSvc.pathds[this.configSvc.activelevel-1]));
          var parentpath = [this.configSvc.pathds[this.configSvc.activelevel-1]]
          console.log('this is the parentpath'+JSON.stringify(parentpath));
          var parentitemid = parentpath[0].path.pfoldid
          console.log('this is the parentitemid'+parentitemid);
          var parenttype = parentpath[0].path.type
          var parentname = parentpath[0].path.name
          var parentlevel = parentpath[0].path.level
          var parentteamid = parentpath[0].path.teamid
        console.log('deleting folder '+JSON.stringify(this.configSvc.folderdetailsds.parentid));
        this.configSvc.deleteFolder(this.configSvc.activeitemid, this.configSvc.folderdetailsds.parentid, this.configSvc.activeteamid)
       // this.router.navigateByUrl(this.configSvc.currentURL);
       this.router.navigate(['/toolbard/home'], { queryParams: { itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid } });
        //this.router.navigate(["/toolbard/home" [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}" ></a>
      }
        if(deletetype == 'item' && this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, true ,this.configSvc.currentrow.activetrack )
        }

        if(deletetype == 'item' && !this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting non kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, false )
        }
         

        this.dialogRef.close();
      }

      public findUser() {
        this.finduserclicked = true;
        this.configSvc.findUser(this.usersearchfc.value);
       // console.log('userdetailset is' + this.configSvc.userdetailset);
       // this.dbuser = this.configSvc.userdetailset.name;
       // this.dbemail = this.configSvc.userdetailset.email;
      }
  
      drop(event: CdkDragDrop<string[]>) {
     
       
        transferArrayItem(this.configSvc.finduserdataset, this.configSvc.usersadded, event.previousIndex, event.currentIndex);
        console.log('finished transferring users to selected user');
        console.log('current selected user list'+JSON.stringify(this.configSvc.usersadded));
        //this.configSvc.userselected.push(this.configSvc.usersadded._id);
    }
  
    public addMyself(){
      this.configSvc.mgrsadded = [];
      const userdetails  = this.authService.getUserDetails();
      console.log('this is the managers details'+JSON.stringify(userdetails))
      const mgremail = userdetails.email
      this.configSvc.mgrsadded.push(mgremail)
      console.log('activetype after adding myself ist'+this.configSvc.activetype)
     // this.configSvc.findUser(mgremail, 'addmyself');
     // this.myself = userdetails;
     this.configSvc.activetype = 'team'
    // this.configSvc.mgrsadded.push(userdetails);
  
  
    }

   

    cutItem(itemid, type, level, teamid, name, icontype, iconcolor, sourcekanban){
      console.log('moving itemid '+itemid)
      console.log('moving item name '+name)
      console.log('moving item icontype '+icontype)
      console.log('moving item iconcolor '+iconcolor)
      this.configSvc.move = true;
      this.configSvc.moveitem = [];
     
      if(sourcekanban == true){
        console.log('this sourcekanban is true')
        console.log('this is the selected activetrack'+this.configSvc.nodeds[0].activetrack)
        this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
         'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': this.configSvc.folderdetailsds._id, 'activetrack': this.configSvc.nodeds[0].activetrack})
        console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}
  
        if(sourcekanban == false){
          this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
           'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': '', 'activetrack': 'notrack'})
          console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}
      this.dialogRef.close();
     
    }

    pasteItem(itemid,parentfolderid, type, level, teamid, name, icontype, iconcolor,  sourcekanban, sourcekanbanid, activetrack){

      console.log('icontype is '+icontype); console.log('iconcolor is '+iconcolor)
      this.configSvc.moveItem(itemid, parentfolderid, type, level, teamid, name, icontype, iconcolor,  sourcekanban, sourcekanbanid, activetrack )
      this.dialogRef.close();
    }

  public setAssignedto(){
         console.log('setting setAssignedto '+this.nodeFG.value.assignedto)
         this.configSvc.setAssignedto('node', this.configSvc.nodeds[0]._id, this.nodeFG.value.assignedto, false, this.configSvc.folderdetailsds._id)
        
       }

    userSelected(user){
      console.log('this is the selected user '+JSON.stringify(user))
      this.nodeFG.patchValue({assignedto: user.inboxemail})
      console.log('this is the actual selected user '+this.nodeFG.value.assignedto)
this.configSvc.userready =true
            }

            public prepareFile(event) {
              this.msgattready = false; this.pdfready = false; this.fileready=false; this.imageready=false
              if (event.target.files && event.target.files[0]) {
      
                this.filename = event.target.files[0].name
                console.log('preparing this file: '+this.filename)
      console.log('file extension is' +this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename)
      this.filext = this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename 
      
      if(this.filext == 'jpeg' || this.filext == 'jpg' || this.filext == 'png'){
                this.imageloaded = new FileReader();
            this.actualimage = event.target.files[0]
            this.allfiles = event.target.files
                this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url
            
                this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
                  this.url = event.target.result;
         this.imageready = true}
      
                }
              }
            
              if(this.filext != 'jpeg' && this.filext != 'jpg' && this.filext != 'png' && this.filext != 'pdf' && this.filext != 'msg'  ){
                this.allfiles = event.target.files
                this.fileready = true;
      console.log('this is standard file')}
      
      if(this.filext == 'pdf'  ){
        this.allfiles = event.target.files
        
        this.pdfready = true;
      console.log('this is a pdf file')}
      
      if( this.filext == 'msg'  ){
        console.log('this is msg file')
        this.allfiles = event.target.files
        
        this.msgattready = true;
        console.log('this is msgready status '+this.msgattready)}
      
      
      
      
              }

              public prepareMsg(files: NgxFileDropEntry[]) {
                this.msgattready = false; this.pdfready = false; this.fileready=false; this.imageready=false
                /*
                if (files && files[0]) {
        
                  console.log('preparing email')}
                  */
                  for (const droppedFile of files) {
                    if (droppedFile.fileEntry.isFile) {
                      console.log('preparing msg for attachment')
                  const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                  fileEntry.file((eachfile: File) => {
                    this.msgfiles = eachfile
                  });
                }
        
                }
        
      
          console.log('this is msg file')
          // this.allfiles = files
          console.log('this is msg  '+JSON.stringify(files));
          console.log('this is msg title '+JSON.stringify(files[1].relativePath));
          this.filename = JSON.stringify(files[1].relativePath);
        //  console.log('file extension is' +this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename)
        //  this.filext = this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename 
        //  this.msgfiles = files[1].fileEntry
        this.filext = 'msg'
          this.msgattready = true;
          console.log('this is msgready status '+this.msgattready)
          
        
        
        
        
                }

    moveFolder(itemid, name){
      console.log('moving folder')
      this.configSvc.move = true;
      this.dialogRef.close();
    }

   nodeReport(filereport){
      // Default export is a4 paper, portrait, using millimeters for units
      console.log('starting to write node report')
      console.log('this is the node datar '+JSON.stringify(this.configSvc.nodeds))

      console.log('starting to write report')
      console.log('this is the contents of the folder '+JSON.stringify(this.configSvc.teamds))

      var doc = new jsPDF();
      var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      
      doc.setTextColor(10);
      console.log('family is set to '+this.nodeFG.value.family)
      console.log('style is set to '+this.nodeFG.value.style)
     doc.setFont(this.nodeFG.value.family, this.nodeFG.value.style)
    
     
    // doc.setFont(this.nodeFG.value.style, this.nodeFG.value.fonttype);
      console.log('font is set to '+this.nodeFG.value.fonttype)
      doc.setFontSize(18);
     
   // var ylocation = 5;
      this.myDate = new Date();
      this.myDate = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');
      doc.text(this.auth.getUserDetails().companyname+' Report', pageWidth / 2, 30, {align: 'center'});
      doc.text(this.configSvc.nodeds[0].bo+': '+this.configSvc.nodeds[0].name, pageWidth / 2, 50, {align: 'center'});
    //  doc.text(this.configSvc.nodeds[0].bo+' Report ', pageWidth / 2, 50, {align: 'center'});
      doc.text('Date report created '+this.myDate , pageWidth / 2, 70, {align: 'center'});
      if(!this.configSvc.nodeds[0].attach){
        doc.text(this.configSvc.nodeds[0].name+' has no attachments ', pageWidth / 2, 90, {align: 'center'});
      }
      if(this.configSvc.nodeds[0].attach){
        doc.text(this.configSvc.nodeds[0].name+' has '+this.configSvc.nodeds[0].attachs.length +' attachments included in this report', pageWidth / 2, 90, {align: 'center'});
      }

      if(this.nodeFG.value.inclcmts ){

        doc.text(this.configSvc.nodeds[0].name+' with the following comments:', pageWidth / 2, 110, {align: 'center'});
        // doc.addPage();
      //  doc.text(this.configSvc.nodeds[0].bo, 10, pageHeight-10, );
      //  doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
       // doc.setTextColor(40);
      // doc.setFontSize(18);
      //  doc.text('Comments Section', pageWidth / 2, 20, {align: 'center'});
     ylocation = 120
      Object.keys(this.configSvc.nodeds[0].comments).forEach((i3) => {
       // doc.setTextColor(100);
       // doc.setFontSize(12);
       ylocation =ylocation +5;
        console.log('i3 value is '+i3)
        var cmt: string = String(this.configSvc.nodeds[0].comments[i3].comment);
        var ab: string = String(this.configSvc.nodeds[0].comments[i3].ab);
        var ao: string = String(this.configSvc.nodeds[0].comments[i3].ao);
        ao = this.datePipe.transform(ao, 'dd-MM-yyyy, h:mm a');
        doc.text('"'+cmt+'"  by '+ab+' on '+ao,pageWidth / 2,ylocation, {align: 'center'});
        console.log('i3 value is '+i3)
      
      })
    
     
     }
     // doc.text(this.configSvc.folderdetailsds.itemcnt +' items summarized in this report', pageWidth / 2, 90, {align: 'center'});
     // doc.text('Summary report for '+this.configSvc.activename, 10, 30, 'center');
     doc.setTextColor(50);
   
     doc.setFontSize(14);
     doc.text(this.configSvc.nodeds[0].bo+' : '+this.configSvc.nodeds[0].name, 10, pageHeight-10, );
     
   

      doc.text('Page ' + 1, pageWidth-30, pageHeight-10, );
    
     // doc.text('Do you like that?',20, 20);
     var pagecnt = 2;

    
    
var nodecnt =0
      console.log('nodecnt at very start is '+nodecnt)
      var asset = this.configSvc.nodeds[nodecnt]

        console.log('nodecnt at start is '+nodecnt)
       
      console.log('asset desc is '+asset.desc)
     // doc.addPage();

    //  doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
      var assetname: string = String(asset.name);
      var assetdesc: string = String(asset.desc);
    //  doc.text(assetname+' '+assetdesc, 10, 20);
     
     

      var ab: string = String(asset.ab);
      var ti: string = String(asset.ti);
      var ao: string = String(asset.ao);
      var bo: string = String(asset.bo);
   //   doc.text('AssetID: ' +ti+' created by ' +ab+  ' based on:' +bo, 10, 40);

 

if(this.configSvc.nodeds[0].type == 'node'){
  doc.addPage();
  var asset = this.configSvc.nodeds[0]
  var attachready  =false

   // var asset = this.configSvc.teamds[i]
   console.log('asset desc is '+asset.desc)

 //  doc.setTextColor(100);
  // doc.setFontSize(10);
 // doc.setFontSize(20)
  doc.setTextColor(5)
  doc.text(asset.bo +':'+asset.name, pageWidth / 2, 10, {align: 'center'})
  doc.setTextColor(10)
   doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );


   var ab: string = String(asset.ab);
   var ao: string = String(asset.ao);
   var mb: string = String(asset.mb);
   var mo: string = String(asset.mo);
   var ti: string = String(asset.ti);
   var bo: string = String(asset.bo);
   var desc: string = String(asset.desc);
   var name: string = String(asset.name);
   
   doc.text(name, 10, pageHeight-10, );
   doc.text('Name: ' +name, 10, 20);
   doc.text('Unique ID: ' +ti, 10, 25);
   doc.text('Based on: ' +bo, 10, 30);
   doc.text('Description:' +desc, 10, 35);
   doc.text('Modified: ' + this.datePipe.transform(mo, 'dd-MM-yyyy, hh:mm a')+' by '+mb, 10, 40);
   


   doc.text('Added: ' +this.datePipe.transform(ao, 'dd-MM-yyyy, hh:mm a')+' by '+ab, 10, 45);
  
var ylocation =  55;
var xlocation =  10;
//doc.setTextColor(20);
 // doc.setFontSize(12);
 var boxcount = 0
   Object.keys(this.configSvc.nodeds[0].stepitems).forEach((i2) => {
ylocation =ylocation +5;
if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'string' || this.configSvc.nodeds[0].stepitems[i2].formtype == 'number' 
){
 //  ylocation = ylocation +5
  xlocation = 10
var itemvalue: string = String(this.configSvc.nodeds[0].stepitems[i2].itemvalue);
doc.text( this.configSvc.nodeds[0].stepitems[i2].formitemlabel+':' +itemvalue, xlocation,ylocation);
}

if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'label'){
 //  ylocation = ylocation +5
  xlocation = 10;
  ylocation = ylocation+15;
var itemvalue: string = String(this.configSvc.nodeds[0].stepitems[i2].itemvalue);
// doc.setFont(undefined, 'bold')
doc.setFont('Courier', 'bold')
doc.setFontSize(12);
// doc.text( this.configSvc.nodeds[0].stepitems[i2].formitemlabel, xlocation,ylocation);

var text = this.configSvc.nodeds[0].stepitems[i2].formitemlabel
var lines =  doc.splitTextToSize(text, 180)
doc.text( lines, xlocation,ylocation);

doc.setFont(this.nodeFG.value.family, this.nodeFG.value.style)
doc.setFontSize(14);
}

if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'text'){
  //  ylocation = ylocation +5
   xlocation = 10;
   ylocation = ylocation+10;
 var itemvalue: string = String(this.configSvc.nodeds[0].stepitems[i2].itemvalue);
 // doc.setFont(undefined, 'bold')
 doc.setFont('Courier', 'bold')
 doc.setFontSize(12);
 ylocation = ylocation+10;
 doc.text( this.configSvc.nodeds[0].stepitems[i2].formitemlabel+': ', xlocation,ylocation);
 doc.setFont(this.nodeFG.value.family, this.nodeFG.value.style)
 doc.setFontSize(14);
 ylocation = ylocation+5;
 var text = this.configSvc.nodeds[0].stepitems[i2].itemvalue
 var lines =  doc.splitTextToSize(text, 180)

 doc.text( lines, xlocation,ylocation);

 }

if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'checkbox'){
console.log('checkboxylocation at start is set to '+location)

const checkBox =new AcroFormCheckBox();
checkBox.fieldName = "CheckBox";
checkBox.x = 5;
checkBox.y =ylocation;
checkBox.width = 5;
checkBox.height = 15;
checkBox.maxFontSize = 15;
checkBox.readOnly = true;
checkBox.textAlign = 'center';
// checkBox.value = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
// checkBox.caption = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
//checkBox.fieldName = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;

if(!this.configSvc.nodeds[0].stepitems[i2].hasdefault){checkBox.color = 'red'; checkBox.caption = '8';}
if(this.configSvc.nodeds[0].stepitems[i2].hasdefault){checkBox.color = 'green';}

   


   var formitemlabel: string = String(this.configSvc.nodeds[0].stepitems[i2].formitemlabel);
   if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'checkbox'){
    xlocation = xlocation +5
     doc.text( formitemlabel,xlocation,ylocation);}

  

     
   
 }

 if(this.configSvc.nodeds[0].stepitems[i2].formtype == 'boolean'){

  xlocation =5;
  console.log('checkboxylocation at start is set to '+location)
  console.log('y co-ordinate is ')
  const checkBox =new AcroFormCheckBox();
  checkBox.fieldName = "CheckBox";
  checkBox.x = xlocation;
  checkBox.y =ylocation;
  checkBox.width = 5;
  checkBox.height = 15;
  checkBox.maxFontSize = 15;
  checkBox.readOnly = true;
  checkBox.textAlign = 'center';
  // checkBox.value = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
  // checkBox.caption = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
  //checkBox.fieldName = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
  
  
  if(!this.configSvc.nodeds[0].stepitems[i2].hasdefault && !this.configSvc.nodeds[0].stepitems[i2].label){

    checkBox.color = 'red'; checkBox.caption = '8';
    
    doc.text('Not Completed '+this.configSvc.nodeds[0].stepitems[i2].formitemlabel, xlocation,ylocation+10, { maxWidth: 180 });}


  if(this.configSvc.nodeds[0].stepitems[i2].hasdefault && !this.configSvc.nodeds[0].stepitems[i2].label)
  
  {checkBox.color = 'green';
  doc.text('Completed '+this.configSvc.nodeds[0].stepitems[i2].formitemlabel, xlocation,ylocation+10, { maxWidth: 180 });}

   var formitemlabel: string = String(this.configSvc.nodeds[0].stepitems[i2].formitemlabel);
/*
   if(!this.configSvc.nodeds[0].stepitems[i2].label){
   doc.addField(checkBox);
     doc.text('Completed one toggle'+formitemlabel, 15,ylocation+10, { maxWidth: 180 });}
     */
  if(this.configSvc.nodeds[0].stepitems[i2].label){

    var labeltext = 'Completed '+formitemlabel;
       
       
          if(!this.configSvc.nodeds[0].stepitems[i2].checkbox){
            boxcount++
            xlocation =10
          ylocation =ylocation+15
            var itemvalue: string = String(this.configSvc.nodeds[0].stepitems[i2].itemvalue);
        //    labeltext = 'Completed '+formitemlabel+ ''+itemvalue 
     //   checkBox.color = 'green'; checkBox.caption = '';
        doc.addField(checkBox);
        xlocation = 5
       
            doc.text(boxcount+'. '+formitemlabel, xlocation+10,ylocation, { maxWidth: 180 });
         
          //  ylocation = ylocation + 10
            doc.text(itemvalue, xlocation+10,ylocation+10, { maxWidth: 180 });
          //  checkBox.color = 'green'; checkBox.caption = '';
          checkBox.x = xlocation;
          checkBox.y =ylocation;
          checkBox.color = 'green';
            doc.addField(checkBox); 
        }
  
        if(this.configSvc.nodeds[0].stepitems[i2].checkbox){
          boxcount++
          xlocation =5
         ylocation =ylocation+10
          var textarea: string = String(this.configSvc.nodeds[0].stepitems[i2].textarea);
         checkBox.color = 'red';checkBox.caption = '8';
         checkBox.x = xlocation;
         checkBox.y =ylocation;
         doc.addField(checkBox);
         doc.setTextColor('red')
         
              doc.text(boxcount+'. '+formitemlabel, xlocation+10,ylocation, { maxWidth: 180 });
              
           
              doc.text(textarea,xlocation+10,ylocation+10, { maxWidth: 180 });
              checkBox.x = xlocation;
              checkBox.y =ylocation;
           //   checkBox.color = 'green'; checkBox.caption = '';
              doc.addField(checkBox); 
              doc.setTextColor('black')
      }

      if(ylocation > 240){
        ylocation = 0
        doc.addPage();

  var asset = this.configSvc.nodeds[0]

   // var asset = this.configSvc.teamds[i]
   console.log('asset desc is '+asset.desc)

 //  doc.setTextColor(100);
  // doc.setFontSize(10);
 // doc.setFontSize(20)
  doc.setTextColor(5)
  doc.text(asset.bo +':'+asset.name, pageWidth / 2, 10, {align: 'center'})
  doc.setTextColor(10)
   doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
      }
      
      
     
  
      
        }
      
      
     
   }

   
 })

 // if(this.configSvc.nodeds[0].attach == true){
  if(this.nodeFG.value.inclattach == true){
  console.log('this node has got attachments');
  attachready = false;

 // console.log('nodecnt value is defo '+nodecnt)

  this.gotallphotos = false;
  var attachcount = 0
  console.log('looping thru this data '+JSON.stringify(this.configSvc.nodeds[0].attachs))
Object.keys(this.configSvc.nodeds[0].attachs).forEach((eachattach) => {
  attachcount++
// for (var i4 = 0; i4 < asset.photos.length; i4++) {
 // for(var eachphoto = Object.keys(this.configSvc.nodeds[i].photos), i4 = 0, end = eachphoto.length; i4 < end; i4++) {
 //  console.log('this is the nodedata '+JSON.stringify(asset))

    console.log('getting photo number '+eachattach);

    console.log('mimetype before is  '+ this.configSvc.nodeds[0].attachs[eachattach].mt);
 if(this.configSvc.nodeds[0].attachs[eachattach].mt == 'jpg' || 
 this.configSvc.nodeds[0].attachs[eachattach].mt == 'jpeg' || this.configSvc.nodeds[0].attachs[eachattach].mt == 'png'){
   // var nodecnt = i4.toString();
   console.log('minmetype is an image so off to get image for '+this.configSvc.nodeds[0].attachs[eachattach].mt)
console.log('path is '+this.configSvc.nodeds[0].attachs[eachattach].pa)
   
    console.log('mimetype is '+ this.configSvc.nodeds[0].attachs[eachattach].mt);
  const imageForm = new FormData();
  imageForm.append('docid', this.configSvc.nodeds[0].attachs[eachattach].pa);
  imageForm.append('nodecnt', nodecnt.toString());
 


  
  this.authService.getImagebyid(imageForm, this.user).subscribe(imageresults => {
    console.log('photos nodecnt value after getting image by id is '+nodecnt)
    console.log('photos nodecnt value is '+nodecnt)
    this.imageurl = imageresults
   
    console.log('returned nodecnt value is '+nodecnt)
    // let mySrc;
    const reader = new FileReader();
    reader.readAsDataURL(this.imageurl); 
    reader.onload = (e) => {
 
      this.imageblob = reader.result; 
     ylocation =ylocation +30;
      console.log('eachattach value is '+eachattach)
    
    //  console.log('current nodedso value is '+JSON.stringify(this.configSvc.nodeds[0]));
      
      console.log('photo id is '+this.configSvc.nodeds[0].attachs[eachattach].pa);

     ylocation = 0
      doc.addPage();
      doc.text('Photo relating to '+this.configSvc.nodeds[0].name, pageWidth / 2,ylocation+10, {align: 'center'});
      doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
      doc.text('Photo Attachment for '+this.configSvc.nodeds[0].name,ylocation+10, pageHeight-10, );
      doc.text('PhotoID: ' +this.configSvc.nodeds[0].attachs[eachattach].attachid,ylocation+15, 20);
      var photodate = this.datePipe.transform(this.configSvc.nodeds[0].attachs[eachattach].ao, 'dd-MM-yyyy, h:mm a');
      doc.text('Photo uploaded by ' +this.configSvc.nodeds[0].attachs[eachattach].ab+' on '+photodate, 15, 30);
   //   doc.text('Added on '+this.configSvc.nodeds[0].attachs[eachattach].ao, 15, 40);
      doc.addImage(this.imageblob, 'JPEG', 15, 50, 180, 160);
      console.log('eachattach value is '+eachattach)
      console.log('asset photos length '+this.configSvc.nodeds[0].attachs.length)
      
      console.log('index of attached item '+attachcount);
      console.log('numberof attachments '+this.configSvc.nodeds[0].attachs.length);
      if(attachcount == this.configSvc.nodeds[0].attachs.length  ){
      

        var title =this.configSvc.activename+" "+"Report.pdf";
        doc.save(title);
        var uploadedpdf = doc.output('blob');
        console.log('finished writing report with no comments')
        if(this.nodeFG.value.filereport){
          console.log('filing report now')
          this.fileReport(uploadedpdf)
        }

       
      
       console.log('all attachments have been retrieved')
       attachready =true }
     
   }
}
, (err) => {
   console.error(err); });
//end of getimagebyid
console.log('end of getimagebyid and timeout')
}
if(this.configSvc.nodeds[0].attachs[eachattach].mt == 'attachnode' || this.configSvc.nodeds[0].attachs[eachattach].mt == 'pdf' ||
this.configSvc.nodeds[0].attachs[eachattach].mt == 'docx' )  {

/*
  if(this.configSvc.nodeds[0].attachs[eachattach].mt == 'pdf' || this.configSvc.nodeds[0].attachs[eachattach].mt == 'docx' )  {

    this.otherattach++

     
  //if(this.otherattach == 1){ 
    doc.addPage();
  //}

    doc.text('Attached Files '+this.configSvc.nodeds[0].attachs[eachattach].name, pageWidth / 2, 5, {align: 'center'});
    doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
    doc.text('Attachment:  '+this.configSvc.nodeds[0].attachs[eachattach].name, 10, pageHeight-10, );
    }
    */
  
    if(this.configSvc.nodeds[0].attachs[eachattach].mt == 'attachnode'){
      this.otherattach ++
      if(this.otherattach == 1){ doc.addPage();}
 ylocation = 10

  const imageForm = new FormData();
  imageForm.append('itemid', this.configSvc.nodeds[0].attachs[eachattach].itemid);
 
 
 
  this.authService.getItembyid(imageForm, this.user).subscribe(itemresults => {
    console.log('getItembyid nodecnt value after getting item by id is '+nodecnt)
    console.log('itemresults is '+JSON.stringify(itemresults))
   this.configSvc.attachednodedata = itemresults
  

   doc.text('Attachments Linked to '+this.configSvc.nodeds[0].name, pageWidth / 2, 5, {align: 'center'});
  doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
  doc.text('Attachment:  '+this.configSvc.nodeds[0].name, 10, pageHeight-10, );
  ylocation =ylocation +10;
   console.log('attachment name is '+this.configSvc.attachednodedata[0].name)
   doc.text(' Attachment '+parseInt(eachattach) +' '+this.configSvc.nodeds[0].attachs[eachattach].nodename, 10,ylocation).setFont('bold');
    Object.keys(this.configSvc.attachednodedata[0].stepitems).forEach((i2) => {

   
     
      
      console.log('attached stepitems value is '+i2)
    //  console.log('tag  is '+JSON.stringify(tag))
    //  console.log('formitemlabel  is '+JSON.stringify(tag))

//ylocation = 10;
// console.log('i2 value is '+i2)




if(this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'checkbox'){

 ylocation =ylocation+5
console.log('this is a checkbox')
const checkBox =new AcroFormCheckBox();
checkBox.fieldName = "CheckBox";
checkBox.x = 5;
checkBox.y =ylocation;
checkBox.width = 5;
checkBox.height = 15;
checkBox.maxFontSize = 15;
checkBox.readOnly = true;
checkBox.textAlign = 'center';
// checkBox.value = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
// checkBox.caption = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
//checkBox.fieldName = this.configSvc.teamds[i].stepitems[i2].formitemlabel;

if(!this.configSvc.attachednodedata[0].stepitems[i2].hasdefault){
checkBox.color = 'red';


}
doc.addField(checkBox);
    }


    var formitemlabel: string = String(this.configSvc.attachednodedata[0].stepitems[i2].formitemlabel);
    console.log('this is a formitemlabel '+formitemlabel)
    if(this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'checkbox'){
     
      doc.text( formitemlabel, 15,ylocation);}

      if(this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'string' ||  
      this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'number'){
       ylocation =ylocation+5
        var itemvalue: string = String(this.configSvc.attachednodedata[0].stepitems[i2].itemvalue);
        console.log('this is a string itemvalue'+itemvalue)
        doc.text( formitemlabel+ ':' +itemvalue, 15,ylocation);
      }

   

      if(
      this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'date'){
       ylocation =ylocation+5
        var itemvalue: string = String(this.configSvc.attachednodedata[0].stepitems[i2].itemvalue);
        console.log('this is a string itemvalue'+itemvalue)
        var attachdate = this.datePipe.transform(itemvalue, 'dd-MM-yyyy, h:mm a');

        doc.text( formitemlabel+ ':' +attachdate, 15,ylocation);
      }

      
      if(this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'boolean' ){
if(this.configSvc.attachednodedata[0].stepitems[i2].hasdefault){
 ylocation =ylocation+5
        var hasdefault: string = String(this.configSvc.attachednodedata[0].stepitems[i2].hasdefault);
        doc.text( 'This itemresults has NOT '+this.configSvc.attachednodedata[0].stepitems[i2].formitemlabel, 15,ylocation+10);
      }
     
      if(!this.configSvc.attachednodedata[0].stepitems[i2].hasdefault){
       ylocation =ylocation+5
        var hasdefault: string = String(this.configSvc.attachednodedata[0].stepitems[i2].hasdefault);
        doc.text( 'This itemresults has '+this.configSvc.attachednodedata[0].stepitems[i2].formitemlabel, 15,ylocation+10);
      }
    }

    if(this.configSvc.attachednodedata[0].stepitems[i2].formtype == 'text' ){
     ylocation =ylocation+5
      doc.text( this.configSvc.attachednodedata[0].stepitems[i2].formitemlabel+': '+this.configSvc.attachednodedata[0].stepitems[i2].itemvalue, 15,ylocation);
     ylocation =ylocation +10;
    
    }

    
    
  })
  
}
, (err) => {
   console.error(err); });
//end of getimagebyid
console.log('end of getitembyid and timeout')


  console.log('index of attached attachnode item '+attachcount);
    }
  
  
 
  
 
}

if(attachcount == this.configSvc.nodeds[0].attachs.length){

 


/*
  var title =this.configSvc.activename+" "+"Report.pdf";
  doc.save(title);
  var uploadedpdf = doc.output('blob');
  console.log('finished writing report with attachments')
  if(this.nodeFG.value.filereport){
    console.log('filing report now')
    this.fileReport(uploadedpdf)
  }
  */
  }




  

console.log('index of attached item at finish'+attachcount);
console.log('index of attached length at finish'+this.configSvc.nodeds[0].attachs.length);




})


}

console.log('value of inclattach is '+this.nodeFG.value.inclattach);

if(this.nodeFG.value.inclattach == false){
  console.log('this node has got NO attachments');
  
  var title =this.configSvc.activename+" "+"Report.pdf";
  doc.save(title);
  var uploadedpdf = doc.output('blob');
  console.log('finished writing report with attachments')
  if(this.nodeFG.value.filereport){
    console.log('filing report now')
    this.fileReport(uploadedpdf)
  }
 }



 }

  if(this.configSvc.nodeds[0].type == 'nodetab'){


    Object.keys(this.configSvc.nodeds[0].stepper).forEach((stepperitem) => {
      console.log('stepper entry is '+nodecnt)
      console.log('step entry is '+this.configSvc.nodeds[0].stepper[stepperitem].step)
      console.log('desc entry is '+this.configSvc.nodeds[0].stepper[stepperitem].desc)
    //  console.log('tag  is '+JSON.stringify(tag))
    //  console.log('formitemlabel  is '+JSON.stringify(tag))
   ylocation =ylocation +10;
    doc.text(this.configSvc.nodeds[0].stepper[stepperitem].step+' Details', 15,ylocation);
   ylocation =ylocation +10;
doc.text('Description: '+this.configSvc.nodeds[0].stepper[stepperitem].desc, 15,ylocation);

// console.log('i2 value is '+i2)
Object.keys(this.configSvc.nodeds[0].stepper[stepperitem].tags).forEach((i2) => {
  console.log('looping round the stepper  for '+stepperitem)
console.log('looping round the  tags for '+i2)
console.log('looping round the '+JSON.stringify(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2]));
if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'checkbox'){
const checkBox =new AcroFormCheckBox();
checkBox.fieldName = "CheckBox";
checkBox.x = 5;
checkBox.y =ylocation;
checkBox.width = 5;
checkBox.height = 15;
checkBox.maxFontSize = 15;
checkBox.readOnly = true;
checkBox.textAlign = 'center';
checkBox.color = 'green';
// checkBox.caption = 'l';
// checkBox.value = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
// checkBox.caption = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
//checkBox.fieldName = this.configSvc.teamds[i].stepitems[i2].formitemlabel;

if(!this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].hasdefault){
checkBox.color = 'red';
checkBox.caption = '8';


}
doc.addField(checkBox);
    }


    var formitemlabel: string = String(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formitemlabel);
    if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'checkbox'){
     ylocation =ylocation +10;
      doc.text( formitemlabel, 15,ylocation);}

      if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'string' ||  
      this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'number'){
       ylocation =ylocation +10;
        var itemvalue: string = String(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].itemvalue);
        doc.text( formitemlabel+ ':' +itemvalue, 15,ylocation);
      }

    

      
      if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'boolean' ){
if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].hasdefault){
 ylocation =ylocation +10;
        var hasdefault: string = String(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].hasdefault);
        doc.text( 'This asset has NOT '+this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formitemlabel, 15,ylocation);
      }
     
      if(!this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].hasdefault){
       ylocation =ylocation +10;
        var hasdefault: string = String(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].hasdefault);
        doc.text( 'This asset has '+this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formitemlabel, 15,ylocation);
      }
    }

    if(this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formtype == 'text' ){
     ylocation =ylocation +10;
      doc.text( this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].formitemlabel+': ', 15,ylocation);
     ylocation =ylocation +10;
      doc.text( this.configSvc.nodeds[0].stepper[stepperitem].tags[i2].itemvalue, 15,ylocation)
    }
   }) 
   console.log('adding a page now')
  ylocation =  10;
   //doc.addPage();
   //doc.text('page ' + pagecnt++, 170,285);
  
  })
} 



    

    
    if(this.configSvc.nodeds[0].photo == true){
      console.log('this node has got photos');
     
      console.log('nodecnt value is defo '+nodecnt)

      this.gotallphotos = false;
      var imgcnt = 0
   Object.keys(asset.photos).forEach((i4) => {
    
   // for (var i4 = 0; i4 < asset.photos.length; i4++) {
     // for(var eachphoto = Object.keys(this.configSvc.nodeds[i].photos), i4 = 0, end = eachphoto.length; i4 < end; i4++) {
     //  console.log('this is the nodedata '+JSON.stringify(asset))
    
        console.log('getting photo number '+i4);
        console.log('photos nodecnt value is '+nodecnt)
       // var nodecnt = i4.toString();
      const imageForm = new FormData();
      imageForm.append('docid', asset.photos[i4].photoid);
      imageForm.append('nodecnt', nodecnt.toString());
      console.log('doc id is '+asset.photos[i4].photoid);
      console.log('photos nodecnt value before timeout '+nodecnt)
    
      
      this.authService.getImagebyid(imageForm, this.user).subscribe(imageresults => {
        console.log('photos nodecnt value after getting image by id is '+nodecnt)
        console.log('photos nodecnt value is '+nodecnt)
        this.imageurl = imageresults
       
        console.log('returned nodecnt value is '+nodecnt)
        // let mySrc;
        const reader = new FileReader();
        reader.readAsDataURL(this.imageurl); 
        reader.onload = (e) => {
     
          this.imageblob = reader.result; 
         ylocation =ylocation +30;
          console.log('i4 value is '+i4)
          console.log('nodecnt value is '+nodecnt)
        //  console.log('current nodedso value is '+JSON.stringify(this.configSvc.nodeds[0]));
          
          console.log('photo id is '+asset.photos[i4].photoid);
          doc.addPage();
          doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
          doc.text('PhotoID: ' +asset.photos[i4].photoid, 15, 20);
          doc.text('Photo uploaded by ' +asset.photos[i4].ab, 15, 30);
          doc.text('Added on '+asset.photos[i4].ao, 15, 40);
          doc.addImage(this.imageblob, 'JPEG', 15, 50, 180, 160);
          console.log('i4 value is '+i4)
          console.log('asset photos length '+asset.photos.length)
          imgcnt++;
          if(asset.photos.length ==  imgcnt){
        
          }
        
         
       }
    }
    , (err) => {
       console.error(err); });
//end of getimagebyid
console.log('end of getimagebyid and timeout')


      

    
   
  })}

 

 
    

  
    /*
     if(!this.configSvc.nodeds[0].photo && !this.configSvc.nodeds[0].attach){
     var title =this.configSvc.activename+" "+"Report.pdf";
     doc.save(title);
     console.log('finished writing report')
     var uploadedpdf = doc.output('blob');
     console.log('finished writing report')
     if(this.nodeFG.value.filereport){
      console.log('filing report now')
      this.fileReport(uploadedpdf)
    }
     
    }
*/
 

   
    
    console.log('finished my  nodeds loop, on to the next one: '+nodecnt)



  

//
this.dialogRef.close();
}



    public imagedropped(event) {
 
      if (event.target.files && event.target.files[0]) {
        this.imageloaded = new FileReader();
    this.actualimage = event.target.files[0]
        this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url
    
        this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
          this.url = event.target.result;
          this.imageready = true
        }
      }
    
    // this.dialogRef.close();
      }
    
    
    public imageOver(){
      console.log(' image over')
    }
    
    public imageLeave(){
      console.log(' image leave')
    }

    public saveNodeimage(){

      console.log('saving node for '+this.configSvc.nodeds[0]._id);
    //  console.log('lastest stepitem properties '+JSON.stringify(this.newpropvalues));
      
      if (this.configSvc.activetype == 'editteam'){
        console.log('cannot save at the team level')}
      else{
      console.log(' applying saveNodeimage at the folder level');
      this.uploadSvc.saveNodeimage(this.actualimage, '3', this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
        this.configSvc.nodeds[0].name, this.configSvc.textract, false)
    
      this.dialogRef.close();
     
    }
     }

     usernameFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log('filtering email on: '+filterValue)
      if (filterValue != '')
      {
        console.log('filter value befoore sending is : '+filterValue)
      this.configSvc.usernameFilter(filterValue, 'user')
  
  
    }
    
    }


    public setDeadline(deadlineval){

    this.configSvc.setDeadline(this.configSvc.activeitemid, deadlineval, false, this.configSvc.folderdetailsds._id)
    this.dialogRef.close();
  }

  public onRecreate(){
    this.reset[0] = {}
  }

  starIcon(star: number) {
    if (star <= this.selectedStar) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  
  rate(star: number) {
    this.selectedStar = star;
    const stars = document.querySelectorAll('.mat-icon');
    stars.forEach((starIcon, index) => {
      if (index < star) {
        starIcon.classList.add('selected-star');
      } else {
        starIcon.classList.remove('selected-star');
      }
    });
  }
  
  onTemperatureChange() {
    // Do something with the new temperature value
    console.log('temp is set to '+this.nodeFG.value.temp)
  }

  public refreshFolder(){

    console.log('this is the rating applied '+ this.selectedStar)
    if(this.selectedStar != 0){
      console.log('this has been rated need to set rating on this '+JSON.stringify(this.configSvc.nodeds))
      this.configSvc.ratePrompt(this.configSvc.nodeds[0].parentid, this.configSvc.nodeds[0]._id, this.selectedStar)
  
  }
    if(this.selectedStar == 0){console.log('this has NOT been rated')
    this.configSvc.refreshFolder(this.configSvc.activeitemid, 'fold')}
  
    this.configSvc.popup = false
  }

}


