
  
 
  
  
  



    <span >
      <i class="material-icons icon-string" title=" Find Existing Forms, Tags or Kanbans"
      (click)="this.openNodetool('find', '')">search</i>
   </span>
  
    <span >
      <i class="material-icons icon-string" title=" New Form"
      (click)="this.openNodetool('form', '')">assignment</i>
   </span>

   <span >
    <i class="material-icons icon-string" title=" New Smart Folder"
    (click)="this.openNodetool('smartfolder', '')">create_new_folder</i>
 </span>

 <span >
  <i class="material-icons icon-string" title=" Multi Items"
  (click)="this.openNodetool('batchitems', '')">library_add</i>
</span>

 <span >
  <i class="material-icons icon-string" title=" New Basic flow"
  (click)="this.openNodetool('flow', 'Name of flow')">multiple_stop</i>
</span>
  
 

    <span>
      <i class="material-icons icon-string" title="New Folder Tags" 
      (click)="this.openNodetool('foldertags', 'Folder Tags name')">bookmarks</i>
  </span>
  
  
  
    <span>
      <i class="material-icons icon-string" title=" New Kanban" 
      (click)="this.openNodetool('kanban', 'Kanban name')">view_column</i>
      </span>
  
      <span>
        <i class="material-icons icon-string" title=" New Track" 
        (click)="this.openNodetool('track', 'Track name')">sync_alt</i>
        </span>

        
  <span>
    <i class="material-icons icon-string"  title="Delete Item"
    (click)="this.openNodetool('deletecategory', this.configSvc.activename)">delete</i></span>
  
      
  
  
  
      
  



  
  