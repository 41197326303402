import { Component, OnInit } from '@angular/core';
import {FlowService} from '../../services/flow.service';
import {FormControl, FormBuilder, FormGroup, FormArray} from '@angular/forms';

@Component({
  selector: 'app-queuedetails',
  templateUrl: './queuedetails.component.html',
  styleUrls: ['./queuedetails.component.css']
})
export class QueuedetailsComponent implements OnInit {
  editqueueFG: FormGroup;
  constructor( public flowSvc: FlowService, private fb: FormBuilder) { }

  ngOnInit() {
 
    
    this.editqueueFG = this.fb.group({
      queuename: [this.flowSvc.queueds[0].name],
      queuedesc: [this.flowSvc.queueds[0].desc],
      queueid: [this.flowSvc.queueds[0]._id],
      icontype: [this.flowSvc.queueds[0].icontype],
     iconcolor: [this.flowSvc.queueds[0].clr],
      
   });



  
  




   
 }

}
