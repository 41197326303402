import { Component, OnInit } from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ConfigService} from '../../services/config.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nodephotos',
  templateUrl: './nodephotos.component.html',
  styleUrls: ['./nodephotos.component.css']
})
export class NodephotosComponent implements OnInit {

  public reset: any[] = [{}];
  versionColumns = [ 'ab', 'ao', 'vk'];
  public itemselected = false;
  public itemsadded = [];
  public clickCount = 0;
  constructor(public uploadSvc: UploadService, public configSvc: ConfigService, private router: Router) { }

  ngOnInit(): void {
   
  }

  public selectItem(item){
    this.itemsadded = [];
 this.itemsadded.push(item)
this.uploadSvc.getLatestphoto(item.photoid)

  
  }

 

click(item) {
    this.clickCount++;
    setTimeout(() => {
        if (this.clickCount === 1) {
            console.log('this is a single click')
            this.itemselected = true;
    console.log('is item selected? '+this.itemselected)
    this.itemsadded = []
this.itemsadded.push(item)
console.log('selected items '+JSON.stringify(this.itemsadded))
        } else if (this.clickCount === 2) {
          console.log('this is a double click')
      
          this.itemselected = true;
  
  this.itemsadded = []
this.itemsadded.push(item)
console.log('this is the image item info'+JSON.stringify(this.itemsadded[0]))
var base = '/toolbard/imagevers?'
var itemid = this.itemsadded[0]._id
var teamid = this.configSvc.nodeds[0].teamid;
var dynamicurl = base+'itemid='+itemid+'&type=imagedirect&teamid='+teamid
this.configSvc.tageritemready = false;

this.router.navigateByUrl(dynamicurl);


        }
        this.clickCount = 0;
    }, 250)
}

}
