
  <mat-card class = flex-container>
    <mat-horizontal-stepper  #stepper  [(selectedIndex)]="stepIndex">
      <form [formGroup]="registeruserFG" >
      <mat-step [stepControl]="step1">
        <ng-template matStepLabel>User Details</ng-template>
       
        <mat-card class = "step-card">
       
            <div><mat-form-field class="full-width-input">
           <input matInput placeholder=" Username" formControlName="username" />
           <mat-error *ngIf="isFieldInvalid('username')">
             Please enter the your username
           </mat-error>
         </mat-form-field></div>

             <div>  <mat-form-field class="full-width-input">
           <input matInput type="password" placeholder="Password" formControlName="password" required />
           <mat-error *ngIf="isFieldInvalid('password')">
           Please enter your username password</mat-error>
 
         </mat-form-field></div> 
 
   
 
  

      


         
         <div *ngIf="error" class="alert alert-danger">{{error}}</div>
       
       
        
       
         <button mat-raised-button color="primary"  mat-button matStepperNext (click)="this.iconNext()">Next</button>
     
        </mat-card>
        </mat-step>


        
    
        <mat-step [stepControl]="step2">
          <ng-template matStepLabel>Home Icon</ng-template>
          <mat-card class = "step-card">

            <form [formGroup]="registeruserFG">

              <span>
                <app-iconcategories></app-iconcategories></span>
                    <span class ="colorbox">
                    <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                  </span>
                  
        
        
            </form>
           
          <span class= "button-wrapper">
            <button mat-raised-button color="primary"  mat-button matStepperPrevious class="buttonmargin">Back</button>
            <button mat-raised-button color="primary"  mat-button matStepperNext class="buttonmargin">Next</button>
          </span>
          </mat-card>
          </mat-step>
     
      <mat-step [stepControl]="step3">
        
          <ng-template matStepLabel>Register</ng-template>
          <mat-card class = "step-card">
            <form [formGroup]="registeruserFG"></form>
            <div><mat-form-field class="full-width-input">
              <input matInput placeholder="Home Name" formControlName="teamname" required />
             
              </mat-form-field></div>

              <div><mat-form-field class="full-width-input">
                <input matInput placeholder="Home Description" formControlName="teamdesc" required />
               
                </mat-form-field></div>
   
 
              
           <mat-icon>edit</mat-icon> Click Finish to complete registration
          
            <button mat-raised-button color="primary"  mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary"  mat-button class="buttonmargin" (click)="this.registerUser()">Finish</button>
            <div *ngIf="error" class="alert alert-danger">{{error}}</div>
           
          
     
      </mat-card>
     
    </mat-step>
    </form>
    
    
     
    </mat-horizontal-stepper>
  
  </mat-card>
