<span *ngIf="this.configSvc.toolmenu == 'toolbartools'" >
  <i class= 
  "material-icons folder-tools"  title="Folder Tools"
  (click)="this.configSvc.toolmenu = 'general'">folder_open</i>
</span>





<span *ngIf="this.configSvc.toolmenu == 'favtoolset'">
<span  *ngFor="let toolbar of configSvc.favtoolset" >

    <span>
      <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="{{toolbar.name}}: {{toolbar.desc}} "
      (click)="this.openDialog(toolbar.type, toolbar.name, toolbar)"
    >{{toolbar.icontype}}</i></span>
  
  </span>
</span>
 <span *ngIf="this.configSvc.toolmenu == 'general'" class="scrollmenu">
 


  <span  *ngIf="this.configSvc.activelevel == '0'">
    <i class="material-icons icon-edit"  title="Launch Add Wizard without filing in a folder"
    (click)="this.openDialog('rootfolder', this.configSvc.activename)" >add</i></span>
  
    <span *ngIf="this.configSvc.activetype == 'fold' && this.configSvc.activelevel != '0'">
      <i class="material-icons icon-edit"  title="Add Wizard"
      (click)="this.openDialog('addfiles', this.configSvc.activename)">add</i></span>

      <span *ngIf="this.configSvc.activetype == 'smartfolder' && this.configSvc.activelevel != '0'">
        <i class="material-icons icon-edit"  title="Add Wizard"
        (click)="this.openDialog('addfiles', this.configSvc.activename)">add</i></span>

        <span *ngIf="this.configSvc.activetype == 'smartfoldertab' && this.configSvc.activelevel != '0'">
          <i class="material-icons icon-edit"  title="Add Wizard"
          (click)="this.openDialog('addfiles', this.configSvc.activename)">add</i></span>

  
    <span>
      <i class="material-icons icon-edit"  title="Add Folder"
      (click)="this.openDialog('folder', 'New Folder')">create_new_folder</i></span>

      <span>
        <i class="material-icons icon-edit"  title="Add Template"
        (click)="this.openDialog('addtemplate', this.configSvc.activename)">fact_check</i></span>

        <span>
          <i class="material-icons icon-edit"  title="Add File"
          (click)="this.openDialog('addfile', this.configSvc.activename)">attach_file</i></span>

          <span>
            <mat-icon class="custom"  title="Ask ChatGPT" svgIcon="chat1"
            (click)="this.openDialog('askchatgpt', 'Ask ChatGPT a Question')"
            ></mat-icon></span>

           
          <span>
            <i class="material-icons icon-edit"  title="Ask Toolbard"
            (click)="this.openDialog('asktoolbard', 'Ask Toolbard')">contact_support</i></span>


          <span>
            <i class="material-icons icon-edit"  title="Create ChatGPT Image"
            (click)="this.openDialog('chatimage', 'Create ChatGPT Image')">palette</i></span>

  

        <span *ngIf="this.configSvc.activetool == 'addcomment'">
          <i class="material-icons icon-edit" title="Add Comment">add_comment</i>
        </span>
      
      <span *ngIf="this.configSvc.activetool != 'addcomment'">
      <i class="material-icons icon-edit" title="Add Comment" 
       (click)="this.openDialog('comment', '')">add_comment</i>
      </span>
      

    
     
     
        <span>
          <i class="material-icons icon-edit"  title="Generate Folder Report"
          (click)="this.openDialog('report', 'Generate Folder Report')">picture_as_pdf</i></span>
  
        
  
           <span>
            <i class="material-icons icon-edit"  title="Folder Details"
            (click)="this.openDialog('folderdetails', 'Folder Details')">source</i></span>
    
  
  
    <span *ngIf="this.configSvc.nodeds[0]?.ed == false">
       <i class="material-icons icon-edit" title="Open and Edit {{this.configSvc.nodeds[0]?.name}}" 
       (click)="this.docActions.downloadFile();this.docActions.editFile();">lock</i></span>
  
  
    <span *ngIf="this.configSvc.nodeds[0]?.ed == true"> 
      <i class="material-icons icon-edit" title="Checkin {{this.configSvc.nodeds[0]?.name}}" 
      (click)="this.openDialog('unlock', '')">lock_open</i></span>

      <span *ngIf="this.configSvc.nodeds[0]?.type == 'file'">
        <i  class="material-icons icon-edit"  title="Download {{this.configSvc.nodeds[0]?.name}}" 
        (click)=" this.docActions.downloadFile();">save_alt</i></span>
    
   
    <span *ngIf="this.configSvc.activetype == 'fold' || this.configSvc.activetype == 'smartfoldertab'"> 
      
      <i class="material-icons icon-edit"  title="Delete {{this.configSvc.activename}} Folder"
       (click)="this.openDialog('deletefold', this.configSvc.activename)">folder_delete</i></span>
  
       <span *ngIf="this.configSvc.activetype != 'fold' && this.configSvc.activetype != 'smartfoldertab'"> 
      
        <i class="material-icons icon-edit"  title="Delete {{this.configSvc.nodeds[0]?.name}}"
         (click)="this.openDialog('deletefile', this.configSvc.activename)">delete</i></span>
    
  
        
  
    <span  *ngIf="this.configSvc.activetype != 'undefined'"><a href="mailto:fehin@example.com?subject=Please find attached toolbard link for your reference {{this.configSvc.activename}}&body=Find below a link for you to review {{this.configSvc.activeurl}}"><i class="material-icons icon-edit"  title="Email {{this.configSvc.hlitem[0]?.name}}" >email</i></a> </span>


  <span *ngIf="this.configSvc.activetype == 'fold' || this.configSvc.activetype == 'smartfoldertab'"> 
      
    <i class="material-icons icon-edit"  title="Move {{this.configSvc.hlitem[0]?.name}}"
     (click)="this.openDialog('moveFolder', this.configSvc.activename)">content_cut</i></span>

     <span *ngIf="this.configSvc.activetype != 'fold' && this.configSvc.activetype != 'smartfoldertab'"> 
    
      <i class="material-icons icon-edit"  title="Move {{this.configSvc.nodeds[0]?.name}}"
       (click)="this.openDialog('cutItem', this.configSvc.activename)">content_cut</i></span>

       <span *ngIf="this.configSvc.activetype == 'fold' && this.configSvc.move"> 
    
        <i class="material-icons icon-edit"  title="Paste {{this.configSvc.moveitem[0]?.name}}"
         (click)="this.openDialog('pasteItem', this.configSvc.moveitem[0]?.name)">content_paste</i></span>

         <span *ngIf="this.configSvc.folderdetailsds?.locktoolbar">
          <i class="material-icons icon-edit" title="Unlock toolbar" 
           (click)="this.openDialog('unlocktoolbar', '')">lock_open</i>
          </span>
  
          <span *ngIf="!this.configSvc.folderdetailsds?.locktoolbar">
            <i class="material-icons icon-edit" title="Lock toolbar" 
             (click)="this.openDialog('locktoolbar', '')">key</i>
            </span>

            <span *ngIf="this.configSvc.folderdetailsds?.kanbantagged">
              <i class="material-icons removekanban" title="Remove Kanban" 
               (click)="this.openDialog('removeKanban', '')">playlist_remove</i>
              </span>

              <span *ngIf="!this.configSvc.folderdetailsds?.kanbantagged">
                <i class="material-icons icon-edit" title="Apply Kanban" 
                 (click)="this.openDialog('applyKanban', '')">view_column</i>
                </span>


  
        </span>

        <span *ngIf="this.configSvc.toolmenu == 'toolbartools'">

          <span [matMenuTriggerFor]="activetoolmenu">
         <span *ngIf="this.configSvc.folderdetailsds.locktoolbar">
                <i class= "material-icons lockedtoolbar"   matTooltip="{{this.configSvc.folderdetailsds.toolbarname}} Toolbar Details "  
                    matTooltipPosition="right" [style.color]="this.configSvc.folderdetailsds.toolbarclr"
                   >{{this.configSvc.folderdetailsds.toolbaricon}}</i></span>
       

          <span *ngIf="!this.configSvc.folderdetailsds.locktoolbar"  
         
         >
            <i class= "material-icons lockedtoolbar"   matTooltip="{{this.configSvc.activetoolbar.name}} Toolbar Details "  
                matTooltipPosition="right" [style.color]="this.configSvc.activetoolbar.clr"
               >{{this.configSvc.activetoolbar.icontype}}</i></span>
              </span>

        <mat-menu #activetoolmenu="matMenu" yPosition="below" class= "box"> 

          <span *ngIf="!this.configSvc.folderdetailsds.locktoolbar" class="toolbar-title"> 
           
            <i MatPrefix class="material-icons icon-string"  [style.color]="this.configSvc.activetoolbar.clr"
             
            >{{this.configSvc.activetoolbar.icontype}}</i> <b>Tools available in the {{this.configSvc.activetoolbar.name}} toolbar</b>
          </span>

          <span *ngIf="this.configSvc.folderdetailsds.locktoolbar" class="toolbar-title"> 
           
            <i MatPrefix class="material-icons icon-string"  [style.color]="this.configSvc.folderdetailsds.toolbarclr"
             
            >{{this.configSvc.folderdetailsds.toolbaricon}}</i> <b>Tools available in the {{this.configSvc.folderdetailsds.toolbarname}} toolbar</b>
          </span>

          <span  *ngFor="let toolbar of configSvc.toolbaritems" >
          
          
            <button  mat-menu-item class ="templicons"  (click)="this.openDialog(toolbar.type, toolbar.name, toolbar)">
              <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="{{toolbar.name}}: {{toolbar.desc}} "
             
            >{{toolbar.icontype}}</i>{{toolbar.name}}: {{toolbar.desc}}
          
          </button>
        
        </span>
        
        </mat-menu>


          <span  *ngFor="let toolbar of configSvc.toolbaritems" >
          
          
              <span>
                <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="{{toolbar.name}}: {{toolbar.desc}} "
                (click)="this.openDialog(toolbar.type, toolbar.name, toolbar)"
              >{{toolbar.icontype}}</i></span>
            
            </span>
          </span>
      
        
  
            
             
  
  