import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import { PrefsService } from 'src/app/services/prefs.service';

@Component({
  selector: 'app-favlocations',
  templateUrl: './favlocations.component.html',
  styleUrls: ['./favlocations.component.css']
})
export class FavlocationsComponent implements OnInit {

  constructor(public configSvc: ConfigService, public prefSvc: PrefsService) { }

  ngOnInit(): void {
  }

}
