import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'app-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.css']
})
export class DeadlineComponent implements OnInit {

  deadlineFG = new FormGroup({
    deadlinefc: new FormControl(),
   
  });


  constructor(private _formBuilder: FormBuilder, public configSvc: ConfigService) { }


  ngOnInit(): void {

    if (this.configSvc.activetype != 'fold' ){
  //  this.deadlineFG.value.deadlinefc = this.configSvc.nodeds[0].deadlineval;
    this.deadlineFG.patchValue({deadlinefc:this.configSvc.nodeds[0].deadlineval});
    console.log('deadline value on init is '+this.deadlineFG.value.deadlinefc )}

    if (this.configSvc.activetype == 'fold' ){
      this.deadlineFG.patchValue({deadlinefc:this.configSvc.folderdetailsds.deadlineval});
      console.log('deadline value on init is '+this.deadlineFG.value.deadlinefc )}
  }
  
  clearDeadline(){
    console.log('clearing deadline')
    this.configSvc.clearDeadline(this.configSvc.nodeds[0]._id)

  }

  

}
