<form [formGroup]="fileFG">

  <span *ngIf = "data.type=='assign'">
    <div class = "space">
      <mat-form-field appearance="fill">
        <mat-label>Assign</mat-label>
       
        <input type="text"  
        formControlName="username"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"
   (onSelectionChange)="userSelected(user)"
   >
    {{user.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      </div>
  
    <button  *ngIf="this.configSvc.userready" mat-button  matSuffix 
    mat-raised-button color="primary" (click)="this.setAssignedto();this.dialogRef.close() ">Assign</button>
</span>


<span *ngIf = "data.type=='deadline'">
  <span class = "labels">
    <mat-label>Set Deadline for {{this.configSvc.nodeds[0].name}}?</mat-label>
   </span> 
  <mat-form-field appearance="fill">
  <input matInput [matDatepicker]="picker1" placeholder="Set Deadline"formControlName="deadlineval" >
       <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
       <mat-datepicker  #picker1></mat-datepicker>
     </mat-form-field>
  
     <button 
     (click)="this.setDeadline(this.fileFG.value.deadlineval)"
    
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Deadline">
  Set Deadline </button>
    </span>

  <span *ngIf = "data.type=='state'">

    <ng-template matStepLabel>Set State</ng-template>


    <span class = "labels">
      <mat-label> State</mat-label>
     </span> 

     <mat-form-field appearance="fill">
      <mat-label>State Label</mat-label>
      <input matInput  formControlName="statelabel">
    </mat-form-field>
 
       <button  mat-button matSuffix 
       mat-raised-button color="primary" (click)="this.setState(fileFG.value.statelabel,  this.fileFG.value.statevalue);this.dialogRef.close(); ">Set State and Close </button>
       
    

  </span>
  
    <span *ngIf = "data.type=='report'">
      <div mat-dialog-content>
        <i class="material-icons icon-report">picture_as_pdf</i> 
       Generate report for {{this.configSvc.activename}} ?
      </div>
      <div mat-dialog-actions>
        <span class= "genpopupbottom">
          Generating Folder Based report. Note this does not contain photos
          <div><mat-checkbox formControlName = "attachreport" >Save Report in Attachments</mat-checkbox></div>
          <div><button mat-button (click)="onNoClick()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Cancel">Cancel</button></div>
    
          <button mat-button (click)="folderReport(attachreport)" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Generate">Create Folder Report</button>
        
        </span>
        
      </div>
      </span>
  
  
   
     <span *ngIf = "data.type=='comment'"><i class="material-icons icon-commentpopup">chat</i>
      <span>
        <div>
          Comment on {{this.configSvc.activename}}
      
        </div>
    
    
        <mat-form-field appearance="outline"   class = "textarea">
          <textarea
          
          matInput formControlName="cmtval"></textarea>
         </mat-form-field>
      
      </span>
      <div>
        <mat-button-toggle-group formControlName="cmticon"  aria-label="Comment Icon" (change)="onIconChange($event.value)">
          <mat-button-toggle value="cmt"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
          <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
          <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
          <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
        </mat-button-toggle-group></div>
    
    <div >
    <mat-checkbox formControlName="emoji" >Change Emoji
      <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
      
      </mat-checkbox>
    
      
    
    </div>
    
    <div>
    <mat-checkbox formControlName="atcmt" ><i class="material-icons icon-customtoolbar" [ngStyle]="{'color': darkblue}">alternate_email</i>User
      
      
      </mat-checkbox>
    </div>
     
    
      <div *ngIf="this.fileFG.value.emoji">
        <span>
       <app-iconcategories></app-iconcategories></span>
       <span class ="colorbox">
        <app-color-picker (color)="this.configSvc.color"></app-color-picker>
      </span>
      </div>
    
      <div *ngIf="this.fileFG.value.atcmt" class = space>
    
          <mat-form-field appearance="fill">
            <mat-label>Reassign</mat-label>
           
            <input type="text"  
            formControlName="atcmtuser"
            [matAutocomplete]="auto"
            
            matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
       <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
        {{option.inboxemail}} 
      
       </mat-option>
            </mat-autocomplete>
      
           
           </mat-form-field>
          
     
     
     
     
      </div>

      <button mat-button (click)="addComment(false); this.dialogRef.close()" 
                       
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Add Comment">Add Comment</button>
       </span>

       <span *ngIf = "data.type=='askchatgpt'" class = "cmt">
        <div mat-dialog-actions>
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
  
          <mat-step [stepControl]="stepa">
            <ng-template matStepLabel>
             
          
             ChatGPT Request</ng-template>
       
         <span>
          
   
           <div  >
            Ask ChatGPT about <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >{{this.configSvc.nodeds[0].icontype}}</i> {{this.configSvc.activename}}
         
           </div>
       
       
           <mat-form-field appearance="outline"   class = "textarea">
             <textarea
             
             matInput formControlName="cmtval"></textarea>
            </mat-form-field>
         
         </span>
  
       
   
     <button mat-button matStepperNext (click)="addComment(true); " 
                         
     matSuffix 
     mat-raised-button color="primary"
     aria-label="Add Comment">Ask ChatGPT about file</button>
   
     
  
      </mat-step>
  
        <mat-step [stepControl]="stepb">
          <ng-template matStepLabel>
           
        
           ChatGPT Response</ng-template>
      
           <span *ngIf="!this.configSvc.chatgptresponse">
            <mat-spinner></mat-spinner>
           Waiting on response from ChatGPT......
          </span>
      
          <span *ngIf="this.configSvc.chatgptresponse">
            
             
            <div class="message__text">
              {{this.configSvc.summary}}
            </div>
           </span>
      
           <div>
            <button mat-button matSuffix 
            mat-raised-button color="primary"(click) = "this.getItembyId(); this.dialogRef.close();">Finish </button></div>
           </mat-step>
      
        </mat-horizontal-stepper>
        </div>
        </span>
  
       
 
   <div *ngIf="data.type=='editimage' ">

    <mat-form-field appearance="outline"   class = "textarea">
      <textarea
      
      cdkTextareaAutosize
      cdkAutosizeMinRows=this.textarearowmin
      cdkAutosizeMaxRows=this.textarearowmax
      
      matInput formControlName="cmtval" placeholder="Updated description to provide to ChatGPT"></textarea>
     </mat-form-field>


    
<div>
    <div  ><mat-form-field class="family" >
      <mat-label>Type</mat-label>
       <mat-select  formControlName="mt" required>
        <mat-option *ngFor="let type of mimetypes" [value]="type">
     {{type}} </mat-option> </mat-select> </mat-form-field></div>

     <div ><mat-form-field class="family" >
      <mat-label>Size</mat-label>
       <mat-select  formControlName="size" required>
        <mat-option *ngFor="let size of sizes" [value]="size">
     {{size}} </mat-option> </mat-select> </mat-form-field></div>
 

</div>

<div *ngIf="!fileFG.value.edit">
  <button mat-button (click)="editnewImage(this.fileFG.value.cmtval, this.fileFG.value.mt, this.fileFG.value.size); this.dialogRef.close()" 
                      
  matSuffix 
  mat-raised-button color="primary"
  aria-label="Add Comment">New image</button>
</div>

<div *ngIf="fileFG.value.edit">


<div>
  <button mat-button (click)="editexistingImage(this.fileFG.value.cmtval, this.fileFG.value.mt, this.fileFG.value.size); this.dialogRef.close()" 
                      
  matSuffix 
  mat-raised-button color="primary"
  aria-label="Add Comment">Edit existing image</button>
</div>
</div>
  </div>
  
  

     
   
   
       <span *ngIf = "data.type=='unlock'">
         <div mat-dialog-content>
           <i class="material-icons icon-locked">lock_open</i> Unlock {{this.configSvc.currentrow.name}}
          
         </div>
         <div mat-dialog-actions>
           <div  class = "droparea">
             <ngx-file-drop dropZoneLabel="Drop new version of file here"
              (onFileDrop)="dropversion($event, teamtags, foldertags)"
                       (onFileOver)="fileverOver($event)" (onFileLeave)="fileverLeave($event)"
                      ></ngx-file-drop>
  
                  
                       
           </div>
           <span *ngIf="this.configSvc.folderdetailsds.tagged">
             <mat-checkbox formControlName="foldertags">Index with Folder Tags</mat-checkbox></span>
             <span *ngIf="this.configSvc.teamdetailsds?.tagged">
             <mat-checkbox formControlName="teamtags">Index with Team Tags</mat-checkbox></span>
            
          <span class= "genpopupbottom">
           <button mat-button (click)="onNoClick()" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Close Upload">Finished Uploading</button></span>
          
           
         </div>
    </span>
   
 
   
     <span *ngIf = "data.type=='deletefile'">
       <div mat-dialog-content>
         <i class="material-icons icon-delete">delete</i> 
         Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
       </div>
       <div mat-dialog-actions>
         <span class= "genpopupbottom">
           <button mat-button (click)="onNoClick()" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Cancel">Cancel</button>
     
           <button mat-button (click)="delete('item')" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Delete">Delete</button>
         
         </span>
         
       </div>
       </span>
  
     
  
        <span *ngIf = "data.type=='cutItem'">
          <div mat-dialog-content>
            <i class="material-icons icon-delete">content_cut</i> 
            Are you sure you want to move file {{this.configSvc.currentrow.name}} ?
          </div>
          <div mat-dialog-actions>
            <span class= "genpopupbottom">
              <button mat-button (click)="onNoClick()" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cancel">Cancel</button>
        
              <button mat-button (click)="cutItem(this.configSvc.currentrow._id, 
              this.configSvc.currentrow.type, this.configSvc.currentrow.level, 
              this.configSvc.currentrow.teamid, this.configSvc.currentrow.name, 
              this.configSvc.currentrow.icontype, this.configSvc.currentrow.iconcolor, this.configSvc.folderdetailsds.kanbantagged)" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cut Item">Cut Item</button>
            
            </span>
            
          </div>
          </span>
  
          <span *ngIf = "data.type=='pasteItem'">
            <div mat-dialog-content>
              <i class="material-icons icon-delete">content_paste</i> 
              Are you sure you want to paste {{this.configSvc.moveitem[0].name}} ?
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="pasteItem(this.configSvc.moveitem[0]?.itemid, this.configSvc.activeitemid,
                this.configSvc.moveitem[0]?.type,this.configSvc.moveitem[0]?.level,
                 this.configSvc.moveitem[0]?.teamid, this.configSvc.moveitem[0]?.name, 
                 this.configSvc.moveitem[0]?.icontype, this.configSvc.moveitem[0]?.iconcolor, this.configSvc.folderdetailsds.kanbantagged)" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Paste Item">Paste Item</button>
              
              </span>
              
            </div>
            </span>
  
  </form>
   
       