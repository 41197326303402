
        <span [formGroup]="detailsFG" class = "details-container"
        
        >
           
        <div>  
          <mat-form-field appearance="fill" floatLabel="always" > 
            <mat-label>Title</mat-label>
                <input formControlName="title" matInput value="{{this.configSvc.nodeds[0].ti}}">
               </mat-form-field>
            </div> 

            <div><mat-form-field appearance="outline" floatLabel="always">
                <mat-label >NodeName</mat-label><input matInput [readonly]= "true"  matInput value={{this.configSvc.nodeds[0].nodename}}>
             </mat-form-field></div>
            

              <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Added By</mat-label><input matInput [readonly]= "true" matInput value={{this.configSvc.nodeds[0]?.ab}}>
                </mat-form-field> </div>

                <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="this.configSvc.nodeds[0]?.ao | date: 'medium'"
                  >
                </mat-form-field> </div>

                <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Modified By</mat-label><input matInput [readonly]= "true" matInput value={{this.configSvc.nodeds[0]?.mb}}>
                </mat-form-field> </div>

                <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Modified On</mat-label><input matInput [readonly]= "true" 
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="this.configSvc.nodeds[0]?.mo | date: 'medium'"
                  >
                </mat-form-field> </div>

            

                   <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label >Type</mat-label><input matInput [readonly]= "true" formControlName="type" matInput value={{this.configSvc.nodeds[0]?.type}}>
                     </mat-form-field>
                     </div>
                   
                   <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                      <mat-label >Parentid</mat-label><input matInput [readonly]= "true" formControlName="parentid" matInput value={{this.configSvc.nodeds[0]?.parentid}}>
                     </mat-form-field>
                     </div>

                 

                       <div>
                        <mat-form-field appearance="outline" floatLabel="always">
                          <mat-label >Description</mat-label><input matInput [readonly]= "true" formControlName="desc" matInput value={{this.configSvc.nodeds[0]?.desc}}>
                         </mat-form-field>
                         </div>

                         <div>
                            <mat-form-field appearance="outline" floatLabel="always">
                              <mat-label >Based On Node</mat-label><input matInput [readonly]= "true" formControlName="basedon" matInput value={{this.configSvc.nodeds[0]?.bo}}>
                             </mat-form-field>
                             </div>
             
              </span>

              <div> <button  [disabled]= "!this.detailsFG.dirty" (click)="this.renameItem(this.detailsFG.value.title, this.configSvc.activeitemid);"
                mat-raised-button color="primary" [disabled]="!detailsFG.dirty">Apply Changes</button>
             </div>