<span *ngIf=" !this.configSvc.hidebrowser" class ="container">

        
    <mat-card class ="browserpane">
  
                  
      <table mat-table [dataSource]="this.configSvc.cachedteamds">
    
           <!--  <tr mat-header-row *matHeaderRowDef="projectColumns; sticky: true" ></tr> -->
    
             <tr mat-row *matRowDef="let selectedrow; columns: browseColumns; let i = index;"
           
             ></tr>
             
  
           
    
               <!-- MimeType Column -->
               <ng-container matColumnDef="MimeColumn">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                      <div  *ngIf="element.type == 'team'"> <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >{{element.icontype}} </i></div>
                   <span *ngIf="element.ts"><i class="material-icons md-24" [ngStyle]="{'color': element.clr || 'black'}" >loyalty</i></span>
                   <div  *ngIf="element.type == 'fold' && element.tagged == false">
                     
                     <i class="material-icons md-24"[ngStyle]="{'color': element.iconcolor || 'black'}" 
                     matTooltip="{{element.desc}}"  matTooltipPosition="right" 
                     >{{element.icontype}} </i>
                    </div>
                   <div  *ngIf="element.type == 'fold' && element.tagged == true">
                     
                      <span class="fa-layers fa-fw">
                        <i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >folder</i>
                  
                    
                  </span>
                  </div>
                  
                  
     
                     <div  *ngIf="element.type  == 'basicflow' || element.type =='formassign' || element.type =='stepperassign' || element.type =='tabbedassign'" matTooltip="Dynamic Form">
                      <i class="material-icons md-24" [ngStyle]="{'color': element.iconcolor}" >{{element.icontype}}</i>
                      
                      </div>
                   
                     <div  *ngIf="element.type  == 'file'">
  
                    
                        
                           
                           <div *ngIf="element.mt == 'pdf'" class="fa-2x"  matTooltip="PDF"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i></span></div>
                           <div *ngIf="element.mt == 'msg'" class="fa-2x"  matTooltip="Email"><span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span>
                               <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i><span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div>
                           </div>

                           
                            <div *ngIf="element.mt == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                           <div *ngIf="element.mt == 'xlsx'" class="fa-2x"  matTooltip="Excel"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                           <div *ngIf="element.mt == 'pptx' && element.ver==1" class="fa-2x"  matTooltip="PowerPoint"><span class="fa-layers fa-fw">
                            <span><i class="fas fa-file-powerpoint" data-fa-transform="shrink-5" style="color:red"></i></span></span>
                          </div>
                           <div *ngIf="element.mt == 'docx' && element.ver==1" class="fa-2x"  matTooltip="Word"><span class="fa-layers fa-fw">
                                   <span><i class="fas fa-file-word" data-fa-transform="shrink-5" style="color:blue"></i></span></span>
                                 </div>
                             <div *ngIf="element.mt == 'docx' && element.ver>1" class="fa-2x"  matTooltip="Word"><span class="fa-layers fa-fw">
                                    <i class="fas fa-file-word versioned" data-fa-transform="shrink-5 up-3 left-5 " style="color:blue"></i>
                                   <i class="fas fa-file-word" data-fa-transform="shrink-5"  style="color:blue"></i>
                                      </span></div>
                           <div *ngIf="element.mt == 'bmp' || element.mt == 'png'  || element.mt == 'jpeg'  || element.mt == 'jpg' || element.mt == 'gif' " class="fa-2x"  matTooltip="Image"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
                         
                     </div>
  
                     <div *ngIf="element.type  == 'node' || element.type  == 'nodeimage' " >
                      <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}"  matTooltip="{{element.bo}}" >{{element.icontype}}</i>
                     </div>

                     <div *ngIf="element.type  == 'chatgpt' " >
                      <span *ngIf="element.icontype  != 'contact_support' " >
                        <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"
            
                        ></mat-icon></span>

                        <span *ngIf="element.icontype  == 'contact_support' " >
                          <i class="material-icons md-48" [ngStyle]="{'color': 'green'}" 
                          matTooltip="{{element.bo}}" >{{element.icontype}}</i></span>
                     </div>

                    

                 

                     <div *ngIf="element.type  == 'nodetab'" >
                      <i  class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" matTooltip="{{element.bo}}"  >{{element.icontype}}</i>
                  
                       
                     </div>

                     <div *ngIf="element.type  == 'smartfolder'" >
                      <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}" matTooltip="{{element.bo}}" >{{element.icontype}}</i>
                     </div>

                  

                     <div *ngIf="element.type  == 'smartfoldertab'" >
                      <i class="material-icons md-48" [ngStyle]="{'color': element.iconcolor || 'black'}"  matTooltip="{{element.bo}}"  >{{element.icontype}}</i>
                     </div>
  
                     
  
                     
                      <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i> <span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div> 
                     
                  
                  </td>
              </ng-container>
    
              <!--Team Column -->
            <ng-container matColumnDef="ItemColumn">
              <th mat-header-cell *matHeaderCellDef></th>
    
              <td mat-cell *matCellDef="let element; let itemindex = index">

             
              <a *ngIf="element.type == 'team'" fxFlexFill
             
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element._id}"
             
              >
              <div *ngIf="element.type == 'team'" 
              (click)="this.configSvc.currentteam=element; setTeamtool()"> 
              
              <span style="cursor: pointer">{{element.name}} </span></div>
              
             
              </a>

            
  
              <a *ngIf="element.type == 'fold'" fxFlexFill
             
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
         
              >
            
              <div *ngIf="element.type == 'fold'" (click)="this.configSvc.activetype='fold';
          
               this.configSvc.typeenabled = true;
              this.configSvc.itemlocked = false; this.configSvc.currentrow=element;">   <span style="cursor: pointer">{{element.name}} </span>
              
              <span *ngIf="element.locktoolbar == true">
                <i class="material-icons locktoolbar"   [ngStyle]="{'color': element.toolbarclr}" matTooltip="Toolbar: {{element.toolbarname}} "  matTooltipPosition="right">{{element.toolbaricon}} </i>
              </span>
              <span *ngIf="element.tagged== true">
                <i class="material-icons icon-size" [ngStyle]="{'color': element.tagcolor}">bookmarks</i></span>
                 
                <span *ngIf="element.cmt== true">
                 <span *ngIf="element.ischatgpt  != true "><i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i></span> 
                
                  <div *ngIf="element.ischatgpt  == true " >
                    <span>
                      <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"
          
                      ></mat-icon></span>
                   </div>
                </span> 
                  
                  <span *ngIf="element.counttotals" class ="status-kanban">
                    <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >calculate </i>
                    <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">${{element.total}}</span>
                  </span>

                  <span *ngIf="element.assigned== true">
                    <i class="material-icons icon-size" [ngStyle]="{'color': 'purple'}" 
                    matTooltip="Assigned to {{element.assignedto}}"  matTooltipPosition="right">person_pin_circle</i></span>
                    
                      <span *ngIf="element.deadline== true">
                    <span *ngIf="element.deadlineval > this.dateTime">
                     <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}" matTooltip="Deadline Set"  matTooltipPosition="right">timer</i></span>
                               <span *ngIf="element.deadlineval < this.dateTime">
                           <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}" matTooltip="Deadline Expired"  matTooltipPosition="right">timer</i></span></span>
                  
                  <span *ngIf="element.kanbantagged == true">
                  <span *ngIf="element.kantype == 'standard'">
                    <i class="material-icons status-kanban"   [ngStyle]="{'color': element.kanbancolor}" matTooltip="Kanban {{element.kanbanname}} "  matTooltipPosition="right" >view_column </i>
                    <span >
                  

                      
                        <span *ngIf="element.kanbanpercent==100">
                          <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" matTooltip=" Kanban {{element.kanbanname}} Completed"  matTooltipPosition="right">check_circle </i>
                        </span>

                        <span *ngIf="element.kanbanpercent==0">
                          <i class="material-icons status-kanban"   [ngStyle]="{'color': 'red'}" matTooltip="Not Started"  matTooltipPosition="right">cancel </i>
                        </span>
                        
                        <span *ngIf="element.kanbanpercent!=100 && element.kanbanpercent!=0"  >
                         
                            <span matBadge="{{math.round(element.kanbanpercent)}}" matBadgeOverlap="false" 
                            matBadgePosition="after" matTooltip="In Progress"  matTooltipPosition="right"></span> 
      
                            <span *ngIf="element.nodetabkanban && element.nodetabpercent != 100">
                 
                           
                              <i matBadge="{{element.nodetabpercent}}" matBadgeOverlap="true" matBadgeSize = "small" 
                              matBadgePosition="above"  ></i></span>
                        
                        </span>
                         
                 

                      </span>
                    </span>

                    <span *ngIf="element.kantype == 'time'">
                      <i class="material-icons status-kanban"   [ngStyle]="{'color': element.kanbancolor}" >schedule </i>
                      <span  matBadge="{{element.time}}" matBadgeOverlap="false" 
                      matBadgePosition="after" >
                    
                      <span *ngIf="setotoal == true" >  
                        <i class="material-icons status-kanban"   [ngStyle]="{'color': element.kanbancolor}" >schedule </i> </span>
                    </span> 

                
                    

 </span>



 <span *ngIf="element.kantype == 'moneydollars'" class ="status-kanban">
  <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >monetization_on </i>
  <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">{{element.money}}</span>
</span>



</span>






                </div>
             
              </a>

              <a *ngIf="element.type == 'smartfolder'" fxFlexFill
             
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
             
              >
            
             
             
              </a>
              <a *ngIf="element.type == 'smartfolder'" fxFlexFill
             
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
             
              >
            
              <div *ngIf="element.type == 'smartfolder'" (click)="this.configSvc.activetype='smartfolder';
          
               this.configSvc.typeenabled = true;
              this.configSvc.itemlocked = false; this.configSvc.currentrow=element;">   <span style="cursor: pointer">{{element.name}} </span>
              
                <span *ngIf="element.cmt== true">
                  <i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i></span>  
                  
                
                </div>
             
              </a>

              <a *ngIf="element.type == 'smartfoldertab'" fxFlexFill
             
              [routerLink]="['/toolbard/home']"
              [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}"
             
              >
            
              <div *ngIf="element.type == 'smartfoldertab'" (click)="this.configSvc.activetype='smartfoldertab';
          
               this.configSvc.typeenabled = true;
              this.configSvc.itemlocked = false; this.configSvc.currentrow=element;">  <span style="cursor: pointer">{{element.name}} </span>
              
                <span *ngIf="element.cmt== true">
                  <i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i></span>  
                  
                
                </div>
             
              </a>
              
              
           
  
              <div *ngIf="element.type == 'file'" class = "filehover"
              (click)="this.clickFile(element, itemindex)"
              [style.background]=" this.configSvc.nodeds.indexOf(element) != -1 ? 'lightblue' : ''"
              > {{element.ti}} 
              <span *ngIf="element.tagged== true">
                <i class="material-icons bookmarked" [ngStyle]="{'color': element.tagcolor}">bookmarks</i></span> 
                <span *ngIf="element.counttotals" class ="status-kanban">
                  <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >calculate </i>
                  <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">${{element.total}}</span>
                </span>
                <span *ngIf="element.cmt== true">
                  <span *ngIf="element.ischatgpt  == true " >
                      <mat-icon class="chatlogo"  title="ChatGPT" svgIcon="chat2"
                      ></mat-icon></span>
                      <span *ngIf="element.ischatgpt  != true " >
                  <i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i></span></span> 
                  <span *ngIf="element.assigned== true">
                    <i class="material-icons icon-adssigned">person_pin_circle</i></span>

                    <span *ngIf="element.deadline== true">
                      <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">timer</i></span>

                      <span *ngIf="element.rating != 0" >
                        <mat-icon class="yellow-icon" matBadge="{{element.rating}}" matBadgeSize="small">
                          star
                 </mat-icon> 
                      </span>

                      <span *ngIf="element.kanbantagged == false">
                      <span *ngIf="element.statetracker== true && element.state== true">
                        <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">check_circle </i></span>
                        <span *ngIf="element.statetracker== true && element.state== false">
                          <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">toggle_off </i></span>
                        </span>
                        <span *ngIf="element.kanbantagged == true && element.kantype == 'standard'">
                          <span *ngIf="element.kanbanpercent > 0 && element.kanbanpercent < 100 ">
                           
                           
                             <span class ="percentage"  matTooltip="@{{element.kanbanpercent}}%" > <i class="material-icons icon-size" [ngStyle]="{'color': 'orange'}">pending</i> </span></span>
                          <span *ngIf="element.kanbanpercent == 100" ><i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">check_circle</i></span>
                            <span *ngIf="element.kanstate == 0"> <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}">cancel </i></span>
            
                          
                          </span>

                          <span *ngIf="element.kanbantagged == true && element.kantype == 'moneydollars'" class ="status-kanban">
                            <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >monetization_on </i>
                            <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">{{element.total}}</span>
                          </span>

                          
              
                            
                            
                          <span *ngIf="element.nodetabkanban && element.nodetabpercent != 100">
           
                     
                            <i matBadge="{{element.nodetabpercent}}" matBadgeOverlap="true" matBadgeSize = "small" 
                            matBadgePosition="above"  ></i></span>

                     


                    <span *ngIf="element.ed== true">
                      <i class="material-icons icon-locked" title="Locked for Editing">lock</i>
                    </span>
            </div>
  
            
  
              <div *ngIf="element.type == 'basicflow' || element.type =='formassign' || element.type =='stepperassign' || element.type =='tabbedassign'" 
              (click)=" this.getActiveflow(element) "
              
              [style.background]=" this.configSvc.hlitem.indexOf(element) != -1 ? 'lightblue' : ''"
              >   <span style="cursor: pointer">{{element.name}} </span>
              <span *ngIf="element.attach == true" > 
                <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">attach_file</i></span>

                

                <span *ngIf="element.deadline== true">
                  <span *ngIf="element.deadlineval > this.dateTime">
                  <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">timer</i></span>
    
                  <span *ngIf="element.deadlineval < this.dateTime">
                    <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}">timer</i></span></span>

                    <span *ngIf="element.statetracker== true && element.state== true">
                      <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">check_circle </i></span>
                      <span *ngIf="element.statetracker== true && element.state== false">
                        <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">toggle_off</i></span>

              <span *ngIf="element.status == 0" > 
                <i class="material-icons status-notstarted">pause_circle_outline </i>
              </span>
      
              <span *ngIf="element.status == 1"> 
                <i class="material-icons status-inprogress" >history</i>  
              </span>
      
              <span *ngIf="element.status == 2" > 
                <i class="material-icons status-completed">check_circle_outline </i>
              </span>

              <span *ngIf="element.status == 3" > 
                <i class="material-icons status-rejected">cancel</i>
              </span>

        
       
             
           
            
            </div>
  
            <div *ngIf="element.type == 'node' || element.type == 'nodetab'  || element.type == 'chatgpt' ||  element.type == 'nodeimage'"  
            (click)="this.clickNode(element, element.type, itemindex); this.configSvc.currentrow=element;"
       
            [style.background]=" this.configSvc.nodeds?.indexOf(element) != -1 ? 'lightblue' : ''"
            class ="itemstyle">
             
  <span style="cursor: pointer">{{element.name}} </span>
 
     
  <span *ngIf="element.tagged== true">
    <i class="material-icons icon-size"  [ngStyle]="{'color': element.tagcolor}">bookmarks</i></span> 
    <span *ngIf="element.cmt== true">
      <i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i></span> 
      <span *ngIf="element.counttotals" class ="status-kanban">
        <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >calculate </i>
        <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">${{element.total}}</span>
      </span>

      <span *ngIf="element.attach== true">
        <span *ngFor="let attachment of element.attachs">
      <span *ngIf="attachment.mt == 'attachnode'"> <i class="material-icons icon-size" [ngStyle]="{'color': attachment.iconcolor}">{{attachment.icontype}}</i> </span>
      <span *ngIf="attachment.mt != 'attachnode'"> <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">attach_file</i> </span>        
        </span>
      </span>

      <span *ngIf="element.rating != 0" >
        <mat-icon class="yellow-icon" matBadge="{{element.rating}}" matBadgeSize="small">
          star
 </mat-icon> 
      </span>
        
        <span *ngIf="element.photo== true">
          <i class="material-icons icon-size" [ngStyle]="{'color': 'gray'}">photo_camera</i></span>
          <span *ngIf="element.assigned== true">
            <i class="material-icons icon-assigned">person_pin_circle</i></span>
            <span *ngIf="element.statetracker== true && element.state== true">
              <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">check_circle </i></span>
              <span *ngIf="element.statetracker== true && element.state== false">
                <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">toggle_off</i></span>

<span *ngIf="element.kanbantagged == true">
            <span *ngIf="element.kantype == 'standard'">
              <span *ngIf="element.kanbanpercent > 0 && element.kanbanpercent < 100 "> 
                <span class ="percentage"  matTooltip="@{{element.kanbanpercent}}%" > <i class="material-icons icon-size" [ngStyle]="{'color': 'orange'}">pending</i> </span></span>
              <span *ngIf="element.kanbanpercent == 100" ><i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">check_circle</i></span>
                <span *ngIf="element.kanstate == 0"> <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}">cancel </i></span>

              </span>

        

              <span *ngIf="element.kantype == 'moneydollars'" class ="status-kanban">
                <i class="material-icons status-kanban"   [ngStyle]="{'color': 'green'}" >monetization_on </i>
                <span [ngStyle]="{ 'font-size': '12px', 'font-weight': 'bold'}">{{element.total}}</span>
              </span>

            </span>
              <span *ngIf="element.nodetabkanban && element.nodetabpercent != 100">
           
                     
                <i matBadge="{{element.nodetabpercent}}" matBadgeOverlap="false" matBadgeSize = "medium" 
                matBadgePosition="after"  ></i></span>

                <span *ngIf="element.nodetabkanban && element.nodetabpercent == 100 && element.kanbantagged == false">
           
                  <i class="material-icons icon-size" [ngStyle]="{'color': element.kanbancolor}">check_circle</i>
                 
                
                </span>
            

            <span *ngIf="element.textracted == true && element.textractype == 'form'">
              <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">fact_check</i></span>

              <span *ngIf="element.textracted == true && element.textractype == 'invoice'">
                <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">receipt_long</i></span>

            <span *ngIf="element.deadline== true">
              <span *ngIf="element.deadlineval > this.dateTime">
              <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">timer</i></span>

              <span *ngIf="element.deadlineval < this.dateTime">
                <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}">timer</i></span></span>
      
    </div>

    
  

           
                </td>
            </ng-container>
  
         
    
    
        </table>

        <span *ngIf="this.configSvc.teamds?.length == 0">
          <i>This folder is empty</i>
          
        </span>

        <span *ngIf="this.configSvc.folderdetailsds?.fldrcnt > 100 && this.configSvc.activetype == 'fold'">
          <i>Return more folders via filter above</i>
          
        </span>

        <span *ngIf="this.configSvc.teamdetailsds?.fldrcnt > 100 && this.configSvc.activetype == 'editteam'  ">
          <i>Return more root folders via filter above</i>
          
        </span>
        <span (click)="deselect()" class = "deselect">
          
                  
        </span> 
            
       
      </mat-card>
    
   
<mat-card *ngIf="this.configSvc.activetype== 'editteam' && this.configSvc.teamdetailsready" 
                [ngClass]= "
                {'edittypelrg': this.configSvc.fullpage == true,
                 'edittypesml': this.configSvc.fullpage == false} "
                >
              
        <app-editteam></app-editteam>
                </mat-card>
  
               
          
                <mat-card *ngIf="this.configSvc.folderready && (this.configSvc.activetype =='fold' || this.configSvc.activetype =='smartfolder' 
                || this.configSvc.activetype =='stepperfolder' || this.configSvc.activetype =='smartfoldertab') "
                   class = "folderdetails">
                      
                    <app-editfolder></app-editfolder>
                </mat-card>        

                  
          


            <span *ngFor="let r of reset" >
              <mat-card *ngIf="this.configSvc.flowready && this.configSvc.activetype =='activeflow'"
              [ngClass]= "
              {'edittypelrg': this.configSvc.fullpage == true,
               'edittypesml': this.configSvc.fullpage == false } "
               >
               <app-flowstatus></app-flowstatus> 
    </mat-card></span>
  <span *ngIf="this.configSvc.nodeready">
                  <span *ngIf="this.configSvc.activetype =='node' || this.configSvc.activetype =='chatgpt' || this.configSvc.activetype =='nodetab' ">
                    <mat-card   [ngClass]= "
                    {'edittypelrg': this.configSvc.fullpage == true,
                     'edittypesml': this.configSvc.fullpage == false } ">
                    <mat-tab-group [(selectedIndex)]="this.configSvc.tabIndex">
                     
                      <mat-tab label="Node Item" *ngIf="this.configSvc.nodeds[0].type !='chatgpt'">
                        <ng-template mat-tab-label>
                          <span >
                          <i class="material-icons icon-tab" 
                          [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> </span>
                     
                          </ng-template>
                          <span  *ngIf="this.configSvc.activetype =='node'">
                  <span *ngFor="let r of reset" >
                    <app-activenode></app-activenode>
                  </span></span>

           

                    <span  *ngIf="this.configSvc.activetype =='nodetab'">
                      <span *ngFor="let r of reset" >
                        <app-activetabs></app-activetabs>
 </span></span>

 <span *ngIf="this.configSvc.activetype =='chatgpt' && this.configSvc.nodeready">
  <mat-card
  [ngClass]= "
  {'edittypelrg': this.configSvc.fullpage == true,
   'edittypesml': this.configSvc.fullpage == false } "
   >
   <app-filesummary></app-filesummary> 
</mat-card></span>




                     


                  </mat-tab>
                  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
                    <ng-template mat-tab-label>
                      <span *ngIf="this.configSvc.nodeds[0].mb !='chatgpt' " >
                      <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}" >
                        {{this.configSvc.nodeds[0]?.cmticon}}</i>
                        </span>

                        <span *ngIf="this.configSvc.nodeds[0].mb =='chatgpt' " >
                         
                            <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"
                            ></mat-icon>
                          </span>
                     
                      </ng-template>
                    <span class= "comments">
                        <app-viewcomments></app-viewcomments></span>
                  
                  </mat-tab>




                  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.nodetabkanban == true" label="Kanban">
                    <ng-template mat-tab-label>
                    <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.kanbancolor}" >
                        view_column</i>
                       {{this.configSvc.nodeds[0].kanbanname}}  {{this.configSvc.nodeds[0].nodetabpercent}}% 
                     
                      </ng-template>
                    <span *ngFor="let r of reset" class= "comments">
                        <app-kanban></app-kanban></span>
                  
                  </mat-tab>
                
  
       

            <mat-tab  *ngIf="this.configSvc.nodeds[0]?.photo == true" label="Photos">
              <ng-template mat-tab-label>
                <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >photo_camera</i>
               
                </ng-template>
              <span class= "comments">
                  <app-nodephotos></app-nodephotos></span>
            
            </mat-tab>

            <mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Textraction">
              <ng-template mat-tab-label>
                <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'form'">
                  <i class="material-icons icon-size" [ngStyle]="{'color': 'green'}">fact_check</i></span>
    
                  <span *ngIf="this.configSvc.nodeds[0]?.textractype == 'invoice'">
                    <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">receipt_long</i></span>
               
                </ng-template>
              <span class= "comments">
           <app-textraction></app-textraction></span>
            
            </mat-tab>

            <mat-tab  *ngIf="this.configSvc.nodeds[0]?.textracted == true" label="Lineitems">
              <ng-template mat-tab-label>
                <span >
                  <i class="material-icons icon-size" [ngStyle]="{'color': 'black'}">format_list_numbered</i></span>
    
                  
               
                </ng-template>
              <span class= "comments">
           <app-lineitems></app-lineitems></span>
            
            </mat-tab>

            <mat-tab  *ngIf="this.configSvc.nodeds[0]?.attach == true" label="Attachments">
              <ng-template mat-tab-label>
                <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >attach_file</i>
                
                </ng-template>
              <span class= "comments">
                  <app-nodefiles></app-nodefiles></span>
            
            </mat-tab>
            <mat-tab  *ngIf="this.configSvc.nodeds[0]?.assigned == true" label="Assigned">
              <ng-template mat-tab-label>
                <i class="material-icons icon-assigned">person_pin_circle</i>
              
                </ng-template>
              <span class= "comments">
                <app-assigned></app-assigned>
              </span>
              </mat-tab>

              <mat-tab  *ngIf="this.configSvc.nodeds[0]?.deadline == true && this.configSvc.nodeready" label="Deadline">
                <ng-template mat-tab-label>
                 <span> <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >timer</i></span>
                 
                
                  </ng-template>
                <span class= "comments">
                  <app-deadline></app-deadline>
                </span>
                </mat-tab>

              <mat-tab  *ngIf="this.configSvc.nodeds[0]?.tagged == true" label="Tagged">
                <ng-template mat-tab-label>
                  <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.tagcolor}" >bookmark</i>
                  
                  </ng-template>
                <span class= "comments">
                  <app-foldertags [indexlaunch]="'edit'" ></app-foldertags>
                </span>
                </mat-tab>
                <mat-tab label="Details" >
  
                  <ng-template mat-tab-label>
                    <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>
                    
                    </ng-template>
                
                      <app-nodedetails></app-nodedetails>
     </mat-tab>
         
                </mat-tab-group>
              </mat-card>
                </span></span>

                <span *ngIf="this.configSvc.activetype =='file' && this.configSvc.nodeready">
                  <mat-card
                  [ngClass]= "
                  {'edittypelrg': this.configSvc.fullpage == true,
                   'edittypesml': this.configSvc.fullpage == false } "
                   >
                   <app-editfile></app-editfile> 
        </mat-card></span>

     

                <span *ngIf="this.configSvc.activetype =='nodeimage' && this.uploadSvc.imageready ">
                  <mat-card   [ngClass]= "
                  {'edittypelrg': this.configSvc.fullpage == true,
                   'edittypesml': this.configSvc.fullpage == false } ">
                  <mat-tab-group>
                   
                    <mat-tab label="Node Item">
                      <ng-template mat-tab-label>
                        <i class="material-icons icon-tab" 
                        [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i> 
                      
                        </ng-template>
                <span *ngFor="let r of reset" >
                  <app-activenode></app-activenode>
                </span>
                </mat-tab>

                <mat-tab label="Details" >

                  <ng-template mat-tab-label>
                    <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>
                  
                    </ng-template>
                
                      <app-nodedetails></app-nodedetails>
     </mat-tab>

           <mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
            <ng-template mat-tab-label>
              <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}" >{{this.configSvc.nodeds[0]?.cmticon}}</i>
        
              </ng-template>
            <span class= "comments">
                <app-viewcomments></app-viewcomments></span>
          
          </mat-tab>

          <mat-tab label="Versions">
            <ng-template mat-tab-label>
              <i class="material-icons md-24" [ngStyle]="{'color': 'gray'}" >collections</i>
           
              </ng-template>
            <span class= "comments">
                <app-imageversions></app-imageversions></span>
          
          </mat-tab>

        

       
              </mat-tab-group>
            </mat-card>
              </span>
  
            

          
                    
          <span *ngIf="this.configSvc.activetype =='root'">
            Welcome to {{ authService.getUserDetails()?.companyname}}
            <span class= "home">
            Made with Toolbard
            <i class="material-icons icon-createteamactive"  title="Create a new Team"
            (click)="this.openDialog('createteam', 'Team Name')">build_circle</i>
          </span>
            Please click on one of the teams on the left hand side to begin.
          </span>  
  
           
           </span>
  

  
  <div  *ngIf="this.configSvc.activetool =='kanban'">
  <app-tagerbuilder></app-tagerbuilder>
  </div>
  
  <div *ngIf="this.configSvc.activetool == 'team'">
     <app-createteam></app-createteam>
    </div>
  
    <div  *ngIf="this.configSvc.activetype =='nodedirect' && this.configSvc.nodeready">
      <app-nodedirect></app-nodedirect>
      </div>

      <div  *ngIf="this.configSvc.activetype =='chatgptdirect' && this.configSvc.nodeready">
        <app-chatgptdirect></app-chatgptdirect>
        </div>

      <div  *ngIf="this.configSvc.activetype =='nodefeed' && this.configSvc.tageritemready">
        <app-nodedirect></app-nodedirect>
        </div>

      <div  *ngIf="this.configSvc.activetype =='imagedirect' && this.configSvc.tageritemready">
        <app-activenode></app-activenode>
        </div>



        <div  *ngIf="this.configSvc.activetype =='tabbeddirect' && this.configSvc.nodeready">
          <app-nodedirect></app-nodedirect>
          </div>

      <div  *ngIf="this.configSvc.activetype =='filedirect' && this.configSvc.fileready">
        <app-editfile></app-editfile>
        </div>

     

  
  <div  *ngIf="this.configSvc.activetool =='tagerseq' || this.configSvc.activetool =='tagerpar' 
  || this.configSvc.activetool =='tageritem' || this.configSvc.activetool =='tagerkanban' || this.configSvc.activetool =='foldertags'   ">
    <app-applytager></app-applytager>
    </div>
  
  <div  *ngIf="this.configSvc.activetool =='applytags'">
    <app-applytags ></app-applytags>
  </div>
  
  <div  *ngIf="this.configSvc.activetool =='tagbuilder'">
    <app-tagbuilder></app-tagbuilder>
  </div>
  
  
  



  