<span>
 
  
    <h4> <i class="material-icons icon-kanban" 
      [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}" >
     {{ this.configSvc.nodeds[0]?.icontype}} </i>Kanban Details </h4>
      <mat-card  class = "details-container">
    <form [formGroup]="editkanbanFG">

    
    <span>
            <mat-form-field appearance="fill"> 
              
                   <mat-label >Kanban Name</mat-label>
                   <input formControlName="name" matInput value="{{this.configSvc.nodeds[0]?.name}}">
                  </mat-form-field></span>
               <div>  <mat-form-field appearance="fill"> 
                  <mat-label >Kanban Description</mat-label>
                  <input formControlName="desc" matInput value="{{this.configSvc.nodeds[0]?.desc}}">
                 </mat-form-field></div> 
    <span>
    <mat-form-field appearance="outline" floatLabel="always" >
                  
      <mat-label >Added By</mat-label>
      <input 
      matInput [readonly]= "true" 
      formControlName="addedby">
    </mat-form-field> </span>
    <div><mat-form-field appearance="outline" floatLabel="always" >
              
      <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
      [ngModelOptions]="{standalone: true}"
      [ngModel]="this.configSvc.nodeds[0]?.ao | date: 'medium'"
      >
    </mat-form-field> </div>

  


    <mat-card class="tracktypes">
      <mat-card-header>
  

        <mat-card-subtitle>Track</mat-card-subtitle>
      </mat-card-header>
      <mat-radio-group formControlName ="kantype" >
    <span>
        <mat-radio-button value="standard" 
        checked= 'standard'
        > State</mat-radio-button></span>
        <span>
        <mat-radio-button value="time" 
        checked= 'time'
        > Time</mat-radio-button></span>
      <span>
        <mat-radio-button value="moneydollars" 
        checked= 'moneydollars'
        > Dollars</mat-radio-button></span>
      <span> <mat-radio-button value="moneypounds" 
        checked= 'moneypounds'
        > Pounds</mat-radio-button></span>
      
        <span *ngIf="this.editkanbanFG.value.kantype  == 'time'" ><mat-checkbox formControlName = "settotal" >
          Set total</mat-checkbox>
        <span *ngIf="this.editkanbanFG.value.settotal">
          <mat-form-field appearance="fill">  
            <input formControlName="timetotal" type = "number" matInput value="{{this.editkanbanFG.value.timetotal}}">
          </mat-form-field></span>
        </span>
       
       
        <div>
          <span>
     </span>

     
    
      
     

         
                     
         
      
      
      </div>
    
    
      
     </mat-radio-group>
    
    
      
    
    
    </mat-card>
    
    <mat-checkbox formControlName="changecolor" >Change Kanban Colour</mat-checkbox>
  
    <span class ="colorbox" *ngIf="this.editkanbanFG.value.changecolor">
      <app-color-picker (color)="this.configSvc.color"></app-color-picker>
    </span>
    
    <div>
      <button [disabled]= "!this.configSvc.colorchanged && !this.editkanbanFG.dirty" (click)="this.updateKanban();"
               matSuffix 
               mat-raised-button color="primary"
               aria-label="Update Kanban ">
            
           Update Node</button></div>
 
  </form>
  </mat-card>
</span>
