import { Injectable } from '@angular/core';
import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';
import {Docversion, Dochist} from '../model/docversion';
import {Configmodel}from '../model/configmodel';
import {ConfigService} from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DocSearchService {

  public foldertags = [];
  public foldertagonly = [];
  public tagindexes = [];
  public searchstatus =0;

  details: UserDetails;
  // public docdetails: Docversion = null;

  public searchtime;
  public docstatus;
  public uploadpercent = 0;
  public uploadcount = 0;

  user: TokenPayload;

 //  public currentrow: Docversion;
  public searchresults: Configmodel;
  public searchcount: Number;
  public currentattach: Docversion;
  public currenthist: Dochist;
  public currentsearch: Dochist;
  public currentdochist;
  public showdetails = false;
  public mybatch;
  public blobdata;
  public mt;
  public docsearchcounter = 0;
  public dochistdataset;
  public searchhistds;

 displayedColumns: string[] = ['filecount', 'mt', 'status', 'filename', 'filesize', 'title', 'path' ];
 displayedsubColumns2: string[] = ['property', 'value'];
 highlightedRows = [];
 highlightedHist = [];
 highlightedSearch = [];

  constructor(private authService: AuthenticationService, public configSvc: ConfigService) {}

  searchdocs(tagtype, selprop, selop, srcval) {
  this.searchresults = null;
  const formData = new FormData();
  formData.append('tagtype', tagtype);
  formData.append('property', selprop);
  formData.append('operator', selop);
  formData.append('searchvalue', srcval);
  formData.append('histtype', JSON.stringify(2));
  this.searchtime = Date.now();
  formData.append('searchtime', this.searchtime);

  this.authService.searchdocs(formData, this.user).subscribe(dbsearchresults => {
    console.log('this is the email user data: ' + JSON.stringify(this.user));
    console.log('this is the search results in json format: ' + JSON.stringify(dbsearchresults));
   this.searchresults = dbsearchresults.srchdocresults.doc_results;
   this.searchcount = dbsearchresults.srchdocresults.file_count;
  //  this.getsearchhist();

}, (err) => {
  console.error(err); });


  }

  searchattach(selprop, srcval) {
    this.currentattach = null;
    const formData = new FormData();
    formData.append('property', selprop);
    formData.append('searchvalue', srcval);
    console.log('this is search service for attachments');
    this.authService.searchattach(formData, this.user).subscribe(attachresults => {
     this.currentattach = attachresults;

  }, (err) => {
    console.log('got an error from the search');
    console.error(err); });

    }

  getdochist() {
    this.dochistdataset = null;
    const histForm = new FormData();
    this.authService.getdochist(histForm, this.user).subscribe(histresults => {
      console.log('this is the history results in json format: ' + JSON.stringify(histresults));
     this.dochistdataset = histresults;
  }, (err) => {
    console.error(err); });

    }

    getsearchhist() {
      this.searchhistds = null;
      const searchhistForm = new FormData();
      this.authService.getsearchhist(searchhistForm, this.user).subscribe(searchhistresults => {
        console.log('this is the search history results in json format: ' + JSON.stringify(searchhistresults));
       this.searchhistds = searchhistresults;
    }, (err) => {
      console.error(err); });

      }

    public selectHist(histrow) {
      this.highlightedHist = [];
      this.currenthist = histrow;
      console.log('this is the current history row selected in json format: ' + JSON.stringify(this.currenthist));
      this.highlightedHist.push(histrow);

  return histrow;
    }

    public selectSearchhist(searchrow) {
      this.highlightedSearch = [];
      this.currentsearch = searchrow;
      console.log('this is the current search row selected in json format: ' + JSON.stringify(this.currentsearch));
      this.highlightedSearch.push(searchrow);

  return searchrow;
    }
    
    public getFoldertags(){
    const foldertagsForm = new FormData();
    this.authService.getFoldertags(foldertagsForm, this.user).subscribe(typesresults => {
      console.log('this is the available foldertags: ' + JSON.stringify(typesresults));
      this.foldertags = typesresults;
      console.log('this is the foldertags'+JSON.stringify(this.foldertags))
     
  }, (err) => {
    console.error(err); });

  }

  searchAI(searchterm: string) {
    this.searchstatus = 1
    this.configSvc.teamds =null;

    const formData = new FormData();
    formData.append('searchterm', searchterm);

    this.searchtime = Date.now();
    formData.append('searchtime', this.searchtime);
  
    this.authService.searchAI(formData, this.user).subscribe(searchresults => {
      console.log('this is the email user data: ' + JSON.stringify(this.user));
      console.log('this is the search results in json format: ' + JSON.stringify(searchresults));
      
     
      this.configSvc.teamds = searchresults;
      if(this.configSvc.teamds.length<1){
        console.log('search status is now 3');
        this.searchstatus = 3
      }

      if(this.configSvc.teamds.length>0){
        console.log('search status is now 2');
        console.log('actibve type status is now '+this.configSvc.activetype);
        this.searchstatus = 4
      }


      this.configSvc.teamds = searchresults;
    

  
  }, (err) => {
    console.error(err); });
  
  
    }
  
   }

