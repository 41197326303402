
import { Component, OnInit, Inject } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter,CdkDragExit} from '@angular/cdk/drag-drop';

// import {Kanbanmodel, kprops} from '../../model/Kanbanmodel';
import {TagerService} from '../../services/tager.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-previewkanban',
  templateUrl: './previewkanban.component.html',
  styleUrls: ['./previewkanban.component.css']
})
export class PreviewkanbanComponent implements OnInit {

  public docarray = [];
  public kanbansorted = []; 
  public kanbaninitial = [];
public current = []
public lasttrack = 0
public lastrackname = '';
public firsttrackname = '';
public status = '';

  constructor(public configSvc: ConfigService, public tagerSvc: TagerService,  
    public auth: AuthenticationService,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.configSvc.activekanban = this.configSvc.nodeds[0].kanbanprops

  }

  get tracknames(): string[] {

    return this.configSvc.activekanban
  }

  public sortKanban(){
    
    
    //  console.log('this is the current kanbansorted'+JSON.stringify(this.kanbansorted))
  this.kanbansorted = this.configSvc.activekanban.sort((a, b) => Number(a.trackorder) - Number(b.trackorder));
  this.lasttrack = this.kanbansorted.length-1
  
    this.lastrackname = this.kanbansorted[this.lasttrack].track
    this.firsttrackname = this.kanbansorted[0].track
  
    
  return this.kanbansorted
   
  }

}
