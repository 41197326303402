<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>
          <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >text_fields</i>
        
        Modify Tag</span>
      </ng-template>
    <form [formGroup]="tagsFG">
      <span >
    
       
  <div>
      <mat-form-field appearance="outline" floatLabel="always" >
         <mat-label>Tag Name</mat-label>
         <input matInput  formControlName="formitem">
       </mat-form-field></div>
  
       
  <div *ngIf="tagsFG.value.formtype != 'checkbox' && tagsFG.value.formtype != 'boolean'">
    <mat-checkbox
    [checked]=tagsFG.value.label
    (change)="togglelabel()" class = "itemwrapper">
      Label Value</mat-checkbox>
  
  
    <span *ngIf="tagsFG.value.label">
      <mat-form-field appearance="fill">
        <mat-label>Label Value</mat-label>
       <input formControlName="formitemlabel" matInput value={{tagsFG.value.formitemlabel}}>
      </mat-form-field>
   </span> 
  </div>
  
      <div *ngIf="tagsFG.value.formtype != 'boolean' && !tagsFG.value.formchoices && tagsFG.value.formtype != 'date' && tagsFG.value.formtype != 'checkbox'">
        <mat-checkbox
        [checked]=tagsFG.value.hasdefault
        (change)="toggleChange()" class = "itemwrapper">
          Set Default Value</mat-checkbox>
        <span *ngIf=" this.configSvc.nodeds[0].hasdefault && tagsFG.value.formtype !='text'">
         <mat-form-field appearance="fill">
          <mat-label>Default Value</mat-label>
           <input formControlName="itemvalue" matInput value={{tagsFG.value.itemvalue}}></mat-form-field>
       </span> 
  
       <span *ngIf=" this.configSvc.nodeds[0].hasdefault  && tagsFG.value.formtype=='text'">
        <mat-form-field appearance="outline"   class = "textarea">
          <textarea
          
          formControlName="itemvalue" matInput value={{tagsFG.value.itemvalue}}></textarea>
         </mat-form-field>
      </span> 
  
       
  
      </div>
  
      <div *ngIf="tagsFG.value.formtype == 'choicelist'  ">
     
  
           <p>Choistlist: {{tagsFG.value.choicesetname}}</p>
           <div>   <mat-form-field>
         
            <mat-select formControlName="itemvalue"  [(value)]="tagsFG.value.itemvalue" 
           
            (selectionChange)="choiceChange()"
            >
        <mat-option *ngFor="let choice of getChoices(tagsFG.value.choicesetid)" [value]="choice.name">
          {{choice.name}}
        </mat-option>
      </mat-select>
     
    </mat-form-field>
    Value set to: {{tagsFG.value.itemvalue}}
    </div>
    
  
      </div>
  
      <div *ngIf="tagsFG.value.formtype == 'radiogroup'  ">
     
  
        <p>Radiogroup based on list: {{tagsFG.value.choicesetname}}</p>
        <mat-radio-group
        class="radio-group">
        <mat-radio-button class="radio-button" 
        
      
       
        *ngFor="let choice of this.tagerSvc.choicesetsrch" 
        [value]="choice"
        [checked]="choice.name == tagsFG.value.itemvalue"
        (change)="tagsFG.value.itemvalue = $event.value.name"
        >
          {{choice.name}}
        </mat-radio-button>
      </mat-radio-group>
      <div>Your selected radio button is: {{this.tagsFG.value.itemvalue}}</div>
  
  
   </div>
  
      <div *ngIf="tagsFG.value.formtype == 'treelist'  ">
     
  
        <p>Treelist Path: </p>
        <mat-chip-list>
  
        <span *ngIf="!this.choicesetselected"><mat-chip>{{tagsFG.value.choicesetname}}</mat-chip></span>  
          <mat-chip *ngFor="let paths of this.sortPath()"
          (click)="getChip(paths.pfoldid) "
          >
            {{paths.name}}
      </mat-chip>
      </mat-chip-list>
  
    
        <div class="outerbox">  
          <span  >
          <table mat-table [dataSource]="this.tagerSvc.choicesetsrch"  >
          
            <tr mat-row *matRowDef="let selectedrow; columns: resultColumns; let i = index;"
            (click)="this.getChoiceset(selectedrow)"
            [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
            class="innerbox"
            ></tr>
          
          
                     
          <ng-container matColumnDef="Name" >
                <th mat-header-cell *matHeaderCellDef>Name </th>
             <td mat-cell *matCellDef="let element">
          {{element.name}}
           </td></ng-container>
          
             </table> </span>
  </div>
  
  Value selected: 
  <div  *ngIf="this.choicesetselected"><mat-chip-list>
  <mat-chip>
  {{this.tagsFG.value.itemvalue}}
  </mat-chip></mat-chip-list></div>
  
  
  
   </div>
  
  
      <div *ngIf="tagsFG.value.formtype == 'boolean'">
      <mat-slide-toggle
    [checked] = tagsFG.value.hasdefault
    (change)="toggleChange()" >
      Default slider value {{tagsFG.value.hasdefault }}
   </mat-slide-toggle>
  </div>
  
  <div *ngIf="tagsFG.value.formtype == 'checkbox'">
    <mat-slide-toggle
  [checked] = tagsFG.value.hasdefault
  (change)="toggleChange()" >
    Set default checkbox value {{tagsFG.value.hasdefault}}
  </mat-slide-toggle>
  </div>
  
  <div *ngIf="tagsFG.value.formtype == 'checkbox' || tagsFG.value.formtype == 'boolean'">
   
  
      
    <span>
      <mat-form-field appearance="fill" class = "booleanfields">
        <mat-label>Label Value</mat-label>
       <input formControlName="formitemlabel" matInput value={{tagsFG.value.formitemlabel}}>
      </mat-form-field>
   </span> 

   <div>
    <mat-checkbox
    [checked]=tagsFG.value.label
    (change)="togglelabel()"
  
   >
    <span *ngIf="tagsFG.value.formtype == 'boolean' ">Show second toggle if first toggle true</span>
    <span *ngIf="tagsFG.value.formtype == 'checkbox' ">Show text box if checked is true</span>
 </mat-checkbox></div>

   <span *ngIf="tagsFG.value.label == true && tagsFG.value.formtype == 'boolean'">

    <div>
      <mat-form-field appearance="fill" class = "booleanfields">
        <mat-label>FALSE</mat-label>
       <input formControlName="itemvalue" matInput value={{tagsFG.value.itemvalue}}>
      </mat-form-field>
    </div>
    <div>
    <mat-form-field appearance="fill" class = "booleanfields">
      <mat-label>TRUE</mat-label>
     <input formControlName="textarea" matInput value={{tagsFG.value.textarea}}>
    </mat-form-field>
  </div>

 


 </span> 

 <span *ngIf="tagsFG.value.label == true && tagsFG.value.formtype == 'checkbox'">
  <span>
    <mat-form-field appearance="fill" class = "booleanfields">
      <mat-label>Description</mat-label>
     <input formControlName="itemvalue" matInput value={{tagsFG.value.itemvalue}}>
    </mat-form-field>
 </span>
 </span>

  </div>

  <div  class = "typewrapper">
          
    

    <mat-form-field appearance="fill">
     <mat-label>Size in row</mat-label>
     <mat-select formControlName="rowsize"  [(value)]="tagsFG.value.rowsize" 
           
    
     >
       <mat-option *ngFor="let rowsize of sizes" [value]="rowsize.value">
         {{rowsize.viewValue}}
       </mat-option>
     </mat-select>
   </mat-form-field>

    
</div>
  
  
  
  
    
  
      <div class = "flex-container">
    
  
   
  
      </div>
        
  
  <span>
    <button [disabled]= "!this.tagsFG.dirty" (click)="this.updateTagitems();"
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Update Tag ">
          
         Update Tag</button></span>

         <span>
          <button (click)="this.deleteTagitem();"
                   matSuffix 
                   mat-raised-button color="primary"
                   aria-label="Delete Tag ">
                
               Delete Tag</button></span>


         </span></form>
      
      </mat-tab>
  
  </mat-tab-group>
  