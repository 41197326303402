
  <mat-card class = flex-container>

    
  
        <mat-card class = "step-card">
          <form novalidate (ngSubmit)="onSubmit(registercompanyFG )" [formGroup]="registercompanyFG" >
            
            
            <div><mat-form-field class="full-width-input">
                <input matInput placeholder="Company Name" formControlName="companyname" />
                <mat-error *ngIf="isFieldInvalid('companyname')">
                 Please enter a unique company name
                </mat-error>
              </mat-form-field></div>

            
         <div><mat-form-field class="full-width-input">
          <input matInput placeholder="Username" formControlName="username" required />
          <mat-error *ngIf="isFieldInvalid('username')">
            Username for this company</mat-error>
          </mat-form-field></div>
            
         
 
         <div><mat-form-field class="full-width-input">
           <input matInput placeholder="Email" formControlName="email" required />
           <mat-error *ngIf="isFieldInvalid('email')">
             Please enter your  email address</mat-error>
           </mat-form-field></div>
 
  

      


         
         <div *ngIf="error" class="alert alert-danger">{{error}}</div>
       
         <div>Already a member? Please <a routerLink="/login">Sign in</a> instead.
         </div>
        
       
        <button mat-raised-button color="primary"  mat-button>Register Company</button>
       </form>
        </mat-card>
     

      
  
  </mat-card>

