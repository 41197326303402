import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-locktoolbar',
  templateUrl: './locktoolbar.component.html',
  styleUrls: ['./locktoolbar.component.css']
})
export class LocktoolbarComponent implements OnInit {

  @Input() assigntype= '';
  lockFG : FormGroup;
 
  constructor( private _formBuilder: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {
    this.configSvc.filtereduser = [];
  

    this.lockFG = this._formBuilder.group({
      toolbaricon: [this.configSvc.folderdetailsds.toolbaricon, Validators.required],
      toolbarclr: [this.configSvc.folderdetailsds.toolbaricon, Validators.required],
      toolbarname: [this.configSvc.folderdetailsds.toolbarname, Validators.required],
    });
  


  }



 



public unlockToolbar(){
  console.log('unlockToolbar    '+this.configSvc.activeitemid)


//  this.configSvc.clearAssignment(this.configSvc.nodeds[0]._id)


}

}
