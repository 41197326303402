import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../services/config.service';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {TagerService} from '.././../services/tager.service';

import { AuthenticationService, UserDetails, TokenPayload } from '../../services/authentication.service';

@Component({
  selector: 'app-createteam',
  templateUrl: './createteam.component.html',
  styleUrls: ['./createteam.component.css']
})
export class CreateteamComponent implements OnInit {

  
  @Input() teamtype: string;
  isLinear = true;
  step1 = "step1";
  step2= "step2";
  step3= "step3";
  step4= "step4";
  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;
    teamfileFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    constructor(private _formBuilder: FormBuilder, public tagerSvc: TagerService, public configSvc: ConfigService, private authService: AuthenticationService, 
      ) { }
  
    ngOnInit() {
  
      this.teamfileFG = this._formBuilder.group({
        teamname: [''],
        teamdescription : '',
        colors: 'black',
        checked: false,
        teams: [],
        tagstep: 1
      });

      this.configSvc.registericon = true;
     
      this.configSvc.colorinputtype = 'team';

      
    }

    public findUser() {
      this.finduserclicked = true;
      this.configSvc.findUser(this.usersearchfc.value);
     // console.log('userdetailset is' + this.configSvc.userdetailset);
     // this.dbuser = this.configSvc.userdetailset.name;
     // this.dbemail = this.configSvc.userdetailset.email;
    }

    drop(event: CdkDragDrop<string[]>) {
   
     
      transferArrayItem(this.configSvc.finduserdataset, this.configSvc.usersadded, event.previousIndex, event.currentIndex);
      console.log('finished transferring users to selected user');
      console.log('current selected user list'+JSON.stringify(this.configSvc.usersadded));
      //this.configSvc.userselected.push(this.configSvc.usersadded._id);
  }

  public addMyself(){
    this.configSvc.mgrsadded = [];
    const userdetails  = this.authService.getUserDetails();
    console.log('this is the managers details'+JSON.stringify(userdetails))
   // this.myself = userdetails;
   this.configSvc.activetype = 'team'
    this.configSvc.mgrsadded.push(userdetails.email);


  }

  
  

 
}
