


    
        <span [formGroup]="editsetFG" class = "details-container">
           
            <div>
             


                <mat-form-field appearance="fill" floatLabel="always" > 
                  <mat-label>SetName</mat-label>
                      <input formControlName="setname" matInput value="{{this.tagerSvc.setdetailsds[0].name}}">
                     </mat-form-field>
              
              </div>
            

              <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Added By</mat-label><input matInput [readonly]= "true" matInput value={{this.tagerSvc.setdetailsds[0].ab}}>
                </mat-form-field> </div>

                <div><mat-form-field appearance="outline" floatLabel="always" >
                
                  <mat-label >Added On</mat-label><input matInput [readonly]= "true" 
                  [ngModelOptions]="{standalone: true}"
                  [ngModel]="this.tagerSvc.setdetailsds[0].ao | date: 'medium'"
                  >
                </mat-form-field> </div>


                              

           
              <div> <mat-form-field appearance="outline" floatLabel="always"  >
                  <mat-label>SetID</mat-label><input formControlName="setid"  matInput placeholder={{this.editsetFG.value.setid}}>
               </mat-form-field></div>

               <div> <button class=" btn1"  [disabled]= "!this.editsetFG.dirty" (click)="this.renameSet(this.editsetFG.value.setid, this.editsetFG.value.setname);"
                mat-raised-button color="primary" >Apply Changes</button>

                <button   (click)="this.deleteSet(this.editsetFG.value.setid);"
                mat-raised-button color="primary">Delete {{this.tagerSvc.setdetailsds[0].name}}</button>
             </div>
             
             
             
              </span>

     
   

 

    





   