import { Injectable } from '@angular/core';

import { AuthenticationService, UserDetails, TokenPayload } from '../services/authentication.service';
import {FormControl} from '@angular/forms';
import {Docversion, Doccomments} from '.././model/docversion';
import {Commentmodel} from '.././model/commentmodel';
import {ConfigService} from '../services/config.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DoccommentsService {

  public commenter;
  datenow;
 //  public allcomments: Doccomments = null;
  // public newcmtval;
  // public doccomment: Doccomments = null;
  public cmtcnt;
  user: TokenPayload;
  public filecommentsds: Commentmodel;


  constructor(private authService: AuthenticationService, public configSvc: ConfigService, private router: Router,) { }

  public addcomment(process, quickadd, type, cmtval?,  cmticon?, cmtclr?, atcmt?, atcmtuser?, ischatgpt?)
  
{
  //  console.log('row is:' + JSON.stringify(this.configSvc.currentrow));
 //   const userdetails  = this.authService.getUserDetails();
 //   this.commenter = userdetails.email;
 //   console.log('user email is:' + JSON.stringify(this.commenter));
    this.datenow = Date.now();
    console.log('datetime comment made:' + JSON.stringify(this.datenow));
    console.log('comment is: ' + cmtval);
 //   this.cmtcnt = this.configSvc.currentrow.cc;
    const commentForm = new FormData();
  //  commentForm.append('ab', this.commenter);
  commentForm.append('cmt', 'true');
  commentForm.append('process', process);
    commentForm.append('addedon', this.datenow);
    commentForm.append('cmtclr', cmtclr);
    commentForm.append('cmticon', cmticon);
    commentForm.append('prompt', cmtval);
    commentForm.append('quickadd', quickadd);
    commentForm.append('atcmt', atcmt);
    commentForm.append('ischatgpt', ischatgpt);

    if(type == 'file'){
      console.log('here is the nodeds: '+JSON.stringify(this.configSvc.nodeds[0]))
      console.log('here is the aidata: '+this.configSvc.nodeds[0].aidata)
      commentForm.append('mt', this.configSvc.nodeds[0].mt);
      commentForm.append('textraction', JSON.stringify(this.configSvc.nodeds[0].aidata));
    }

    if (atcmt){
      commentForm.append('atcmtuser', atcmtuser);
      var directtype = ''
      if(this.configSvc.nodeds[0].type == 'node' ) (directtype = 'nodedirect')
      if(this.configSvc.nodeds[0].type == 'chatgpt' ) (directtype = 'chatgptdirect')
      if(this.configSvc.nodeds[0].type == 'wizard' ) (directtype = 'wizarddirect')
      if(this.configSvc.nodeds[0].type == 'tabbed' ) (directtype = 'tabbeddirect')
      if(this.configSvc.nodeds[0].type == 'file' ) (directtype = 'filedirect')

      var routerlink = 'http://localhost:4200/toolbard/home?itemid='+this.configSvc.nodeds[0]._id+
      '&type='+directtype+
      '&level='+this.configSvc.nodeds[0].level+
      '&teamid='+this.configSvc.nodeds[0].teamid+
      '&name='+this.configSvc.nodeds[0].name
    
      commentForm.append('link', routerlink);
    }

    if (ischatgpt){
      console.log('need to send turbo msg')
      var msg =[]

      if(type == 'file' && this.configSvc.nodeds[0].mt == 'msg'){
        var emailbody = this.configSvc.nodeds[0].email[0].emailbody
        console.log('emailbody is '+emailbody)
        msg.push({'content': emailbody, 'role': 'user'})

        if(this.configSvc.nodeds[0].email[0].ischatgpt){
          msg.push({'content': this.configSvc.nodeds[0].email[0].summary, 'role': 'system'})
        }
      }
      if(type == 'file' && (this.configSvc.nodeds[0].mt == 'jpg' || this.configSvc.nodeds[0].mt == 'png')){
     

    //    if(this.configSvc.nodeds[0].summarize){
   //       msg.push({'content': this.configSvc.nodeds[0].summary, 'role': 'system'})
    //    }
      }
      if(type == 'file' && (this.configSvc.nodeds[0].mt == 'pdf' && this.configSvc.nodeds[0].ischatgpt)){
    //  msg.push({'content': this.configSvc.nodeds[0].summary, 'role': 'system'})
    }
/*
      if(type == 'chatgpt'){

        msg.push({'content': this.configSvc.nodeds[0].summary, 'role': 'system'})
      
      }

      */

      msg.push({'content': cmtval, 'role': 'user'})
     // for(const content of this.configSvc.nodeds[0].comments){
      for(let i = 0; i < this.configSvc.nodeds[0].comments.length; i++) {
        let content = this.configSvc.nodeds[0].comments[i];
        console.log('here is the content' +JSON.stringify(content))
        if(content.username == 'ChatGPT')
        {
          
        }
        msg.unshift({'content': content.comment, 'role': 'system'})
    }
    console.log('here is the msg' +JSON.stringify(msg))



    commentForm.append('msg', JSON.stringify(msg));
    }
 //   commentForm.append('iic', this.configSvc.currentrow._id);
//    commentForm.append('cc', this.cmtcnt);
    commentForm.append('itemid', this.configSvc.activeitemid);
    commentForm.append('type', this.configSvc.activetype);

    if(this.configSvc.activetype == 'node' || this.configSvc.activetype == 'file'
    || this.configSvc.activetype == 'nodetab' ){
    commentForm.append('folderid', this.configSvc.nodeds[0].parentid);}
    if(this.configSvc.activetype == 'fold' && quickadd == false){
      commentForm.append('folderid', this.configSvc.activeitemid);}

      if(this.configSvc.activetype == 'fold' && quickadd == true){
        commentForm.append('folderid', this.configSvc.folderdetailsds._id);}

    // commentForm.append('docid', this.currentrow.);
   // if(type != 'chatgpt' && this.configSvc.popup){
      this.configSvc.nodeready = false;
  //  }
    this.configSvc.fileready = false;
    this.configSvc.folderready = false;
    console.log('activetype is set to '+this.configSvc.activetype)
console.log('nodeready is set to '+this.configSvc.nodeready)
this.configSvc.chatgptresponse = false

    this.authService.addcomment(commentForm, this.user).subscribe(commentresults => {
   console.log('the results of refreshed folder is'+JSON.stringify(commentresults))
   console.log('the current folderdetails is'+JSON.stringify(this.configSvc.folderdetailsds))
   
    if(quickadd == false){
   if(type == 'fold' || type == 'smartfolder' || type == 'smartfoldertab'){
     
    this.configSvc.folderdetailsds = commentresults[0]; 
    this.configSvc.folderready = true}}
   
    if(quickadd == true){
    if(type == 'fold' || type == 'smartfolder' || type == 'smartfoldertab'){
     
    this.configSvc.teamds = commentresults;
    this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
  this.configSvc.folderready = true
  }}
   if(type == 'node' || type == 'file' || type == 'nodetab'){
    this.configSvc.teamds = commentresults;
 
    this.configSvc.cachedteamds = this.configSvc.teamds.slice(this.configSvc.cachestartindex, this.configSvc.cacheendindex) 
       this.configSvc.tabIndex = 1
       console.log('this is the itemindex '+this.configSvc.itemindex)
      
       console.log('this is the teamds itemindex '+JSON.stringify(this.configSvc.teamds[this.configSvc.itemindex]))
      
       this.configSvc.nodeds[0] = this.configSvc.teamds[this.configSvc.itemindex]

       this.configSvc.nodeready = true; this.configSvc.fileready = true;
  }
  if(type == 'nodedirect'  || type == 'tabbeddirect'){
    
    this.configSvc.nodeds[0] = commentresults[0];
    this.configSvc.hidebrowser = true;
    console.log('refreshed the nodeds to '+JSON.stringify(this.configSvc.nodeds[0]));
     
   //  this.configSvc.activetype = 'nodedirect'
       this.configSvc.nodeready = true;
       this.configSvc.tabIndex = 1
       console.log('active type is set to '+this.configSvc.activetype);
       console.log('node ready is set to '+this.configSvc.nodeready);
     
      }

      if(type == 'chatgpt' && ischatgpt == true){
        console.log('results from adding comment '+JSON.stringify(commentresults[0]))
        var lastcomment 

        this.configSvc.nodeds[0] = commentresults[0];
/*
        if(commentresults[0].comments.length == 0){lastcomment = 0}
        if(commentresults[0].comments.length != 0){lastcomment = commentresults[0].comments.length-1}
   // lastcomment = refreshedfolder[0].comments.length-1
        console.log('lastcomment from asking chatgpt a question '+lastcomment)
        this.configSvc.summary[0] =  JSON.stringify(commentresults[0].comments[lastcomment].comment)
*/

this.configSvc.nodeready = true
        this.configSvc.chatgptresponse = true
      }

      if(type == 'chatgpt' && ischatgpt == false){
        console.log('results from adding comment '+JSON.stringify(commentresults[0]))
        this.configSvc.nodeds[0] = commentresults[0]

       this.configSvc.nodeready = true; this.configSvc.fileready = true;
      }

  }
     , (err) => {
      console.error(err); });

    }


  public getComments() {
    console.log('heading off to get the comment data now');
    const getcommentForm = new FormData();
    getcommentForm.append('doc_id', this.configSvc.currentrow._id);

    this.authService.getComments(getcommentForm, this.user).subscribe(getcommentdata => {
      console.log('returning comment data now' + JSON.stringify(getcommentdata));

      this.filecommentsds = getcommentdata;
     const row = this.configSvc.currentrow;
     console.log('this is the current row data:' + JSON.stringify(row));
     this.configSvc.highlightedRows.push(row);
    }
     , (err) => {
      console.error(err); });

  }

  public clearNotification(){
    console.log('clearing Notification comment data now');
    const clearnotificationForm = new FormData();
    clearnotificationForm.append('itemid', this.configSvc.activeitemid);

    this.authService.clearNotification(clearnotificationForm, this.user).subscribe(clearnotification => {
      console.log('returning clearnotification data now' + JSON.stringify(clearnotification));

      this.configSvc.nodeds = clearnotification;
     
    }
     , (err) => {
      console.error(err); });
  }

  public clearComment(itemid, type, commentid, last){
    console.log('clearing personal comment  data now');
    const clearncommentForm = new FormData();
    clearncommentForm.append('itemid', itemid);
    clearncommentForm.append('commentid', commentid);
    clearncommentForm.append('last', last);
    clearncommentForm.append('folderid', this.configSvc.folderdetailsds._id);
    clearncommentForm.append('type', 'lastcmt');
this.configSvc.nodeready = false
    this.authService.clearComment(clearncommentForm, this.user).subscribe(clearcomment => {
      console.log('returning clearcomment data now' + JSON.stringify(clearcomment));
      
      if(last){this.configSvc.nodeds = clearcomment;}
      if(!last){this.configSvc.nodeds[0] = clearcomment;}
      
      this.configSvc.nodeready = true
    }
     , (err) => {
      console.error(err); });
  }

  public getFileComments(iic){

      
    const formData = new FormData();
     formData.append('iic', iic);
      this.authService.getFileComments(formData, this.user).subscribe(filecommentresult => {
         this.filecommentsds = filecommentresult;
     }, (err) => {console.error(err); });} 


}
