import { Component, OnInit} from '@angular/core';
import { AuthenticationService, TokenPayload } from '../../services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; // new
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';



@Component({
        templateUrl: './register.component.html',
        styleUrls: ['./register.component.css'] // new
})
export class RegisterComponent implements OnInit {
    error = ''; // new
    registerFG: FormGroup;  // new
    step1 = "step1";
    step2= "step2";
    step3= "step3";
  

    public selectedIndex: number
    public stepIndex = 0;
    public icontype: string;
    public usersadded = [];
    public mgradded = [];
    selectedCategory = 'Action';
    categories = ['Action', 'Alert', 'AV', 'Communication', 'Content', 'Device', 
    'Editor','File', 'Hardware', 'Image', 'Maps', 'Navigation', 'Notification',
  'Places', 'Social', 'Toggle',   ];
  
    action = [ '3d_rotation', 'accessibility', 'accessibility_new', 'accessible_forward', 'account_balance',
    'account_balance_wallet', 'account_box', 'account_circle'
    ];
  
    av = ['4k', 'add_to_queue', 'airplay', 'album', 'art_track', 'av_timer', 'branding_watermark', 'call_to_action',
  'closed_caption' ]

    private formSubmitAttempt: boolean;  // new

  credentials: TokenPayload = {
    username: '',
    
    password: ''

  };

  constructor(
          private fb: FormBuilder, // new
          private auth: AuthenticationService,
      
          public configSvc: ConfigService
          ) {}

  ngOnInit() {
 

      this.registerFG = this.fb.group({     // {5}
        email: ['', Validators.required],
        password: ['', Validators.required],
        name: ['', Validators.required],
        iconControl: ['', Validators.required],
        iconCategory: ['', Validators.required],
        teamname: ['', Validators.required],
        teamdesc: ['', Validators.required],
      });
      console.log(' STEP1 register component ng on init phase');

      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'register'
      this.configSvc.chosenregisterIcon = 'face'
    }
  onSubmit({ value }) 
  {
    console.log('value collected from submit' + JSON.stringify(value));
    localStorage.setItem('mybatch', '0') ;
    this.auth.register(value).subscribe(() => {
      console.log('step 3');
    //  this.router.navigateByUrl('/tager/home');
    }, (err) => {
      console.error(err);
    });
    this.formSubmitAttempt = true; // new
   // console.log('formSubmitAttempt value:' + formSubmitAttempt);

 }

  isFieldInvalid(field: string) { // {6}

      return (
        (!this.registerFG.get(field).valid && this.registerFG.get(field).touched) ||
        (this.registerFG.get(field).untouched && this.formSubmitAttempt)

      );

    }

    public iconNext(){
      this.configSvc.activetype = 'registeruser'
      this.configSvc.colorinputtype = 'register'
      console.log('this is a register color input type')
    }
/*
    public createTeam(){
      this.usersadded.push(this.registerFG.value.email)
      console.log('users added are: '+JSON.stringify(this.usersadded))
      this.configSvc.createTeam('team', 'base', this.registerFG.value.teamname,this.registerFG.value.teamdesc, 0, this.mgradded, this.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, true)
    }
*/
}
