<div class="signin-content">
<mat-card>
    <mat-card-content>
      <form [formGroup]="resetemailform">
          <mat-icon title="Reset">
              <i class="fas fa-lock" data-fa-transform="grow-2" ></i></mat-icon> Send Email to Reset Password
        <mat-form-field class="full-width-input">
          <input matInput placeholder="Username" formControlName="username" required>
          <mat-error *ngIf="isFieldInvalid('username')">
            Please enter your user name
          </mat-error>
        </mat-form-field>
    
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
        <button mat-raised-button color="primary" (click)="sendpwordEmail()">Send Email</button>
    
   
   </form> </mat-card-content>
  </mat-card>
</div>