import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../.././services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService} from '../../services/authentication.service';
import {TagerService} from '../.././services/tager.service';
import {FormControl, FormBuilder, FormGroup,FormArray, Validators} from '@angular/forms';


@Component({
  selector: 'app-prompts',
  templateUrl: './prompts.component.html',
  styleUrls: ['./prompts.component.css']
})
export class PromptsComponent implements OnInit {
  browseColumns = [  'NameColumn','AddedBy', 'AddedOn'];
  choiceColumns = [  'NameColumn', 'AddedBy', 'AddedOn'];
  public prompt = [];
  public promptselected = false
  public promptdesc = ''
  public promptFG: FormGroup;

  constructor(public configSvc: ConfigService,  private authService: AuthenticationService,private _formBuilder: FormBuilder, 
    private router: Router, private activatedRoute: ActivatedRoute,public tagerSvc: TagerService) { }

  ngOnInit() {

    this.promptFG = new FormGroup({
      prompt: new FormControl({ value: ''}),
      promptdesc: new FormControl({ value: ''}),
      promptid: new FormControl({ value: ''}),
     
    });
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.configSvc.activeitemid = params['itemid'];
      this.configSvc.activetype = params['type'];
      this.configSvc.activename = params['name'];
      this.configSvc.activelevel = params['level'];
      this.configSvc.activeteamid = params['teamid'];
      this.configSvc.activeurl = "http://localhost:4200"+this.router.url;
      
    
      console.log('activated itemid'+this.configSvc.activeitemid);
      console.log('activated type'+this.configSvc.activetype);
      console.log('activated name'+this.configSvc.activename);
      console.log('activated level'+this.configSvc.activelevel);
    
      console.log('sending this url to the auth service'+this.router.url);
      this.configSvc.toolmenu = 'prompttools'
      this.configSvc.mode = 'prompttools'
      console.log('set the tool menu to '+this.configSvc.toolmenu)
      // this.currentURL = this.router.url;
      this.authService.choiceset(this.router.url).subscribe(choicedata => {
       
      
          if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
            this.tagerSvc.choiceset = choicedata;
            this.tagerSvc.rootchoiceconsole = true;
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid')){
          this.tagerSvc.choiceset = choicedata;
          this.tagerSvc.rootchoiceconsole = false;
          console.log(' rootchoiceconsole is set to '+this.tagerSvc.rootchoiceconsole); 
          console.log('active choiceset is '+JSON.stringify(this.tagerSvc.choiceset))
        //  this.tagerSvc.getChoicepath(this.configSvc.activeitemid) 
        this.tagerSvc.getsetDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid)
        
          console.log('active type is set to '+this.configSvc.activetype)
        }
       }, (err) => {
        console.error(err);
       });
    
      
     
    });
  }

  clickedSet(set){
    console.log('clicked set')
    console.log('current set'+JSON.stringify(set));
    this.configSvc.folderpath= set.path
    console.log('folder path is '+this.configSvc.folderpath)
    this.configSvc.mgrsadded= set.mgrs
    console.log('set managers '+this.configSvc.mgrsadded)
    this.tagerSvc.rootchoiceconsole = false;

  }

  clickPrompt(promptchosen){
    
    this.prompt =[promptchosen]
    console.log('clicking prompt '+JSON.stringify(this.prompt))
    console.log('clicking promptid '+this.prompt[0]._id)
   // this.promptFG.value.promptid = this.prompt[0]._id
    this.promptFG.patchValue({promptid: this.prompt[0]._id})
    console.log('clicking prompt '+this.prompt[0].name)
    this.promptFG.patchValue({prompt: this.prompt[0].name})
   // this.promptFG.value.prompt = this.prompt[0].name
    console.log('clicking prompt desc '+this.prompt[0].desc)
    this.promptFG.patchValue({promptdesc: this.prompt[0].desc})
   // this.promptFG.value.promptdesc = this.prompt[0].desc
    this.promptselected = true
    this.promptFG.markAsPristine()
    

  }

  modifyPrompt(){
    console.log('saving promptid '+this.promptFG.value.promptid)
    console.log('saving promptname '+this.promptFG.value.prompt)
    console.log('saving promptdesc '+this.promptFG.value.promptdesc)
    this.promptFG.markAsPristine()
  }

  public deletePrompt(){
    console.log('deleting choiceid is'+this.configSvc.activeitemid)
    console.log(' set id is'+this.tagerSvc.setdetailsds[0].parentid)

    this.tagerSvc.deleteChoiceitem( this.promptFG.value.promptid, this.configSvc.activeitemid )
    this.promptselected = false
  }
       
   

}
