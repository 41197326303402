import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../.././services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService} from '../../services/authentication.service';
import { TagerService } from '.././../services/tager.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter,CdkDragExit} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-nodeconsole',
  templateUrl: './nodeconsole.component.html',
  styleUrls: ['./nodeconsole.component.css']
})
export class NodeconsoleComponent implements OnInit {

  browseColumns = [ 'MimeColumn', 'ItemColumn'];

  currentURL: string;
  // public hlnode =[];
  public launcherchanged = false;
  public reset: any[] = [{}];
  
  constructor(public configSvc: ConfigService,  private authService: AuthenticationService,
    private router: Router, private activatedRoute: ActivatedRoute,public tagerSvc: TagerService) { }

  ngOnInit() {
    
    this.configSvc.mode = 'design'
   console.log('init of nodeconsole setting mode to '+this.configSvc.mode)
    this.configSvc.rootnodeconsole = false;
    this.configSvc.teamtype = 'node';
    this.configSvc.nodedetailsready = false;
    this.configSvc.flowready = false;
    this.configSvc.folderpath = [];
    console.log('folderpath is set to '+JSON.stringify(this.configSvc.folderpath))
    this.activatedRoute.queryParams.subscribe(params => {
      this.configSvc.activeitemid = params['itemid'];
      this.configSvc.activetype = params['type'];
      this.configSvc.activename = params['name'];
      this.configSvc.activelevel = params['level'];
      this.configSvc.activeteamid = params['teamid'];
      this.configSvc.activeurl = "http://localhost:4200"+this.router.url;
      
    
      console.log('activated itemid'+this.configSvc.activeitemid);
      console.log('activated type'+this.configSvc.activetype);
      console.log('activated name'+this.configSvc.activename);
      console.log('activated level'+this.configSvc.activelevel);
      console.log('activated teamid'+this.configSvc.activeteamid);
      console.log('sending this url to the auth service'+this.currentURL);
      console.log('sending this url to the auth service'+this.router.url);
      // this.currentURL = this.router.url;
      this.authService.nodeteam(this.router.url).subscribe(nodedata => {
       
        if(this.configSvc.activetype=='nodeteam')
        {  
          this.configSvc.nodeteamds = nodedata;
         // this.configSvc.nodedetailsready = true;
       //   console.log('off to get the direct path for'+this.configSvc.activeitemid)
       
       //   this.configSvc.getnodepath(this.configSvc.activeitemid, this.configSvc.activeteamid) 
       }
     
          if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
            this.configSvc.nodeteamds = nodedata;
            this.configSvc.rootnodeconsole = true;
            this.configSvc.activetype = 'nodeteam';
            console.log('activetype is set to '+this.configSvc.activetype)
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='category'){
          this.configSvc.nodeteamds = nodedata;
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='smartfolder'){
        //  this.configSvc.nodeteamds = nodedata;
          this.configSvc.nodeds[0] = nodedata[0];
          this.configSvc.nodetagsds = nodedata[0].stepitems
          this.configSvc.folderpath = nodedata[0].path
          console.log('folderpath is set to '+JSON.stringify(this.configSvc.folderpath))
           this.configSvc.toolmenu = 'tagtools'
           this.configSvc.editnode ='editnode';
          this.configSvc.nodedetailsready = true;
        }

        
        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='batchitems'){
          this.configSvc.nodeteamds = nodedata;
          console.log('ive got the batchitems data back')
          this.configSvc.toolmenu = 'tagtools'
          if( this.configSvc.nodeteamds[0].folders == true)
          { this.configSvc.subfolders = nodedata[0].subfolders}
          if( this.configSvc.nodeteamds[0].folders == false)
          { this.configSvc.subfolders = []}

          this.configSvc.folderpath = nodedata[0].path
          this.configSvc.nodedetailsready = true;
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='node'){
         this.configSvc.nodeds[0] = nodedata[0];
          this.configSvc.nodetagsds = nodedata[0].stepitems
          this.configSvc.folderpath = nodedata[0].path
          console.log('folderpath is set to '+JSON.stringify(this.configSvc.folderpath))
           this.configSvc.toolmenu = 'tagtools'
           this.configSvc.editnode ='editnode';
          this.configSvc.nodedetailsready = true;
         
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='foldertags'){
          this.configSvc.nodeds[0] = nodedata[0];
           this.configSvc.nodetagsds = nodedata[0].stepitems
           this.configSvc.folderpath = nodedata[0].path
           console.log('folderpath is set to '+JSON.stringify(this.configSvc.folderpath))
            this.configSvc.toolmenu = 'tagtools'
            this.configSvc.editnode ='editnode';
           this.configSvc.nodedetailsready = true;
          
         }


        if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='basicflow'){
          this.configSvc.assigned = [];
          this.configSvc.nodeds[0] = nodedata[0];
           this.configSvc.nodetagsds = nodedata[0].stepitems
            this.configSvc.toolmenu = 'tagtools'
            this.configSvc.editnode ='editnode';
            this.configSvc.assigned = nodedata[0].assign
            console.log('this is an basicflow and the assigned is '+JSON.stringify(this.configSvc.assigned))
            this.configSvc.folderpath = nodedata[0].path
           this.configSvc.nodedetailsready = true;
          
         }

      

       

         if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='editkanban' 
         || this.configSvc.activetype=='editflow'){
          this.configSvc.nodeds[0] = nodedata[0];
           this.configSvc.nodeteamds = nodedata[0].stepitems
           console.log('this is the current nodeteamds: '+JSON.stringify(this.configSvc.nodeteamds))
            this.configSvc.toolmenu = 'steptools'
            this.configSvc.folderpath = nodedata[0].path
           this.configSvc.nodedetailsready = true;
          
        console.log('this is what should appear in node console pane '+JSON.stringify(this.configSvc.nodeteamds))
       }

       

   

     if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='editstepperassign'){
      this.configSvc.nodeds[0] = nodedata[0];
      
      this.configSvc.editstepper = 'stepper'; this.configSvc.editnode = ''; this.configSvc.editflow = ''
      console.log('this is the current node details: '+JSON.stringify(this.configSvc.nodeds))
       this.configSvc.nodestepds = nodedata[0].stepper
       console.log('this is the current nodestepds details: '+JSON.stringify(this.configSvc.nodestepds))
        this.configSvc.toolmenu = 'tagtools'
        this.configSvc.assigned = nodedata[0].assign
        this.configSvc.folderpath = nodedata[0].path
       this.configSvc.nodedetailsready = true;
       console.log('activetype is '+this.configSvc.activetype )
      console.log('editstepper is '+this.configSvc.editstepper )
     this.configSvc.editstepper = 'stepper'
     this.configSvc.editstepperassign =''
      console.log('nodedetailsreadsy is '+this.configSvc.nodedetailsready )
   }

 

     if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='nodetab'){
      this.configSvc.nodeds[0] = nodedata[0];
      
      this.configSvc.nodetab = 'tabbed'; this.configSvc.editnode = ''; this.configSvc.editflow = ''
      console.log('this is the current tabbed details: '+JSON.stringify(this.configSvc.nodeds))
       this.configSvc.nodestepds = nodedata[0].stepper
       console.log('this is the current nodestepds details for tabbed: '+JSON.stringify(this.configSvc.nodestepds))
        this.configSvc.toolmenu = 'steptools'
        console.log('toolmenu is '+this.configSvc.toolmenu)
       this.configSvc.nodedetailsready = true;
       this.configSvc.nodeready = true;
       console.log('activetype is '+this.configSvc.activetype )
      console.log('nodetab is '+this.configSvc.nodetab )
      console.log('nodedetailsreadsy is '+this.configSvc.nodedetailsready )
   }

   
   if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='edittabbedassign'){
    this.configSvc.nodeds[0] = nodedata[0];
    
    this.configSvc.nodetab = 'tabbed'; this.configSvc.editnode = ''; this.configSvc.editflow = ''
    console.log('this is the current tabbed assign details: '+JSON.stringify(this.configSvc.nodeds))
     this.configSvc.nodestepds = nodedata[0].stepper
     console.log('this is the current nodestepds details: '+JSON.stringify(this.configSvc.nodestepds))
      this.configSvc.toolmenu = 'tagtools'
     this.configSvc.nodedetailsready = true;
     console.log('activetype is '+this.configSvc.activetype )
    console.log('editstepper is '+this.configSvc.editstepper )
    console.log('nodedetailsreadsy is '+this.configSvc.nodedetailsready )
 }

   if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='smartfoldertab'){
    this.configSvc.nodeds[0] = nodedata[0];
    
    this.configSvc.nodetab = 'tabbed'; this.configSvc.editnode = ''; this.configSvc.editflow = ''
    console.log('this is the current node details: '+JSON.stringify(this.configSvc.nodeds))
     this.configSvc.nodestepds = nodedata[0].stepper
     console.log('this is the current nodestepds details: '+JSON.stringify(this.configSvc.nodestepds))
      this.configSvc.toolmenu = 'steptools'
      this.configSvc.folderpath = nodedata[0].path
      this.configSvc.activekanban = nodedata[0].kanbanprops
     this.configSvc.nodedetailsready = true;
    
     console.log('activetype is '+this.configSvc.activetype )
    console.log('editstepper is '+this.configSvc.editstepper )
    console.log('nodedetailsreadsy is '+this.configSvc.nodedetailsready )
 }
   

     if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='editflow'){
     //  this.configSvc.nodeds[0] = nodedata[0];
    // this.configSvc.getFlowDetails(this.configSvc.activeitemid,this.configSvc.activeteamid)
    // console.log('off to get the Flow details');
    this.configSvc.flowdetailsds= [nodedata[0]];
    console.log('this is the current flow details: '+JSON.stringify(this.configSvc.flowdetailsds))
    
      this.configSvc.editstepper = ''; this.configSvc.editnode = ''; this.configSvc.editflow = 'editflow'
      console.log('this is the current node details: '+JSON.stringify(this.configSvc.nodeds))
      this.configSvc.nodeflowds = nodedata[0].assign
       console.log('this is the current nodeflowds details: '+JSON.stringify(this.configSvc.nodeflowds))
       if( this.configSvc.nodeflowds.length == 0) {
         console.log('this is a launch phase')
        this.configSvc.launchtype = 'launcher'}
         if( this.configSvc.nodeflowds.length != 0) {
          console.log('this is not launch phase')
          this.configSvc.launchtype = 'nonlauncher'
        }

        this.configSvc.toolmenu = 'designtools'
       this.configSvc.nodedetailsready = true;
       console.log('activetype is '+this.configSvc.activetype )
      console.log('editflow is '+this.configSvc.editflow )
      console.log('nodedetailsreadsy is '+this.configSvc.nodedetailsready )
   }
/*
     if(this.activatedRoute.snapshot.queryParamMap.has('itemid') && this.configSvc.activetype=='editstep' 
     ){
      this.configSvc.nodeds[0] = nodedata[0];
       this.configSvc.nodeteamds = nodedata[0].stepper
       console.log('this is the current nodeteamds: '+JSON.stringify(this.configSvc.nodeteamds))
        this.configSvc.toolmenu = 'designtools'
       this.configSvc.nodedetailsready = true;
      
    console.log('this is what should appear in node console pane '+JSON.stringify(this.configSvc.nodeteamds))
   }
   */
        
       }, (err) => {
        console.error(err);
       });

       
    
       if (this.activatedRoute.snapshot.queryParamMap.has('itemid')){
      //  this.configSvc.getnodepath(this.configSvc.activeitemid, this.configSvc.activeteamid)  
      //  console.log('off to get the node path for'+this.configSvc.activeitemid);
    
        if(this.configSvc.activetype=='nodeteam'){
        
          this.configSvc.getNodeTeamDetails(this.configSvc.activeteamid, this.configSvc.activetype)
          console.log('off to get the team details');}

         
      
    
        


          if(this.configSvc.activetype=='category'){
        this.configSvc.getCategoryDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid)
       console.log('off to get the category details');
       this.configSvc.categoryready = false;
       console.log('getting folder set to true in nodeconsole init');
      // this.configSvc.getNodeTeamDetails(this.configSvc.activeteamid, this.configSvc.activetype)
        //  console.log('off to get the team details')
 
        this.configSvc.activetype ='category'; 
         this.configSvc.typeenabled = true;
         this.configSvc.toolmenu = 'designtools'
        
      
      }

     
    
      if(this.configSvc.activetype=='team'){
       this.configSvc.getTeamDetails(this.configSvc.activeteamid, 'editteam')
       console.log('off to get the team details');
       this.configSvc.gettingteam = true;
     //  this.configSvc.getTagtemplate(this.configSvc.activeteamid, this.configSvc.activetype)
     //  console.log('getting tag template details via tagteam component as this is a team');
     //  this.configSvc.getTags(this.configSvc.activetype, this.configSvc.activeteamid)
      }
    
      if(this.configSvc.activetype=='editteam'){
        this.configSvc.getTeamDetails(this.configSvc.teamdetailsds._id, this.configSvc.activetype)
        console.log('off to get the team details');
     //   this.configSvc.getTagtemplate(this.configSvc.teamdetailsds._id, this.configSvc.activetype)
      //  console.log('getting tag  template details via tagteam component as this is a editteam');
     //   this.configSvc.getTags(this.configSvc.activetype, this.configSvc.teamdetailsds._id)
       }
    
       if(this.configSvc.activetype=='file'){
        console.log('getting tagtemplate details via tagteam component as this is a file')
      //  this.configSvc.getTagtemplate(this.configSvc.currentrow._id, this.configSvc.activetype)
        console.log('getting tag details from the file');
        this.configSvc.getTags(this.configSvc.activetype, this.configSvc.currentrow._id)
       }
    
       if(this.configSvc.activetype=='tageritem'){
         this.configSvc.buildtool = true
        console.log('getting direct tager item details ')
      //  this.configSvc.getTagtemplate(this.configSvc.currentrow._id, this.configSvc.activetype)
        console.log('getting tag details from the tageritem');
        this.configSvc.getTags(this.configSvc.activetype, this.configSvc.activeitemid)
       }
       
      }
      if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
        console.log('this is the design root need to set design tools')
this.configSvc.toolmenu = 'designteam';
      }
     
      
       
    });
  }

  clickNodeteam(){
    
    console.log('clicked node team')
    this.configSvc.rootnodeconsole = false; 
    this.configSvc.toolmenu = 'designtools'
   // this.configSvc.activetype='editnodeteam'
    console.log('set activetype to editnode team')
  }

  deselect(){
    console.log('deseelcting from design mode now')
   this.configSvc.toolmenu = 'design';
  
    this.configSvc.deselect('design');
  }

  clickCategory(){
    
    console.log('clicked category')
    this.configSvc.toolmenu = 'designtools'
   
  }

  clickNode(nodeselected){
    this.configSvc.nodedetailsready = false;
    this.onRecreate()
    console.log('clicked node')
    this.configSvc.toolmenu = 'tagtools'
  //  this.configSvc.activetype='node'
    console.log('set activetype to node and trying to hl')
   // this.configSvc.hlnode = []
// this.configSvc.hlnode.push(nodeselected) 
  }

  clickTag(tagselected, type){
    console.log('type is'+type)
    
    if(type == 'wizard'){ 
    
      this.configSvc.editstepper = 'edittag'
      this.configSvc.editstepperassign ='edittag'
      this.configSvc.nodetab = 'edittag'
     //  this.configSvc.activetype='steppertag'
     console.log('this is the editstepper value '+ this.configSvc.editstepper)}

     if(type == 'nodetab'){ 
    
      this.configSvc.nodetab = 'edittag'
     //  this.configSvc.activetype='steppertag'
     console.log('this is the nodetab value '+ this.configSvc.nodetab)}
   
    if(type == 'node' || type == 'foldertags' || type == 'smartfolder'){ 
      console.log('this is a nodetag')
      this.configSvc.editnode='edittag'}
    this.configSvc.nodeds = []; this.tagerSvc.choicesetsrch = [];
    this.configSvc.nodeds.push(tagselected) 
    console.log('highlighted tag selected is'+JSON.stringify(this.configSvc.nodeds[0]))
    this.onRecreate()
  //  this.tagerSvc.getChoicesetbyid(tagselected.choicesetid, 'choicelist')

  }

 

  clickTrack(trackselected){
   
   //  this.configSvc.activetype='track'
    this.configSvc.editkanban = 'edittrack';
     this.configSvc.trackdetailsds = [];
     this.configSvc.trackdetailsds.push(trackselected) 
     console.log('highlighted track selected is'+JSON.stringify(this.configSvc.trackdetailsds[0]))
   }

  clickKanban(){
    
    console.log('clicked kanban')
     this.configSvc.toolmenu = 'steptools'
   // this.configSvc.activetype='kanban'
   this.configSvc.editkanban = 'editkanban';
   
   this.configSvc.nodedetailsready = false
   this.onRecreate()
    console.log('toolmenu now set to tracktools')
   
  }

  clickFlow(){
    
    console.log('clicked nodeflow')
     this.configSvc.toolmenu = 'designtools'
   // this.configSvc.activetype='kanban'
   this.configSvc.nodedetailsready = false
   this.onRecreate()
  
   
  }

  clickStepper(element){
    
    console.log('clicked nodestepper for '+JSON.stringify(element))
     this.configSvc.toolmenu = 'designtools'

     if(element.type == 'editstepperassign') { this.configSvc.editstepper ='stepper'}
   
    

     console.log('clickStepper activetype is '+this.configSvc.activetype)
     console.log('editStepper  is '+this.configSvc.editstepper)
   this.configSvc.nodedetailsready = false
   this.onRecreate()
  
   
  }

  clickTabbed(){
    
    console.log('clicked tabbed')
     this.configSvc.toolmenu = 'designtools'
 
   this.configSvc.nodedetailsready = false
   this.onRecreate()
  
   
  }

  clickStep(stepselected, stepcount){
    this.configSvc.toolmenu = 'tagtools'
console.log('activetype after clicking step is '+this.configSvc.activetype)
if(this.configSvc.activetype == 'wizard' || this.configSvc.activetype == 'smartfolder' ){
    this.configSvc.editstepper = 'stepper'
   
    console.log('editstepper is now: '+this.configSvc.editstepper)
    console.log('activetype is now: '+this.configSvc.activetype)
  }
  if(this.configSvc.activetype == 'editstepperassign'  ){
    this.configSvc.editstepper = ''
    this.configSvc.editstepperassign ='editstep'
    }


    if(this.configSvc.activetype == 'nodetab'  || this.configSvc.activetype == 'smartfoldertab' ){
      this.configSvc.stepready = false
      this.configSvc.nodetab = 'editstep'
      console.log('nodetab is now: '+this.configSvc.nodetab)}

   //this.configSvc.toolmenu = 'tagtools'
 //   console.log('active type is now editstep')
   // this.configSvc.nodeds[0] = nodedata[0];
   console.log('this is the current step selected'+JSON.stringify(stepselected));
   console.log('this is the current step index'+stepcount);
   this.configSvc.activestep = stepcount;
   this.configSvc.hltag = []; this.tagerSvc.choicesetsrch = [];
  this.configSvc.stepdetailsds=[];
  this.configSvc.stepdetailsds.push(stepselected);
  console.log('this is the  stepdetails: '+JSON.stringify(this.configSvc.stepdetailsds))
  this.configSvc.hlstep=[];
  this.configSvc.hlstep.push(stepselected);
  console.log('this is the  stepdetails: '+JSON.stringify(this.configSvc.hlstep))
  this.configSvc.chosenIcon = this.configSvc.stepdetailsds[0].icontype
  console.log('this is the  active icontype for the step: '+this.configSvc.chosenIcon )



   // this.configSvc.nodeds = stepselected.tags;
   // console.log('this is the current tags: '+JSON.stringify(this.configSvc.nodeteamds))

    this.configSvc.nodetagsds = stepselected.tags;
    console.log('this is the current tags: '+JSON.stringify(this.configSvc.nodetagsds))
  // this.configSvc.stepdetailsds[0] = stepselected
    // console.log('hclicked step: '+stepselected)

    this.configSvc.stepready = true
    this.onRecreate()
   }

  public onRecreate(){
    this.reset[0] = {}
  }

  drop(event: CdkDragDrop<string[]>, type:string) {

    if(type == 'tags'){ 
      
      console.log('moving tags')
      moveItemInArray(this.configSvc.nodetagsds, event.previousIndex, event.currentIndex)
      console.log('updating the new nodetagsds'+JSON.stringify(this.configSvc.nodetagsds))
      this.configSvc.moveTagitem(JSON.stringify(this.configSvc.nodetagsds),this.configSvc.nodetagsds[0].itemid )
      
    
    }
    if(type == 'steps' ){ 
      

      moveItemInArray(this.configSvc.nodestepds, event.previousIndex, event.currentIndex)}

      if (type == 'track'){ 
      console.log('moving track')
      
        moveItemInArray(this.configSvc.nodeteamds, event.previousIndex, event.currentIndex)
        this.configSvc.moveTrackitem(JSON.stringify(this.configSvc.nodeteamds) )
      }
    if(type == 'flow'){
      console.log('moving a flow item')
      console.log('this is the current flowds'+JSON.stringify(this.configSvc.nodeflowds))
      moveItemInArray(this.configSvc.nodeflowds, event.previousIndex, event.currentIndex)
      console.log('this is the new index '+event.currentIndex)
      if(event.currentIndex == 0){
        console.log('the launcher has changed')
      this.launcherchanged = true}
      console.log('updating the new flowds'+JSON.stringify(this.configSvc.nodeflowds))
      this.configSvc.moveFlowitem(JSON.stringify(this.configSvc.nodeflowds),this.launcherchanged, this.configSvc.nodeflowds[0].itemid )
      
    
    }
  }

  public clickFlowitem(flowitem: string){
    this.configSvc.flowready = false;
          console.log('clicked on a flowitem'+flowitem)
          this.configSvc.flowitemdetailsds=[];
  this.configSvc.flowitemdetailsds.push(flowitem);
  console.log('this is the  flowitemdetailsds: '+JSON.stringify(this.configSvc.flowitemdetailsds))
  this.configSvc.assigned = this.configSvc.flowitemdetailsds[0].assign
  this.configSvc.editflow = 'editflowitem'
  console.log('go get a preview')
  this.configSvc.getFlowitembyid(this.configSvc.flowitemdetailsds[0].itemid, 'false')

  }
  

}
