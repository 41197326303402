<span *ngIf  = "data.type=='nodeteam'"><i class="material-icons icon-docaddpopup">group</i>
    <span mat-dialog-content>
     Create Toolbar
      
    </span>
    <div mat-dialog-actions>
     
      <mat-card class = "build-container">
  
      
                 <span *ngIf="this.configSvc.teamtype=='node'"><mat-card-title>Toolbar Setup</mat-card-title></span> 
                  <mat-horizontal-stepper [linear]="isLinear" #stepper>
                 
                 
                    <mat-step [stepControl]="step1">
      <form [formGroup]="teamfileFG">
              <ng-template matStepLabel>
                   Toolbar Name
                     </ng-template>
          <mat-form-field appearance="fill" class= "large-item">
              <mat-label>Create a new Toolbar</mat-label>
              <input formControlName ="teamname"  matInput placeholder="Team Name" >
                  <i  class="material-icons icon-group" matSuffix style="color:black">group</i>
              </mat-form-field>
  <div>
              <mat-form-field appearance="fill" class= "large-item">
                <mat-label>Description of Toolbar</mat-label>
                <input formControlName ="teamdescription"  matInput placeholder="Description" >
                   
                </mat-form-field></div>
    
              <div>
                     
                  <button  mat-raised-button color="primary" (click)="this.addMyself();" mat-button matStepperNext>Next</button>
                  </div>
              </form>
              </mat-step>
  
              <mat-step [stepControl]="step2">
                <ng-template matStepLabel>Designers </ng-template>
                  
                       <app-teamsecurity></app-teamsecurity>   
                       <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                       <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                       <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                  
                </mat-step>
  
                <mat-step [stepControl]="step3">
                  <ng-template matStepLabel>Toolbar Users </ng-template>
                    
                         <app-usersecurity></app-usersecurity>   
                         <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                         <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                         <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                    
                  </mat-step>
  
                  <mat-step [stepControl]="step4">
                          <form [formGroup]="teamfileFG">
                                  <ng-template matStepLabel>
                                         Design Icon
                                         </ng-template>
                                         <div>
                                          <span>
                                            <app-iconcategories></app-iconcategories></span>
                                                <span class ="colorbox">
                                                <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                                              </span>
                                          
                                              <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px">Back</button>
                                            <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
                                          </div>
                                         </form>
                      </mat-step>
  
                    
  
                      <mat-step [stepControl]="step5">
                              <form [formGroup]="teamfileFG">
                                      <ng-template matStepLabel>
                                             Create ToolbarTeam 
                                             </ng-template>
                                           
                                             
                                             <div class = "summary" >
             
              
             
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                              <mat-label >TeamName</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamname}}>
                                              </mat-form-field>
  
                                              <mat-form-field appearance="outline" floatLabel="always" >
                                                <mat-label >TeamDescription</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamdescription}}>
                                                </mat-form-field>
                                           
                                            
                                               
                                                <div class="example-list">
                                                  <mat-label>Managers</mat-label>
                                                         <div class="box-list" *ngFor="let manager of this.configSvc.mgrsadded" (click)="this.configSvc.selectMgrRow(manager, true)"
                                                               [style.background]=" this.configSvc.highlightedMgrRow.indexOf(manager) == 0 ? 'lightblue' : ''">
                                                                         <div>                                                                                                      
                                                                                     
                                      </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{manager}}
                                               </div></div>
                                               <mat-label>Users</mat-label>
                                   <div class="box-list" *ngFor="let users of this.configSvc.usersadded" (click)="this.configSvc.selectUserRow(users, true)"
                                                 [style.background]=" this.configSvc.highlightedUserRow.indexOf(users) == 0 ? 'lightblue' : ''">
                                                    <div> </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{users}}
                                                                                       </div></div>
                                   
                                                                                     </div>
                                   
                                   
                                                                             
                                                                              
                                                                                  <mat-label>Icon</mat-label>
                                                                                  <div>
                                                                                    <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || this.configSvc.teamdetailsds?.clr}" >{{this.configSvc.chosenIcon}} </i>
                                                                                   
                                                                                   </div>
    </div>
  
    <span *ngIf="this.configSvc.teamtype=='folder'">
   <button mat-raised-button color="primary"  (click)="this.configSvc.createTeam('team', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenIcon, true)
        ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Create Folder Team</button></span>
        
        <span *ngIf="this.configSvc.teamtype=='node'">
          <button mat-raised-button color="primary"  (click)="this.configSvc.nodeTeam('nodeteam', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
          this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenIcon, true)
          ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Toolbar Setup</button>
        </span>
  
  
                <span>
                      <button mat-raised-button color="primary" mat-button matStepperPrevious style="margin: 10px;">Back</button>
                </span>
                                             </form>
                          </mat-step>
  
                        
          </mat-horizontal-stepper>
        </mat-card>
    </div>
    </span>