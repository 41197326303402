<span class="flex-container" >
<mat-card class = "nodelist">

    <table mat-table [dataSource]="this.attachedfiles">
      
      

        <ng-container matColumnDef="mt" >
          
            <th mat-header-cell *matHeaderCellDef > </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.mt == 'pdf'" class="fa-2x"  matTooltip="PDF"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" data-fa-transform="shrink-5" style="color:red"></i></span></div>
                <div *ngIf="element.mt == 'msg'" class="fa-2x"  matTooltip="Email"><span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span>
                    <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i><span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div>
                </div>
                 <div *ngIf="element.mt == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                <div *ngIf="element.mt == 'xlsx' || element.mt == 'csv'" class="fa-2x"  matTooltip="Excel"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                <div *ngIf="element.mt == 'pptx'" class="fa-2x"  matTooltip="PowerPoint"><span class="fa-layers fa-fw">
                 <span><i class="fas fa-file-powerpoint" data-fa-transform="shrink-5" style="color:red"></i></span></span>
               </div>
                <div *ngIf="element.mt == 'docx' " class="fa-2x"  matTooltip="Word"><span class="fa-layers fa-fw">
                        <span><i class="fas fa-file-word" data-fa-transform="shrink-5" style="color:blue"></i></span></span>
                      </div>
                  
                <div *ngIf="element.mt == 'bmp' || element.mt == 'jpeg'  || element.mt == 'png'  || element.mt == 'jpg' " class="fa-2x"  matTooltip="Image"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
                <div *ngIf="element.mt == 'attachnode'" class="fa-2x"  matTooltip="Attach">   <i MatPrefix class="material-icons icon-string"  [style.color]="element.iconcolor" title="Attach {{element.name}} "
                  
                  >{{element.icontype}}</i></div>
            </td></ng-container>

            <ng-container matColumnDef="type" >
          
                <th mat-header-cell *matHeaderCellDef >Attach Type </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.mt == 'pdf' || element.mt == 'msg' || element.mt == 'txt' || element.mt == 'xlsx' || element.mt == 'pptx' || element.mt == 'docx'">File</div>
                  
                   
                   
                      
                    <div *ngIf="element.mt == 'bmp' || element.mt == 'jpeg'  || element.mt == 'jpg' || element.mt == 'png' ">Picture</div>
                    <div *ngIf="element.mt == 'attachnode'"> {{element.nodename}}</div>
                </td></ng-container>
 
            <ng-container matColumnDef="name" >
                <th mat-header-cell *matHeaderCellDef>Name </th>
                <td mat-cell *matCellDef="let element">
                
                {{element.name}}
                </td></ng-container>

<ng-container matColumnDef="ab" >
<th mat-header-cell *matHeaderCellDef>Uploaded By </th>
<td mat-cell *matCellDef="let element">

{{element.ab}}
</td></ng-container>

<ng-container matColumnDef="ao" >
<th mat-header-cell *matHeaderCellDef>Uploaded On </th>
<td mat-cell *matCellDef="let element">

{{element.ao | date:'medium'}}  
</td></ng-container>

<tr mat-header-row *matHeaderRowDef="versionColumns;  sticky: true"></tr>
<tr mat-row *matRowDef="let selectedrow; columns: versionColumns; let i2 = index;"
(click)="this.selectItem(selectedrow)"
[style.background]=" this.configSvc.linkeditem.indexOf(selectedrow) == 0 ? 'lightblue' : ''"

></tr>

         
    
       </table>

    </mat-card>


    <mat-card *ngIf="this.configSvc.linkready" class = "nodeitem">
        <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'jpg' || this.configSvc.linkeditem[0]?.mt == 'jpeg' || this.configSvc.linkeditem[0]?.mt == 'png'">
            <app-fileimage  [imagesource]="'nodefile'"  ></app-fileimage></span>

            <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'msg'">
        <app-emailtags   [emailsource] = "'nodeemail'"  ></app-emailtags></span>

        <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'attachnode'">
            <app-attachednode></app-attachednode></span>

            <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'attachnodetab'">
                <app-attachedtabs></app-attachedtabs></span>
        


    </mat-card>
</span>