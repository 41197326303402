
<div class = "results-container">

    <table mat-table [dataSource]="dataSource" matSort >

        <tr mat-header-row *matHeaderRowDef="activeColumns; sticky: true" ></tr>

        <tr mat-row *matRowDef="let element; columns: activeColumns; let docindex = index;"
        
      (click)="this.clickResult(element, docindex)"
      [style.background]=" this.itemsadded.indexOf(element) == 0 ? 'lightblue' : ''"
        ></tr>

          <!-- Mimetype Column -->
      <ng-container matColumnDef="mt">
       <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px" ></th>

       <td mat-cell *matCellDef="let element">


               

                   <div  *ngIf="element.type == 'team'"> <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >{{element.icontype}} </i></div>
                   <span *ngIf="element.ts"><i class="material-icons md-24" [ngStyle]="{'color': element.clr || 'black'}" >loyalty</i></span>
                   <div  *ngIf="element.type == 'fold' && element.tagged == false">
                     
                     <i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >folder</i>
                    </div>
                   <div  *ngIf="element.type == 'fold' && element.tagged == true">
                     
                      <span class="fa-layers fa-fw">
                        <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >folder</i>
                  
                    
                  </span>
                  </div>
                
                   
                  
     
                     <div  *ngIf="element.type  == 'node'  || element.type  == 'nodetab' || element.type  == 'attachnode' || element.type  == 'attachnodetab'  || element.type  == 'tagerseq' || element.type  == 'formassign' || element.type  == 'nodeimage'" matTooltip="{{element.bo}}">
                      <i class="material-icons md-24" [ngStyle]="{'color': element.iconcolor}" >{{element.icontype}}</i>
                   
                      </div>
                   
                     <div  *ngIf="element.type  == 'file'">
  
                    
                        
                           
                           <div *ngIf="element.mt == 'pdf'" class="fa-2x"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i></span></div>
                           <div *ngIf="element.mt == 'msg'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span>
                               <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i><span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div>
                           </div>
                            <div *ngIf="element.mt == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                           <div *ngIf="element.mt == 'xlsx'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                           <div *ngIf="element.mt == 'pptx' && element.ver==1" class="fa-2x"><span class="fa-layers fa-fw">
                            <span><i class="fas fa-file-powerpoint" data-fa-transform="shrink-5" style="color:red"></i></span></span>
                          </div>
                           <div *ngIf="element.mt == 'docx' && element.ver==1" class="fa-2x"><span class="fa-layers fa-fw">
                                   <span><i class="fas fa-file-word" data-fa-transform="shrink-5" style="color:blue"></i></span></span>
                                 </div>
                             <div *ngIf="element.mt == 'docx' && element.ver>1" class="fa-2x"><span class="fa-layers fa-fw">
                                    <i class="fas fa-file-word versioned" data-fa-transform="shrink-5 up-3 left-5 " style="color:blue"></i>
                                   <i class="fas fa-file-word" data-fa-transform="shrink-5"  style="color:blue"></i>
                                      </span></div>
                           <div *ngIf="element.mt == 'bmp' || element.mt == 'jpeg' || element.mt == 'jpg'  || element.mt == 'png' " class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
                         
                       </div>
                     
                       <div *ngIf="element.type =='chatgpt' " >
                         
                        
                        <mat-icon  title="ChatGPT" svgIcon="chat2"
                        ></mat-icon>

                   
                       </div>

                  
                     


       </td>

     </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
       <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30px">Type</th>
       <td mat-cell *matCellDef="let element"> {{element.type}} </td>
     </ng-container>

      
    

     

   

    
 


        <!-- Addedby Column -->
        <ng-container matColumnDef="ab">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
          <td mat-cell *matCellDef="let element"> {{element.ab}} </td>
        </ng-container>

    

                 <!-- Addedby Column -->
                 <ng-container matColumnDef="mo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified On</th>
                  <td mat-cell *matCellDef="let element"> {{element.mo | date: 'medium'}} </td>
                </ng-container>

                       <!-- Addedby Column -->
                       <ng-container matColumnDef="score">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Score</th>
                        <td mat-cell *matCellDef="let element"> {{element.score}} </td>
                      </ng-container>

                               <!-- Addedby Column -->
                               <ng-container matColumnDef="aidata">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Summary</th>
                                <td mat-cell *matCellDef="let element"> 
                                  <span *ngIf="element.aidata?.length > 100 ">
                                  
                                    {{element.aidata.substring(0, 100) + "..."}}
                                  
                                  </span>

                                  <span *ngIf="element.aidata?.length < 101 ">
                                  
                                    {{element.aidata}}
                                  
                                  </span>
                               </td>
                              </ng-container>
        

      
        

       <!-- Title Column -->
       <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let element"> 
          
          <span *ngIf="element.website">
          <a href={{element.weblink}}>{{element.name}}</a>
        </span>

        <span *ngIf="!element.website">
          {{element.name}}
        </span>
     
          
          <span *ngIf="element.deadline">
            <span *ngIf="element.deadlineval > this.dateTime">
            <i class="material-icons icon-size" [ngStyle]="{'color': 'blue'}">timer</i></span>
    
            <span *ngIf="element.deadlineval < this.dateTime">
              <i class="material-icons icon-size" [ngStyle]="{'color': 'red'}">timer</i></span></span>
              <span *ngIf="element.assigned">
                <i class="material-icons icon-size" [ngStyle]="{'color': 'purple'}">person_pin_circle</i></span>
                <span *ngIf="element.kanbantagged">
                  <mat-icon [style.color] = "element.kanbancolor"> 
           {{element.kanbanicon}}</mat-icon> </span>
           <span *ngIf="element.cmt">
            <i class="material-icons icon-size"  [ngStyle]="{'color': element.cmtclr}">{{element.cmticon}}</i> </span>
            <span *ngIf="element.tagged">
              <i class="material-icons icon-comment">bookmarks</i> </span>
              <span *ngIf="element.kanbantagged">
                <mat-icon [style.color] = "element.kanbancolor"> 
                  {{element.kanbanicon}}</mat-icon> </span>
                  <span *ngIf="element.attach">
                    <i class="material-icons icon-size"  [ngStyle]="{'color': 'black'}">attach_file</i> </span>
            
                    <span *ngIf="element.linked">
                      <i class="material-icons icon-size"  [ngStyle]="{'color': 'lightblue'}">attachment</i> </span>
            </td>
      </ng-container>

  



  
    

     


     

    </table>
 
</div>
  
