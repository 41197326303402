<span *ngIf = "data.type=='step'"><i class="material-icons icon-step" [ngStyle]="{'color': 'darkred'}">directions</i>
    <div mat-dialog-content>
      <p>Enter Tab Name</p>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
      </mat-form-field>
  
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput [(ngModel)]="data.desc">
      </mat-form-field>

    <div> <mat-checkbox  [(ngModel)]="icons">Add Icon</mat-checkbox></div> 
      <div *ngIf="this.icons">
   <mat-label>Add Tab icons</mat-label>
     
        <app-iconcategories></app-iconcategories>
 
        
        <span class ="colorbox">
         <app-color-picker (color)="this.configSvc.color"></app-color-picker>
       </span>
</div>
  
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="this.addStep(data.name, data.desc, 'step')">Create Step</button>
      
    </div>
</span>

<span *ngIf = "data.type=='linktab'"><i class="material-icons icon-step" [ngStyle]="{'color': 'darkred'}">directions</i>
  <div mat-dialog-content>
    <p>Enter LinkTab Name</p>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Link Description</mat-label>
      <input matInput [(ngModel)]="data.desc">
    </mat-form-field>

    <mat-form-field class = "icondropdown">
      <mat-label>Choose Tab</mat-label>
      <mat-select
      matInput [(ngModel)]="sourcetab"
      (selectionChange)="changeTab(this.sourcetab)">
        <mat-option *ngFor="let sourcetab of this.configSvc.nodestepds" [value]="sourcetab"
        >
        {{sourcetab.step}}
        </mat-option>
      
      </mat-select>
      
      </mat-form-field>

      <mat-form-field *ngIf="this.tabselected" class = "icondropdown">
        <mat-label>Choose Source Index</mat-label>
        <mat-select
        matInput [(ngModel)]="chosenindex"
        (selectionChange)="changesourceIndex(chosenindex)">
          <mat-option *ngFor="let tags of this.sourceindex" [value]="tags.formitem"
          >
          {{tags.formitem}}
          </mat-option>
        
        </mat-select>
        
        </mat-form-field>


<div *ngIf="this.sourceindexselected" > 


    <div> <mat-checkbox  [(ngModel)]="restrict">Restrict to specific template</mat-checkbox></div> 

    <div *ngIf="restrict">
        <mat-label> Target Template</mat-label>
      <mat-form-field appearance="fill"  class = "indextemplates">
        <mat-label>Template</mat-label>
       
        <input type="text"  
        [(ngModel)]="targettemplate"   
        [matAutocomplete]="auto"
        
        matInput (keyup)="templateFilter($event)" placeholder="Index" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let template of this.configSvc.finditemds" [value]="template.name"  
    (onSelectionChange)="selectTemplate(template)">
    <i class="material-icons icon-size" [ngStyle]="{'color': template.iconcolor}">
      {{template.icontype}}</i>
    {{template.name}}:<i>{{template.desc}} </i>
    
    </mat-option>
        </mat-autocomplete>
    
       
       </mat-form-field>
    
       <div *ngIf="templateselected">
      <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.targetcolor}">
        {{this.targeticon}}</i>
      {{this.targettemplate}}
    </mat-label></div>

    <mat-form-field *ngIf="this.templateselected" class = "icondropdown">
      <mat-label>Choose Target Index</mat-label>
      <mat-select
      matInput [(ngModel)]="chosentargetindex"
      (selectionChange)="changetargetIndex(chosentargetindex)">
        <mat-option *ngFor="let tags of this.targetindexes" [value]="tags.formitem"
        >
        {{tags.formitem}}
        </mat-option>
      
      </mat-select>
      
      </mat-form-field>
 </div>
   
  </div></div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="this.createLinktab(data.name, data.desc, 'linktab', this.restrict, this.targettemplate, this.chosenindex, this.chosentargetindex, this.targeticon, this.targetcolor)">Create Link Tab</button>
    
  </div>
</span>

<span *ngIf = "data.type=='track'"><i class="material-icons icon-addtrack" [ngStyle]="{'color': 'purple'}">sync_alt</i>
    <div mat-dialog-content>
      <p>Enter Track Name</p>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="data.name">
      </mat-form-field>
    
      <mat-form-field>
        <mat-label>Track Description</mat-label>
        <input matInput [(ngModel)]="data.desc">
      </mat-form-field>
    
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="this.addTrack(data.name, data.desc, 'track')">Create Track</button>
      
    </div>
    </span>

    <span *ngIf = "data.type=='kanban'"><i class="material-icons icon-addtrack" [ngStyle]="{'color': 'purple'}">sync_alt</i>
      <div mat-dialog-content>
        <p>Find Kanban</p>
        <div >
          <mat-form-field appearance="fill">
            <mat-label>Kanbans</mat-label>
           
            <input type="text"  
            matInput  matInput [(ngModel)]="kanbanname" 
            [matAutocomplete]="auto"
            
            matInput (keyup)="kanbanFilter($event)" placeholder="Kanban" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let kanban of this.configSvc.findkanbands" [value]="kanban.name"
        (onSelectionChange)="kanbanSelected(kanban)">
        {{kanban.name}} 
        
        </mat-option>
            </mat-autocomplete>
        
           
           </mat-form-field>
        
           <span *ngIf="this.kanbanready" class = "flex-container"  >
        
            <div  class="kanban-container"
            
            *ngFor="let track of this.configSvc.activekanban" 
          >
          
            <mat-card><span class= "tracks">
              <h2  class="mat-h2">{{track.formitem}}</h2>
              <i class="material-icons md-24" [ngStyle]="{'color': 'green'}"
              matTooltip={{track.itemvalue}}  matTooltipPosition= 'after'
              >info</i></span>
             </mat-card>
             </div>
            
            </span>
            
 
        
      
          
        </div>
      
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" (click)="this.applynodetabKanban(); this.dialogRef.close();">Apply Kanban</button>
        
      </div>
</span>

<span *ngIf = "data.type=='foldertag'" class = "step-container"><i class="material-icons icon-addtrack" [ngStyle]="{'color': 'orange'}">bookmark</i>

    <p>Enter Foldertag Name</p>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Foldertag Description</mat-label>
      <input matInput [(ngModel)]="data.desc">
    </mat-form-field>

    <mat-radio-group  [(ngModel)]="tagtype"
    class="tagtypes">
    <mat-radio-button value="firstprop"> Tag by first property only</mat-radio-button>
   

    <mat-radio-button value="firsttab"> Tag by all in first Tab</mat-radio-button>



 </mat-radio-group>
  

  <div mat-dialog-actions>
    <div class ="btns"> <button mat-raised-button color="primary" (click)="this.applySmartfoldertag(data.name, data.desc, 'foldertags', tagtype)">Apply SmartFolder tag</button>
    </div>
    <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
   
    
  </div>
  </span>

