  
        <div  class="outerbox" >
            <table mat-table [dataSource]="this.textdata"  >
            
            
             <ng-container matColumnDef="key" >
                  <th mat-header-cell *matHeaderCellDef>Key</th>
               <td mat-cell *matCellDef="let element">
              {{element.key}}
            
                
             </td></ng-container>
                       
            <ng-container matColumnDef="value" >
                  <th mat-header-cell *matHeaderCellDef>Value</th>
               <td mat-cell *matCellDef="let element">
                
                   {{element.value}}
             </td></ng-container>
    
             <ng-container matColumnDef="match" >
                <th mat-header-cell *matHeaderCellDef>Matched</th>
             <td mat-cell *matCellDef="let element">
              
                <span *ngIf="element.match"> 
                    <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >check_circle_outline </i>
                  </span>

                  <span *ngIf="!element.match"> 
                    <i class="material-icons md-24" [ngStyle]="{'color': 'red'}" >highlight_off </i>
                  </span>
           </td></ng-container>

           <ng-container matColumnDef="link" >
            <th mat-header-cell *matHeaderCellDef>Link</th>
         <td mat-cell *matCellDef="let element; let rowcount = index;">
          
         

              <span *ngIf="!element.match && this.itemselected && rowcount == this.indexselected"> 
               <mat-form-field appearance="fill">
                  
                  <mat-select  (selectionChange)="chooseProperty(property)"  [(ngModel)]="selectedProperty" name="property">
                    <mat-option *ngFor="let property of indexoptions" [value]="property">
                      {{property.formitem}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
       </td></ng-container>
      
    
         
        
   
    
    
    <tr mat-header-row *matHeaderRowDef="textcolumns;  sticky: true"></tr>
    <tr mat-row *matRowDef="let selectedrow; columns: textcolumns; let i2 = index;"
    (click)="this.selectItem(selectedrow, i2)"
    [style.background]=" this.itemsadded.indexOf(selectedrow) == 0 ? 'lightblue' : ''"
    
    ></tr>
    
                 
            
               </table>

          
          </div>
          <div> <button  [disabled]="!this.applyproperty"  mat-raised-button color="primary" (click)="assignKey()" mat-button>Update  {{this.property[0]?.formitem}}</button> 
          </div>
