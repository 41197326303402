import { Component, OnInit } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfigService} from '../../services/config.service';
import {DocactionsService} from '.././../services/docactions.service';
import {UploadService} from '../../services/upload.service';

@Component({
  selector: 'app-docversions',
  templateUrl: './docversions.component.html',
  styleUrls: ['./docversions.component.css']
})
export class DocversionsComponent implements OnInit {

  public itemsadded = [];
  public versionready = false;
  public clickCount = 0;
  editfileFG: FormGroup;
  versionColumns = ['Mime', 'Name', 'Version', 'AddedBy', 'AddedOn', 'Instructions'];
  public reset: any[] = [{}];

  constructor(public uploadSvc: UploadService, private fb: FormBuilder, public configSvc: ConfigService,  private docActions: DocactionsService) { }

 
  ngOnInit() {
    this.editfileFG = this.fb.group({
      filename: ['', Validators.required ],
   });
  
}

public getVersion(){
  console.log('trying to get the version of the file')
}



clickVersion(selectedVersion){
 console.log('clicked version')

  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {

  console.log('current version row is'+JSON.stringify(selectedVersion))

  
 // this.itemsadded = []
//this.itemsadded.push(selectedVersion)
// console.log('selected items '+JSON.stringify(this.itemsadded))
this.configSvc.linkeditem = []
this.configSvc.linkeditem.push(selectedVersion)
console.log('selected version '+JSON.stringify(this.configSvc.linkeditem))
// this.versionready = true
this.uploadSvc.imageverready = false
/*
if(this.configSvc.nodeds[0].mt == 'jpeg' || this.configSvc.nodeds[0].mt == 'jpg' ||
this.configSvc.nodeds[0].mt == 'gif' || this.configSvc.nodeds[0].mt == 'png'){

  this.uploadSvc.getImagebyid(this.configSvc.linkeditem[0].docid, this.configSvc.linkeditem[0].ver)
  }
 */

 


    console.log('docid '+this.configSvc.linkeditem[0].docid);
    console.log('version '+this.configSvc.linkeditem[0].ver);
    this.uploadSvc.getImagebyver(this.configSvc.linkeditem[0].docid, this.configSvc.linkeditem[0].ver, 'imageversion')
  


 
}

else if (this.clickCount === 2) {
  console.log('this is a double click')
  console.log('version is '+selectedVersion.ver)
  console.log('versionid is '+selectedVersion.docid)
  this.docActions.downloadVersion(this.configSvc.currentrow._id, selectedVersion.docid);



}
this.clickCount = 0;
}, 300)
}


public onRecreate(){
  this.reset[0] = {}
}


}
