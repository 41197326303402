<mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}" >loupe</i>
       Tabbed Details
      </ng-template>
      <mat-card  class = "details-container">
    <form [formGroup]="edittabsFG">
      <span>
    <mat-form-field>
      <mat-label>Tabbed Name</mat-label>
      <input   formControlName="name" matInput value={{this.configSvc.nodeds[0].name}}>
    </mat-form-field></span>
    <div>
    <mat-form-field >
      <mat-label>Tabbed Description</mat-label>
      <input  formControlName="desc" matInput value={{this.configSvc.nodeds[0].desc}}>
    </mat-form-field></div>

    <div><mat-checkbox formControlName="ischatgpt" >ChatGPT Template</mat-checkbox>
    </div>
    <div *ngIf=" this.edittabsFG.value.ischatgpt">
Default Prompt
<mat-form-field appearance="outline"   class = "textarea">
<textarea
matInput value={{this.edittabsFG.value.defaultprompt}}
formControlName="defaultprompt"></textarea>
</mat-form-field>

</div>
  
    <span> <app-iconcategories></app-iconcategories></span>
  
      <div>Choose a colour for Tabbed</div>
      <mat-checkbox formControlName="changecolor" >Change Colour</mat-checkbox>
  
        <span class ="colorbox" *ngIf="this.edittabsFG.value.changecolor">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker></span>
  
     
          
      
         
  
  <div>
    <button [disabled]= "!this.configSvc.colorchanged && !this.edittabsFG.dirty" (click)="this.updateNode();"
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Update Tabs ">
          
         Update Tabs</button></div>
        </form>
        </mat-card>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >preview</i>
          Preview
        </ng-template>

        <app-activetabs></app-activetabs>

      </mat-tab>

      <mat-tab *ngIf="this.configSvc.nodeds[0].kanbantagged">
        <ng-template mat-tab-label>
          <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].kanbancolor}" >view_column</i>
          {{this.configSvc.nodeds[0].kanbanname}}
        </ng-template>

        <app-previewkanban></app-previewkanban>

      </mat-tab>

      <mat-tab  *ngIf="this.configSvc.nodeds[0]?.foldertagged == true" label="FolderTags">
        <ng-template mat-tab-label>
          <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.nodeds[0]?.tagcolor}">bookmark</i>
          {{this.configSvc.nodeds[0]?.tagname}}
        </ng-template>
        <app-foldertags  [indexlaunch]="'nodeconsole'" ></app-foldertags>
      </mat-tab>
  </mat-tab-group>
  