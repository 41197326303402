import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../.././services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService} from '../../services/authentication.service';
import {TagerService} from '../.././services/tager.service';

@Component({
  selector: 'app-choicesets',
  templateUrl: './choicesets.component.html',
  styleUrls: ['./choicesets.component.css']
})
export class ChoicesetsComponent implements OnInit {

  browseColumns = [ 'IconColumn', 'NameColumn', 'DescColumn','AddedBy', 'AddedOn'];
  choiceColumns = [ 'IconColumn', 'NameColumn','DescColumn', 'AddedBy', 'AddedOn'];

  constructor(public configSvc: ConfigService,  private authService: AuthenticationService,
    private router: Router, private activatedRoute: ActivatedRoute,public tagerSvc: TagerService) { }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.configSvc.activeitemid = params['itemid'];
      this.configSvc.activetype = params['type'];
      this.configSvc.activename = params['name'];
      this.configSvc.activelevel = params['level'];
      this.configSvc.activeteamid = params['teamid'];
      this.configSvc.activeurl = "http://localhost:4200"+this.router.url;
      
    
      console.log('activated itemid'+this.configSvc.activeitemid);
      console.log('activated type'+this.configSvc.activetype);
      console.log('activated name'+this.configSvc.activename);
      console.log('activated level'+this.configSvc.activelevel);
    
      console.log('sending this url to the auth service'+this.router.url);
      this.configSvc.toolmenu = 'choicetools'
      this.configSvc.mode = 'choicetools'
      console.log('set the tool menu to '+this.configSvc.toolmenu)
      // this.currentURL = this.router.url;
      this.authService.choiceset(this.router.url).subscribe(choicedata => {
       
      
          if (!this.activatedRoute.snapshot.queryParamMap.has('itemid')){
            this.tagerSvc.choiceset = choicedata;
            this.tagerSvc.rootchoiceconsole = true;
        }

        if(this.activatedRoute.snapshot.queryParamMap.has('itemid')){
          this.tagerSvc.choiceset = choicedata;
          this.tagerSvc.rootchoiceconsole = false;
          console.log('active choiceset is '+JSON.stringify(this.tagerSvc.choiceset))
        //  this.tagerSvc.getChoicepath(this.configSvc.activeitemid) 
        this.tagerSvc.getsetDetails(this.configSvc.activeitemid, this.configSvc.activetype, this.configSvc.activeteamid)
        
          console.log('active type is set to '+this.configSvc.activetype)
        }
       }, (err) => {
        console.error(err);
       });
    
      
     
    });
  }

  clickedSet(set){
    console.log('clicked set')
    console.log('current set'+JSON.stringify(set));
    this.configSvc.folderpath= set.path
    console.log('folder path is '+this.configSvc.folderpath)
    this.configSvc.mgrsadded= set.mgrs
    console.log('set managers '+this.configSvc.mgrsadded)

  }
       
   

}
