<span *ngIf = "data.type=='folder'">

  
    <div mat-dialog-content>
     <span>
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
    
  
       
        <mat-step [stepControl]="step1">
          <ng-template matStepLabel>
            <i class="material-icons" style="color:yellow">create_new_folder</i>
        
            Folder Name</ng-template>
  
  
  
  <span class = "genpopupbottom">
     <mat-form-field  appearance="fill">
       
       <input matInput [(ngModel)]="data.name" (keyup.enter)="addFolder(data.name, indextype)">
     </mat-form-field></span>
  
  <mat-radio-group [(ngModel)]="indextype" class="indextypes">
    <mat-radio-button value="0">No inherted indexing</mat-radio-button> 
  <span *ngIf="this.configSvc.activelevel != '0'">
    <span  *ngIf="this.configSvc.folderdetailsds.tagged" >
  <mat-radio-button value="1">Index with Foldertags
  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
  {{this.configSvc.folderdetailsds?.nodename}}
  </mat-radio-button></span></span>
  <span *ngIf="this.configSvc.teamdetailsds?.tagged ">
  <mat-radio-button value="2">Index with Teamtags
  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
  {{this.configSvc.teamdetailsds?.nodename}}
  </mat-radio-button></span>
  
  
  <mat-radio-button value="3">Bulk Add Folders with CSV</mat-radio-button>
  </mat-radio-group>
  
  <span *ngIf ="indextype != '0'">
    <button mat-button matStepperNext matSuffix 
    mat-raised-button color="primary">Next </button>
  
    <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
  </span>
  
    <span *ngIf ="indextype == '0'">
      <button  mat-raised-button color="primary"  (click)="addFolder(data.name, indextype)"  [ngStyle]="{'margin-right': '10px'}">Create</button>
    <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
  </span>
  
  
  </mat-step>
  
  <mat-step [stepControl]="step2" *ngIf="indextype =='1'" >  <ng-template matStepLabel>Complete Folder Indexing</ng-template>
  <app-foldertags  [indexlaunch]="'toolbar'"></app-foldertags>
  
  <span >
    <button  mat-raised-button color="primary"  (click)="addFolder(data.name, indextype)"  [ngStyle]="{'margin-right': '10px'}">Create</button>
    <button mat-button matStepperPrevious matSuffix 
    mat-raised-button color="primary">Previous </button>
    <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
  </span>
  
  </mat-step>
  
  <mat-step [stepControl]="step2" *ngIf="indextype =='2'" >  <ng-template matStepLabel>Complete Team Indexing</ng-template>
  
  <app-teamtags></app-teamtags>
  
  <span >
    <button  mat-raised-button color="primary"  (click)="addFolder(data.name, indextype)"  [ngStyle]="{'margin-right': '10px'}">Create</button>
    <button mat-button matStepperPrevious matSuffix 
    mat-raised-button color="primary">Previous </button>
    <button  mat-raised-button color="primary"  (click)="onNoClick()">Cancel</button>
  </span>
  
  
  </mat-step>
  
  <mat-step [stepControl]="step2" *ngIf="indextype =='3'" >  <ng-template matStepLabel>Bulk Add Folders</ng-template>
  
    <span mat-dialog-content>
      Bulk Upload folders to {{this.configSvc.activename}}
       
     </span>
     <div mat-dialog-actions>
      
   <ngx-file-drop dropZoneLabel="Drop csv file here"
      (onFileDrop)="bulkprocesslocal($event, bulkindex)"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
              >
             
             </ngx-file-drop>
   
             <mat-card>
               <mat-card-content>
                 <h2 class="progress-h2">Automation Progess</h2>
                 <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
               
               </mat-card-content>
             </mat-card>
             
   <span *ngIf="this.configSvc.activetype == 'fold'">
             <mat-radio-group [(ngModel)]="bulkindex" class="indextypes"> 
               <span>
               <mat-radio-button value="1">Index with Foldertags
                 <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
                 {{this.configSvc.folderdetailsds?.nodename}}
                 </mat-radio-button></span>
             
         
               <mat-radio-button value="0">No inherted indexing</mat-radio-button>
              </mat-radio-group></span>
   
              <span *ngIf="this.configSvc.activetype == 'editteam' || this.configSvc.activetype == 'team' ">
               <mat-radio-group [(ngModel)]="bulkindex" class="indextypes"> 
               
                 <span *ngIf="this.configSvc.teamdetailsds.tagged">
                 <mat-radio-button value="2">Index with Teamtags
                   <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
                   {{this.configSvc.teamdetailsds?.nodename}}
                 </mat-radio-button></span>
           
                 <mat-radio-button value="0">No Team indexing</mat-radio-button>
                </mat-radio-group>
              </span>
   
             <span class= "genpopupbottom">
              <button mat-button (click)="onNoClick()" 
                        
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Close Upload">Finished Bulk </button></span>
         
     </div>
    
   
    
    
    </mat-step>
  </mat-horizontal-stepper>
    </span>
  </div>
  </span>
  
  <span *ngIf  = "data.type=='bulkadd'"><i class="material-icons icon-docaddpopup">add_box</i>
    <span mat-dialog-content>
     Bulk Upload folders to {{this.configSvc.activename}}
      
    </span>
    <div mat-dialog-actions>
     
  <ngx-file-drop dropZoneLabel="Drop csv file here"
     (onFileDrop)="bulkprocesslocal($event, indextype)"
              (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
             >
            
            </ngx-file-drop>
  
            <mat-card>
              <mat-card-content>
                <h2 class="progress-h2">Automation Progess</h2>
                <mat-progress-bar mode="determinate" value="{{this.configSvc.csvprocessed}}"></mat-progress-bar>
              
              </mat-card-content>
            </mat-card>
            
  <span *ngIf="this.configSvc.activetype == 'fold'">
            <mat-radio-group [(ngModel)]="indextype" class="indextypes"> 
              <span>
              <mat-radio-button value="1">Index with Foldertags
                <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
                {{this.configSvc.folderdetailsds?.nodename}}
                </mat-radio-button></span>
            
        
              <mat-radio-button value="0">No inherted indexing</mat-radio-button>
             </mat-radio-group></span>
  
             <span *ngIf="this.configSvc.activetype == 'editteam' || this.configSvc.activetype == 'team' ">
              <mat-radio-group [(ngModel)]="indextype" class="indextypes"> 
              
                <span *ngIf="this.configSvc.teamdetailsds.tagged">
                <mat-radio-button value="2">Index with Teamtags
                  <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
                  {{this.configSvc.teamdetailsds?.nodename}}
                </mat-radio-button></span>
          
                <mat-radio-button value="0">No Team indexing</mat-radio-button>
               </mat-radio-group>
             </span>
  
            <span class= "genpopupbottom">
             <button mat-button (click)="onNoClick()" 
                       
             matSuffix 
             mat-raised-button color="primary"
             aria-label="Close Upload">Finished Bulk </button></span>
        
    </div>
    </span>
  
    <span *ngIf = "data.type=='folderdetails'">
      <app-editfolder></app-editfolder>
    </span>
  
    <span *ngIf = "data.type=='report'">
      <div mat-dialog-content>
        <i class="material-icons icon-report">picture_as_pdf</i> 
       Generate report for {{this.configSvc.activename}} ?
      </div>
      <div mat-dialog-actions>
        <span class= "genpopupbottom">
          Generating Folder Based report. Note this does not contain photos
          <div><mat-checkbox [(ngModel)]="attachreport">Save Report in Attachments</mat-checkbox></div>
          <div><button mat-button (click)="onNoClick()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Cancel">Cancel</button></div>
    
          <button mat-button (click)="folderReport(attachreport)" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Generate">Create Folder Report</button>
        
        </span>
        
      </div>
      </span>
  
   
   <span *ngIf  = "data.type=='addfiles'"><i class="material-icons icon-docaddpopup">cloud_upload</i>
     <span mat-dialog-content>
       Upload files to {{data.name}}
       
     </span>
     <div mat-dialog-actions>
      
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
    
  
                <mat-step [stepControl]="step1">
                  <ng-template matStepLabel>Index Type</ng-template>
  
  
  
       <mat-radio-group [(ngModel)]="indextype" class="indextypes"> 
        <span *ngIf="this.configSvc.folderdetailsds.tagged">
        <mat-radio-button value="1">Index with Foldertags
          <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.folderdetailsds?.tagcolor}">bookmarks</i>
          {{this.configSvc.folderdetailsds?.nodename}}
          </mat-radio-button></span>
        <span *ngIf="this.configSvc.teamdetailsds?.tagged">
        <mat-radio-button value="2">Index with Teamtags
          <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.teamdetailsds?.tagcolor}">bookmarks</i>
          {{this.configSvc.teamdetailsds?.nodename}}
        </mat-radio-button></span>
  
        <mat-radio-button value="0">No inherted indexing</mat-radio-button>
       </mat-radio-group>
  
       <button mat-button matStepperNext matSuffix 
       mat-raised-button color="primary">Next </button>
  </mat-step>
  
  <mat-step [stepControl]="step2" *ngIf="indextype =='1'" >  <ng-template matStepLabel>Complete Folder Indexing</ng-template>
  <app-foldertags  [indexlaunch]="'toolbar'" ></app-foldertags>
  
  <button mat-button matStepperNext matSuffix 
  mat-raised-button color="primary">Next </button>
  </mat-step>
  
  <mat-step [stepControl]="step2" *ngIf="indextype =='2'" >  <ng-template matStepLabel>Complete Team Indexing</ng-template>
  
  <app-teamtags></app-teamtags>
  
  <button mat-button matStepperNext matSuffix 
  mat-raised-button color="primary">Next </button>
  </mat-step>
  
  
  <mat-step [stepControl]="step3">   <ng-template matStepLabel>Upload files</ng-template>
  
    <div *ngIf="!imageready">
      <label for="files">Select files:</label>
      <input type="file"
             id="files"
             name="files" 
             multiple
             (change)="filedropped($event.target.files)">
     </div>
      <div *ngIf="imageready">
      
     
        <span > <button mat-raised-button color="primary" (click)="filedropped($event, indextype)" class = "buttonstyle">Save Files </button></span>
      </div>
  
  <ngx-file-drop dropZoneLabel="Or Drop files here"
      (onFileDrop)="dropped($event, indextype)"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
              >
             
             </ngx-file-drop>
  
             <mat-card>
              <mat-card-content>
                <h2 class="progress-h2">File Upload Progess</h2>
                <mat-progress-bar mode="determinate" value="{{this.uploadSvc.filesprocessed}}"></mat-progress-bar>
              
              </mat-card-content>
            </mat-card>
             <span class= "genpopupbottom">
              <button  [disabled]= "!this.uploadSvc.uploadfinished"  mat-button (click)="onNoClick()" 
                        
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Close Upload">Finished Uploading</button></span>
            </mat-step>
      </mat-horizontal-stepper>
     </div>
     </span>
  
     <span *ngIf  = "data.type=='createteam'"><i class="material-icons icon-docaddpopup">group</i>
      <span mat-dialog-content>
       Create new Team
        
      </span>
      <div mat-dialog-actions>
       
        <mat-card class = "build-container">
  
        
                   <span *ngIf="this.configSvc.teamtype=='node'"><mat-card-title>Node Team Setup</mat-card-title></span> 
                    <mat-horizontal-stepper [linear]="isLinear" #stepper>
                   
                   
                      <mat-step [stepControl]="step1">
        <form [formGroup]="teamfileFG">
                <ng-template matStepLabel>
                       Team Name
                       </ng-template>
            <mat-form-field appearance="fill" class= "large-item">
                <mat-label>Create a new Team</mat-label>
                <input formControlName ="teamname"  matInput placeholder="Team Name" >
                    <i  class="material-icons icon-group" matSuffix style="color:black">group</i>
                </mat-form-field>
    <div>
                <mat-form-field appearance="fill" class= "large-item">
                  <mat-label>Description of Team</mat-label>
                  <input formControlName ="teamdescription"  matInput placeholder="Description" >
                     
                  </mat-form-field></div>
      
                <div>
                       
                    <button  mat-raised-button color="primary" (click)="this.addMyself();" mat-button matStepperNext>Next</button>
                    </div>
                </form>
                </mat-step>
    
                <mat-step [stepControl]="step2">
                    <ng-template matStepLabel>Managers </ng-template>
                      
                           <app-teamsecurity></app-teamsecurity>   
                           <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                           <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                           <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                      
                    </mat-step>
  
                    <mat-step [stepControl]="step3">
                      <ng-template matStepLabel>Users </ng-template>
                        
                             <app-usersecurity></app-usersecurity>   
                             <div *ngIf="this.configSvc.activetype == 'team' || this.configSvc.activetype == 'addnodeteam'">     
                             <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px;">Back</button>
                             <button mat-raised-button color="primary"  mat-button matStepperNext>Next</button>    </div>       
                                        
                      </mat-step>
    
                    <mat-step [stepControl]="step4">
                            <form [formGroup]="teamfileFG">
                                    <ng-template matStepLabel>
                                           Design Icon
                                           </ng-template>
                                           <div>
                                            <span>
                                              <app-iconcategories></app-iconcategories></span>
                                                  <span class ="colorbox">
                                                  <app-color-picker (color)="this.configSvc.color"></app-color-picker>
                                                </span>
                                            
                                                <button mat-raised-button color="primary"  mat-button matStepperPrevious style="margin: 10px">Back</button>
                                              <button mat-raised-button color="primary" mat-button matStepperNext>Next</button>
                                            </div>
                                           </form>
                        </mat-step>
    
                      
    
                        <mat-step [stepControl]="step5">
                                <form [formGroup]="teamfileFG">
                                        <ng-template matStepLabel>
                                               Create Team 
                                               </ng-template>
                                             
                                               
                                               <div class = "summary" >
               
                
               
                                                <mat-form-field appearance="outline" floatLabel="always" >
                                                <mat-label >TeamName</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamname}}>
                                                </mat-form-field>
    
                                                <mat-form-field appearance="outline" floatLabel="always" >
                                                  <mat-label >TeamDescription</mat-label><input  matInput placeholder={{this.teamfileFG.value.teamdescription}}>
                                                  </mat-form-field>
                                             
                                              
                                                
                                                  <div class="example-list">
                 <mat-label>Managers</mat-label>
                        <div class="box-list" *ngFor="let manager of this.configSvc.mgrsadded" (click)="this.configSvc.selectMgrRow(manager, true)"
                              [style.background]=" this.configSvc.highlightedMgrRow.indexOf(manager) == 0 ? 'lightblue' : ''">
                                        <div>                                                                                                      
                                                    
     </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{manager}}
              </div></div>
              <mat-label>Users</mat-label>
  <div class="box-list" *ngFor="let users of this.configSvc.usersadded" (click)="this.configSvc.selectUserRow(users, true)"
                [style.background]=" this.configSvc.highlightedUserRow.indexOf(users) == 0 ? 'lightblue' : ''">
                   <div> </div><div><i MatPrefix class="fas fa-user" style="color:green"></i>{{users}}
                                                      </div></div>
  
                                                    </div>
  
  
                                            
                                             
                                                 <mat-label>Icon</mat-label>
                                                 <div>
                                                   <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.color || this.configSvc.teamdetailsds?.clr}" >{{this.configSvc.chosenIcon}} </i>
                                                  
                                                  </div>
      </div>
    
      <span *ngIf="this.configSvc.teamtype=='folder'">
     <button mat-raised-button color="primary"  (click)="this.configSvc.createTeam(false, 'team', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
     this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenIcon, false)
          ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Create Folder Team</button></span>
          
          <span *ngIf="this.configSvc.teamtype=='node'">
            <button mat-raised-button color="primary"  (click)="this.configSvc.nodeTeam('nodeteam', 'base', this.teamfileFG.value.teamname,this.teamfileFG.value.teamdescription, 0, 
            this.configSvc.mgrsadded, this.configSvc.usersadded, this.configSvc.color, this.configSvc.chosenregisterIcon, false)
            ; this.teamfileFG.reset(); this.dialogRef.close();" mat-button matStepperNext>Create Node Team</button>
          </span>
    
    
                  <span>
                        <button mat-raised-button color="primary" mat-button matStepperPrevious style="margin: 10px;">Back</button>
                  </span>
                                               </form>
                            </mat-step>
    
                          
            </mat-horizontal-stepper>
          </mat-card>
      </div>
      </span>
  
  
  
   
     <span *ngIf = "data.type=='comment'" class = "cmt">
      <div mat-dialog-actions>
  
  
  
        <span>
      <div>
        Comment on {{this.configSvc.activename}}
    
      </div>
  
  
      <mat-form-field appearance="outline"   class = "textarea">
        <textarea
        
        matInput [(ngModel)]="data.name"></textarea>
       </mat-form-field>
    
    </span>
  <div>
    <mat-button-toggle-group [(ngModel)]="this.configSvc.chosenIcon"  aria-label="Comment Icon">
      <mat-button-toggle value="cmt"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
      <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
      <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
      <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
    </mat-button-toggle-group></div>
  
  <div >
  <mat-checkbox [(ngModel)]="this.configSvc.emoji" >Change Emoji
    <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
    
    </mat-checkbox></div>
  
   
  
    <div *ngIf="this.configSvc.emoji">
      <span>
     <app-iconcategories></app-iconcategories></span>
     <span class ="colorbox">
      <app-color-picker (color)="this.configSvc.color"></app-color-picker>
    </span>
    </div>

    <div *ngIf="this.configSvc.atcmt" class = space>

      <mat-form-field appearance="fill">
        <mat-label>Reassign</mat-label>
       
        <input type="text"  
        [(ngModel)]="username"
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
    {{option.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field>
      
  
  
  
  
  </div>
  
    <button mat-button (click)="addComment(data.name)" 
                        
    matSuffix 
    mat-raised-button color="primary"
    aria-label="Add Comment">Add Comment</button>
  
  
  
  
  
  
  
   
       </div>
       </span>
   
   
       <span *ngIf = "data.type=='unlock'">
         <div mat-dialog-content>
           <i class="material-icons icon-locked">lock_open</i> Unlock {{this.configSvc.currentrow.name}}
          
         </div>
         <div mat-dialog-actions>
           <div  class = "droparea">
             <ngx-file-drop dropZoneLabel="Drop new version of file here"
              (onFileDrop)="dropversion($event, teamtags, foldertags)"
                       (onFileOver)="fileverOver($event)" (onFileLeave)="fileverLeave($event)"
                      ></ngx-file-drop>
  
                  
                       
           </div>
      
            
          <span class= "genpopupbottom">
           <button mat-button (click)="onNoClick()" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Close Upload">Finished Uploading</button></span>
          
           
         </div>
    </span>
   
    <span *ngIf = "data.type=='deletefold'">
    
     <div mat-dialog-content>
       <i class="material-icons icon-delete">delete</i> 
       Are you sure you want to delete folder {{data.name}}    
     </div>
     <div mat-dialog-actions>
       <span class= "genpopupbottom">
         <button mat-button (click)="onNoClick()" 
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Close Upload">Cancel</button>
   
         <button mat-button (click)="delete('folder')" 
         
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Delete"
         
         >Delete Folder</button>
       
       </span>
       
     </div>
     </span>
   
     <span *ngIf = "data.type=='deletefile'">
       <div mat-dialog-content>
         <i class="material-icons icon-delete">delete</i> 
         Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
       </div>
       <div mat-dialog-actions>
         <span class= "genpopupbottom">
           <button mat-button (click)="onNoClick()" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Cancel">Cancel</button>
     
           <button mat-button (click)="delete('item')" 
                 
           matSuffix 
           mat-raised-button color="primary"
           aria-label="Delete">Delete</button>
         
         </span>
         
       </div>
       </span>
  
       <span *ngIf = "data.type=='moveFolder'">
        <div mat-dialog-content>
          <i class="material-icons icon-delete">content_cut</i> 
          Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
        </div>
        <div mat-dialog-actions>
          <span class= "genpopupbottom">
            <button mat-button (click)="onNoClick()" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Cancel">Cancel</button>
      
            <button mat-button (click)="moveFolder(this.configSvc.currentrow._id)" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Delete">Move Folder</button>
          
          </span>
          
        </div>
        </span>
  
        <span *ngIf = "data.type=='cutItem'">
          <div mat-dialog-content>
            <i class="material-icons icon-delete">content_cut</i> 
            Are you sure you want to move queue item {{this.configSvc.currentrow.name}} ?
          </div>
          <div mat-dialog-actions>
            <span class= "genpopupbottom">
              <button mat-button (click)="onNoClick()" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cancel">Cancel</button>
        
              <button mat-button (click)="cutItem(this.configSvc.currentrow._id, 
              this.configSvc.currentrow.type, this.configSvc.currentrow.level, 
              this.configSvc.currentrow.teamid, this.configSvc.currentrow.name, 
              this.configSvc.currentrow.icontype, this.configSvc.currentrow.iconcolor)" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cut Item">Cut Item</button>
            
            </span>
            
          </div>
          </span>
  
          <span *ngIf = "data.type=='pasteItem'">
            <div mat-dialog-content>
              <i class="material-icons icon-delete">content_paste</i> 
              Are you sure you want to paste {{this.configSvc.moveitem[0].name}} ?
            </div>
            <div mat-dialog-actions>
              <span class= "genpopupbottom">
                <button mat-button (click)="onNoClick()" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Cancel">Cancel</button>
          
                <button mat-button (click)="pasteItem(this.configSvc.moveitem[0]?.itemid, this.configSvc.activeitemid,
                this.configSvc.moveitem[0]?.type,this.configSvc.moveitem[0]?.level,
                 this.configSvc.moveitem[0]?.teamid, this.configSvc.moveitem[0]?.name, 
                 this.configSvc.moveitem[0]?.icontype, this.configSvc.moveitem[0]?.iconcolor)" 
                      
                matSuffix 
                mat-raised-button color="primary"
                aria-label="Paste Item">Paste Item</button>
              
              </span>
              
            </div>
            </span>
  
  
   
       