<span class= "comments" >
    <form  [formGroup]="summaryFG" >

<div>

  <span class = "labels">
  
   </span> 

 
   <div>
    <div>
      <span>
        <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"

        ></mat-icon> <b>Prompt:</b></span>
       </div>
  <mat-form-field appearance="outline"   class = "textarea">
    <textarea 
                   
 
    
    formControlName="prompt"
     matInput value={{this.configSvc.nodeds[0].prompt}}

     cdkTextareaAutosize
     cdkAutosizeMinRows=this.textarearowmin
     cdkAutosizeMaxRows=this.textarearowmax
     ></textarea>
    </mat-form-field>
</div>

<div>
  <div><b>ChatGPT Response:</b></div>
  <mat-form-field appearance="outline"   class = "textarea">
    <textarea 
                   
 
    
    formControlName="summary"
     matInput value={{this.configSvc.nodeds[0].summary}}

     cdkTextareaAutosize
     cdkAutosizeMinRows=this.textarearowmin
     cdkAutosizeMaxRows=this.textarearowmax
     ></textarea>
    </mat-form-field>
</div>


   </div>


 </form> </span>
