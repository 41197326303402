<div cdkDropListGroup>
    <div class="board" >
    
      <div  class="kanban-container"
      
      *ngFor="let track of this.sortKanban()" 
      cdkDropList [id]="track.track" 
      [cdkDropListData]="track.kanitems"
      [cdkDropListConnectedTo]="tracknames" 
      (cdkDropListDropped)="onTaskDrop($event)" 
      cdkDragHandle>
    
      <mat-card><span class= "tracks">
        <h2  class="mat-h2">{{track.track}}</h2>
        <i class="material-icons md-24" [ngStyle]="{'color': this.configSvc.nodeds[0]?.kanbancolor}"
        matTooltip={{track.desc}}  matTooltipPosition= 'after'
        >info</i></span>
       </mat-card>
    
     
       
    
            <mat-card class ="drag-items" *ngFor="let element of track.kanitems" cdkDrag>
              <!-- Use the mat-card-content to add the proper spacing. -->
              <span class="flex-container"  (dblclick)="this.openDialog(element, track.track)">
              <div class = "items">
                <div *ngIf="element.kitype == 'node' || element.kitype == 'wizard' || element.kitype == 'nodetab'"><i class="material-icons md-48" [ngStyle]="{'color': element.kiclr || 'black'}" >{{element.kiicon}} </i></div>
                <div *ngIf="element.kitype == 'pdf'" class="fappt
                -2x"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i></span></div>
                <div *ngIf="element.kitype == 'msg'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i></span>
                    <div  *ngIf="element.AttachCnt > 0"><i class="fas fa-paperclip" data-fa-transform="shrink-9" ></i><span class="fa-layers-counter" style="background:Tomato">{{element.AttachCnt}}</span></div>
                </div>
                 <div *ngIf="element.kitype == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                <div *ngIf="element.kitype == 'xlsx'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                <div *ngIf="element.kitype == 'docx'" class="fa-2x"><span class="fa-layers fa-fw">
                        <span><i class="fas fa-file-word" data-fa-transform="shrink-5" style="color:blue"></i></span></span>
                      </div>
              
                <div *ngIf="element.kitype == 'bmp' || element.kitype == 'jpg' || element.kitype == 'png'  " class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
              
                  {{element.kiname}}</div>
    <div>
                  <div class ="badge-container">
                    <span *ngIf=" element.kitime != '0'" class ="badge"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}"  matBadge="{{element.kitime}}" matBadgePosition="after" matBadgeColor="accent">schedule</i></span>
                    <span *ngIf=" element.kiworker != '1'" class ="badge">  <i class="material-icons md-48" [ngStyle]="{'color': 'green'}" matBadge="{{element.kiworker}}" matBadgePosition="after" matBadgeColor="accent">low_priority</i></span></div>
                </div> </span>
            </mat-card>
    
       
    
      </div>
    
    </div>
    