import { Injectable } from '@angular/core';
import {Profilemodel} from '.././model/profilemodel';
import { AuthenticationService, UserDetails, TokenPayload } from './authentication.service';
import {ConfigService} from '../services/config.service';
@Injectable({
  providedIn: 'root'
})
export class PrefsService {
  public favlocations = [];
  user: TokenPayload;
  public defloc
  public toolfavs =[];
  public bookmarks = [];
  public filter = false;
  public userfilter = '';
  public search =[];
  public feedsearch = '';
  public feedurl ='';
  public profileready = false;
  public hc = false
  public profileds: Profilemodel;
  public assignedto = '';
  public state= false
  public pagesize= 0

  constructor(private authService: AuthenticationService,  public configSvc: ConfigService) { }

  public saveSearch(name,searchurl)  {
 
    console.log('Saving search for'+name)
    
    const savesearchForm = new FormData();
   
  
  
    savesearchForm.append('name', name);
    savesearchForm.append('searchurl', searchurl);
   
    
   
  
  
    this.authService.saveSearch(savesearchForm, this.user).subscribe(savesearchresults => {
      console.log('this is the results from adding tool to favs: ' + JSON.stringify(savesearchresults));
       this.profileds = savesearchresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }

  public addBookmark(itemid, type, name, level, teamid)  {
 
    console.log('Adding bookmark for '+name)
    
    const addbookmarkForm = new FormData();
   
  
    addbookmarkForm.append('itemid', itemid);
    addbookmarkForm.append('type', type);
    addbookmarkForm.append('name', name);
    addbookmarkForm.append('level', level);
    addbookmarkForm.append('teamid', teamid);
    
   
  
  
    this.authService.addBookmark(addbookmarkForm, this.user).subscribe(addbookmarkresults => {
      console.log('this is the results from adding tool to favs: ' + JSON.stringify(addbookmarkresults));
       this.profileds = addbookmarkresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }
  getfavLocations(){
    const favlocForm = new FormData();
    favlocForm.append('type', 'favlocations');
  
    
    
   
    this.authService.getfavLocations(favlocForm, this.user).subscribe(favlocresults => {
      console.log('this is the results from getting fav toolbars ' + JSON.stringify(favlocresults));
      this.favlocations = favlocresults
  console.log('favlocations' +JSON.stringify(this.favlocations))
 
 
      
    }, (err) => {
      console.error(err); });
    
    }

  public removeToolfavs(toolid, name)  {
 
    console.log('Removing fav tools for '+toolid)
    
    const removetoolsfavForm = new FormData();
   
    removetoolsfavForm.append('toolid', toolid);
    removetoolsfavForm.append('name', name);
   
  
  
    this.authService.removeToolfavs(removetoolsfavForm, this.user).subscribe(removetoolfavresults => {
      console.log('this is the results from adding tool to favs: ' + JSON.stringify(removetoolfavresults));
       this.toolfavs = removetoolfavresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }

  public updateToolfavs(toolfavs)  {
 
    console.log('updated fav tools for '+toolfavs)
    
    
    const movetoolsfavForm = new FormData();
   
    movetoolsfavForm.append('toolfavs', toolfavs);

  
  
    this.authService.updateToolfavs(movetoolsfavForm, this.user).subscribe(movetoolfavresults => {
      console.log('this is the results from adding tool to favs: ' + JSON.stringify(movetoolfavresults));
       this.toolfavs = movetoolfavresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }


  public updatePrefs(defloc, hc, pagesize,assignedto, state, filter, userfilter?)  {
 
   
    
    const updateprefsForm = new FormData();
   
  
    updateprefsForm.append('defloc', defloc);
    updateprefsForm.append('hc', hc);
    updateprefsForm.append('filter', filter);
    updateprefsForm.append('assignedto', assignedto);

    updateprefsForm.append('state', state);
    updateprefsForm.append('pagesize', pagesize);
 
    if(filter){
     updateprefsForm.append('userfilter', userfilter);
    }
 
  
    this.authService.updatePrefs(updateprefsForm, this.user).subscribe(profileupdateresults => {
      console.log('this is the results from getting profile: ' + JSON.stringify(profileupdateresults));
       this.profileds = profileupdateresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }

  public getProfile(userid)  {
 
    this.profileready = false;
    
    console.log('profileready is  '+this.profileready )
     console.log('getting email for emailid '+userid)
     
     const profileForm = new FormData();
     
     console.log('profileds before getting results'+JSON.stringify(this.profileds))
     profileForm.append('userid', userid);
   
     this.authService.profile(profileForm, this.user).subscribe(profileresults => {
       console.log('this is the results from getting profile: ' + JSON.stringify(profileresults));
        this.profileds = profileresults[0];
        console.log('profileds after getting results'+JSON.stringify(this.profileds))
        
    
       
     this.profileready = true;
     console.log('profileready after results is  '+this.profileready )
   
      
       
      }, (err) => {console.error(err); });
   
   }
/*
   public addToolfavs(userid, toolid, icontype, iconcolor, name)  {
 
    console.log('Adding fav tools for '+userid)
    
    const addtoolsfavForm = new FormData();
   
  
    addtoolsfavForm.append('userid', userid);
    addtoolsfavForm.append('toolid', toolid);
    addtoolsfavForm.append('icontype', icontype);
    addtoolsfavForm.append('iconcolor', iconcolor);
    addtoolsfavForm.append('name', name);
    addtoolsfavForm.append('type', 'favs');
   
  
  
    this.authService.addToolfavs(addtoolsfavForm, this.user).subscribe(addtoolfavresults => {
      console.log('this is the results from adding tool to favs: ' + JSON.stringify(addtoolfavresults));
       this.profileds = addtoolfavresults;
       
   
      
    this.profileready = true;
    console.log('profileready after results is  '+this.profileready )
  
     
      
     }, (err) => {console.error(err); });
  
  }
  */
 
}
