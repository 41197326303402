<mat-card class ="container">
 
  
    <h4> <i class="material-icons icon-kanban" 
      [ngStyle]="{'color': 'deepred'}" >
      directions </i>Tab Details </h4>
    
    <form [formGroup]="editstepFG">
    <span  class="track-items">
    
    <div>
            <mat-form-field appearance="fill"> 
              
                   <mat-label >Step Name</mat-label>
                   <input formControlName="step" matInput value="{{this.configSvc.stepdetailsds[0].step}}">
                  </mat-form-field></div>
               <div>  <mat-form-field appearance="fill"> 
                  <mat-label >Step Description</mat-label>
                  <input formControlName="desc" matInput value="{{this.configSvc.stepdetailsds[0].desc}}">
                 </mat-form-field></div> 

                 <div>  <mat-form-field appearance="fill"> 
                  <mat-label >Step ID</mat-label>
                  <input formControlName="stepid" matInput value="{{this.configSvc.stepdetailsds[0]._id}}">
                 </mat-form-field></div> 

                 <div *ngIf="this.configSvc.stepdetailsds[0].type == 'linktab'">

              

                <div>  <mat-form-field appearance="fill"> 
                  <mat-label >Tool</mat-label>
                  <input formControlName="tool" matInput value="{{this.configSvc.stepdetailsds[0].tool}}">
                 </mat-form-field></div> 

                 <div>  <mat-form-field appearance="fill"> 
                  <mat-label >ToolIndex</mat-label>
                  <input formControlName="toolindex" matInput value="{{this.configSvc.stepdetailsds[0].toolindex}}">
                 </mat-form-field></div> 

                 
                 <div>  <mat-form-field appearance="fill"> 
                  <mat-label >ToolIndexValue</mat-label>
                  <input formControlName="toolinval" matInput value="{{this.configSvc.stepdetailsds[0].toolinval}}">
                 </mat-form-field></div>
                 
                </div>
    <div>
    <mat-form-field appearance="outline" floatLabel="always" >
                  
      <mat-label >Added By</mat-label>
      <input 
      matInput [readonly]= "true" 
      formControlName="addedby"  matInput value="{{this.configSvc.stepdetailsds[0].ab}}">
    </mat-form-field> </div>
    <div>
    <mat-form-field appearance="outline" floatLabel="always" >
    
      <mat-label >Added On</mat-label>
      <input 
      matInput [readonly]= "true" 
      [ngModelOptions]="{standalone: true}"
      [ngModel]="this.configSvc.stepdetailsds[0].ao | date: 'medium'"
      ></mat-form-field></div>

      <div>
        <mat-form-field appearance="outline" floatLabel="always" >
                      
          <mat-label >Modified By</mat-label>
          <input 
          matInput [readonly]= "true" 
          formControlName="modifiedby" matInput value="{{this.configSvc.stepdetailsds[0].mb}}">
        </mat-form-field> </div>
        <div>
        <mat-form-field appearance="outline" floatLabel="always" >
        
          <mat-label >Modified On</mat-label>
          <input 
          matInput [readonly]= "true" 
          [ngModelOptions]="{standalone: true}"
          [ngModel]="this.configSvc.stepdetailsds[0]?.mo | date: 'medium'"
          ></mat-form-field></div>
    <div *ngIf="this.configSvc.stepdetailsds[0].type != 'linktab'">
      <span> <app-iconcategories></app-iconcategories></span>
  
      
      <mat-checkbox formControlName="changecolor" >Change Colour</mat-checkbox>
  
        <span class ="colorbox" *ngIf="this.editstepFG.value.changecolor">
          <app-color-picker (color)="this.configSvc.color"></app-color-picker></span>
        </div>
        
    </span>
    </form>
    
    
    
    
    
  
    <div class="track-items">
    <span>
      <button [disabled]= "!this.configSvc.colorchanged && !this.editstepFG.dirty" (click)="this.updateStep();"
               matSuffix 
               mat-raised-button color="primary"
               aria-label="Update Tab ">
     Update Tab</button></span>
  
  <span>
     <button (click)="this.deleteStep();"
     matSuffix 
     mat-raised-button color="primary"
     aria-label="Delete Step ">
  
  Delete Step</button></span></div>
  
</mat-card>
  