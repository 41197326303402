import { Component, OnInit, Inject } from '@angular/core';
import {FormControl, FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import { AuthenticationService } from '.././../services/authentication.service';
import {ConfigService} from '.././../services/config.service';
import {TagerService} from '.././../services/tager.service';
import { TagService } from '.././../services/tag.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
 
  name: string;
  type: string;
}


@Component({
  selector: 'app-filetags',
  templateUrl: './filetags.component.html',
  styleUrls: ['./filetags.component.css']
})
export class FiletagsComponent implements OnInit {

 
  public tagsArray: FormArray;
  public tagsFG: FormGroup;
  public detailsFG: FormGroup;
  public boolchoice =[true, false]
  // public newpropvalues = [];
  stpcnt: string;
  hltreepath =[];
  sorted=[];
  clickchoiceset = false;
  chipselected = false;
  hlchoiceset = [];
  treeColumns = ['Name']


  constructor(private fb: FormBuilder, public configSvc: ConfigService, public tagerSvc: TagerService, 
    public tagSvc: TagService, private authSvc: AuthenticationService, public dialog: MatDialog) { }
    

    ngOnInit() {
      
      this.tagsFG = new FormGroup({
        formArrayName: this.fb.array([])
      })

      
      this.detailsFG = this.fb.group({
        name : [this.configSvc.nodeds[0].name, Validators.required],
        parentid: [this.configSvc.nodeds[0].parentid, Validators.required],
        type: [this.configSvc.nodeds[0].type, Validators.required],
        addedon: [this.configSvc.nodeds[0].ao, Validators.required],
        addedby: [this.configSvc.nodeds[0].ab, Validators.required],
        desc: [this.configSvc.nodeds[0].desc, Validators.required],
        basedon: [this.configSvc.nodeds[0].bo, Validators.required],
        checked: false,
      });
  
     // this.buildTemplate();
     console.log('building tags now');
     
     this.buildstepitems();
    this.detectNodechanges();
    }

    ngOnDestroy() {
      console.log('destroying component now');
      // this.configSvc.nodeds[0] =null;
      this.tagsArray = null;
    }
    

    
    
    buildstepitems(){

      console.log('this is the node data we are workin with: '+JSON.stringify(this.configSvc.nodeds[0]));
            var choicesetids= []
            this.tagsArray = this.tagsFG.get('formArrayName') as FormArray;
           

           
           console.log('this is the formstep array'+this.configSvc.nodeds[0].stepitems);
           Object.keys(this.configSvc.nodeds[0].stepitems).forEach((i2) => {
            console.log('tthis is the item number in the step'+i2);
            this.stpcnt = i2;
            console.log('tthis is the formitem name '+JSON.stringify(this.configSvc.nodeds[0].stepitems[i2].formitem));
         
       // console.log('this is the stepitem property for step'+this.configSvc.nodeds[0].stepitems[i].formitem);
           // console.log('this is the step item value'+this.configSvc.nodeds[0].stepitems[i].value);
  
              this.tagsArray.push( this.fb.group({
                
                 formitem: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].formitem, emitEvent:false}),
                 label: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].label, emitEvent:false}),
                 formitemlabel: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].formitemlabel, emitEvent:false}),
                itemvalue: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].itemvalue, disabled: false  }),
                 formtype: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].formtype}),
               formchoices: new FormControl({ value:this.configSvc.nodeds[0].stepitems[i2].formchoices}),
              choicesetid: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].choicesetid}),
              choicesetname: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].choicesetname}),
              choicevalue: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].choicevalue, disabled: false }),
              hasdefault: new FormControl(this.configSvc.nodeds[0].stepitems[i2].hasdefault),
              treepath: new FormControl({ value: this.configSvc.nodeds[0].stepitems[i2].treepath, emitEvent:false}),
              
                }))
  
                console.log('tags array is now:'+this.tagsArray);
                
                if (this.configSvc.nodeds[0].stepitems[i2].formtype=='choicelist' || this.configSvc.nodeds[0].stepitems[i2].formtype=='radiogroup'
                  && localStorage.getItem(this.configSvc.nodeds[0].stepitems[i2].choicesetid)===null){ 
                   
                   
                  console.log('this property has a list associated with it and its not in cache, lets go and get it')
               // choicesetids.push(this.configSvc.nodeds[0].stepitems[i2].choicesetid)
                console.log('choicesetids list is now'+choicesetids);
               this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepitems[i2].choicesetid, 'cache')

                }

                if (this.configSvc.nodeds[0].stepitems[i2].formtype=='choicelist' ||  this.configSvc.nodeds[0].stepitems[i2].formtype=='radiogroup'
                  && localStorage.getItem(this.configSvc.nodeds[0].stepitems[i2].choicesetid)!==null){ 
                  console.log('check to see if the cache variable has the localstorage cache')
                  console.log('this is the choicesetid: '+this.configSvc.nodeds[0].stepitems[i2].choicesetid)
               // this.tagerSvc.getFormChoices(this.configSvc.nodeds[0].stepitems[i2].choicesetid)
               var localdata = JSON.parse(localStorage.getItem(this.configSvc.nodeds[0].stepitems[i2].choicesetid))
               console.log('this is the localdata im pushing'+localdata);
               if(!this.authSvc.choicesetids.includes(this.configSvc.nodeds[0].stepitems[i2].choicesetid)){
                this.authSvc.choicesetids.push(this.configSvc.nodeds[0].stepitems[i2].choicesetid)
                console.log('we havent added this to the auth localcache yet: '+this.configSvc.nodeds[0].stepitems[i2].choicesetid);
                console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
                this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
              }
              
              
              }

              if (this.configSvc.nodeds[0].stepitems[i2].formtype == 'treelist'){
                this.hltreepath = this.configSvc.nodeds[0].stepitems[i2].treepath
                  console.log('this is it he initial hl tree path '+JSON.stringify( this.hltreepath));
                 this.tagerSvc.getChoicesetbyid(this.configSvc.nodeds[0].stepitems[i2].choicesetid, 'treelist')
                 
              }

               })
}
   
    

  completeTags(type){
    console.log('type is: '+type)



  console.log ('get the folder details now')

  }

  public getChoices(choicesetid){
    console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
    
    var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
    console.log('this is the filtered choicelist: '+filtered)
    return filtered;
     }

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log('event firing')
    this.events.push(`${type}: ${event.value}`);
    console.log('events'+this.events)
  }
  
  detectNodechanges(){
    
    console.log('checking for changes to the node');
    this.tagsFG.get('formArrayName').valueChanges
    
    .subscribe(
      data => {
        this.configSvc.newpropvalues = [];
      console.log('new propvalues: ' + JSON.stringify(data));
      this.tagsFG.markAsDirty();
      for (let properties of data) {
        console.log('full property is simply '+JSON.stringify(properties));
        console.log('property name is '+JSON.stringify(properties.formitem.value));
        console.log('property value is '+JSON.stringify(properties.itemvalue));
        console.log('label is '+JSON.stringify(properties.label.value));
        console.log('pformitemlabel is '+JSON.stringify(properties.formitemlabel.value));
        console.log('checkbox is '+JSON.stringify(properties.checkbox));
        console.log('textarea is '+JSON.stringify(properties.textarea));
        if(properties.formtype.value != 'text'){properties.textarea = ''}
  
        if(properties.formtype.value=='choicelist' ){
          console.log('there ARE choicesets associated with this property')
        this.configSvc.newpropvalues .push({
          formitem:properties.formitem.value, 
          itemvalue:properties.itemvalue, 
          formchoices:properties.formchoices.value, 
          checkbox:properties.checkbox,
          formtype: properties.formtype.value,   
          formitemlabel: properties.formitemlabel.value, 
          textarea: properties.textarea, 
          label: properties.label.value, 
          choicesetid:properties.choicesetid.value, 
          hasdefault: properties.hasdefault})}

          if( properties.formtype.value=='radiogroup'){
            console.log('there ARE choicesets associated with this property')
          this.configSvc.newpropvalues .push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
            formchoices:properties.formchoices.value, 
            checkbox:properties.checkbox,
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
            textarea: properties.textarea, 
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault})}
          if(properties.formtype.value=='treelist'){
            console.log('pushing new values based on treelist')
            console.log('changes to tree list based on this: '+JSON.stringify(this.hltreepath))
          this.configSvc.newpropvalues .push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
           
            formtype: properties.formtype.value,   
            formitemlabel: properties.formitemlabel.value, 
           
            label: properties.label.value, 
            choicesetid:properties.choicesetid.value, 
            hasdefault: properties.hasdefault,
            treepath: this.hltreepath
          })}
        if(properties.formtype.value=='string'|| properties.formtype.value=='number' ||
        properties.formtype.value=='checkbox' || properties.formtype.value=='date' ||
        properties.formtype.value=='text'|| properties.formtype.value=='boolean' 
        ){ 
          console.log('there are no choiceset associated with this property')
          this.configSvc.newpropvalues .push({
            formitem:properties.formitem.value, 
            itemvalue:properties.itemvalue, 
            formchoices:properties.formchoices.value, 
            checkbox:properties.checkbox,
            formtype: properties.formtype.value,
             formitemlabel: properties.formitemlabel.value,  
             textarea: properties.textarea, 
             label: properties.label.value, 
             choicesetid: 'nocs',
           
            hasdefault: properties.hasdefault})
            
          
            console.log('this is the updated tags:'+JSON.stringify(this.configSvc.newpropvalues ))
          }
     
          
      //  this.configSvc.newpropvalues .push({property:properties.property.value, propval:properties.propvalue, formtype:properties.formtype.value})
      }
      
      }
    ); 
  }

  updateTags(){
    console.log('updating the followingtags')

      this.configSvc.updateFoldertags(this.configSvc.newpropvalues , this.configSvc.activetype, this.configSvc.nodeds[0]._id)
        
    this.tagsFG.markAsPristine();
  }

  toggleChange(){
     
    
    //  this.edittagFG.value.hasdefault = !this.edittagFG.value.hasdefault
     this.tagsFG.value.hasdefault = !this.tagsFG.value.hasdefault 
     console.log('changed current toggle value TO '+this.tagsFG.value.hasdefault  )
     this.tagsFG.markAsDirty();
   }  

   updateSlider(trueorfalse, count){
    console.log('changed current toggle value TO '+trueorfalse  )
    console.log('count '+count  )
    this.tagsFG.markAsDirty();
   }

   public sortPath(){
    
    
    // console.log('this is the current treepath'+JSON.stringify(this.hltreepath))
   this.sorted = this.hltreepath.sort((a, b) => Number(a.level) - Number(b.level));
   //console.log('this is the sorted result: '+JSON.stringify(this.sorted))
     return this.sorted
   
   }

 
   
   public getChoicetree(choicesetid){
   
     this.getChip(choicesetid)
      this.sorted = [];
   
   
    
     this.clickchoiceset = true; 
      console.log('getting the choice tree for '+choicesetid)
      console.log('here is the choicesetids already cached '+JSON.stringify(this.authSvc.choicesetids))
    
      this.sortPath()
     if(this.authSvc.choicesetids.includes(choicesetid)){
       console.log('this choicetree has a list associated with it and its in cache')
   
     }
   
   
    if(!this.authSvc.choicesetids.includes(choicesetid)){
      console.log('ive never got this list before lets get it')
     this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
   
   }
   
   
   }
   
   public getChip(choicesetid){
   
    
     this.sorted = [];
     console.log('getting choicesetbyid with getChip type is path')
     this.tagerSvc.getChoicesetbyid(choicesetid, 'treelist')
   }
   
   
   public hlchoice(selectedrow, index){
     console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
    console.log('this is itthe itemvalue: '+selectedrow.name);
    console.log('this is itthe formcontrol index: '+index);
   
    
   // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
   this.tagsArray.controls[index].patchValue({'itemvalue': selectedrow.name})
    console.log('this is formtype '+this.tagsArray.controls[index].value.formtype.value);
    console.log('this is fitemvalue '+this.tagsArray.controls[index].value.itemvalue);
    // this.previewArray.at(index).controls['itemvalue'].patchValue('test');
    console.log('this is itthe current preview array: '+this.tagsArray);
    
     this.hlchoiceset = [];
     this.hlchoiceset.push(selectedrow)
     this.hltreepath= [];
     this.hltreepath = this.hlchoiceset[0].path
     console.log('this is itthe new selected tree path '+JSON.stringify( this.hltreepath));
     this.tagsArray.controls[index].patchValue({'treepath': this.hltreepath})
    }
    
    public changeRadio(selectedchoice, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      console.log('this is itthe itemvalue: '+selectedchoice.name);
      console.log('this is itthe formcontrol index: '+index);
     
      
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.tagsArray.controls[index].patchValue({'itemvalue': selectedchoice.name})
    }

    public changeBoolean(hasdefault, index){
      //console.log('this is itthe hlchoie new selected row '+JSON.stringify(selectedrow));
      var bool = false;
      console.log('this is itthe formcontrol index: '+index);
      console.log('this is itthe current hasdefault value: '+hasdefault);
      console.log('this is new hasdefault value: '+!hasdefault)
      

    
     
      this.tagsArray.markAsDirty();
     // this.previewArray.controls[index].value.itemvalue= selectedrow.name;
     this.tagsArray.controls[index].patchValue({'hasdefault': !hasdefault})
    }

    

    
}



