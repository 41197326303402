import { Component, OnInit } from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ConfigService} from '../../services/config.service';
import { Router } from '@angular/router';
import { DocactionsService } from '../../services/docactions.service';

@Component({
  selector: 'app-nodefiles',
  templateUrl: './nodefiles.component.html',
  styleUrls: ['./nodefiles.component.css']
})
export class NodefilesComponent implements OnInit {

  public attachedfiles = [];
  public reset: any[] = [{}];
  versionColumns = ['type', 'mt', 'name', 'ab', 'ao'];
  public itemselected = false;
  
  public clickCount = 0;
  constructor(public uploadSvc: UploadService, public configSvc: ConfigService, private router: Router, public docActions: DocactionsService) { }
  ngOnInit(): void {
    if(this.configSvc.activetype == 'fold'){
      console.log('this is the folder details '+JSON.stringify(this.configSvc.folderdetailsds))
      this.attachedfiles = this.configSvc.folderdetailsds.attachs
      console.log('this is a foolder so getting attached files')
    }

    if(this.configSvc.activetype =='smartfoldertab'){
      this.attachedfiles = this.configSvc.attachednode[0].attachs
    }

    if(this.configSvc.activetype !='fold' && this.configSvc.activetype !='smartfoldertab'){
      this.attachedfiles = this.configSvc.nodeds[0].attachs
    }


  }


  selectItem(item) {
  this.clickCount++;
  setTimeout(() => {
      if (this.clickCount === 1) {
          console.log('this is a single click')
     
  this.configSvc.linkeditem = []
this.configSvc.linkeditem.push(item)
console.log('selected items '+JSON.stringify(this.configSvc.linkeditem))
this.configSvc.linkready = false
if(this.configSvc.linkeditem[0].mt == 'jpeg' || this.configSvc.linkeditem[0].mt == 'jpg' || this.configSvc.linkeditem[0].mt == 'png'){
  this.uploadSvc.getImagebyid(item.pa, "1", 'nodefile')
  }
/*
if(this.configSvc.linkeditem[0].mt != 'msg'){
  this.configSvc.linkready = true
  this.configSvc.emaildata = this.configSvc.currentrow.email
  console.log('emaildata is '+JSON.stringify(this.configSvc.emaildata))
}
*/
if(this.configSvc.linkeditem[0].mt == 'msg'){
 // this.emaildata = this.configSvc.linkeditem[0].email
 this.uploadSvc.getItembyid(this.configSvc.linkeditem[0].attachid, this.configSvc.linkeditem[0].mt)
}

if(this.configSvc.linkeditem[0].mt == 'attachnode' || this.configSvc.linkeditem[0].mt == 'attachnodetab'){
  console.log('this attached item is an attachnode or attachnodetab');
  this.uploadSvc.getItembyid(this.configSvc.linkeditem[0].attachid, this.configSvc.linkeditem[0].mt)
 }
      } else if (this.clickCount === 2) {
        console.log('this is a double click')
        console.log('active type is '+this.configSvc.activetype)
        var itemid: string
    if(this.configSvc.activetype =='activeflow' || this.configSvc.activetype =='basicflow'){ 
      itemid = this.configSvc.nodeds[0].activeflowid}
    if(this.configSvc.activetype !='activeflow'  && this.configSvc.activetype !='basicflow'){
      itemid = this.configSvc.currentrow._id}
this.configSvc.linkeditem = []
this.configSvc.linkeditem.push(item)
console.log('this is the image item info'+JSON.stringify(this.configSvc.linkeditem[0]))

if(this.configSvc.linkeditem[0].mt == 'msg' || this.configSvc.linkeditem[0].mt == 'pdf' ||
this.configSvc.linkeditem[0].mt == 'docx' || this.configSvc.linkeditem[0].mt == 'jpeg' || this.configSvc.linkeditem[0].mt == 'png' || this.configSvc.linkeditem[0].mt == 'jpg'){
this.docActions.getFile(item.pa, item.name, itemid);
}

      }
      this.clickCount = 0;
  }, 250)
}

}
