<mat-tab-group  [(selectedIndex)]="this.configSvc.tabIndex">

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.mt == 'jpg' || this.configSvc.nodeds[0]?.mt == 'jpeg' || this.configSvc.nodeds[0]?.mt == 'png' || this.configSvc.nodeds[0]?.mt == 'gif'" label="Photos">
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >photo_camera</i>
 
      </ng-template>
    <span class= "comments">
        <app-fileimage [imagesource]="'image'"></app-fileimage></span>
  
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.mt == 'msg'" label="EmailTags">
    <ng-template mat-tab-label>
    <i class="material-icons md-24" [ngStyle]="{'color': 'yellow'}" >email</i>Email

    </ng-template>
    <span class= "emailtags">
    <app-emailtags [emailsource] = "'editfile'"></app-emailtags></span>
    
    </mat-tab>





  
  <span  *ngIf="this.configSvc.nodeds[0].ver>1 " label="Versions">
  <mat-tab>
  <ng-template mat-tab-label>
  <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >content_copy</i>
 
  </ng-template>
  <mat-card>
  <app-docversions></app-docversions>
  </mat-card></mat-tab>
  </span>
  
  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.cmt == true" label="Comments">
  <ng-template mat-tab-label>

    <span *ngIf="!this.configSvc.nodeds[0]?.ischatgpt  " >
    <i class="material-icons icon-size"  [ngStyle]="{'color': this.configSvc.nodeds[0]?.cmtclr}">{{this.configSvc.nodeds[0]?.cmticon}}</i>
  </span>
    <div *ngIf="this.configSvc.nodeds[0]?.ischatgpt " >
      <span>
        <mat-icon class="custom"  title="ChatGPT" svgIcon="chat2"

        ></mat-icon></span>
     </div>
  </ng-template>
  <span class= "comments">
  <app-viewcomments></app-viewcomments></span>
  
  </mat-tab>

  <mat-tab  *ngIf="this.configSvc.nodeds[0]?.assigned == true" label="Assigned">
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'green'}" >person</i>
    
      </ng-template>
    <span class= "comments">
      <app-assigned></app-assigned>
    </span>
    </mat-tab>

    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.deadline == true && this.configSvc.nodeready" label="Deadline">
      <ng-template mat-tab-label>
       <span> <i class="material-icons md-24" [ngStyle]="{'color': 'blue'}" >timer</i></span>
       
      
        </ng-template>
      <span class= "comments">
        <app-deadline></app-deadline>
      </span>
      </mat-tab>

 
    <mat-tab  *ngIf="this.configSvc.nodeds[0]?.tagged == true" label="Indexed">
      <ng-template mat-tab-label>
        <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': this.configSvc.nodeds[0].tagcolor}">bookmarks</i>
    
        </ng-template>
      <span class= "comments">
      <app-foldertags  [indexlaunch]="'edit'" ></app-foldertags></span>
      
      </mat-tab>

 

      
  <mat-tab>
    <ng-template mat-tab-label>
      <i class="material-icons md-24" [ngStyle]="{'color': 'black'}" >loupe</i>
    
      </ng-template>
<mat-card>
<app-docdetails></app-docdetails>
</mat-card>
</mat-tab>
  
  
  </mat-tab-group>