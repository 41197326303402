import { Component, OnInit, Inject, Input } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DoccommentsService } from '../../../services/doccomments.service';
import {UploadService} from '../../../services/upload.service';
import { jsPDF, AcroFormCheckBox } from "jspdf";
export interface DialogData {
 
  name: string;
  type: string;
}

@Component({
  selector: 'app-queuetools',
  templateUrl: './queuetools.component.html',
  styleUrls: ['./queuetools.component.css']
})
export class QueuetoolsComponent implements OnInit {

  constructor(public configSvc: ConfigService, public dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  openDialog(type, name): void {

    console.log('popup name is'+name)
    console.log('popup type is'+type)
    this.configSvc.tagupload = true;
var popupheight = '500px'
var popupwidth= '400px'
    if(type == 'deletefold' || type == 'deletefile' || type == 'deletetageritem' || type =='cutitem' || type =='pasteitem'){popupheight = '150px'}
    if(type == 'folder' || 'bulkadd'){popupheight = '450px'; popupwidth = '500px'}
    if(type == 'addfiles'){popupwidth = '700px'}
    if(type == 'folderdetails'){popupwidth = '1200px'}
    if(type == 'report'){popupwidth = '700px'}
    if(type == 'createteam'){
      popupheight = '650px'; popupwidth= '1200px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'team';
    this.configSvc.activetype = 'createteam';
    }
    if(type == 'comment'){
       popupheight = '650px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'orange'
      this.configSvc.commenticon = 'chat'
    }
    const dialogRef = this.dialog.open(QueuePopup, {
      width: popupwidth, height: popupheight,
      data: {name: name, type: type}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.tagupload = false;
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }
}

@Component({
  selector: 'app-queuepopup',
  templateUrl: 'queuepopup.html',
  styleUrls: ['./queuetools.component.css']
})
export class QueuePopup {

  public username = '';
  public cmtIcon = 'cmt'
  public attachreport = false;
  @Input() teamtags: boolean;
  @Input() foldertags = true;
  @Input() kanbanned: boolean;
  public indextype = '0';
  public bulkindex = '0';
  
  isLinear = true;
  step1 = "step1";
  step2= "step2";

  @Input() teamtype: string;
 // isLinear = true;
 // step1 = "step1";
 // step2= "step2";
  step3= "step3";
  step4= "step4";
  
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;
    teamfileFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    public csvRecords: any[] = [];
    public header = true;
    public imageready = false
    public imageloaded
    public actualimage
    public url;
 

  constructor(private _formBuilder: FormBuilder, private router: Router, 
    public dialogRef: MatDialogRef<QueuePopup>, private doccomments: DoccommentsService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private uploadSvc: UploadService,
    public configSvc: ConfigService,
   ) {}

    ngOnInit() {
  
  



      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }



 


  
  
  
  addFolder(name, indextype, desc){
    this.configSvc.addFolder('fold', name, desc,
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, indextype, 'folder', 'yellow');
      this.dialogRef.close();
  }

 

   

   

 
  
 

  public addComment(comment, atcmt) {
    console.log('cmticon is set to '+this.cmtIcon)
    if(this.cmtIcon == 'thumb_up'){
      this.configSvc.color = 'green'; } 
    if(this.cmtIcon == 'thumb_down'){this.configSvc.color = 'blue'; } 
    if(this.cmtIcon == 'priority_high'){this.configSvc.color = 'red'; } 
    if(this.cmtIcon == 'chat'){this.configSvc.color = 'black'; } 

    if(atcmt){
      this.doccomments.addcomment('addcomment', comment, this.configSvc.activetype, this.configSvc.chosenIcon, this.configSvc.color, atcmt, false, this.username);
    }
    if(!atcmt){  
          this.doccomments.addcomment('addcomment', comment, this.configSvc.activetype, this.configSvc.chosenIcon, this.configSvc.color, false, atcmt);
          
    }
          this.dialogRef.close();
   }

   usernameFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering email on: '+filterValue)
    if (filterValue != '')
    {
      console.log('filter value befoore sending is : '+filterValue)
    this.configSvc.usernameFilter(filterValue, 'user')
  }

}
  



    
    
    

   


  
  
  
  
   
   

  

    folderReport(attachreport){
      // Default export is a4 paper, portrait, using millimeters for units
      console.log('starting to write report')
      console.log('this is the contents of the folder '+JSON.stringify(this.configSvc.teamds))

      var doc = new jsPDF();
   
     // var {CheckBox} = jsPDF.;
    // doc.advancedAPI(doc => {
      // your code
    
 
  
      doc.text('Eddison Inspections', 20, 20);
      doc.text('This a a report for all assets in folder '+this.configSvc.activename, 20, 30);
      doc.text('page ' + 1, 170,285);
    
     // doc.text('Do you like that?',20, 20);
     var pagecnt = 2;
     
     for(var i = 0; i < this.configSvc.teamds.length; i++){

       var asset = this.configSvc.teamds[i]
      console.log('asset desc is '+asset.desc)
      doc.addPage();

      doc.text('page ' + pagecnt++, 170,285);
      var assetname: string = String(asset.name);
      var assetdesc: string = String(asset.desc);
      doc.text(assetname+' '+assetdesc, 10, 20);
     
     

      var ab: string = String(asset.ab);
      var ti: string = String(asset.ti);
      var bo: string = String(asset.bo);
      doc.text('AssetID: ' +ti+' created by ' +ab+  ' based on:' +bo, 10, 40);

var location =  50;
      Object.keys(this.configSvc.teamds[i].stepitems).forEach((i2) => {
      //  var tag = asset.stepitems[i2]
      //  console.log('tag  is '+JSON.stringify(tag))
      //  console.log('formitemlabel  is '+JSON.stringify(tag))
location = location +10;
// console.log('i2 value is '+i2)
if(i2 == '0'){
  doc.text( 'Engineer carried out the following checks on site: ', 15, location);
  location = location +10
}

if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
const checkBox =new AcroFormCheckBox();
checkBox.fieldName = "CheckBox";
checkBox.x = 5;
checkBox.y = location-10;
checkBox.width = 5;
checkBox.height = 15;
checkBox.maxFontSize = 15;
checkBox.readOnly = true;
checkBox.textAlign = 'center';
// checkBox.value = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
// checkBox.caption = this.configSvc.teamds[i].stepitems[i2].formitemlabel;
//checkBox.fieldName = this.configSvc.teamds[i].stepitems[i2].formitemlabel;

if(!this.configSvc.teamds[i].stepitems[i2].hasdefault){
checkBox.color = 'red';


}
doc.addField(checkBox);
      }


      var formitemlabel: string = String(this.configSvc.teamds[i].stepitems[i2].formitemlabel);
      if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
       
        doc.text( formitemlabel, 15, location);}

        if(this.configSvc.teamds[i].stepitems[i2].formtype == 'string' ){

          var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
          doc.text( formitemlabel+ ':' +itemvalue, 15, location);
        }

        
        if(this.configSvc.teamds[i].stepitems[i2].formtype == 'boolean' ){
if(this.configSvc.teamds[i].stepitems[i2].hasdefault){
          var hasdefault: string = String(this.configSvc.teamds[i].stepitems[i2].hasdefault);
          doc.text( 'This asset has NOT '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, 15, location+10);
        }
       
        if(!this.configSvc.teamds[i].stepitems[i2].hasdefault){
          var hasdefault: string = String(this.configSvc.teamds[i].stepitems[i2].hasdefault);
          doc.text( 'This asset has '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, 15, location+10);
        }
      }
      
    })

    Object.keys(this.configSvc.teamds[i].comments).forEach((i3) => {
      location = location +20;
      console.log('i3 value is '+i3)
      var cmt: string = String(this.configSvc.teamds[i].comments[i3].comment);
      var ab: string = String(this.configSvc.teamds[i].comments[i3].ab);
      doc.text( cmt+ ': commented by' +ab, 15, location);
      console.log('i3 value is '+i3)

    })
     
     }

      var title =this.configSvc.activename+" "+"Report.pdf";
      doc.save(title);
      console.log('finished writing report')
      var uploadedpdf = doc.output('blob');
      console.log('finished writing report')
      if(attachreport){
        console.log('filing report now')
      this.fileReport(uploadedpdf)
      }

//
    }

    public fileReport(doc) {
      var reportname =  this.configSvc.activename+' Folder Report.pdf';
      this.uploadSvc.totalfilesuploaded = doc.length
      console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
      this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
      this.uploadSvc.fileReport(doc, reportname);
      }

      public imagedropped(event) {
 
        if (event.target.files && event.target.files[0]) {
          this.imageloaded = new FileReader();
      this.actualimage = event.target.files[0]
          this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url
      
          this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
            this.url = event.target.result;
            this.imageready = true
          }
        }
      
      // this.dialogRef.close();
        }
 
  

}
