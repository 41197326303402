<div >
    <table mat-table [dataSource]="this.configSvc.nodeds[0].version" class = "version-container">
        <tr mat-row *matRowDef="let selectedrow; columns: versionColumns; let i = index;"
        (click)="this.clickVersion(selectedrow)"
        [style.background]=" this.configSvc.linkeditem.indexOf(selectedrow) == 0 ? 'lightblue' : ''"
        ></tr>
        <tr mat-header-row *matHeaderRowDef="versionColumns" ></tr>
  
          <!-- MimeType Column -->
          <ng-container matColumnDef="Mime">
           <th mat-header-cell  *matHeaderCellDef>Type</th>
           <td mat-cell *matCellDef="let element">
            
                   
                      
                      <div *ngIf="this.configSvc.nodeds[0].mt == 'pdf'" class="fa-2x"><span class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i></span></div>
                  
                       <div *ngIf="this.configSvc.nodeds[0].mt == 'txt'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-alt" style="color:gray"></i></span></div>
                      <div *ngIf="this.configSvc.nodeds[0].mt == 'xlsx'" class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i></span></div>
                      <div *ngIf="this.configSvc.nodeds[0].mt == 'docx'" class="fa-2x"><span class="fa-layers fa-fw">
                              <span class = "fileindexed"><i class="fas fa-file-word" style="color:blue"></i></span></span>
                            </div>
                    
                      <div *ngIf="this.configSvc.nodeds[0].mt == 'bmp' || element.mt == 'jpeg' || element.mt == 'png' || element.mt == 'jpg' " class="fa-2x"><span class="fa-layers fa-fw"><i class="fas fa-file-image" style="color:blue"></i></span></div>
                    
             </td>
         </ng-container>
  
         <!--Doc Column -->
       <ng-container matColumnDef="Name">
         <th mat-header-cell *matHeaderCellDef>Name</th>
  
         <td mat-cell *matCellDef="let element">
            {{this.configSvc.nodeds[0].name}}
              </td>
            </ng-container>
  
              <!--Ver Column -->
       <ng-container matColumnDef="Version">
          <th mat-header-cell *matHeaderCellDef>Version</th>
  
         <td mat-cell *matCellDef="let element"> {{element.ver}} </td>
       </ng-container>
        <!--Addedby Column -->
        <ng-container matColumnDef="AddedBy">
          <th mat-header-cell *matHeaderCellDef>AddedBy</th>
  
         <td mat-cell *matCellDef="let element"> {{element.ab}} </td>
       </ng-container>
  
          <!--AddedOn Column -->
          <ng-container matColumnDef="AddedOn">
            <th mat-header-cell *matHeaderCellDef>AddedOn</th>
  
           <td mat-cell *matCellDef="let element"> {{element.ao | date: 'medium'}} </td>
         </ng-container>

             <!--Addedby Column -->
        <ng-container matColumnDef="Instructions">
          <th mat-header-cell *matHeaderCellDef>Instructions</th>
  
         <td mat-cell *matCellDef="let element"> {{element.instr}} </td>
       </ng-container>
  
     
      </table>
      <span *ngIf="this.uploadSvc.imageverready" class = "nodeitem">
<mat-card *ngFor="let r of reset">
     

        <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'jpg' || this.configSvc.linkeditem[0]?.mt == 'png'">
  
          <div  >   
                  
            <img [src]="this.uploadSvc.versionblob" > <br/>
            </div></span>

      

        <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'attachnode'">
            <app-attachednode></app-attachednode></span>

            <span *ngIf="this.configSvc.linkeditem[0]?.mt == 'attachnodetab'">
                <app-attachedtabs></app-attachedtabs></span>
        


         
          </mat-card>   </span>
    </div>