import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {DocactionsService} from '.././../services/docactions.service';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthenticationService, UserDetails, TokenPayload } from '.././../services/authentication.service';

@Component({
  selector: 'app-docdetails',
  templateUrl: './docdetails.component.html',
  styleUrls: ['./docdetails.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DocdetailsComponent  implements OnInit{
  


  public detailsFG: FormGroup;

 
 
  user: TokenPayload;
  highlightedRows = [];
  // activeTabIndex = 0;

  constructor(private fb: FormBuilder, public configSvc: ConfigService, private docActions: DocactionsService, 
    private authService: AuthenticationService) { }

    ngOnInit() {

      this.detailsFG = this.fb.group({
        id: [this.configSvc.nodeds[0]._id, Validators.required],
        title: [this.configSvc.nodeds[0].ti, Validators.required],
        name : [this.configSvc.nodeds[0].name, Validators.required],
        addedby: [this.configSvc.nodeds[0].ab, Validators.required],
        mb: [this.configSvc.nodeds[0].mb, Validators.required],
        ver: [this.configSvc.nodeds[0].ver, Validators.required],
        ed: [this.configSvc.nodeds[0].ed, Validators.required],
        lv: [this.configSvc.nodeds[0].lv, Validators.required],
        tagged: [this.configSvc.nodeds[0].tagged, Validators.required],
        nodename: [this.configSvc.nodeds[0].nodename, Validators.required],
        fs: [this.configSvc.nodeds[0].fs, Validators.required],
        mt: [this.configSvc.nodeds[0].mt, Validators.required],
        bn: [this.configSvc.nodeds[0].bn, Validators.required],
        fc: [this.configSvc.nodeds[0].fc, Validators.required],
      });
    }

  
  public showdetails() {
    return this.docActions.showdetails;
  }

  public downloadFile() {
    this.docActions.downloadFile();

    }

  public editFile() {
    this.docActions.editFile();

      }
    public deleteFile() {
  }
  public emailFile() {
  }
 


   

  public showprogress() {
    return this.docActions.showprogress;
  }

  public showtabs() {
    return this.docActions.showtabs;
  }


    public getDocid() {
      console.log('heading off to get the updated document data now');
      const searchdocidForm = new FormData();
      searchdocidForm.append('doc_id', this.configSvc.nodeds[0]._id);
      this.authService.searchdocid(searchdocidForm, this.user).subscribe(getdociddata => {
        console.log('returning docid comment count now' + JSON.stringify(getdociddata.CommentCnt));
  const doccommentcnt =  JSON.stringify(getdociddata.CommentCnt);
      this.highlightedRows = [];
       this.highlightedRows.push(getdociddata);
     console.log(this.highlightedRows);
      }
       , (err) => {
        console.error(err); });
    }

    public renameItem(newname: string, itemid: string)
    {
      console.log('this is the new name '+newname)
      console.log('this is the itemid '+itemid)
    }

    public setState(statelabel){
      this.configSvc.nodeds[0].state = !this.configSvc.nodeds[0].state
      this.configSvc.setState(this.configSvc.activeitemid, this.configSvc.nodeds[0].state, statelabel, false, this.configSvc.folderdetailsds._id)
     
    }

}
