import { Component, OnInit } from '@angular/core';
import {ConfigService} from '.././../services/config.service';

@Component({
  selector: 'app-chatgptdirect',
  templateUrl: './chatgptdirect.component.html',
  styleUrls: ['./chatgptdirect.component.css']
})
export class ChatgptdirectComponent implements OnInit {

  constructor(public configSvc: ConfigService) { }

  ngOnInit(): void {
  }

}
