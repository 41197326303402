<span class="scrollmenu">
 

    
  
      
  
        
        <span>
        <i class="material-icons icon-comment" title="Add Comment" 
         (click)="this.openDialog('comment', '')">chat</i>
        </span>
  
      
       
       
          <span>
            <i class="material-icons icon-report"  title="Generate Queue Report"
            (click)="this.openDialog('report', 'Generate Queue Report')">picture_as_pdf</i></span>
    
       
       
          <span >
            <i class="material-icons icon-docadd"  title="Upload Files"
            (click)="this.openDialog('addfiles', this.configSvc.activename)">cloud_upload</i></span>
           
           
    
    
     <span>
    
   
    
      <span  *ngIf="this.configSvc.activetype != 'undefined'"><a href="mailto:fehin@example.com?subject=Please find attached toolbard link for your reference {{this.configSvc.activename}}&body=Find below a link for you to review {{this.configSvc.activeurl}}"><i class="material-icons icon-edit"  title="Email {{this.configSvc.hlitem[0]?.name}}" >email</i></a> </span>
    </span>
  
   
  
  
  
  
  
    
          </span>
        
          
    
              
               
    
    