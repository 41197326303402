<form [formGroup]="nodeFG">
 <span *ngIf = "data.type=='folderdetails'">
  <app-editfolder></app-editfolder>
</span>

<span *ngIf = "data.type=='assign'">
    <div class =cardcontent>
      Assign User to <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >{{this.configSvc.nodeds[0].icontype}}</i>{{this.configSvc.activename}}
     <div  > <mat-form-field appearance="fill"  style="max-width: 30vw; min-width: 30vw">
        <mat-label>Assign</mat-label>
       
        <input type="text"  
        formControlName="username"  
        [matAutocomplete]="auto"
        
        matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
        
        <mat-autocomplete #auto="matAutocomplete">
   <mat-option *ngFor="let user of this.configSvc.filtereduser" [value]="user.inboxemail"
   (onSelectionChange)="userSelected(user)"
   >
    {{user.inboxemail}} 
  
   </mat-option>
        </mat-autocomplete>
  
       
       </mat-form-field></div>
       <button   [disabled]="!this.configSvc.userready" mat-button  matSuffix 
       mat-raised-button color="primary" (click)="this.setAssignedto();this.dialogRef.close() ">Assign</button>
      </div>
  
  
</span>

<span *ngIf = "data.type=='deadline'">
<span class = "labels">
  <mat-label>Set Deadline for {{this.configSvc.nodeds[0].name}}?</mat-label>
 </span> 
<mat-form-field appearance="fill">
<input matInput [matDatepicker]="picker1" placeholder="Set Deadline"formControlName="deadlineval" >
     <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
     <mat-datepicker  #picker1></mat-datepicker>
   </mat-form-field>

   <button 
   (click)="this.setDeadline(this.nodeFG.value.deadlineval)"
  
       matSuffix 
       mat-raised-button color="primary"
       aria-label="Deadline">
Set Deadline </button>
  </span>

  <span *ngIf = "data.type=='nodereport'">
    <div>
      <i class="material-icons icon-report">picture_as_pdf</i> 
     Generate report on {{this.configSvc.nodeds[0].bo}} {{this.configSvc.nodeds[0].name}} 
    </div>
    <div mat-dialog-actions>
      <span class= "genpopupbottom">
       
  

        <mat-label>Report Style</mat-label>
        <span ><mat-form-field class="family" >
          <mat-label>Family</mat-label>
           <mat-select  formControlName="family" required>
            <mat-option *ngFor="let style of family" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field>

         <span *ngIf="this.nodeFG.value.family == 'Courier' || this.nodeFG.value.family == 'Helvetica'" ><mat-form-field >
          <mat-label>FontStyle</mat-label>
           <mat-select  formControlName="style" required>
            <mat-option *ngFor="let style of familystyle" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field></span>

         <span *ngIf="this.nodeFG.value.family == 'Times'" ><mat-form-field >
          <mat-label>FontStyle</mat-label>
           <mat-select  formControlName="style" required>
            <mat-option *ngFor="let style of timestyle" [value]="style">
         {{style}} </mat-option> </mat-select> </mat-form-field></span>
        </span>

        <span class="reportincludes">
          <div>
        <mat-checkbox class = "reportitem" formControlName="filereport" >File Report</mat-checkbox>
      </div>
        <div *ngIf="this.configSvc.nodeds[0].attach">
        <mat-checkbox class = "reportitem"formControlName="inclattach" >Include Attachments</mat-checkbox>
      </div>
      <div *ngIf="this.configSvc.nodeds[0].cmt">
        <mat-checkbox class = "reportitem" formControlName="inclcmts" >Include Comments</mat-checkbox>
    </div>
  </span>

  
        <div class="reportbuttons"><button class = "reportitem"mat-button (click)="onNoClick()" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Cancel">Cancel</button>
  
        <button class = "reportitem" mat-button (click)="nodeReport(filereport)" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Generate">Generate PDF</button>
      
      
      </div>
      </span>
      
    </div>
    </span>

 
 <span *ngIf  = "data.type=='addfiles'"><i class="material-icons icon-docaddpopup">cloud_upload</i>
   <span mat-dialog-content>
     Upload files to {{data.name}}
     
   </span>
   <div mat-dialog-actions>

    <mat-radio-group formControlName="attachchoice" 
    (change)="changeChoice()"> 
      <mat-radio-button value= '0'>Attach a File</mat-radio-button>
      <span *ngIf= "this.configSvc.folderdetailsds.locktoolbar">
      <mat-radio-button value= '2'>Attach {{this.configSvc.folderdetailsds.toolbarname}} Tool</mat-radio-button>
    </span>  <mat-radio-button value= '1'>Attach Favourite Tool</mat-radio-button>
 
  </mat-radio-group>
  

  
  </div>

  <span *ngIf="nodeFG.value.attachchoice == '0'">
    <span>
      <input type='file' (change)="prepareFile($event)">
      <ngx-file-drop dropZoneLabel="Drop Email from Outlook"
      (onFileDrop)="prepareMsg($event)"
              
              >
             
             </ngx-file-drop>
    
    </span>
    <div *ngIf="imageready">
    
      <img [src]="url" height="200"> <br/>

      <button mat-raised-button color="primary" 
      (click)="addNodefiles('image'); this.dialogRef.close();" class = "buttonstyle">Attach Image </button>

   </div>


    <div *ngIf="fileready">
    
      <span *ngIf="this.filext == 'docx'" class="fa-layers fa-fw"><i class="fas fa-file-word" style="color:blue"></i> </span>
      <span *ngIf="this.filext == 'xlsx'" class="fa-layers fa-fw"><i class="fas fa-file-excel" style="color:green"></i> </span>
      
      {{this.filename}}

      <button mat-raised-button color="primary" 
      (click)="addNodefiles('file'); this.dialogRef.close();" class = "buttonstyle">Attach File </button>
    </div>

    <div *ngIf="pdfready">
      <span *ngIf="this.filext == 'pdf'" class="fa-layers fa-fw"><i class="far fa-file-pdf" style="color:red"></i> </span>
     
      {{this.filename}}

      <button mat-raised-button color="primary" 
      (click)="addNodefiles('pdf'); this.dialogRef.close();" class = "buttonstyle">Attach PDF </button>
    </div>

   
</span>
<span *ngIf="msgattready" >
<span class="fa-layers fa-fw"><i class="fas fa-envelope" style="color:rgba(255, 238, 0, 0.911)"></i>
 </span> {{this.filename}}

<div>  <button mat-raised-button color="primary" 
  (click)="addNodefiles('msg'); this.dialogRef.close();" class = "buttonstyle">Attach Email </button> </div>
</span>
   
<span *ngIf="nodeFG.value.attachchoice == '1'">

  Choose from Favourites below
  <div>
  <span  *ngFor="let toolbar of configSvc.favtoolset" >

    <span>
      <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="Attach {{toolbar.name}} "
      (click)="this.clickFav(toolbar.type, toolbar.name, toolbar)"
    >{{toolbar.icontype}}</i></span>


  </span>
  <div>
    <span *ngIf="attachtype =='nodetab'">this is a nodetab </span>
    <span *ngIf="attachtype =='node'">this is a node
      <app-attachednode></app-attachednode>
      <button mat-button (click)="attachNode(); this.dialogRef.close()" 
                      
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Add Comment">Attach</button>

    </span>
    <span *ngIf="attachtype =='smartfolder' ">cannot attach a smartfolder</span>
    <span *ngIf="attachtype =='kanban' ">cannot attach a kanban</span>

  </div>
</div>


</span>

<span *ngIf="nodeFG.value.attachchoice == '2'">

  Choose from {{this.configSvc.folderdetailsds.toolbarname}} Toolbar below
  <div>
  <span  *ngFor="let toolbar of this.configSvc.toolbaritems " >

    <span>
      <i MatPrefix class="material-icons icon-string"  [style.color]="toolbar.iconcolor" title="Attach {{toolbar.name}} "
      (click)="this.clickFav(toolbar.type, toolbar.name, toolbar)"
    >{{toolbar.icontype}}</i></span>


  </span>
  <div>
    <span  *ngFor="let r of reset">
    <span *ngIf="attachtype =='node' && this.attachready">
      <app-attachednode></app-attachednode>
      <button mat-button (click)="attachNode(); this.dialogRef.close()" 
                      
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Add Comment">Attach</button>

    </span>
  </span>

  </div>
</div>


</span>
 
   </span>

 


 
   <span *ngIf = "data.type=='comment'" class = "cmt">
    
     <div mat-dialog-actions>



      <span>
        <div >
          Add Comment on <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >{{this.configSvc.nodeds[0].icontype}}</i> {{this.configSvc.activename}}
      
        </div>

        
    
    
        <mat-form-field appearance="outline"   class = "textarea">
          <textarea
          
          matInput formControlName="cmtval"></textarea>
         </mat-form-field>
      
      </span>
    <div>
      <mat-button-toggle-group formControlName="cmticon"  aria-label="Comment Icon" (change)="onIconChange($event.value)">
        <mat-button-toggle value="chat"> <i class="material-icons md-48" [ngStyle]="{'color': 'black'}" >chat</i></mat-button-toggle>
        <mat-button-toggle value="thumb_up"><i class="material-icons md-48" [ngStyle]="{'color': 'green'}" >thumb_up</i></mat-button-toggle>
        <mat-button-toggle value="thumb_down"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >thumb_down</i></mat-button-toggle>
        <mat-button-toggle value="priority_high"><i class="material-icons md-48" [ngStyle]="{'color': 'red'}" >priority_high</i></mat-button-toggle>
        <mat-button-toggle value="question_mark"><i class="material-icons md-48" [ngStyle]="{'color': 'blue'}" >question_mark</i></mat-button-toggle>
      </mat-button-toggle-group></div>
    
    <div >
    <mat-checkbox formControlName="emoji" >Change Emoji
      <i class="material-icons icon-customtoolbar" [ngStyle]="{'color': yellow}">insert_emoticon</i>
      
      </mat-checkbox>
    
      
    
    </div>
    
    <div>
    <mat-checkbox formControlName="atcmt" ><i class="material-icons icon-customtoolbar" [ngStyle]="{'color': darkblue}">alternate_email</i>User
      
      
      </mat-checkbox>
    </div>
     
  
    
      <div *ngIf="this.nodeFG.value.emoji">
        <span>
       <app-iconcategories></app-iconcategories></span>
       <span class ="colorbox">
        <app-color-picker (color)="this.configSvc.color"></app-color-picker>
      </span>
      </div>
    
      <div *ngIf="this.nodeFG.value.atcmt" class = space>
    
          <mat-form-field appearance="fill">
            <mat-label>Reassign</mat-label>
           
            <input type="text"  
            formControlName="atcmtuser"
            [matAutocomplete]="auto"
            
            matInput (keyup)="usernameFilter($event)" placeholder="Username" autocomplete="off" >
            
            <mat-autocomplete #auto="matAutocomplete">
       <mat-option *ngFor="let option of this.configSvc.filtereduser" [value]="option.inboxemail">
        {{option.inboxemail}} 
      
       </mat-option>
            </mat-autocomplete>
      
           
           </mat-form-field>
          
     
     
     
     
      </div>

  <button  mat-button (click)="addComment(false); this.dialogRef.close()" 
                      
  matSuffix 
  mat-raised-button color="primary"
  aria-label="Add Comment">Add Comment</button>









 
     </div>
     </span>

     <span *ngIf = "data.type=='askchatgpt'" class = "cmt">
      <div mat-dialog-actions>
      <mat-horizontal-stepper [linear]="isLinear" #stepper>

        <mat-step [stepControl]="stepa">
          <ng-template matStepLabel>
           
        
           ChatGPT Request</ng-template>
     
       <span>
        
 
         <div  >
          Ask ChatGPT about <i class="material-icons md-48" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}" >{{this.configSvc.nodeds[0].icontype}}</i> {{this.configSvc.activename}}
       
         </div>
     
     
         <mat-form-field appearance="outline"   class = "textarea">
           <textarea
           
           matInput formControlName="cmtval"></textarea>
          </mat-form-field>
       
       </span>

     
 
   <button mat-button matStepperNext (click)="addComment(true); " 
                       
   matSuffix 
   mat-raised-button color="primary"
   aria-label="Add Comment">Ask ChatGPT</button>
 
   

    </mat-step>

      <mat-step [stepControl]="stepb">
        <ng-template matStepLabel>
         
      
         ChatGPT Response</ng-template>
    
         <span *ngIf="!this.configSvc.chatgptresponse">
          <mat-spinner></mat-spinner>
         Waiting on response from ChatGPT......
        </span>
    
        <span *ngIf="this.configSvc.chatgptresponse" class ="responses">
      
          <div >
           <app-viewcomments></app-viewcomments>
          </div>
    
          <div>
            Rate response
          
            <div class="star-container">
              <button mat-icon-button *ngFor="let star of stars" (click)="rate(star)">
                <mat-icon class="yellow-icon">{{ starIcon(star) }}</mat-icon>
              </button>
          </div>
          </div>
       
    
         <div>
          <button [disabled] ="this.nodeFG.value.saveprompt" mat-button matSuffix 
          mat-raised-button color="primary"(click) = "this.refreshFolder(); this.dialogRef.close();">Finish </button></div>
    
          <mat-checkbox formControlName="saveprompt" >Save Prompt</mat-checkbox>
    
          <div>
            <button [disabled] ="!this.nodeFG.value.saveprompt" mat-button matSuffix matStepperNext
            mat-raised-button color="primary">Next </button></div>
    
          </span>
    </mat-step>
    
      <mat-step [stepControl]="stepc" *ngIf="this.nodeFG.value.saveprompt">
       
        <ng-template matStepLabel>
         
      
        Save Prompt</ng-template>
    
    <div>
      Prompt provided to ChatGPT
    </div>
        <div>
    
    </div>
    
    <div>
      <mat-form-field appearance="fill" class= "large-item">
      
        <input formControlName ="prompt" matInput [value]=this.nodeFG.value.promptsent>
         
        </mat-form-field>
      </div>
    
      
        <span class = "labels"><mat-label >Type of Prompt</mat-label></span>
        <mat-form-field>
    
          <mat-select (selectionChange)="getPrompt()" formControlName="promptlist">
      <mat-option *ngFor="let prompt of this.tagerSvc.promptlist" [value]="prompt">
        {{prompt.name}}
      </mat-option>
    </mat-select>
    </mat-form-field>
    <div>
    <mat-form-field appearance="fill" class= "large-item">
      <mat-label>Prompt Name</mat-label>
      <input formControlName ="promptname"  matInput placeholder="Enter name" >
       
      </mat-form-field>
    </div>
    
    
    
    <div>
    <button mat-button matSuffix 
          mat-raised-button color="primary"(click) = "this.savePrompt(); this.dialogRef.close();">Save Prompt & Finish </button></div>
    
    
    
    </mat-step>
    
      </mat-horizontal-stepper>
      </div>
      </span>
 
 
    
 
 
 
   <span *ngIf = "data.type=='deletefile'">
     <div mat-dialog-content>
       <i class="material-icons icon-delete">delete</i> 
       Are you sure you want to delete {{this.configSvc.currentrow.name}} ?
     </div>
     <div mat-dialog-actions>
       <span class= "genpopupbottom">
         <button mat-button (click)="onNoClick()" 
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Cancel">Cancel</button>
   
         <button mat-button (click)="delete('item')" 
               
         matSuffix 
         mat-raised-button color="primary"
         aria-label="Delete">Delete</button>
       
       </span>
       
     </div>
     </span>

     

      <span *ngIf = "data.type=='cutitem'">
        <div mat-dialog-content>
          <i class="material-icons icon-delete">content_cut</i> 
          Are you sure you want to move  node item {{this.configSvc.currentrow.name}} ?
        </div>
        <div mat-dialog-actions>
          <span class= "genpopupbottom">
            <button mat-button (click)="onNoClick()" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Cancel">Cancel</button>
      
            <button mat-button (click)="cutItem(this.configSvc.currentrow._id, 
            this.configSvc.currentrow.type, this.configSvc.currentrow.level, 
            this.configSvc.currentrow.teamid, this.configSvc.currentrow.name, 
            this.configSvc.currentrow.icontype, this.configSvc.currentrow.iconcolor,this.configSvc.folderdetailsds.kanbantagged)" 
                  
            matSuffix 
            mat-raised-button color="primary"
            aria-label="Cut Item">Cut Item</button>
          
          </span>
          
        </div>
        </span>

        <span *ngIf = "data.type=='pasteItem'">
          <div mat-dialog-content>
            <i class="material-icons icon-delete">content_paste</i> 
            Are you sure you want to paste {{this.configSvc.moveitem[0].name}} ?
          </div>
          <div mat-dialog-actions>
            <span class= "genpopupbottom">
              <button mat-button (click)="onNoClick()" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Cancel">Cancel</button>
        
              <button mat-button (click)="pasteItem(this.configSvc.moveitem[0]?.itemid, this.configSvc.activeitemid,
              this.configSvc.moveitem[0]?.type,this.configSvc.moveitem[0]?.level,
               this.configSvc.moveitem[0]?.teamid, this.configSvc.moveitem[0]?.name, 
               this.configSvc.moveitem[0]?.icontype, this.configSvc.moveitem[0]?.iconcolor)" 
                    
              matSuffix 
              mat-raised-button color="primary"
              aria-label="Paste Item">Paste Item</button>
            
            </span>
            
          </div>
          </span>
</form>

 
     