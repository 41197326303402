import { Component, OnInit, Inject, Input } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { DocactionsService } from '../../../services/docactions.service';
import { DatePipe } from '@angular/common';
import {TagService} from '../../../services/tag.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TagerService} from '../../../services/tager.service';
import {UploadService} from '../../../services/upload.service';
import { NgxFileDropEntry, FileSystemFileEntry} from 'ngx-file-drop';
import { DoccommentsService } from '../../../services/doccomments.service';
import { Router} from '@angular/router';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import { AuthenticationService } from '../../../services/authentication.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { jsPDF, AcroFormCheckBox } from "jspdf";
import {SidenavService} from '../../../services/sidenav.service';
import {FlowService} from '../../../services/flow.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { PrefsService } from 'src/app/services/prefs.service';









export interface DialogData {
 
  name: string;
  type: string;
}

@Component({
  selector: 'app-generaltools',
  templateUrl: './generaltools.component.html',
  styleUrls: ['./generaltools.component.css']
})
export class GeneraltoolsComponent implements OnInit {

 public popupheight = '80vh'
 public popupwidth= '80vw'
  

  constructor(public configSvc: ConfigService, public docActions: DocactionsService, public sidenavSvc: SidenavService,
    public dialog: MatDialog, public tagerSvc: TagerService) 
    {

     }

  ngOnInit() {

    this.configSvc.popupwidth = '70vw'; this.configSvc.popupheight= '60vh'
    
    
  }

  getrootToolbars(){
    this.sidenavSvc.toggle('navigation')
    this.configSvc.roottoolbar = true;
    console.log('getting root toolbars so set to '+this.configSvc.roottoolbar)
    this.sidenavSvc.activesidenav = 'toolbars'
    console.log('activesidenav set to '+this.sidenavSvc.activesidenav)
    this.configSvc.parenttoolbar.splice(0,0, {'type': 'roottoolbars', 'itemid': 'noid', 'teamid':'rootlevel'})
    console.log('after shit the parenttoolbar is now set to: '+JSON.stringify(this.configSvc.parenttoolbar))
    this.configSvc.getToolbars('roottoolbars', 'noid', 'rootlevel')
    console.log('folderready is set to '+this.configSvc.folderready)
    console.log('activetype is set to '+this.configSvc.activetype)
    console.log('folderdetailsds is set to '+JSON.stringify(this.configSvc.folderdetailsds));
  }

  public getfavTools(){
    console.log('setting the fav tools');
    this.configSvc.toolmenu = 'favtoolset'; 
    console.log('active toolmenu is set to favtoolset')
    this.configSvc.favtools = true;
    this.configSvc.activeitemid = this.configSvc.currentfolder
    this.configSvc.activename = this.configSvc.currentfoldername
    this.configSvc.hlitem = []; this.configSvc.nodeready = false; this.configSvc.nodeds = [];
   this.configSvc.getfavToolbars()

  }


  

  openDialog(type, name, toolbar?): void {
    this.configSvc.popupheight = '80vh'; this.configSvc.popupwidth = '80vw'
console.log('toolmenu is '+this.configSvc.toolmenu )
this.configSvc.popup = true;

// this.configSvc.nodeds =[];
    if(this.configSvc.toolmenu == 'toolbartools' || this.configSvc.toolmenu == 'favtoolset'){
      this.configSvc.nodeds[0] = toolbar
    }
    console.log('popup name is'+name)
    console.log('popup type is'+type)
    this.configSvc.tagupload = true;
   // this.configSvc.quickadd = true

   
 

    if(type == 'deletefold' || type == 'deletefile' || type == 'deletetageritem' || type =='cutitem' || type =='pasteitem'){
      this.configSvc.popupheight = '20vh'; this.configSvc.popupwidth = '20vw'}
 //   if(type == 'folder' || 'bulkadd'){this.configSvc.popupheight = '450px'; this.configSvc.popupwidth = '500px'}
    if(type == 'addfiles' || 'addtemplate' || 'addfile'){this.configSvc.popupwidth = '70vw'}
  


    if(type == 'folderdetails'){this.configSvc.popupwidth = '90vw'; this.configSvc.popupheight= '90vh'}
    if(type == 'report'){this.configSvc.popupwidth = '700px'}
    if(type == 'createteam'){
      this.configSvc.popupheight = '650px'; this.configSvc.popupwidth= '1200px'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'team';
    this.configSvc.activetype = 'createteam';
    }
    if(type == 'comment'){
       this.configSvc.popupheight = '60vh'; this.configSvc.popupwidth= '50vw'
      this.configSvc.registericon = true;
      this.configSvc.colorinputtype = 'comment'
      this.configSvc.activecolor = 'orange'
      this.configSvc.commenticon = 'chat'
    }

    console.log('popup width is'+this.configSvc.popupwidth)
    console.log('popup height is'+this.configSvc.popupheight)
    const dialogRef = this.dialog.open(GeneralPopup, {
      width: this.configSvc.popupwidth, height: this.configSvc.popupheight,
      data: {name: name, type: type}
    });


    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed and a change was made');
      this.configSvc.tagupload = false;
      this.configSvc.popup = false
      //this.animal = result;
     // console.log('Answer is'+this.animal);
    });
  }

  hideBrowse(){
    //this.tagerSvc.kibrowse=false;
  //  this.configSvc.buildtool = true;
    console.log('kibrowse is now: '+this.tagerSvc.kibrowse)
  }


  

  
  


}

@Component({
  selector: 'app-generalpopup',
  templateUrl: 'generalpopup.html',
  styleUrls: ['./generaltools.component.css']
})
export class GeneralPopup {
 // public singletemplate ='0'
 panelOpenState = false;
 activeColumns = ['type', 'name']
 public hlsubitem = [];
 public subitemselected = false; 
  public kanbanpreview = []

  public currentAssigntype = 'toolbar'
public indexready = false;
public smartfolderready = false;
public smartfoldertabready = false
public kanbanready = false;
public addactive = false;
public addallactive = false;
public gotprompt = false;
public selectedkanban = []

public selectedToolbar = []
public deadlineval = '';
public comment = '';
public deadline = false;
public addcomment = false;
public assigned = false;

//public deadline = false;
//public addcomment = false;
//public assigned = false;


//public attach = false;
//public attachimage = false;
public selectedPrompt = ''
// public selectedPromptlist = []
public selectedcontext = ''
  public cmtIcon = 'cmt'
  public username = '';
  public attachreport = false;
  @Input() teamtags: boolean;
  @Input() foldertags = true;
  @Input() kanbanned: boolean;
  public indextype = '0';
  public foldertag = '';
  public bulkindex = '0';
  
  isLinear = true;
  step1 = "step1";
  step2= "step2";

  @Input() teamtype: string;
 // isLinear = true;
 // step1 = "step1";
 // step2= "step2";
  step3= "step3";
  step4= "step4";
  stars = [1, 2, 3, 4, 5];
selectedStar = 0;
  usersColumns = [ 'email', 'name'];
  usersaddedColumns  = [ 'email', 'name'];
  public myself: string;
    // folderFG: FormGroup;
    teamfileFG: FormGroup;
    folderFG: FormGroup;
    public colors = new FormControl();
    public newuser = new FormControl();
    public teams = new FormControl();
    public usersearchfc = new FormControl();
    finduserclicked = false;
    public csvRecords: any[] = [];
    public header = true;
    public imageready = false
    public fileready = false
    public pdfready = false
    public msgready = false
    public imageloaded
    public filename: string;
    public actualimage
    public allfiles
    public msgfiles
    public url;
    public reset: any[] = [{}];
    public foldercontext = [];
    public csvprocessed =0
    public myDate 
    public family = ['Courier', 'Helvetica', 'Times', ]
    public mimetypes = ['jpg', 'png', 'gif', ]
    public sizes = ['256x256', '512x512','1024x1024' ]
   
    public familystyle = [
      'Regular', "Oblique ", 'Bold', "Bold-Oblique "
    ]


  constructor(public auth: AuthenticationService, private _formBuilder: FormBuilder, private router: Router, 
    public dialogRef: MatDialogRef<GeneralPopup>, private doccomments: DoccommentsService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public tagSvc: TagService, private authSvc: AuthenticationService,
    public configSvc: ConfigService, public tagerSvc: TagerService, private uploadSvc: UploadService,
    private ngxCsvParser: NgxCsvParser, public flowSvc: FlowService,public prefSvc: PrefsService, private datePipe: DatePipe) {}

    ngOnInit() {
  
      this.teamfileFG = this._formBuilder.group({
        teamname: [''],
        teamdescription : '',
        colors: 'black',
        checked: false,
        teams: [],
        tagstep: 1
      });

      this.folderFG = 
      this._formBuilder.group({
        name: [''],
        desc: [''],
        fileinfolder: true,
   
      filereport: false,
      family:'Courier',
      style:'Regular',
      mt: '',
      size: '256x256',
        indextype: '0',
        options: '0',
        kanban: false,
        locktoolbar: false,
        toolbarid: '',
        toolbarname: '',
        toolbardesc: '',
        toolbaricon: '',
        toolbarclr: '',
        kanbanname: '',
        kanbanid: '',
        kanbancolor: 'green',
    
        assigned: false,
  title: '',
        kanbantotal: 0,
        settotal: false,
        timetotal: 0,
        kantype: '',
        assignedto: '',
        deadline: false,
        deadlineval: '',
        counttotals: false,
        state: false,
        statetracker: false,
        total: 0,
        statelabel: 'Completed',  
        addcomment: false,
        bulkindex: '0',
     attach: false,
     bulkfiles: false,
        flow: false,
        username: '',
        cmticon: 'chat',
        cmtclr: 'blue',
        emoji: false,
        icontype: '',
        iconcolor: '',
        
        cmtval: '',
        atcmt: false,
        atcmtuser: '',
        attachreport: false,
        indextemplate: false,
        indexname: '',
        
        singletemplate: '1',
        attachimage: false,
        nodename: '',
        nodeid: '',
        foldericon: 'folder',
        foldercolor: 'yellow',
        foldertags: [],
        textract: false,
        extract: false,
        ischatgpt: false,

        summary: '',
        textractype: 'form',
        tagreview: false,
       type: '',
       tagname: '',
       tagdesc: '',
       tagtype: '',
       prompt: '',
       promptlist: [],
       promptbuild: '',
       promptsent: '',
       askchatgpt: false,
       defaultprompt: '',
       usepromptlist: false,
       usecontext: false,
       contextlist: [],
       saveprompt: false,
       promptname: '',
       temp: 1.0,
       promptopts: false,
       responses: 1,
       advanced: false,
       website: false,
       weblink:''
     


        
      
      });

      console.log('this is the assigned value on init'+this.folderFG.value.assigned);
      console.log('this is the counttotals value on init'+this.folderFG.value.counttotals);
      
      console.log('this is the company'+this.auth.getUserDetails()?.companyname)
      this.configSvc.activeurl = "http://localhost:4200"+this.router.url;

      

      this.uploadSvc.filesprocessed = 0;this.uploadSvc.uploadfinished = false;
      this.configSvc.userready = false;
   //   this.addcomment = false; this.deadline = false;
    //  this.configSvc.indextemplate = false;
      this.configSvc.quickadd = true;
      console.log('quickadd is set to ' +this.configSvc.quickadd )
      if(this.configSvc.activetype == 'fold' && this.configSvc.folderdetailsds.tagged){
        console.log('this is a foldertagged folder')
        this.folderFG.patchValue({indextype: '1'})
        this.folderFG.patchValue({options: '1'});
        
        console.log('indextype is '+this.folderFG.value.indextype)
      }

      if(this.configSvc.activetype == 'smartfoldertab' && this.configSvc.folderdetailsds.tagged){
        console.log('this is a smartfoldertab folder')
        this.folderFG.patchValue({indextype: '1'})
        this.folderFG.patchValue({options: '1'});
        
        console.log('indextype is '+this.folderFG.value.indextype)
      }

      if(this.data.type == 'askchatgpt'){
        console.log('this is askchatgpt')
        this.tagerSvc.getPromptlist()
      }

      if(this.data.type == 'node' && this.configSvc.nodeds[0].ischatgpt){
console.log('this is a chatgpt node template')
this.folderFG.patchValue({type: 'node'})
this.folderFG.patchValue({ischatgpt: true})
this.folderFG.patchValue({prompt: this.configSvc.nodeds[0].defaultprompt})
// this.folderFG.patchValue({defaultprompt: true})
this.configSvc.popup =true
      }

      if(this.data.type == 'addtemplate'){
        this.folderFG.patchValue({indextemplate: true})
      }

      if(this.data.type == 'addfile'){
        this.folderFG.patchValue({attach: true})
      }

      if(this.data.type == 'nodetab' && this.configSvc.nodeds[0].ischatgpt){
        console.log('this is a chatgpt nodetab template')
        this.folderFG.patchValue({ischatgpt: true})
        this.folderFG.patchValue({type: 'nodetab'})
        this.folderFG.patchValue({prompt: this.configSvc.nodeds[0].defaultprompt})
        // this.folderFG.patchValue({defaultprompt: true})
              }


      console.log('activetype on popupinit  '+this.configSvc.activetype);
      console.log('nodeds[0] on folder popup   '+JSON.stringify(this.configSvc.nodeds[0]));
      if(this.configSvc.activetype == 'editteam' && this.configSvc.teamdetailsds.tagged){
        console.log('editing team with teamtags');
        this.folderFG.patchValue({indextype: '1'})
          this.folderFG.patchValue({fileinfolder: false})
      }

      if(this.configSvc.folderdetailsds.locktoolbar){
        console.log('parent folder has a locked toolbar');
        this.folderFG.patchValue({locktoolbar: true})
        this.folderFG.patchValue({toolbarname: this.configSvc.folderdetailsds.toolbarname})
        this.folderFG.patchValue({toolbardesc: this.configSvc.folderdetailsds.toolbardesc})
        this.folderFG.patchValue({toolbarid: this.configSvc.folderdetailsds.toolbarid})
        this.folderFG.patchValue({toolbaricon: this.configSvc.folderdetailsds.toolbaricon})
        this.folderFG.patchValue({toolbarclr: this.configSvc.folderdetailsds.toolbarclr})

         
      }

        if(this.configSvc.activetype == 'editteam' && !this.configSvc.teamdetailsds.tagged){
          console.log('editing team with NO teamtags');
    
          this.folderFG.patchValue({indextype: '0'})
          this.folderFG.patchValue({fileinfolder: false})
        }


     if(this.configSvc.toolmenu == 'toolbartools' || this.configSvc.toolmenu =='favtoolset'){
      this.folderFG.patchValue({indextemplate: true})
     // this.folderFG.value.indextemplate = true
     this.configSvc.popup =true
     this.selectTemplate(this.configSvc.nodeds[0])

     }

     

 
   //  if(this.configSvc.toolmenu != 'toolbartools'){ this.configSvc.nodeds = []}

   
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public onRecreate(){
    this.reset[0] = {}
  }

  public getFoldercontext(){
    console.log('getting dolder context')
    const values = this.configSvc.folderpath.map(item => item.desc);
    this.foldercontext = values
    console.log('here are the context values '+JSON.stringify(this.foldercontext))
    this.addallactive = true
  }



 
  createCardbyid(tagerid, tagertype, name): void {
    console.log('saving tageritem now'+name)
    console.log('saving tageritem id'+tagerid)
    console.log('saving tageritem id with level set to '+this.configSvc.activelevel)
    console.log('saving tageritem id with teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createCardbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }

  createStepsbyid(tagerid, tagertype, name): void {
    console.log('creating Steps now'+name)
    console.log('id'+tagerid)
    console.log('level set to '+this.configSvc.activelevel)
    console.log(' teamid set to '+this.configSvc.activeteamid)
    this.tagSvc.createStepsbyid(tagerid, tagertype, name, this.configSvc.activeitemid, this.configSvc.activelevel, this.configSvc.activeteamid  )
    this.dialogRef.close();
  }

 
  


  addFolder(){

    console.log('this is the options value '+this.folderFG.value.options)
    console.log('this is the indextype value '+this.folderFG.value.indextype)

    if( this.folderFG.value.options == '5'){
      this.folderFG.value.indextype = '5'
      this.folderFG.value.foldertags = this.configSvc.newpropvalues
      
      console.log('this is the newpropvalues foldertags value '+JSON.stringify(this.folderFG.value.foldertags))
    }

    if(this.folderFG.value.kanban == true){
      this.folderFG.patchValue({kanbanid: this.selectedkanban[0]._id})
      this.folderFG.patchValue({kantype: this.selectedkanban[0].kantype})
     
      this.folderFG.patchValue({kanbancolor: this.selectedkanban[0].iconcolor})
      this.folderFG.patchValue({kanbanname: this.selectedkanban[0].name})
      let kanbanprops: number = this.selectedkanban[0].stepitems.length-1
      console.log('kanbanprops is: '+ kanbanprops)

      
      this.folderFG.patchValue({kanbantotal: kanbanprops.toString()})
      console.log('selectedkanban settotal is: '+ this.selectedkanban[0].settotal)
      console.log('selectedkanban timetotal is: '+ this.selectedkanban[0].timetotal)
      this.folderFG.patchValue({settotal: this.selectedkanban[0].settotal})
    //  this.folderFG.value.settotal = this.selectedkanban[0].settotal
    console.log('settotal is '+this.folderFG.value.settotal)
      if(this.folderFG.value.settotal){
        this.folderFG.patchValue({timetotal: this.selectedkanban[0].timetotal})
        console.log('timetotal is '+this.folderFG.value.timetotal)
      }
      
    }

    if(this.folderFG.value.options == '1' && this.configSvc.folderdetailsds.tagged){
      console.log('this is inherited foldertags')
      this.folderFG.value.indextype = '1';
      console.log('set indextype to '+ this.folderFG.value.indextype)
      console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
      this.folderFG.value.foldertags = this.configSvc.newfoldertags
      this.folderFG.value.nodename= this.configSvc.folderdetailsds.nodename
      this.folderFG.value.nodeid= this.configSvc.folderdetailsds.nodeid
      this.folderFG.value.foldericon= this.configSvc.folderdetailsds.icontype
      this.folderFG.value.foldercolor= this.configSvc.folderdetailsds.tagcolor
      this.folderFG.value.tagname= this.configSvc.folderdetailsds.tagname
      this.folderFG.value.tagdesc= this.configSvc.folderdetailsds.tagdesc
      this.folderFG.value.tagtype= this.configSvc.folderdetailsds.type
      console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)

console.log('this is the selected foldertag details '+this.folderFG.value.nodename+' '+this.folderFG.value.foldericon+' '+this.folderFG.value.foldercolor)

    }
    console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
   
console.log('this is the assigned value '+this.folderFG.value.assigned)
console.log('this is the assignedto value '+this.folderFG.value.assignedto)
console.log('this is the addcomment value '+this.folderFG.value.addcomment)
console.log('this is the cmtval value '+this.folderFG.value.cmtval)
console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
console.log('this is the atcmt value '+this.folderFG.value.atcmt)
console.log('this is the atcmtuser value '+this.folderFG.value.atcmtuser)

console.log('this is the cmticon value '+this.configSvc.chosenIcon)

console.log('this is the cmtclr value '+this.configSvc.color)
console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
 var foldertags
if(this.folderFG.value.options == '1'){foldertags = JSON.stringify(this.configSvc.folderdetailsds.foldertags)}
if(this.folderFG.value.options == '5'){
  
  foldertags = JSON.stringify(this.configSvc.newpropvalues)
  this.folderFG.value.nodename= this.configSvc.nodeds[0].name
  this.folderFG.value.nodeid= this.configSvc.nodeds[0]._id
  this.folderFG.value.tagdesc= this.configSvc.nodeds[0].desc
  this.folderFG.value.tagicon= this.configSvc.nodeds[0].icontype
  this.folderFG.value.tagcolor= this.configSvc.nodeds[0].iconcolor
}

if(this.folderFG.value.options == '1' ||this.folderFG.value.options == '5' ){
    this.configSvc.addFolder('fold', this.folderFG.value.name, this.folderFG.value.desc, this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel,  this.folderFG.value.indextype, this.folderFG.value.foldericon, 
      this.folderFG.value.foldercolor,this.folderFG.value.nodename,this.folderFG.value.id, this.folderFG.value.foldertags, 
      null, null, 
      this.folderFG.value.kanban, this.folderFG.value.kanbanid, this.folderFG.value.kanbancolor, this.folderFG.value.kanbanname, this.folderFG.value.kanbantotal, 
      this.folderFG.value.locktoolbar,this.folderFG.value.toolbarid,this.folderFG.value.toolbarname, this.folderFG.value.toolbardesc, this.folderFG.value.toolbaricon, this.folderFG.value.toolbarclr,
      true, 
      this.folderFG.value.assigned, this.folderFG.value.assignedto, this.folderFG.value.deadline, this.folderFG.value.deadlineval, 
      this.folderFG.value.addcomment,this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, 
      this.folderFG.value.atcmt, this.folderFG.value.atcmtuser,foldertags, this.folderFG.value.tagname, 
      this.folderFG.value.tagdesc,this.folderFG.value.tagtype,this.folderFG.value.kantype, this.folderFG.value.settotal, 
      this.folderFG.value.timetotal, this.folderFG.value.counttotals
      );}
      if(this.folderFG.value.options == '0' ){
        this.configSvc.addFolder('fold', this.folderFG.value.name,  this.folderFG.value.desc,
          this.configSvc.activeitemid, this.configSvc.activename, 
          this.configSvc.activeteamid, this.configSvc.activelevel, 
          '0', this.folderFG.value.foldericon, 
          this.folderFG.value.foldercolor,this.folderFG.value.nodename,this.folderFG.value.id, this.folderFG.value.foldertags, 
          null, null, 
          this.folderFG.value.kanban, this.folderFG.value.kanbanid, this.folderFG.value.kanbancolor, this.folderFG.value.kanbanname,this.folderFG.value.kanbantotal, 
          this.folderFG.value.locktoolbar,this.folderFG.value.toolbarid,this.folderFG.value.toolbarname, this.folderFG.value.toolbardesc, this.folderFG.value.toolbaricon, this.folderFG.value.toolbarclr,
          true, 
          this.folderFG.value.assigned, this.folderFG.value.assignedto,
          this.folderFG.value.deadline, this.folderFG.value.deadlineval, this.folderFG.value.addcomment,
          this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser,
          null, null, null,null,
          this.folderFG.value.kantype, this.folderFG.value.settotal, this.folderFG.value.timetotal, this.folderFG.value.counttotals
     );}  
     
     if(this.folderFG.value.options == '2'){
      this.configSvc.addFolder('fold', this.folderFG.value.name, this.folderFG.value.desc,
        this.configSvc.activeitemid, this.configSvc.activename, 
        this.configSvc.activeteamid, this.configSvc.activelevel, 
        '0', this.folderFG.value.foldericon, 
        this.folderFG.value.foldercolor,this.folderFG.value.nodename,this.folderFG.value.id, this.folderFG.value.foldertags, 
        null, null, 
        this.folderFG.value.kanban, this.folderFG.value.kanbanid, this.folderFG.value.kanbancolor, this.folderFG.value.kanbanname,this.folderFG.value.kanbantotal, 
        this.folderFG.value.locktoolbar,this.folderFG.value.toolbarid,this.folderFG.value.toolbarname, this.folderFG.value.toolbardesc, this.folderFG.value.toolbaricon, this.folderFG.value.toolbarclr,
        true, 
        this.folderFG.value.assigned, this.folderFG.value.assignedto,
        this.folderFG.value.deadline, this.folderFG.value.deadlineval, this.folderFG.value.addcomment,
        this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser,
        JSON.stringify(this.configSvc.teamdetailsds.foldertags), this.configSvc.teamdetailsds.tagname, this.configSvc.teamdetailsds.tagdesc,this.configSvc.teamdetailsds.tagtype,
        this.folderFG.value.kantype, this.folderFG.value.settotal, this.folderFG.value.timetotal, this.folderFG.value.counttotals
        );}   
  }

  onIconChange(cmticon){
    this.folderFG.value.cmticon = cmticon
    console.log('changing the cmticon '+cmticon)
     if(cmticon == 'thumb_up'){this.configSvc.color = 'green'}
     if(cmticon == 'cmt'){this.configSvc.color = 'black'}
     if(cmticon == 'thumb_down'){this.configSvc.color = 'blue'}
     if(cmticon == 'priority_high'){this.configSvc.color = 'red'}
     if(cmticon == 'question_mark'){this.configSvc.color = 'blue'}

  }

  public addComment() {
    



 
    if(this.folderFG.value.cmticon == 'thumb_up'){this.configSvc.color = 'green'}
    if(this.folderFG.value.cmticon == 'cmt'){this.configSvc.color = 'black'}
    if(this.folderFG.value.cmticon == 'thumb_down'){this.configSvc.color = 'blue'}
    if(this.folderFG.value.cmticon == 'priority_high'){this.configSvc.color = 'red'}
    if(this.folderFG.value.emoji){ 
      console.log('changed to custom emoji')
      this.folderFG.patchValue({cmticon: this.configSvc.chosenIcon})}

  this.doccomments.addcomment('addcomment', false, this.configSvc.activetype, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color,  this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, false);
         
 }

 public createImage(chatgptcomment, title, mt, size) {


this.uploadSvc.createImage(chatgptcomment, title, 'createimage', mt, size, this.folderFG.value.indextype, this.folderFG.value.assigned, this.folderFG.value.assignedto,
  this.folderFG.value.deadline, this.folderFG.value.deadlineval,this.folderFG.value.statetracker, this.folderFG.value.state, this.folderFG.value.statelabel,
  this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.folderFG.value.total, this.folderFG.value.ischatgpt);
       
      }




  public setFull(){
    this.configSvc.popupwidth = '100vw';this.configSvc.popupheight = '100vh'
    console.log('set to full '+this.configSvc.popupwidth )
  }

  public askChatgpt(prompt){
    this.configSvc.chatgptresponse = false;
    console.log('asking chatgpt '+prompt)
    this.configSvc.askChatgpt(true, true, this.configSvc.activeitemid,this.configSvc.activeteamid, this.configSvc.activelevel, prompt, 'chat2', 'purple', prompt, this.folderFG.value.temp, this.folderFG.value.responses)
  }

  public askToolbard(prompt){
    this.configSvc.toolbardresponse = false;
    console.log('asking toolbard '+prompt)
    this.configSvc.askToolbard(true, true, this.configSvc.activeitemid,this.configSvc.activeteamid, this.configSvc.activelevel, prompt, 'contact_support', 'green', prompt, this.folderFG.value.temp, this.folderFG.value.responses)
  }

  public savePrompt(){
    console.log('saving prompt and refreshing')
    this.folderFG.patchValue({promptsent: this.folderFG.value.prompt})
    this.tagerSvc.createChoiceitem( this.folderFG.value.promptname, this.folderFG.value.prompt,  this.folderFG.value.promptlist.path[0].pfoldid, 0)
    
    this.refreshFolder()
  }
  
/*
  public dropped(files: NgxFileDropEntry[]) {
    this.uploadSvc.totalfilesuploaded = files.length
    console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
    this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
   
var process = 'fileupload'    
if (this.msgready == true){
  process = 'msgupload'
};
   
    this.uploadSvc.dropped(files, this.indextype.toString(), this.folderFG.value.textract, process);

  // this.dialogRef.close();
    }
*/
    public filedropped(process) {

      var filetoupload
      if(process == 'msg'){
        console.log('this is an dragged msg file')
        filetoupload = this.msgfiles
        this.folderFG.value.mt = 'msg'
        console.log(' mt before uploading msg '+ this.folderFG.value.mt)
        if(this.folderFG.value.ischatgpt){
          process = 'msgresp'
          this.folderFG.patchValue({mt: 'msg'})
        }
      }

      if(process != 'msg' && process != 'msgresp'){
        console.log('this is a file system file')
       // this.folderFG.value.mt = 'pdf'
      //  this.folderFG.patchValue({mt: 'pdf'})
        console.log('mt is '+this.folderFG.value.mt)
        filetoupload = this.allfiles[0]
      }
      console.log('content of allfiles for '+process+'  '+JSON.stringify(this.allfiles) )
   //   this.uploadSvc.totalfilesuploaded = this.allfiles.length
      console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
      this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
 if(this.folderFG.value.fileinfolder){
      if(this.configSvc.folderdetailsds.tagged){this.indextype = '1'}
      if(!this.configSvc.folderdetailsds.tagged){this.indextype = '0'}}
      
      if(this.folderFG.value.emoji) { this.folderFG.patchValue({cmticon: this.configSvc.chosenIcon})}
   
    
      console.log('mimetype2 is '+this.folderFG.value.mt)
     
      this.uploadSvc.filedropped( this.folderFG.value.fileinfolder, process, this.folderFG.value.mt, filetoupload, this.folderFG.value.indextype, this.folderFG.value.assigned, this.folderFG.value.assignedto,
         this.folderFG.value.deadline, this.folderFG.value.deadlineval,this.folderFG.value.statetracker, this.folderFG.value.state, this.folderFG.value.statelabel,
         this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.folderFG.value.total, 
         this.folderFG.value.extract, this.folderFG.value.askchatgpt, this.folderFG.value.prompt, this.folderFG.value.temp, this.folderFG.value.responses)
     

    

      
  
    // this.dialogRef.close();
      }

      public bulkFiledropped(filesdropped: NgxFileDropEntry[]) {
        
        
        for (const droppedFile of filesdropped) {


      
          
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  
  
          fileEntry.file((eachfile) => {
            console.log('cwhat is going on here');
            if(this.folderFG.value.emoji) { this.folderFG.patchValue({cmticon: this.configSvc.chosenIcon})}
       
        this.uploadSvc.filedropped( this.folderFG.value.fileinfolder, 'fileupload',this.folderFG.value.mt, eachfile, this.folderFG.value.indextype, this.folderFG.value.assigned, this.folderFG.value.assignedto,
           this.folderFG.value.deadline, this.folderFG.value.deadlineval,this.folderFG.value.statetracker, this.folderFG.value.state, this.folderFG.value.statelabel,
           this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser)
          });
     
        }
          
  
      this.dialogRef.close();
        }

      public filesChosen(){
        console.log('files chosen')
        this.imageready = true
      }

    /*
    public bulkprocess(files: NgxFileDropEntry[]) {
      console.log('bulk processing now')
      this.uploadSvc.bulkprocess(files);
    }
*/
    public bulkAddfolders(files: NgxFileDropEntry[]) {
      
      for (const droppedFile of files) {


      
          
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  
  
          fileEntry.file((fileext) => {
            console.log('cwhat is going on here');
      //start of parse process
      this.ngxCsvParser.parse(fileext, { header: this.header, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
 
        console.log('Result', result);
        this.csvRecords = result;

        for(const csvitem of this.csvRecords){
      //   console.log('this is the skeleton indexes'+JSON.stringify(this.configSvc.teamdetailsds.stepitems))
        console.log('csvitem is: '+csvitem.Name)
        console.log('this is the csv row: '+JSON.stringify(csvitem))
        this.configSvc.totalcsvrows = this.csvRecords.length;
    console.log('totalcsvrows is: '+this.configSvc.totalcsvrows)
    
        let name = csvitem.name
        if(this.folderFG.value.bulkindex == '1'){
          console.log('youve chosen to tag these bulk folders using the parent folder tags')
       // this.configSvc.newpropvalues = this.configSvc.folderdetailsds.foldertags
     
          console.log('this is inherited foldertags')
          this.folderFG.value.indextype = '1';
          console.log('set indextype to '+ this.folderFG.value.indextype)
          console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
          this.folderFG.value.foldertags = this.configSvc.folderdetailsds.foldertags
          this.folderFG.value.nodename= this.configSvc.folderdetailsds.nodename
          this.folderFG.value.foldericon= this.configSvc.folderdetailsds.icontype
          this.folderFG.value.foldercolor= this.configSvc.folderdetailsds.tagcolor
          console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
    
    console.log('this is the selected foldertag details '+this.folderFG.value.nodename+' '+this.folderFG.value.foldericon+' '+this.folderFG.value.foldercolor)
    
        
      }
        if(this.configSvc.activetype == 'team' && this.configSvc.folderdetailsds.tagged){
          console.log('this is team tagged')
          this.folderFG.value.foldertags = this.configSvc.teamdetailsds.foldertags
          this.folderFG.value.indextype = '1'
        }

          if(this.configSvc.activetype == 'fold' && !this.configSvc.folderdetailsds.tagged){
            console.log('this is a plain old folder')
            this.folderFG.value.indextype = '0'
          }

        for(const propertyitem of this.configSvc.newpropvalues )
        {

          console.log('property is'+propertyitem.formitem);
            let csvprop= propertyitem.formitem
            console.log('csvprop is'+csvprop);
          console.log('property value is'+propertyitem.itemvalue);
          propertyitem.itemvalue = csvitem[csvprop];
          console.log('newly update property value is'+propertyitem.itemvalue);
        }

        console.log('this is the populated new skeleton indexes'+JSON.stringify(this.configSvc.newpropvalues ))
        console.log('this is the indextype value on bulk folder add '+ this.folderFG.value.indextype);

        
        this.configSvc.addFolder('fold', name, 
      this.configSvc.activeitemid, this.configSvc.activename, 
      this.configSvc.activeteamid, this.configSvc.activelevel, 
      this.folderFG.value.indextype, this.folderFG.value.foldericon, 
      this.folderFG.value.foldercolor,this.folderFG.value.nodename, this.folderFG.value.nodeid, this.folderFG.value.foldertags, 
      null, null, this.folderFG.value.kanban, this.folderFG.value.kanbanid, this.folderFG.value.kanbancolor,  this.folderFG.value.kanbanname, this.folderFG.value.kanbantotal, true, 
      this.folderFG.value.assigned, this.folderFG.value.assignedto,
      this.folderFG.value.deadline, this.folderFG.value.deadlineval, this.folderFG.value.addcomment,
      this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, this.folderFG.value.atcmtuser,
      this.folderFG.value.kantype, this.folderFG.value.settotal, this.folderFG.value.timetotal, this.folderFG.value.counttotals);


        }
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      //end of parse process
    });
     
  }
    }

    
 
  
    
    
      public fileOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

      
   public dropversion(files: NgxFileDropEntry[]) {
    this.uploadSvc.dropversion(files);
    }

    usernameFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log('filtering email on: '+filterValue)
      if (filterValue != '')
      {
        console.log('filter value befoore sending is : '+filterValue)
      this.configSvc.usernameFilter(filterValue, 'user')
  
  
    }
    
    }

    
    
      public fileverOver(event) {
        this.configSvc.docadd = true;
        // this.configSvc.browserspan = 1;
        this.configSvc.fileover= true;
        console.log('docadd value is' + this.configSvc.docadd);
        console.log(event);
      }

      public fileverLeave(event) {
        console.log(event);
    
      }

      public delete(deletetype){

        if(this.configSvc.activetype == 'fold' || this.configSvc.activetype=='smartfoldertab'){
         
          console.log('this is the pathds'+JSON.stringify(this.configSvc.folderpath));
          console.log('this is the current level'+this.configSvc.activelevel);
          console.log('this is the parent path'+JSON.stringify(this.configSvc.folderpath[this.configSvc.activelevel-1]));
          var parentpath = this.configSvc.folderpath[this.configSvc.activelevel-1]
          console.log('this is the parentpath'+JSON.stringify(parentpath));
          var parentitemid = parentpath.pfoldid
          console.log('this is the parentitemid'+parentitemid);
          var parenttype = parentpath.type
          var parentname = parentpath.name
          var parentlevel = parentpath.level
          var parentteamid = parentpath.teamid
        console.log('deleting folder '+JSON.stringify(this.configSvc.activename));
        this.configSvc.deleteFolder(this.configSvc.activeitemid, parentitemid, this.configSvc.activeteamid)
       // this.router.navigateByUrl(this.configSvc.currentURL);
       this.router.navigate(['/toolbard/home'], { queryParams: { itemid: parentitemid, type:parenttype, name: parentname, level: parentlevel, teamid: parentteamid } });
        //this.router.navigate(["/toolbard/home" [queryParams] = "{itemid:element._id, type:element.type, name: element.name, level: element.level, teamid: element.teamid}" ></a>
      }
        if(deletetype == 'item' && this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, true ,this.configSvc.currentrow.activetrack )
        }

        if(deletetype == 'item' && !this.configSvc.folderdetailsds.kanbantagged){
          console.log('deleting non kanbanned item '+JSON.stringify(this.configSvc.currentrow.name));
        this.configSvc.deleteItem(this.configSvc.currentrow._id, this.configSvc.currentfolder, 
          this.configSvc.activeteamid, false )
        }
         

        this.dialogRef.close();
      }

      public findUser() {
        this.finduserclicked = true;
        this.configSvc.findUser(this.usersearchfc.value);
       // console.log('userdetailset is' + this.configSvc.userdetailset);
       // this.dbuser = this.configSvc.userdetailset.name;
       // this.dbemail = this.configSvc.userdetailset.email;
      }
  
      drop(event: CdkDragDrop<string[]>) {
     
       
        transferArrayItem(this.configSvc.finduserdataset, this.configSvc.usersadded, event.previousIndex, event.currentIndex);
        console.log('finished transferring users to selected user');
        console.log('current selected user list'+JSON.stringify(this.configSvc.usersadded));
        //this.configSvc.userselected.push(this.configSvc.usersadded._id);
    }
  
    public addMyself(){
      this.configSvc.mgrsadded = [];
      const userdetails  = this.auth.getUserDetails();
      console.log('this is the managers details'+JSON.stringify(userdetails))
      const username = userdetails.username
      this.configSvc.mgrsadded.push(username)
      console.log('activetype after adding myself ist'+this.configSvc.activetype)
     // this.configSvc.findUser(mgremail, 'addmyself');
     // this.myself = userdetails;
     this.configSvc.activetype = 'team'
     this.configSvc.usersadded = []
    // this.configSvc.mgrsadded.push(userdetails);
  
  
    }
   
   

    cutItem(itemid, type, level, teamid, name, icontype, iconcolor, sourcekanban){
      console.log('moving itemid '+itemid)
      console.log('moving item name '+name)
      console.log('moving item icontype '+icontype)
      console.log('moving item iconcolor '+iconcolor)

      this.configSvc.move = true;
      this.configSvc.moveitem = [];
      if(sourcekanban == true){
      this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
       'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': this.configSvc.folderdetailsds._id, 'activetrack' : this.configSvc.nodeds[0].activetrack})
      console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}

      if(sourcekanban == false){
        this.configSvc.moveitem.push({'itemid': itemid, 'name':name, 'type': type, 'level': level,
         'teamid': teamid, 'icontype':icontype, 'iconcolor':iconcolor, 'sourcekanban': sourcekanban, 'sourcekanbanid': '', activetrack : 'notrack'})
        console.log('moved item details '+JSON.stringify(this.configSvc.moveitem));}


      this.dialogRef.close();
     
    }

    pasteItem(itemid,parentfolderid, type, level, teamid, name, icontype, iconcolor, sourcekanban, sourcekanbanid, activetrack){

      console.log('icontype is '+icontype); console.log('iconcolor is '+iconcolor)
      this.configSvc.moveItem(itemid, parentfolderid, type, level, teamid, name, icontype, iconcolor, sourcekanban, sourcekanbanid, activetrack )
      this.dialogRef.close();
    }

    moveFolder(itemid, name){
      console.log('moving folder')
      this.configSvc.move = true;
      this.dialogRef.close();
    }

    folderReport(attachreport){
      // Default export is a4 paper, portrait, using millimeters for units
      console.log('starting to write report')
      console.log('this is the contents of the folder '+JSON.stringify(this.configSvc.teamds))

      var doc = new jsPDF();
      var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      var img = new Image()
      img.src = 'assets/vertiv.png'
      doc.addImage(img, 'png', 10, 5, 24, 30)

      var img = new Image()
      img.src = 'assets/EandI.png'
      doc.addImage(img, 'png',  pageWidth-30, 5, 24, 30)
      
      doc.setTextColor(10);
      console.log('family is set to '+this.folderFG.value.family)
      console.log('style is set to '+this.folderFG.value.style)
     doc.setFont(this.folderFG.value.family, this.folderFG.value.style)
    
     
    // doc.setFont(this.nodeFG.value.style, this.nodeFG.value.fonttype);
      console.log('font is set to '+this.folderFG.value.fonttype)
      doc.setFontSize(18);
     
     // var {CheckBox} = jsPDF.;
    // doc.advancedAPI(doc => {
      // your code
    
      this.myDate = new Date();
      this.myDate = this.datePipe.transform(this.myDate, 'dd-MM-yyyy');
      doc.text(this.auth.getUserDetails().companyname+' Report', pageWidth / 2, 30, {align: 'center'});
  
      doc.text('Summary report for '+this.configSvc.activename, pageWidth / 2, 50, {align: 'center'});
      doc.text('Report created '+this.myDate , pageWidth / 2, 70, {align: 'center'});
      doc.text(this.configSvc.folderdetailsds.itemcnt +' items summarized in this report', pageWidth / 2, 90, {align: 'center'});
     // doc.text('Summary report for '+this.configSvc.activename, 10, 30, 'center');
     var img = new Image()
     img.src = 'assets/crossbar.png'
     doc.addImage(img, 'png',  5, 92, pageWidth-10, 5)
     doc.setTextColor(100);
     doc.setFontSize(10);
     doc.text(this.auth.getUserDetails().companyname+' Summary', 10, pageHeight-10, );
     
   
  
     doc.setTextColor(100);
     doc.setFontSize(10);
     var pagecnt = 0;
     pagecnt++
     doc.text('Page ' + pagecnt, pageWidth-30, pageHeight-10, );
    // pagecnt++;
 

   
    
     // doc.text('Do you like that?',20, 20);

     
     
     for(var i = 0; i < this.configSvc.teamds.length; i++){
      var ylocation =50
       var asset = this.configSvc.teamds[i]
      console.log('asset desc is '+asset.desc)
      doc.addPage();
      doc.setTextColor(100);
      doc.setFontSize(10);
      pagecnt++
      doc.text('Page ' + pagecnt, pageWidth-30, pageHeight-10, );
 
      doc.setTextColor(5)
      doc.setFontSize(14);
      doc.text(asset.bo +':'+asset.name, pageWidth / 2, 10, {align: 'center'})
      var img = new Image()
      img.src = 'assets/crossbar.png'
      doc.addImage(img, 'png',  5, 11, pageWidth-10, 5)
      doc.setTextColor(10)
     //  doc.text('Page ' + pagecnt++, pageWidth-30, pageHeight-10, );
    
    
       var ab: string = String(asset.ab);
       var ao: string = String(asset.ao);
       var mb: string = String(asset.mb);
       var mo: string = String(asset.mo);
       var ti: string = String(asset.ti);
       var bo: string = String(asset.bo);
       var desc: string = String(asset.desc);
       var name: string = String(asset.name);
       
       doc.text(name, 10, pageHeight-10, );
       doc.text('Name: ' +name, 10, 20);
       doc.text('Unique ID: ' +ti, 10, 25);
       doc.text('Based on: ' +bo, 10, 30);
       doc.text('Description:' +desc, 10, 35);
       doc.text('Added: ' +this.datePipe.transform(ao, 'dd-MM-yyyy, hh:mm a')+' by '+ab, 10, 40);
       doc.text('Last Modified: ' + this.datePipe.transform(mo, 'dd-MM-yyyy, hh:mm a')+' by '+mb, 10, 45);
 ylocation =50
       if(asset.cmt ){
       // ylocation = 50
        doc.text('Comments: ', 10, 50);
      
  
      Object.keys(asset.comments).forEach((i3) => {
       // doc.setTextColor(100);
       // doc.setFontSize(12);
       ylocation =ylocation +5;
        console.log('i3 value is '+i3)
        var cmt: string = String(asset.comments[i3].comment);
        var ab: string = String(asset.comments[i3].ab);
        var ao: string = String(asset.comments[i3].ao);
        ao = this.datePipe.transform(ao, 'dd-MM-yyyy, h:mm a');
        doc.text('"'+cmt+'"  by '+ab+' on '+ao,10, ylocation);
        console.log('i3 value is '+i3)
      
      })
    
     
     }

     
    if(asset.attach){
      ylocation = ylocation +5;
    
    //   doc.text('Attachments', 10, ylocation);
     
      doc.text('This item has attachments/pictures associated with it.', 10, ylocation);
      doc.text('Please refer to the individual report for this '+bo, 10, ylocation+5);
    }

     ylocation =  ylocation+10;
     var xlocation =  10;
      var boxcount = 0
Object.keys(this.configSvc.teamds[i].stepitems).forEach((i2) => {
  ylocation =ylocation +5;
if(this.configSvc.teamds[i].stepitems[i2].formtype == 'string' || this.configSvc.teamds[i].stepitems[i2].formtype == 'number' ){

  var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
  doc.text( this.configSvc.teamds[i].stepitems[i2].formitemlabel+ ':' +itemvalue, xlocation, ylocation);
}

if(this.configSvc.teamds[i].stepitems[i2].formtype == 'label'){
  //  ylocation = ylocation +5
   xlocation = 10;
   ylocation = ylocation+15;
 var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
 // doc.setFont(undefined, 'bold')
 doc.setFont('Courier', 'bold')
 doc.setFontSize(12);
// doc.text( this.configSvc.teamds[i].stepitems[i2].formitemlabel, xlocation,ylocation);
 var text = this.configSvc.teamds[i].stepitems[i2].formitemlabel
var lines =  doc.splitTextToSize(text, 180)
doc.text( lines, xlocation,ylocation);
 doc.setFont(this.folderFG.value.family, this.folderFG.value.style)
 doc.setFontSize(14);
 }

 if(this.configSvc.teamds[i].stepitems[i2].formtype == 'text'){
  //  ylocation = ylocation +5
   xlocation = 10;
   ylocation = ylocation+15;
 var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
 doc.setFont('Courier', 'bold')
 doc.setFontSize(12);
 ylocation = ylocation+10;
 doc.text( this.configSvc.teamds[i].stepitems[i2].formitemlabel+': ', xlocation,ylocation);
 doc.setFont(this.folderFG.value.family, this.folderFG.value.style)
 doc.setFontSize(14);
 ylocation = ylocation+5;
 var text = this.configSvc.teamds[i].stepitems[i2].itemvalue
 var lines =  doc.splitTextToSize(text, 180)

 doc.text( lines, xlocation,ylocation);

 }
 
 if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
 console.log('checkboxylocation at start is set to '+location)
 
 const checkBox =new AcroFormCheckBox();
 checkBox.fieldName = "CheckBox";
 checkBox.x = 5;
 checkBox.y =ylocation;
 checkBox.width = 5;
 checkBox.height = 15;
 checkBox.maxFontSize = 15;
 checkBox.readOnly = true;
 checkBox.textAlign = 'center';
 // checkBox.value = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
 // checkBox.caption = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
 //checkBox.fieldName = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
 
 if(!this.configSvc.teamds[i].stepitems[i2].hasdefault){checkBox.color = 'red'; checkBox.caption = '8';}
 if(this.configSvc.teamds[i].stepitems[i2].hasdefault){checkBox.color = 'green';}
 
    
 
 
    var formitemlabel: string = String(this.configSvc.nodeds[0].stepitems[i2].formitemlabel);
    if(this.configSvc.teamds[i].stepitems[i2].formtype == 'checkbox'){
     xlocation = xlocation +5
      doc.text( formitemlabel,xlocation,ylocation);}
 
   
 
      
    
  }
 
  if(this.configSvc.teamds[i].stepitems[i2].formtype == 'boolean'){
 
   xlocation =5;
   console.log('checkboxylocation at start is set to '+location)
   console.log('y co-ordinate is ')
   const checkBox =new AcroFormCheckBox();
   checkBox.fieldName = "CheckBox";
   checkBox.x = xlocation;
   checkBox.y =ylocation;
   checkBox.width = 5;
   checkBox.height = 15;
   checkBox.maxFontSize = 15;
   checkBox.readOnly = true;
   checkBox.textAlign = 'center';
   // checkBox.value = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
   // checkBox.caption = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
   //checkBox.fieldName = this.configSvc.nodeds[0].stepitems[i2].formitemlabel;
   
   
   if(!this.configSvc.teamds[i].stepitems[i2].hasdefault && !this.configSvc.teamds[i].stepitems[i2].label){
 
     checkBox.color = 'red'; checkBox.caption = '8';
     
     doc.text('Not Completed '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, xlocation,ylocation+10, { maxWidth: 180 });}
 
 
   if(this.configSvc.teamds[i].stepitems[i2].hasdefault && !this.configSvc.teamds[i].stepitems[i2].label)
   
   {checkBox.color = 'green';
   doc.text('Completed '+this.configSvc.teamds[i].stepitems[i2].formitemlabel, xlocation,ylocation+10, { maxWidth: 180 });}
 
    var formitemlabel: string = String(this.configSvc.teamds[i].stepitems[i2].formitemlabel);
 /*
    if(!this.configSvc.nodeds[0].stepitems[i2].label){
    doc.addField(checkBox);
      doc.text('Completed one toggle'+formitemlabel, 15,ylocation+10, { maxWidth: 180 });}
      */
   if(this.configSvc.teamds[i].stepitems[i2].label){
 
     var labeltext = 'Completed '+formitemlabel;
        
        
           if(!this.configSvc.teamds[i].stepitems[i2].checkbox){
             boxcount++
             xlocation =10
           ylocation =ylocation+15
             var itemvalue: string = String(this.configSvc.teamds[i].stepitems[i2].itemvalue);
         //    labeltext = 'Completed '+formitemlabel+ ''+itemvalue 
      //   checkBox.color = 'green'; checkBox.caption = '';
         doc.addField(checkBox);
         xlocation = 5
        
             doc.text(formitemlabel, xlocation+10,ylocation, { maxWidth: 180 });
          
           //  ylocation = ylocation + 10
             doc.text(itemvalue, xlocation+10,ylocation+10, { maxWidth: 180 });
           //  checkBox.color = 'green'; checkBox.caption = '';
           checkBox.x = xlocation;
           checkBox.y =ylocation;
           checkBox.color = 'green';
             doc.addField(checkBox); 
         }
   
         if(this.configSvc.teamds[i].stepitems[i2].checkbox){
           boxcount++
           xlocation =5
          ylocation =ylocation+10
           var textarea: string = String(this.configSvc.teamds[i].stepitems[i2].textarea);
          checkBox.color = 'red';checkBox.caption = '8';
          checkBox.x = xlocation;
          checkBox.y =ylocation;
          doc.addField(checkBox);
          doc.setTextColor('red')
          
               doc.text(formitemlabel, xlocation+10,ylocation, { maxWidth: 180 });
               
            
               doc.text(textarea,xlocation+10,ylocation+10, { maxWidth: 180 });
               checkBox.x = xlocation;
               checkBox.y =ylocation;
            //   checkBox.color = 'green'; checkBox.caption = '';
               doc.addField(checkBox); 
               doc.setTextColor('black')
       }
 
       if(ylocation > 240){
         ylocation = 0
    
 
   var asset = this.configSvc.teamds[i]
 
    // var asset = this.configSvc.teamds[i]
    console.log('asset desc is '+asset.desc)
 
    doc.addPage();
    doc.setTextColor(100);
    doc.setFontSize(10);
    pagecnt++
    doc.text('Page ' + pagecnt, pageWidth-30, pageHeight-10, );

    doc.setTextColor(5)
    doc.setFontSize(14);
    doc.text(asset.bo +':'+asset.name, pageWidth / 2, 10, {align: 'center'})
       }
       
       
      
   
       
         }
       
       
      
    }
      
    })

   

     
     }

      var title =this.configSvc.activename+" "+"Report.pdf";
      doc.save(title);
      console.log('finished writing report')
      var uploadedpdf = doc.output('blob');
      console.log('finished writing report')
      if(attachreport){
        console.log('filing report now')
      this.fileReport(uploadedpdf)
      }

//
this.dialogRef.close();}

    public fileReport(doc) {
      var reportname =  this.configSvc.activename+' Folder Report.pdf';
      this.uploadSvc.totalfilesuploaded = doc.length
      console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
      this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
      this.uploadSvc.fileReport(doc, reportname);
      }

      public prepareFile(event) {
        this.msgready = false; this.pdfready = false; this.fileready=false; this.imageready=false
        if (event.target.files && event.target.files[0]) {

          this.filename = event.target.files[0].name
          console.log('preparing this file: '+this.filename)
console.log('file extension is' +this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename)
this.folderFG.patchValue({mt: this.filename.substring(this.filename.lastIndexOf('.')+1, this.filename.length) || this.filename })


if(this.folderFG.value.mt == 'jpeg' || this.folderFG.value.mt == 'jpg' || this.folderFG.value.mt == 'png'){
          this.imageloaded = new FileReader();
      this.actualimage = event.target.files[0]
      this.allfiles = event.target.files
          this.imageloaded.readAsDataURL(event.target.files[0]); // read file as data url
      
          this.imageloaded.onload = (event) => { // called once readAsDataURL is completed
            this.url = event.target.result;
   this.imageready = true}

          }
        }
      
        if(this.folderFG.value.mt != 'jpeg' && this.folderFG.value.mt != 'jpg' && this.folderFG.value.mt != 'png' && this.folderFG.value.mt != 'pdf' && this.folderFG.value.mt != 'msg'  ){
          this.allfiles = event.target.files
          this.fileready = true;
console.log('this is standard file')}

if(this.folderFG.value.mt == 'pdf'  ){
  this.allfiles = event.target.files
  
  this.pdfready = true;
console.log('this is a pdf file')
this.folderFG.value.mt = 'pdf'
console.log('this is the mt value '+this.folderFG.value.mt)
}

if( this.folderFG.value.mt == 'msg'  ){
  console.log('this is msg file')
  this.allfiles = event.target.files
  
  this.msgready = true;
  console.log('this is msgready status '+this.msgready)}




        }

        public prepareMsg(files: NgxFileDropEntry[]) {
          this.msgready = false; this.pdfready = false; this.fileready=false; this.imageready=false
          /*
          if (files && files[0]) {
  
            console.log('preparing email')}
            */
            for (const droppedFile of files) {
              if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((eachfile: File) => {
              this.msgfiles = eachfile
            });
          }
  
          }
  

    console.log('this is msg file')
    // this.allfiles = files
    console.log('this is msg  '+JSON.stringify(files));
    console.log('this is msg title '+JSON.stringify(files[1].relativePath));
    this.filename = JSON.stringify(files[1].relativePath);
  //  this.msgfiles = files[1].fileEntry
    this.msgready = true;
    console.log('this is msgready status '+this.msgready)
    
  
  
  
  
          }

          

      
 
        templateFilter(event: Event) {
          const filterValue = (event.target as HTMLInputElement).value;
          console.log('filtering index on: '+filterValue)
          if (filterValue != '')
          {
            console.log('filter value befoore sending is : '+filterValue)
          this.configSvc.findTemplate(filterValue)
        console.log('templateFilter value is currently set to '+this.configSvc.finditemds)
      
        }
      }

      selectTemplate(template){
console.log('this is the selected index '+JSON.stringify(template))
this.configSvc.nodeds[0] = template
this.onRecreate()
console.log('template type is set to '+template.type)
console.log('template textractable is set to '+template.textractable)
console.log('template textractype is set to '+template.textractype)
this.folderFG.patchValue({type: template.type })
if(this.folderFG.value.attach == true){
this.folderFG.patchValue({textract: template.textractable })
this.folderFG.patchValue({textractype: template.textractype })


}

if(template.ischatgpt){this.folderFG.patchValue({ischatgpt: true })}

if(template.textractable){
  this.folderFG.patchValue({attach: true})
  this.folderFG.patchValue({textract: template.textractable })
this.folderFG.patchValue({textractype: template.textractype })
}
console.log('folderfg template type is set to '+this.folderFG.value.type)
if(this.folderFG.value.type=='kanban' ){
  this.folderFG.patchValue({kanban: true })

  this.kanbanSelected(template)
}
if(this.folderFG.value.type=='smartfolder' || this.folderFG.value.type=='smartfoldertab'  ){
  this.folderFG.patchValue({options: '4' })

  this.smartfolderSelected(template)
}
this.indexready =true
if(template.type == 'basicflow' || template.type == 'editstepperassign' || template.type == 'edittabbedassign'){
  this.folderFG.patchValue({flow: true, assigned: false })
  console.log('folderfg flow value: '+this.folderFG.value.flow)
}
      }

      foldertagFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        console.log('filtering index on: '+filterValue)
        this.configSvc.finditemds = [];
        if (filterValue != '')
        {
          console.log('filter value befoore sending is : '+filterValue)
        this.configSvc.findFoldertags( filterValue)
      console.log('foldertagFilter value is currently set to '+this.configSvc.finditemds)
    
      }
    }

 

    smartfolderFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log('filtering index on: '+filterValue)
      this.configSvc.finditemds = [];
      if (filterValue != '')
      {
        console.log('filter value befoore sending is : '+filterValue)
      this.configSvc.findSmartfolder( filterValue)
    console.log('foldertagFilter value is currently set to '+this.configSvc.finditemds)
  
    }
  }

    public buildCsv(){
      console.log('building csv get headers')
      console.log('this is the nodeds stepitems'+JSON.stringify(this.configSvc.nodeds[0].stepitems))
var buildheaders = []
      for( const header of this.configSvc.nodeds[0].stepitems){
        console.log('formitem is '+header.formitem)
        buildheaders.push(header.formitem)
        console.log('buildheaders is '+JSON.stringify(buildheaders))
      }
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: false, 
        showTitle: false,
        title: 'Your title',
        useBom: true,
        noDownload: false,
        headers: buildheaders,
        useHeader: true,
        nullToEmptyString: true,
      };
     new AngularCsv([], 'My Report.csv', options);
      

    }

    public bulkforms(files: NgxFileDropEntry[], type) {
      console.log('bulk processing locally with type of'+type)
     
      this.configSvc.csvrowcnt = 0;
      this.csvprocessed =0;
      for (const droppedFile of files) {
    
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
         
          fileEntry.file((fileext) => {
            console.log('cwhat is going on here bulk forms');
      //start of parse process
      this.ngxCsvParser.parse(fileext, { header: true, delimiter: ',' })
      .pipe().subscribe((result: Array<any>) => {
    
        console.log('Result', result);
        this.csvRecords = result;
        this.configSvc.totalcsvrows = this.csvRecords.length;
        console.log('totalcsvrows is: '+this.configSvc.totalcsvrows)
    
        for(const newfolder of this.csvRecords){
       //  console.log('this is the skeleton indexes'+JSON.stringify(this.configSvc.teamdetailsds.stepitems))
     //   console.log('newfolder is: '+newfolder.name)
      //  console.log('this is the csv row: '+JSON.stringify(newfolder))
       
     //   
      //  let name = newfolder.name
        this.configSvc.newpropvalues = this.configSvc.nodeds[0].stepitems
        for(const propertyitem of this.configSvc.newpropvalues )
        {
    
          console.log('property is'+propertyitem.formitem);
            let csvprop= propertyitem.formitem
          console.log('property value is'+propertyitem.itemvalue);

          if(propertyitem.formtype != 'boolean'){
          propertyitem.itemvalue = newfolder[csvprop];
          console.log('newly update property value is'+propertyitem.itemvalue);
          }
        }
    
        console.log('this is the populated new skeleton indexes'+JSON.stringify(this.configSvc.newpropvalues ))
        
     
      
      
    
        this.configSvc.saveNode(this.folderFG.value.fileinfolder, true, this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
          this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, type, 
        JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0].icontype, 
        this.configSvc.nodeds[0].iconcolor, this.folderFG.value.attach, this.folderFG.value.textract, this.folderFG.value.indextype, 
        
        this.folderFG.value.assigned,  this.folderFG.value.assignedto, this.folderFG.value.deadline,this.folderFG.value.deadlineval,
        this.folderFG.value.statetracker,  this.folderFG.value.statelabel,
        this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, 
        this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.allfiles, this.folderFG.value.total, this.folderFG.value.textractype, this.folderFG.value.ischatgpt, this.folderFG.value.prompt, this.folderFG.value.temp, this.folderFG.value.responses )
    
        }
      }, (error: NgxCSVParserError) => {
        console.log('Error', error);
      });
      //end of parse process
    });
     
    }
    }

    public createSmartfolder(){

      console.log('saving smart folder for '+this.configSvc.nodeds[0]._id);
      console.log('does this superfolder have subfolders?  '+this.configSvc.nodeds[0].folders);
      console.log('lastest smart folder properties '+JSON.stringify(this.configSvc.newpropvalues));
    var subfolders = null;
    var kanbanprops = null
    if(this.configSvc.nodeds[0].folders == true){
      console.log('this smart folder has subfolders')
      subfolders =this.configSvc.nodeds[0].subfolders }
    
      var kanbanid = null;
    if(this.configSvc.nodeds[0].kanbantagged == true){
      console.log('this smart folder has a kanban')
     kanbanid =  this.configSvc.nodeds[0].kanbanid}
     
     var smartfolderproperties
     var foldertags
     var foldertype
 
        if(this.configSvc.nodeds[0].type == 'smartfolder'){
          smartfolderproperties = JSON.stringify(this.configSvc.newpropvalues)
          foldertype = 'smartfolder'
          this.folderFG.value.name = this.configSvc.newpropvalues[0]?.itemvalue
       //   indextype = '3'
        }
        if(this.configSvc.nodeds[0].type == 'smartfoldertab'){
          smartfolderproperties = JSON.stringify(this.configSvc.stepperprops)
          foldertype = 'smartfoldertab'
        //  indextype = '4'
          this.folderFG.value.name = this.configSvc.stepperprops[0]?.itemvalue
          if(this.configSvc.nodeds[0].foldertagged == true ){
            //  this.folderFG.patchValue({indextype: '1'})
            this.folderFG.value.foldertags = smartfolderproperties
            console.log('this is a smart foldertab with foldertags')
            this.folderFG.value.indextype = '1'
          //  foldertags = this.configSvc.stepperprops[0].step[0];
         ///   console.log('this the first prop '+JSON.stringify(foldertags));
          }
        }

        if(this.folderFG.value.options == '1' && this.configSvc.folderdetailsds.tagged){
          console.log('this is inherited foldertags')
          this.folderFG.value.indextype = '1';
          console.log('set indextype to '+ this.folderFG.value.indextype)
          console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
          this.folderFG.value.foldertags = this.configSvc.newfoldertags
          this.folderFG.value.nodename= this.configSvc.folderdetailsds.nodename
          this.folderFG.value.nodeid= this.configSvc.folderdetailsds.nodeid
          this.folderFG.value.foldericon= this.configSvc.folderdetailsds.icontype
          this.folderFG.value.foldercolor= this.configSvc.folderdetailsds.tagcolor
          console.log(' indextype before exec addfolder '+ this.folderFG.value.indextype)
    
    console.log('this is the selected foldertag details '+this.folderFG.value.nodename+' '+this.folderFG.value.foldericon+' '+this.folderFG.value.foldercolor)
    
        }
        if(this.folderFG.value.options == '4'){
        console.log('adding smart folders')
        console.log('count totals is '+this.folderFG.value.counttotals)
        this.configSvc.addFolder( 
          foldertype, this.folderFG.value.name, this.folderFG.value.desc, this.configSvc.activeitemid, this.configSvc.activename, 
        this.configSvc.activeteamid, this.configSvc.activelevel, this.folderFG.value.indextype,this.configSvc.nodeds[0].icontype, 
        this.configSvc.nodeds[0].iconcolor, this.folderFG.value.nodename, this.folderFG.value.nodeid, smartfolderproperties, 
        this.configSvc.nodeds[0].folders, subfolders, this.configSvc.nodeds[0].kanbantagged, kanbanid, 
        this.configSvc.nodeds[0].kanbancolor, this.folderFG.value.kanbanname, this.folderFG.value.kanbantotal, 
        this.folderFG.value.locktoolbar,this.folderFG.value.toolbarid,this.folderFG.value.toolbarname, this.folderFG.value.toolbardesc, this.folderFG.value.toolbaricon, this.folderFG.value.toolbarclr,
         true, this.folderFG.value.assigned, 
        this.folderFG.value.assignedto, this.folderFG.value.deadline, this.folderFG.value.deadlineval, this.folderFG.value.addcomment,
        this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, this.folderFG.value.atcmt, 
        this.folderFG.value.atcmtuser, this.folderFG.value.foldertags, this.configSvc.nodeds[0].tagname, this.configSvc.nodeds[0].tagdesc, 
        this.configSvc.nodeds[0].tagtype, this.folderFG.value.kantype, this.folderFG.value.settotal, this.folderFG.value.timetotal, 
        this.folderFG.value.counttotals
        )} 
      
    
      
    
      this.dialogRef.close();
     
    
     }
    
     public createStepperfolder(){
    
      console.log('saving form folder for '+this.configSvc.nodeds[0]._id);
      console.log('lastest stepperfolder properties '+JSON.stringify(this.configSvc.newpropvalues));
      
      this.configSvc.addFolder( 'stepperfolder', this.configSvc.newpropvalues[0].itemvalue, 
        this.configSvc.activeitemid, this.configSvc.activename, 
        this.configSvc.activeteamid, this.configSvc.activelevel, '4',this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0]._id)
    
      this.dialogRef.close();
     
    
     }
    
     public createTabbedfolder(){
    
      console.log('creatoing tabbed folder for '+this.configSvc.nodeds[0]._id);
      console.log('lastest smartfoldertab properties '+JSON.stringify(this.configSvc.newpropvalues));
      
      this.configSvc.addFolder( 'smartfoldertab', this.configSvc.newpropvalues[0].itemvalue, 
        this.configSvc.activeitemid, this.configSvc.activename, 
        this.configSvc.activeteamid, this.configSvc.activelevel, '4',this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0]._id)
    
      this.dialogRef.close();
     
    
     }
    

    foldertagSelected(foldertag){
console.log('this is the selected foldertag '+JSON.stringify(foldertag))
this.configSvc.nodeds[0] = foldertag
console.log('this is the selected foldertag name '+this.configSvc.nodeds[0].name)
console.log('this is the selected foldertag icontype '+this.configSvc.nodeds[0].icontype)
console.log('this is the selected foldertag iconcolor '+this.configSvc.nodeds[0].iconcolor)
this.folderFG.patchValue({nodename: this.configSvc.nodeds[0].name})
this.folderFG.patchValue({nodeid: this.configSvc.nodeds[0]._id})
this.folderFG.patchValue({foldericon: this.configSvc.nodeds[0].icontype})
this.folderFG.patchValue({foldercolor: this.configSvc.nodeds[0].iconcolor})
console.log('this is the selected foldertag details '+this.folderFG.value.nodename+' '+this.folderFG.value.foldericon+' '+this.folderFG.value.foldercolor)

this.indexready =true
    }

    smartfolderSelected(smartfolder){
      console.log('this is the selected smartfolder '+JSON.stringify(smartfolder))
      this.configSvc.nodeds[0] = smartfolder
      console.log('this is the selected smartfolder name '+this.configSvc.nodeds[0].name)
      console.log('this is the selected smartfolder icontype '+this.configSvc.nodeds[0].icontype)
      console.log('this is the selected smartfolder iconcolor '+this.configSvc.nodeds[0].iconcolor)
      this.folderFG.patchValue({nodename: this.configSvc.nodeds[0].name})
      this.folderFG.patchValue({nodeid: this.configSvc.nodeds[0]._id})
      this.folderFG.patchValue({foldericon: this.configSvc.nodeds[0].icontype})
      this.folderFG.patchValue({foldercolor: this.configSvc.nodeds[0].iconcolor})
 

      console.log('this is the selected foldertag details '+this.folderFG.value.nodename+' '+this.folderFG.value.foldericon+' '+this.folderFG.value.foldercolor)
      if(this.configSvc.nodeds[0].type == 'smartfolder'){
      this.smartfolderready =true
    }
    if(this.configSvc.nodeds[0].type == 'smartfoldertab'){
      this.smartfoldertabready =true
    }
          }

    kanbanFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      console.log('filtering index on: '+filterValue)
      this.configSvc.finditemds = [];
      if (filterValue != '')
      {
        console.log('filter value befoore sending is : '+filterValue)
      this.configSvc.findKanban( filterValue)
    console.log('foldertagFilter value is currently set to '+this.configSvc.finditemds)
  
    }
  }

  public toolbarFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log('filtering toolbar on: '+filterValue)
    this.configSvc.toolbarFilter(filterValue)
  
  }

  kanbanSelected(kanban){
    this.kanbanready =false
console.log('this is the selected kanban '+JSON.stringify(kanban))
/// this.configSvc.nodeds[0] = kanban
this.selectedkanban = [kanban]
this.configSvc.activekanban = kanban.stepitems

console.log('this is the active selected kanban '+JSON.stringify(this.configSvc.activekanban))
this.kanbanready =true
  }

  toolbarSelected(toolbar){
  
console.log('this is the selected toolbar '+JSON.stringify(toolbar))
this.folderFG.patchValue({toolbarid:toolbar._id})
console.log('this is the selected toolbarid '+this.folderFG.value.toolbarid )
this.folderFG.patchValue({toolbarname:toolbar.name})
this.folderFG.patchValue({toolbaricon:toolbar.icontype})
this.folderFG.patchValue({toolbarclr:toolbar.clr})
this.folderFG.patchValue({toolbardesc:toolbar.desc})



  }

      public saveNode(type){
        this.configSvc.chatgptresponse = false;
        console.log('saving node for '+this.configSvc.nodeds[0]._id);
        console.log('lastest stepitem properties '+JSON.stringify(this.configSvc.newpropvalues));
        var statelabel = '';
        if (this.configSvc.nodeds[0].statetracker){statelabel = this.configSvc.nodeds[0].statelabel}
        if(this.folderFG.value.emoji) { this.folderFG.patchValue({cmticon: this.configSvc.chosenIcon})}
   
        if (this.configSvc.nodeds[0].website){
          this.folderFG.patchValue({website: this.configSvc.nodeds[0].website})
          this.folderFG.patchValue({weblink: this.configSvc.weblink})
         
         // const newdatetime = this.deadlineval;
         if(this.configSvc.nodechanged == false){
         this.configSvc.newpropvalues = this.configSvc.nodeds[0].stepitems;}
        console.log(' applying saveNode at the folder level');

        if(this.folderFG.value.fileinfolder){
          if(this.configSvc.folderdetailsds.tagged){this.indextype = '1'}
          if(!this.configSvc.folderdetailsds.tagged){this.indextype = '0'}}

          if(this.folderFG.value.ischatgpt) {

         //   this.folderFG.patchValue({addcomment: true})
            this.folderFG.patchValue({cmtval: this.folderFG.value.prompt})
            this.folderFG.patchValue({cmticon: 'forum'})
            this.folderFG.patchValue({cmtclr: 'purple'})
            this.folderFG.patchValue({temp: this.folderFG.value.temp})
          }

        this.configSvc.saveNode(this.folderFG.value.fileinfolder, true, this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
          this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, type, 
        JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, 
        this.folderFG.value.attach, this.folderFG.value.textract, this.folderFG.value.indextype, 
        
        this.folderFG.value.assigned,  this.folderFG.value.assignedto, this.folderFG.value.deadline,this.folderFG.value.deadlineval,
        this.folderFG.value.statetracker, this.folderFG.value.statelabel,
        this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.folderFG.value.cmtclr, 
        this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.allfiles, this.folderFG.value.total, this.folderFG.value.textractype, 
        this.folderFG.value.ischatgpt, this.folderFG.value.prompt, this.folderFG.value.temp, this.folderFG.value.responses,
        this.folderFG.value.website, this.configSvc.weblink )
        }
       
       // this.addToolfavs();
       if(!this.folderFG.value.textract && !this.folderFG.value.ischatgpt){
        console.log('ischatgpt is set to '+this.folderFG.value.ischatgpt)
        console.log('textract is set to '+this.folderFG.value.textract)
       this.dialogRef.close();}
      
       }

      public saveStepper() {
        this.configSvc.chatgptresponse = false;
        console.log('saving wizard now')
        if(this.folderFG.value.emoji) { this.folderFG.patchValue({cmticon: this.configSvc.chosenIcon})}

        if(this.folderFG.value.ischatgpt) {

         
          this.folderFG.patchValue({cmtval: this.folderFG.value.prompt})
          this.folderFG.patchValue({cmticon: 'forum'})
          this.folderFG.patchValue({cmtclr: 'purple'})
          this.folderFG.patchValue({temp: this.folderFG.value.temp})
          
        }

        this.configSvc.saveStepper(this.folderFG.value.fileinfolder, true, this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
        this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, this.folderFG.value.type, 
      JSON.stringify(this.configSvc.stepperprops), this.configSvc.nodeds[0].icontype, 
      this.configSvc.nodeds[0].iconcolor, this.folderFG.value.attach, this.folderFG.value.textract, this.folderFG.value.indextype, 
        
      this.folderFG.value.assigned,  this.folderFG.value.assignedto, this.folderFG.value.deadline,this.folderFG.value.deadlineval,
      this.folderFG.value.statetracker,  this.folderFG.value.statelabel,
      this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.folderFG.value.cmtclr, 
      this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.allfiles, this.configSvc.nodeds[0].kanbantagged, this.folderFG.value.total,
      this.folderFG.value.textractype, this.folderFG.value.ischatgpt, this.folderFG.value.prompt, this.folderFG.value.temp, this.folderFG.value.responses)
      

      if(!this.folderFG.value.textract && !this.folderFG.value.ischatgpt){
        console.log('ischatgpt is set to '+this.folderFG.value.ischatgpt)
        console.log('textract is set to '+this.folderFG.value.textract)
       this.dialogRef.close();
       }
     // this.addToolfavs();
      }

      /*
      public addToolfavs(){
        
          console.log('adding tool to favourites '+this.configSvc.nodeds[0]._id)
          console.log('tool name '+this.configSvc.nodeds[0].name)
          this.prefSvc.addToolfavs(this.auth.getUserDetails()._id, this.configSvc.nodeds[0]._id, 
          this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].name)
        
        
      }
      */

       public launchFlow(){
        console.log('launching flow')

        if(this.configSvc.nodeds[0].type == 'basicflow'){
          console.log('launching nodeflow for name '+JSON.stringify(this.configSvc.nodeds[0].name))
          console.log('launching nodeflow for flowid '+JSON.stringify(this.configSvc.nodeds[0]._id))
          console.log('launching stepperflow with newprop values'+JSON.stringify(this.configSvc.newpropvalues))
          this.flowSvc.launchFlow(true, 'basicflow', this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, this.configSvc.nodeds[0]._id,
            JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].icontype, 
            this.configSvc.activeitemid, this.configSvc.activelevel,  this.folderFG.value.attach, this.folderFG.value.textract, this.folderFG.value.ischatgpt, '1', 
          this.folderFG.value.deadline,this.folderFG.value.deadlineval,
          this.folderFG.value.statetracker, this.folderFG.value.state, this.folderFG.value.statelabel,
            this.folderFG.value.addcomment, this.folderFG.value.cmtval, this.folderFG.value.cmticon, this.configSvc.color, 
            this.folderFG.value.atcmt, this.folderFG.value.atcmtuser, this.folderFG.value.emoji, this.allfiles, this.folderFG.value.textractype )
        //  this.dialogRef.close();
        }
/*
        if(this.configSvc.nodeds[0].type == 'wizard'){
          console.log('launching nodeflow for '+JSON.stringify(this.configSvc.nodeds[0]))
          console.log('launching stepperflow with newprop values'+JSON.stringify(this.configSvc.newpropvalues))
          this.flowSvc.launchFlow('basicflow', this.configSvc.nodeds[0].name, this.configSvc.nodeds[0]._id,
            JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].icontype, this.configSvc.activeitemid, 'wizard')
          this.dialogRef.close();
        }
        */
  
      }
      
       public saveNodeimage(){

        console.log('saving node for '+this.configSvc.nodeds[0]._id);
      //  console.log('lastest stepitem properties '+JSON.stringify(this.newpropvalues));
        
        if (this.configSvc.activetype == 'editteam'){
          console.log('cannot save at the team level')}
        else{
        console.log(' applying saveNodeimage at the folder level');
        this.uploadSvc.saveNodeimage(this.actualimage, '3', this.configSvc.activeitemid, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.teamds.slice(-1)[0]._id, 
          this.configSvc.nodeds[0].name, this.folderFG.value,this.folderFG.value.ischatgpt )
      
          
       
      }
       }
/*
       public addNodefiles() {
        this.uploadSvc.totalfilesuploaded = this.allfiles.length
        console.log('total files uploading'+this.uploadSvc.totalfilesuploaded)
        this.uploadSvc.filesprocessed = 0; this.uploadSvc.fileuploadcount =0;this.uploadSvc.uploadfinished = false;
        this.uploadSvc.addNodefiles(this.configSvc.nodeds[0]._id, this.allfiles, false, this.configSvc.nodeds[0].icontype, this.configSvc.nodeds[0].iconcolor, this.configSvc.nodeds[0].name);
        }
        */

       public closeTextraction(){
         console.log('closing the textraction confirmation screen')
         this.dialogRef.close();
         this.uploadSvc.nodetextracted = false
         this.folderFG.value.textract =false 
         this.configSvc.indextemplate = false;
   
    //     var dynamicurl = '/toolbard/home?itemid='+this.configSvc.activeitemid+'&type=fold&name='+this.configSvc.activename+'&level='+this.configSvc.activelevel+'&teamid='+this.configSvc.activeteamid
     //   this.router.navigateByUrl(dynamicurl);

     this.configSvc.refreshFolder(this.configSvc.folderdetailsds._id, 'fold') 

       }

      

      



      userSelected(user){
        console.log('this is the selected user '+JSON.stringify(user))
        this.folderFG.patchValue({assignedto: user.inboxemail})
 
        this.configSvc.userready =true
        console.log('this is the ischatgpt value '+this.folderFG.value.ischatgpt)
              }
      
              public applyTags(){

                this.configSvc.applyTags(this.configSvc.teamds.slice(-1)[0]._id, this.configSvc.activeteamid, this.configSvc.activelevel, this.configSvc.nodeds[0]._id, 
                  this.configSvc.nodeds[0].name, this.configSvc.nodeds[0].desc, 'foldertag', 
                JSON.stringify(this.configSvc.newpropvalues), this.configSvc.nodeds[0].icontype, 
                this.configSvc.nodeds[0].iconcolor)
                this.dialogRef.close();
               
 }

 public applyKanban(){
  console.log('need to apply kanban now')
  let kanbancalc: number = this.selectedkanban[0].stepitems.length-1
  console.log('kanbanprops is: '+ kanbancalc)

  let kanbantotal = kanbancalc.toString()
  
 // this.folderFG.patchValue({kanbantotal: kanbancalc.toString()})

  this.configSvc.applyKanban(this.configSvc.activetype, this.configSvc.activeitemid,
    this.selectedkanban[0]._id, this.selectedkanban[0].name, this.selectedkanban[0].iconcolor, true, this.selectedkanban[0].settotal, this.selectedkanban[0].kantype, kanbantotal)
    this.dialogRef.close();
}

public removeKanban(){
  console.log('need to apply kanban now')

  this.configSvc.removeKanban(this.configSvc.activetype, this.configSvc.activeitemid, true)
    this.dialogRef.close();
}


public selectSubitem(selecteditem, index){
    
  this.hlsubitem = [];
  this.hlsubitem.push(selecteditem)
  console.log('cklciked item' +JSON.stringify(this.hlsubitem[0]))
  this.subitemselected = true; 
  
  
  
  this.onRecreate()
}

public batchItems(nodename, icontype, iconcolor, nodeid) {
  console.log('batching items bnow')
  console.log('number of items in batch '+this.configSvc.nodeds[0].subfolders.length);
  for( const [index, item] of this.configSvc.nodeds[0].subfolders.entries())
{ console.log('item in batch '+item.nodename);
  this.configSvc.batchitems(item.linkid, this.configSvc.activeitemid, item.type, this.folderFG.value.indextype, item.nodename, icontype, iconcolor, nodeid, this.configSvc.nodeds[0].subfolders.length, index)
} 
}

public updateNodetags(){


this.configSvc.updateNodetags(this.configSvc.newpropvalues, this.configSvc.activetype,  this.configSvc.nodeds[0]._id, this.folderFG.value.total )
this.dialogRef.close();
} 

public lockToolbar(){
  console.log('locking toolbar')
  this.configSvc.lockToolbar(this.configSvc.activeitemid, this.folderFG.value.toolbarid,this.folderFG.value.toolbarname, this.folderFG.value.toolbardesc, this.folderFG.value.toolbaricon, this.folderFG.value.toolbarclr )
this.dialogRef.close();
  
}

public unlockToolbar(){
  console.log('unlocking toolbar')
  this.configSvc.unlockToolbar(this.configSvc.activeitemid, this.folderFG.value.toolbarid )
this.dialogRef.close();
  
}

public getChoices(choicesetid){
 // console.log('this is the choicesetid at the getchoices function'+choicesetid)
  //  console.log('this is the current cached list'+JSON.stringify(this.authSvc.cachedchoices))
 //   console.log('this is the filtered choicesetid: '+choicesetid)
    var filtered = this.authSvc.cachedchoices.filter(item => item.parentid === choicesetid)
    
    return filtered;
}

public getPrompt(){
  console.log('selected promptlist is ')

  var choicesetid = this.folderFG.value.promptlist.path[0].pfoldid
  console.log('off to get the prompt for the choisen prompt list '+choicesetid)

  if ( localStorage.getItem(choicesetid)===null){ 
                 
    var localdata = JSON.parse(localStorage.getItem(choicesetid))
console.log('parta choicelist this is the localstoarage list that im getting '+JSON.stringify(localdata));
  console.log('this property has a list associated with it and its not in cache, lets go and get it')
// choicesetids.push(this.configSvc.nodeds[i].stepitems[i2].choicesetid)

this.tagerSvc.getChoicesetbyid(choicesetid, 'cache')

this.gotprompt = true
}




if (localStorage.getItem(choicesetid)!==null){ 
  console.log('partb choicelist check to see if the cache variable has the localstorage cache')
  console.log('this is the choicesetid: '+choicesetid)
// this.tagerSvc.getFormChoices(this.configSvc.nodeds[i].stepitems[i2].choicesetid)
var localdata = JSON.parse(localStorage.getItem(choicesetid))
console.log('this is the localstoarage '+JSON.stringify(localdata));
this.gotprompt = true
      if(!this.authSvc.choicesetids.includes(choicesetid)){
this.authSvc.choicesetids.push(choicesetid)
console.log('we havent added this to the auth localcache yet: '+choicesetid);
console.log('this is the choicesetids in cache: '+this.authSvc.choicesetids);
this.authSvc.cachedchoices= this.authSvc.cachedchoices.concat(localdata)
this.gotprompt = true

console.log('gotprompt is'+this.gotprompt )
}
}

}

public getPromptlist(){
  console.log('get prompt list')
 if(this.folderFG.value.ischatgpt)
{
this.tagerSvc.getPromptlist()}
console.log('ischatgpt is set to '+this.folderFG.value.ischatgpt); 
console.log('indextemplate is set to '+this.folderFG.value.indextemplate); 
console.log('attach is set to '+this.folderFG.value.attach); 

}

public selectPrompt(){
  console.log('selecting pompt now')
  this.addactive = true
  this.panelOpenState = false;


// this.tagerSvc.getPromptlist()



}

public addPrompt(promptbuild){
  console.log('adding prompt: '+promptbuild)
  var tempbuild = this.folderFG.value.prompt.concat(promptbuild+ ". ")
 this.folderFG.patchValue({prompt: tempbuild})
 this.folderFG.patchValue({promptbuild: ''})
 this.addactive = false
 this.panelOpenState = false;
}

public addContext(context){
  console.log('adding context: '+context)
  var tempbuild = this.folderFG.value.prompt.concat(context+ ". ")
 this.folderFG.patchValue({prompt: tempbuild})
 this.folderFG.patchValue({promptbuild: ''})
 this.addactive = false
 this.panelOpenState = false;
}

public addallContext(){
  console.log('adding all context')
   var tempbuild = ''
  console.log('foldercontext is '+JSON.stringify(this.foldercontext))
 
  for (const context of this.foldercontext) {
   tempbuild = tempbuild.concat(context+ ". ")
  }
  console.log('prompt is '+this.folderFG.value.prompt)
 this.folderFG.patchValue({prompt: tempbuild})
 this.folderFG.patchValue({promptbuild: ''})
 this.addallactive = false
}

public refreshFolder(){
  this.configSvc.popup = false
  console.log('this is the rating applied '+ this.selectedStar)
  if(this.selectedStar != 0){
    console.log('this has been rated need to set rating on this '+JSON.stringify(this.configSvc.nodeds))
    this.configSvc.ratePrompt(this.configSvc.nodeds[0].parentid, this.configSvc.nodeds[0]._id, this.selectedStar)

}
  if(this.selectedStar == 0){console.log('this has NOT been rated')
  this.configSvc.refreshFolder(this.configSvc.activeitemid, 'fold')}

}

starIcon(star: number) {
  if (star <= this.selectedStar) {
    return 'star';
  } else {
    return 'star_border';
  }
}

rate(star: number) {
  this.selectedStar = star;
  const stars = document.querySelectorAll('.mat-icon');
  stars.forEach((starIcon, index) => {
    if (index < star) {
      starIcon.classList.add('selected-star');
    } else {
      starIcon.classList.remove('selected-star');
    }
  });
}

onTemperatureChange() {
  // Do something with the new temperature value
  console.log('temp is set to '+this.folderFG.value.temp)
}

onaskingChatgpt() {
  // Do something with the new temperature value
  this.folderFG.patchValue({textractype: 'invoice'})
  console.log('textractype is set to '+this.folderFG.value.textractype)
}


}






