import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../services/config.service';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, FormArray, Validators, NgForm} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {

  public changesdetected = false;
  public reset: any[] = [{}];
 
public hluser = [];
public selectedteamindex: number;
public hlassignment = [];
public selectassigneduser = false;

public userselected = false;


  findteamclicked = false;
  finduserclicked = false;
  public teamsearchfc = new FormControl();
  public usersearchfc = new FormControl();

  constructor(private fb: FormBuilder, public configSvc: ConfigService) { }

  ngOnInit(): void {
    this.configSvc.findteamds = []
     if(this.configSvc.nodeds[0]?.assigned == true){
      this.configSvc.assigned = [];
      this.configSvc.assigned.push(this.configSvc.nodeds[0].assignedto);
     }
    
  //  this.onChanges();
  }
/*
  onChanges(): void {
    this.nextbutton.valueChanges.subscribe(val => {
    console.log('the value has changed to '+JSON.stringify(val))
    this.configSvc.assigned[this.selectedteamindex].next=val
    console.log('teams assigned is now set to'+JSON.stringify(this.configSvc.assigned))
   
    });}
*/
    public onRecreate(){
      this.reset[0] = {}
    }

  



  public findUser() {
    this.finduserclicked = true;
    this.configSvc.findUser(this.usersearchfc.value, 'user');

  
  }

  public assignUser(user){
    console.log('adding user: '+JSON.stringify(user))
    this.configSvc.assigned = [];
    this.configSvc.assigned.push(user[0].username)
   
    console.log('new assigned user is'+JSON.stringify(this.configSvc.assigned));
    this.changesdetected = true;

}

public unassignTeam(){
  console.log('unassigning team')
  this.configSvc.assigned.splice(this.selectedteamindex, 1)
  this.changesdetected = true;
}

public  selectUser(user) {
  this.hluser= [];this.hlassignment=[]
   console.log('the user seelected is set to '+user);
 
  
 this.hluser.push(user);
// this.selectassigneduser = false;
 

 this.userselected = true;
 
   console.log('highlighed team: ' + JSON.stringify(this.hluser));
    }
/*
    public selectAssignment(assigned, index){
      this.selectassignedteam = true;
      this.selectedteamindex = index
      this.hlteam = [];this.hlassignment = [];
      this.hlassignment.push(assigned);
      console.log('this is the selected assignment'+JSON.stringify(this.hlassignment))
      this.teamselected = false;
      console.log('this is the active next button'+JSON.stringify(this.configSvc.assigned[index].next))
     this.nextbutton.patchValue= this.configSvc.assigned[index].next
     console.log('this is the value of the next'+this.nextbutton.patchValue)
      console.log('this is the new assignment'+JSON.stringify(this.hlassignment))
  //    this.configSvc.assigned[index].next = this.nextbutton.value
      console.log('this is the assignment'+JSON.stringify(this.configSvc.assigned))
    }
    */

    drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.configSvc.assigned, event.previousIndex, event.currentIndex)
     
    }

   public updateAssignment(user){
     console.log('updating assignment for flow '+this.configSvc.activeitemid)
   //  console.log('updating assignment for item '+this.configSvc.flowitemdetailsds[0].itemid);
   this.configSvc.assigned = []
   this.configSvc.assigned.push(user[0].username)
     console.log('this is the new assignment'+JSON.stringify(this.configSvc.assigned))
     if(this.configSvc.activetype != 'fold'){
     this.configSvc.updateAssignment(this.configSvc.activeitemid, user[0].username)
     }
   }


  }


