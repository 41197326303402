<span *ngIf = "data.type=='find'">
<form [formGroup]="templateFG">
  <mat-label> Find Template</mat-label>
  <mat-form-field appearance="fill"  class = "indextemplates">
    <mat-label>Template</mat-label>
   
    <input type="text"  
    formControlName="indexname"   
    [matAutocomplete]="auto"
    
    matInput (keyup)="templateFilter($event)" placeholder="Index" autocomplete="off" >
    
    <mat-autocomplete #auto="matAutocomplete">
<mat-option *ngFor="let item of this.configSvc.finditemds" [value]="item.name"  
(onSelectionChange)="selectTemplate(item)">
<i class="material-icons icon-size" [ngStyle]="{'color': item.iconcolor}">
  {{item.icontype}}</i>
{{item.name}}:<i>{{item.desc}} </i>

</mat-option>
    </mat-autocomplete>

   
   </mat-form-field>

   <div *ngIf="templateFG.value.templateready == true" >
  <mat-label> <i class="material-icons icon-size" [ngStyle]="{'color': this.configSvc.nodeds[0]?.iconcolor}">
    {{this.configSvc.nodeds[0]?.icontype}}</i>
  {{this.configSvc.nodeds[0]?.name}}:  {{this.configSvc.nodeds[0]?.desc}}
</mat-label>
<span>
  <mat-tab-group>

    <mat-tab  label="Add Item">
      <ng-template mat-tab-label>
   
       Add Item
      </ng-template>
<span *ngIf="templateFG.value.type == 'node' ">
<app-activenode></app-activenode>
<!--
<span  class = "save-container">
  <button  mat-raised-button color="primary" class = "buttonstyle" (click)="addffItem(this.configSvc.activeitemid, this.configSvc.subitemsadded[0]._id)">Add Item </button>
              
</span>
-->
</span>


<span *ngIf="templateFG.value.type == 'wizard' || templateFG.value.type == 'tabbed'  ">
  <app-activestepper></app-activestepper>
  </span>

  <span  *ngIf="this.configSvc.activetype =='smartfolder' || this.configSvc.activetype =='batchitems'"  class = "save-container">
    <button  mat-raised-button color="primary"  class = "buttonstyle" (click)="addffItem(this.configSvc.activeitemid, this.configSvc.subitemsadded[0]._id)">Add Item </button>
                
  </span>
</mat-tab>
</mat-tab-group>
</span>

</div>
 
</form>  </span>



<span *ngIf = "data.type=='string'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">text_format</i>
    <span class = "popuptitle">Create New String Item</span> 
     
     <div mat-dialog-content>
        <span class="dialog">
         <mat-form-field>
           <mat-label>String Name</mat-label>
           <input matInput [(ngModel)]="data.formitem">
         </mat-form-field>
   
         <div  class = "typewrapper">
         <mat-checkbox (change)="toggledefault()" class = "itemwrapper">
           Set Default Value</mat-checkbox>
         <span *ngIf="this.hasdefault">
          <mat-form-field><mat-placeholder>Set default value here</mat-placeholder>
            <input  matInput [(ngModel)]="data.defaultvalue" matInput type="text"></mat-form-field>
        </span> </div>
   
        <div  class = "typewrapper">
          
        
   
           <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
            Include Label</mat-checkbox>
   
            <span *ngIf="haslabel" >
         <mat-form-field>
           <mat-placeholder>Enter label name here</mat-placeholder>
           <input 
           matInput [(ngModel)]="data.formitemlabel" 
          matInput type="text">
     </mat-form-field>
   </span>
   </div>

   <div  class = "typewrapper">
          
    

     <mat-form-field appearance="fill">
      <mat-label>Size in row</mat-label>
      <mat-select [(ngModel)]="selectedRowsize" name="rowsize">
        <mat-option *ngFor="let rowsize of sizes" [value]="rowsize.value">
          {{rowsize.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

     
</div>

   </span>
       </div>
       <div mat-dialog-actions>
     
         <button mat-raised-button color="primary"  class = "buttonstyle" (click)="onNoClick()" >Cancel</button>
       
         <button mat-raised-button color="primary" class = "buttonstyle" (click)="createString(data.formitem,data.formitemlabel, this.haslabel, data.defaultvalue, this.hasdefault )">Create String</button>
        
       </div>
   </span>

   <span *ngIf = "data.type=='label'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">label</i>
    <span class = "popuptitle">Create New Label</span> 
     
     <div mat-dialog-content>
        <span class="dialog">
         <mat-form-field>
           <mat-label>Label</mat-label>
           <input matInput [(ngModel)]="data.formitem">
         </mat-form-field>
   
     
   
        <div  class = "typewrapper">
          
        
   
          
   
            <span>
         <mat-form-field>
           <mat-placeholder>Label Display Name</mat-placeholder>
           <input 
           matInput [(ngModel)]="data.formitemlabel" 
          matInput type="text">
     </mat-form-field>
   </span>
   </div>

   <div  class = "typewrapper">
          
    

     <mat-form-field appearance="fill">
      <mat-label>Size in row</mat-label>
      <mat-select [(ngModel)]="selectedRowsize" name="rowsize">
        <mat-option *ngFor="let rowsize of sizes" [value]="rowsize.value">
          {{rowsize.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

     
</div>

   </span>
       </div>
       <div mat-dialog-actions>
     
         <button mat-raised-button color="primary"  class = "buttonstyle" (click)="onNoClick()" >Cancel</button>
       
         <button mat-raised-button color="primary" class = "buttonstyle" (click)="createLabel(data.formitem, data.formitemlabel)">Create Label</button>
        
       </div>
   </span>
   
   <span *ngIf = "data.type=='number'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">filter_1</i>
     <span class = "popuptitle">Create New Number Item</span> 
     <div mat-dialog-content>
     
       <mat-form-field>
         <mat-label>Number Name</mat-label>
         <input matInput [(ngModel)]="data.formitem">
       </mat-form-field>
   
       <div  class = "typewrapper">
         <mat-checkbox (change)="toggledefault()" class = "itemwrapper">
           Set Default Number</mat-checkbox>
         <span *ngIf="this.hasdefault">
          <mat-form-field><mat-placeholder>Set default number here</mat-placeholder>
            <input  matInput [(ngModel)]="data.defaultvalue" matInput type="number"></mat-form-field>
        </span> 
       </div>
        
        <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
         Include Number Label</mat-checkbox>
   
         <span *ngIf="haslabel" >
      <mat-form-field>
        <mat-placeholder>Enter number label</mat-placeholder>
        <input 
        matInput [(ngModel)]="data.formitemlabel" 
       matInput type="text">
   </mat-form-field>
   </span>

   <div  class = "typewrapper">


     <mat-form-field appearance="fill">
      <mat-label>Size in row</mat-label>
      <mat-select [(ngModel)]="selectedSize" name="size">
        <mat-option *ngFor="let size of sizes" [value]="size.value">
          {{size.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

     
</div>
   
     </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="createNumber(data.formitem,data.formitemlabel, data.defaultvalue )">Create Number</button>
       
     </div>
   </span>

   <span *ngIf = "data.type=='image'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">photo_camera</i>
    <span class = "popuptitle">Create New Image Item</span> 
     
     <div mat-dialog-content>
        <span class="dialog">
         <mat-form-field>
           <mat-label>String Name</mat-label>
           <input matInput [(ngModel)]="data.formitem">
         </mat-form-field>
   
         <div  class = "typewrapper">
         <mat-checkbox (change)="toggledefault()" class = "itemwrapper">
           Set Default Value</mat-checkbox>
         <span *ngIf="this.hasdefault">
          <mat-form-field><mat-placeholder>Set default value here</mat-placeholder>
            <input  matInput [(ngModel)]="data.defaultvalue" matInput type="text"></mat-form-field>
        </span> </div>
   
        <div  class = "typewrapper">
          
        
   
           <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
            Include Label</mat-checkbox>
   
            <span *ngIf="haslabel" >
         <mat-form-field>
           <mat-placeholder>Enter label name here</mat-placeholder>
           <input 
           matInput [(ngModel)]="data.formitemlabel" 
          matInput type="text">
     </mat-form-field>
   </span>
   </div>

   <div  class = "typewrapper">
          
    

     <mat-form-field appearance="fill">
      <mat-label>Image Size</mat-label>
      <mat-select [(ngModel)]="selectedImagesize" name="imagesize">
        <mat-option *ngFor="let image of imagesizes" [value]="image.value">
          {{image.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

     
</div>

   </span>
       </div>
       <div mat-dialog-actions>
     
         <button mat-raised-button color="primary"  class = "buttonstyle" (click)="onNoClick()" >Cancel</button>
       
         <button mat-raised-button color="primary" class = "buttonstyle" (click)="createImage(data.formitem,data.formitemlabel,data.defaultvalue, this.hasdefault )">Create Image</button>
        
       </div>
   </span>
   
   <span *ngIf = "data.type=='boolean'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">toggle_off</i>
     <span class = "popuptitle">Create New Slider</span> 
     <div mat-dialog-content>
    
       <mat-form-field class ="label1">
         <mat-label>Slider Name</mat-label>
         <input matInput [(ngModel)]="data.formitem">
       </mat-form-field>
   
       <div  class = "typewrapper">
    
            <mat-slide-toggle
            [(ngModel)]="hasdefault"
          
           >
            Set default slider value {{this.hasdefault}}
         </mat-slide-toggle>
        </div>

      
    
        
       
   
         <span >
      <mat-form-field class="label1">
        <mat-placeholder >Enter slider label</mat-placeholder>
        <input 
        matInput [(ngModel)]="data.formitemlabel" 
       matInput type="text">
   </mat-form-field>
   </span>

   <div>
    <mat-checkbox
    [(ngModel)]="this.haslabel"
  
   >
    Show second toggle if true {{haslabel}}
 </mat-checkbox></div>

 <span *ngIf="this.haslabel">
  <mat-slide-toggle
  [(ngModel)]="this.checkbox"

 >
  
</mat-slide-toggle>
<div *ngIf="!this.checkbox">
  <mat-form-field class="label1">
    <mat-placeholder >Enter label if second toggle false</mat-placeholder>
    <input 
    matInput [(ngModel)]="data.itemvalue" 
   matInput type="text">
</mat-form-field>
</div>

<div *ngIf="this.checkbox">
  <mat-form-field class="label1">
    <mat-placeholder >Enter label if second toggle true</mat-placeholder>
    <input 
    matInput [(ngModel)]="data.textarea" 
   matInput type="text">
</mat-form-field>
</div>
</span>
   
  </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="createBoolean(data.formitem, data.formitemlabel, this.defaulttoggle, this.hasdefault, this.haslabel, data.itemvalue, data.textarea )">Create Slider</button>
       
     </div>
</span>
   
   <span *ngIf = "data.type=='checkbox'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">check_box</i>
     <span class = "popuptitle">Create New Checkbox Item</span> 
     
     <div mat-dialog-content>
       
       <mat-form-field>
         <mat-label>Checkbox Name</mat-label>
         <input matInput [(ngModel)]="data.formitem">
       </mat-form-field>
   
       <div  class = "typewrapper">
    
            <mat-checkbox
            [(ngModel)]="defaulttoggle"
          
           >
            Set default checkboxvalue {{this.defaulttoggle}}
         </mat-checkbox>
      
       </div>
        
   
         <span >
           <mat-form-field>
             <mat-placeholder>Enter checkbox label</mat-placeholder>
             <input 
             matInput [(ngModel)]="data.formitemlabel" 
            matInput type="text">
        </mat-form-field>
        </span>
   
     </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="createCheckbox(data.formitem, data.formitemlabel, this.defaulttoggle )">Create Checkbox</button>
       
     </div>
   </span>
   
   <span *ngIf = "data.type=='date'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">calendar_today</i>
     <span class = "popuptitle">Create New Date Item</span> 
     <div mat-dialog-content>
    
       <mat-form-field>
         <mat-label>Date Name</mat-label>
         <input matInput [(ngModel)]="data.formitem">
       </mat-form-field>
   
   
   
      <div  class = "typewrapper">
        
      
   
         <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
          Include Label</mat-checkbox>
   
          <span *ngIf="haslabel" >
       <mat-form-field>
         <mat-placeholder>Enter label name here</mat-placeholder>
         <input 
         matInput [(ngModel)]="data.formitemlabel" 
        matInput type="text">
   </mat-form-field>
   </span>
   </div>
   
     </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="createDate('date', data.formitem,data.formitemlabel,'' )">Create Date</button>
       
     </div>
   </span>

   <span *ngIf = "data.type=='datetime'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">calendar_today</i>
    <span class = "popuptitle">Create New Date Time Item</span> 
    <div mat-dialog-content>
   
      <mat-form-field>
        <mat-label>Date Name</mat-label>
        <input matInput [(ngModel)]="data.formitem">
      </mat-form-field>
  
  
  
     <div  class = "typewrapper">
       
     
  
        <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
         Include Label</mat-checkbox>
  
         <span *ngIf="haslabel" >
      <mat-form-field>
        <mat-placeholder>Enter label name here</mat-placeholder>
        <input 
        matInput [(ngModel)]="data.formitemlabel" 
       matInput type="text">
  </mat-form-field>
  </span>
  </div>
  
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-button (click)="createDate('datetime', data.formitem,data.formitemlabel,'' )">Create Date</button>
      
    </div>
  </span>
   
   <span *ngIf = "data.type=='text'"><i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">notes</i>
     <span class = "popuptitle">Create New Text Box</span> 
     <div mat-dialog-content>
      
       <mat-form-field>
         <mat-label>Textbox Name</mat-label>
         <input matInput [(ngModel)]="data.formitem">
       </mat-form-field>
   
       <div  class = "typewrapper">
       <mat-checkbox (change)="toggledefault()" class = "itemwrapper">
         Set Default Text </mat-checkbox>
       <span *ngIf="this.hasdefault">
     
          <mat-form-field appearance="outline"   class = "textarea">
           <textarea
           
           matInput  [(ngModel)]="data.defaultvalue" ></textarea>
          </mat-form-field>
   
      </span> </div>
   
      <div  class = "typewrapper">
        
      
   
         <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
          Include Label</mat-checkbox>
   
          <span *ngIf="haslabel" >
       <mat-form-field>
         <mat-placeholder>Enter label name here</mat-placeholder>
         <input 
         matInput [(ngModel)]="data.formitemlabel" 
        matInput type="text">
   </mat-form-field>
   </span>
   </div>
   
     </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <button mat-button (click)="createText(data.formitem,data.formitemlabel,data.defaultvalue )">Create Textbox</button>
       
     </div>
   </span>
   
   <span *ngIf = "data.type=='choicelist' || data.type=='treelist' || data.type=='radiogroup'">
     <span *ngIf = "data.type=='choicelist'">
       <i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">arrow_drop_down_circle</i>
     <span class = "popuptitle">Create New Choicelist</span></span>
   
     <span *ngIf = "data.type=='radiogroup'">
       <span>
         <i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">radio_button_checked</i>
       <span class = "popuptitle">Create New Radio Button Group</span></span>
     
       
     
     </span>
   
     <span *ngIf = "data.type=='treelist'">
       <i class="material-icons icon-popup" [ngStyle]="{'color': 'black'}">account_tree</i>
       <span class = "popuptitle">Create New Treelist</span></span>
     <div mat-dialog-content>
   
   
         
         <mat-form-field>
           <mat-label>Name</mat-label>
           <input matInput [(ngModel)]="data.formitem">
         </mat-form-field>
   
       
   
        <div  class = "typewrapper">
          
           <mat-checkbox (change)="togglelabel()" class = "itemwrapper">
            Include Label</mat-checkbox>
   
            <span *ngIf="haslabel" >
         <mat-form-field>
           <mat-placeholder>Enter label name here</mat-placeholder>
           <input 
           matInput [(ngModel)]="data.formitemlabel" 
          matInput type="text">
     </mat-form-field>
   
   
   </span>
   Chosen List:
   
   
         <mat-chip-list>
          
         <span *ngIf="this.clickchoiceset">
         <mat-chip *ngFor="let paths of this.sortPath()"
           (click)="getChip(paths.pfoldid) "
           >
             {{paths.name}}
       </mat-chip></span>
       </mat-chip-list>
   
   </div>
   <div  class = "typewrapper">


    <mat-form-field appearance="fill">
     <mat-label>Size in row</mat-label>
     <mat-select [(ngModel)]="selectedSize" name="size">
       <mat-option *ngFor="let size of sizes" [value]="size.value">
         {{size.viewValue}}
       </mat-option>
     </mat-select>
   </mat-form-field>

    
</div>

   <form [formGroup]="choicesetFG">
         <mat-form-field>
             <mat-placeholder>Find ChoiceSet</mat-placeholder>
             <input 
             formControlName ="choicesetsearch"  
            matInput type="text">
       
   
             <button mat-button (click)="this.tagerSvc.findChoiceset(this.choicesetFG.value.choicesetsearch); " 
            
                     matSuffix 
                     mat-raised-button color="primary"
                     aria-label="Find Choiceset">
                 <i matSuffix class="fas fa-search" style="color:yellow"></i>
          </button>
         </mat-form-field>
   
         <div *ngIf="this.tagerSvc.findchoicesetclicked" class="tablebox" >
           <table mat-table [dataSource]="this.tagerSvc.choicesetsrch" >
            <tr mat-header-row *matHeaderRowDef="resultColumns"></tr>
            <tr mat-row *matRowDef="let selectedrow; columns: resultColumns; let i = index;"
            (click)="this.getChoiceset(selectedrow, data.type)"
            [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    ></tr>
          
            <ng-container matColumnDef="Icon">
             <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" class = "alignresults">
           <span *ngIf="element.haschild">  
             <i class="material-icons md-48" [ngStyle]="{'color': element.clr || 'black'}" >expand_more </i>
             </span>
        </td></ng-container>
                     
          <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef>Name </th>
             <td mat-cell *matCellDef="let element" class = "alignresults">
          {{element.name}}
           </td></ng-container>
          
           <ng-container matColumnDef="Desc">
            <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element" class = "alignresults">
          {{element.desc}}
          </td></ng-container>
          
          <ng-container matColumnDef="AddedBy">
            <th mat-header-cell *matHeaderCellDef> Added By </th>
          <td mat-cell *matCellDef="let element" class = "alignresults">
          {{element.ab}} 
          </td></ng-container>
          
          <ng-container matColumnDef="AddedOn">
            <th mat-header-cell *matHeaderCellDef> Added On </th>
          <td mat-cell *matCellDef="let element" class = "alignresults">
          {{element.ao | date: 'medium'}}
          </td></ng-container>
             </table> </div>
   
               </form>   
          
   
       
   
       
     
   
   
     </div>
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
       <span *ngIf="this.clickchoiceset && data.type=='choicelist'">
         <button mat-button (click)="this.createListitem(data.formitem,data.formitemlabel, data.type)">Create Choice List</button>
       </span>
   
       <span *ngIf="this.clickchoiceset && data.type=='radiogroup'">
         <button mat-button (click)="this.createListitem(data.formitem,data.formitemlabel, data.type)">Create Radio Group</button>
       </span>
   
       <span *ngIf="this.clickchoiceset && data.type=='treelist'">
         <button mat-button (click)="this.createListitem(data.formitem,data.formitemlabel, data.type)">Create Tree List</button>
       </span>
     </div>
   </span>
   
   
   
   
   
   <span *ngIf = "data.type=='steppreview'"><i class="material-icons icon-popup" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i>
     <div mat-dialog-content>
       <div>
   
         <mat-horizontal-stepper [linear]="isLinear" #stepper >
           <mat-step *ngFor="let step of this.configSvc.nodeds[0].stepper; let stepindex = index;" >
             <ng-template matStepLabel>{{ step.step }}</ng-template>
            <form id="myForm" [formGroup]="previewFG" >
                 <span [formArrayName]="'formArrayName'" class="popup-container">
                   <span class = "popuptitle">{{step.desc}}</span> 
                 <div *ngFor="let property of this.previewArray.controls; let i = index">
                
                   <div [formGroupName]="i" *ngIf="property.value.formstep.value == stepindex" >
                  
                    
                     <div *ngIf="property.value.formtype.value == 'string'"  > 
                       <span >
                       <span *ngIf="property.value.label.value==true" class = "labels">
                         <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                        
                      
                       <mat-form-field appearance="fill" class= "large-item"> 
                        <span *ngIf="property.value.label.value==false">
                             <mat-label >{{property.value.formitem.value}} </mat-label></span>
                             <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                            </mat-form-field>
                         
         
                          <div *ngIf="property.value.label.value==false" class = "labels">
                          
                          <mat-form-field appearance="outline" floatLabel="always"> 
                            <mat-label >{{property.value.property.value}} </mat-label>
                                <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                               </mat-form-field>
                              </div> 
                          
                       </span>     </div> 
                
                       <div *ngIf="property.value.formtype.value == 'number'" > 
                         <span>
           <span *ngIf="property.value.label.value==true" class = "labels">
                           <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                          
                        <mat-form-field appearance="fill" class= "small-item">  
                            
                              <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
            </mat-form-field></span>
           
            <span *ngIf="property.value.label.value==false" class = "labels">
         
             <mat-form-field appearance="outline" floatLabel="always"> 
               <mat-label >{{property.value.property.value}} </mat-label>
                   <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                  </mat-form-field>
           </span></span>
         </div>  
                
         <span *ngIf="property.value.formtype.value == 'boolean'"> 
          
           <div class= "labels"> 
      
             <mat-slide-toggle 
             [checked] = property.value.hasdefault
             (change)="changeBoolean(property.value.hasdefault, i)">
                                     
              {{property.value.formitemlabel.value}}
               </mat-slide-toggle>
            
              
             </div> 
      
                          </span>
                
                             <span *ngIf="property.value.formtype.value == 'date'"  > 
                               <span *ngIf="property.value.label.value==true" class = "labels">
                                 <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                </span> 
                             <mat-form-field appearance="fill">
                               
                               <input matInput [matDatepicker]="picker1"  formControlName="itemvalue" class= "medium-date">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker  #picker1></mat-datepicker>
                                  </mat-form-field></span>
         
         
                            <span *ngIf="property.value.formtype.value == 'checkbox'"> 
                                  
                                   
                                    <mat-checkbox formControlName="hasdefault">
                                     {{property.value.formitemlabel.value}} </mat-checkbox>
                          </span>
            
   
                          <span *ngIf="property.value.formtype.value == 'text'"  > 
                              
                           <span class = "labels">
                             <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                            </span> 
                          
                            <mat-form-field appearance="outline"   class= "xlarge-item">
                               <textarea
                               
                               formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                              </mat-form-field>
                              
                      </span> 
   
                      <span *ngIf="property.value.formtype.value == 'choicelist'"  class= "medium-item">
   
   
                       <span>
                         <span class = "labels">
                         <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
   <mat-form-field>
                     
                        <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue">
                    <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                      {{choice.name}}
                    </mat-option>
                  </mat-select>
                 
                </mat-form-field>
               </span>
                
              
             </span>
   
                  <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                   <span  *ngIf="property.value.label.value==true" >
                          <span >
                            <div class = "labels">
                            <mat-label >{{property.value.formitemlabel.value}} </mat-label></div>
                          <mat-radio-group
                          class="radio-container">
                          <mat-radio-button
                          
                        
                         
                          *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                          [value]="choice.name"
                          [checked]="choice.name == property.value.itemvalue"
                          (change)="changeRadio(choice, i)"
                          >
                            {{choice.name}}
                          </mat-radio-button>
                        </mat-radio-group>
                       
                      </span>
                    </span>
                 
                     </div>
   
                     <div *ngIf="property.value.formtype.value == 'treelist'  ">
                    
                       <span *ngIf="property.value.label.value==true"  class= "treecontainer">
              <span class = "labels">
              <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
   
           
            <mat-chip-list>
      
              
              <mat-chip *ngFor="let paths of this.sortPath()"
              (click)="getChoicetree(paths.pfoldid);property.value.itemvalue = paths.name;"
              >
                {{paths.name}}
          </mat-chip>
          </mat-chip-list>
      
        
            <div *ngIf="clickchoiceset" class="outerbox">  
              <span  >
              <table mat-table [dataSource]="this.tagerSvc.activetree"  >
              
                <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i2 = index;"
                (click)="this.getChoicetree(selectedrow._id); 
               
                
                this.hlchoice(selectedrow,  i);
                "
                [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                class="innerbox"
                ></tr>
              
              
                         
              <ng-container matColumnDef="Name" >
                    <th mat-header-cell *matHeaderCellDef>Name </th>
                 <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.haschild">  
                    <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                    </span>
                     {{element.name}}
               </td></ng-container>
              
                 </table> </span>
      </div>
      
     
    
      
      </span>
                  </div>
   
   
                                        
                                 </div> 
                 </div>
                 
                
               </span>
                  </form>
         
             </mat-step>
         </mat-horizontal-stepper>
         </div>
   </div>
   
     
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
     
       
     </div>
   </span>
   
   
   
   <span *ngIf = "data.type=='nodepreview'">
     
     <div mat-dialog-content>
     
       <i class="material-icons icon-preview" [ngStyle]="{'color': this.configSvc.nodeds[0].iconcolor}">{{this.configSvc.nodeds[0].icontype}}</i>
    <span class = "popuptitle">{{this.configSvc.nodeds[0].desc}}</span> 
    
            <form id="myForm" [formGroup]="previewFG" >
                 <span [formArrayName]="'formArrayName'">
         
                 <div *ngFor="let property of this.previewArray.controls; let i = index"
                
                 
                 >
                   <div [formGroupName]="i"  >
         
                      <div *ngIf="property.value.formtype.value == 'string'" > 
                        <span class ="itemcontainer">
                        <span *ngIf="property.value.label.value==true" class = "labels">
                          <mat-label>{{property.value.formitemlabel.value}}</mat-label></span>
                         
                       
                        <mat-form-field appearance="fill"> 
                         <span *ngIf="property.value.label.value==false">
                              <mat-label >{{property.value.property.value}} </mat-label></span>
                              <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                             </mat-form-field>
                          
   
                           <div *ngIf="property.value.label.value==false" class = "labels">
                           
                           <mat-form-field appearance="outline" floatLabel="always"> 
                             <mat-label >{{property.value.property.value}} </mat-label>
                                 <input formControlName="itemvalue" matInput value="{{property.value.itemvalue}}">
                                </mat-form-field>
                               </div> 
                           
                        </span>     </div> 
                
                             <div *ngIf="property.value.formtype.value == 'number'"> 
                               <span class ="itemcontainer">
                 <span *ngIf="property.value.label.value==true" class = "labels">
                                 <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                
                              <mat-form-field appearance="fill">  
                                  
                                    <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                  </mat-form-field></span>
                 
                  <span *ngIf="property.value.label.value==false" class = "labels">
               
                   <mat-form-field appearance="outline" floatLabel="always"> 
                     <mat-label >{{property.value.property.value}} </mat-label>
                         <input formControlName="itemvalue" type = "number" matInput value="{{property.value.itemvalue}}">
                        </mat-form-field>
                 </span></span>
               </div>  
                 <span *ngIf="property.value.formtype.value == 'boolean'"> 
                 
               <div class= "labels"> 
   
                 
                
                 <mat-slide-toggle 
                 [checked] = property.value.hasdefault.value
                 
                                             (change)="toggleChange()" >
                  {{property.value.formitemlabel.value}}
                   </mat-slide-toggle>
                
                 
                 </div> 
   
                              </span>
                
                             <span *ngIf="property.value.formtype.value == 'date'"> 
                               <span *ngIf="property.value.label.value==true" class = "labels">
                                 <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                </span> 
                             <mat-form-field appearance="fill">
                               <input matInput [matDatepicker]="picker1" placeholder="{{property.value.formitem}}" formControlName="itemvalue" >
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker  #picker1></mat-datepicker>
                                  </mat-form-field></span>
         
         
                                  <div *ngIf="property.value.formtype.value == 'checkbox'"> 
                                  
                                    
                                    <mat-checkbox formControlName="hasdefault"
                                   >
                                     {{property.value.formitemlabel.value}}   {{property.value.hasdefault.value}}  </mat-checkbox>
                             </div>
   
                             <div *ngIf="property.value.formtype.value == 'text'">
                               <span  class = "labels">
                                 <mat-label>{{property.value.formitemlabel.value}}</mat-label>
                                </span> 
                                <mat-form-field appearance="outline"   class = "textarea">
                                 <textarea
                                 
                                 formControlName="itemvalue" matInput value={{property.value.itemvalue}}></textarea>
                                </mat-form-field>
                             </div>
   
            
                                    <div *ngIf="property.value.formtype.value == 'choicelist'  ">
      
   
                                     <span *ngIf="property.value.label.value==true" class= "itemcontainer">
                                       <span class = "labels">
                                       <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
      <mat-form-field>
                                   
                                      <mat-select formControlName="itemvalue"  [(value)]="property.value.itemvalue" 
                                     
                                      (selectionChange)="choiceChange()"
                                      >
                                  <mat-option *ngFor="let choice of getChoices(property.value.choicesetid.value)" [value]="choice.name">
                                    {{choice.name}}
                                  </mat-option>
                                </mat-select>
                               
                              </mat-form-field>
                             </span>
                              
                            
                                </div>
   
                                <div *ngIf="property.value.formtype.value == 'treelist'  ">
      
   
                                 <span *ngIf="property.value.label.value==true" class= "treecontainer">
                                   <span class = "labels">
                                   <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
   
                                
                                 <mat-chip-list>
                           
                                   
                                   <mat-chip *ngFor="let paths of this.sortPath()"
                                   (click)="getChoicetree(paths.pfoldid) "
                                   >
                                     {{paths.name}}
                               </mat-chip>
                               </mat-chip-list>
                           
                             
                                 <div class="outerbox">  
                                   <span  >
                                   <table mat-table [dataSource]="this.tagerSvc.activetree"  >
                                   
                                     <tr mat-row *matRowDef="let selectedrow; columns: treeColumns; let i = index;"
                                     (click)="this.getChoicetree(selectedrow._id); this.hlchoice(selectedrow)"
                                     [style.background]=" this.hlchoiceset.indexOf(selectedrow) != -1 ? 'lightblue' : ''"    
                                     class="innerbox"
                                     ></tr>
                                   
                                   
                                              
                                   <ng-container matColumnDef="Name" >
                                         <th mat-header-cell *matHeaderCellDef>Name </th>
                                      <td mat-cell *matCellDef="let element">
                                       <span *ngIf="element.haschild">  
                                         <i class="material-icons md-48" [ngStyle]="{'color': 'black'}">expand_more </i>
                                         </span>
                                          {{element.name}}
                                    </td></ng-container>
                                   
                                      </table> </span>
                           </div>
                           
                           Value selected: 
                           <div  *ngIf="this.choicesetselected"><mat-chip-list>
                           <mat-chip>
                           {{this.edittagFG.value.itemvalue}}
                           </mat-chip></mat-chip-list></div>
                           
                           </span>
                           
                            </div>
   
                                <div *ngIf="property.value.formtype.value == 'radiogroup'  ">
                          <span  *ngIf="property.value.label.value==true" >
                                 <span class= "itemcontainer">
                                   <span class = "labels">
                                   <mat-label >{{property.value.formitemlabel.value}} </mat-label></span>
                                 <mat-radio-group
                                 class="radio-group">
                                 <mat-radio-button class="radio-button" 
                                 
                               
                                
                                 *ngFor="let choice of getChoices(property.value.choicesetid.value)"
                                 [value]="choice.name"
                                 [checked]="choice.name == property.value.itemvalue"
                                 (change)="property.value.itemvalue = $event.value.name"
                                 >
                                   {{choice.name}}
                                 </mat-radio-button>
                               </mat-radio-group>
                              
                             </span>
                           </span>
                        
                            </div>
                                   </div> 
                 </div>
                 
                
               </span>
                  </form>
         
       
       
        
   </div>
   
     
     <div mat-dialog-actions>
       <button mat-button (click)="onNoClick()">Cancel</button>
     
       
     </div>
   </span>

   
<span *ngIf = "data.type=='step'"><i class="material-icons icon-step" [ngStyle]="{'color': 'darkred'}">directions</i>
  <div mat-dialog-content>
    <p>Enter StepName</p>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>StepDescription</mat-label>
      <input matInput [(ngModel)]="data.desc">
    </mat-form-field>

  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="this.addStep(data.name, data.desc, 'step')">Create Step</button>
    
  </div>
</span>

        
<span *ngIf = "data.type=='copy'">
  <div mat-dialog-content>
    <i class="material-icons icon-delete">content_copy</i> 
    Copy {{this.configSvc.activename}} ?
  </div>
  <div mat-dialog-actions>
    <span class= "genpopupbottom">
      <button mat-button (click)="onNoClick()" 
            
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Cancel">Cancel</button>

      <button mat-button (click)="copy(this.configSvc.activeitemid)" 
            
      matSuffix 
      mat-raised-button color="primary"
      aria-label="Delete">Copy</button>
    
    </span>
    
  </div>
  </span>

  <span *ngIf = "data.type=='subfolder'">
    <div mat-dialog-content>
      <i class="material-icons icon-delete">create_new_folder</i> 
     Create Sub Folder
    </div>
    <div mat-dialog-actions>
      <span class= "genpopupbottom">

      
          <mat-form-field  appearance="fill">
            
            <input matInput [(ngModel)]="data.name" (keyup.enter)="createSubfolder('subfolder', data.name, this.configSvc.activeitemid)">
          </mat-form-field>

          <div>
        <button mat-button (click)="onNoClick()" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Cancel">Cancel</button>
  
        <button mat-button (click)="createSubfolder('subfolder', data.name, this.configSvc.activeitemid )" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="subfolder">Create Subfolder</button>
      </div>
      </span>
      
    </div>
    </span>

    <span *ngIf = "data.type=='kanban'">
      <div mat-dialog-content>
        <i class="material-icons icon-delete">view_column</i> 
       Apply Kanban
      </div>
      <div mat-dialog-actions>
        <span class= "genpopupbottom">
  
        
            <mat-form-field  appearance="fill">
              
              <input matInput [(ngModel)]="data.name" (keyup.enter)="findItem(data.name, this.configSvc.activeitemid)">
            </mat-form-field>
  
            <div>
          <button mat-button (click)="onNoClick()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Cancel">Cancel</button>
    
          <button mat-button (click)="findItem(data.name, 'kanban' )" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Find">Find Kanban</button>
        </div>
        </span>
        
      </div>
      </span>

      

  <span *ngIf = "data.type=='paste' ">
    <div mat-dialog-content>
      <i class="material-icons icon-delete">content_paste</i> 
      Paste  ?
    </div>
    <div mat-dialog-actions>
      <span class= "genpopupbottom">
        <button mat-button (click)="onNoClick()" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Cancel">Cancel</button>
  
        <button mat-button (click)="paste(this.configSvc.activeitemid)" 
              
        matSuffix 
        mat-raised-button color="primary"
        aria-label="Delete">Copy</button>
      
      </span>
      
    </div>
    </span>

    <span *ngIf = "data.type=='deletedesign'">
      <div mat-dialog-content>
        <i class="material-icons icon-delete">delete</i> 
        Are you sure you want to delete {{this.configSvc.activename}} ?
      </div>
      <div mat-dialog-actions>
        <span class= "genpopupbottom">
          <button mat-button (click)="onNoClick()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Cancel">Cancel</button>
    
          <button mat-button (click)="deleteDesign()" 
                
          matSuffix 
          mat-raised-button color="primary"
          aria-label="Delete">Delete</button>
        
        </span>
        
      </div>
      </span>
   
   
   